import React, { useState } from 'react'
import { connect } from 'react-redux'
import { deepClone } from '~/utils/immutability'
import EditableHeader from '~/components/utility/EditableHeader'
import { Icon } from 'semantic-ui-react'
import PDVC from '~/utils/PDVC'
import { Process } from '~/utils/process'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import FlashMessenger from '~/utils/FlashMessenger'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'
import PhaseIterationCounter from './PhaseIterationCounter'
import './PhaseGroupHeader.css'
import Authorizer from '~/utils/Authorizer'

const { VALID_PROCESS_DATA: { phaseGroupNameLength } } = ProcessValidator

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  displayedPhaseId: state.displayedPhaseId,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateProcess: (processId, params) => (
    callUpdateProcess(dispatch, processId, params)
  ),
})

function PhaseGroupHeader({
  groupName,
  activeProcess,
  dispatchCallUpdateProcess,
  processDescriptionSnapshots,
  setDisableDrag,
  deleteGroup,
  user,
}) {

  const [loading, setLoading] = useState(false)

  const changeNameHandler = async newGroupName => {

    if (newGroupName === groupName) return

    if (newGroupName.length > phaseGroupNameLength) {
      FlashMessenger.error(`Group name must be shorter than ${phaseGroupNameLength + 1} characters.`)
      return
    }

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    if (diffs.length) {
      FlashMessenger.warning('Please revert or apply your process changes before renaming a group')
      return
    }

    setLoading(true)

    try {
      const newProcess = deepClone(activeProcess)
      PhaseGroupHandler.renameGroup(newProcess, groupName, newGroupName)
      await dispatchCallUpdateProcess(activeProcess.id, { description: newProcess.description })
    } catch (e) {
      FlashMessenger.error(e.message)
      setLoading(false)
    }
  }

  const isEditDisabled = () => (
    PhaseGroupParser.hasGroupStarted(activeProcess, groupName)
    || Process.isFinished(activeProcess)
    || Authorizer.isResourceViewer(activeProcess, user.id)
  )

  const getIconClassName = disabled => (
    disabled ? 'phase-group-delete-icon-disabled' : 'phase-group-delete-icon'
  )

  return (
    <div
      onMouseOver={() => setDisableDrag(false)}
      onFocus={() => setDisableDrag(false)}
      onMouseLeave={() => setDisableDrag(true)}
    >
      <EditableHeader
        name={groupName}
        changeNameHandler={newName => changeNameHandler(newName)}
        fontSize='1rem'
        fontWeight='normal'
        fluid
        iconSize='tiny'
        isEditDisabled={isEditDisabled()}
        loading={loading}
      />
      <div
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        className='phase-group-header-controls-container'
      >
        <PhaseIterationCounter
          groupName={groupName}
          activeProcess={activeProcess}
        />
        <Icon
          role='button'
          name='trash'
          className={getIconClassName(isEditDisabled())}
          onClick={deleteGroup}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseGroupHeader)
