import React, { useState } from 'react'
import { Divider } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Dashboard from './Dashboard'
import DashboardToolbar from './DashboardToolbar'
import SaveDashboardModalContainer from './SaveDashboardModalContainer'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import FlashMessenger from '~/utils/FlashMessenger'

const mapStateToProps = ({ user }) => ({ user })

const Dashboards = props => {
  const { activeDevice, activeProcess, user } = props
  const { fctGraphUIConfig: { configs, fctGraphId } } = user
  const { dashboards } = configs

  const [selectedDashboardId, setSelectedDashboardId] = useState(dashboards[0].id)
  const [openSaveDashboardModalByType, setOpenSaveDashboardModalByType] = useState(false)
  const [ requestLockDashboard, setRequestLockDashboard ] = useState(false)
  const [ openWidgetModal, setOpenWidgetModal ] = useState(false)

  const dashboard = dashboards.find(d => d.id === selectedDashboardId)
  const { locked } = dashboard
  const isDeleteForbidden = dashboards.length === 1

  const toggleDashboardLock = async () => {
    try {
      setRequestLockDashboard(true)
      const toggleLock = !locked

      const updatedDashboard = GraphDashboard.toggleDashboardLock(dashboard, toggleLock)
      const updatedDashboards = GraphDashboards.updateDashboard(dashboards, updatedDashboard)

      await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
    } catch (e) {
      FlashMessenger.error(e.message)
    } finally {
      setRequestLockDashboard(false)
    }
  }

  return (
    <div>
      <DashboardToolbar
        dashboards={dashboards}
        dashboard={dashboard}
        setSelectedDashboardId={setSelectedDashboardId}
        setOpenSaveDashboardModalByType={setOpenSaveDashboardModalByType}
        toggleDashboardLock={toggleDashboardLock}
        requestLockDashboard={requestLockDashboard}
        setOpenWidgetModal={setOpenWidgetModal}
      />
      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
      <Dashboard
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        dashboard={dashboard}
        dashboards={dashboards}
        setOpenWidgetModal={setOpenWidgetModal}
        openWidgetModal={openWidgetModal}
      />
      {openSaveDashboardModalByType ? (
        <SaveDashboardModalContainer
          dashboard={dashboard}
          setSelectedDashboardId={setSelectedDashboardId}
          closeModal={() => setOpenSaveDashboardModalByType(false)}
          isDeleteForbidden={isDeleteForbidden}
          openSaveDashboardModalByType={openSaveDashboardModalByType}
          initialTab='Edit'
        />
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps)(Dashboards)
