import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'

import Device from '~/utils/Device'
import { Process } from '~/utils/process'
import ResumeProcessModal from '../modals/ResumeProcessModal'

export const ExecutedProcessButtons = ({
  activeProcess,
  toggleModal,
  activeDevice,
  isPaused,
  disableAllButtons,
}) => {

  const [toggleResumeModal, setToggleResumeModal] = useState(false)

  const closeResumeModal = () => setToggleResumeModal(false)

  const ResumeButton = () => (
    <Popup
      trigger={(
        <Button
          icon='play'
          onClick={() => setToggleResumeModal(true)}
          disabled={
            Device.hasInconsistentState(activeDevice)
            || !isPaused
            || disableAllButtons
          }
        />
      )}
      content='resume process'
      position='bottom center'
    />
  )

  const PauseButton = () => (
    <Popup
      trigger={(
        <Button
          icon='pause'
          onClick={e => toggleModal(e, 'showPauseProcessModal')}
          disabled={Device.hasInconsistentState(activeDevice) || isPaused || disableAllButtons}
          data-testid='pause-process-button'
        />
      )}
      content='pause process'
      position='bottom center'
    />
  )

  const StepButton = () => (
    <Popup
      trigger={(
        <Button
          icon='step forward'
          onClick={e => toggleModal(e, 'showNextPhaseModal')}
          data-testid='next-phase-button'
          disabled={
            Device.hasInconsistentState(activeDevice)
            || isPaused
            || disableAllButtons
            || Process.isInLastPhase(activeProcess)
          }
        />
      )}
      content='go to next phase'
      position='bottom center'
    />
  )

  return (
    <>
      {toggleResumeModal && (
        <ResumeProcessModal
          activeDevice={activeDevice}
          activeProcess={activeProcess}
          closeHandler={closeResumeModal}
          open
          headerText='Resume process'
        />
      )}
      <Button.Group size='small'>
        <ResumeButton />
        <PauseButton />
        <StepButton activeProcess={activeProcess} />
      </Button.Group>
    </>
  )
}

export default ExecutedProcessButtons
