import nexus from '@ospin/nexus'
import { setFunctionalityDescriptions } from '~/redux/actions/actions'

const callGetFunctionalityDescriptions = async dispatch => {
  const { data: { functionalityDescriptions } } = await nexus.deviceDescription
    .functionalityDescription.list()

  dispatch(setFunctionalityDescriptions(functionalityDescriptions))
}

export default callGetFunctionalityDescriptions
