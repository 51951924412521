const DOCS_BASE_PATH = 'https://synaptic-lab.refined.site/space/OD/'
const DOC_LINK_MAP = { collaboratingOnDevices: '583401633/Managing+device+access' }

export default function getDocsUrlString(tag) {
  const urlEnding = DOC_LINK_MAP[tag]
  if (!urlEnding) {
    throw new Error(`${tag} is not a valid documentation tag`)
  }
  return DOCS_BASE_PATH.concat(urlEnding)
}
