import nexus from '@ospin/nexus'
import { modifyProcessAccess } from '~/redux/actions/actions'

const callModifyProcessAccess = async (dispatch, processId, userId, newGroup) => {
  await nexus.process.access.modify({ processId, userId, newGroup })
  dispatch(modifyProcessAccess({
    processId,
    userId,
    newGroup,
  }))
}

export default callModifyProcessAccess
