import { FCTGraph } from '@ospin/fct-graph'
import FunctionalityDefinitions from '~/utils/functionalities/FunctionalityDefinitions'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import uuid from 'uuid'
import { generatePhysicalFctMarkup, generateVirtualFctMarkup } from './FctMarkupGenerator'
import LayoutUtils from './LayoutUtils'

class X6Utils {

  static getX6IdByPosId(nodes, lookupPosId) {
    return nodes.find(({ data: { posId } }) => posId === lookupPosId).id
  }

  static generateFctNode(templateFct, position, definitions, descriptions, template) {
    const fctDefinition = FunctionalityDefinitions.findBySubType(definitions, templateFct.subType)
    const iconURL = FunctionalityDescription.getFunctionalityIcon(descriptions, templateFct.subType)

    if (!fctDefinition) {
      throw new Error(`Unknown Functionality ${templateFct.subType} in template, currently the builder only supports viewing functionalities that are connected to the device`)
    }


    const baseMarkup = templateFct.props.isVirtual
      ? generateVirtualFctMarkup({ ...fctDefinition, posId: templateFct.posId, iconURL }, template)
      : generatePhysicalFctMarkup({ ...fctDefinition, posId: templateFct.posId, iconURL }, template)
    const { posId, props: { isVirtual }, subType, type } = templateFct
    const data = {
      posId,
      type,
      subtype: subType,
      isvirtual: isVirtual,
    }

    const { height, width, attrs, ports, label, markup } = baseMarkup

    return {
      position,
      attrs,
      data,
      label,
      ports,
      id: uuid(),
      markup,
      size: { height, width },
      shape: 'rect',
      visibile: true,
    }
  }

  static generateEdgeCell({ source, target, data }) {
    return {
      shape: 'edge',
      attrs: { zIndex: 0 },
      source: { ...source, connectionPoint: { name: 'boundary', args: { selector: 'circle' } } },
      target: { ...target, connectionPoint: { name: 'boundary', args: { selector: 'circle', offset: 3 } } },
      tools: { name: 'button-remove' },
      data,
    }
  }

  static generateEdges(connections, fctNodes) {
    return connections.map(element => {
      const { from, to } = element
      const sourceCellId = X6Utils.getX6IdByPosId(fctNodes, from.posId)
      const targetCellId = X6Utils.getX6IdByPosId(fctNodes, to.posId)

      return X6Utils.generateEdgeCell(
        {
          source: { cell: sourceCellId, port: from.slotName },
          target: { cell: targetCellId, port: to.slotName },
          data: {
            source_pos_id: from.posId,
            target_pos_id: to.posId,
          },
        })

    })
  }

  static getRenderableFunctionalities(template) {
    const { functionalities } = template
    return FCTGraph.getFctsWithoutIONodes({ functionalities })
  }

  static getRenderableConnections(template) {
    const fcts = this.getRenderableFunctionalities(template)
    const posIds = fcts.map(({ posId }) => posId)
    const { connections } = template
    return connections.filter(
      ({ from, to }) => posIds.includes(from.posId) && posIds.includes(to.posId),
    )
  }

  static generateJSON(template, layout, functionalityDefinitions, functionalityDescriptions) {

    const fctNodes = this.getRenderableFunctionalities(template)
      .map(
        fct => X6Utils.generateFctNode(
          fct,
          LayoutUtils.getNodePositionOr0(layout, fct.posId),
          functionalityDefinitions,
          functionalityDescriptions,
          template,
        ),
      )
    const renderableConnections = this.getRenderableConnections(template)
    const edgeCells = X6Utils.generateEdges(renderableConnections, fctNodes)
    const json = { cells: [...fctNodes, ...edgeCells] }
    return json
  }
}

export default X6Utils
