import { DeviceProcessStreamingDataPusherChannel } from '@ospin/pusher'
import liveDataHandler from '~/redux/pusher/deviceProcessStreamingDataChannel/eventHandlers/liveDataHandler'
import cameraImageHandler from '~/redux/pusher/deviceProcessStreamingDataChannel/eventHandlers/cameraImageHandler'

const { EVENTS: DEVICE_PROCESS_STREAMING_DATA_PUSHER_EVENTS } = DeviceProcessStreamingDataPusherChannel

export default () => ({
  [DEVICE_PROCESS_STREAMING_DATA_PUSHER_EVENTS.PROCESS_IMAGE_GENERATED]: cameraImageHandler,
  [DEVICE_PROCESS_STREAMING_DATA_PUSHER_EVENTS.PROCESS_SENSOR_DATA_GENERATED]: liveDataHandler,
})
