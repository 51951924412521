import Validator from '~/utils/validation/Validator'

class SlotConfig {

  static get CONFIG_PROPS() {
    return {
      DEFAULT_INPUT_VALUE: 'defaultInputValue',
      DISPLAY_UNIT: 'displayUnit',
    }
  }

  static isPropertySet(slotConfig, propName) {
    return !Validator.isUndefinedOrNull(slotConfig[propName])
  }

  static hasDefaultInputValue(slotConfig) {
    return slotConfig && SlotConfig
      .isPropertySet(slotConfig, SlotConfig.CONFIG_PROPS.DEFAULT_INPUT_VALUE)
  }

  static getDefaultInputValue(slotConfig) {
    return slotConfig?.defaultInputValue
  }

  static hasDisplayUnit(slotConfig) {
    return slotConfig && SlotConfig
      .isPropertySet(slotConfig, SlotConfig.CONFIG_PROPS.DISPLAY_UNIT)
  }

  static getDisplayUnit(slotConfig) {
    return slotConfig?.displayUnit
  }

  static getAbsoluteTareValue(slotConfig) {
    return slotConfig?.absoluteTareValue
  }

  static getDisplayedDecimalPlaces(slotConfig) {
    return slotConfig?.displayedDecimalPlaces
  }

}

export default SlotConfig
