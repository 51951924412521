import nexus from '@ospin/nexus'
import { updateProcess } from '~/redux/actions/actions'

const callCreateAnnotation = async (dispatch, process, data) => {
  const { data: newAnnotation } = await nexus.process.annotation
    .create({ processId: process.id, data })
  const params = { annotations: [...process.annotations, newAnnotation] }
  dispatch(updateProcess({ processId: process.id, params }))
}

export default callCreateAnnotation
