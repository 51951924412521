import React from 'react'
import { Progress } from 'semantic-ui-react'
import PasswordValidation from '~/utils/validation/PasswordValidation'

const STRENGTH_STYLE = {
  strong: { color: 'green', text: 'strong' },
  good: { color: 'blue', text: 'good' },
  weak: { color: 'red', text: 'weak' },
}

const getStyleAndText = strength => {
  if (strength === 6) {
    return STRENGTH_STYLE.strong
  }
  if (strength >= 4) {
    return STRENGTH_STYLE.good
  }
  return STRENGTH_STYLE.weak
}

const PasswordStrenghIndicator = ({ password }) => {

  const strengthRules = [
    ...PasswordValidation.REQUIRED_PASSWORD_RULES,
    ...PasswordValidation.OPTIONAL_PASSWORD_RULES,
  ]

  const strength = strengthRules.filter(rule => rule.isValid(password)).length

  const { color, text } = getStyleAndText(strength)

  return (
    <Progress
      percent={(strength / 6) * 100}
      color={color}
      label={`Password strength: ${text}`}
      size='tiny'
    />
  )
}

export default PasswordStrenghIndicator
