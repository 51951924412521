import React, { useState } from 'react'
import nexus from '@ospin/nexus'
import { Table, Button } from 'semantic-ui-react'
import Time from '~/utils/Time'
import Authorizer from '~/utils/Authorizer'

import FlashMessenger from '~/utils/FlashMessenger'
import './DeviceInvitation.css'

const DeviceInvitation = ({
  invitation,
  removePendingInvitation,
  activeDevice,
  user,
}) => {

  const [isLoadingDeleteInvitation, setIsLoadingDeleteInvitation] = useState(false)

  const { userEmail, createdAt, groupName, id } = invitation

  const isAuthorizedToRetract = Authorizer.isResourceAdmin(activeDevice, user.id)
    || Authorizer.hasApplicationAdminAccess(user)

  const formatedDate = Time.getTrimmedDateAndTimeString(createdAt, { seconds: false })

  const handleDeletePendingRequest = async invitationId => {
    try {
      setIsLoadingDeleteInvitation(true)
      await nexus.device.deletePendingInvitation({ deviceId: activeDevice.id, invitationId })
      removePendingInvitation(invitationId)
    } catch (err) {
      FlashMessenger.error(err.message)
      setIsLoadingDeleteInvitation(false)
    }
  }

  return (
    <Table.Row>
      <Table.Cell>
        {userEmail}
      </Table.Cell>
      <Table.Cell>
        {formatedDate}
      </Table.Cell>
      <Table.Cell>
        {groupName}
      </Table.Cell>
      <Table.Cell>
        <Button
          icon='times'
          loading={isLoadingDeleteInvitation}
          className='invitation-delete-button'
          onClick={() => handleDeletePendingRequest(id)}
          disabled={!isAuthorizedToRetract}
          aria-label='delete-invitation-btn'
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default DeviceInvitation
