/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Dropdown, Form, List, Menu, Popup } from 'semantic-ui-react'
import { Slot } from '@ospin/fct-graph'
import GraphTools from '~/utils/GraphTools'
import { Process } from '~/utils/process'
import PhaseProgression from '~/utils/process/PhaseProgression'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import CalibrationModal from '../modals/calibration/CalibrationModal'
import LineChartAnnotationModal from './LineChartAnnotationModal'
import FlashMessenger from '~/utils/FlashMessenger'
import callCreateDownloadRequest from '~/redux/thunks/process/callCreateDownloadRequest'
import DownloadRequest from '~/utils/process/DownloadRequest'

const mapDispatchToProps = dispatch => ({
  dispatchCallCreateDownloadRequest:
  (processId, reporterFctIds) => callCreateDownloadRequest(
    dispatch,
    DownloadRequest.TYPES.SENSOR_DATA,
    processId,
    reporterFctIds,
  ),
})

const BUTTONS = {
  PRINT: 'print',
  SYNC: 'sync',
  ANNOTATE: 'annotate',
  DOWNLOAD: 'download',
  CALIBRATE: 'calibrate',
}

const RUNNING_PROCESS_FRAME_OPTIONS = [
  {
    key: GraphTools.DISPLAY_MODES.LIVE,
    text: 'live',
    value: GraphTools.DISPLAY_MODES.LIVE,
  },
]
const FRAME_OPTIONS = [
  {
    key: GraphTools.DISPLAY_MODES.FULL,
    text: 'process',
    value: GraphTools.DISPLAY_MODES.FULL,
  },
  {
    key: GraphTools.DISPLAY_MODES.PHASE,
    text: 'phase',
    value: GraphTools.DISPLAY_MODES.PHASE,
  },
  {
    key: GraphTools.DISPLAY_MODES.DAY,
    text: 'day',
    value: GraphTools.DISPLAY_MODES.DAY,
  },
  {
    key: GraphTools.DISPLAY_MODES.HOUR8,
    text: '8h',
    value: GraphTools.DISPLAY_MODES.HOUR8,
  },
  {
    key: GraphTools.DISPLAY_MODES.HOUR,
    text: '1h',
    value: GraphTools.DISPLAY_MODES.HOUR,
  },
]

const ZOOM_OPTIONS = [
  { text: 'x-axis', value: 'x' },
  { text: 'y-axis', value: 'y' },
  { text: 'xy-axis', value: 'xy' },
]

const TIME_DISPLAY_OPTIONS = [
  { text: 'process time', value: 'processTime' },
  { text: 'date & time', value: 'dateTime' },
]

const generatePhaseOptions = activeProcess => {
  return activeProcess.progression.map(({ phaseId }) => {
    return {
      value: phaseId,
      text: PhaseGroupParser.getPhaseNameWithIterationIndicator(activeProcess, phaseId),
    }
  })
}

const getFrameButtonText = (rangeSetting, activeProcess) => {
  const { mode } = rangeSetting
  if (mode === GraphTools.DISPLAY_MODES.PHASE) {
    return PhaseGroupParser
      .getPhaseNameWithIterationIndicator(activeProcess, rangeSetting.selectedPhaseId)
  }
  if (mode === GraphTools.DISPLAY_MODES.CUSTOM) {
    return 'custom'
  }
  return [...FRAME_OPTIONS, ...RUNNING_PROCESS_FRAME_OPTIONS].find(({ key }) => key === mode).text
}

const shouldDisplayResetYAxis = (rangeSetting, yaxisIds) => {
  if (rangeSetting.mode !== GraphTools.DISPLAY_MODES.CUSTOM) { return false }

  return yaxisIds.some(id => {
    if (!rangeSetting[id]) {
      return false
    }
    return (rangeSetting[id].min !== null || rangeSetting[id].max !== null)
  })
}

function OspinLineChartControls({
  reporterData,
  rangeSetting,
  setRangeSetting,
  displaySettings,
  updateDisplaySettings,
  activeProcess,
  zoomMode,
  setZoomMode,
  includeZero,
  setIncludeZero,
  isAnnotating,
  setIsAnnotating,
  graphRef,
  setSyncRangeSettings,
  annotationModalProps,
  setAnnotationModalProps,
  activeDevice,
  lineChartFormat,
  dispatchCallCreateDownloadRequest,
}) {

  const frameOptions = Process.isRunningOrPaused(activeProcess)
    ? [...RUNNING_PROCESS_FRAME_OPTIONS, ...FRAME_OPTIONS]
    : FRAME_OPTIONS

  const frameOptionWithoutPhase = frameOptions
    .filter(({ key }) => key !== GraphTools.DISPLAY_MODES.PHASE)

  const [showRangeDropdown, setShowRangeDropdown] = useState(false)
  const [showCalibrationModal, setShowCalibrationModal] = useState(false)
  const [waitingForAPIResponse, setWaitingForAPIResponse] = useState(false)

  const downloadChannel = async () => {
    setWaitingForAPIResponse(true)
    try {
      await dispatchCallCreateDownloadRequest(activeProcess.id, reporterData
        .map(({ reporterFctId }) => reporterFctId))
      FlashMessenger.info('Your download will start once ready and will also be available in the "Downloads" section')
    } catch (e) {
      FlashMessenger.error('Your download request failed. If this issue persist, contact the OSPIN support team.')
    } finally {
      setWaitingForAPIResponse(false)
    }
  }
  function resetYAxis() {
    const nullYAxisObject = GraphTools.generateNullYLimit(reporterData)
    setRangeSetting({
      ...rangeSetting,
      ...nullYAxisObject,
    })
  }

  function updateSelectedPhasee(phaseId) {
    setShowRangeDropdown(false)

    setRangeSetting({
      mode: GraphTools.DISPLAY_MODES.PHASE,
      selectedPhaseId: phaseId,
    })
  }

  function updateRangeSetting(value) {
    const updatePayload = value === GraphTools.DISPLAY_MODES.PHASE
      ? { mode: value, selectedPhaseId: PhaseProgression.getLatest(activeProcess).phaseId }
      : { mode: value }
    setShowRangeDropdown(false)
    setRangeSetting(updatePayload)
  }

  function resetYAxis() {
    const nullYAxisObject = GraphTools.generateNullYLimit(reporterData)
    setRangeSetting({
      ...rangeSetting,
      ...nullYAxisObject,
    })
  }

  const escAnnotationButton = e => {
    if (isAnnotating) {
      if (e.key === 'Escape' || e.keyCode === 27) {
        setIsAnnotating(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escAnnotationButton)

    return () => {
      document.removeEventListener('keydown', escAnnotationButton)
    }
  })

  function print(type) {
    const chartInstance = graphRef.current
    const { ctx } = chartInstance
    ctx.save()

    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chartInstance.width, chartInstance.height);
    const base64Img = chartInstance.toBase64Image(`image/${ type }`)
    ctx.restore()

    const a = document.createElement('a')
    a.href = base64Img

    a.setAttribute('download', `${ activeProcess.name }-graph.${ type }`)
    a.setAttribute('data-testid', 'graph-image-download')
    a.click()
  }

  function sync() {
    const updatePayload = { mode: rangeSetting.mode }
    if (rangeSetting.mode === GraphTools.DISPLAY_MODES.CUSTOM) {
      updatePayload.x = rangeSetting.x
    }
    if (rangeSetting.mode === GraphTools.DISPLAY_MODES.PHASE) {
      updatePayload.selectedPhaseId = rangeSetting.selectedPhaseId
    }
    setSyncRangeSettings(updatePayload)

  }

  function renderWithPopup(popupTrigger, buttonParams) {
    return (
      <Popup
        trigger={(
          <Button
            style={{ marginLeft: '0.4rem' }}
            primary
            {...buttonParams}
          />
        )}
        content={popupTrigger}
      />
    )
  }

  function renderButton(type) {
    switch (type) {
      case BUTTONS.SYNC:
        return renderWithPopup(
          <span>Synchronize X-Axis</span>,
          { onClick: () => { sync() }, icon: 'sync' },
        )
      case BUTTONS.ANNOTATE: {
        return renderWithPopup(
          <span>Annotate</span>,
          { onClick: () => { setIsAnnotating(!isAnnotating) }, inverted: isAnnotating, icon: 'sticky note' },
        )
      }
      case BUTTONS.DOWNLOAD: {
        return renderWithPopup(
          <span>Download channel data</span>,
          { onClick: () => { downloadChannel() }, loading: waitingForAPIResponse, disabled: waitingForAPIResponse, icon: 'download' },
        )
      }

      case BUTTONS.CALIBRATE: {
        return renderWithPopup(
          <span>calibrate</span>,
          { onClick: () => { setShowCalibrationModal(true) }, icon: 'crosshairs' },
        )
      }

      case BUTTONS.PRINT: {
        return (
          <Dropdown style={{ width: '36px' }} button icon='print' className='ospin-line-chart-control-print-button'>
            <Dropdown.Menu>
              <Dropdown.Item content='jpeg' onClick={() => print('jpeg')} />
              <Dropdown.Item content='png' onClick={() => print('png')} />
            </Dropdown.Menu>
          </Dropdown>
        )
      }

      default:
        break;
    }
  }

  const { slot, fct: functionality, reporterFctId } = reporterData[0]

  const shouldDisplayYReset = shouldDisplayResetYAxis(rangeSetting, reporterData
    .map((({ fct, slot }) => GraphTools.generateAxisId(fct.id, slot.name))))

  function renderButtonsbyFormat() {
    switch (lineChartFormat) {
      case 'dataviewer':
        return (
          <Menu.Item>
            {renderButton(BUTTONS.PRINT)}
            {renderButton(BUTTONS.SYNC)}
            {renderButton(BUTTONS.ANNOTATE)}
            {renderButton(BUTTONS.DOWNLOAD)}
            {renderButton(BUTTONS.CALIBRATE)}
          </Menu.Item>
        )
      case 'dashboard':
        return (
          <Menu.Item>
            {renderButton(BUTTONS.PRINT)}
            {renderButton(BUTTONS.ANNOTATE)}
            {renderButton(BUTTONS.DOWNLOAD)}
          </Menu.Item>
        )
      default:
        return null
    }
  }

  function renderDisplayCheckbox(label, key) {
    return (
      <List.Item style={{ width: '100%' }}>
        <Form.Checkbox
          label={label}
          checked={displaySettings[key]}
          onChange={(_, { checked }) => updateDisplaySettings({ [key]: checked })}
        />
      </List.Item>
    )
  }

  return (
    <>
      {annotationModalProps.mode !== null
        ? (
          <LineChartAnnotationModal
            activeProcess={activeProcess}
            mode={annotationModalProps.mode}
            targetTimeStamp={annotationModalProps.targetTimeStamp}
            annotationId={annotationModalProps.annotationId}
            setAnnotationModalProps={setAnnotationModalProps}
            timeStampsMode={displaySettings.timeStampsMode}
          />
        ) : null}

      {showCalibrationModal
        ? (
          <CalibrationModal
            size='large'
            open={showCalibrationModal}
            closeHandler={() => setShowCalibrationModal(!showCalibrationModal)}
            headerText={`Calibration of ${ Slot.getDisplayName(slot) }`}
            slot={slot}
            functionality={functionality}
            activeProcess={activeProcess}
            activeDevice={activeDevice}
            reporterFctId={reporterFctId}
          />
        ) : null }

      <Menu text fluid widths={lineChartFormat === 'calibration' ? 5 : 6}>
        <Menu.Item>
          <Form.Dropdown
            label={<b>Time</b>}
            open={showRangeDropdown}
            onBlur={() => { setShowRangeDropdown(false) }}
            onClick={() => setShowRangeDropdown(!showRangeDropdown)}
            style={{ width: '100%', textAlign: 'end' }}
            button
            text={getFrameButtonText(rangeSetting, activeProcess)}
          >
            <Dropdown.Menu>
              {frameOptionWithoutPhase.map(opt => (
                <Dropdown.Item
                  key={opt.value}
                  value={opt.value}
                  active={rangeSetting.mode === opt.value}
                  onClick={(_, { value }) => updateRangeSetting(value)}
                  content={opt.text}
                />
              ))}
              <Dropdown item scrolling text='phase'>
                <Dropdown.Menu >
                  {generatePhaseOptions(activeProcess).map(({ value, text }) => (
                    <Dropdown.Item
                      key={value}
                      active={rangeSetting.mode === GraphTools.DISPLAY_MODES.PHASE
                        && rangeSetting.selectedPhaseId === value}
                      value={value}
                      onClick={(_, { value }) => updateSelectedPhasee(value)}
                      content={text}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Menu>
          </Form.Dropdown>

        </Menu.Item>
        <Menu.Item>
          <Form.Select
            fluid
            label={<b>Zoom Direction</b>}
            value={zoomMode}
            options={ZOOM_OPTIONS}
            onChange={(_, { value }) => setZoomMode(value)}
          />
        </Menu.Item>
        <Menu.Item>
          {shouldDisplayYReset
            ? <Button onClick={() => resetYAxis()}>Reset Y Axis</Button>
            : (
              <Form.Checkbox
                label='Include Zero'
                checked={includeZero}
                onChange={(_, { checked }) => setIncludeZero(checked)}
              />
            )}
        </Menu.Item>
        <Menu.Item>
          <Form.Select
            fluid
            label={<b>Display Time</b>}
            value={displaySettings.timeStampsMode}
            options={TIME_DISPLAY_OPTIONS}
            onChange={(_, { value }) => updateDisplaySettings({ timeStampsMode: value })}
          />
        </Menu.Item>
        <Menu.Item>
          <List>
            {renderDisplayCheckbox('Target Lines', 'showTargetLines')}
            {renderDisplayCheckbox('Phase Label', 'showPhaseLabel')}
            {renderDisplayCheckbox('Annotations', 'showAnnotations')}
          </List>
        </Menu.Item>
        {renderButtonsbyFormat()}
      </Menu>
    </>
  )
}

export default connect(null, mapDispatchToProps)(OspinLineChartControls)
