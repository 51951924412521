import { FCTGraph } from '@ospin/fct-graph'
import { replaceNoUnitCharacter } from '~/utils/units'
import DescriptionParser from '~/utils/process/DescriptionParser'
import Phase from '~/utils/process/Phase'
import Validator from '~/utils/validation/Validator'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import FeatureVersioning from '~/utils/FeatureVersioning'
import Device from '~/utils/Device'
import FirmwareUpdate from '~/utils/device/FirmwareUpdate'

export default class ProcessValidator extends Validator {

  static VALIDATION_MESSAGES = {
    EXCEEDING_PROCESS_SIZE_ERROR: 'The process is too large. Please reduce the number of phases and/or group cycles',
    MISMATCHING_CONFIGURATION: 'The process does not match the device\'s configuration and cannot be started',
  }

  static VALIDATION_ERRORS = {
    TRANSITION_ERROR: 'TRANSITION_ERROR',
    INPUT_VALUE_ERROR: 'INPUT_VALUE_ERROR',
    EXCEEDING_PROCESS_SIZE_ERROR: 'EXCEEDING_PROCESS_SIZE_ERROR',
    MISMATCHING_CONFIGURATION: 'MISMATCHING_CONFIGURATION',
    MISMATCHING_PORTS: 'MISMATCHING_PORTS',
    PROCESS_STARTING: 'PROCESS_STARTING',
    OUTDATED_PROCESS: 'OUTDATED_PROCESS',
    DEVICE_ALREADY_RUNNING: 'DEVICE_ALREADY_RUNNING',
    OFFLINE_DEVICE: 'OFFLINE_DEVICE',
    PENDING_FIRMWARE_UPDATE: 'PENDING_FIRMWARE_UPDATE',
    MISSING_FUNCTIONALITIES: 'MISSING_FUNCTIONALITIES',
  }

  static errorTypeExists(type) {
    return type in ProcessValidator.VALIDATION_ERRORS
  }

  static VALID_PROCESS_DATA = {
    nameLength: 45,
    commentLength: 500,
    phaseGroupNameLength: 64,
    minimumNameLength: 1,
  }

  static VALID_ANNOTATION_DATA = {
    textMaxLength: 512,
    textMinLength: 1,
  }

  static VALID_PHASE_DESCRIPTION_DATA = { phaseDescriptionLength: 500 }

  static isProcessNameMinLengthValid = string => (
    string.length < ProcessValidator.VALID_PROCESS_DATA.minimumNameLength
  )

  static isValidProcessName = string => (
    ProcessValidator
      .lessThanOrEqual(string.length, ProcessValidator.VALID_PROCESS_DATA.nameLength)
  )

  static isValidProcessComment = string => (
    ProcessValidator
      .lessThanOrEqual(string.length, ProcessValidator.VALID_PROCESS_DATA.commentLength)
  )

  static isValidAnnotationComment = string => (
    ProcessValidator
      .withinRange(
        string.length,
        [ProcessValidator.VALID_ANNOTATION_DATA.textMinLength,ProcessValidator.VALID_ANNOTATION_DATA.textMaxLength ])
  )

  static isAnnotationCommentMinimumLengthValid = string => (
    string.length < ProcessValidator.VALID_ANNOTATION_DATA.minimumLength
  )

  static createError(type, message, phaseName, groupName) {
    if (!ProcessValidator.errorTypeExists(type)) {
      throw new Error(`Unknown validation error type: ${type}`)
    }

    return {
      type,
      message: message || ProcessValidator.VALIDATION_MESSAGES[type],
      phaseName,
      groupName,
    }
  }

  static createExceedingProcessSizeError() {
    return ProcessValidator.createError('EXCEEDING_PROCESS_SIZE_ERROR')
  }

  static validateDescriptionMismatch(process, device) {
    if (ProcessValidator.isMismatchingDevice(process, device)) {
      const error = ProcessValidator
        .createError(ProcessValidator.VALIDATION_ERRORS.MISMATCHING_CONFIGURATION)
      return [ error ]
    }
    return []
  }

  static isMismatchingDevice(process, device) {
    return device.fctGraphs.every(fctGraph => fctGraph.id !== process.fctGraph.id)
  }

  static validateDescription(process, device) {
    const descriptionMismatchErrors = ProcessValidator.validateDescriptionMismatch(process, device)
    const finishingMethodErrors = ProcessValidator.validateFinishingMethod(process)
    const inputNodeValuesErrors = ProcessValidator
      .validateInputNodeValues(process, device)
    const portAssignmentErrors = ProcessValidator.validatePorts(process)

    return [
      ...finishingMethodErrors,
      ...inputNodeValuesErrors,
      ...descriptionMismatchErrors,
      ...portAssignmentErrors,
    ]
  }

  static validateFinishingMethod(process) {
    return DescriptionParser
      .getPhasesArray(process)
      .filter(phase => Phase.isTimeBased(phase) && Phase.getDuration(phase) <= 0)
      .map(phase => (
        ProcessValidator.createError(
          'TRANSITION_ERROR',
          'No duration time for time-based transition provided.',
          Phase.getName(phase),
          Phase.getGroupName(phase),
        )
      ))
  }

  static createWrongDataTypeError(slot, fct, phase, value, messageSuffix = '') {
    return ProcessValidator.createError(
      ProcessValidator.VALIDATION_ERRORS.INPUT_VALUE_ERROR,
      `Invalid target value ${value} in functionality ${fct.name} at channel ${slot.name}.${messageSuffix}`,
      Phase.getName(phase),
      Phase.getGroupName(phase),
    )
  }

  static createNumericValueOutOfLimitsError(slot, fct, phase, value) {
    const { min, max } = slot
    return ProcessValidator.createError(
      ProcessValidator.VALIDATION_ERRORS.INPUT_VALUE_ERROR,
      `Value in functionality ${fct.name} at slot ${slot.name} is out of limits.
        Provided ${value} ${replaceNoUnitCharacter(slot.unit, '')},
        limits are [${min} ${replaceNoUnitCharacter(slot.unit, '')},
        ${max} ${replaceNoUnitCharacter(slot.unit, '')}]`,
      Phase.getName(phase),
      Phase.getGroupName(phase),
    )
  }

  static validateFloat({ phase, value, slot, fct }) {
    if (!ProcessValidator.isNumber(value)) {
      return ProcessValidator.createWrongDataTypeError(slot, fct, phase, value, ' Expected Number.')
    }

    if (!ProcessValidator.withinRange(value, [slot.min, slot.max])) {
      return ProcessValidator.createNumericValueOutOfLimitsError(slot, fct, phase, value)
    }
  }

  static validateBoolean({ value, phase, slot, fct }) {
    if (!ProcessValidator.isBoolean(value)) {
      return ProcessValidator.createWrongDataTypeError(slot, fct, phase, value, ' Expected true or false.')
    }
  }

  static validateOneOf({ value, phase, slot, fct }) {
    if (!slot.selectOptions.includes(value)) {
      return ProcessValidator.createWrongDataTypeError(slot, fct, phase, value, ' Provided option is unknown.')
    }
  }

  static validateInteger({ value, phase, slot, fct }) {
    if (!Number.isInteger(value)) {
      return ProcessValidator.createWrongDataTypeError(slot, fct, phase, value, ' Expected integer.')
    }

    if (!ProcessValidator.withinRange(value, [slot.min, slot.max])) {
      return ProcessValidator.createNumericValueOutOfLimitsError(slot, fct, phase, value)
    }
  }

  static validateBySlotDataType(params) {
    const { slot: { dataType } } = params

    switch (dataType) {
      case 'boolean':
        return ProcessValidator.validateBoolean(params)
      case 'oneOf':
        return ProcessValidator.validateOneOf(params)
      case 'integer':
        return ProcessValidator.validateInteger(params)
      default:
        return ProcessValidator.validateFloat(params)
    }
  }

  static validatePhaseInputNodeValues(device, process, { id, data: phase }) {
    const inputNodeFctIds = Object.keys(Phase.getInputNodeValues(phase))
    const { fctGraph } = process

    return inputNodeFctIds
      .map(inputNodeFctId => {
        const slot = FCTGraph.getConnectingSinkSlot(fctGraph, inputNodeFctId)
        const fct = FCTGraph.getSinkFct(fctGraph, inputNodeFctId)
        const value = DescriptionParser.getInputNodeValue(process, id, inputNodeFctId)
        return ProcessValidator.validateBySlotDataType({
          phase, fct, value, slot,
        })
      })
      .filter(error => error)
  }

  static validateInputNodeValues(process, device) {
    const phases = DescriptionParser.getPhasesSequence(process)

    return phases.reduce((acc, phase) => (
      [ ...acc, ...ProcessValidator.validatePhaseInputNodeValues(device, process, phase) ]
    ), [])
  }

  static validatePhaseName(newPhaseName) {
    if (/'.*'/g.test(newPhaseName)) {
      throw new Error('phase name contains more than one single quote character')
    }
    if (/".*"/g.test(newPhaseName)) {
      throw new Error('phase name contains more than one double quote character')
    }
  }

  static isValidPhaseDescriptionLength = newPhaseDescription => (
    ProcessValidator
      .lessThanOrEqual(
        newPhaseDescription.length,
        ProcessValidator.VALID_PHASE_DESCRIPTION_DATA.phaseDescriptionLength,
      )
  )

  static validatePorts(process) {
    if (process.featureVersion >= 3 && !FunctionalityGraph.processPortsAreUpToDate(process)) {
      return [ProcessValidator.createError(
        this.VALIDATION_ERRORS.MISMATCHING_PORTS,
        'The port assignment of this process is outdated. You can synchronize them with the latest device configuration in the "Overview" tab.',
      )]
    }
    return []
  }

  static validateErrors(condition, error, message) {
    return condition ? [ProcessValidator.createError(this.VALIDATION_ERRORS[error], message)] : []
  }

  static isOutdatedProcess(process) {
    return ProcessValidator.validateErrors(
      process.outdated,
      'OUTDATED_PROCESS',
      'The process does not match the current device configuration anymore',
    )
  }

  static isDeviceOffline(activeDevice) {
    return ProcessValidator.validateErrors(
      Device.isOffline(activeDevice),
      'OFFLINE_DEVICE',
      'The device is offline',
    )
  }

  static getRunningFctGraphIds(activeDevice) {
    return activeDevice.runningProcesses.map(({ fctGraphId }) => fctGraphId)
  }

  static isActiveGraphRunning(activeDevice, process) {
    const { fctGraph } = process
    const runningProcessesFctGraphIds = ProcessValidator.getRunningFctGraphIds(activeDevice)
    return runningProcessesFctGraphIds.includes(fctGraph.id)
  }

  static isBlockingGraphsRunningProcesses(activeDevice, process) {
    const { fctGraph } = process
    const runningProcessesFctGraphIds = ProcessValidator.getRunningFctGraphIds(activeDevice)

    const otherRunningGraphs = activeDevice.fctGraphs.filter(
      graph => runningProcessesFctGraphIds.includes(graph.id) && graph.id !== fctGraph.id,
    )

    const blockingFctGraphs = FunctionalityGraph
      .findGraphsWithOverlappingPhysicalFcts(fctGraph, otherRunningGraphs)

    return blockingFctGraphs.length > 0
  }

  static isDeviceAlreadyRunning(activeDevice, process) {
    const isSingleProcessAndRunning = !FeatureVersioning.supportsMultipleProcesses(activeDevice)
      && activeDevice.runningProcesses.length > 0

    const isMultipleProcessesWithBlockingGraphs = FeatureVersioning
      .supportsMultipleProcesses(activeDevice)
      && ProcessValidator.isBlockingGraphsRunningProcesses(activeDevice, process)

    const isMultipleProcessesWithActiveGraphRunning = FeatureVersioning
      .supportsMultipleProcesses(activeDevice)
      && ProcessValidator.isActiveGraphRunning(activeDevice, process)

    const activeMessage = isSingleProcessAndRunning || isMultipleProcessesWithActiveGraphRunning
      ? 'The device is already running a process'
      : 'The required devices are used in another setup'

    return ProcessValidator.validateErrors(
      (isSingleProcessAndRunning
        || isMultipleProcessesWithBlockingGraphs
        || isMultipleProcessesWithActiveGraphRunning),
      'DEVICE_ALREADY_RUNNING',
      activeMessage,
    )
  }

  static isPendingFirmwareUpdated(activeDevice) {
    const { firmwareUpdate } = activeDevice

    return ProcessValidator.validateErrors(
      firmwareUpdate && FirmwareUpdate.isInitiated(firmwareUpdate),
      'PENDING_FIRMWARE_UPDATE',
      'The device is currently updating its firmware',
    )
  }

  static validateRequiredFunctionalities(process, activeDevice) {
    const { fctGraph } = process
    if (!FeatureVersioning.supportsMultipleProcesses(activeDevice)) return []

    return ProcessValidator.validateErrors(
      !FunctionalityGraph.deviceCanExecuteGraph(fctGraph, activeDevice),
      ProcessValidator.VALIDATION_ERRORS.MISSING_FUNCTIONALITIES,
      'The process is missing functionalities',
    )
  }

  static validateStartProcess(process, device) {
    const outdatedProcess = ProcessValidator.isOutdatedProcess(process)
    const offlineDevice = ProcessValidator.isDeviceOffline(device)
    const deviceAlreadyRunning = ProcessValidator.isDeviceAlreadyRunning(device, process)
    const pendingFirmwareUpdated = ProcessValidator.isPendingFirmwareUpdated(device)
    const validateRequiredFunctionalities = ProcessValidator
      .validateRequiredFunctionalities(process, device)

    return [
      ...outdatedProcess,
      ...offlineDevice,
      ...deviceAlreadyRunning,
      ...pendingFirmwareUpdated,
      ...validateRequiredFunctionalities,
    ]
  }
}
