import { findAndUpdate } from '~/redux/helper/updateFunctions'
import DataManager from '~/utils/DataManager'

export default (state, action) => {
  const { data: { sensorData, processId } } = action.payload
  if (!sensorData) return state

  const { processes } = state

  const updateFn = targetProcess => DataManager.appendManySensorsData(targetProcess, sensorData)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
