import nexus from '@ospin/nexus'
import { setSession } from '~/redux/actions/actions'
import setCognitoIdentityId from '~/redux/thunks/helper/setCognitoIdentityId'
import { getDeploymentEnv } from '~/config/envDetector'
import createPusherStateChangeHandler from '~/redux/pusher/createPusherStateChangeHandler'
import userChannelHandler from '~/redux/pusher/userChannel/userChannelHandler'
import { OspinPusherClient, UserPusherChannel } from '@ospin/pusher'

export default async dispatch => {
  try {
    const { data: session } = await nexus.auth.getCurrentSession()
    if (session) {
      const userSub = session.accessToken.payload.sub
      const { data: userData } = await nexus.user.get(userSub)

      dispatch(setSession({ authenticated: true, userData }))

      await Promise.all([
        setCognitoIdentityId(userData, userSub, dispatch),
        OspinPusherClient
          .connect({
            env: getDeploymentEnv(),
            userId: userSub,
            authUserSubscriptions: nexus.user.pusher.subscriptions.authorizeMany,
            authDeviceSubscriptions: nexus.user.pusher.subscriptions.device.authorizeMany,
            authDeviceProcessSubscriptions: nexus.user.pusher.subscriptions.device.process
              .authorizeMany,
          }),
      ])

      OspinPusherClient
        .registerConnectionEvent('state_change', createPusherStateChangeHandler(dispatch))
      UserPusherChannel.subscribe({ userId: userData.id }, userChannelHandler(userData.id))

      return
    }
  } catch (_) {}
  dispatch(setSession({ authenticated: false, userData: null }))
}
