import nexus from '@ospin/nexus'
import { updateProcess } from '~/redux/actions/actions'

const callSetSensorCalibration = async (dispatch, process, fctId, slotName, params) => {
  const { fctGraphId, deviceId } = process
  await nexus.device.functionality.calibration
    .update(
      deviceId,
      fctGraphId,
      fctId,
      { slotName, calibrationData: { params } },
    )
  const { data: { calibrations } } = await nexus.process.get(process.id)

  dispatch(updateProcess({ processId: process.id, params: { calibrations } }))
}

export default callSetSensorCalibration
