import React from 'react'
import { Form, Label, Input } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'
import ProcessValidator from '~/utils/validation/ProcessValidator'

const { VALID_PROCESS_DATA: { nameLength } } = ProcessValidator

const CloneProcessModalForm = ({
  newName,
  handleUpdateName,
  isDisabled,
}) => (
  <Form.Field error={!ProcessValidator.isValidProcessName(newName)}>
    <label>
      Cloned Process Name
    </label>
    <Input
      fluid
      placeholder='cloned process name...'
      value={newName}
      onChange={handleUpdateName}
      disabled={isDisabled}
    />
    <Label>
      {newName.length}
      /
      {nameLength}
    </Label>
  </Form.Field>
)

export default formalize(CloneProcessModalForm)
