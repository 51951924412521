import React from 'react'
import nexus from '@ospin/nexus'
import { Container, Table, Message, Loader } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import ObjectTools from '~/utils/ObjectTools'
import { calculateSkippedItems } from '~/utils/pagination'
import {
  setQueryParametersOnPageLoad,
  getQueryParams,
} from '~/utils/query'
import LogViewQueryBar from './LogViewQueryBar'
import LogList from './LogList'
import LogViewTableControls from './LogViewTableControls'
import './LogView.css'

const NON_QUERY_STRING_DEFAULTS = { limit: '50' }

const QUERY_STRING_DEFAULTS = { skip: '0' }

const OPTIONAL_QUERY_STRING_PARAMETERS = [
  'levelQuery',
  'subjectQuery',
  'textQuery',
  'timeRefEndMS',
  'timeRefStartMS',
]

const QUERY_STRING_PARAMETERS = [
  ...Object.keys(QUERY_STRING_DEFAULTS),
  ...OPTIONAL_QUERY_STRING_PARAMETERS,
]

class LogView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      logs: [],
      subjects: [],
      totalMatchingLogs: 0,
      isLoading: false,
      error: null,
    }
  }

  async componentDidMount() {

    const { location, history } = this.props
    setQueryParametersOnPageLoad(location, history, QUERY_STRING_DEFAULTS)
    await this.loadLogs()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    const prevQuery = new URLSearchParams(prevProps.location.search)
    const currentQuery = new URLSearchParams(location.search)

    const isInitialSearchSet = Object.keys(QUERY_STRING_DEFAULTS).every(
      key => prevQuery.get(key) === null && prevQuery.get(key) !== currentQuery.get(key),
    )

    const changedKeys = QUERY_STRING_PARAMETERS.filter(
      key => prevQuery.get(key) !== currentQuery.get(key),
    )

    const skipFetchLogs = (isInitialSearchSet || changedKeys.length === 0)

    if (skipFetchLogs) { return }

    this.loadLogs()
  }

  updateQueryParameters = keyValuePairs => {
    const { location, history } = this.props
    const query = new URLSearchParams(location.search)

    keyValuePairs.forEach(({ key, value }) => {
      if (value === null) {
        query.delete(key)
      } else {
        query.set(key, value)
      }
    })

    history.replace(`?${query.toString()}`)
  }

  updateActivePage = (_, { activePage }) => {
    const skipValue = calculateSkippedItems(activePage, NON_QUERY_STRING_DEFAULTS.limit)
    this.updateQueryParameters([{ key: 'skip', value: skipValue }])
  }

  generateQuery = () => {
    const { location, match: { params: { deviceId, processId } } } = this.props
    const query = new URLSearchParams(location.search)

    const baseQuery = processId ? { processId } : { deviceId }
    const queryWithUndefined = {
      ...baseQuery,
      endTime: query.get('timeRefEndMS'),
      startTime: query.get('timeRefStartMS'),
      subject: query.get('subjectQuery'),
      level: query.get('levelQuery'),
      textQuery: query.get('textQuery'),
      skip: query.get('skip'),
      limit: NON_QUERY_STRING_DEFAULTS.limit,
    }

    return ObjectTools.removeKeysWithFalsyValuesOrEmptyArrays(queryWithUndefined)
  }

  async loadLogs() {
    this.setState({ isLoading: true })
    const query = this.generateQuery()
    try {
      const { data: { logs, totalNumberOfLogs, subjects } } = await nexus.log.list(query)
      this.setState({
        isLoading: false,
        subjects,
        totalMatchingLogs: totalNumberOfLogs,
        logs,
        error: null,
      })
    } catch ({ message }) {
      this.setState({ isLoading: false, error: message })
    }
  }

  renderTable = () => {
    const { logs } = this.state
    const HEADERS = ['Level', 'Subject', 'Message', 'Timestamp']

    return (
      <Table celled padded className='ospin-red'>
        <Table.Header>
          <Table.Row>
            {HEADERS.map(header => <Table.HeaderCell key={header}>{header}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>
        <LogList displayedLogs={logs} />
      </Table>
    )
  }

  renderBody = () => {
    const { isLoading, totalMatchingLogs, error } = this.state
    const { location } = this.props
    const { skip } = getQueryParams(location, QUERY_STRING_DEFAULTS, QUERY_STRING_PARAMETERS)

    const parsedLimit = parseInt(NON_QUERY_STRING_DEFAULTS.limit, 10)
    const parsedSkip = parseInt(skip, 10)

    const activePage = Math.floor(parsedSkip / parsedLimit) + 1

    if (isLoading) {
      return <Loader style={{ marginTop: '10rem' }} size='big' active inline='centered' />
    }

    if (error) {
      return <Message error content={error} />
    }

    if (totalMatchingLogs === 0) {
      return <Message info content='No logs found' />
    }

    return (
      <>
        <LogViewTableControls
          activePage={activePage}
          totalMatchingLogs={totalMatchingLogs}
          logsPerPage={parseInt(NON_QUERY_STRING_DEFAULTS.limit,10)}
          updateActivePage={this.updateActivePage}
        />
        {this.renderTable()}
      </>
    )
  }

  render() {
    const {
      error,
      subjects,
    } = this.state

    if (error) {
      return <Message error content={error} />
    }

    return (
      <Container fluid>
        <LogViewQueryBar subjects={subjects} updateQueryParameters={this.updateQueryParameters} />
        {this.renderBody()}

      </Container>
    )
  }
}

export default withRouter(LogView)
