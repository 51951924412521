import { Functionality, Ports, FCTGraph } from '@ospin/fct-graph'

export const isIdenticalMapping = (mapA, mapB) => (
  mapA.functionalities.length === mapB.functionalities.length
    && mapA.functionalities.every(fctMapping => {
      const matchingMap = mapB.functionalities.find(mapBfct => mapBfct.id === fctMapping.id)
      return Ports.areEqual(fctMapping.ports, matchingMap.ports)
    })
)

export const isPossiblePhysicalMapping = (activeDevice, fctGraph, mapping) => {
  const { connectedPhysicalFunctionalities: physFcts } = activeDevice
  const potentialUpdatedGraph = { ...fctGraph, functionalities: fctGraph.functionalities.map(fct => ({
    ...fct,
    ports: mapping[fct.id]
  })) }

  const physFct = potentialUpdatedGraph.functionalities.filter(Functionality.isPhysical)
  const containsAll = physFct.length === FCTGraph.getPhysicalFcts(fctGraph).length

  const allPortsUnique = physFct.length
    === [ ...new Set(physFct.map(fct => Ports.getId(fct.ports))) ].length

  const hasAllDevicesOnRequestedPorts = physFct.every(({ id, ports }) => {
    const fct = FCTGraph.getFctById(fctGraph, id)
    const { subType } = fct
    return physFcts
      .some(physFct => physFct.subType === subType && Ports.areEqual(physFct.ports, ports))
  })

  return containsAll && allPortsUnique && hasAllDevicesOnRequestedPorts
}
