import React from 'react'
import { FCTGraph } from '@ospin/fct-graph'
import { Container, Accordion, Ref, Button, Grid } from 'semantic-ui-react'
import { DragDropContext, Droppable, useMouseSensor, useTouchSensor } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { Process } from '~/utils/process'
import GraphTools from '~/utils/GraphTools'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'

import NoDataMessage from '~/components/utility/NoDataMessage'
import Functionality from './Functionality'
import DownloadProcessDatapointsModal from './DownloadProcessDatapointsModal'

const mapStateToProps = state => ({ user: state.user })

class DataGraphs extends React.Component {
  constructor(props) {
    super(props)

    const { user, activeProcess } = this.props

    this.state = {
      waitingForAPIResponse: false,
      openModal: false,
    }
  }

  renderDataViewerButtons = waitingForAPIResponse => (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Button.Group
        style={{ marginRight: '15px' }}
      >
        <Button
          className='process-viewer-button'
          compact
          onClick={this.foldAllFunctionalities}
        >
          Collapse All
        </Button>
        <Button
          className='process-viewer-button'
          compact
          onClick={this.expandAllFunctionalities}
        >
          Expand All
        </Button>
        <Button
          style={{ marginRight: '0px' }}
          className='process-viewer-button'
          loading={waitingForAPIResponse}
          disabled={waitingForAPIResponse}
          primary
          compact
          onClick={() => this.setState({ openModal: true })}
        >
          Download Data
        </Button>
      </Button.Group>
    </div>
  )

  foldAllFunctionalities = () => {
    UserFctGraphUIConfig.toggleAllGraphsFcts(true)
  }

  expandAllFunctionalities = () => {
    UserFctGraphUIConfig.toggleAllGraphsFcts(false)
  }

  foldGraphFunctionality = fctId => {
    UserFctGraphUIConfig.toggleGraphFctFold(fctId)
  }

  foldGraphFunctionalitySlot = (slotName, fctId) => {
    UserFctGraphUIConfig.toggleGraphFctSlotFold(fctId, slotName)
  }

  onDragEnd = result => {
    const { type, destination, source } = result
    if (!destination || destination.droppableId !== source.droppableId) {
      return
    }

    if (type && type.type === 'functionality') {
      UserFctGraphUIConfig.moveGraphsFct(source.index, destination.index)
    }
  }

  onSlotDrag = (result, fctId) => {

    const { type, destination, source } = result
    if (!destination || destination.droppableId !== source.droppableId) {
      return
    }

    if (type && type.type === 'slot') {
      UserFctGraphUIConfig.moveGraphFctSlot(fctId, source.index, destination.index)
    }
  }


  render() {

    const {
      activeProcess,
      activeDevice,
      user,
    } = this.props

    const {
      waitingForAPIResponse,
    } = this.state

    const { fctGraphUIConfig } = user
    const { configs: { graphs: { functionalities: fctsUIConfigs } } } = fctGraphUIConfig
    const { fctGraph } = activeProcess
    const { openModal } = this.state

    if (Process.isExecutable(activeProcess)) return <NoDataMessage text='Process has not been started' />

    return (
      <Container fluid>
        <DragDropContext onDragEnd={this.onDragEnd} sensors={[useMouseSensor, useTouchSensor]}>
          <Droppable
            droppableId='graph-group-id'
            type={{ type: 'functionality' }}
          >
            {
              provided => (
                <Ref innerRef={provided.innerRef}>
                  <Grid>
                    <Grid.Row style={{ paddingTop: '8px', paddingBottom: '0px', justifyContent: 'flex-end' }}>
                      {this.renderDataViewerButtons(
                        waitingForAPIResponse,
                      )}
                      <DownloadProcessDatapointsModal
                        headerText='Download Data Points'
                        closeHandler={() => this.setState({ openModal: false })}
                        closeOnSubmit
                        open={openModal}
                        fctGraph={fctGraph}
                        fctGraphUIConfig={fctGraphUIConfig}
                        activeProcess={activeProcess}
                      />
                    </Grid.Row>
                    <Grid.Row style={{ padding: '8px 0px' }}>
                      <Accordion
                        {...provided.droppableProps}
                        fluid
                        styled
                        exclusive={false}
                      >
                        {fctsUIConfigs.map((fctUIConfig, i) => {
                          const fct = FCTGraph.getFctById(fctGraph, fctUIConfig.fctId)

                          return (
                            <Functionality
                              key={fct.id}
                              clickHandler={this.handleClick}
                              index={i}
                              functionality={fct}
                              fctUIConfig={fctUIConfig}
                              toggleFold={() => this.foldGraphFunctionality(fct.id)}
                              toggleSlotFold={slotName => this.foldGraphFunctionalitySlot(slotName, fct.id)}
                              activeProcess={activeProcess}
                              activeDevice={activeDevice}
                              handleOnDrag={result => this.onSlotDrag(result, fct.id)}
                            />
                          )
                        })}
                        {provided.placeholder}
                      </Accordion>
                    </Grid.Row>
                  </Grid>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>
      </Container>
    )
  }
}

export default connect(mapStateToProps)(DataGraphs)
