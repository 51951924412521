import { OspinPusherClient } from '@ospin/pusher'

export function getSubscribedChannelNames() {
  return OspinPusherClient.client.allChannels().map(channel => channel.name)
}

export function isAlreadySubscribedToChannel(channel, channelParams) {
  const subscribedChannelNames = getSubscribedChannelNames()
  const channelName = channel.generateChannelName(channelParams)
  return subscribedChannelNames.includes(channelName)
}
