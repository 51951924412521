import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion, Button, Icon, Divider } from 'semantic-ui-react'
import GatewayDisplayedFcts from '~/utils/partners/GatewayDisplayedFcts'
import UserUIConfig from '~/utils/UIConfig/UserUIConfig'
import AggregatedGraphsWidgets from './AggregatedGraphsWidgets'
import FunctionalityGraphWidget from './FunctionalityGraphWidget'
import NoGraphMessage from './NoGraphmessage'
import './GraphsSection.css'
import './GatewayWidget.css'

const mapStateToProps = ({ user }) => ({ user })

function renderGraphWidgets(graphsToRender, device) {
  return graphsToRender.map(fctGraph => (
    <FunctionalityGraphWidget
      key={fctGraph.id}
      device={device}
      fctGraph={fctGraph}
    />
  ))
}

function GraphsSection(props) {
  const {
    user,
    device,
    fctGraphsType,
    match: { params: { deviceId: deviceIdMatchParams } },
  } = props

  const { id: deviceId, fctGraphs } = device

  const {
    fctGraphsWithMultiplePhysicalFcts,
    fctGraphsWithSinglePhysicalFct,
  } = GatewayDisplayedFcts.getFilteredPhysicalFctsGraphs(fctGraphs)

  const { uIConfig } = user

  const viewType = GatewayDisplayedFcts.getViewType(deviceIdMatchParams)

  const { [viewType]: { viewOffline, viewAggregate, elements } } = UserUIConfig
    .getDeviceConfig(uIConfig, deviceId)

  const { title } = GatewayDisplayedFcts.CONSTANTS[fctGraphsType]

  const renderableCoreFctGraphs = GatewayDisplayedFcts.getDisplayedFctGraphs(
    fctGraphsWithSinglePhysicalFct,
    device,
    viewOffline,
  )

  const renderableSolutionsFctGraphs = GatewayDisplayedFcts.getDisplayedFctGraphs(
    fctGraphsWithMultiplePhysicalFcts,
    device,
    viewOffline,
  )

  const rendarablefFctGraphs = fctGraphsType === GatewayDisplayedFcts
    .CONSTANTS.singleFctView.uiConfigKey ? renderableCoreFctGraphs : renderableSolutionsFctGraphs

  const generateCssClass = () => (
    `${elements[fctGraphsType].index === 0 ? 'first' : 'second'}-divider-${viewType}-view`
  )

  const onClickTitleHandler = () => (
    UserUIConfig.setCollapsedValue(deviceId, uIConfig, fctGraphsType, viewType)
  )

  const onClickSwitchPositionsHandler = () => (
    UserUIConfig.swapElementPositions(deviceId, uIConfig, fctGraphsType, viewType)
  )

  return (
    <div style={{ position: 'relative' }}>
      <Divider className={generateCssClass()} />
      <Accordion.Title
        active={!elements[fctGraphsType].collapsed}
        onClick={onClickTitleHandler}
        className='gateway-widget-accordion-title'
      >
        <Icon name='dropdown' />
        {title}
      </Accordion.Title>

      <Button
        compact
        className='swap-position-button'
        onClick={onClickSwitchPositionsHandler}
      >
        <Icon name={elements[fctGraphsType].index === 0 ? 'arrow down' : 'arrow up'} />
      </Button>

      <Accordion.Content
        active={!elements[fctGraphsType].collapsed}
      >
        <div className='gateway-widget-configurations-container'>
          { viewAggregate
            ? (
              <AggregatedGraphsWidgets
                fctGraphs={rendarablefFctGraphs}
                device={device}
                messageString={GatewayDisplayedFcts.CONSTANTS[fctGraphsType].messageString}
              />
            )
            : renderGraphWidgets(rendarablefFctGraphs, device)}
          {!rendarablefFctGraphs.length ? (
            <NoGraphMessage
              noGraphMsgClassName='no-graph-msg-widget-device-categories'
              messageString={`${GatewayDisplayedFcts.CONSTANTS[fctGraphsType].messageString}s`}
            />
          ) : null}
        </div>
      </Accordion.Content>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(GraphsSection))
