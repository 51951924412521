import { Functionality, Ports } from '@ospin/fct-graph'
import React from 'react'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import { Card, Image, Divider, Popup } from 'semantic-ui-react'
import defaultImage from '~/images/bioreactor_online_cropped.png'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Device from '~/utils/Device'
import DeviceProcess from './DeviceProcess'
import './DeviceWidget.css'
import './FunctionalityGraphWidget.css'
import WidgetStatusIndicator from './WidgetStatusIndicator'

const FCT_GRAPH_WIDGET_FCT_NAME_MIN_LEN = 57

const mapStateToProps = ({ user }) => ({ user })

const getImageClass = (device, fctGraph) => {
  const base = 'device-widget-image'
  const faded = `${base} ${base}--faded`
  if (Device.isOffline(device)) return faded
  return FunctionalityGraph.deviceCanExecuteGraph(fctGraph, device) ? base : faded
}

const getImageSource = fctGraph => (
  fctGraph.imageURL || defaultImage
)

const renderPorts = fctGraph => {
  const physFcts = fctGraph.functionalities.filter(Functionality.isPhysical)
  const maxDisplayedPorts = 6
  return (
    <div className='functionality-graph-widget-port-container'>
      {physFcts.slice(0, maxDisplayedPorts).map(physFct => (
        <span
          className='functionality-graph-widget-port-indicator'
          key={physFct.id}
        >
          {Ports.getId(physFct.ports)}
        </span>
      ))}
      {physFcts.length > maxDisplayedPorts
        ? (
          <span className='functionality-graph-widget-port-indicator' >
            ...
          </span>
        )
        : null}
    </div>
  )
}

function FunctionalityGraphWidget(props) {
  const { device, fctGraph } = props
  const runningProcess = device.runningProcesses.find(rp => rp.fctGraphId === fctGraph.id)

  return (
    <Card className='functionality-graph-widget'>
      <Link to={`/devices/${device.id}/configurations/${fctGraph.id}/processes`}>
        {renderPorts(fctGraph)}
        <Card.Content className='device-widget-header fct-graph-widget-header-height'>
          <Card.Meta className='device-widget-meta-style'>&nbsp;</Card.Meta>
          <Card.Header className='functionality-graph-widget-name overflow-ellipsis'>
            <Popup
              content={fctGraph.name}
              disabled={fctGraph.name.length < FCT_GRAPH_WIDGET_FCT_NAME_MIN_LEN}
              trigger={
                <span>{fctGraph.name}</span>
              }
            />
          </Card.Header>
          <Card.Meta className='device-widget-meta-style'>&nbsp;</Card.Meta>
        </Card.Content>
        <Divider className='fct-graph-widget-divider' />
        <div className='device-widget-image-frame'>
          <Image
            className={getImageClass(device, fctGraph)}
            centered
            src={getImageSource(fctGraph)}
          />
        </div>
      </Link>
      {
        runningProcess
          ? (
            <DeviceProcess
              device={device}
              runningProcessId={runningProcess.id}
              fctGraphId={fctGraph.id}
              redirectsToConfiguration
            />
          )
          : (<WidgetStatusIndicator device={device} fctGraphId={fctGraph.id} />)
      }
    </Card>
  )
}

export default withRouter(connect(mapStateToProps)(FunctionalityGraphWidget))
