import React from 'react'
import { Segment } from 'semantic-ui-react'

import { Process } from '~/utils/process'
import StartProcessButton from './StartProcessButton'
import StopProcessButton from './StopProcessButton'
import ExecutedProcessButtons from './ExecutedProcessButtons'
import RunningPhaseDetails from './RunningPhaseDetails'

const ProcessControl = ({
  toggleModal,
  activeProcess,
  activeDevice,
}) => (
  <Segment compact style={{ marginTop: '0px' }}>
    {(!Process.isExecutable(activeProcess))
      && (
        <RunningPhaseDetails
          activeProcess={activeProcess}
        />
      )}
    <div className='control-process-box-segment'>
      {
        Process.isExecutable(activeProcess) ? (
          <StartProcessButton
            toggleModal={toggleModal}
            activeProcess={activeProcess}
            activeDevice={activeDevice}
          />
        )
          : (
            <StopProcessButton
              activeDevice={activeDevice}
              toggleModal={toggleModal}
            />
          )
      }
      <ExecutedProcessButtons
        disableAllButtons={Process.isExecutable(activeProcess)}
        isPaused={Process.isPaused(activeProcess)}
        toggleModal={toggleModal}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
      />
    </div>
  </Segment>
)

export default ProcessControl
