import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Container,
  Accordion,
  Icon,
} from 'semantic-ui-react'
import ErrorPage from './ErrorPage'

const RenderStackTrace = ({ errorString, lineNumber, functionName, fileName }) => {
  const [active, displayStackTrace] = useState(false)
  const handleClick = () => displayStackTrace(!active)

  return (
    <Accordion styled fluid>
      <Accordion.Title
        active={active}
        onClick={handleClick}
      >
        <Icon name='dropdown' />
        Stack Trace
      </Accordion.Title>
      <Accordion.Content active={active} style={{ whiteSpace: 'pre-wrap' }}>
        {errorString}
        <br />
        {`\nError in\n \n FileName: ${fileName}\n Function: ${functionName} at line: ${lineNumber}\n`}
      </Accordion.Content>
    </Accordion>
  )
}

const Report = props => {
  const { location: { errorObject }, history } = props
  return (
    <Container>
      <ErrorPage history={history} errorCode={500} />
      {errorObject != null ? RenderStackTrace(errorObject) : ''}
    </Container>
  )
}

export default withRouter(Report)
