import React, { useState } from 'react'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import nexus from '@ospin/nexus'
import modalize from '~/components/utility/modal/modalize'
import Validator from '~/utils/validation/Validator'
import FlashMessenger from '~/utils/FlashMessenger'
import ClaimLicenceModalForm from './ClaimLicenceModalForm'
import { Button, Divider } from 'semantic-ui-react'

const ERROR_MESSAGE_TO_REQUEST_DEVICE_ID = 'No device provided'

const LICENCE_KEY_QUERY_PARAM_KEY = 'claimLicenceKey'

function removeQueryParamIfNecessary(location, history) {
  const queryParams = new URLSearchParams(location.search)
  if (queryParams.has(LICENCE_KEY_QUERY_PARAM_KEY)) {
    queryParams.delete(LICENCE_KEY_QUERY_PARAM_KEY)
    history.replace({ search: queryParams.toString() })
  }
}

// MIGRATION WARNING: New sold licences will be pre-assigned to a device
// If that is the case only a licence key is needed, for older licences a deviceId is also required
// once we can either associate all licenses or they have been claimed we can remove this
function ClaimLicenceModal({ queryStringLicenceKey, addLicence, closeHandler, user }) {

  const [ requiresDeviceId, setRequiresDeviceId ] = useState(false)
  const [licenceKey, setLicenceKey] = useState(queryStringLicenceKey)
  const [deviceId, setDeviceId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [deviceIsInitiatingUpdate,setDeviceIsInitiatingUpdate ] = useState(false)

  const isValidDeviceId = Validator.isUUIDv4(deviceId)

  const isValidForSubmit = licenceKey.length && (isValidDeviceId || !requiresDeviceId)

  const location = useLocation()
  const history = useHistory()

  const submitHandler = async () => {
    setIsLoading(true)
    try {
      const params = requiresDeviceId ? { key: licenceKey, deviceId } : { key: licenceKey }

      const { data: { licence, firmwareUpdateStarted } } = await nexus.user.licence.add({
        userId: user.id,
        params,
      })

      removeQueryParamIfNecessary(location, history)
      addLicence(licence)

      if (!firmwareUpdateStarted) {
        FlashMessenger.success('Success! Your licence has been succesfully claimed')
        closeHandler()
      } else {
        setDeviceIsInitiatingUpdate(true)
      }

    } catch ({ message }) {
      if (message.includes(ERROR_MESSAGE_TO_REQUEST_DEVICE_ID)) {
        setRequiresDeviceId(true)
      } else {
        setIsError(true)
        setErrorMessage(message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  if (deviceIsInitiatingUpdate) {
    return (
      <>
        We are updating your device to the newest firmware. This might take a while.
        You can still create processes but wont be able to start them until the device has updated.
        <Divider />
        <Button content='Got It' fluid onClick={closeHandler} />
      </>
    )
  }

  return (
    <ClaimLicenceModalForm
      licenceKey={licenceKey}
      setLicenceKey={setLicenceKey}
      deviceId={deviceId}
      setDeviceId={setDeviceId}
      loading={isLoading}
      addLicence={addLicence}
      closeHandler={closeHandler}
      requiresDeviceId={requiresDeviceId}
      user={user}
      submitHandler={submitHandler}
      disableSubmit={!isValidForSubmit}
      isError={isError}
      errorMessage={errorMessage}
    />
  )
}

export default withRouter(modalize(ClaimLicenceModal))
