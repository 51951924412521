import CalibrationMode from '~/utils/calibration/CalibrationMode'
import DataManager from '~/utils/DataManager'
import { linear } from '~/utils/math'

class SlopeOnlyMode extends CalibrationMode {

  static calculateParams(componentState) {

    const { pointSelectionSlope } = componentState
    const { slopeRangeAvrgReal, slopeRangeReference } = pointSelectionSlope

    const parsedSlopeRangeReference = parseFloat(slopeRangeReference)

    if (Number.isNaN(parsedSlopeRangeReference)) {
      return { error: ('Slope is not a number.') }
    }

    const p1 = [0, 0]
    const p2 = [slopeRangeAvrgReal, parsedSlopeRangeReference]
    const slope = linear.slope(p1, p2)
    const offset = linear.offset(p1, p2)

    return { offset, slope }
  }

  static selectParams({ slope }) {
    return { slope: { value: slope } }
  }

  static getNextField(currField) {
    const nextFields = { startTime: 'endTime' }

    return nextFields[currField]
  }

  static getUpdatedPointSelection(componentState, time) {
    const { pointSelectionSlope, selectedField } = componentState

    if (selectedField === 'endTime'
      && pointSelectionSlope.startTime !== '') {
      if (time <= pointSelectionSlope.startTime) {
        return
      }
    }

    if (selectedField === 'startTime'
      && pointSelectionSlope.endTime !== '') {
      if (time >= pointSelectionSlope.endTime) {
        return
      }
    }

    const nextField = this.getNextField(selectedField)
    return {
      params: {
        pointSelectionSlope: {
          ...pointSelectionSlope,
          [selectedField]: time,
        },
      },
      nextField,
    }
  }

  static getUpdatedAverage(componentState, componentProps, rawData) {
    const { slot, functionality, activeProcess } = componentProps
    const { pointSelectionSlope } = componentState

    const {
      startTime,
      endTime,
      slopeRangeAvrgDisplayed,
    } = pointSelectionSlope

    const newAvrg = this.getAverage(rawData, startTime, endTime)

    const fctId = functionality.id
    const slotName = slot.name

    const decalibratedData = this
      .reverseCalibrationOnSlice(rawData, startTime, endTime, activeProcess, fctId, slotName)
    const realNewAvrg = this.getAverage(decalibratedData, startTime, endTime)

    return {
      params: {
        pointSelectionSlope: {
          ...pointSelectionSlope,
          slopeRangeAvrgDisplayed: newAvrg || slopeRangeAvrgDisplayed,
          slopeRangeAvrgReal: realNewAvrg,
        },
      },
    }
  }

  static attachGraphSlice(sensorData, componentState) {
    const { pointSelectionSlope } = componentState

    const { startTime, endTime } = pointSelectionSlope

    if (startTime !== '' && endTime !== '') {
      const slice = DataManager.valuesWithinTimeRange(sensorData, startTime, endTime)
      return [ this.createGraphSlice(slice, { color: 'red', label: 'Selected' }) ]
    }
  }
}

export default SlopeOnlyMode
