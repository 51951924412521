import React from 'react'
import { Menu } from 'semantic-ui-react'

const MODAL_TABS = [
  'Edit',
  'Delete',
]

const DashboardDetailsModalMenu = props => {
  const { activeTab, handleActiveTab, disableDelete } = props

  return (
    <Menu attached tabular>
      {MODAL_TABS.map(tab => (
        <Menu.Item
          key={tab}
          name={tab}
          active={activeTab === tab}
          onClick={() => handleActiveTab(tab)}
          disabled={tab === 'Delete' ? disableDelete : false}
        />
      ))}
    </Menu>
  )
}

export default DashboardDetailsModalMenu
