class FirmwareUpdate {

  static get STATES() {
    return {
      AVAILABLE: 'available',
      INITIATED: 'initiated',
      FAILED: 'failed',
    }
  }

  static isAvailable(fwUpdate) {
    return fwUpdate.state === FirmwareUpdate.STATES.AVAILABLE
  }

  static isInitiated(fwUpdate) {
    return fwUpdate.state === FirmwareUpdate.STATES.INITIATED
  }

}

export default FirmwareUpdate
