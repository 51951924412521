import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { Button, Table } from "semantic-ui-react"

function isUltimateDeviceSpecificTemplate(template) {
  return !!template.deviceId
}

export default function TemplateTableRow({
  template,
  setShowDeleteTemplateModal,
  setSelectedTemplateId,
}) {
  const history = useHistory()
  const { deviceId } = useParams()

  function redirectToTemplateBuilder() {
    history.push(`/devices/${deviceId}/setups/${template.id}`)
  }

  function openDeleteModal() {
    setShowDeleteTemplateModal(true)
    setSelectedTemplateId(template.id)
  }

  return (
    <Table.Row key={template.id}>
      <Table.Cell>{template.name}</Table.Cell>
      <Table.Cell>
        <Button content="Open in Editor" onClick={() => redirectToTemplateBuilder()} />
        { isUltimateDeviceSpecificTemplate(template) ? <Button primary onClick={() => openDeleteModal()} content="Delete" /> : null }

      </Table.Cell>
    </Table.Row>
  )
}
