import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import Widget from './Widget'

const TYPE = 'queryValue'
const DISPLAY_NAME = 'Sensor Value'
const DESCRIPTION = 'Displays the latest value of a sensor'
const USE_DATASOURCES = false

const getCompatibleFcts = fctGraph => (
  FCTGraph.getFctsWithoutIONodes(fctGraph)
    .filter(fct => fct.slots
      .some(slot => slot.type === Slot.TYPES.OUT_SLOT))
)

const getCompatibleSlots = fct => (
  Functionality.getOutSlots(fct)
)

const createDefaultWidget = ({ fctId, slotName }) => ({
  type: TYPE,
  fctId,
  slotName,
  display: {
    escalations: [],
    dimension: {
      minHeight: 200,
      maxHeight: 400,
      minWidth: 200,
      maxWidth: 600,
      height: 200,
      width: 200,
    },
    ...Widget.generateDisplayDefaults(),
  },
  ...Widget.generateDefaults(),
})

const addEscalation = (widget, escalation) => ({
  ...widget,
  display: {
    ...widget.display,
    escalations: [
      ...widget.display.escalations,
      escalation,
    ],
  },
})

const removeEscalation = (widget, escalationIndex) => ({
  ...widget,
  display: {
    ...widget.display,
    escalations: widget.display.escalations.filter((_, index) => index !== escalationIndex),
  },
})

const updateEscalation = (widget, escalationIndex, updatedData) => ({
  ...widget,
  display: {
    ...widget.display,
    escalations: widget.display.escalations.map((escalation, index) => {
      if (index === escalationIndex) {
        return { ...escalation, ...updatedData }
      }
      return escalation
    }),
  },
})

const hasRequiredParamsForCreation = params => params.slotName && params.fctId

export default {
  DESCRIPTION,
  DISPLAY_NAME,
  TYPE,
  USE_DATASOURCES,
  addEscalation,
  createDefaultWidget,
  getCompatibleFcts,
  getCompatibleSlots,
  hasRequiredParamsForCreation,
  removeEscalation,
  updateEscalation,
}
