import React from 'react'
import modalize from '~/components/utility/modal/modalize'
import EscalationWidgetModalForm from './EscalationWidgetModalForm'

const EscalationWidgetModal = ({
  closeHandler,
  dashboard,
  widget,
  fctGraphId,
}) => (
  <EscalationWidgetModalForm
    showSubmitButton={false}
    closeHandler={closeHandler}
    dashboard={dashboard}
    widget={widget}
    fctGraphId={fctGraphId}
  />
)

export default modalize(EscalationWidgetModal)
