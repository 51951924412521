import { FCTGraph, Functionality, Ports } from '@ospin/fct-graph'
import React, { useState } from 'react'
import nexus from '@ospin/nexus'
import { connect } from 'react-redux'
import { Button, Table, Message } from 'semantic-ui-react'
import PhysicalMappingsSelectionRow from './PhysicalMappingsSelectionRow'
import { updateDevice } from '~/redux/actions/actions'
import FlashMessenger from '~/utils/FlashMessenger'
import { isPossiblePhysicalMapping } from '~/utils/device/physicalMapping'
import './PhysicalMappingOverview.css'

const mapDispatchToProps = dispatch => (
  { dispatchUpdateDevice: (deviceId, params) => dispatch(updateDevice({ deviceId, params })) }
)

const ERRORS = {
  INSUFFICIENT_HARDWARE: 'Insufficient hardware, Please connect the required devices to the gateway',
  INVALID_MAPPING: 'Your port assignment is not valid anymore, this could be due to a different connected port or due to assigning a port twice',
}

const HEADERS = ['Functionality', 'Port']

function generateNewFctGraphsArray(device, updatedFctGraph) {
  const { fctGraphs } = device
  return fctGraphs.map(
    fctGraph => (fctGraph.id === updatedFctGraph.id ? updatedFctGraph : fctGraph),
  )
}

const generateInitialNewMapping = graph => (
  graph.functionalities.filter(Functionality.isPhysical).reduce((a, v) => ({ ...a, [v.id]: v.ports }), {})
)

const deviceHasEnoughHardware = (physicalFcts, connectedPhysicalFunctionalities) => {
  const uniqueSelectedGraphSubTypes = [...new Set(physicalFcts.map(fct => fct.subType))]

  const numberOfConnected = (subType) => { return connectedPhysicalFunctionalities.filter(fct => fct.subType === subType).length }
  const numberOfRequired = (subType) => { return physicalFcts.filter(fct => fct.subType === subType).length }

  return uniqueSelectedGraphSubTypes.every(subType => {
    return numberOfConnected(subType) >= numberOfRequired(subType)
  })
}

function PhysicalMappingSelection({
  selectedGraph,
  activeDevice,
  dispatchUpdateDevice,
}) {

  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [newPhysicalMappingsValues, setNewPhysicalMappingsValues] = useState(
    generateInitialNewMapping(selectedGraph),
  )
  const functionalitiesToRender = selectedGraph
    .functionalities.filter(Functionality.isPhysical)

  const hasEnoughHardware = deviceHasEnoughHardware(functionalitiesToRender,activeDevice.connectedPhysicalFunctionalities)

  const renderPortValidationMessage = isValidMapping => {
    if (!hasEnoughHardware) { return <Message error content={ERRORS.INSUFFICIENT_HARDWARE} /> }

    if (!isValidMapping) {
      return <Message error content={ERRORS.INVALID_MAPPING} />
    }
  }

  async function submitPhysicalMapping() {
    const updatePayload = Object.keys(newPhysicalMappingsValues).map(id => ({id, ports: newPhysicalMappingsValues[id]}))

    try {
      setIsLoadingBtn(true)

      const { data: { fctGraph } } = await nexus.device.functionalityGraph.ports.update(
        activeDevice.id,
        selectedGraph.id,
        { updates: updatePayload },
      )
      FlashMessenger.success('Device ports have been updated')
      dispatchUpdateDevice(
        activeDevice.id,
        { fctGraphs: generateNewFctGraphsArray(activeDevice, fctGraph) },
      )
    } catch ({ message }) {
      setErrorMessage(message)
    } finally {
      setIsLoadingBtn(false)
    }
  }

  const currentMappingPossible = isPossiblePhysicalMapping(
    activeDevice,
    selectedGraph,
    newPhysicalMappingsValues,
  )
  const hasMappingChanged = !Object.keys(newPhysicalMappingsValues).every(fctId => {
    const graphFct = FCTGraph.getFctById(selectedGraph, fctId)
    return Ports.areEqual(graphFct.ports, newPhysicalMappingsValues[fctId])
  })

  return (
    <div className='physical-mapping'>
      <Table className='ospin-red'>
        <Table.Header>
          <Table.Row>
            {HEADERS.map(header => <Table.HeaderCell key={header} content={header} />)}
          </Table.Row>
          {functionalitiesToRender.map(fct => (
            <PhysicalMappingsSelectionRow
              key={fct.id}
              fct={fct}
              disableDropdown={isLoadingBtn}
              selectedGraph={selectedGraph}
              activeDevice={activeDevice}
              newPhysicalMappingsValues={newPhysicalMappingsValues}
              setNewPhysicalMappingsValues={setNewPhysicalMappingsValues}
            />
          ))}
        </Table.Header>
      </Table>
      <div>
        <Button
          onClick={() => submitPhysicalMapping()}
          primary
          style={{ float: 'right', marginBottom: '14px', marginRight: '0' }}
          loading={isLoadingBtn}
          disabled={!hasMappingChanged || !currentMappingPossible}
        >
          save ports settings
        </Button>
      </div>
      <div>
        {errorMessage ? <Message error content={errorMessage} /> : null}
        {renderPortValidationMessage(currentMappingPossible)}
      </div>
    </div>
  )
}

export default connect(null, mapDispatchToProps)(PhysicalMappingSelection)
