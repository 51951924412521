import React from 'react'
import { Loader } from 'semantic-ui-react'

const DynamicLoader = () => (
  <div style={{ position: 'fixed', top: '50%', left: 'calc(50% + 90px)' }}>
    <Loader active size='big' />
  </div>
)

export default DynamicLoader
