import Phase from '~/utils/process/Phase'
import Time from '~/utils/Time'
import { Slot } from '@ospin/fct-graph'
import { Process } from '~/utils/process'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import { deepClone } from '~/utils/immutability'

function parseTargetDuration(phase) {
  if (Phase.isManuallyTransitioned(phase)) {
    return '-'
  }
  return Time.stringFromDuration(phase.duration, true, '-')
}

function parseGroupName(phase) {
  return phase.groupName !== null ? phase.groupName : '-'
}

function parsePhaseDescription(phase) {
  if (phase.description && phase.description.includes('\n')) {
    return phase.description.replace(/[\r\n]/gm, ' ')
  }
  return phase.description !== '' ? phase.description : '-'
}

function getDisplayedInputNodeValue(inputNodeValue) {
  if (typeof inputNodeValue === 'boolean') {
    return inputNodeValue === true ? 'on' : 'off'
  }
  return inputNodeValue
}

function getCycles(phase) {
  return phase.cycle === null ? '-' : phase.cycle
}

function getProcessName(processClone) {
  return [ processClone.name ]
}

function addRunRurationToPhases(processClone) {
  return Object.entries(processClone.description).forEach(phase => {
    const [ phaseId, phaseData ] = phase
    const runDuration = Process.getPhaseRunTimeInSeconds(processClone, parseInt(phaseId, 10))
    const cycle = phaseData.groupName
      ? PhaseGroupParser.getPhaseIterationCount(processClone, parseInt(phaseId, 10)) : null

    processClone.description[phaseId] = { ...phaseData, runDuration, cycle }
  })
}

const createFunctionalityMap = slotsData => (
  slotsData.map(fct => (
    fct.slots.map(slot => ({
      id: slot.inputNodeFctId,
      key: 'inputNodeValue',
      name: `${slot.sinkFct.name} - ${Slot.getDisplayName(slot.connectingSinkSlot)}${
        slot.connectingSinkSlot.unit === '-' ? '' : `[${slot.connectingSinkSlot.unit}]`}`,
    }))
  ))
)

const CSV_KEYS = [{
  name: 'Phase Name',
  key: 'name',
}, {
  name: 'Group Name',
  key: 'group',
}, {
  name: 'Description',
  key: 'description',
}, {
  name: 'Cycles',
  key: 'cycle',
},
{
  name: 'Transition',
  key: 'transition',
}, {
  name: 'Target Duration',
  key: 'targetDuration',
}, {
  name: 'Run Duration',
  key: 'runDuration',
}]

export function getProcessOverviewData(slotsData, activeProcess) {
  const processClone = deepClone(activeProcess)
  addRunRurationToPhases(processClone)

  const functionalityMap = createFunctionalityMap(slotsData)
  const processName = getProcessName(processClone)
  const cvsMap = DescriptionParser.getPhasesSequence(processClone)
  const allKeys = CSV_KEYS.concat(functionalityMap.flat())

  const getColumnValue = (key, data) => {
    switch (key.key) {
      case 'name':
        return data.name
      case 'description':
        return parsePhaseDescription(data)
      case 'transition':
        return data.transition
      case 'group':
        return parseGroupName(data)
      case 'cycle':
        return getCycles(data)
      case 'targetDuration':
        return parseTargetDuration(data)
      case 'runDuration':
        return Time.stringFromDuration(data.runDuration, true, '-')
      case 'inputNodeValue':
        return getDisplayedInputNodeValue(data.inputNodeValues[key.id].value)

      default:
        break
    }
  }

  const getCSVColumnValues = key => Object.values(cvsMap).map(({ data }) => getColumnValue(key, data))

  const createCSVRow = key => [key.name].concat(getCSVColumnValues(key))

  const fullOverViewData = allKeys.map(
    key => createCSVRow(key),
  )

  fullOverViewData.unshift(processName)

  return fullOverViewData
}

function isFileTypeCSV(fileType) {
  return fileType === 'csv'
}

const generateCSVFileContent = (dataToCSV, options) => {
  const { delimiter: chosenDelimiter, fileType } = options
  let fileContent = `data:text/csv;charset=utf-8,${isFileTypeCSV(fileType) ? '' : ' SEP=,\r\n'}`

  dataToCSV.forEach(rowArray => {
    if (chosenDelimiter !== 'noReplacement') {
      rowArray.forEach((element, index) => {
        if (element.toString().includes(',')) {
          rowArray[index] = element.replace(/,/g, chosenDelimiter)
        }
      })
    }
    const row = rowArray.join(',')
    fileContent += `${row}\r\n`
  })
  return fileContent
}

const createLinkToDownload = (fileContent, processName) => {
  const encodedUri = encodeURI(fileContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${processName}.csv`)
  document.body.appendChild(link) // required for supporting in FF
  return link
}

// eslint-disable-next-line import/prefer-default-export
export function generateAndDownloadCSV(slotsData, activeProcess, options = {}) {

  const dataToCSV = getProcessOverviewData(slotsData, activeProcess)

  const fileContent = generateCSVFileContent(dataToCSV, options)

  const link = createLinkToDownload(fileContent, dataToCSV[0][0])
  link.click()
}
