import React, { useEffect } from 'react'
import { FCTGraph } from '@ospin/fct-graph'
import formalize from '~/components/utility/modal/formalize'
import { Container } from 'semantic-ui-react'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import LineChart from '~/utils/UIConfig/widgets/LineChart'
import LineChartOptionRow from './LineChartOptionRow'
import DashboardWidgetFCTAndSlotSelection from './DashboardWidgetFCTAndSlotSelection'

const LineChartEditModalForm = ({
  fctGraph,
  widget,
  selectedFctId,
  setSelectedFctId,
  selectedSlotName,
  setSelectedSlotName,
  addDataSource,
  removeDataSource,
  updateDataSource,
  selectedLineColorEditChart,
  setSelectedLineColorEditChart,
}) => {
  const { dataSources } = widget
  const fct = FCTGraph.getFctById(fctGraph, selectedFctId)

  const fctOptions = GraphDashboard
    .getFctsForWidgetType(LineChart.TYPE, fctGraph, dataSources)
    .map(aFct => ({ key: aFct.id, text: aFct.name, value: aFct.id }))

  useEffect(() => {
    if (fctOptions.length === 1) {
      setSelectedFctId(fctOptions[0].value)
    }
  }, [fctOptions])

  const slotOptions = GraphDashboard
    .getSlotsForWidgetType(LineChart.TYPE, { fct, dataSources, fctGraph })
    .map(({ name }) => ({ key: name, text: name, value: name }))

  useEffect(() => {
    if (slotOptions.length === 1) {
      setSelectedSlotName(slotOptions[0].value)
    }
  }, [slotOptions])

  return (
    <Container>
      <DashboardWidgetFCTAndSlotSelection
        slotOptions={slotOptions}
        fctOptions={fctOptions}
        selectedFctId={selectedFctId}
        setSelectedFctId={setSelectedFctId}
        selectedSlotName={selectedSlotName}
        setSelectedSlotName={setSelectedSlotName}
        showAddDataSource
        addDataSource={addDataSource}
        selectedLineColor={selectedLineColorEditChart}
        setSelectedLineColor={setSelectedLineColorEditChart}
        selectedWidgetType={LineChart.TYPE}
      />
      <LineChartOptionRow
        dataSources={dataSources}
        removeDataSource={removeDataSource}
        fctGraph={fctGraph}
        updateDataSource={updateDataSource}
      />
    </Container>
  )
}

export default formalize(LineChartEditModalForm)
