import createReducer from '~/redux/reducers/createReducer'

import userHandlers from '~/redux/reducers/user/handlers'
import processHandlers from '~/redux/reducers/process/handlers'
import uIHandlers from '~/redux/reducers/ui/handlers'
import deviceHandlers from '~/redux/reducers/device/handlers'
import utilsHandlers from '~/redux/reducers/utils/handlers'
import { initialState } from '../initialState'

const userReducer = createReducer(userHandlers)
const processReducer = createReducer(processHandlers)
const uIReducer = createReducer(uIHandlers)
const deviceReducer = createReducer(deviceHandlers)
const utilsReducer = createReducer(utilsHandlers)

function rootReducer(state = initialState, action) {
  switch (true) {
    case (action.type in userHandlers):
      return userReducer(state, action)
    case (action.type in processHandlers):
      return processReducer(state, action)
    case (action.type in uIHandlers):
      return uIReducer(state, action)
    case (action.type in deviceHandlers):
      return deviceReducer(state, action)
    case (action.type in utilsHandlers):
      return utilsReducer(state, action)
    default:
      return state
  }
}

export default rootReducer
