import uuidv4 from 'uuid/v4'

class GraphDashboards {
  static createDashboard(dashboards, data) {
    dashboards.push({
      id: uuidv4(),
      locked: false,
      widgets: [],
      ...data,
    })
    return dashboards
  }

  static deleteDashboard(dashboards, dashboardId) {
    return dashboards.filter(dashboard => dashboard.id !== dashboardId)
  }

  static updateDashboard(dashboards, updatedDashboard) {
    return dashboards.map(dashboard => (
      dashboard.id === updatedDashboard.id ? { ...dashboard, ...updatedDashboard } : dashboard
    ))
  }

}

export default GraphDashboards
