import React from 'react'
import { Slot } from '@ospin/fct-graph'
import { replaceNoUnitCharacter } from '~/utils/units'

const renderSlotname = slot => (
  <span className='slot-data-display-header-slot-name'>
    {Slot.getDisplayName(slot)}
  </span>
)

const renderLastDataPoint = (val, { unit }) => (
  <span className='slot-data-display-header-last-data-point'>
    {val === null ? 'No Data' : val}
    {' '}
    {val !== null ? replaceNoUnitCharacter(unit) : ''}
  </span>
)

const SlotDataDisplayHeader = ({
  lastDataPoint,
  slot,
}) => (
  <div className='slot-data-display-header'>
    <div className='slot-data-display-header-name-container'>
      {renderSlotname(slot)}
      {renderLastDataPoint(lastDataPoint, slot)}
    </div>
    <div className='slot-data-display-header-controls-container'>

    </div>
  </div>
)

export default SlotDataDisplayHeader
