export default (state, action) => {
  const { processId, images, fctId } = action.payload
  const processFctsImgs = {
    ...state.processFctsImgs,
    [processId]: {
      ...state.processFctsImgs[processId],
      [fctId]: images,
    },
  }
  return { ...state, processFctsImgs }
}
