import React from 'react'
import { Sidebar, Menu, Icon, Image, Button } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { capitalize } from '~/utils/string'
import callLogout from '~/redux/thunks/auth/callLogout'
import callDeleteEphemeralNotifications from '~/redux/thunks/user/callDeleteEphemeralNotifications'
import logoIcon2 from '~/images/logo_icon2.png'
import Notification from '~/utils/user/Notification'
import OspinLogoTopbar from './OspinLogoTopbar'
import NotificationModal from '../notifications/NotificationModal'
import '~/components/main/OspinSidebar.css'

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = dispatch => ({ dispatchLogout: () => callLogout(dispatch) })

class OspinSidebar extends React.Component {

  state = {
    showConciergeModal: !!Notification
      .getApplicationLevelEphmeralNotifications(this.props.user.notifications).length,
    showSidebarOnSmallScreen: false,
  }

  getCurrentTabFromUrl() {
    const { location: { pathname } } = this.props
    return pathname.substr(1) || ''
  }

  toggleShowSidebarOnSmallScreen = () => {
    this.setState(prevState => ({ showSidebarOnSmallScreen: !prevState.showSidebarOnSmallScreen }))
  }

  toggleModal = modalTrigger => {
    this.setState(prevState => ({ [modalTrigger]: !prevState[modalTrigger] }))
  }

  pushInternalRedirect = tab => {
    const { history } = this.props
    const { showSidebarOnSmallScreen } = this.state
    if (showSidebarOnSmallScreen) {
      this.toggleShowSidebarOnSmallScreen()
    }
    history.push(`/${tab}`)
  }

  getSidebarStyleClasses = () => {
    const { showSidebarOnSmallScreen } = this.state
    const baseClass = 'ospin-sidebar'
    return showSidebarOnSmallScreen ? `${baseClass}--on-small-screen` : `${baseClass}`
  }

  renderMenuItem = item => {
    const { text, handler } = item
    return (
      <Menu.Item
        key={text}
        active={this.getCurrentTabFromUrl() === text}
        onClick={() => (handler ? handler() : this.pushInternalRedirect(text))}
        as='a'
      >
        {item.icon}
        <span>{capitalize(text)}</span>
      </Menu.Item>
    )
  }

  renderEntryModal = () => {
    const { showConciergeModal } = this.state
    return (
      <NotificationModal
        show={showConciergeModal}
        closeHandler={() => this.toggleModal('showConciergeModal')}
        level='application'
      />
    )
  }

  renderTopbar = () => {
    const { showSidebarOnSmallScreen } = this.state
    return (
      <div className='ospin-sidebar-top-trigger'>
        <Button
          icon
          primary
          inverted
          className='ospin-sidebar-top-trigger-button'
          onClick={() => this.toggleShowSidebarOnSmallScreen(showSidebarOnSmallScreen)}
        >
          <Icon name='bars' />
        </Button>
        <div className='ospin-sidebar-top-trigger-logo'>
          <OspinLogoTopbar />
        </div>
      </div>
    )
  }

  renderSiderbar = () => {
    const { showSidebarOnSmallScreen } = this.state
    const { user, dispatchLogout, history } = this.props

    const getDimmerClass = () => {
      const base = 'ospin-sidebar-dimmer'
      if (showSidebarOnSmallScreen) {
        return `${base} ${base}--show`
      }
    }

    const sideBarItems = [
      {
        text: 'devices',
        icon: <Icon name='hdd' />,
        alignment: 'top',
      }, {
        text: 'profile',
        icon: <Icon name='user' />,
        alignment: 'top',
      }, {
        text: 'alerts',
        icon: user.events.length ? <Icon name='bell' color='red' /> : <Icon name='bell' />,
        alignment: 'top',
      }, {
        text: 'messages',
        icon: Notification.getInvitationNotifications(user.notifications).length
          ? <Icon name='mail' color='red' />
          : <Icon name='mail' />,
        alignment: 'top',
      }, {
        text: 'help',
        icon: <Icon name='help' />,
        alignment: 'bottom',
        handler: () => { window.open('https://synaptic-lab.refined.site/', '_blank').focus() }      }, {
        text: 'changelog',
        icon: Notification.getChangeLogNotifications(user.notifications).length
          ? <Icon name='info circle' color='red' />
          : <Icon name='info circle' />,
        alignment: 'bottom',
      }, {
        text: 'logout',
        icon: <Icon name='log out' />,
        alignment: 'bottom',
        /* TODO: ADD_ERROR_HANDLING */
        handler: async () => {
          await dispatchLogout()
          history.push('/login')
        },
      }]

    return (
      <>
        <Sidebar
          as={Menu}
          visible
          vertical
          className={this.getSidebarStyleClasses()}
        >
          <Link to='/devices'>
            <Image
              src={logoIcon2}
              centered
              alt='Ospin logo'
              className='ospin-sidebar-logo'
            />
          </Link>
          {sideBarItems.filter(item => item.alignment === 'top').map(item => this.renderMenuItem(item))}
          <div className='ospin-sidebar-lower-menu'>
            {sideBarItems.filter(item => item.alignment === 'bottom').map(item => this.renderMenuItem(item))}
          </div>
        </Sidebar>
        <div
          className={getDimmerClass()}
          onClick={this.toggleShowSidebarOnSmallScreen}
        />
      </>
    )
  }

  render() {
    return (
      <div>
        {this.renderTopbar()}
        {this.renderSiderbar()}
        {this.renderEntryModal()}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OspinSidebar))
