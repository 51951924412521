import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'semantic-ui-react'
import Device from '~/utils/Device'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'

const BACKGROUND_COLOR = {
  grey: '#B3B3B3',
  green: '#27AE60',
  orange: '#FF8C00',
}

function findBlockingGraphsWithRunningProcesses(device, fctGraph) {
  const runningProcessesFctGraphIds = device.runningProcesses.map(({ fctGraphId }) => fctGraphId)
  const otherRunningGraphs = device.fctGraphs.filter(
    graph => runningProcessesFctGraphIds.includes(graph.id) && graph.id !== fctGraph.id,
  )
  return FunctionalityGraph.findGraphsWithOverlappingPhysicalFcts(fctGraph, otherRunningGraphs)
}

function renderIndicator({
  deviceId,
  fctGraphId,
  text,
  color,
  linkTo = `/devices/${deviceId}/configurations/${fctGraphId}/processes`,
}) {
  return (
    <Link to={linkTo}>
      <Card.Content
        className='device-widget-status-container '
        style={{ backgroundColor: color }}
      >
        <span>
          {text}
        </span>
      </Card.Content>
      {' '}

    </Link>
  )
}

function renderBlockedIndicator(device, blockingGraphs) {
  const isSingleBlockingGraph = blockingGraphs.length === 1

  if (isSingleBlockingGraph) {
    return renderIndicator({
      deviceId: device.id,
      text: `blocked by ${blockingGraphs[0].name}`,
      linkTo: `/devices/${device.id}/configurations/${blockingGraphs[0].id}/processes`,
      color: BACKGROUND_COLOR.orange,
    })
  }

  return renderIndicator({
    deviceId: device.id,
    text: 'blocked by multiple processes',
    linkTo: `/devices/${device.id}/processes?fctGraphIds=${blockingGraphs.map(({ id }) => id).join()}`,
    color: BACKGROUND_COLOR.orange,
  })
}

export default function FunctionalityGraphWidgetStatusIndicator({ device, fctGraphId }) {
  const fctGraph = FunctionalityGraph.getGraphById(device.fctGraphs, fctGraphId)
  const isPossible = FunctionalityGraph.deviceCanExecuteGraph(fctGraph, device)
  const blockingFctGraphs = findBlockingGraphsWithRunningProcesses(device, fctGraph)

  const defaultProps = { deviceId: device.id, fctGraphId }

  if (Device.isOffline(device)) {
    return (isPossible
      ? renderIndicator({ ...defaultProps, text: 'connected', color: BACKGROUND_COLOR.grey })
      : renderIndicator({ ...defaultProps, text: 'disconnected', color: BACKGROUND_COLOR.grey })
    )
  }

  if (!isPossible) {
    return renderIndicator({ ...defaultProps, text: 'disconnected', color: BACKGROUND_COLOR.grey })
  }

  if (blockingFctGraphs.length) { return renderBlockedIndicator(device, blockingFctGraphs) }

  return renderIndicator({ ...defaultProps, text: 'ready for process', color: BACKGROUND_COLOR.green })

}
