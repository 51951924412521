import React from 'react'
import { useHistory } from 'react-router-dom'
import OopsinNotFound from '~/images/404.png'
import OopsinCantAccess from '~/images/403.png'
import Oopsin from '~/images/OOPSIN.png'
import {
  Container,
  Segment,
  Grid,
  Image,
  Button,
} from 'semantic-ui-react'

const ErrorText = ({ text }) => (
  <svg viewBox='0 0 400 70' style={{ transform: 'scale(0.8)' }}>
    <polyline
      fill='none'
      stroke='#92312d'
      strokeWidth='2'
      points='0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 400,45.486'
    />
    <text style={{ fontSize: '9px' }} fill='#92312d' x='115' y='35'>{text}</text>
  </svg>
)

const ErrorPage = ({ errorCode, customMessage }) => {

  const history = useHistory()

  const generateErrorMessage = () => {
    if (customMessage) {
      return customMessage
    }
    switch (errorCode) {
      case 403:
        return ('You do not have permission to access this resource!')
      case 404:
        return ('Oh no! You took a wrong turn!')
      case 500:
        return ('Something went wrong! The error has been reported to our developers.')
      default:
        return ('Oh no! You took a wrong turn!')
    }
  }

  const generateErrorImage = () => {
    switch (errorCode) {
      case 403:
        return OopsinCantAccess
      case 404:
        return OopsinNotFound
      case 500:
        return Oopsin
      default:
        return OopsinNotFound
    }
  }

  return (
    <Container>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Image src={generateErrorImage()} size='large' centered style={{ paddingTop: '30px' }} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column verticalAlign='middle'>
              <br />
              <ErrorText text={generateErrorMessage()} />
              <br />
              <Button onClick={() => history.push('/devices')} primary size='big'>Go to devices</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>

  )
}

export default ErrorPage
