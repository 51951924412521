import React from 'react'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import AggregatedGraphsWidget from './AggregatedGraphsWidget'

function generateTemplateToGraphMap(fctGraphs, device) {
  const templateIds = [...new Set(fctGraphs.map(graph => graph.templateId))]
  const templateIdGraphIdMap = templateIds.map(
    tId => {
      const matchingTemplate = device.fctGraphTemplates.find((({ id }) => tId === id))
      const matchingGraphs = fctGraphs.filter(({ templateId }) => templateId === tId)
      return {
        template: matchingTemplate,
        fctGraphIds: matchingGraphs.map(({ id }) => id),
        hasAtLeastOneOnline: matchingGraphs.some(
          graph => FunctionalityGraph.deviceCanExecuteGraph(graph, device),
        ),
      }
    }
    ,
  )
  return templateIdGraphIdMap
}

function sortByNameAndOnline(templateMap) {
  return templateMap.sort((a, b) => {
    if (a.hasAtLeastOneOnline && !b.hasAtLeastOneOnline) return -1
    if (!a.hasAtLeastOneOnline && b.hasAtLeastOneOnline) return 1
    return a.template.name.localeCompare(b.template.name)
  })
}

export default function AggregatedGraphsWidgets({
  fctGraphs,
  device,
  messageString,
}) {
  const templateMap = generateTemplateToGraphMap(fctGraphs, device)
  const sortedTemplateMap = sortByNameAndOnline(templateMap, fctGraphs)
  return (
    sortedTemplateMap.map(
      ({ fctGraphIds, template }) => (
        <AggregatedGraphsWidget
          key={template.id}
          device={device}
          fctGraphIds={fctGraphIds}
          template={template}
          messageString={messageString}
        />
      ),
    )
  )
}
