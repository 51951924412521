import DataManager from '~/utils/DataManager'
import SensorCalibration from '~/utils/sensors/SensorCalibration'

class CalibrationMode {

  static get MODES() {
    return {
      SELECTION_OFFSET: 'SELECTION_OFFSET',
      SELECTION_SLOPE: 'SELECTION_SLOPE',
      SELECTION_TWO_POINT: 'SELECTION_TWO_POINT',
      DIRECT_INPUT: 'DIRECT_INPUT',
    }
  }

  static getAverage = (rawData, start, end) => {
    if (!start || !end) return
    const slice = DataManager.valuesWithinTimeRange(rawData, start, end)
    return DataManager.averageValues(slice)
  }

  static reverseCalibrationOnPoint(dp, offset, slope) {
    return { x: dp.x, y: (dp.y - offset) / slope }
  }

  static reverseCalibrationOnSlice(rawData, start, end, process, fctId, slotName) {
    if (start === undefined || start === '' || end === undefined || end === '') return rawData

    let slice = DataManager.valuesWithinTimeRange(rawData, start, end)
    const { startedAt, calibrations } = process

    const sensorCalibs = SensorCalibration
      .getAllByFctIdAndSlot(calibrations, fctId, slotName)

    const calibsDuringPeriod = SensorCalibration
      .getCalibrationsInTimePeriod(sensorCalibs, startedAt + start * 1000, startedAt + end * 1000)

    // sort sensor calibs asc and map their endedAt and startedAt to rel. process time in seconds

    const mappedSensorCalibrations = calibsDuringPeriod
      .sort((a, b) => a.createdAt - b.createdAt)
      .map(c => ({
        ...c,
        createdAt: (c.createdAt - startedAt) / 1000,
        endedAt: c.endedAt !== null ? (c.endedAt - startedAt) / 1000 : null,
      }))

    for (const cal of mappedSensorCalibrations) {
      const { offset, slope } = SensorCalibration.getParameterValues(cal)
      slice = slice.map(dp => {
        const withinCalibRange = cal.endedAt !== null
          ? (dp.x <= cal.endedAt && dp.x >= cal.createdAt)
          : (cal.endedAt === null && dp.x >= cal.createdAt)

        if (withinCalibRange) return CalibrationMode.reverseCalibrationOnPoint(dp, offset, slope)
        return dp
      })
    }

    return slice
  }

  static getUpdatedPointSelection() {
    return { params: null }
  }

  static attachGraphSlice() { return [] }

  static createGraphSlice = (dataPoints, {
    color = 'red',
    label = 'Interval 1',
    markerSize = 3,
    markerType = 'circle',
  } = {}) => ({
    markerSize,
    type: 'line',
    markerType,
    showInLegend: true,
    legendText: label,
    color,
    dataPoints,
  })

}

export default CalibrationMode
