import nexus from '@ospin/nexus'
import { updateProcess } from '~/redux/actions/actions'

const callUpdateAnnotation = async (dispatch, annotationId, process, data) => {
  const { data: newAnnotation } = await nexus.process.annotation
    .update({ annotationId, processId: process.id, data })

  const params = {
    annotations: process.annotations.map(an => {
      if (an.id === annotationId) {
        return newAnnotation
      }
      return an
    }),
  }
  dispatch(updateProcess({ processId: process.id, params }))
}

export default callUpdateAnnotation
