export default (state, action) => {
  const { downloadRequests } = state
  const { request } = action.payload
  const { id: requestId } = request

  const requestExists = downloadRequests.find(({ id }) => id === requestId)

  if (!requestExists) {
    return { ...state, downloadRequests: [ ...downloadRequests, request ] }
  }

  const updatedRequests = downloadRequests.map(aRequest => {
    if (aRequest.id === requestId) return request
    return aRequest
  })

  return { ...state, downloadRequests: updatedRequests }
}
