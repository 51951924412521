import React, { useState } from 'react'
import { Button, Divider, List } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import AuthViewContainer from '../AuthViewContainer'
import RequestConfirmationCode from './RequestConfirmationCode'
import RedirectionLink from './RedirectionLink'
import SubmitSignUpCode from './SubmitSignUpCode'

const WORKFLOWS = {
  requestSignUpCode: 'requestSignUpCode',
  confirmSignUp: 'confirmSignUp',
}

function ConfirmSignUp() {
  const [ workflow, setWorkflow ] = useState(WORKFLOWS.confirmSignUp)

  return (
    <AuthViewContainer>
      <Button.Group fluid>
        <Button
          primary={workflow === WORKFLOWS.confirmSignUp}
          onClick={() => setWorkflow(WORKFLOWS.confirmSignUp)}
          content='Confirm Account'
        />
        <Button.Or />
        <Button
          onClick={() => setWorkflow(WORKFLOWS.requestSignUpCode)}
          primary={workflow === WORKFLOWS.requestSignUpCode}
          content='Request Confirmation Code'
        />
      </Button.Group>
      <Divider horizontal hidden />

      {workflow === WORKFLOWS.requestSignUpCode
        ? <RequestConfirmationCode />
        : (
          <SubmitSignUpCode renderReRequestForm={() => setWorkflow(WORKFLOWS.requestSignUpCode)} />
        )}
      <List>
        <List.Item>
          <RedirectionLink text='Back To Login' route='login' />
        </List.Item>
      </List>

    </AuthViewContainer>
  )
}

export default withRouter(ConfirmSignUp)
