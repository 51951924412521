import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion } from 'semantic-ui-react'
import UserUIConfig from '~/utils/UIConfig/UserUIConfig'
import GatewayDisplayedFcts from '~/utils/partners/GatewayDisplayedFcts'
import ConfigurationViewControl from './ConfigurationViewControl'
import GraphsSection from './GraphsSection'

const mapStateToProps = ({ user }) => ({ user })

function ConfigurationsView(props) {
  const { device, user } = props
  const { id: deviceId } = device
  const { uIConfig } = user

  const { configurations: { elements } } = UserUIConfig
    .getDeviceConfig(uIConfig, deviceId)

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ConfigurationViewControl
        device={device}
      />
      <Accordion fluid>
        {GatewayDisplayedFcts.getOrderedElements(elements).map(type => (
          <GraphsSection
            key={type}
            fctGraphsType={type}
            device={device}
          />
        ))}
      </Accordion>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(ConfigurationsView))
