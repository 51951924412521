import Authorizer from '~/utils/Authorizer'

export default class SensorCalibration {

  static getParamDefaultValue(paramName) {
    if (paramName === 'offset') return 0
    if (paramName === 'slope') return 1
    throw new Error(`Unknown parameter name ${paramName} provided`)
  }

  static sensorHasCalibration(calibrations, fctId, slotName) {
    return calibrations.some(cal => (cal.fctId === fctId && cal.slotName === slotName))
  }

  static _add(calibrations, calibration) {
    return [ ...calibrations, calibration]
  }

  static update(calibrations, calibration) {
    const { fctId, slotName } = calibration
    const existentCalibration = SensorCalibration
      .sensorHasCalibration(calibrations, fctId, slotName)

    if (!existentCalibration) return SensorCalibration._add(calibrations, calibration)

    return calibrations.map(aCalibration => {
      if (aCalibration.fctId === fctId && aCalibration.slotName === slotName) return calibration
      return aCalibration
    })
  }

  static getParameterValues(calibration) {
    const { params: { offset: { value: offsetValue }, slope: { value: slopeValue } } } = calibration
    return { offset: offsetValue, slope: slopeValue }
  }

  static delete(calibrations, fctId, slotName) {
    return calibrations.filter(cal => !(cal.fctId === fctId && cal.slotName === slotName))
  }

  static getByFctIdAndSlot(calibrations, fctId, slotName) {
    return calibrations.find(cal => cal.fctId === fctId && cal.slotName === slotName)
  }

  static userCanUpdateParameter(user, activeDevice, calibration, param) {
    if (Authorizer.userHasSuperDeviceAccess(activeDevice, user)) return true

    if (!calibration) return Authorizer.isResourceOperator(activeDevice, user.id)

    const { locked } = calibration.params[param]

    if (Authorizer.isResourceOperator(activeDevice, user.id)) return !locked

    return false
  }

  static userCanDelete(user, activeDevice, calibration) {
    const {
      params: {
        offset: { locked: offsetLocked },
        slope: { locked: slopeLocked },
      },
    } = calibration

    if (offsetLocked || slopeLocked) {
      return Authorizer.userHasSuperDeviceAccess(activeDevice, user)
    }

    return Authorizer.userHasSuperDeviceAccess(activeDevice, user)
      || Authorizer.isResourceOperator(activeDevice, user.id)
  }

  static userCanSetLockedState(user, activeDevice) {
    return Authorizer.userHasSuperDeviceAccess(activeDevice, user)
  }

  static getAllByFctIdAndSlot(calibrations, fctId, slotName) {
    return calibrations.filter(cal => (cal.fctId === fctId && cal.slotName === slotName))
  }

  static isActive(calibration) {
    return calibration.endedAt === null
  }

  static getCalibrationsInTimePeriod(calibrations, start, end) {
    return calibrations.filter(cal => {
      if (cal.createdAt <= end && cal.endedAt >= start) return true
      if (cal.createdAt <= end && cal.endedAt === null) return true
      return false
    })
  }

}
