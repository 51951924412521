import PhaseProgression from '~/utils/process/PhaseProgression'
import EventHistoryParser from '~/utils/process/EventHistoryParser'

class Phase {

  static get TRANSITIONS() {
    return {
      MANUAL: 'manual',
      TIME_BASED: 'time-based',
    }
  }

  static get MAXIMUM_DURATION_IN_SECONDS() {
    return 60 * 60 * 24 * 365 // one year
  }

  static getName(phase) { return phase.name }

  static getGroupName(phase) { return phase.groupName }

  static getTransitionMethod(phase) { return phase.transition }

  static getDuration(phase) { return phase.duration }

  static getInputNodeValues(phase) { return phase.inputNodeValues }

  static isTimeBased(phase) {
    return this.getTransitionMethod(phase) === Phase.TRANSITIONS.TIME_BASED
  }

  static isManuallyTransitioned(phase) {
    return this.getTransitionMethod(phase) === Phase.TRANSITIONS.MANUAL
  }

  static getRunTimeInSeconds(phase, process) {
    const { id: phaseId } = phase

    const secondsSincePhaseStart = PhaseProgression.getSecondsSincePhaseStart(process, phaseId)
    const pauseDuration = EventHistoryParser.getFullPausedDurationFromEvents(process, phaseId)
    return secondsSincePhaseStart - pauseDuration
  }

  static getRemainingMSDurationWithinProcess(phase, process) {
    if (!Phase.isTimeBased(phase)) return null
    const { duration } = phase

    const runTimeInSeconds = Phase.getRunTimeInSeconds(phase, process)
    const remainingPhaseDurationMS = (duration - runTimeInSeconds) * 1000

    return (remainingPhaseDurationMS < 0) ? 0 : remainingPhaseDurationMS
  }
}

export default Phase
