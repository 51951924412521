import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Divider, Segment, Button, Form, Icon, Input, Popup } from 'semantic-ui-react'
import Time from '~/utils/Time'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import { Process } from '~/utils/process'
import DescriptionParser from '~/utils/process/DescriptionParser'
import Authorizer from '~/utils/Authorizer'
import { setPhaseProps } from '~/redux/actions/actions'
import Phase from '~/utils/process/Phase'
import PhaseDescription from './PhaseDescription'
import './PhaseHeader.css'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchSetTransitionMethod: (processId, phaseId, transition) =>
    dispatch(setPhaseProps({ processId, phaseId, update: { transition } })),
  dispatchSetPhaseDuration: (processId, phaseId, duration) =>
    dispatch(setPhaseProps({ processId, phaseId, update: { duration } })),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

const PhaseHeader = ({
  activeProcess,
  phase,
  phaseId,
  phaseName,
  submitNewPhaseName,
  dispatchSetTransitionMethod,
  dispatchSetPhaseDuration,
  phaseCanBeModified,
  dispatchCallUpdateProcess,
  user,
}) => {

  const [editPhaseName, setEditPhaseName] = useState(false)
  const [editPhaseDescription, setEditPhaseDescription] = useState(false)
  const [loadingSubmitDescription, setLoadingSubmitDescription] = useState(false)

  const isEditDisabled = Authorizer.isResourceViewer(activeProcess, user.id)

  const handleEditName = e => {
    e.stopPropagation()
    setEditPhaseName(true)
  }

  const isValidValue = newName => {
    if (newName.trim() !== '') return true
    setEditPhaseName(false)
    return false
  }

  const submitNewName = e => {
    const newName = e.target.value
    setEditPhaseName(false)
    if (!isValidValue(newName)) return
    submitNewPhaseName(newName)
  }

  const submitNewNameOnEnter = e => {
    if (e.keyCode === 13) {
      submitNewName(e)
    }
  }

  const handlePhaseDescription = e => {
    e.stopPropagation()
    setEditPhaseDescription(true)
  }

  const renderHeader = () => (
    <div className='phase-name-header phase-name-header--no-pseudo'>
      {editPhaseName
        ? (
          <Input
            className='phase-name-header-input-container'
            size='mini'
            defaultValue={phaseName}
            onBlur={submitNewName}
            onClick={e => e.stopPropagation()}
            control='input'
            autoFocus
            onKeyDown={submitNewNameOnEnter}
          />
        )
        : (
          <Button.Group className='editable-phase-header editable-phase-header--fluid'>
            <Popup
              content={phaseName}
              basic
              trigger={(
                <div onDoubleClick={!isEditDisabled ? handleEditName : null}>
                  <h5 className='phase-name-header-headline'>
                    {phaseName}
                  </h5>
                </div>
              )}
            />
            <div>
              <Button
                compact
                className='phase-header-edit-name-description-buttons'
                onClick={handleEditName}
                disabled={isEditDisabled || loadingSubmitDescription}
              >
                Edit Name
              </Button>
              <Button
                compact
                className='phase-header-edit-name-description-buttons'
                onClick={handlePhaseDescription}
                disabled={isEditDisabled || loadingSubmitDescription}
                loading={loadingSubmitDescription}
              >
                Edit Description
              </Button>
            </div>
          </Button.Group>
        )}
    </div>
  )

  const save = async () => {
    if (Process.isExecutable(activeProcess)) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const handlePhaseTransitionMethod = async method => {
    dispatchSetTransitionMethod(activeProcess.id, phaseId, method)
    await save()
  }

  const handleDurationUpdate = async (unit, e) => {
    let { value } = e.target

    if (isNaN(value)) return

    if (value === '') value = 0

    const duration = Time
      .parseTimeElapsedFromSeconds(DescriptionParser.getPhaseDuration(activeProcess, phaseId))
    const newDuration = { ...duration }

    newDuration[unit] = parseInt(value, 10)
    const durationInSeconds = Math.min(Time.durationToSeconds(newDuration), Phase.MAXIMUM_DURATION_IN_SECONDS)

    dispatchSetPhaseDuration(activeProcess.id, phaseId, durationInSeconds)
    await save()
  }

  const renderDurationInput = (duration, unit, disabled, key) => (
    <Form.Field key={key}>
      <label>{unit}</label>
      <Input
        value={duration[unit]}
        onChange={event => handleDurationUpdate(unit, event)}
        onBlur={save}
        fluid
        disabled={disabled}
      />
    </Form.Field>
  )

  const transition = DescriptionParser.getPhaseTransitionMethod(activeProcess, phaseId)
  const duration = Time
    .parseTimeElapsedFromSeconds(DescriptionParser.getPhaseDuration(activeProcess, phaseId))
  const durationUnits = ['days', 'hours', 'minutes', 'seconds']
  const disabledInput = !phaseCanBeModified || Authorizer.isResourceViewer(activeProcess, user.id)

  return (
    <>
      { renderHeader() }
      <PhaseDescription
        activeProcess={activeProcess}
        phaseId={phaseId}
        phase={phase}
        editPhaseDescription={editPhaseDescription}
        setEditPhaseDescription={setEditPhaseDescription}
        setLoadingSubmitDescription={setLoadingSubmitDescription}
        isEditDisabled={isEditDisabled}
      />
      <Divider />
      <Button.Group className='phase-button-group'>
        <Button
          primary={Phase.isManuallyTransitioned({ transition })}
          disabled={disabledInput}
          onClick={() => handlePhaseTransitionMethod(Phase.TRANSITIONS.MANUAL)}
        >
          <Icon name='hand pointer' />
          Manual
        </Button>
        <Button.Or />
        <Button
          primary={Phase.isTimeBased({ transition })}
          disabled={disabledInput}
          onClick={() => handlePhaseTransitionMethod(Phase.TRANSITIONS.TIME_BASED)}
        >
          <Icon name='clock outline' />
          Duration
        </Button>
      </Button.Group>
      {Phase.isTimeBased({ transition }) ? (
        <Segment>
          <Form>
            <Form.Group widths='equal'>
              {durationUnits
                .map((unit, i) => renderDurationInput(duration, unit, disabledInput, i))}
            </Form.Group>
          </Form>
        </Segment>
      ) : null}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseHeader)
