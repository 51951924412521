import React from 'react'
import { Link } from 'react-router-dom'

const RedirectionLink = ({
  route,
  text,
}) => (
  <Link className='link-style' to={route}>
    {text}
  </Link>
)

export default RedirectionLink
