import React from 'react'
import { Button } from 'semantic-ui-react'
import './ProcessControls.css'

export default function ProcessControls({
  totalPages,
  selectedIds,
  allSelected,
  handleSelectAll,
  toggleModal,
}) {

  const deleteDisabled = selectedIds.length === 0

  if (totalPages === 0) return null

  return (
    <div className='process-controls-container'>
      <div>
        <Button
          style={{ marginRight: '16px' }}
          onClick={() => toggleModal('showDeleteProcessModal')}
          disabled={deleteDisabled}
          content='Delete'
          primary
        />
        <Button
          onClick={handleSelectAll}
          content={`${!allSelected ? 'Select' : 'Deselect'} all`}
        />
      </div>
    </div>
  )
}
