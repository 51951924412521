import React from 'react'
import { Slot } from '@ospin/fct-graph'
import { Form, Checkbox } from 'semantic-ui-react'

const BooleanInSlot = ({
  slot,
  inputNodeValue,
  disabledInput,
  updateInputNodeValue,
  inputNodeFctId,
}) => (
  <div>
    <span style={{ fontWeight: 'bold' }}>
      {Slot.getDisplayName(slot)}
    </span>
    <Form.Group>
      <Form.Field>
        <Checkbox
          toggle
          checked={inputNodeValue}
          onChange={() => updateInputNodeValue(inputNodeFctId, !inputNodeValue)}
          disabled={disabledInput}
          style={{ marginTop: '8px' }}
        />
      </Form.Field>
    </Form.Group>
  </div>
)

export default BooleanInSlot
