import React, { useState } from 'react'
import { Dimmer, Header } from 'semantic-ui-react'
import LineChartEditModal from './LineChartEditModal'
import LineChartWidgetBody from './LineChartWidgetBody'

const LineChartWidget = ({
  activeProcess,
  widget,
  removeWidget,
  setAllowDrag,
  dashboard,
  locked
}) => {

  const [ isEditModalOpen, setIsEditModalOpen ] = useState(false)
  const [ showControls, setShowControls ] = useState(true) /// Not implemented yet
  const [ showDimmer,setShowDimmer ] = useState(false)
  const { fctGraph } = activeProcess

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setAllowDrag(true)
  }

  const openEditModal = () => {
    setIsEditModalOpen(true)
    setAllowDrag(false)
  }

  const dim = () => {
    if (locked) { return  }
    setShowDimmer(true)
  }

  const unDim = () => {
    if (!showDimmer) { return }

    setShowDimmer(false)
  }

  return (
    <div
      onMouseOver={dim}
      onMouseOut={unDim}
      onClick={(e) => showDimmer ? e.preventDefault() : null}
    >
      <Dimmer
        active={showDimmer}
        style={{backgroundColor: 'lightGrey', opacity: 0.75, borderBottomRightRadius: '5rem'}}
      >
        <Header as={'h1'} style={{color: 'white'}} content='lock the dashboard to interact with the graph'/>
      </Dimmer>
      <LineChartWidgetBody
        openEditModal={() => openEditModal()}
        removeWidget={removeWidget}
        activeProcess={activeProcess}
        widget={widget}
        showControls={showControls}
        setShowControls={setShowControls}
      />
      <LineChartEditModal
        headerText='Edit Chart'
        size='small'
        closeHandler={() => closeEditModal()}
        open={isEditModalOpen}
        fctGraph={fctGraph}
        widget={widget}
        dashboard={dashboard}
      />
    </div>
  )
}

export default LineChartWidget
