export function ellipseString(string, max) {
  const numberOfEllipses = 3
  if (max < numberOfEllipses) return '...'
  if (!string) { return '' }
  return string.length > max ? `${string.slice(0, max - numberOfEllipses)}...` : string
}

export function capitalize(string) {
  return string[0].toUpperCase() + string.substr(1)
}

export function replaceString(string, index, replacement) {
  return string.substr(0, index) + replacement + string.substr(index + replacement.length)
}

export function removeWhiteSpaces(string) {
  return string.replace(/\s+/g, '')
}
