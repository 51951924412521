import React from 'react'
import { Form, Dropdown } from 'semantic-ui-react'
import { getProcessOverviewData } from '~/utils/ProcessOverviewCSVBuilder'
import formalize from '~/components/utility/modal/formalize'

const FILE_TYPE_OPTIONS = [
  { value: 'csvForExcel', text: '.csv (for Excel)' },
  { value: 'csv', text: '.csv (Standard)' },
]

const DELIMITER_OPTIONS = [
  { value: ' ', text: 'empty space' },
  { value: '.', text: 'dot' },
  { value: ';', text: 'semi-colon' },
  { value: 'noReplacement', text: 'no replacement' },
]

const renderSelectDelimiter = changeDelimiter => (
  <>
    <p className='margin-t-16'>
      Some of the values in the table contains a comma. This will break the separation of values in your .csv file.
      Do you want to replace the comma with another value in the downloaded file?
    </p>
    <Form.Field>
      <label>Select Delimiter</label>
      <Dropdown
        onChange={changeDelimiter}
        options={DELIMITER_OPTIONS}
        defaultValue=' '
        selection
      />
    </Form.Field>
  </>
)

const CSVDownloadModalForm = props => {
  const { changeFileType, activeProcess, slotsData, changeDelimiter } = props

  const isDelimiterPresent = () => {
    const overviewData = getProcessOverviewData(slotsData, activeProcess)
    let contentHasDelimiter = false

    overviewData.forEach(array => {
      array.forEach(element => {
        if (element.toString().includes(',')) {
          contentHasDelimiter = true
        }
      })
    })
    return contentHasDelimiter
  }

  return (
    <Form.Field>
      <label>File Type</label>
      <Dropdown
        onChange={changeFileType}
        options={FILE_TYPE_OPTIONS}
        selection
        defaultValue='csvForExcel'
      />
      {isDelimiterPresent(slotsData, activeProcess) && renderSelectDelimiter(changeDelimiter)}
    </Form.Field>
  )
}

export default formalize(CSVDownloadModalForm)
