import React, { useState } from 'react'
import { Button, Message, Modal } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import FlashMessenger from '~/utils/FlashMessenger'
import { updateDevice } from '~/redux/actions/actions'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => (
  { dispatchUpdateDevice: (deviceId, params) => dispatch(updateDevice({ deviceId, params })) }
)

function generateUpdatedDeviceForStore(device, selectedTemplateId) {
  return {
    ...device,
    fctGraphTemplates: device.fctGraphTemplates.filter(({ id }) => id !== selectedTemplateId),
    fctGraphs: device.fctGraphs.filter(({ templateId }) => templateId !== selectedTemplateId ),
  }
}

function DeleteTemplateModal({
  dispatchUpdateDevice,
  activeDevice,
  template,
  show,
  close,
  removeTemplateFromList,
}) {
  const [loading, setLoading] = useState(false)
  const [ hasError, setHasError ] = useState(false)
  const [ error, setError ] = useState('')

  async function deleteTemplate() {
    try {
      setLoading(true)
      await nexus.deviceDescription.template.remove(template.id)
      removeTemplateFromList(template.id)
      FlashMessenger.success(`Template ${template.name} has been succesfully deleted`)
      const storeUpdatePayload = generateUpdatedDeviceForStore(activeDevice, template.id)
      dispatchUpdateDevice(activeDevice.id, storeUpdatePayload)

      close()
    } catch ({ message }) {
      setHasError(true)
      setError(message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={show}
      onClose={close}
    >
      <Modal.Header>
        Delete Template
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message info content='Deleting a template can only be done if there are no processes' />
          <div style={{display: 'flex', justifyItems: 'baseline'}}>
            <Button fluid onClick={() => close()} content='Close' />
            <Button fluid primary onClick={() => deleteTemplate()} loading={loading} content='Delete' />
          </div>
          { hasError ? <Message error content={error}/> : null }
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default connect(null, mapDispatchToProps)(DeleteTemplateModal)
