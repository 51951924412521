import React from 'react'
import { Form, Button, Message, List } from 'semantic-ui-react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import FlashMessenger from '~/utils/FlashMessenger'
import callLogin from '~/redux/thunks/auth/callLogin'
import HelpIconPopup from '~/components/utility/HelpIconPopup'
import AuthViewContainer from '~/components/authentication/AuthViewContainer'
import { removeWhiteSpaces } from '~/utils/string'
import './Login.css'
import RedirectionLink from './signUp/RedirectionLink'

const mapStateToProps = state => ({ authenticated: state.authenticated })

const mapDispatchToProps = dispatch => ({ dispatchCallLogin: (username, password) => callLogin(dispatch, username, password) })

class Login extends React.Component {

  state = {
    username: '',
    password: '',
    isError: false,
    errorMessage: '',
    pending: false,
  }

  componentDidMount() {
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    const signUpConfirmation = query.get('signUpConfirmation')

    if (!signUpConfirmation || signUpConfirmation !== 'true') return
    FlashMessenger.success('User registration confirmed')
  }

  onUpdateUsername = ({ target: { value } }) => {
    this.setState({ username: removeWhiteSpaces(value), isError: false, errorMessage: '' })
  }

  onUpdatePassword = ({ target: { value } }) => {
    this.setState({ password: removeWhiteSpaces(value), isError: false, errorMessage: '' })
  }

  submitLogin = async () => {
    this.setState({ pending: true })
    const { username, password } = this.state
    const { dispatchCallLogin } = this.props
    try {
      await dispatchCallLogin(username, password)
    } catch (e) {
      this.setState({ isError: true, errorMessage: e.message, pending: false })
    }
  }

  render() {
    const { authenticated, location } = this.props
    const { username, password } = this.state
    const query = new URLSearchParams(location.search)

    if (authenticated) return <Redirect to={query.get('redirectTo') || '/devices'} />

    const userNameTooltip = 'verified accounts can also log in with their email address'

    const { pending, isError, errorMessage } = this.state

    return (
      <AuthViewContainer>
        <Form error={isError} size='large' onSubmit={e => e.preventDefault}>
          <Form.Field>
            <label htmlFor='username'>
              username or email
              <HelpIconPopup description={userNameTooltip} />
            </label>
            <Form.Input
              size='big'
              id='username'
              name='username'
              fluid
              icon='user'
              iconPosition='left'
              value={username}
              autoComplete='username'
              onChange={this.onUpdateUsername}
              className='login-input'
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor='password'>
              password
            </label>
            <Form.Input
              size='big'
              id='password'
              name='password'
              fluid
              icon='lock'
              iconPosition='left'
              autoComplete='current-password'
              type='password'
              value={password}
              onChange={this.onUpdatePassword}
              className='login-input'
            />
          </Form.Field>
          {isError && (
            <Message
              error
              header='Login failed.'
              content={errorMessage}
            />
          )}
          <div>
            <div>
              <Button
                loading={pending}
                disabled={pending || !username || !password}
                size='large'
                primary
                fluid
                onClick={this.submitLogin}
                id='login'
              >
                Login
              </Button>
            </div>
            <List>
              <List.Item content={<RedirectionLink text='No account yet? Sign up!' route='sign-up' />} />
              <List.Item content={<RedirectionLink text='Forgot password' route='reset-password' />} />
              <List.Item content={<RedirectionLink text='Confirm sign up' route='confirm-sign-up' />} />
            </List>

            <div className='link-btn-container margin-t-8' />
          </div>
        </Form>
      </AuthViewContainer>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
