import React from 'react'
import { connect } from 'react-redux'
import { Button, Divider, Modal, Form, Segment, Header } from 'semantic-ui-react'
import Notification from '~/utils/user/Notification'
import callDeleteEphemeralNotifications from '~/redux/thunks/user/callDeleteEphemeralNotifications'
import AvailableFirmwareUpdateNotification from './topics/AvailableFirmwareUpdateNotification'
import PortsChangedNotification from './topics/PortsChangesNotification'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchDeleteEphemeralNotifications: (userId, notifications, deleteOptimistically) =>
  callDeleteEphemeralNotifications(dispatch, userId, notifications, deleteOptimistically),
})

function getModalHeader(level, { fctGraph }) {
  switch (level) {
    case 'application':
      return 'Your News'
    case 'device':
      return 'Device Notifications'
    case 'fctGraph':
      return `${fctGraph.name}`
    default:
      return ''
  }
}

function getNotificationHeadline(notification) {
  if (notification.topic === 'device/revocation') {
    return 'Device Access Revocation'
  }
  return 'Notification'
}

function getNotifications(level, user, device, fctGraph) {
  switch (level) {
    case 'application':
      return Notification.getApplicationLevelEphmeralNotifications(user.notifications)
    case 'device':
      return Notification.getDisplayableDeviceNotifications(user, device)
    case 'fctGraph':
      return Notification
        .getFunctionalityGraphLevelEphemeralNotifications(
          user.notifications,
          device.id,
          fctGraph.id,
        )
    default:
      return []
  }
}

function renderNotification(notification, device, closeHandler, fctGraph) {
  switch (notification.topic) {
    case Notification.TOPICS.DEVICE_AVAILABLE_FIRMWARE_UPDATE:
      return (
        <AvailableFirmwareUpdateNotification
          key={notification.id}
          device={device}
          closeHandler={closeHandler}
        />
      )
    case Notification.TOPICS.DEVICE_AUTOMATIC_PORT_UPDATE:
      return (
        <PortsChangedNotification
          key={notification.id}
          device={device}
          fctGraph={fctGraph}
        />
      )
    default:
      return (
        <Segment key={notification.id}>
          <Header as='h3' content={getNotificationHeadline(notification)} />
          {notification.data.message}
        </Segment>
      )
  }
}

function renderCloseButton(level, closeHandler) {
  if (level === 'device') {
    return null
  }

  return (
    <Button
      style={{ marginBottom: '1rem' }}
      floated='right'
      onClick={() => closeHandler()}
      content='Got it!'
    />
  )
}

function NotificationModal({
  user,
  device,
  fctGraph,
  show,
  dispatchDeleteEphemeralNotifications,
  closeHandler,
  level,
  deleteOptimistically,
}) {
  const notifications = getNotifications(level, user, device, fctGraph)

  function close() {
    dispatchDeleteEphemeralNotifications(user.id, notifications, deleteOptimistically)
    if (closeHandler) closeHandler()
  }
  return (
    <Modal
      size='tiny'
      open={show}
      onClose={() => close()}
      onClick={event => event.stopPropagation()}
    >
      <Modal.Header>{getModalHeader(level, { fctGraph })}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            {notifications.map(
              notification => renderNotification(notification, device, close, fctGraph),
            )}
            <Divider />
            {renderCloseButton(level, close)}
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal)
