import React, { useState } from 'react'
import { connect } from 'react-redux'
import nexus from '@ospin/nexus'
import { updateDevice } from '~/redux/actions/actions'
import { Icon, Segment, Header, Button } from 'semantic-ui-react'
import Authorizer from '~/utils/Authorizer'
import Device from '~/utils/Device'
import FirmwareUpdate from '~/utils/device/FirmwareUpdate'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDevice: (deviceId, params) =>
    dispatch(updateDevice({ deviceId, params: { ...params } })),
})

const renderIcon = condition => (
  condition
    ? <Icon name='check' color='green' />
    : <Icon name='times circle outline' color='red' />
)

const renderPreRequisites = ({ online, idle, authorized }) => (
  <>
    <h3>Prerequisites</h3>
    <div className='margin-rl-16'>
      <div>
        {renderIcon(online)}
        {' '}
        the device is online
      </div>
      <div>
        {renderIcon(idle)}
        {' '}
        the device is idle
      </div>
      <div>
        {renderIcon(authorized)}
        {' '}
        you are authorized to update the firmware
      </div>
    </div>
  </>
)

function AvailableFirmwareUpdateNotification({
  device,
  user,
  closeHandler,
  dispatchUpdateDevice,
}) {

  const [requestState, setRequestState] = useState({
    pending: false,
    isError: false,
    respMessage: '',
  })

  const [isLoading, setIsLoading ] = useState(false)

  const preRequisites = {
    online: Device.isOnline(device),
    idle: Device.isIdle(device),
    authorized: Authorizer.userHasSuperDeviceAccess(device, user),
  }

  const fulfilledAllPreRequesites = Object.values(preRequisites).every(req => req === true)

  const { firmwareUpdate } = device

  const updateFirmware = async () => {

    setRequestState({
      respMessage: '',
      pending: true,
      isError: false,
    })

    try {
      setIsLoading(true)
      await nexus.command.device.updateFirmware({
        deviceId: device.id,
        firmwareUpdateId: firmwareUpdate.id,
      })
      dispatchUpdateDevice(device.id, {
        firmwareUpdate: {
          ...firmwareUpdate,
          state: FirmwareUpdate.STATES.INITIATED,
        },
      })
      closeHandler()
    } catch (e) {
      setIsLoading(false)
      setRequestState({
        ...requestState,
        pending: false,
        isError: true,
        respMessage: e.message,
      })
    }
  }

  return (
    <Segment key={firmwareUpdate.id}>
      <Header as='h3' content='Update Firmware Version' />
      A new firmware update is available for the following device
      <Segment style={{ display: 'flex', justifyContent: 'center' }}>
        <b>{device.name}</b>
      </Segment>
      <p>
        We strongly recommend keeping up-to-date with the latest versions of the
        firmware as they enable new functionalities and increase the
        portfolio of supported devices available to you.
      </p>
      <b>
        During the update, the Gateway will shut-down and not respond.
        Please make sure that during this period, the device has a stable internet connection
        and remains connected to the power supply. Once the process is finished,
        the gateway will appear online again and is ready to be used.
      </b>
      {renderPreRequisites(preRequisites)}
      <br />
      <div style={{ display: 'flex' }}>
        <Button content='Later' onClick={() => closeHandler()} />
        <Button primary fluid content='Update Now' loading={isLoading} onClick={() => updateFirmware()} disabled={!fulfilledAllPreRequesites} />
      </div>
    </Segment>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(AvailableFirmwareUpdateNotification)
