import React from 'react'
import { Icon, Button, Popup } from 'semantic-ui-react'

class DeletePopup extends React.Component {

  state = { showPopup: false }

  handleClick = () => {
    const { confirmHandler } = this.props
    confirmHandler()
    this.handleClose()
  }

  handleOpen = () => this.setState({ showPopup: true })

  handleClose = () => this.setState({ showPopup: false })

  render() {
    const { disabled, iconName, position, loading = false } = this.props

    return (
      <Popup
        trigger={(
          <Button
            primary
            icon
            size='small'
            disabled={disabled}
            loading={loading}
          >
            <Icon name={iconName} />
          </Button>
        )}
        content={(
          <Button
            primary
            onClick={this.handleClick}
          >
            Confirm
          </Button>
        )}
        on='click'
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        open={this.state.showPopup}
        position={position}
      />
    )
  }
}

export default DeletePopup
