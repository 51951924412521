export default class PasswordValidation {
  static get REQUIRED_PASSWORD_RULES() {
    return [
      { errorMessage: 'Password can not be empty', isValid: val => /^.+$/u.test(val) },
      { errorMessage: 'At least one uppercase letter', isValid: val => /[A-Z]/.test(val) },
      { errorMessage: 'At least one number', isValid: val => /\d/.test(val) },
      { errorMessage: 'At least one lowercase letter', isValid: val => /[a-z]/.test(val) },
      { errorMessage: 'Minimum length of 8 letters', isValid: val => val.length >= 8 },
    ]
  }

  static get OPTIONAL_PASSWORD_RULES() {
    return [
      { errorMessage: 'special letter', isValid: val => /[!@#$%^&*(),.?":{}|<>]/.test(val) },
    ]
  }
}
