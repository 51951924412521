import nexus from '@ospin/nexus'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Segment, Header, Button, Grid, Icon } from 'semantic-ui-react'
import ProcessValidator from '~/utils/validation/ProcessValidator'

import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import {
  setProcessBuilderValidationErrors,
  updateProcess,
  setDisplayedProcessPhase,
} from '~/redux/actions/actions'
import PDVC from '~/utils/PDVC'
import { Process } from '~/utils/process'
import Device from '~/utils/Device'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import PhaseProgression from '~/utils/process/PhaseProgression'
import FlashMessenger from '~/utils/FlashMessenger'
import Validator from '~/utils/validation/Validator'
import ChangesTable from './ChangesTable'
import ConfirmAdhocChangesModal from './ConfirmAdhocChangesModal'

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  displayedPhaseId: state.displayedPhaseId,
})

const mapDispatchToProps = dispatch => ({
  dispatchUpdateProcess: (processId, params) => dispatch(updateProcess({ processId, params })),
  dispatchCallUpdateProcess: (processId, params) =>
    callUpdateProcess(dispatch, processId, params),
  dispatchSetProcessBuilderValidationErrors: errors =>
    dispatch(setProcessBuilderValidationErrors({ errors })),
  dispatchSetDisplayedProcessPhase: phaseId =>
    dispatch(setDisplayedProcessPhase(phaseId)),
})

const ChangesFooter = ({
  activeProcess,
  activeDevice,
  processDescriptionSnapshots,
  displayedPhaseId,
  dispatchCallUpdateProcess,
  dispatchUpdateProcess,
  dispatchSetProcessBuilderValidationErrors,
  dispatchSetDisplayedProcessPhase,
}) => {

  const [ showConfirmationModal, triggerConfirmationModal ] = useState(false)
  const [ applyingChanges, setApplyingChanges ] = useState(false)

  const renderLatestPhase = () => {
    const latestPhase = PhaseProgression.getLatest(activeProcess)
    const newDisplayedPhaseId = latestPhase ? latestPhase.phaseId : displayedPhaseId
    dispatchSetDisplayedProcessPhase(newDisplayedPhaseId)
  }

  const amendDisplayedPhaseId = revertedDescription => {
    const phase = DescriptionParser.getPhaseById(activeProcess, displayedPhaseId)

    if (!phase) {
      renderLatestPhase()
      return
    }

    const { iterationOf, groupName } = phase

    if (!groupName || Validator.isUndefinedOrNull(iterationOf)) {
      renderLatestPhase()
      return
    }

    const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(activeProcess, iterationOf)
    phaseIterationIds.unshift(iterationOf)

    const prevDisplayedIndex = phaseIterationIds
      .findIndex(aPhaseId => aPhaseId === displayedPhaseId)

    const prevPhaseIds = phaseIterationIds.slice(0, prevDisplayedIndex)
    prevPhaseIds.reverse()

    const firstPossiblePhaseId = prevPhaseIds.find(aPhaseId => aPhaseId in revertedDescription)

    if (firstPossiblePhaseId !== undefined) {
      dispatchSetDisplayedProcessPhase(firstPossiblePhaseId)
      return
    }

    renderLatestPhase()
  }

  const revertChanges = () => {
    const revertedDescription = PDVC.revertToSnapshot(activeProcess, processDescriptionSnapshots)

    if (!DescriptionParser.hasPhaseWithId(revertedDescription, displayedPhaseId)) {
      amendDisplayedPhaseId(revertedDescription)
    }

    dispatchUpdateProcess(activeProcess.id, { description: revertedDescription })
    dispatchSetProcessBuilderValidationErrors([])
  }

  const confirmChanges = async diffs => {
    setApplyingChanges(true)

    try {
      const { description: descriptionBefore } = PDVC
        .getSnapshot(activeProcess.id, processDescriptionSnapshots)
      const { updatedProcess, elapsedTime, entryPhaseId } = await PDVC
        .applyDescriptionModification(activeProcess, processDescriptionSnapshots, diffs)
      const updateParams = { description: updatedProcess.description }
      /* TODO: ADD_ERROR_HANDLING */
      await dispatchCallUpdateProcess(updatedProcess.id, updateParams)

      /* TODO: ADD_ERROR_HANDLING */
      const { status } = await nexus.command
        .device.process.updateRunning(
          activeProcess.deviceId,
          activeProcess.id,
          { elapsedTime, entryPhaseId },
        )

      if (status === 413) {
        /* this is too specific to assume 413 is the tooLargeError
         * using this for now as its done in StartProcessButton the same way
         * and because it is planned to be removed
         * when we get rid of MQTT for this type of communication
         *
         * this is JANKY but I beleive a better a solution than rejecting process
         * updates that are too large, as that effects a large amount of process
         * update calls throughout the front end
         */
        const tooLargeError = ProcessValidator.createExceedingProcessSizeError()
        dispatchSetProcessBuilderValidationErrors([ tooLargeError ])
        window.scrollTo(0, 0)
        // revert back to last process
        /* TODO: ADD_ERROR_HANDLING */
        await dispatchCallUpdateProcess(updatedProcess.id, { description: descriptionBefore })
        // This is done here for the user experience of the ad-hoc changes table
        dispatchUpdateProcess(activeProcess.id, { description: updatedProcess.description })
      }
    } catch ({ message }) {
      FlashMessenger.error(message, 10000)
    } finally {
      setApplyingChanges(false)
      triggerConfirmationModal(false)
    }
  }

  const evaluateChanges = async diffs => {

    const errors = ProcessValidator.validateDescription(activeProcess, activeDevice)
    dispatchSetProcessBuilderValidationErrors(errors)

    if (errors.length) return

    const runningPhaseId = Process.getLatestPhaseProgressionEntry(activeProcess).phaseId
    const insertPhase = PDVC.hasInputNodeValuesChange(diffs, runningPhaseId)

    if (insertPhase) {
      triggerConfirmationModal(true)
      return
    }
    await confirmChanges(diffs)
  }

  const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

  if (!diffs.length) return null

  const snapshot = PDVC.getSnapshot(activeProcess.id, processDescriptionSnapshots)

  return (
    <Grid.Row>
      <Grid.Column width={13}>
        <Segment
          style={{ backgroundColor: '#FFFFFF' }}
        >
          {showConfirmationModal && (
            <ConfirmAdhocChangesModal
              open
              closeHandler={() => triggerConfirmationModal(false)}
              confirmHandler={() => confirmChanges(diffs)}
              process={activeProcess}
              applyingChanges={applyingChanges}
            />
          )}
          <Header as='h3'>Process Changes</Header>
          <ChangesTable
            diffs={diffs}
            activeProcess={activeProcess}
            snapshot={snapshot}
          />
          <Button.Group>
            <Button
              primary
              onClick={() => evaluateChanges(diffs)}
              disabled={(
                activeProcess.outdated
                || applyingChanges
                || Device.hasInconsistentState(activeDevice)
              )}
            >
              <Icon name='check'/>
              Apply
            </Button>
            <Button.Or />
            <Button
              disabled={applyingChanges}
              onClick={() => revertChanges()}
            >
              <Icon name='undo'/>
              Undo
            </Button>
          </Button.Group>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangesFooter)
