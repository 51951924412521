import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { getTotalDuration, getXAxisLabelFormater } from '~/utils/GraphTimeStampsFormatter'
import Time from '~/utils/Time'
import GraphTools from '~/utils/GraphTools'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import PhaseProgression from '~/utils/process/PhaseProgression'
import DataManager from '~/utils/DataManager'
import { Process } from '~/utils/process'
import './OspinLineChart.css'
import { Line } from 'react-chartjs-2';
import { getRelativePosition } from 'chart.js/helpers';

import zoomPlugin from 'chartjs-plugin-zoom';
import annotationsPlugin from 'chartjs-plugin-annotation'
import CrosshairPlugin from 'chartjs-plugin-crosshair'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


/// https://github.com/AbelHeinsbroek/chartjs-plugin-crosshair/issues/119
const CustomCrosshairPlugin = function (plugin) {
  const originalAfterDraw = plugin.afterDraw;
  plugin.afterDraw = function(chart, easing) {
      if (chart && chart.crosshair) {
        originalAfterDraw.call(this, chart, easing);
      }
  };
  return {...plugin, id: 'crosshair-plugin'};
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationsPlugin,
  CustomCrosshairPlugin(CrosshairPlugin)
);

function renderNoDataPlaceHolder(height) {
  return (
    <div style={{ height }}>
      <div className='ospin-line-chart-no-data-text'>
        No Data
      </div>
    </div>
  )
}

const getPhaseIdForTimestamp = (activeProcess, relativeTimestampInSeconds, min, max) => {
  const { progression } = activeProcess
  const x = progression.find(({ phaseId, startTime }) => {
    const relativePhaseStartTime = (startTime - activeProcess.startedAt) / 1000
    const nextPhase = PhaseProgression.getFollowingPhaseProgression(activeProcess, phaseId)

    if (!nextPhase) {
      return relativePhaseStartTime <= relativeTimestampInSeconds
    }

    return relativePhaseStartTime <= relativeTimestampInSeconds && (nextPhase.startTime - activeProcess.startedAt) / 1000 >= relativeTimestampInSeconds
  })

  return x
}

const getInputNodeId = (
  activeProcess, functionalityDescriptions, fct, slot
) => {
  const description = FunctionalityDescription
    .getBySubType(functionalityDescriptions, fct.subType)

  const targetSlotName = FunctionalityDescription
    .getAssociatedTargetSlotName(description, slot.name)

  if (!targetSlotName) return null

  const targetSlot = Functionality.getSlotByName(fct, targetSlotName)
  if (!targetSlot) return null

  const inputNodeFctId = FCTGraph
    .getInputNodeFctIdForSlot(activeProcess.fctGraph, fct.id, targetSlot.name)


  return inputNodeFctId
}


function createTargetMarkers(
  reporterData,
  activeProcess,
  functionalityDescriptions,
  showTargetLines,
) {

  if (!showTargetLines) { return {} }

  const phaseReporterArray = activeProcess.progression.flatMap(({ phaseId }) => {
    return reporterData.map((data) => {
      const { slot, fct, lineColor } = data

      const color = lineColor

      const inputNodeFctId = getInputNodeId(activeProcess, functionalityDescriptions, fct, slot)

      if (!inputNodeFctId) return null

      const val = DescriptionParser
        .getInputNodeValue(activeProcess, phaseId, inputNodeFctId)

      const prevPhase = PhaseProgression.getPreviousPhaseProgression(activeProcess, phaseId)
      const nextPhase = PhaseProgression.getFollowingPhaseProgression(activeProcess, phaseId)
      return {
        id: `${ phaseId }-${ inputNodeFctId }`,
        borderColor: color,
        borderDash: [6, 6],
        yMin: val,
        yMax: val,
        yScaleID: GraphTools.generateAxisId(fct.id, slot.name),
        xMin: prevPhase ? PhaseProgression.getSecondsSinceProcessStart(activeProcess, phaseId) : undefined,
        xMax: nextPhase ? PhaseProgression.getSecondsSinceProcessStart(activeProcess, nextPhase.phaseId) : undefined,
        xScaleID: 'x',
      }
    })
  })

  return phaseReporterArray.filter(a => !!a).reduce((a, v) => ({ ...a, [v.id]: v }), {})
}

function createPhaseStartMarkers(activeProcess, showPhaseLabel) {
  const { startedAt, progression } = activeProcess


  const ann = progression.map(({ startTime, phaseId }) => {
    const startValue = (startTime - startedAt) / 1000
    return {
      phaseId,
      type: 'line',
      borderColor: 'black',
      borderWidth: 1,
      label: {
        display: showPhaseLabel,
        content: PhaseGroupParser.getPhaseNameWithIterationIndicator(activeProcess, phaseId),
        position: 'end',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#900E2C',
        xAdjust: 6,
        rotation: 90
      },
      scaleID: 'x',
      value: startValue,
      enter({ element }) {
        if (showPhaseLabel) { return true }

        element.options.borderWidth = 2
        element.label.options.display = true;
        return true
    },
      leave({ element }) {
          if (showPhaseLabel) { return true }
          element.options.borderWidth = 1
          element.label.options.display = false;
          return true
      }
    }
  })
  return ann.reduce((a, v) => ({ ...a, [v.phaseId]: v }), {})
}

const createAnnotationMarkers = (clickHandler, activeProcess, timeStampsMode, derivedTimeDisplayFormat, showAnnotations) => {
  if (!showAnnotations) { return {} }

  const { annotations } = activeProcess
  const arr = annotations.map(ann => {
    const { id, userTimestamp,text } = ann
    const relativeTimeStampInS =  (userTimestamp - activeProcess.startedAt) / 1000
    const formattedTimeStamp = getXAxisLabelFormater(
      timeStampsMode,
      activeProcess,
      derivedTimeDisplayFormat,
    )(relativeTimeStampInS)
    return {
      id,
      type: 'line',
      borderColor: 'blue',
      borderWidth: GraphTools.ANNOTATATION_BORDER_WITH,
      label: {
        enabled: false,
        backgroundColor: 'white',
        borderWidth: 1,
        drawTime: 'afterDatasetsDraw',
        color: 'black',
        content: [formattedTimeStamp, text],
        textAlign: 'center'
      },
      scaleID: 'x',
      value: relativeTimeStampInS,
      click({ id }) {clickHandler(GraphTools.CLICK_HANDLER_OBJECTS.ANNOTATION, { annotationId: id }) },
      enter({ element }) {
        element.options.borderWidth = GraphTools.ANNOTATATION_BORDER_WITH * 2
        element.label.options.display = true;
        return true
    },
      leave({ element }) {
          element.options.borderWidth = GraphTools.ANNOTATATION_BORDER_WITH
          element.label.options.display = false;
          return true
      }
    }

  })
  return arr.reduce((a, v) => ({ ...a, [v.id]: v }), {})
}

const OspinLineChart = ({
  activeProcess,
  reporterData,
  rangeSetting,
  displaySettings,
  includeZero = true,
  containerProps = { height: 400 },
  functionalityDescriptions,
  setManualRange,
  graphRef,
  clickHandler,
  zoomMode,
  displayDataPoints = false
}) => {

  const { minTime, maxTime } = GraphTools
  .deriveTimeBoundariesFromMode(rangeSetting, activeProcess)

  const lastFetchRef = useRef({minTime,maxTime})

  const { mode, selectedPhaseId  } = rangeSetting
  const { showPhaseLabel, showTargetLines, timeStampsMode, showAnnotations } = displaySettings

  const reporterFctIds = reporterData
    .map(({ reporterFctId }) => reporterFctId)

  const allSensorData = reporterFctIds
    .map(reporterFctId => DataManager.getSensorDataPoints(activeProcess, reporterFctId))

  function fetchData() {
    let idx = 0
    for (const reporterFctId of reporterFctIds) {
      GraphTools.fetchDataInViewport({
        minTime,
        maxTime,
        activeProcess,
        sensorData: allSensorData[idx],
        reporterFctId,
        loadMaterializedView: Process.isRunningOrPaused(activeProcess) && mode === GraphTools.DISPLAY_MODES.FULL,
      })
      idx = idx + 1
    }
    lastFetchRef.current = { minTime, maxTime }

  }


  /// Loading on Navigation
  useEffect(() => {
    if (!lastFetchRef.current || (lastFetchRef.current.minTime === minTime && lastFetchRef.current.maxTime === maxTime) ) { return }
    if (Process.isExecutable(activeProcess)) return

    fetchData()
  }, [rangeSetting])

  if (Process.isExecutable(activeProcess)) return renderNoDataPlaceHolder(containerProps.height)

  function generateDataSets() {
    return reporterData.map(singlereporterData => {
      const { lineColor, slot, fct, reporterFctId } = singlereporterData
      const data = DataManager.getSensorDataPoints(activeProcess, reporterFctId)
      return {
        targetDisplayInputNodeFctId: getInputNodeId(activeProcess, functionalityDescriptions, fct, slot),
        unit: slot.unit,
        name: slot.name,
        yAxisID: GraphTools.generateAxisId(fct.id, slot.name),
        data: data,
        parsing: false,
        normalized: true,
        borderColor: lineColor,
        borderWidth: 1.5,
        fill: false,
        spanGaps: true,
        pointStyle: 'circle',
        animation: false,
        pointHitRadius: GraphTools.DATAPOINT_HIT_RADIUS,
        pointRadius: 0,
        pointHoverBackgroundColor: 'green',
        pointHoverRadius: displayDataPoints ? 5 : 0,
      }
    })
  }

  function generateScales() {
    const yAxis = {}
    reporterData.forEach(({ fct, slot, lineColor }, idx) => {
      const axisId = GraphTools.generateAxisId(fct.id, slot.name)
      const isManualFrame = mode === GraphTools.DISPLAY_MODES.CUSTOM
      const hasCustomMinMax = isManualFrame && rangeSetting[axisId] && rangeSetting[axisId].max && rangeSetting[axisId].min
      const name = reporterData.length !== 1 ? `${fct.name}:${ slot.name } [${ slot.unit }]` : `${ slot.name } [${ slot.unit }]`

      yAxis[axisId] = {
        beginAtZero: includeZero,
        position: idx % 2 ? 'right' : 'left',
        title: {
          display: true,
          color: lineColor,
          text: name
        },
        max: hasCustomMinMax ? rangeSetting[axisId].max : null,
        min: hasCustomMinMax ? rangeSetting[axisId].min : null,
        grace: '5%'
      }
    })

    const maxTimeInMS = Process.isRunningOrPaused(activeProcess) ? Date.now() - activeProcess.startedAt  :activeProcess.finishedAt -  activeProcess.startedAt
    const maxTimeInS = maxTimeInMS / 1000


    return {
      x: {
        type: 'linear',
        ticks: {
          maxTicksLimit: 20,
          callback: ((val, idx) => {
            return getXAxisLabelFormater(
              timeStampsMode,
              activeProcess,
              derivedTimeDisplayFormat,
            )(val)
          })
        },
        min: minTime,
        max: maxTime || maxTimeInS
      },
      ...yAxis
    }

  }


  const datasets = generateDataSets()

  const totalDuration = getTotalDuration(datasets)
  const derivedTimeDisplayFormat = Time.deriveTimeDisplayFormat(totalDuration)

  function generateOptions() {
    return {
      onClick: (evt) => {
        const element = evt.chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)[0];

        if (!element) { return }

        const { datasetIndex } = element
        const { yAxisID: selectedYAxisId }  = evt.chart.getDatasetMeta(datasetIndex)

        const canvasPosition = getRelativePosition(evt, evt.chart);
        const relativeTimestamp = evt.chart.scales.x.getValueForPixel(canvasPosition.x)

        clickHandler(
          GraphTools.CLICK_HANDLER_OBJECTS.DATAPOINT,
          {
            relativeTimestamp: Math.round(relativeTimestamp),
            absoluteTimestamp: activeProcess.startedAt + Math.round(relativeTimestamp) * 1000,
            value: evt.chart.scales[selectedYAxisId].getValueForPixel(canvasPosition.y),
            data: datasets.find(({yAxisID}) => yAxisID === selectedYAxisId).data
          }
        )
      },
      animation: false ,

      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: true,
        mode: 'nearest',
      },
      scales: generateScales(),
      hover: {
        mode: 'index',
        intersec: false
      },
      plugins: {
        decimation: {enabled: true, algorithm: 'min-max'},
        crosshair: {
          line: {
            color: GraphTools.DEFAULT_COLOR,
            width: 1
          },
        },
        annotation: {
          annotations: {
            ...createPhaseStartMarkers(activeProcess, showPhaseLabel, minTime, maxTime),
            ...createTargetMarkers(reporterData, activeProcess, functionalityDescriptions, showTargetLines),
            ...createAnnotationMarkers(clickHandler, activeProcess, timeStampsMode,derivedTimeDisplayFormat, showAnnotations )
          }
        },
        tooltip: {
          enabled: true,
          intersect: false,
          position: 'nearest',
          usePointStyle: false,
          mode: 'index',
          callbacks: {
            title: (data) => {
              const { parsed } = data[0]
              const { phaseId } = getPhaseIdForTimestamp(activeProcess, parsed.x)
              const name = PhaseGroupParser.getPhaseNameWithIterationIndicator(activeProcess, phaseId)
              const { x } = parsed
              const formattedTimeStamp = getXAxisLabelFormater(
                timeStampsMode,
                activeProcess,
                derivedTimeDisplayFormat,
              )(x)
              return `${ name } - ${ formattedTimeStamp }`
            },
            label: ({ dataset, formattedValue, parsed }) => {
              const { x: secondsSinceProcessStart } = parsed
              const { targetDisplayInputNodeFctId, name, unit } = dataset
              const { phaseId } = getPhaseIdForTimestamp(activeProcess, secondsSinceProcessStart)

              if (!targetDisplayInputNodeFctId) {
                return `${ name } : ${ formattedValue } ${unit}`
              }

              const targetValue = DescriptionParser.getInputNodeValue(activeProcess, phaseId, targetDisplayInputNodeFctId)

              return `${ name } : ${ formattedValue } ${unit} (Target: ${ targetValue } ${unit})`
            }
          }
        },
        legend: {
          display: false
        },
        zoom: {
          enabled: true,
          limits: {
            x: {
              min: 0,
              max: Process.isRunningOrPaused(activeProcess) ?  (Date.now() - activeProcess.startedAt ) / 1000 : (activeProcess.finishedAt - activeProcess.startedAt) / 1000,
              minRange: 15
            }
          },
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',

            onPanComplete: (({chart}) => {
              const { min, max } = chart.scales.x;
              setManualRange({ x: { min, max }})
            })
          },
          zoom: {
            drag: {
              enabled: true
            },
            mode: zoomMode,
            scaleMode: zoomMode,
            onZoomComplete: (({ chart }) => {
              const getYScaleLimit = () => {
                const yScaleIds = Object.keys(chart.scales).filter((id) => id !== 'x')
                return yScaleIds.reduce((a, v) => ({ ...a, [v]: {min: chart.scales[v].min, max: chart.scales[v].max} }), {})
              }

              const getXScaleLimit = () =>  { return { min: chart.scales.x.min, max: chart.scales.x.max } }

              switch (zoomMode) {
                case 'x':
                  setManualRange({ x: getXScaleLimit() })
                  break;
                case 'xy':
                  setManualRange({ x: getXScaleLimit(), ...getYScaleLimit() })
                break
                case 'y':
                  setManualRange({...getYScaleLimit() , x: { min: minTime, max: maxTime }})

                  break
                default:
                  break;
              }
            })
          }
        },
      }

    }
  }


  if (!datasets.length || !datasets[0].data.length) {
    return renderNoDataPlaceHolder(containerProps.height)
  }

  return (
    <div  style={{ height: containerProps.height }}  >

        <Line
        ref={graphRef}
        data={{
          datasets: datasets,
        }}
        options={generateOptions()}
        style={{ width: '100%' }}
      />

    </div>
  )
}

export default connect(mapStateToProps)(OspinLineChart)
