import DescriptionParser from '~/utils/process/DescriptionParser'
import { FCTGraph, Functionality } from '@ospin/fct-graph'

export default class FunctionalityDescription {

  static get RELATION_TYPES() {
    return {
      REQUIRED_ONLY_WHEN: 'REQUIRED_ONLY_WHEN',
      HIDE_INPUT_WHEN: 'HIDE_INPUT_WHEN',
      TRACK_SLOT_VALUE: 'TRACK_SLOT_VALUE'
    }
  }

  static getBySubType(descriptions, subType) {
    return descriptions.find(desc => desc.subType === subType)
  }

  static evaluateTriggerToBeEqual({
    fctId,
    targetSlotName,
    targetSlotValue,
    phaseId,
    process
  }) {
    const { fctGraph } = process

    const fct = FCTGraph.getFctById(fctGraph, fctId)
    if (!fct) return false

    const slot = Functionality.getSlotByName(fct, targetSlotName)
    if (!slot) return false

    const inputNodeFctId = FCTGraph
      .getInputNodeFctIdForSlot(fctGraph, fctId, targetSlotName)

    if (!inputNodeFctId) {
      // the InSlot might not be controlled by the user
      // but by another functionality, so it might not have
      // an inputNodeFctId; in this case we always render it
      // because we don't know what the value is; it might change
      // dynamically during a single phase
      return false
    }

    const inputNodeValue = DescriptionParser
      .getInputNodeValue(process, phaseId, inputNodeFctId)

      if (inputNodeValue === undefined) return false

    return targetSlotValue === inputNodeValue
  }

  static shouldHideInSlot({
    functionalityDescription,
    fctId,
    slotName,
    process,
    phaseId,
  }) {
    if (!functionalityDescription) return false

    const { slotRelations } = functionalityDescription
    const hideInputRelation = slotRelations
    .find(rel => rel.type === FunctionalityDescription.RELATION_TYPES.HIDE_INPUT_WHEN
      && rel.slotName === slotName)


    if (!hideInputRelation) {
      return false
    }

    const { data } = hideInputRelation

      return data.some(({triggerType, triggers}) => {
        switch (triggerType) {
          case 'OR':
            return triggers.some(({targetSlotName, targetSlotValue}) => FunctionalityDescription.evaluateTriggerToBeEqual({fctId, targetSlotName, targetSlotValue,phaseId, process}))
          case 'AND':
            return triggers.every(({targetSlotName, targetSlotValue}) => FunctionalityDescription.evaluateTriggerToBeEqual({fctId, targetSlotName, targetSlotValue,phaseId, process}))
          default:
            return false
        }
      })

  }

  static getAssociatedTargetSlotName(functionalityDescription, slotName) {
    if (!functionalityDescription) return null

    const { slotRelations } = functionalityDescription

    const relations = slotRelations
      .filter(rel => rel.type === FunctionalityDescription.RELATION_TYPES.TRACK_SLOT_VALUE
        && (rel.data.slotName === slotName || rel.slotName === slotName))

    /* for now, we only support a single target value for a sensor */
    if (relations.length !== 1) return null
    const { targetSlotName } = relations[0].data

    return targetSlotName
  }

  static getProperty(functionalityDescription, subType, property) {
    const def = FunctionalityDescription.getBySubType(functionalityDescription, subType)
    return (def && def[property]) || null
  }

  static getFunctionalityDisplayName(functionalityDescription, subType) {
    const def = FunctionalityDescription.getBySubType(functionalityDescription, subType)
    return (def && def.displayName) || subType
  }

  static getFunctionalityDescription(functionalityDescription, subType) {
    return FunctionalityDescription.getProperty(functionalityDescription, subType, 'description')
  }

  static getFunctionalityIcon(functionalityDescription, subType) {
    return FunctionalityDescription.getProperty(functionalityDescription, subType, 'iconURL')
  }

  static getInputDescriptionImageURL(functionalityDescription, subType) {
    return FunctionalityDescription.getProperty(functionalityDescription, subType, 'inputDescriptionImageURL')
  }

  static getSlotDescription(functionalityDescription, subType, name) {
    const description = FunctionalityDescription.getBySubType(functionalityDescription, subType)
    if (!description) { return }

    const { slotDescriptions } = description

    return slotDescriptions.find(({ slotName }) => slotName === name)?.description || null
  }

}
