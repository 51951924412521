import React from 'react'
import { connect } from 'react-redux'
import { Grid , Image } from 'semantic-ui-react'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })

function TemplateFctDescription({ functionality, functionalityDescriptions }) {
  const { subType, props: { isVirtual } } = functionality

  const description = FunctionalityDescription.getFunctionalityDescription(
    functionalityDescriptions, subType,
  )
  const imageUrlHandler = isVirtual ? 'getInputDescriptionImageURL' : 'getFunctionalityIcon'
  const imageUrl = FunctionalityDescription[imageUrlHandler](functionalityDescriptions, subType)

  if (!description && !imageUrl) {
    return null
  }

  return (
    <div>
      <Grid celled>
        <Grid.Row style={{display: 'flex', justifyContent: 'center'}}><b>Description</b></Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            {description || 'No Description Available'}
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {imageUrl ? <Image style={{ height: '20rem' }} src={imageUrl} /> : null}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}


export default connect(mapStateToProps, null)(TemplateFctDescription)
