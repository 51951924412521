import nexus from '@ospin/nexus'
import userChannelHandler from '~/redux/pusher/userChannel/userChannelHandler'
import { OspinPusherClient, UserPusherChannel } from '@ospin/pusher'
import { setSession } from '~/redux/actions/actions'
import setCognitoIdentityId from '~/redux/thunks/helper/setCognitoIdentityId'
import { getDeploymentEnv } from '~/config/envDetector'
import createPusherStateChangeHandler from '~/redux/pusher/createPusherStateChangeHandler'

const callLogin = async (dispatch, usernameOrEmail, password) => {
  const { data: result } = await nexus.auth.signIn(usernameOrEmail, password)
  const session = result.signInUserSession

  if (!session) return

  const userSub = session.accessToken.payload.sub
  const { data: userData } = await nexus.user.get(userSub)

  dispatch(setSession({ authenticated: true, userData }))

  await Promise.all([
    setCognitoIdentityId(userData, userSub, dispatch),
    OspinPusherClient
      .connect({
        env: getDeploymentEnv(),
        userId: userSub,
        authUserSubscriptions: nexus.user.pusher.subscriptions.authorizeMany,
        authDeviceSubscriptions: nexus.user.pusher.subscriptions.device.authorizeMany,
        authDeviceProcessSubscriptions: nexus.user.pusher.subscriptions.device.process
          .authorizeMany,
      }),
  ])

  UserPusherChannel.subscribe({ userId: userData.id }, userChannelHandler(userData.id))
  OspinPusherClient
    .registerConnectionEvent('state_change', createPusherStateChangeHandler(dispatch))
}

export default callLogin
