import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider } from 'semantic-ui-react'
import HelpIconPopup from '~/components/utility/HelpIconPopup'
import { IconMenu } from '~/components/device/processes/ProcessIconBar'
import callUpdateProcessOptimistic from '~/redux/thunks/process/callUpdateProcessOptimistic'
import { Process } from '~/utils/process'
import Time from '~/utils/Time'
import PDVC from '~/utils/PDVC'
import PhaseProgression from '~/utils/process/PhaseProgression'

import './ProcessLabel.css'

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateProcessOptimistic:
  (processId, params) => callUpdateProcessOptimistic(dispatch, processId, params),
})

function handleToggleFavorite(event, processId, pinned, dispatchCallUpdateProcessOptimistic) {
  event.stopPropagation()
  /* TODO: ADD_ERROR_HANDLING */
  dispatchCallUpdateProcessOptimistic(processId, { pinned: !pinned })
}

const getColor = state => {
  switch (state) {
    case 'paused':
      return '#ff8c00'
    case 'running':
      return '#1578c2'
    default:
      return '#585858'
  }
}

const renderPrefixWithPopup = prefixText => (
  <span>
    <span>{`${prefixText} `}</span>
    <HelpIconPopup
      description='The process contains manual phases which have not finished yet, therefore we cannot predict the exact duration of the process - the shown value considers only duration based phases'
      position='bottom left'
      iconStyle={{
        marginLeft: '4px',
        marginRight: '0px',
      }}
    />
  </span>
)

const renderRow = (label, content, contentStyle = {}) => (
  <span style={{ color: '#585858', display: 'block' }}>
    <span>
      {label}:&nbsp;&nbsp;
    </span>
    <span style={{ fontWeight: 'bold', ...contentStyle }}>
      {content}
    </span>
  </span>
)

const renderStartedAtOrFixedRunTime = process => {

  if (!Process.isExecutable(process)) {
    return renderRow('started', Time.getTrimmedDateAndTimeString(process.startedAt))
  }

  const fixedDuration = Process.getTotalFixedProcessDuration(process)

  if (fixedDuration === 0) return renderRow('fixed run time', renderPrefixWithPopup('only manual phases'))

  const hasManualPhases = Process.hasManuallyTransitionedPhases(process)
  const renderPrefix = () => (hasManualPhases
    ? renderPrefixWithPopup('at least')
    : ''
  )
  return renderRow('fixed run time', <>{renderPrefix()}{Time.stringFromDuration(fixedDuration / 1000)}</>)
}

const renderRunDuration = process => {

  if (Process.isRunning(process) || Process.isPaused(process)) {
    const { startedAt } = process
    const processDurationInSeconds = (Date.now() - startedAt) / 1000
    const durationString = Time.stringFromDuration(processDurationInSeconds)
    return renderRow('run duration', durationString)
  }
  return null
}

const renderRemainingOrTotalDuration = process => {

  if (Process.isExecutable(process)) {
    return null
  }

  if (Process.isFinished(process)) {
    const { startedAt, finishedAt } = process
    const processDurationInSeconds = (finishedAt - startedAt) / 1000
    const durationString = Time.stringFromDuration(processDurationInSeconds)
    return renderRow('total run duration', durationString)
  }
  const fixedDuration = Process.getRemainingFixedProcessDurationInMS(process)
  if (fixedDuration === 0) return renderRow('time remaining', renderPrefixWithPopup('only manual phases'))

  const runningPhase = Process.getRunningPhaseDetails(process)

  const hasNonFinishedManualPhases = Process.hasManuallyTransitionedPhases(process, runningPhase.id)
  const renderPrefix = () => (hasNonFinishedManualPhases
    ? renderPrefixWithPopup('at least')
    : ''
  )
  return renderRow('time remaining', <>{renderPrefix()}{Time.stringFromDuration(fixedDuration / 1000)}</>)
}

const renderFinishedAtOrEstimatedFinishedAt = process => {

  if (Process.isExecutable(process)) {
    return null
  }

  if (Process.isFinished(process)) {
    const { finishedAt } = process
    return renderRow('finished', Time.getTrimmedDateAndTimeString(finishedAt))
  }

  const fixedDuration = Process.getRemainingFixedProcessDurationInMS(process)

  const runningPhase = Process.getRunningPhaseDetails(process)

  const hasNonFinishedManualPhases = Process.hasManuallyTransitionedPhases(process, runningPhase.id)
  const renderPrefix = () => (hasNonFinishedManualPhases
    ? renderPrefixWithPopup('earliest')
    : ''
  )
  return renderRow('end date', <>{renderPrefix()}{Time.getTrimmedDateAndTimeString(Date.now() + fixedDuration)}</>)
}

const ProcessLabel = ({
  activeProcess,
  dispatchCallUpdateProcessOptimistic,
  toggleModal,
  processDescriptionSnapshots,
  showProcessDetailsModal,
}) => {

  // date is updated via an interval function to force a render

  const setDate = useState(Date.now())[1]

  const processSnapshot = PDVC.getSnapshot(activeProcess.id, processDescriptionSnapshots)
  const processUsedForTimeInfoDisplay = processSnapshot
    ? { ...activeProcess, ...processSnapshot }
    : activeProcess

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(Date.now())
    }, 300)

    return () => clearInterval(interval)
  })

  return (
    <div className='process-label-info'>
      <div className='process-label-process-name-icon-bar'>
        <span className='process-label-process-name'>
          {activeProcess.name}
        </span>
        <span className='.process-label-icon-bar'>
          <IconMenu
            toggleModal={toggleModal}
            handleToggleFavorite={e => handleToggleFavorite(
              e,
              activeProcess.id,
              activeProcess.pinned,
              dispatchCallUpdateProcessOptimistic,
            )}
            isPinned={activeProcess.pinned}
            contentJustificationClass='flex-start negative-left-margin-10'
            showProcessDetailsModal={showProcessDetailsModal}
          />
        </span>
      </div>

      <Divider
        style={{ margin: '0.5rem 0' }}
      />
      {renderRow('state', activeProcess.state, { color: getColor(activeProcess.state), fontWeight: 'bold' })}
      {renderStartedAtOrFixedRunTime(processUsedForTimeInfoDisplay)}
      { PhaseProgression.hasStarted(activeProcess) && (
        <>
          {renderRunDuration(processUsedForTimeInfoDisplay)}
          {renderRemainingOrTotalDuration(processUsedForTimeInfoDisplay)}
          {renderFinishedAtOrEstimatedFinishedAt(processUsedForTimeInfoDisplay)}
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessLabel)
