import PhaseProgression from '~/utils/process/PhaseProgression'

export default class EventHistoryParser {

  static getPhaseRelatedEvents(events, queryPhaseId) {
    return events.filter(({ phaseId }) => phaseId === queryPhaseId)
  }

  static getPauseRelatedEvents(events) {
    return events.filter(({ type }) => (
      type === 'PAUSE' || type === 'RESUME'
    ))
  }

  static sortByCreatedAt(events) {
    return events.sort((a, b) => a.createdAt - b.createdAt)
  }

  static isValidPauseEventSequence(events) {
    const startsWithAPauseEvent = phaseEvents => phaseEvents[0].type === 'PAUSE'
    const eventTypes = ['PAUSE', 'RESUME']

    const eventsAlternate = phaseEvents => {
      const types = startsWithAPauseEvent ? eventTypes : eventTypes.reverse()
      return phaseEvents.every(({ type }, idx) => type === types[idx % 2])
    }

    return eventsAlternate(events)
  }

  static lastEventWasPause(sortedEvents) {
    return (
      sortedEvents.length
      && sortedEvents[sortedEvents.length - 1].type === 'PAUSE'
    )
  }

  static insertArtificialPauseEvent(sortedEvents, phaseId, process) {
    const phaseStart = PhaseProgression.getPhaseById(process, phaseId).startTime
    const artificialEvent = { type: 'PAUSE', createdAt: phaseStart, phaseId }
    return [ artificialEvent, ...sortedEvents ]
  }

  static safeInsertArtificialPauseEvent(sortedEvents, phaseId, process) {
    const pauseStartedInPreviousPhase = sortedEvents[0].type === 'RESUME'

    if (!pauseStartedInPreviousPhase) return sortedEvents

    return EventHistoryParser.insertArtificialPauseEvent(sortedEvents, phaseId, process)
  }

  static insertArtificialResumeEvent(sortedEvents, phaseId) {
    const lastPauseStillRunning = EventHistoryParser.lastEventWasPause(sortedEvents)

    if (!lastPauseStillRunning) return sortedEvents

    const artificialEvent = { type: 'RESUME', createdAt: Date.now(), phaseId }
    return [ ...sortedEvents, artificialEvent ]
  }

  static insertComplementaryEvents(sortedEvents, phaseId, process) {
    const eventsWithArtificialPauseEvent = EventHistoryParser
      .safeInsertArtificialPauseEvent(sortedEvents, phaseId, process)

    return EventHistoryParser.insertArtificialResumeEvent(eventsWithArtificialPauseEvent, phaseId)
  }

  static getFullPausedDurationFromEvents(process, phaseId) {
    const { eventHistory: rawEvents } = process
    const sortedProcessEvents = EventHistoryParser.sortByCreatedAt(rawEvents)

    const sortedPhaseEvents = EventHistoryParser.getPhaseRelatedEvents(sortedProcessEvents, phaseId)
    let sortedPhasePauseEvents = EventHistoryParser.getPauseRelatedEvents(sortedPhaseEvents)
    const lastProcessEventWasPause = EventHistoryParser.lastEventWasPause(sortedProcessEvents)

    /* This is to handle the following case:
     * - while process is paused, ad-hoc change auto creates a new phase
     * - the auto creation also triggers a transition in to the new phase
     * - the device remains paused, but the current phase has no pause event
     * - the last phase has an unresolved pause event */
    if (lastProcessEventWasPause && sortedPhasePauseEvents.length === 0) {
      sortedPhasePauseEvents = EventHistoryParser.insertArtificialPauseEvent(
        sortedPhasePauseEvents,
        phaseId,
        process,
      )
    }

    if (sortedPhasePauseEvents.length === 0) { return 0 }

    let totalPausedDurationInSeconds = 0

    const events = EventHistoryParser.insertComplementaryEvents(
      sortedPhasePauseEvents,
      phaseId,
      process,
    )

    if (!EventHistoryParser.isValidPauseEventSequence(events)) {
      throw new Error('Cannot derive pause duration because the process events are incomplete')
    }

    while (events.length) {
      const pauseEndEvent = events.pop()
      const pauseStartEvent = events.pop()

      totalPausedDurationInSeconds += (pauseEndEvent.createdAt - pauseStartEvent.createdAt) / 1000
    }

    return totalPausedDurationInSeconds
  }
}
