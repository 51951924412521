import React from 'react'
import PhysicalMappingOverview from '~/components/device/setup/PhysicalMapping/PhysicalMappingSelection'

const PortsChangedNotification = ({ device, fctGraph }) => (
  <div>
    <p>
    We have automatically updated the assigned ports of this setup. Please make sure that everything is connected how you expect it.
    </p>
    <PhysicalMappingOverview
      activeDevice={device}
      selectedGraph={fctGraph}
    />
  </div>
)

export default PortsChangedNotification
