import React from 'react'
import { Form, Popup } from 'semantic-ui-react'
import Validator from '~/utils/validation/Validator'
import { replaceNoUnitCharacter } from '~/utils/units'
import SlotUtils from '~/utils/functionalities/slots/Slot'
import { Slot } from '@ospin/fct-graph'
import Unit from '../Unit'

/* This component is used in the process builder to receive target values for an input node of a
 * process, for executable processes and for running processes (then via ad-hoc API)
 * The single source of truth should be the process description, from where the target is loaded
 * and to where it is saved. But because we need intermediate storage for unit conversion, the
 * entered value is stored in local state while the user is entering an input ('takingInput')
 * and then applied to the process on blur. The intermediate storage is flushed everytime to
 * prevent overwriting the process target during a running process after ad-hoc changes were
 * dismissed but the local state still holds the value that has been dismissed from the process
 */

export default class IntegerInSlot extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      takingInput: false,
      currentInput: '',
      nameHovered: false,
    }
  }

  componentWillUnmount() {
    /* this guarantees that we update the input when somebody collpses the section
     * without blurring beforehand */
    if (this.state.takingInput) {
      this.save()
    }
  }

  submitInputNodeValue = event => {
    const newTarget = event.target.value
    if (!Validator.isIntegerString(newTarget) && newTarget !== '-' && newTarget !== '') return

    this.setState({ currentInput: newTarget, takingInput: true })
    event.stopPropagation()
  }

  save = async () => {
    const { inputNodeValue, updateInputNodeValue, inputNodeFctId } = this.props
    const { currentInput } = this.state

    if (
      inputNodeValue === currentInput
      || !Validator.isIntegerString(currentInput)
    ) {
      this.setState({ takingInput: false })
      return
    }

    updateInputNodeValue(inputNodeFctId, parseInt(Number(currentInput), 10))
    /* always deleting the curentInput after it was applied
     * to prevent it from overwriting the process target on blur
     * after an ad-hoc change has been dismissed
     */
    this.setState({ takingInput: false, currentInput: '' })
  }

  renderTextBoxWithUnit = () => {
    const { inputNodeValue, disabledInput, slot } = this.props
    const { currentInput, takingInput, nameHovered } = this.state

    return (
      <Form.Field error={SlotUtils.valueOutOfLimits(slot, inputNodeValue)}>
        <Form.Input action>
          <Popup
            trigger={(
              <input
                value={takingInput ? currentInput : inputNodeValue}
                type='text'
                onChange={this.submitInputNodeValue}
                onBlur={this.save}
                onClick={this.submitInputNodeValue}
                disabled={disabledInput}
              />
            )}
            content={this.getBoundariesText()}
            open={takingInput || nameHovered}
            position='right center'
          />
          <Unit
            slot={slot}
          />
        </Form.Input>
      </Form.Field>
    )
  }

  getBoundariesText = () => {
    const { slot } = this.props
    const { unit, min, max } = slot

    return `min: ${min} ${unit} | max: ${max} ${unit}`
  }

  renderDefault = () => {
    const { showDefault, slot, defaultValue } = this.props
    if (!showDefault) return null

    const unit = replaceNoUnitCharacter(slot.unit, '')

    return (
      <span style={{ color: 'grey', marginLeft: '8px' }}>
        {`[ default: ${defaultValue}${unit} ]`}
      </span>
    )
  }

  render() {
    const { slot } = this.props

    return (
      <div>
        <span
          style={{ fontWeight: 'bold' }}
          onMouseEnter={() => this.setState({ nameHovered: true })}
          onMouseLeave={() => this.setState({ nameHovered: false })}
        >
          {Slot.getDisplayName(slot)}
        </span>
        {this.renderDefault()}
        <Form.Group>
          {this.renderTextBoxWithUnit()}
        </Form.Group>
      </div>
    )
  }
}
