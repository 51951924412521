const getQueryParams = (location, defaults, queryValues) => {
  const query = new URLSearchParams(location.search)

  const getValue = param => {
    if (query.get(param) !== null) { return query.get(param) }

    return defaults[param] || null
  }

  return queryValues.reduce((a, v) => ({ ...a, [v]: getValue(v) }), {})

}

const setQueryParametersOnPageLoad = (location, history, defaults) => {
  const query = new URLSearchParams(location.search)
  const dafaultParams = Object.keys(defaults)

  dafaultParams.forEach(param => {
    const value = query.get(param)
    if (value === null) {
      query.set(param, defaults[param])
    }
  })

  history.replace(`?${query.toString()}`)
}

const toggleSortOrder = querySortOrder => (
  querySortOrder === 'ASC' ? 'DESC' : 'ASC'
)

const setSortByQueryParamsOnClick = (history, location, sortkey) => {
  const query = new URLSearchParams(location.search)
  const querySortBy = query.get('sortBy')
  const querySortOrder = query.get('sortOrder')

  if (querySortBy === sortkey) {
    query.set('sortOrder', toggleSortOrder(querySortOrder))
  } else {
    query.set('sortOrder', 'ASC')
  }

  query.set('sortBy', sortkey)

  /* go back to page 1 when changing filters */
  query.set('skip', 0)

  return history.replace(`?${query.toString()}`)
}

export {
  getQueryParams,
  setQueryParametersOnPageLoad,
  toggleSortOrder,
  setSortByQueryParamsOnClick,
}
