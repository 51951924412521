import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import SlotUtils from '~/utils/functionalities/slots/Slot'
import './LineChartWidgetBody.css'
import OspinLineChartWrapper from '~/components/processviewer/charts/OspinLineChartWrapper'

const WIDGET_PADDING_PX = 10
const WIDGET_CONTROLS_PADDING_PX = 100
const WIDGET_HEADER_HEIGHT = 41.5

export const LineChartHeader = ({
  displayedName,
  removeWidget,
  openEditModal,
  image,
}) => (
  <div className='line-chart-widget-header-layout'>
    <div className='line-chart-widget-header-icon-name'>
      <img src={image} className='line-chart-widget-header-icon' alt='Widget Icon' />
      <div className='ospin-line-chart-widget-slot-name'>
        {displayedName}
      </div>
    </div>
    <div className='transition-opacity ospin-line-chart-widget-dropdown-container'>
      <Dropdown
        className='ospin-line-chart-widget-opt-btn'
        item
        closeOnChange
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={openEditModal} text='Edit Chart' />
          <Dropdown.Item onClick={removeWidget} text='Delete Widget' />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </div>
)


const calcGraphHeight = height => {
  /* this is a best effort to try to size the line chart */
  return height - 2 * WIDGET_PADDING_PX - WIDGET_HEADER_HEIGHT - WIDGET_CONTROLS_PADDING_PX
}

const getDisplayedHeaderTitle = reporterData => {
  if (reporterData.length === 1) {
    const { fct, slot } = reporterData[0]
    return `${fct.name} : ${Slot.getDisplayName(slot)}`
  }

  return ''
}

const LineChartWidgetBody = ({
  activeProcess,
  widget,
  openEditModal,
  removeWidget,
  showControls,
  setShowControls
}) => {
  const { dataSources, display: { dimension: { height } } } = widget

  const { fctGraph } = activeProcess
  const reporterData = dataSources.map(({ fctId, slotName, lineColor }) => {
    const fct = FCTGraph.getFctById(fctGraph, fctId)
    const slot = Functionality.getSlotByName(fct, slotName)
    const reporterFctId = FCTGraph.getReporterFctIdForSlot(fctGraph, fct.id, slot.name)
    return {
      slot,
      fct,
      reporterFctId,
      lineColor,
    }
  })

  const { slot } = reporterData[0]

  const image = SlotUtils.getImage(slot)

  return (
    <div className='line-chart-widget-container'>
      <LineChartHeader
        displayedName={getDisplayedHeaderTitle(reporterData)}
        image={image}
        removeWidget={removeWidget}
        openEditModal={openEditModal}
        showControls={showControls}
        setShowControls={setShowControls}
      />
      <div style={{ padding: WIDGET_PADDING_PX, borderRadius: '0px 0 10px 10px', backgroundColor: 'white' }}>
        <OspinLineChartWrapper
          showControls={showControls}
          activeProcess={activeProcess}
          containerProps={{ height: calcGraphHeight(height) }}
          reporterData={reporterData}
          lineChartFormat='dashboard'
        />
      </div>
    </div>
  )
}

export default withRouter(LineChartWidgetBody)
