import React from 'react'
import { connect } from 'react-redux'

import modalize from '~/components/utility/modal/modalize'
import { updateDevice } from '~/redux/actions/actions'
import FailedFirmwareUpdateModalForm from '~/components/utility/FailedFirmwareUpdateModalForm'

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDevice: (deviceId, firmwareUpdate) => dispatch(
    updateDevice({ deviceId, params: { firmwareUpdate } }),
  ),
})

const FailedFirmwareUpdateModal = ({
  dispatchUpdateDevice,
  devicesWithFailedUpdates,
}) => {

  const setFailedFirmwareUpdatesToSeen = () => {
    devicesWithFailedUpdates.forEach(device => {
      dispatchUpdateDevice(device.id, { ...device.firmwareUpdate, seen: true })
    })
  }

  return (
    <FailedFirmwareUpdateModalForm
      devicesWithFailedUpdates={devicesWithFailedUpdates}
      showSubmitButton={false}
      closeHandler={setFailedFirmwareUpdatesToSeen}
    />
  )
}

export default modalize(connect(null, mapDispatchToProps)(FailedFirmwareUpdateModal))
