// subdomains match their deployment environments

const ALLOWED_NON_PRODUCTION_SUBDOMAINS = new Set([
  'dev',
  'staging',
])

const getNonProductionEnv = location => {

  const { host } = location

  if (host.includes('localhost')) return 'dev'

  const subdomain = host.split('.')[0]
  if (!ALLOWED_NON_PRODUCTION_SUBDOMAINS.has(subdomain)) {
    throw new Error('Unknown deployment environment detected')
  }
  return subdomain
}

export const getDeploymentEnv = (location = window.location) => (
  `${location.protocol}//${location.host}` === 'https://ospin-app.com'
    ? 'prod'
    : getNonProductionEnv(location)
)
