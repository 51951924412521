import React from 'react'
import formalize from '~/components/utility/modal/formalize'

const ResumeProcessModalForm = () => (
  <>
    Are you sure you want to resume the process?
  </>
)

export default formalize(ResumeProcessModalForm)
