export const isInDeviceView = () => (
  /^\/devices\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
    .test(window.location.pathname)
)

export const parseDeviceIdFromURL = () => {
  if (!isInDeviceView()) {
    return null
  }

  return window.location.pathname
    .match(/^\/devices\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/)[1]
}

export const isInProcessView = () => (
  /\/processes\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
    .test(window.location.pathname)
)

export const parseProcessIdFromURL = () => {
  if (!isInProcessView()) {
    return null
  }

  return window.location.pathname
    .match(/\/processes\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/)[1]
}
