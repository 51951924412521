import React, { useState, useEffect } from 'react'
import { Functionality, Ports } from '@ospin/fct-graph'
import { withRouter } from 'react-router-dom'
import { Divider, Segment, Select } from 'semantic-ui-react'
import SectionLoader from '~/components/utility/SectionLoader'
import NoDataMessage from '~/components/utility/NoDataMessage'
import nexus from '@ospin/nexus'
import FeatureVersioning from '~/utils/FeatureVersioning'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import {
  filterOutRemovedSlotsFromConfigs,
  filterOutRemovedSlotsFromCalibrations,
} from '~/utils/legacy/StreamBasedDevice'
import Licence from '~/utils/licence/Licence'
import Authorizer from '~/utils/Authorizer'
import { connect } from 'react-redux'
import CalibrationTable from './CalibrationTable'
import SlotDefaultTable from './SlotDefaultsTable'
import FunctionalitiesTable from './FunctionalitiesTable'
import SectionWrapper from './SectionWrapper/SectionWrapper'
import descriptions from './SectionWrapper/sectionDescriptions'
import DataPrecisionTable from './DataPrecisionTable'
import DeviceMetaInformation from './DeviceMetaInformation'
import FctGraphNameTable from './FctGraphNameTable'
import TemplateTable from './Templates/TemplateTable'
import PhysicalMappingSelection from './PhysicalMapping/PhysicalMappingSelection'

const mapStateToProps = ({ user }) => ({ user })

const generateDropdownOptionsText = (fctGraph, activeDevice) => {
  /* stream-based devices don't have ports on their functionalities */
  const physFcts = fctGraph.functionalities.filter(Functionality.isPhysical)

  const shouldShowPort = FeatureVersioning.supportsMultipleProcesses(activeDevice)
    && physFcts.length === 1

  if (!shouldShowPort) return fctGraph.name

  return `${fctGraph.name} - ${Ports.getId(physFcts[0].ports)}`
}

const generateFunctionalityGraphOptions = (fctGraphs, activeDevice) => fctGraphs.map(fctGraph => (
  {
    key: fctGraph.id,
    text: generateDropdownOptionsText(fctGraph, activeDevice),
    value: fctGraph.id,
  }
))

const rendersortedFctGraphOptions = (fctGraphs, activeDevice) => (
  generateFunctionalityGraphOptions(fctGraphs, activeDevice)
    .sort((a, b) => a.text.localeCompare(b.text))
)

function renderSection({ title, desc, content, showOnLoad }) {
  return (
    <SectionWrapper
      title={title}
      description={desc}
      key={title}
      showOnLoad={showOnLoad}
    >
      {content}
    </SectionWrapper>
  )
}

const getSectionConnectedFctsContent = (activeDevice, selectedGraph) => ([
  {
    title: 'Connected Devices',
    content: <FunctionalitiesTable
      activeDevice={activeDevice}
      selectedGraph={selectedGraph}
      format='connectedPhysicalFunctionalities'
    />,
    desc: descriptions.connectedFunctionalities,
  },
])

const getSectionSupportedFctsContent = (activeDevice, selectedGraph) => ([
  {
    title: 'Supported Features',
    content: <FunctionalitiesTable
      activeDevice={activeDevice}
      selectedGraph={selectedGraph}
      format='supportedVirtualFunctionalities'
    />,
    desc: descriptions.connectedFunctionalities,
  },
])

const getFctTables = (activeDevice, user, selectedGraph) => {
  const fctTables = getSectionConnectedFctsContent(activeDevice, selectedGraph)

  if (Authorizer.user(user).hasApplicationDeveloperAccess()) {
    fctTables.push(...getSectionSupportedFctsContent(activeDevice, selectedGraph))
  }

  return fctTables.map(renderSection)
}

const getSectionContents = (
  activeDevice,
  selectedGraph,
  fctsConfigs,
  setFctsConfigs,
  calibrations,
  setCalibrations,
) => ([
  {
    title: 'Functionalities',
    content: <FunctionalitiesTable
      activeDevice={activeDevice}
      selectedGraph={selectedGraph}
      format='graph'
    />,
    desc: descriptions.functionalities,
  },
  {
    title: 'Default Values',
    desc: descriptions.slotDefaults,
    content: <SlotDefaultTable
      activeDevice={activeDevice}
      selectedGraph={selectedGraph}
      fctsConfigs={fctsConfigs}
      setFctsConfigs={setFctsConfigs}
    />,
  },
  {
    title: 'Sensor Calibration',
    desc: descriptions.calibrations,
    content: <CalibrationTable
      activeDevice={activeDevice}
      selectedGraph={selectedGraph}
      calibrations={calibrations}
      setCalibrations={setCalibrations}
    />,
  },
  {
    title: 'Data Precision',
    desc: descriptions.dataPrecision,
    content: <DataPrecisionTable
      activeDevice={activeDevice}
      selectedGraph={selectedGraph}
      fctsConfigs={fctsConfigs}
      setFctsConfigs={setFctsConfigs}
    />,
  },
])

const getFunctionalityRelatedContent = (
  activeDevice,
  selectedGraph,
) => ([{
  title: 'Port Assignments',
  desc: descriptions.portAssignment,
  content: <PhysicalMappingSelection
    activeDevice={activeDevice}
    selectedGraph={selectedGraph}
  />,
  showOnLoad: true,

}])

const FctGraphConfiguration = ({ activeDevice, match, user }) => {
  const { fctGraphs } = activeDevice
  const { params: { fctGraphId } } = match
  const isFctGraphFocusedView = !!fctGraphId

  const isDeviceFocusedView = !isFctGraphFocusedView

  const derivedFctGraphId = fctGraphId || fctGraphs[0].id

  const [selectedGraphId, setSelectedGraphId] = useState(derivedFctGraphId)
  const selectedGraph = FunctionalityGraph.getGraphById(fctGraphs, selectedGraphId)

  const [isLoading, setisLoading] = useState(true)
  const [fctsConfigs, setFctsConfigs] = useState(null)
  const [calibrations, setCalibrations] = useState(null)
  const sectionContents = getSectionContents(
    activeDevice,
    selectedGraph,
    fctsConfigs,
    setFctsConfigs,
    calibrations,
    setCalibrations,
  )

  if (isFctGraphFocusedView) {
    sectionContents.unshift(...getFunctionalityRelatedContent(activeDevice, selectedGraph))
  }

  async function updatedSelectedFctGraph(newSelectedFctGraphId) {
    setisLoading(true)
    setFctsConfigs(null)
    setCalibrations(null)
    const {
      data: {
        calibrations: newCalibrations,
        fctsConfigs: newFctsConfigs,
      },
    } = await nexus.device.functionalityGraph.configuration.get({
      deviceId: activeDevice.id,
      fctGraphId: newSelectedFctGraphId,
    })
    setSelectedGraphId(newSelectedFctGraphId)
    setFctsConfigs(filterOutRemovedSlotsFromConfigs(activeDevice, newFctsConfigs))
    setCalibrations(filterOutRemovedSlotsFromCalibrations(activeDevice, newCalibrations))
    setisLoading(false)
  }

  useEffect(() => {
    updatedSelectedFctGraph(selectedGraph.id)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDevice.id])

  function renderDeviceSpecificSetup() {
    const shouldRenderTemplates = Authorizer.user(user).hasApplicationDeveloperAccess()
      && Licence.hasUltimateLicence(activeDevice)

    return (
      <>
        <DeviceMetaInformation activeDevice={activeDevice} />
        { isDeviceFocusedView && FeatureVersioning.supportsMultipleProcesses(activeDevice)
        && getFctTables(activeDevice, user, selectedGraph)}
        { // TODO: Move this based on final implementaiton details
          shouldRenderTemplates && renderSection({
            title: 'Templates',
            desc: descriptions.templateBuilder,
            content: <TemplateTable
              selectedGraph={selectedGraph}
              setSelectedGraphId={setSelectedGraphId}
              activeDevice={activeDevice}
            />,
          })
        }
        <Divider />
        <span style={{ paddingRight: '1rem', paddingTop: '1rem' }}>
          <label htmlFor='selectedFctGraph'>
            Configuration:
          </label>
        </span>
        <Select
          value={selectedGraph.id}
          options={rendersortedFctGraphOptions(fctGraphs, activeDevice)}
          onChange={(event, { value }) => updatedSelectedFctGraph(value)}
          name='selectedFctGraph'
          id='selectedFctGraph'
          disabled={fctGraphs.length === 1}
          data-testid='fctGraphSelection'
          search
        />
      </>
    )
  }

  function renderSectionContent() {
    return isLoading
      ? <SectionLoader />
      : sectionContents.map(renderSection)
  }

  return (
    <div>
      <Segment>
        {isFctGraphFocusedView && (
          <FctGraphNameTable
            selectedGraph={selectedGraph}
            activeDevice={activeDevice}
          />
        )}
        { isDeviceFocusedView && renderDeviceSpecificSetup()}
        { selectedGraphId ? renderSectionContent() : null }
      </Segment>
    </div>
  )
}

const DeviceSetup = ({ activeDevice, match, user }) => {
  const { fctGraphs } = activeDevice

  const { params: { fctGraphId } } = match
  const isFctGraphFocusedView = !!fctGraphId

  const isDeviceFocusedView = !isFctGraphFocusedView

  if (fctGraphs.length === 0) {
    return (
      <>
        <DeviceMetaInformation activeDevice={activeDevice} />
        { isDeviceFocusedView && FeatureVersioning.supportsMultipleProcesses(activeDevice)
        && getFctTables(activeDevice, user)}
        <div style={{ marginTop: '8px' }}>
          <NoDataMessage text='This device has never reported a (valid) configuration yet' />
        </div>
      </>
    )
  }

  return <FctGraphConfiguration activeDevice={activeDevice} match={match} user={user} />
}

export default withRouter(connect(mapStateToProps)(DeviceSetup))
