import React from 'react'
import { Modal } from 'semantic-ui-react'
import AvailableFirmwareUpdateNotification from '~/components/notifications/topics/AvailableFirmwareUpdateNotification'

const UpdateFirmwareModal = ({
  closeHandler,
  activeDevice,
}) => {

  const { firmwareUpdate } = activeDevice

  return (
    <Modal
      size='tiny'
      open
      onClose={() => closeHandler()}
      onClick={event => event.stopPropagation()}
    >
      <Modal.Content>
        <Modal.Description>
          <AvailableFirmwareUpdateNotification
            key={firmwareUpdate.id}
            device={activeDevice}
            closeHandler={closeHandler}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default UpdateFirmwareModal
