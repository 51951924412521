import React from 'react'
import dateFormat from 'dateformat'
import nexus from '@ospin/nexus'
import { Segment, List, Image, Ref } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import DynamicLoader from '~/components/utility/DynamicLoader'
import callRemoveNotificationsByTopic from '~/redux/thunks/user/callRemoveNotificationsByTopic'
import './Changelog.css'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallRemoveNotificationsByTopic: (userId, topic) =>
    callRemoveNotificationsByTopic(dispatch, userId, topic)
})

class Changelog extends React.Component {

  state = { gitPrs: [], loading: true }

  contextRef = React.createRef()

  async componentDidMount() {
    this.mounted = true
    /* TODO: ADD_ERROR_HANDLING */
    const { data: gitPrs } = await nexus.user.getChangelog()

    const sortedPrs = gitPrs.sort((a, b) => (
      (new Date(b.closedAt)).valueOf() - (new Date(a.closedAt)).valueOf()
    ))
    if (this.mounted) {
      const { dispatchCallRemoveNotificationsByTopic, user } = this.props
      this.setState({ gitPrs: sortedPrs, loading: false }, () => {
        dispatchCallRemoveNotificationsByTopic(user.id, 'changelog')
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  static renderEntry = prs => (
    <div>
      {
        prs.map(entry => (
          <List.Item className='changelog-entry' key={entry.closedAt}>
            <List.Content>
              <List.Description style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div className='ui content header' style={{ marginBottom: '2px' }}>{entry.title}</div>
                <div style={{ color: '#00000066', fontSize: '1em' }}>{dateFormat(entry.closedAt, 'shortDate')}</div>
                <div data-testid='pr-owner' style={{ paddingTop: '10px' }}>
                  <Image avatar src={entry.user.avatarUrl} />
                  {entry.user.login}
                </div>
              </List.Description>
              <ReactMarkdown>
                {entry.body}
              </ReactMarkdown>
            </List.Content>
          </List.Item>
        ))
      }
    </div>
  )

  render() {

    const { loading, gitPrs } = this.state

    if (loading) return <DynamicLoader />

    return (
      <div className='changelog-container'>
        <Ref innerRef={this.contextRef}>
          <Segment>
            {Changelog.renderEntry(gitPrs)}
          </Segment>
        </Ref>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Changelog))
