import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import Validator from '~/utils/validation/Validator'

export default class GroupChangeFilter {

  static getInputNodeValueChangeString(aDiff) {
    const { path, type } = aDiff
    const inputFctIdIndexInPath = 2
    const inputNodeFctId = path[inputFctIdIndexInPath]
    return `${type}-${inputNodeFctId}`
  }

  static getPhasePropChangeString(aDiff) {
    const { property, type } = aDiff
    return `${type}-${property}`
  }

  static getPhaseCountChangeString(aDiff) {
    const { action, type } = aDiff
    return `${type}-${action}`
  }

  static getChangeString(aDiff) {
    const { type } = aDiff

    switch (type) {
      case 'inputNodeValueChange':
        return GroupChangeFilter.getInputNodeValueChangeString(aDiff)
      case 'phasePropChange':
        return GroupChangeFilter.getPhasePropChangeString(aDiff)
      case 'phasePropAdd':
        return GroupChangeFilter.getPhasePropChangeString(aDiff)
      case 'phasePropRemoved':
        return GroupChangeFilter.getPhasePropChangeString(aDiff)
      case 'phaseCountChange':
        return GroupChangeFilter.getPhaseCountChangeString(aDiff)
      default:
        return ''
    }
  }

  static findPhaseInProcessHistory(process, snapshot, phaseId) {
    const inUpdatedProcess = DescriptionParser
      .hasPhaseWithId(process.description, phaseId)

    return inUpdatedProcess
      ? DescriptionParser.getPhaseById(process, phaseId)
      : DescriptionParser.getPhaseById(snapshot, phaseId)
  }

  static findIterationInProcessHistory(process, snapshot, phaseId) {
    const inUpdatedProcess = DescriptionParser
      .hasPhaseWithId(process.description, phaseId)

    return inUpdatedProcess
      ? PhaseGroupParser.getPhaseIterationCount(process, phaseId)
      : PhaseGroupParser.getPhaseIterationCount(snapshot, phaseId)
  }

  static getPhaseIdRefLayer(phaseId, iterationOf, groupPhaseChangesMap) {
    const phaseRefId = Validator.isUndefinedOrNull(iterationOf) ? phaseId : iterationOf
    if (!(phaseRefId in groupPhaseChangesMap)) groupPhaseChangesMap[phaseRefId] = {}

    return groupPhaseChangesMap[phaseRefId]
  }

  static getChangeStringLayer(aDiff, phaseData) {
    const changeString = GroupChangeFilter.getChangeString(aDiff)
    if (!(changeString in phaseData)) {
      phaseData[changeString] = { cycleStart: Infinity, startIdx: undefined }
    }

    return phaseData[changeString]
  }

  static getFilteredIndices(diffs, process, snapshot) {
    const groupPhaseChangesMap = {}
    const filterCanditidatesIdxs = []

    diffs.forEach((aDiff, idx) => {
      const { phaseId } = aDiff
      if (Validator.isUndefinedOrNull(phaseId)) return aDiff

      const { groupName, iterationOf } = GroupChangeFilter
        .findPhaseInProcessHistory(process, snapshot, phaseId)

      if (!groupName) return aDiff

      const phaseData = GroupChangeFilter
        .getPhaseIdRefLayer(phaseId, iterationOf, groupPhaseChangesMap)
      const changeData = GroupChangeFilter.getChangeStringLayer(aDiff, phaseData)

      const iteration = GroupChangeFilter
        .findIterationInProcessHistory(process, snapshot, phaseId)

      if (iteration > changeData.cycleStart) {
        filterCanditidatesIdxs.push(idx)
        return
      }

      if (!Validator.isUndefinedOrNull(changeData.startIdx)) {
        filterCanditidatesIdxs.push(changeData.startIdx)
      }

      changeData.cycleStart = iteration
      changeData.startIdx = idx
    })

    return filterCanditidatesIdxs
  }

  static filter(diffs, process, snapshot) {
    const filteredIndices = GroupChangeFilter.getFilteredIndices(diffs, process, snapshot)
    return diffs.filter((_, idx) => !filteredIndices.includes(idx))
  }
}
