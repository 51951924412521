import React from 'react'
import { Table, Icon } from 'semantic-ui-react'

const GroupAccessInformation = ({ assignedGroupData }) => {

  if (!assignedGroupData) return <span>No Group selected.</span>

  const getIcon = idx => {
    const hasRights = !!parseInt(assignedGroupData.rights[idx], 10)
    return hasRights
      ? <Icon color='green' name='check' />
      : <Icon color='red' name='close' />
  }

  const RIGHTS = [
    'View', 'Edit', 'Execute', 'Delete', 'Manage Access',
  ]

  return (
    <Table celled textAlign='center'>
      <Table.Header>
        <Table.Row>
          {RIGHTS.map(entry => (
            <Table.HeaderCell key={entry}>
              {entry}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          {RIGHTS.map((entry, i) => (
            <Table.Cell
              key={entry}
            >
              {getIcon(i)}
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

export default GroupAccessInformation
