import React from 'react'
import { Message } from 'semantic-ui-react'
import './GatewayWidget.css'

const NoGraphMessage = ({
  noGraphMsgClassName,
  messageString = 'devices',
}) => (
  <Message
    warning
    content={`No executable ${messageString} attached`}
    className={`${noGraphMsgClassName}`}
  />
)

export default NoGraphMessage
