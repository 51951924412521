import React from 'react'
import { Card } from 'semantic-ui-react'
import DeviceWidget from '~/components/device/widget/DeviceWidget'
import GatewayWidget from '~/components/device/widget/GatewayWidget'
import FeatureVersioning from '~/utils/FeatureVersioning'
import './DeviceList.css'

const renderDeviceWidget = device => (FeatureVersioning.supportsMultipleProcesses(device)
  ? (
    <GatewayWidget
      key={device.id}
      device={device}
    />
  )
  : (
    <DeviceWidget
      key={device.id}
      device={device}
    />
  ))

const DeviceList = props => {
  const { devices } = props

  return (
    <Card.Group>
      { devices.map(renderDeviceWidget) }
    </Card.Group>
  )
}

export default DeviceList
