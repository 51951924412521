import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Checkbox, Button } from 'semantic-ui-react'
import UserUIConfig from '~/utils/UIConfig/UserUIConfig'
import GatewayDisplayedFcts from '~/utils/partners/GatewayDisplayedFcts'
import './GatewayWidget.css'

const mapStateToProps = ({ user }) => ({ user })

function ConfigurationViewControl(props) {
  const { device, user, match: { params: { deviceId: deviceIdMatchParams } } } = props
  const { id: deviceId } = device
  const { uIConfig } = user

  const viewType = GatewayDisplayedFcts.getViewType(deviceIdMatchParams)

  const { [viewType]: { viewOffline, viewAggregate } } = UserUIConfig
    .getDeviceConfig(uIConfig, deviceId)

  const onToggle = updatedKey => {
    UserUIConfig.toggleController(device.id, uIConfig, updatedKey, viewType)
  }

  const handleAllAccordions = newValue => {
    UserUIConfig.setAllCollapsedValue(deviceId, uIConfig, newValue, viewType)
  }

  return (
    <>
      <div className='gateway-widget-checkbox-container'>
        <Checkbox
          toggle
          checked={viewOffline}
          onChange={() => onToggle('viewOffline')}
          label='Show disconnected devices & solutions'
        />
      </div>
      <div className='gateway-widget-checkbox-container'>
        <Checkbox
          toggle
          checked={viewAggregate}
          onChange={() => onToggle('viewAggregate')}
          label='Aggregate devices & solutions by type'
        />
        <div style={{ marginRight: '24px' }}>
          <Button.Group>
            <Button
              className='gateway-widget-accordion-button-group'
              compact
              onClick={() => handleAllAccordions(true)}
            >
              Collapse All
            </Button>
            <Button
              className='gateway-widget-accordion-button-group'
              compact
              onClick={() => handleAllAccordions(false)}
            >
              Expand All
            </Button>
          </Button.Group>
        </div>
      </div>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(ConfigurationViewControl))
