import React from 'react'
import { Form } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'

function ClaimLicenceModalForm({
  licenceKey,
  setLicenceKey,
  deviceId,
  setDeviceId,
  requiresDeviceId,
}) {

  return (
    <>
      <Form.Field>
        <label htmlFor='licenceKey'>Licence Key</label>
        <Form.Input
          onChange={(_, { value }) => setLicenceKey(value.trim())}
          value={licenceKey}
          id='licenceKey'
          name='licenceKey'
        />
      </Form.Field>
      {requiresDeviceId
        ? (
          <Form.Field>
            <label htmlFor='deviceId'>Device ID</label>
            <Form.Input
              onChange={(_, { value }) => setDeviceId(value.trim())}
              value={deviceId}
              id='deviceId'
              name='deviceId'
            />
          </Form.Field>
        ) : null}

    </>
  )
}

export default formalize(ClaimLicenceModalForm)
