import React, { useState } from 'react'
import { connect } from 'react-redux'
import modalize from '~/components/utility/modal/modalize'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import callCreateDownloadRequest from '~/redux/thunks/process/callCreateDownloadRequest'
import FlashMessenger from '~/utils/FlashMessenger'
import DownloadRequest from '~/utils/process/DownloadRequest'
import DownloadProcessDatapointsModalForm from './DownloadProcessDatapointsModalForm'
import './DownloadProcessDatapointsModal.css'

const mapDispatchToProps = dispatch => ({
  dispatchCallCreateDownloadRequest: (processId, reporterFctIds) => callCreateDownloadRequest(
    dispatch,
    DownloadRequest.TYPES.SENSOR_DATA,
    processId,
    reporterFctIds,
  ),
})

const DownloadProcessDatapointsModal = ({
  closeHandler,
  fctGraph,
  fctGraphUIConfig,
  activeProcess,
  dispatchCallCreateDownloadRequest,
}) => {

  const { configs: { graphs: { functionalities: fctUIConfigs } } } = fctGraphUIConfig
  const allFcts = fctUIConfigs.map(singleFct => FCTGraph.getFctById(fctGraph, singleFct.fctId))

  const fctAndSlotNames = allFcts.flatMap(fct => {
    const { name: fctName, id } = fct
    const outSlots = Functionality.getOutSlots(fct)
    const outSlotsWithReporterFcts = outSlots.filter(slot => FCTGraph.getReporterFctIdForSlot(fctGraph, id, slot.name))

    return outSlotsWithReporterFcts.map(outSlot => ({
      id,
      name: fctName,
      slotName: outSlot.name,
      displaySlotName: Slot.getDisplayName(outSlot),
    }))
  })

  const [ selectedFctsAndSlots, setSelectedFctsAndSlots ] = useState(fctAndSlotNames)
  const [ waitingForAPIResponse, setWaitingForAPIResponse ] = useState(false)

  const updateSelectedFctsAndSlots = (fctSlot, isChecked) => {
    if (isChecked) {
      setSelectedFctsAndSlots(selectedFctsAndSlots
        .filter(fctAndSlot => !(fctAndSlot.id === fctSlot.id
        && fctAndSlot.slotName === fctSlot.slotName)))
      return
    }
    setSelectedFctsAndSlots([ ...selectedFctsAndSlots, fctSlot ])
  }

  const onSubmitHandler = async () => {
    const reporterFctIds = selectedFctsAndSlots.map(({ id, slotName }) => FCTGraph
      .getReporterFctIdForSlot(fctGraph, id, slotName))

    setWaitingForAPIResponse(true)

    try {
      await dispatchCallCreateDownloadRequest(activeProcess.id, reporterFctIds)
      FlashMessenger.info('Your download will start once ready and will also be available in the "Downloads" section')
    } catch (_) {
      FlashMessenger.error('Your download request failed. If this issue persist, contact the OSPIN support team.')
    } finally {
      setWaitingForAPIResponse(false)
    }
  }

  const hasAllSelectedCheckboxes = selectedFctsAndSlots.length === fctAndSlotNames.length

  const handleSelectAll = () => {
    const isSelectAll = hasAllSelectedCheckboxes ? [] : fctAndSlotNames
    setSelectedFctsAndSlots(isSelectAll)
  }

  return (
    <DownloadProcessDatapointsModalForm
      closeHandler={closeHandler}
      closeOnSubmit
      submitText='Download'
      loading={waitingForAPIResponse}
      disableSubmit={waitingForAPIResponse || selectedFctsAndSlots.length === 0}
      submitHandler={onSubmitHandler}
      fctAndSlotNames={fctAndSlotNames}
      selectedFctsAndSlots={selectedFctsAndSlots}
      handleSelectAll={handleSelectAll}
      hasAllSelectedCheckboxes={hasAllSelectedCheckboxes}
      updateSelectedFctsAndSlots={updateSelectedFctsAndSlots}
    />
  )
}

export default connect(null, mapDispatchToProps)(modalize(DownloadProcessDatapointsModal))
