import { findAndUpdate } from '~/redux/helper/updateFunctions'
import Device from '~/utils/Device'

export default (state, action) => {
  const { devices } = state
  const { deviceId, userId } = action.payload

  const updateFn = device => Device.removeDeviceUser(device, userId)
  const updatedDevices = findAndUpdate(deviceId, devices, [ updateFn ])

  return {
    ...state,
    devices: updatedDevices,
    deviceUsers: state.deviceUsers.filter(user => user.id !== userId),
  }
}
