import React from 'react'
import nexus from '@ospin/nexus'
import { withRouter } from 'react-router-dom'
import { Container, Button, Header, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import UserTable from '~/components/profile/UserTable'
import ChangePasswordForm from '~/components/profile/ChangePasswordForm'
import DeleteUserConfirmModal from '~/components/profile/DeleteUserConfirmModal'

import './Profile.css'

import callUpdateUser from '~/redux/thunks/user/callUpdateUser'
import Licences from './Licence/Licences'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateUser: (userId, userData) =>
    callUpdateUser(dispatch, userId, userData),
})

class Profile extends React.Component {

  state = {
    showResetPasswordForm: false,
    loading: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    newPassword: '',
    oldPassword: '',
    confirmPassword: '',
    openDeleteUserModal: false,
  }

  toggleResetPasswordForm = () => {
    this.setState(prevState => ({ showResetPasswordForm: !prevState.showResetPasswordForm }))
  }

  handleChange = (_, { name, value }) => (
    this.setState({
      [name]: value.trim(),
      isError: false,
      errorMessage: '',
      isSuccess: false,
    })
  )

  submitNewPassword = async () => {

    const { oldPassword, newPassword, confirmPassword } = this.state

    if (newPassword !== confirmPassword) {
      this.setState({ isError: true, errorMessage: 'New passwords don\'t match.' })
      return
    }

    this.setState({ loading: true })

    try {
      await nexus.auth.changePassword({ oldPassword, newPassword })
      this.setState({ isSuccess: true, loading: false })
    } catch (e) {
      this.setState({ isError: true, errorMessage: e.message, loading: false })
    }
  }

  updateUser = async update => {
    const { user: { id: userId }, dispatchCallUpdateUser } = this.props
    /* TODO: ADD_ERROR_HANDLING */
    await dispatchCallUpdateUser(userId, update)
  }

  renderChangePasswordForm = () => {
    const {
      showResetPasswordForm,
      isError,
      errorMessage,
      isSuccess,
      loading,
      oldPassword,
      newPassword,
      confirmPassword,
    } = this.state

    if (!showResetPasswordForm) return null

    return (
      <ChangePasswordForm
        isError={isError}
        errorMessage={errorMessage}
        isSuccess={isSuccess}
        handleChange={this.handleChange}
        submitNewPassword={this.submitNewPassword}
        loading={loading}
        values={{ oldPassword, newPassword, confirmPassword }}
      />
    )
  }

  renderDeleteUserZone = () => {
    const { openDeleteUserModal } = this.state
    return (
      <Container className='profile-delete-user-zone-m-y '>
        <Header as='h2'>Delete Account</Header>
        <Segment>
          <Container>
            <DeleteUserConfirmModal
              headerText='Delete Account'
              closeHandler={() => this.setState({ openDeleteUserModal: false })}
              open={openDeleteUserModal}
            />
            <Header as='h4'>This action will delete your data permanently</Header>
            <Button
              onClick={() => this.setState({ openDeleteUserModal: true })}
              negative
            >
              Delete Account
            </Button>

          </Container>
        </Segment>
      </Container>
    )

  }

  render() {

    const { user } = this.props

    return (
      <Container>
        <UserTable user={user} updateUser={this.updateUser} />
        <Button onClick={this.toggleResetPasswordForm}>Change Password</Button>
        {this.renderChangePasswordForm()}
        <Licences user={user} />
        {this.renderDeleteUserZone()}
      </Container>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
