import React, { useState, useEffect } from 'react'
import { Menu, Dropdown, Button, Input, Segment } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import { withRouter } from 'react-router-dom'
import FeatureVersioning from '~/utils/FeatureVersioning'
import { Functionality, Ports } from '@ospin/fct-graph'

const timeRefOptions = [
  { text: 'created', value: 'createdAt' },
  { text: 'started', value: 'startedAt' },
  { text: 'finished', value: 'finishedAt' },
]

const stateQueryOptions = [
  { text: 'executable', value: 'executable' },
  { text: 'running', value: 'running' },
  { text: 'finished', value: 'finished' },
]

const getDisplayedStyle = showProcessSearchMenu => (
  showProcessSearchMenu
    ? { marginBottom: '16px' }
    : { display: 'none' }
)

const getNormalizedTimestamp = (key, value) => {
  if (!value) { return value }
  if (key === 'timeRefStartMS') { return value }
  return new Date(value).setHours(23, 59, 59).valueOf()
}

function generateFctGraphOptions(activeDevice) {
  const { fctGraphs } = activeDevice

  return fctGraphs.map(graph => {
    const { functionalities } = graph
    const graphPorts = functionalities.filter(
      Functionality.isPhysical,
    ).map(({ ports }) => Ports.getId(ports))

    return {
      key: graph.id,
      value: graph.id,
      text: `${graph.name} - ${graphPorts.join()}`,
    }
  })

}

const ProcessSearchMenu = ({
  timeRefTarget,
  timeRefStartMS,
  timeRefEndMS,
  stateQuery,
  textQuery,
  fctGraphIds,
  showProcessSearchMenu,
  updateQueryParameters,
  activeDevice,
  match: { params: { fctGraphId } },
}) => {
  const [width, setWidth] = React.useState(window.innerWidth)
  const isConfigurationview = !!fctGraphId

  const [ pendingQueryParameters, setpendingQueryParameters ] = useState({
    timeRefTarget,
    timeRefStartMS,
    timeRefEndMS,
    stateQuery,
    textQuery,
    fctGraphIds: isConfigurationview ? null : fctGraphIds,
  })

  const BREAKPOINT_MEDIUM = 1700

  useEffect(() => {
    function resizeBasedOnWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', resizeBasedOnWidth)
    return function cleanup () {
      window.removeEventListener('resize', resizeBasedOnWidth)
    }
  })

  const isCompact = width < BREAKPOINT_MEDIUM

  const returnDateObjectOrNull = timestamp => (timestamp ? new Date(timestamp) : null)

  const disableTimeRefTargetSelection = [
    pendingQueryParameters.timeRefEndMS,
    pendingQueryParameters.timeRefStartMS,
  ].every(param => !param)

  const shouldHideConfigurationFilter = fctGraphId
    || !FeatureVersioning.supportsMultipleProcesses(activeDevice)

  function updatePendingQueryParams({ key, value }) {
    setpendingQueryParameters({
      ...pendingQueryParameters,
      [key]: value,
    })
  }

  function updateFctGraphIds(value) {
    updatePendingQueryParams({ key: 'fctGraphIds', value: value.length ? value : null })
  }

  function updateTimestamps({ key, value }) {
    const normalizeValue = getNormalizedTimestamp(key, value)

    const newState = { ...pendingQueryParameters, [key]: normalizeValue }

    if ((newState.timeRefEndMS || newState.timeRefStartMS) && !newState.timeRefTarget) {
      setpendingQueryParameters({ ...newState, timeRefTarget: timeRefOptions[0].value})
    } else if ((!newState.timeRefEndMS && !newState.timeRefStartMS) && newState.timeRefTarget) {
      setpendingQueryParameters({ ...newState, timeRefTarget: null })
    } else {
      setpendingQueryParameters(newState)
    }
  }

  function applyFilter() {
    const parameterArray = Object.entries(pendingQueryParameters)
      .map(entries => ({ key: entries[0], value: entries[1] }))
    updateQueryParameters([...parameterArray, { key: 'skip', value: 0 }])
  }

  function renderConfigurationFilter() {
    if (shouldHideConfigurationFilter) {
      return null
    }

    return (
      <Menu secondary widths={1} style={getDisplayedStyle(showProcessSearchMenu)}>
        <Menu.Item>
          <Dropdown
            multiple
            search
            selection
            fluid
            style={{ marginLeft: '16px', marginRight: '16px' }}
            placeholder='Filter by Configuration'
            options={generateFctGraphOptions(activeDevice)}
            value={pendingQueryParameters.fctGraphIds || []}
            onChange={(_, { value }) => updateFctGraphIds(value)}
          />
        </Menu.Item>
      </Menu>
    )
  }

  const currentFilterApplied = () => {
    const simpleEqualityParams = {
      timeRefTarget,
      timeRefStartMS,
      timeRefEndMS,
      stateQuery,
      textQuery,
    }

    const fctGraphsAreEqual = () => {
      if (!Array.isArray(fctGraphIds) || !Array.isArray(pendingQueryParameters.fctGraphIds)) {
        return fctGraphIds === pendingQueryParameters.fctGraphIds
      }
      return fctGraphIds.length === pendingQueryParameters.fctGraphIds.length
        && fctGraphIds.every(id => pendingQueryParameters.fctGraphIds.includes(id))
    }

    const otherParamsAreEqual = () => Object.keys(simpleEqualityParams)
      .every(param => simpleEqualityParams[param] === pendingQueryParameters[param])


    return fctGraphsAreEqual() && otherParamsAreEqual()
  }

  const renderLeftSide = () => (
    <>
      <Menu.Item stacked='true'>
        <label className='text-label'>Start: </label>
        <SemanticDatepicker
          name='timeRefStartMS'
          value={returnDateObjectOrNull(pendingQueryParameters.timeRefStartMS)}
          maxDate={returnDateObjectOrNull(pendingQueryParameters.timeRefEndMS) || new Date()}
          onChange={(_, { value }) => updateTimestamps({ key: 'timeRefStartMS', value: Date.parse(value) || null })}

        />
      </Menu.Item>
      <Menu.Item>
        <label className='text-label'>End: </label>
        <SemanticDatepicker
          name='timeRefEndMS'
          value={returnDateObjectOrNull(pendingQueryParameters.timeRefEndMS)}
          minDate={returnDateObjectOrNull(pendingQueryParameters.timeRefStartMS)}
          maxDate={new Date()}
          onChange={(_, { value }) => updateTimestamps({ key: 'timeRefEndMS', value: Date.parse(value) || null })}
        />
      </Menu.Item>
      <Menu.Item content={(
        <Dropdown
          selection
          fluid
          placeholder='created'
          options={timeRefOptions}
          value={pendingQueryParameters.timeRefTarget}
          disabled={disableTimeRefTargetSelection}
          name='timeRefTarget'
          onChange={(_, { value }) => updatePendingQueryParams({ key: 'timeRefTarget', value })}
          style={{ marginLeft: '16px', marginRight: '16px' }}
        />
      )}
      />
    </>
  )

  const renderRightSide = () => (
    <>
      <Menu.Item
        testid='stateQuery'
        content={(
          <Dropdown
            selection
            fluid
            clearable
            placeholder='process state'
            options={stateQueryOptions}
            testid='stateQuery'
            value={pendingQueryParameters.stateQuery}
            name='stateQuery'
            onChange={(_, { value }) => updatePendingQueryParams({ key: 'stateQuery', value: value || null })}
            style={{ marginLeft: '16px', marginRight: '16px' }}
          />
        )}
      />
      <Menu.Item
        testid='searchQuery'
        content={(
          <Input
            fluid
            label='Search'
            placeholder='Search by text'
            value={pendingQueryParameters.textQuery || ''}
            name='textQuery'
            onChange={(_, { value }) => updatePendingQueryParams({ key: 'textQuery', value: value || null })}
            style={{ marginLeft: '16px', marginRight: '16px' }}
          />
        )}
      />
      <Menu.Item content={(
        <span>
          <Button
            fluid
            primary
            disabled={currentFilterApplied()}
            content='Apply Filter'
            onClick={() => applyFilter()}
          />
        </span>
      )}
      />
    </>
  )
  if (isCompact) {
    return (
      <Segment style={getDisplayedStyle(showProcessSearchMenu)}>
        {renderConfigurationFilter()}
        <Menu
          widths='3'
          secondary
          style={getDisplayedStyle(showProcessSearchMenu)}
        >
          {renderLeftSide()}
        </Menu>
        <Menu
          widths='3'
          secondary
          style={getDisplayedStyle(showProcessSearchMenu)}
        >
          {renderRightSide()}
        </Menu>

      </Segment>
    )
  }

  return (
    <Segment style={getDisplayedStyle(showProcessSearchMenu)}>
      {renderConfigurationFilter()}
      <Menu secondary widths={6} style={getDisplayedStyle(showProcessSearchMenu)}>
        {renderLeftSide()}
        {renderRightSide()}
      </Menu>
    </Segment>
  )
}

export default withRouter(ProcessSearchMenu)
