import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import nexus from '@ospin/nexus'
import Authorizer from '~/utils/Authorizer'
import callModifyProcessAccess from '~/redux/thunks/process/callModifyProcessAccess'
import callGrantProcessAccess from '~/redux/thunks/process/callGrantProcessAccess'
import callRevokeProcessAccess from '~/redux/thunks/process/callRevokeProcessAccess'
import FlashMessenger from '~/utils/FlashMessenger'
import AccessProcessModalForm from './AccessProcessModalForm'

const mapStateToProps = ({ user, deviceUsers }) => ({ user, deviceUsers })

const mapDispatchToProps = dispatch => ({
  dispatchCallGrantProcessAccess: (processId, userId, groupName) =>
    callGrantProcessAccess(dispatch, processId, userId, groupName),
  dispatchCallRevokeProcessAccess: (processId, userId) =>
    callRevokeProcessAccess(dispatch, processId, userId),
  dispatchCallModifyProcessAccess: (processId, userId, newGroup) =>
    callModifyProcessAccess(dispatch, processId, userId, newGroup),
})

const AccessProcessFormContainer = props => {

  const [ responseState, setResponseState ] = useState({
    isError: false,
    errorMessage: '',
    isSuccess: false,
    successMessage: '',
    loading: false,
  })

  const [ state, setState ] = useState({
    assignedGroup: undefined,
    assignedGroupData: undefined,
    assignedUser: undefined,
    usersLoaded: false,
    processUsers: [],
    loadingUsers: false,
  })

  const [ pendingRequests, setPendingRequests ] = useState([])

  const shouldFetchUsers = useRef(true)

  const updateState = update => {
    setState({ ...state, ...update })
  }

  const updateResponseState = update => {
    setResponseState({ ...responseState, ...update })
  }

  const fetchProcUsers = async () => {
    const { process: { id } } = props
    if (!state.usersLoaded) {
      updateState({ loadingUsers: true })
    }

    const { data: { users } } = await nexus.user.list({ processId: id })
    updateState({ processUsers: users, usersLoaded: true, loadingUsers: false })
  }

  useEffect(() => {
    fetchProcUsers()
  }, [])

  const addPendingRequest = (userId, type) => {
    setPendingRequests([ ...pendingRequests, { userId, type } ])
  }

  const removePendingRequest = (targetUserId, targetType) => {
    setPendingRequests(
      pendingRequests.filter(({ userId, type }) => (
        !(userId === targetUserId && targetType === type)
      )),
    )
  }

  const handleUserChange = (event, { value }) => {
    updateState({ assignedUser: value })
    updateResponseState()
  }

  const handleGroupChange = (event, { value }) => {
    const { process: { access } } = props
    updateState({
      assignedGroup: value,
      assignedGroupData: access.find(group => group.name === value),
    })
    updateResponseState()
  }

  const shareProcess = async () => {
    const { assignedGroup, assignedUser } = state

    const { deviceUsers, process: { id }, dispatchCallGrantProcessAccess } = props

    const newProcessUser = deviceUsers.find(user => user.id === assignedUser)

    updateResponseState({ loading: true })

    try {
      await dispatchCallGrantProcessAccess(id, newProcessUser.id, assignedGroup)
      updateResponseState({
        isSuccess: true,
        successMessage: 'User has been added to the process',
        isError: false,
        errorMessage: '',
      })
      fetchProcUsers()
    } catch (e) {
      updateResponseState({
        isError: true,
        errorMessage: e.message,
        isSuccess: false,
        successMessage: '',
      })
    } finally {
      updateState({ assignedUser: null })
      updateResponseState({ loading: false })
    }

  }

  const withDrawAccess = async (event, userId) => {
    const {
      user,
      closeHandler,
      activeDevice,
      history,
      match,
      requiresRedirect = false,
      requiresReload = false,
      reloadFunction,
    } = props

    event.preventDefault()
    addPendingRequest(userId, 'revoke')
    const { process: { id }, dispatchCallRevokeProcessAccess } = props

    if (user.id === userId) {
      shouldFetchUsers.current = false
    }

    try {
      await dispatchCallRevokeProcessAccess(id, userId)
      updateResponseState({
        isSuccess: true,
        successMessage: 'User access has been revoked',
        isError: false,
        errorMessage: '',
      })
      fetchProcUsers()
    } catch (e) {
      updateResponseState({
        isError: true,
        errorMessage: e.message,
        isSuccess: false,
        successMessage: '',
      })
    } finally {
      removePendingRequest(userId, 'revoke')

      if (user.id === userId) {
        closeHandler(null, 'showProcessDetailsModal')

        if (requiresRedirect) {
          const { params: { fctGraphId } } = match
          if (fctGraphId) {
            history.push(`/devices/${activeDevice.id}/configurations/${fctGraphId}/processes`)
          } else {
            history.push(`/devices/${activeDevice.id}/processes`)
          }
        }

        if (requiresReload) {
          reloadFunction()
        }

        FlashMessenger.success('Your access has been successfully revoked from a shared process')
      }

      shouldFetchUsers.current = true
    }

  }

  const modifyAccess = async (userId, newGroup) => {
    if (!newGroup) return

    const { process: { id, access }, dispatchCallModifyProcessAccess } = props

    const oldGroup = access.find(group => group.users.includes(userId)).name
    if (oldGroup === newGroup) return

    addPendingRequest(userId, 'update')

    try {
      await dispatchCallModifyProcessAccess(id, userId, newGroup)
      updateResponseState({
        isSuccess: true,
        successMessage: 'User access has been modified',
        isError: false,
        errorMessage: '',
      })
      fetchProcUsers()
    } catch (e) {
      updateResponseState({
        isError: true,
        errorMessage: e.message,
        isSuccess: false,
        successMessage: '',
      })
    } finally {
      removePendingRequest(userId, 'update')
    }
  }

  const getDeviceUserOptions = processUserIds => {
    const { deviceUsers, user } = props

    return deviceUsers
      .filter(deviceUser => !Authorizer.isCurrentLoggedInUser(
        user,
        deviceUser.id,
      ) && !processUserIds.includes(deviceUser.id))
      .map((deviceUser, i) => ({
        value: deviceUser.id,
        text: deviceUser.userName,
        key: i,
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  const getProcessUsers = () => {
    const { process: { access } } = props
    const { processUsers } = state

    return processUsers.map(({ id, userName }) => {
      const group = access.find(group => group.users.includes(id))
      const groupName = group ? group.name : ''

      return {
        name: userName,
        groupName,
        id,
      }
    })
  }

  const getGroupOptions = () => {
    const { process: { access } } = props

    return access
      .filter(group => group.name !== 'support')
      .map(group => ({
        value: group.name,
        text: group.name,
        key: group.name,
      }))
  }

  const {
    assignedGroupData,
    assignedUser,
    assignedGroup,
    loadingUsers,
  } = state

  const {
    isError,
    errorMessage,
    isSuccess,
    successMessage,
    loading,
  } = responseState

  const {
    process,
    user,
    activeDevice,
    closeHandler,
  } = props

  const groupOptions = getGroupOptions()

  const processUserIds = Authorizer.getAllUserIds(process)
  const deviceUserOptions = getDeviceUserOptions(processUserIds)
  const IsDeviceOrProcessAdmin = Authorizer.isResourceAdmin(activeDevice, user.id)
    || Authorizer.isResourceAdmin(process, user.id)
    || Authorizer.user(user).hasApplicationDeveloperAccess()

  const processUsers = getProcessUsers(processUserIds)

  return (
    <AccessProcessModalForm
      isError={isError}
      errorMessage={errorMessage}
      isSuccess={isSuccess}
      successMessage={successMessage}
      closeHandler={closeHandler}
      submitHandler={shareProcess}
      loading={loading}
      IsDeviceOrProcessAdmin={IsDeviceOrProcessAdmin}
      deviceUserOptions={deviceUserOptions}
      assignedUser={assignedUser}
      assignedGroup={assignedGroup}
      groupOptions={groupOptions}
      handleUserChange={handleUserChange}
      handleGroupChange={handleGroupChange}
      assignedGroupData={assignedGroupData}
      pendingRequests={pendingRequests}
      processUsers={processUsers}
      user={user}
      modifyAccess={modifyAccess}
      withDrawAccess={withDrawAccess}
      loadingUsers={loadingUsers}
      process={process}
      showSubmitButton={false}
    />
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccessProcessFormContainer))
