import React, { useState } from 'react'
import { FCTGraph, Functionality as FunctionalityModel } from '@ospin/fct-graph'
import { Accordion, Ref } from 'semantic-ui-react'
import { DragDropContext, Droppable, useMouseSensor, useTouchSensor } from 'react-beautiful-dnd'
import DataManager from '~/utils/DataManager'
import DraggableComponent from '~/components/utility/DraggableComponent'
import FctWidgetHeader from '~/components/processviewer/body/FctWidgetHeader'
import FCTDataViewerWrapperHOC from './FCTDataViewerWrapperHOC'
import SlotDataDisplay from './SlotDataDisplay'
import ImageViewer from './images/ImageViewer'

const Functionality = ({
  functionality,
  fctUIConfig: { folded, slots: slotUIConfigs },
  activeProcess,
  activeDevice,
  toggleFold,
  toggleSlotFold,
  index,
  handleOnDrag,
}) => {

  const [isDragDisabled, setDisableDrag ] = useState(true)
  const [ syncRangeSettings, setSyncRangeSettings ] = useState(null)

  const { name } = functionality
  const { fctGraph } = activeProcess

  if (!slotUIConfigs.length) return

  return (
    <DraggableComponent
      draggableId={functionality.id}
      index={index}
      isDragDisabled={isDragDisabled}
      className='disable-select'
    >
      <Accordion.Title
        active={!folded}
        content={<FctWidgetHeader activeProcess={activeProcess} functionality={functionality} />}
        onClick={toggleFold}
        style={{ fontSize: '18px', backgroundColor: '#f5f5f5', display: 'flex' }}
        className='accordion-menu-title'
        onMouseOver={() => setDisableDrag(false)}
        onFocus={() => setDisableDrag(false)}
        onMouseLeave={() => setDisableDrag(true)}
      />
      <DragDropContext
        onDragEnd={handleOnDrag}
        sensors={[useMouseSensor, useTouchSensor]}
      >
        <Droppable droppableId={`graph-id-${index}`} type={{ type: 'slot', groupName: name }}>
          { provided => (
            <Ref innerRef={provided.innerRef}>
              <Accordion.Content
                {...provided.droppableProps}
                active={!folded}
                style={{
                  paddingBottom: '40px',
                  backgroundColor: '#f5f5f5',
                }}
              >
                { !folded && (
                  <Accordion.Accordion>
                    {slotUIConfigs.map((slotUIConfig, idx) => {
                      const { name: slotName } = slotUIConfig
                      const slot = FunctionalityModel.getSlotByName(functionality, slotName)
                      const reporterFctId = FCTGraph
                        .getReporterFctIdForSlot(fctGraph, functionality.id, slot.name)
                      const loaded = DataManager.isSensorDataLoaded(activeProcess, reporterFctId)
                      const hasDataError = DataManager
                        .isSensorDataError(activeProcess, reporterFctId)
                      const sensorData = DataManager
                        .getSensorDataPoints(activeProcess, reporterFctId)
                      const lastSlotValue = DataManager
                        .getLastSensorDataPoint(activeProcess, reporterFctId)

                      return (
                        <FCTDataViewerWrapperHOC
                          key={slot.name}
                          folded={slotUIConfig.folded}
                          slotName={slot.name}
                          lastSlotValue={lastSlotValue}
                          unit={slot.unit}
                          index={idx}
                          hasDataError={hasDataError}
                          dragDisableOverride={slotUIConfigs.length === 1}
                          toggleFold={() => toggleSlotFold(slotUIConfig.name)}
                        >
                          { slot.displayType === 'image/jpeg'
                            ? (
                              <ImageViewer
                                activeProcess={activeProcess}
                                loaded={loaded}
                                fctId={reporterFctId}
                              />
                            )
                            : (
                              <SlotDataDisplay
                                slot={slot}
                                functionality={functionality}
                                activeProcess={activeProcess}
                                processState={activeProcess.state}
                                activeDevice={activeDevice}
                                sensorData={sensorData}
                                loaded={loaded}
                                reporterFctId={reporterFctId}
                                syncRangeSettings={syncRangeSettings}
                                setSyncRangeSettings={setSyncRangeSettings}
                              />
                            )}
                        </FCTDataViewerWrapperHOC>
                      )
                    })}
                    {provided.placeholder}
                  </Accordion.Accordion>
                )}
              </Accordion.Content>
            </Ref>
          )}
        </Droppable>
      </DragDropContext>
    </DraggableComponent>
  )
}
export default Functionality
