import React from 'react'
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = state => ({ authenticated: state.authenticated })

const PrivateRoute = ({ component: Component, ...rest }) => {
  const prevLocation = useLocation()

  const redirectTo = prevLocation.search ? `${prevLocation.pathname}${prevLocation.search}` : prevLocation.pathname

  return (
    <Route
      {...rest}
      render={props => (
        rest.authenticated
          ? <Component {...props} />
          : <Redirect to={`/login?redirectTo=${redirectTo}`} />
      )}
    />
  )
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
