import defaultIcon from '~/images/slotIcons/slot-default-icon.png'

import pressure from '~/images/slotIcons/pressure.png'
import frequency from '~/images/slotIcons/frequency.png'
import glucose from '~/images/slotIcons/glucose.png'
import lactate from '~/images/slotIcons/lactate.png'
import temperature from '~/images/slotIcons/temperature.png'
import pH from '~/images/slotIcons/pH.png'
import DO from '~/images/slotIcons/DO.png'
import CO2 from '~/images/slotIcons/CO2.png'
import air from '~/images/slotIcons/Air.png'
import pwm from '~/images/slotIcons/PWM.png'
import analog from '~/images/slotIcons/analog.png'
import duration from '~/images/slotIcons/duration.png'
import O2 from '~/images/slotIcons/O2.png'
import direction from '~/images/slotIcons/direction.png'
import switchImg from '~/images/slotIcons/switch.png'
import bitset from '~/images/slotIcons/bit.png'
import min from '~/images/slotIcons/min.png'
import max from '~/images/slotIcons/max.png'
import controllerStatus from '~/images/slotIcons/controller-status.png'
import securityFlag from '~/images/slotIcons/security-flag.png'
import motor from '~/images/slotIcons/motor.png'
import valve from '~/images/slotIcons/valve.png'
import pump from '~/images/slotIcons/pump.png'
import blood from '~/images/slotIcons/blood.png'
import P from '~/images/slotIcons/P.png'
import I from '~/images/slotIcons/I.png'
import D from '~/images/slotIcons/D.png'

const DISPLAY_TYPE_TO_IMAGE_MAP = {
  pressure,
  frequency,
  glucose,
  lactate,
  temperature,
  pH,
  DO,
  CO2,
  air,
  pwm,
  analog,
  duration,
  O2,
  direction,
  switch: switchImg,
  bitset,
  min,
  max,
  'pH controller status': controllerStatus,
  'controller status': controllerStatus,
  'security flag': securityFlag,
  'motor speed': motor,
  'valve position': valve,
  'pump speed': pump,
  'blood speed': blood,
  'P parameter': P,
  'I parameter': I,
  'D parameter': D,
}

export default class Slot {

  static getDefaultImage() { return defaultIcon }

  static getImage({ displayType }) {
    return DISPLAY_TYPE_TO_IMAGE_MAP[displayType] || Slot.getDefaultImage()
  }

  static valueOutOfLimits = (slot, value) => parseFloat(value) > parseFloat(slot.max)
      || parseFloat(value) < parseFloat(slot.min)

  static slotsHaveSameUnit(slotA, slotB) {
    return slotA.unit === slotB.unit
  }

}
