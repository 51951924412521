import Validator from '~/utils/validation/Validator'
import IntegerInSlot from './IntegerInSlot'

export default class FloatInSlot extends IntegerInSlot {

  componentWillUnmount() {
    /* this guarantees that we update the input when somebody collpses the section
     * without blurring beforehand */
    if (this.state.takingInput) {
      this.save()
    }
  }

  submitInputNodeValue = event => {
    const newTarget = event.target.value
    if (!Validator.isDigitString(newTarget) && newTarget !== '-' && newTarget !== '') return

    this.setState({ currentInput: newTarget, takingInput: true })
    event.stopPropagation()
  }

  save = async () => {
    const { inputNodeValue, updateInputNodeValue, inputNodeFctId } = this.props
    const { currentInput } = this.state

    if (currentInput === '-') {
      this.setState({ takingInput: false })
      return
    }

    if (
      inputNodeValue === currentInput
      || !Validator.isDigitString(currentInput)
    ) {
      this.setState({ takingInput: false })
      return
    }

    updateInputNodeValue(inputNodeFctId, Number(currentInput))
    this.setState({ takingInput: false, currentInput: '' })
  }

}
