import { ACTIONS } from '~/redux/actions/actions'
import removeDeviceUser from '~/redux/reducers/device/removeDeviceUser'
import modifyDeviceAccess from '~/redux/reducers/device/modifyDeviceAccess'
import transferDeviceOwnership from '~/redux/reducers/device/transferDeviceOwnership'
import setActiveDevice from '~/redux/reducers/device/setActiveDevice'
import updateDeviceConnectionState from '~/redux/reducers/device/updateDeviceConnectionState'
import updateDevice from '~/redux/reducers/device/updateDevice'
import setFunctionalityDescriptions from '~/redux/reducers/device/setFunctionalityDescriptions'
import setDevices from '~/redux/reducers/device/setDevices'

export default {
  [ACTIONS.SET_DEVICES]: setDevices,
  [ACTIONS.REMOVE_DEVICE_USER]: removeDeviceUser,
  [ACTIONS.MODIFY_DEVICE_ACCESS]: modifyDeviceAccess,
  [ACTIONS.TRANSFER_DEVICE_OWNERSHIP]: transferDeviceOwnership,
  [ACTIONS.SET_ACTIVE_DEVICE]: setActiveDevice,
  [ACTIONS.UPDATE_DEVICE_CONNECTION_STATE]: updateDeviceConnectionState,
  [ACTIONS.UPDATE_DEVICE]: updateDevice,
  [ACTIONS.SET_FUNCTIONALITY_DESCRIPTIONS]: setFunctionalityDescriptions,
}
