import React from 'react'
import formalize from '~/components/utility/modal/formalize'
import { Button, Checkbox } from 'semantic-ui-react'

const groupByFctId = fctAndSlotNames => (
  fctAndSlotNames.reduce((acc, fctAndSlot) => {
    const { id, name, slotName, displaySlotName } = fctAndSlot
    const existing = acc.find(entry => entry.id === id)

    if (existing) {
      existing.slotNames.push(slotName)
      existing.displaySlotNames.push(displaySlotName)
    } else {
      acc.push({
        id,
        name,
        slotNames: [slotName],
        displaySlotNames: [displaySlotName],
      })
    }
    return acc
  }, [])
)

const isSelected = (selectedFctsAndSlots, fctId, slotName) => (
  selectedFctsAndSlots
    .some(selectedFctAndSlot => selectedFctAndSlot.slotName === slotName
      && selectedFctAndSlot.id === fctId)
)

const DownloadProcessDatapointsModalForm = ({
  handleSelectAll,
  hasAllSelectedCheckboxes,
  fctAndSlotNames,
  selectedFctsAndSlots,
  updateSelectedFctsAndSlots,
}) => (
  <div>
    <Button
      className='download-process-data-points-modal-select-button'
      onClick={() => handleSelectAll()}
    >
      {`${!hasAllSelectedCheckboxes ? 'Select' : 'Deselect'} all`}
    </Button>
    <div
      className='download-process-data-points-modal-selection-container'
    >
      <div>
        {
          groupByFctId(fctAndSlotNames).map(({ id, name, slotNames, displaySlotNames }) => (
            <div key={id}>
              <div
                className='download-process-data-points-modal-form-fct-name'
              >
                {name}
              </div>
              {slotNames.map((slotName, idx) => {
                const checkboxSelected = isSelected(selectedFctsAndSlots, id, slotName)
                return (
                  <div key={slotName}>
                    <Checkbox
                      data-testid='download-checkbox'
                      checked={checkboxSelected}
                      label={displaySlotNames[idx]}
                      className='download-process-data-points-modal-form-checkbox'
                      onChange={() => updateSelectedFctsAndSlots({ id, slotName }, checkboxSelected)}
                    />
                  </div>
                )
              })}
            </div>
          ))
        }
      </div>
    </div>
  </div>
)

export default formalize(DownloadProcessDatapointsModalForm)
