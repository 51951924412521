import React, { useState } from 'react'
import { connect } from 'react-redux'
import EditProcessModalForm from '~/components/device/modals/EditProcessModalForm'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import Authorizer from '~/utils/Authorizer'

const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

const EditProcessFormContainer = props => {
  const { process, dispatchCallUpdateProcess, closeHandler, user } = props

  const [ newName, setNewName ] = useState(process.name)
  const [ newComment, setNewComment ] = useState(process.comment)
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(false)
  const [ successMessage, setSuccessMessage ] = useState('')

  const handleUpdate = (_, { name, value }) => {
    if (name === 'newName') {
      setNewName(value)
    } else {
      setNewComment(value)
    }
    setIsError(false)
    setErrorMessage('')
  }

  const invalidData = () => {
    if (!ProcessValidator.isValidProcessName(newName)) {
      setIsError(true)
      setErrorMessage(`Process name must be shorter than ${nameLength + 1} characters`)
      return true
    }

    if (!ProcessValidator.isValidProcessComment(newComment)) {
      setIsError(true)
      setErrorMessage(`Process comment must be shorter than ${commentLength + 1} characters`)
      return true
    }

    return false
  }

  const updateProcess = async () => {
    if (invalidData(newName, newComment)) return

    setLoading(true)

    try {
      await dispatchCallUpdateProcess(process.id, { name: newName, comment: newComment })
      setIsSuccess(true)
      setSuccessMessage('The process has been updated successfully')
    } catch (e) {
      setIsError(true)
      setErrorMessage(e.message)
      setIsSuccess(false)
      setSuccessMessage('')
    } finally {
      setLoading(false)
    }
  }

  const isDisabled = Authorizer.isResourceViewer(process, user.id)

  return (
    <EditProcessModalForm
      isError={isError}
      errorMessage={errorMessage}
      isSuccess={isSuccess}
      successMessage={successMessage}
      disableSubmit={(ProcessValidator.isProcessNameMinLengthValid(newName)) || isDisabled}
      submitHandler={updateProcess}
      loading={loading}
      newName={newName}
      newComment={newComment}
      handleUpdate={handleUpdate}
      submitText='Save'
      closeHandler={closeHandler}
      isDisabled={isDisabled}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProcessFormContainer)
