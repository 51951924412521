import React from 'react'
import { connect } from 'react-redux'
import modalize from '~/components/utility/modal/modalize'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import DeleteGroupModalForm from '~/components/processviewer/modals/DeleteGroupModalForm'
import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import { updateProcess } from '~/redux/actions/actions'
import { Process } from '~/utils/process'

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
  dispatchUpdateProcess: (processId, params) => dispatch(updateProcess({ processId, params })),
})

const mapStateToProps = ({ processDescriptionSnapshots }) => ({ processDescriptionSnapshots })

class DeleteGroupModal extends React.Component {

  state = {
    isError: false,
    errorMessage: '',
    toggleOriginalPhases: true,
  }

  render() {

    const { isError, errorMessage, toggleOriginalPhases } = this.state

    const {
      closeHandler,
      activeProcess,
      groupName,
      dispatchUpdateProcess,
      dispatchCallUpdateProcess,
    } = this.props

    const groupSize = PhaseGroupParser.getGroupSize(activeProcess, groupName)
    const isContainingAllProcessPhases = groupSize === Object.keys(activeProcess.description).length

    const deleteGroup = async () => {
      const state = Process.getState(activeProcess)
      PhaseGroupHandler.deleteGroup(activeProcess, groupName, toggleOriginalPhases)
      const isRunning = (
        Process.isPaused({ state })
        || Process.isRunning({ state })
      )

      if (Process.isExecutable({ state })) {
        await Process.save(activeProcess, dispatchCallUpdateProcess)
      } else if (isRunning) {
        await Process.save(activeProcess, dispatchUpdateProcess)
      }
    }

    const toggleKeepOriginalPhases = value => {
      this.setState({ toggleOriginalPhases: value })
    }

    return (
      <>
        <DeleteGroupModalForm
          isError={isError}
          errorMessage={errorMessage}
          closeHandler={closeHandler}
          toggleKeepOriginalPhases={toggleKeepOriginalPhases}
          toggleOriginalPhases={toggleOriginalPhases}
          submitHandler={deleteGroup}
          submitText='Confirm'
          isContainingAllProcessPhases={isContainingAllProcessPhases}
        />
      </>
    )
  }
}

export default modalize(connect(mapStateToProps, mapDispatchToProps)(DeleteGroupModal))
