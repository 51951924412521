import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './ConnectionIssueBanner.css'

const mapStateToProps = ({ pusherConnectionState }) => ({ pusherConnectionState })

const solutionDisconnect = 'Try reloading the page and '
  + 'make sure you are connected to the internet'

const solutionBrowser = 'Try using the latet version of Google Chrome or Mozilla Firefox. '
  + ' If this does not solve your problem, please contact OSPIN.'

const DISPLAYED_ERRORS = {
  DISCONNECTED: `Your application seems to have lost connection to live updates. ${solutionDisconnect}`,
  UNSUPPORTED_BROWSER: `Your browser does not seem to support live updates. ${solutionBrowser}`,
}

const mapErrorToDisplayText = error => {
  if (error in DISPLAYED_ERRORS) {
    return DISPLAYED_ERRORS[error]
  }
  return 'Unknown Pusher error'
}

const ConnectionIssueBanner = ({ pusherConnectionState }) => {

  const { connected: connectedToPusher, error } = pusherConnectionState

  if (connectedToPusher) return null

  return (
    <div>
      <div className='connection-issue-banner'>
        {mapErrorToDisplayText(error)}
        <div className='connection-issue-banner-button-container'>
          <Button
            compact
            onClick={() => setVisibility(false)}
            className='connection-issue-banner-button'
          >
            Ignore
          </Button>
          {error !== 'UNSUPPORTED_BROWSER' &&
            <Button
              compact
              primary
              className='connection-issue-banner-button'
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(ConnectionIssueBanner)
