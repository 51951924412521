import { getDeploymentEnv } from "~/config/envDetector";

class GlobalConfig {
  static get REPORT_CLIENT_ERRORS() {
    switch (getDeploymentEnv()) {
      case 'dev':
        return  {
          enabled: import.meta.env.MODE === 'production', /// production mode means built rather than development server
          target: 'development'
        }
      case 'staging': {
        return {
          enabled: true,
          target: 'staging'
        }
      }
      case 'prod': {
        return {
          enabled: true,
          target: 'prod'
        }
      }
      default:
        break;
    }
  }
}

export default GlobalConfig
