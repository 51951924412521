import React from 'react'
import { Button, Popup, Icon } from 'semantic-ui-react'

import Device from '~/utils/Device'

const StopProcessButton = ({
  toggleModal,
  activeDevice,
}) => (
  <Popup
    trigger={(
      <Button
        primary
        className='start-stop-control-button'
        size='small'
        onClick={e => toggleModal(e, 'showStopProcessModal')}
        disabled={Device.hasInconsistentState(activeDevice)}
      >
        <Icon name='stop' />
        Stop
      </Button>
    )}
    content='stop process'
    position='bottom center'
  />
)

export default StopProcessButton
