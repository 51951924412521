import PDVC from '~/utils/PDVC'
import ProcessViewerDisplay from '~/utils/display/ProcessViewerDisplay'

export default (state, action) => {
  const process = action.payload
  const { id, description, entryPhaseId } = process

  return {
    ...state,
    displayedPhaseId: ProcessViewerDisplay.getInitiallyDisplayedPhaseId(process),
    processBuilderValidationErrors: [],
    processDescriptionSnapshots: [ PDVC.createSnapshot(id, description, entryPhaseId) ],
    downloadRequests: [],
  }
}
