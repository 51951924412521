import React from 'react'
import { List, Message } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'

const FailedFirmwareUpdateModalForm = ({ devicesWithFailedUpdates }) => (
  <div>
    <Message negative>
      <Message.Header>The firmware update failed for</Message.Header>
      <List bulleted style={{ marginLeft: 32 }}>
        {devicesWithFailedUpdates.map(device => (
          <List.Item key={device.id}>{device.name}</List.Item>
        ))}
      </List>
    </Message>
    <strong>What now?</strong>
    <p>
      The device(s) will notify you when another firmware update attempt
      can be started. Please make sure that you provide a stable internet connection to the device while it is updating.
    </p>
  </div>
)

export default formalize(FailedFirmwareUpdateModalForm)
