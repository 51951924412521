import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import nexus from '@ospin/nexus'
import Notification from '~/utils/user/Notification'
import DynamicLoader from '~/components/utility/DynamicLoader'
import Device from '~/utils/Device'
import { setDevices } from '~/redux/actions/actions'
import { Message } from 'semantic-ui-react'
import DeviceList from './DeviceList'
import './Devices.css'
import DeviceListControl from './DeviceListControl'

const mapStateToProps = state => ({
  user: state.user,
  devices: state.devices,
})

const mapDispatchToProps = dispatch => ({
  dispatchSetDevices: devices => dispatch(
    setDevices(devices),
  ),
})

const DEFAULT_PAGINATION_LIMIT = 15

const renderPendingInvitationMessage = () => (
  <div>
    It seems you have an outstanding invitation.
    <Link to='/messages'> Check your messages!</Link>
  </div>
)

const renderNoDevicesMessage = pendingDeviceInvitation => (
  <div className='device-list-no-devices-placeholder'>
    You don&apos;t have access to any devices yet.
    <br />
    {pendingDeviceInvitation ? (
      renderPendingInvitationMessage())
      : (
        <p>Contact a device administrator to add you to a device.</p>
      )}
  </div>
)

class Devices extends React.Component {

  state = {
    initializing: true,
    textQuery: '',
    skip: 0,
    totalNumberOfDevices: 0,
    error: null,
    currentlyAppliedTextQuery: '',
  }

  async componentDidMount() {
    const { history } = this.props
    this.fetchDevices()

    history.replace('devices')
  }

  async fetchDevices(newSkip = 0, newTextQuery = '') {
    const { user, dispatchSetDevices } = this.props
    const query = {
      userId: user.id,
      queryStringParameters: { skip: newSkip, limit: DEFAULT_PAGINATION_LIMIT },
    }

    if (newTextQuery) {
      query.queryStringParameters.textQuery = newTextQuery
    }

    this.setState({ initializing: true })

    try {
      const { data: { devices, totalNumberOfDevices } } = await nexus.user.device
        .list(query)

      dispatchSetDevices(devices)

      this.setState({
        initializing: false,
        totalNumberOfDevices,
        error: null,
        skip: newSkip,
        textQuery: newTextQuery,
        currentlyAppliedTextQuery: newTextQuery
      })
    } catch ({ message }) {
      this.setState({ error: message, initializing: false })
    }

  }

  sortDevices(filteredDevices) {
    return filteredDevices.sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name))
  }

  sortByOnlineAndName(devices) {
    const filteredOnlineDevices = devices.filter(Device.isOnline)
    const sortedOnlineDevices = this.sortDevices(filteredOnlineDevices)

    const filteredOfflineDevices = devices.filter(Device.isOffline)
    const sortedOfflineDevices = this.sortDevices(filteredOfflineDevices)

    return sortedOnlineDevices.concat(sortedOfflineDevices)
  }

  updateActivePage({ skip, textQuery }) {
    this.fetchDevices(skip, textQuery)
  }

  updateTextQuery(textQuery) {
    this.setState({ textQuery })
  }

  renderContent() {
    const { initializing, currentlyAppliedTextQuery } = this.state
    const { devices, user } = this.props

    if (initializing) {
      return <DynamicLoader />
    }

    if (!devices.length && currentlyAppliedTextQuery) {
      return <Message info content={`No device matching ${currentlyAppliedTextQuery} found`} />
    }

    if (!devices.length) {
      return renderNoDevicesMessage(
        Notification.getInvitationNotifications(user.notifications).length > 0,
      )
    }

    return (
      <DeviceList
        devices={this.sortByOnlineAndName(devices)}
      />
    )
  }

  render() {
    const {
      skip,
      totalNumberOfDevices,
      textQuery,
      error,
      currentlyAppliedTextQuery,
      initializing,
    } = this.state

    if (error) {
      return <Message error content={error} />
    }

    return (
      <div>
        <DeviceListControl
          initializing={initializing}
          totalNumberOfDevices={totalNumberOfDevices}
          skip={skip}
          limit={DEFAULT_PAGINATION_LIMIT}
          textQuery={textQuery}
          currentlyAppliedTextQuery={currentlyAppliedTextQuery}
          updateActivePage={props => this.updateActivePage(props)}
          updateTextQuery={text => this.updateTextQuery(text)}
        />
        { this.renderContent()}

      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Devices))
