import nexus from '@ospin/nexus'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import modalize from '~/components/utility/modal/modalize'
import FlashMessenger from '~/utils/FlashMessenger'
import AccessDeviceModalForm from './AccessDeviceModalForm'

const mapStateToProps = state => ({ user: state.user })

const AccessDeviceModal = props => {
  const [saveMsg, setSaveMsg] = useState({ isError: false, msg: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [accessDetails, setAccessDetails] = useState({
    userIdentifierValue: '',
    identifierType: 'email',
    assignedGroup: '',
  })

  const onToggleClose = () => {
    const { closeHandler } = props
    closeHandler()
  }

  const handleChanges = (key, value) => {
    setAccessDetails({
      ...accessDetails,
      [key]: value,
    })
  }

  const { userIdentifierValue, identifierType, assignedGroup } = accessDetails

  const onSubmit = async () => {
    const isIdentifierValueValid = identifierType === 'email'
      ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(userIdentifierValue.trim())
      :  /^.+$/.test(userIdentifierValue)

    if (!assignedGroup) {
      setSaveMsg({ isError: true, msg: 'Please provide a group for the user.' })
      return
    }

    if (!isIdentifierValueValid) {
      setSaveMsg({ isError: true, msg: `Please enter a valid ${identifierType.toLowerCase()}.` })
      return
    }

    setIsLoading(true)

    const { activeDevice, user, appendInvitation } = props

    try {
      const { data: newInvitation } = await nexus.device.access.grant({
        deviceId: activeDevice.id,
        params: {
          [identifierType]: userIdentifierValue.trim(),
          groupName: assignedGroup,
          hostName: user.userName,
        },
      })

      const { activeDevice: { name } } = props
      appendInvitation(newInvitation)
      FlashMessenger.success(`User has been invited to ${name}.`)
      onToggleClose()
    } catch (e) {
      setSaveMsg({ isError: true, msg: e.message })
      setIsLoading(false)
    }
  }

  const { activeDevice: { access } } = props
  const groupOptions = access
    .filter(group => group.name !== 'support')
    .map(group => ({
      value: group.name,
      text: group.name,
      key: group.name,
    }))

  return (
    <AccessDeviceModalForm
      isError={saveMsg.isError}
      selected={accessDetails.assignedGroup}
      errorMessage={saveMsg.msg}
      successMessage={saveMsg.msg}
      closeHandler={onToggleClose}
      submitHandler={onSubmit}
      submitText='Add User'
      loading={isLoading}
      groupOptions={groupOptions}
      handleChanges={handleChanges}
      identifierType={accessDetails.identifierType}
    />
  )
}

export default modalize(connect(mapStateToProps)(AccessDeviceModal))
