import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { Process } from '~/utils/process'
import FlashMessenger from '~/utils/FlashMessenger'
import { ellipseString } from '~/utils/string'
import PDVC from '~/utils/PDVC'

const isSubsequentPhaseProgression = params => (
  !params.state && params.progression
)

const messageNotRequired = (params, process) => (
  !(params.state || params.progression) || !process
)

const handleDefaultUpdate = ({ processId, processUpdateFns, state }) => {
  const { processes } = state
  const updatedProcesses = findAndUpdate(processId, processes, processUpdateFns)
  return { ...state, processes: updatedProcesses }
}

const handleInvalidPhaseProgression = ({ state, processId, processUpdateFns }) => {
  const { processes } = state
  processUpdateFns.pop() // remove the original update
  processUpdateFns.push(targetProcess => Process.markProcessAsOutdated(targetProcess))
  const updatedProcesses = findAndUpdate(processId, processes, processUpdateFns)
  return {
    ...state,
    processes: updatedProcesses,
  }
}

const handlePhaseProgression = ({
  process,
  processId,
  state,
  processUpdateFns,
  params: { progression },
}) => {
  const { processes, processDescriptionSnapshots } = state

  /* only run this when process is not in the "list" view */
  if (Process.isEnriched(process)) {
    /* When the process has adhoc changes staged,
     * we have to revert them when a new phase arrives */

    const diffs = PDVC.getDiff(process, processDescriptionSnapshots)

    if (diffs.length) {
      const revertedDescription = PDVC.revertToSnapshot(process, processDescriptionSnapshots)
      const updateDescriptionFn = targetProcess => Object
        .assign(targetProcess, { description: revertedDescription })
      processUpdateFns.unshift(updateDescriptionFn)
    }

    const isValidProgression = Process
      .isValidPhaseProgression(
        {
          ...process,
          description: processDescriptionSnapshots.length
            ? processDescriptionSnapshots[0].description
            : process.description,
        },
        progression,
      )

    if (!isValidProgression) {
      return handleInvalidPhaseProgression({
        process,
        state,
        processId,
        processUpdateFns,
      })
    }
  }

  const { flashMessages } = state

  const updatedFlashMessages = [ ...flashMessages ]
  if (process.progression.length) {
    const msg = `${ellipseString(process.name, 25)} progressed into the next phase!`
    const flashMsg = FlashMessenger.createMessage('info', msg)
    updatedFlashMessages.push(flashMsg)
  }

  const updatedProcesses = findAndUpdate(processId, processes, processUpdateFns)
  return {
    ...state,
    processes: updatedProcesses,
    flashMessages: updatedFlashMessages,
  }
}

export default (state, action) => {
  const { processes, flashMessages } = state
  const { processId, params } = action.payload

  const processUpdateFns = [
    process => Object.assign(process, params),
  ]

  const process = Process.getById(processes, processId)

  if (messageNotRequired(params, process)) {
    return handleDefaultUpdate({
      processId,
      processes,
      processUpdateFns,
      state,
    })
  }

  if (isSubsequentPhaseProgression(params)) {
    return handlePhaseProgression({
      process,
      processId,
      state,
      processUpdateFns,
      params,
    })
  }

  const updatedFlashMessages = [ ...flashMessages ]

  if (process.state !== params.state) {
    const msg = `${ellipseString(process.name, 25)} is ${params.state}!`
    const flashMsg = FlashMessenger.createMessage('info', msg)
    updatedFlashMessages.push(flashMsg)
  }

  const updatedProcesses = findAndUpdate(processId, processes, processUpdateFns)

  return {
    ...state,
    processes: updatedProcesses,
    flashMessages: updatedFlashMessages,
  }
}
