import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'
import { findAndUpdate } from '~/redux/helper/updateFunctions'

export default (state, action) => {
  const { processId, phaseIds } = action.payload
  const { processes } = state

  const updateFn = process => PhaseGroupHandler.createGroup(process, phaseIds)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
