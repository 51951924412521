import React from 'react'
import { connect } from 'react-redux'

import callAuthenticateUser from '~/redux/thunks/auth/callAuthenticateUser'
import Routes from '~/components/routing/Routes'
import DynamicLoader from '~/components/utility/DynamicLoader'

const mapStateToProps = state => ({ authenticated: state.authenticated })

const mapDispatchToProps = dispatch => ({
  authenticateUser: () => callAuthenticateUser(dispatch),
})

class App extends React.Component {

  componentDidMount() {
    const {
      authenticateUser,
    } = this.props
    authenticateUser()
  }

  render() {
    const { authenticated } = this.props
    return (
      authenticated === undefined
        ? <DynamicLoader />
        : <Routes />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
