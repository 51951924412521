import React from 'react'

import PhaseTabContent from './PhaseTabContent'

const Builder = ({
  phases,
  activeDevice,
  activeProcess,
}) => (
  <div className='no-border no-shadow'>
    {phases.map(({ data: phase, id }) => (
      <PhaseTabContent
        key={id}
        phaseId={id}
        phase={phase}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        phasesLength={phases.length}
        phases={phases}
      />
    ))}
    <div />
  </div>
)

export default Builder
