import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import Validator from '~/utils/validation/Validator'
import { Form, Input, Button } from 'semantic-ui-react'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'

const mapStateToProps = state => ({ user: state.user })

const comparatorOptions = ['<', '<=', '>=', '>'].map(c => ({ key: c, value: c, text: c }))

const EscalationWidgetModalFormRow = props => {
  const { escalation, index, widgetId, user, dashboard } = props
  /*
   * same hack as for numeric input fields in the process builder:
   * We want to render from the data, but we have to allow temporary invalid
   * values, e.g. when somebody clears the field; so we use a flag to render either
   * from the local variable when a user is typing, and using the the value
   * from the data when it was submitted.
   */
  const [takingInput, setTakingInput] = useState(false)
  const [localInput, setLocalInput] = useState('')
  const [
    newLocalInputBackgroundColor,
    setNewLocalInputBackgroundColor,
  ] = useState(escalation.backgroundColor)
  const [ newLocalInputFontColor, setNewLocalInputFontColor] = useState(escalation.fontColor)

  const ref = useRef(null)

  const { fctGraphUIConfig: { configs, configs: { dashboards }, fctGraphId } } = user

  const removeEscalation = async () => {
    const updatedDashboard = GraphDashboard
      .removeSensorWidgetEscalation(dashboard, widgetId, index)
    await UserFctGraphUIConfig.update(fctGraphId, {
      ...configs, dashboards: GraphDashboards.updateDashboard(dashboards, updatedDashboard) })
  }

  const updateEscalation = updatedData => {
    const updatedDashboard = GraphDashboard
      .updateSensorWidgetEscalation(dashboard, widgetId, index, updatedData)

    // Not awaiting here because it interferes with onChange/onInput events
    // for color-picker input on Firefox
    UserFctGraphUIConfig.update(fctGraphId, {
      ...configs, dashboards: GraphDashboards.updateDashboard(dashboards, updatedDashboard),
    })
  }

  const saveDashboard = async () => {
    await UserFctGraphUIConfig.update(
      fctGraphId, {
        ...configs,
        dashboards: GraphDashboards.updateDashboard(dashboards, dashboard),
      })
  }

  const updateBackGroundColorOnBlur = () => {
    updateEscalation({ backgroundColor: newLocalInputBackgroundColor })
  }

  const updateFontColorOnBlur = () => {
    updateEscalation({ fontColor: newLocalInputFontColor })
  }

  const updateOperator = value => { updateEscalation({ comparator: value }) }

  const updateThreshold = value => {
    if (!Validator.isDigitString(value) && value !== '-' && value !== '') return
    setTakingInput(true)
    setLocalInput(value)
  }

  const submitThreshold = async () => {
    if (localInput === '-') {
      setTakingInput(false)
      return
    }

    if (!Validator.isDigitString(localInput)) {
      setTakingInput(false)
      return
    }

    const updateData = { threshold: parseFloat(localInput) }
    const updatedDashboard = GraphDashboard
      .updateSensorWidgetEscalation(dashboard, widgetId, index, updateData)

    await UserFctGraphUIConfig.update(
      fctGraphId,
      {
        ...configs,
        dashboards: GraphDashboards.updateDashboard(
          dashboards,
          updatedDashboard,
        ),
      },
    )
    setTakingInput(false)
    setLocalInput('')
  }

  return (
    <div>
      <Form.Group>
        <Form.Select
          name='comparator'
          type='text'
          label='Comparison'
          onChange={(_, { value }) => updateOperator(value)}
          onBlur={saveDashboard}
          options={comparatorOptions}
          value={escalation.comparator}
        />
        <Form.Field>
          <label htmlFor='threshold'>Threshold</label>
          <Input
            id='threshold'
            name='threshold'
            type='text'
            placeholder='Enter value'
            onChange={({ target: { value } }) => updateThreshold(value)}
            onBlur={submitThreshold}
            value={takingInput ? localInput : escalation.threshold}
            onKeyDown={e => {
              /* forcing a submit when hitting enter and preventing default
               * submit via the Delete button
               */
              if (e.keyCode === 13) {
                ref.current.focus()
                e.preventDefault()
              }
            }}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor='backgroundColor'>Background</label>
          <input
            id='backgroundColor'
            name='backgroundColor'
            type='color'
            placeholder='Background'
            onInput={({ target: { value } }) => setNewLocalInputBackgroundColor(value)}
            onBlur={updateBackGroundColorOnBlur}
            value={newLocalInputBackgroundColor}
            style={{ height: '38px' }}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor='fontColor'>Font</label>
          <input
            id='fontColor'
            name='fontColor'
            type='color'
            placeholder='Font'
            onInput={({ target: { value } }) => setNewLocalInputFontColor(value)}
            onBlur={updateFontColorOnBlur}
            value={newLocalInputFontColor}
            style={{ height: '38px' }}
          />
        </Form.Field>
        <Form.Field>
          <Button
            style={{ marginTop: '24px' }}
            onClick={removeEscalation}
          >
            Delete
          </Button>
        </Form.Field>
      </Form.Group>
    </div>
  )
}

export default connect(mapStateToProps)(EscalationWidgetModalFormRow)
