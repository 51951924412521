import { Process } from '~/utils/process'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseProgression from '~/utils/process/PhaseProgression'
import Phase from '~/utils/process/Phase'
import EventHistoryParser from '~/utils/process/EventHistoryParser'
import nexus from '@ospin/nexus'

export default class ProcessUpdateManager {

  static get REASONS() {
    return {
      INVALID_DURATION: 'The supplied duration for the running phase is below the already elapsed time.',
      PROCESS_FINISHED: 'The process is finished.',
      TOO_LATE: 'Process live updates cannot be applied within the last'
        + ` ${ProcessUpdateManager.MINIMUM_TIME_TILL_PHASE_TRANSITION_IN_SECONDS} seconds of a time-based`
        + ' running phase.',
    }
  }

  static get MINIMUM_TIME_TILL_PHASE_TRANSITION_IN_SECONDS() {
    return 10
  }

  static async allowAdhocChangesToPhase(process) {
    const runningPhaseId = Process.getLatestPhaseProgressionEntry(process).phaseId
    const runningPhase = DescriptionParser.getPhaseById(process, runningPhaseId)

    if (Phase.getTransitionMethod(runningPhase) === Phase.TRANSITIONS.MANUAL) {
      return { allow: true }
    }

    /* TODO: ADD_ERROR_HANDLING */
    const { data: fetchedProcess } = await nexus.process.get(process.id)
    const pausedDuration = EventHistoryParser
      .getFullPausedDurationFromEvents(fetchedProcess, runningPhaseId)

    const phaseDuration = Phase.getDuration(runningPhase)
    const elapsedTime = PhaseProgression
      .getSecondsSincePhaseStart(process, runningPhaseId) - pausedDuration

    const elapsedTimeWithSecurity = elapsedTime
      + ProcessUpdateManager.MINIMUM_TIME_TILL_PHASE_TRANSITION_IN_SECONDS

    const elapsedTimeIsBelowDuration = elapsedTime < phaseDuration
    const elapsedTimeAboveSecurityThreshold = elapsedTimeWithSecurity > phaseDuration

    if (elapsedTimeIsBelowDuration && elapsedTimeAboveSecurityThreshold) {
      return { allow: false, reason: ProcessUpdateManager.REASONS.TOO_LATE }
    }

    if (!elapsedTimeIsBelowDuration) {
      return { allow: false, reason: ProcessUpdateManager.REASONS.INVALID_DURATION }
    }

    return { allow: true }
  }

  static async adhocChangesAllowed(process) {
    if (Process.isFinished(process)) {
      return { allow: false, reason: ProcessUpdateManager.REASONS.PROCESS_FINISHED }
    }

    if (Process.isExecutable(process)) return { allow: true }

    return ProcessUpdateManager.allowAdhocChangesToPhase(process)
  }

}
