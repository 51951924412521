import nexus from '@ospin/nexus'
import React, { useEffect, useState } from 'react'
import { Loader, Message } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import TemplateUtils from './Utils/TemplateUtils'
import TemplateBuilder from './TemplateBuilder'
import DeviceTemplateUtils from './Utils/DeviceTemplateUtils'

function TemplateBuilderWrapper({ activeDevice }) {
  const { templateId } = useParams()
  const [ loading, setIsLoading ] = useState(true)
  const [ sourceTemplate, setSourceTemplate ] = useState({})
  const [ functionalityDefinitions, setFunctionalityDefinitions ] = useState([])
  const [ sourceLayout, setSourceLayout ] = useState({})
  const [ error, setError ] = useState(null)


  async function loadExisingTemplate() {
    try {
      const [
        { data: { functionalityDefinitions: nexusFctDefs } },
        { data: { template, layout: responseLayout } },
      ] = await Promise.all([
        nexus.deviceDescription.functionalityDefinition.list(),
        nexus.deviceDescription.template.get(templateId, { deviceId: activeDevice.id })
      ])

      const availableDefinitions = DeviceTemplateUtils.getAllAvailableFcts(
        activeDevice,
        nexusFctDefs,
        template,
      )
      const layout = responseLayout ? responseLayout.layout : { nodePositions: {} }

      setFunctionalityDefinitions(availableDefinitions)
      setSourceTemplate(template)
      setSourceLayout(layout)

    } catch (responseError) {
      setError(responseError)
    }
  }

  async function initializeNewLayout() {
    const { data: { functionalityDefinitions: nexusFctDefs },
    } = await nexus.deviceDescription.functionalityDefinition.list()
    const emptySourceTemplate = TemplateUtils.generateEmpty(activeDevice.id)
    setSourceTemplate(emptySourceTemplate)
    setFunctionalityDefinitions( nexusFctDefs )
  }

  useEffect(() => {
    async function loadTemplate() {
      if (templateId) {
        await loadExisingTemplate()
      } else {
        await initializeNewLayout()
      }
      setIsLoading(false)
    }

    loadTemplate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <Message error content={`${error}`}/>
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader inline active />
      </div>
    )
  }

  return (
    <div>
      <TemplateBuilder
        functionalityDefinitions={functionalityDefinitions}
        activeDevice={activeDevice}
        sourceTemplate={sourceTemplate}
        setSourceTemplate={setSourceLayout}
        sourceLayout={sourceLayout}
        setSourceLayout={setSourceLayout}
      />
    </div>
  )
}

export default TemplateBuilderWrapper
