import React, { useState } from 'react'
import { Container, Segment, Item, Header, Divider, Message, Button, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import callDeleteManyDeviceAlertsByDeviceId from '~/redux/thunks/event/callDeleteManyDeviceAlertsByDeviceId'
import { connect } from 'react-redux'
import DeviceAuthorizer from '~/utils/DeviceAuthorizer'
import Device from '~/utils/Device'
import AlertsDeleteModal from './AlertsDeleteModal'
import Alert from './Alert'

const mapDispatchToProps = dispatch => ({
  dispatchCallDeleteManyDeviceAlertsByDeviceId: deletableDeviceAlertsDeviceIds =>
    callDeleteManyDeviceAlertsByDeviceId(dispatch, deletableDeviceAlertsDeviceIds),
})

const mapStateToProps = ({ devices, user }) => ({ devices, user })

const DeviceAlerts = ({
  deviceAlerts,
  deleteAlert,
  dispatchCallDeleteManyDeviceAlertsByDeviceId,
  devices,
  user,
}) => {
  const [onDeleteModal, setOnDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const deletableDeviceAlertsDeviceIds = Array.from(
    deviceAlerts.reduce((deletableIds, { deviceId }) => {
      const device = Device.getById(devices, deviceId)
      const userCanDelete = DeviceAuthorizer.isUserAbleToDeleteNotification(device, user)

      return userCanDelete ? deletableIds.add(deviceId) : deletableIds
    }, new Set()),
  )

  function alertIsDeletable(alert) {
    return DeviceAuthorizer.isUserAbleToDeleteNotification(
      Device.getById(devices, alert.deviceId), user,
    )
  }

  const deleteAllDeviceAlerts = async e => {
    setLoading(true)
    e.preventDefault()
    /* TODO: ADD_ERROR_HANDLING */
    await dispatchCallDeleteManyDeviceAlertsByDeviceId(deletableDeviceAlertsDeviceIds)
    setLoading(false)
    setOnDeleteModal(false)
  }

  const toggleOnDeleteModal = () => {
    setOnDeleteModal(!onDeleteModal)
  }

  return (
    <Container fluid text>
      <AlertsDeleteModal
        headerText='Delete Alerts'
        open={onDeleteModal}
        loading={loading}
        closeHandler={toggleOnDeleteModal}
        deleteAllDeviceAlerts={deleteAllDeviceAlerts}
      />
      <Segment>
        <div style={{
          height: '20px', display: 'flex ', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between',
        }}
        >
          <Header as='h2'>Device Alerts</Header>
          { deviceAlerts.length > 0 ? (
            <Popup
              content='You are not authorized to delete any of these alerts'
              disabled={(deletableDeviceAlertsDeviceIds.length > 0)}
              trigger={(
                <span>
                  <Button
                    onClick={toggleOnDeleteModal}
                    negative
                    style={{ padding: '5px 10px', marginBottom: '15px', marginRight: '0px' }}
                    disabled={deletableDeviceAlertsDeviceIds.length === 0}
                  >
                    Delete Alerts
                  </Button>
                </span>
              )}
            />
          ) : null }
        </div>
        <Divider />
        <Item.Group divided>
          { deviceAlerts.length === 0 ? (
            <Message warning>
              <Message.Header>You have no Alerts!</Message.Header>
              <p>You can create alerts in the process viewer using the alerts tab.</p>
            </Message>
          ) : (
            deviceAlerts.map(alert => (
              <Alert
                key={alert.id}
                alert={alert}
                deleteAlert={deleteAlert}
                isDeletable={alertIsDeletable(alert)}
              />
            ))
          )}
        </Item.Group>
      </Segment>
      <Divider hidden />
    </Container>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceAlerts))
