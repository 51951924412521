import nexus from '@ospin/nexus'
import store from '~/redux/store'
import { updateUser } from '~/redux/actions/actions'

export default class UserFctGraphUIConfig {

  static async setUsersFctGraphUIConfig(fctGraph) {
    const { user } = store.getState()

    if (!user.fctGraphUIConfig || user.fctGraphUIConfig.fctGraphId !== fctGraph.id) {
      /* TODO: ADD_ERROR_HANDLING */
      const { data: fctGraphUIConfig } = await nexus.uIConfig.userFctGraphUIConfig
        .get({ userId: user.id, fctGraphId: fctGraph.id })
      const { id, userId, fctGraphId, configs } = fctGraphUIConfig
      const { dashboards, graphs, processBuilder } = configs
      store.dispatch(updateUser({
        fctGraphUIConfig: {
          id,
          userId,
          fctGraphId,
          configs: {
            dashboards,
            graphs,
            processBuilder,
          },
        },
      }))
    }
  }

  static async update(fctGraphId, configs) {
    /* optimistic approach for snappy behaviour */
    const { user } = store.getState()
    store.dispatch(updateUser({ fctGraphUIConfig: { configs, fctGraphId, userId: user.id } }))
    /* TODO: ADD_ERROR_HANDLING */
    await nexus.uIConfig.userFctGraphUIConfig.put(user.id, fctGraphId, configs)
  }

  static toggleFctFold(fcts, fctId) {
    return fcts.map(fct => {
      if (fct.fctId === fctId) {
        return { ...fct, folded: !fct.folded }
      }

      return fct
    })
  }

  static foldAll(fcts) {
    return fcts
      .map(fct => ({
        ...fct,
        folded: true,
        slots: fct.slots.map(slot => ({ ...slot, folded: true })),
      }))
  }

  static expandAll(fcts) {
    return fcts
      .map(fct => ({
        ...fct,
        folded: false,
        slots: fct.slots.map(slot => ({ ...slot, folded: false })),
      }))
  }

  static getProcessBuilderFunctionalities() {
    const { user } = store.getState()
    const { fctGraphUIConfig } = user
    const { configs } = fctGraphUIConfig
    const { processBuilder } = configs
    const { functionalities } = processBuilder

    return functionalities
  }

  static getGraphsFunctionalities() {
    const { user } = store.getState()
    const { fctGraphUIConfig } = user
    const { configs } = fctGraphUIConfig
    const { graphs } = configs
    const { functionalities } = graphs

    return functionalities
  }

  static updateProcessBuilderFunctionalities(fcts) {
    const { user } = store.getState()
    const { fctGraphUIConfig } = user
    const { configs, fctGraphId } = fctGraphUIConfig
    const { processBuilder } = configs

    const newConfigs = {
      ...configs,
      processBuilder: {
        ...processBuilder,
        functionalities: fcts,
      },
    }
    UserFctGraphUIConfig.update(fctGraphId, newConfigs)
  }

  static updateGraphsFunctionalities(fcts) {
    const { user } = store.getState()
    const { fctGraphUIConfig } = user
    const { configs, fctGraphId } = fctGraphUIConfig
    const { graphs } = configs

    const newConfigs = {
      ...configs,
      graphs: {
        ...graphs,
        functionalities: fcts,
      },
    }
    UserFctGraphUIConfig.update(fctGraphId, newConfigs)
  }

  static toggleAllBuilderFcts(fold) {
    const functionalities = UserFctGraphUIConfig.getProcessBuilderFunctionalities()
    const newFunctionalities = fold
      ? UserFctGraphUIConfig.foldAll(functionalities)
      : UserFctGraphUIConfig.expandAll(functionalities)
    UserFctGraphUIConfig.updateProcessBuilderFunctionalities(newFunctionalities)
  }

  static toggleBuilderFctFold(fctId) {
    const functionalities = UserFctGraphUIConfig.getProcessBuilderFunctionalities()
    const newFunctionalities = UserFctGraphUIConfig.toggleFctFold(functionalities, fctId)
    UserFctGraphUIConfig.updateProcessBuilderFunctionalities(newFunctionalities)
  }

  static toggleAllGraphsFcts(fold) {
    const functionalities = UserFctGraphUIConfig.getGraphsFunctionalities()
    const newFunctionalities = fold
      ? UserFctGraphUIConfig.foldAll(functionalities)
      : UserFctGraphUIConfig.expandAll(functionalities)
    UserFctGraphUIConfig.updateGraphsFunctionalities(newFunctionalities)
  }

  static toggleGraphFctFold(fctId) {
    const functionalities = UserFctGraphUIConfig.getGraphsFunctionalities()
    const newFunctionalities = UserFctGraphUIConfig.toggleFctFold(functionalities, fctId)
    UserFctGraphUIConfig.updateGraphsFunctionalities(newFunctionalities)
  }

  static toggleGraphFctSlotFold(fctId, slotName) {
    const functionalities = UserFctGraphUIConfig.getGraphsFunctionalities()
    const newFunctionalities = UserFctGraphUIConfig.toggleSlotFold(functionalities, fctId, slotName)
    UserFctGraphUIConfig.updateGraphsFunctionalities(newFunctionalities)
  }

  static toggleSlotFold(fcts, fctId, slotName) {
    return fcts.map(fct => {
      if (fct.fctId === fctId) {
        const updatedSlots = fct.slots.map(aSlot => {
          if (aSlot.name === slotName) {
            return { ...aSlot, folded: !aSlot.folded }
          }
          return aSlot
        })
        return { ...fct, slots: updatedSlots }
      }
      return fct
    })
  }

  static getFctBuilderPosition(fctId) {
    const { user } = store.getState()
    const { fctGraphUIConfig: { configs } } = user
    const { processBuilder } = configs
    return processBuilder.functionalities.findIndex(entry => entry.fctId === fctId)
  }

  static getFctGraphsPosition(fctId) {
    const { user } = store.getState()
    const { fctGraphUIConfig: { configs } } = user
    const { graphs } = configs
    return graphs.functionalities.findIndex(entry => entry.fctId === fctId)
  }

  static getFctSlotGraphsPosition(fctId, slotName) {
    const { user } = store.getState()
    const { fctGraphUIConfig: { configs } } = user
    const { graphs } = configs
    const fct = graphs.functionalities.find(entry => entry.fctId === fctId)
    return fct.slots.findIndex(slot => slot.name === slotName)
  }

  static reorderFcts(fcts, srcIndex, destIndex) {
    const movedFct = fcts.splice(srcIndex, 1)[0]
    fcts.splice(destIndex, 0, movedFct)
    return fcts
  }

  static moveBuilderFct(srcIndex, destIndex) {
    const functionalities = UserFctGraphUIConfig.getProcessBuilderFunctionalities()
    const newFunctionalities = UserFctGraphUIConfig
      .reorderFcts(functionalities, srcIndex, destIndex)
    UserFctGraphUIConfig.updateProcessBuilderFunctionalities(newFunctionalities)
  }

  static moveGraphsFct(srcIndex, destIndex) {
    const functionalities = UserFctGraphUIConfig.getGraphsFunctionalities()
    const newFunctionalities = UserFctGraphUIConfig
      .reorderFcts(functionalities, srcIndex, destIndex)
    UserFctGraphUIConfig.updateGraphsFunctionalities(newFunctionalities)
  }

  static moveGraphFctSlot(fctId, srcIndex, destIndex) {
    const functionalities = UserFctGraphUIConfig.getGraphsFunctionalities()
    const newFunctionalities = UserFctGraphUIConfig
      .reorderSlots(functionalities, fctId, srcIndex, destIndex)
    UserFctGraphUIConfig.updateGraphsFunctionalities(newFunctionalities)
  }

  static reorderSlots(fcts, fctId, srcIndex, destIndex) {
    return fcts.map(fct => {
      if (fct.fctId === fctId) {
        const movedSlot = fct.slots.splice(srcIndex, 1)[0]
        fct.slots.splice(destIndex, 0, movedSlot)
      }
      return fct
    })
  }
}
