import { Functionality, Slot } from '@ospin/fct-graph'
import React from 'react'
import TemplateUtils from '../../Utils/TemplateUtils'

export default function FunctionalitySlotTableRowConnections({
  targetPosId,
  slot,
  template,
}) {

  const isOutSlotTable = slot.type === Slot.TYPES.OUT_SLOT

  const subTypeToFilter = isOutSlotTable
    ? Functionality.FIXED_SUB_TYPES.INTERVAL_OUT
    : Functionality.FIXED_SUB_TYPES.PUSH_IN

  const { connections } = isOutSlotTable
    ? TemplateUtils.getOutgoing(template, { posId: targetPosId, slotName: slot.name })
    : TemplateUtils.getIncomming(template, { posId: targetPosId, slotName: slot.name })

  const displayableConnections = connections.map(con => {
    const direction = isOutSlotTable ? 'to' : 'from'

    const otherFct = TemplateUtils.getFctByPosId(template, { posId: con[direction].posId })
    return {
      name: otherFct.props.name,
      subType: otherFct.subType,
      slotName: con[direction].slotName,
    }
  }).filter(({ subType }) => subType !== subTypeToFilter)

  if (!isOutSlotTable && displayableConnections.length === 0) {
    return <> Manual Input</>
  }

  return (
    <div>
      {displayableConnections.map(({ name, slotName }) => (
        <p>
          {name}
          {' '}
          :
          {' '}
          {slotName}
        </p>
      ))}
    </div>
  )
}
