import React from 'react'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import { Button, Icon, Table, TableCell, Form } from 'semantic-ui-react'
import GraphTools from '~/utils/GraphTools'

const LineChartOptionRow = ({
  dataSources,
  fctGraph,
  removeDataSource,
  updateDataSource,
  allowDeletingLastDataSource = false,
}) => {
  if (dataSources.length === 0) return null

  const getDefaultColor = idx => (
    GraphTools.getColorByIndex(idx)
  )

  return (
    <>
    <b>Datasets in your chart</b>
    <Table celled className='ospin-red'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Functionality</Table.HeaderCell>
          <Table.HeaderCell>Slot</Table.HeaderCell>
          <Table.HeaderCell>Line Color</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { dataSources.map((dataSource, idx) => {
          const selectedFct = FCTGraph.getFctById(fctGraph, dataSource.fctId)
          const slot = Functionality.getSlotByName(selectedFct, dataSource.slotName)
          return (
            <Table.Row key={`${dataSource.fctId}-${dataSource.slotName}`}>
              <Table.Cell>{selectedFct.name}</Table.Cell>
              <Table.Cell>{Slot.getDisplayName(slot)}</Table.Cell>
              <TableCell>
                <Form.Field>
                  <input
                    id='lineColorLineChartOptionRow'
                    name='lineColorLineChartOptionRow'
                    type='color'
                    onInput={({ target: { value } }) => updateDataSource(idx, value)}
                    value={dataSource.lineColor || getDefaultColor(idx)}
                  />
                </Form.Field>
              </TableCell>
              <Table.Cell>
                <Button
                  disabled={!allowDeletingLastDataSource && dataSources.length === 1}
                  primary
                  icon
                  size='small'
                  onClick={() => removeDataSource(idx)}
                >
                  <Icon name='times' />
                </Button>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    </>
  )
}

export default LineChartOptionRow
