class PhaseProgression {

  static hasStarted(process) {
    return !!process.progression?.length > 0
  }

  static getLatest(process) {
    return process.progression[process.progression.length - 1]
  }

  static isLatestPhase(process, phaseId) {
    if (!PhaseProgression.contains(process, phaseId)) return false
    const { phaseId: latestPhaseId } = PhaseProgression.getLatest(process)

    return phaseId === latestPhaseId
  }

  static contains(process, phaseId) {
    return process.progression.some(entry => entry.phaseId === phaseId)
  }

  static phaseHasStarted(process, phaseId) {
    return PhaseProgression.contains(process, phaseId)
  }

  static getPhaseById(process, phaseId) {
    // NOTE: this shares a name with DescriptionParser.getPhaseById
    return process.progression.find(aPhase => aPhase.phaseId === phaseId)
  }

  static getPhaseProgressionIndexById(process, phaseId) {
    return process.progression.findIndex(entry => entry.phaseId === phaseId)
  }

  static getFollowingPhaseProgression(process, phaseId) {
    const phaseIdx = PhaseProgression.getPhaseProgressionIndexById(process, phaseId)
    return process.progression[phaseIdx + 1] || null
  }

  static getPreviousPhaseProgression(process, phaseId) {
    const phaseIdx = PhaseProgression.getPhaseProgressionIndexById(process, phaseId)
    if (phaseIdx < 1 ) { return null }
    return process.progression[phaseIdx - 1]
  }

  static getSecondsSinceProcessStart(process, phaseId) {
    const processStart = process.progression[0].startTime
    const phase = PhaseProgression.getPhaseById(process, phaseId)

    if (phase) {
      const phaseStart = phase.startTime
      return (phaseStart - processStart) / 1000
    }
    return Infinity
  }

  static getMSSincePhaseStart(process, phaseId) {
    const phase = PhaseProgression.getPhaseById(process, phaseId)

    return phase ? Math.round(((Date.now() - phase.startTime))) : 0
  }

  static getSecondsSincePhaseStart(process, phaseId) {
    return PhaseProgression.getMSSincePhaseStart(process, phaseId) / 1000
  }

  static noPhaseObject() {
    return { phaseId: -1, startTime: -1 }
  }

}

export default PhaseProgression
