import { Functionality } from '@ospin/fct-graph'

/**
 * Template Utils is a collection of methods to manipulate templates,
 * they should all be agnostic to device,functionalities etc.
 *
 *
 *
 * @class TemplateUtils
 */
class TemplateUtils {

  /////////////////////////////////////////////
  /// GLOBAL FUNCTIONS
  /////////////////////////////////////////////

  static generateEmpty(deviceId) {
    return {
      name: 'New Template',
      imageUrl: null,
      functionalities: [],
      connections: [],
      deviceId,
    }
  }

  static mutateTemplate(template, mutators) {
    let updatedTemplate = { ...template }
    mutators.forEach((fn, idx) => {
      if (idx === 0) {
        updatedTemplate = fn(template)
      } else {
        updatedTemplate = fn(updatedTemplate)
      }
    })

    return updatedTemplate
  }

  static update(template, update) {
    return { ...template, ...update }
  }

  static isLicenceTemplate(template) {
    return !template.deviceId
  }

  static isExistingTemplate(template) {
    return !!template.id
  }

  /////////////////////////////////////////////
  /// SAFE CRUD OPERATIONS
  /////////////////////////////////////////////

  static addFunctionalityWithPushInAndReporterFcts(template, fct) {
    const inSlots = Functionality.getInSlots(fct)
    const outSlots = Functionality.getOutSlots(fct)
    return TemplateUtils.mutateTemplate(
      template,
      [
        tempTpl => TemplateUtils.addFunctionality(tempTpl, fct),
        ...inSlots.map(({ name }) => (
          tempTpl => TemplateUtils.addPushInFctForSlot(
            tempTpl, { posId: fct.posId, slotName: name },
          )
        )),
        ...outSlots.map(({ name }) => (
          tempTpl => TemplateUtils.addReporterFctForSlot(
            tempTpl, { posId: fct.posId, slotName: name },
          )
        )),
      ],
    )
  }

  static addFunctionality(template, fct) {
    return {
      ...template,
      functionalities: [
        ...template.functionalities,
        TemplateUtils.generateTemplateFct(fct)],
    }
  }

  static updateFunctionality(template, fct) {
    return {
      ...template,
      functionalities: template.functionalities.map(
        templateFct => (templateFct.posId === fct.posId ? fct : templateFct),
      ),
    }
  }

  static renameFunctionality(template, { posId, name }) {
    const originalfct = TemplateUtils.getFctByPosId(template, { posId })
    const updatedFunctionality = {
      ...originalfct,
      props: {
        ...originalfct.props,
        name,
      },
    }

    return this.updateFunctionality(template, updatedFunctionality)
  }

  static setSlotDisplayName(template, { posId, slotName, displayName }) {
    const functionality = TemplateUtils.getFctByPosId(template, { posId })
    const generateSlotArray = () => {
      if (!functionality.props.slots) {
        return [{ name: slotName, displayName }]
      }

      if (!functionality.props.slots.find(({ name }) => name === slotName)) {
        return [...functionality.props.slots, { name: slotName, displayName }]
      }

      return functionality.props.slots.map(
        templateSlot => (templateSlot.name === slotName
          ? { name: slotName, displayName }
          : templateSlot
        ),
      )
    }

    const updatedFunctionality = {
      ...functionality,
      props: {
        ...functionality.props,
        slots: generateSlotArray(),
      },
    }

    return TemplateUtils.updateFunctionality(template, updatedFunctionality)
  }

  static removeSlotDisplayName(template, { posId, slotName }) {
    const functionality = TemplateUtils.getFctByPosId(template, { posId })

    const updatedFunctionality = {
      ...functionality,
      props: {
        ...functionality.props,
        slots: functionality.props.slots.flatMap(slot => (slot.name === slotName ? [] : [slot])),
      },
    }

    return TemplateUtils.updateFunctionality(template, updatedFunctionality)

  }

  static addConnectionAndReplacePushInAndReporterFct(template, { from, to }) {
    const operations = [tempTpl => TemplateUtils.addConnection(tempTpl, { from, to })]

    const reporterFct = TemplateUtils.getReporterNode(
      template,
      { posId: from.posId, slotName: from.slotName },
    )

    const pushIn = TemplateUtils.getPushInNode(template,
      { posId: to.posId, slotName: to.slotName },
    )

    if (reporterFct) {
      operations.push(
        tempTpl => TemplateUtils.removeReporterFctForSlot(tempTpl, from)
      )
    }

    if (pushIn) {
      operations.push(
        tempTpl => TemplateUtils.removePushInFctForSlot(tempTpl, to)
      )
    }

    return TemplateUtils.mutateTemplate(template, operations)
  }

  static removeFunctionalityWithConnectedPushInAndReporter(template, { posId }) {
    const subtractions = [tempTpl => TemplateUtils.removeFunctionality(tempTpl, { posId })]
    const additions = []
    const { connections: incommingConnections } = TemplateUtils.getAllIncomming(template, { posId })
    const { connections: outGoingConnections } = TemplateUtils.getAllOutgoing(template, { posId })

    incommingConnections.forEach(({ to, from }) => {
      const fct = TemplateUtils.getFctByPosId(template, { posId: from.posId })

      if (fct.subType === Functionality.FIXED_SUB_TYPES.PUSH_IN) {
        subtractions.push(
          tempTpl => TemplateUtils.removeFunctionality(tempTpl, { posId: fct.posId }),
          tempTpl => TemplateUtils.removeConnection(tempTpl, { from, to }),
        )
      } else {
        subtractions.push(
          tempTpl => TemplateUtils.removeConnection(tempTpl, { from, to }),
        )
        additions.push(
          tempTpl => TemplateUtils.addReporterFctForSlot(tempTpl, from),
        )
      }
    })

    outGoingConnections.forEach(({ to, from }) => {
      const fct = TemplateUtils.getFctByPosId(template, { posId: to.posId })

      if (fct.subType === Functionality.FIXED_SUB_TYPES.INTERVAL_OUT) {
        subtractions.push(
          tempTpl => TemplateUtils.removeFunctionality(tempTpl, { posId: fct.posId }),
          tempTpl => TemplateUtils.removeConnection(tempTpl, { from, to }),
        )
      } else {
        subtractions.push(
          tempTpl => TemplateUtils.removeConnection(tempTpl, { from, to }),
        )
        additions.push(
          tempTpl => TemplateUtils.addPushInFctForSlot(tempTpl, to),
        )
      }
    })

    return TemplateUtils.mutateTemplate(
      template,
      [
        ...subtractions,
        ...additions,
      ],
    )
  }

  static removeConnectionAndReplaceWithPushInAndReporter(template, { from, to }) {
    return TemplateUtils.mutateTemplate(
      template,
      [
        tempTpl => TemplateUtils.removeConnection(tempTpl, { from, to }),
        tempTpl => TemplateUtils.addReporterFctForSlot(tempTpl, from),
        tempTpl => TemplateUtils.addPushInFctForSlot(tempTpl, to),
      ],
    )

  }

  static addConnection(template, { from, to }) {
    return TemplateUtils.update(
      template, {
        connections: [...template.connections, { from, to }],
      })
  }

  static removeConnection(template, { from, to }) {
    return {
      ...template,
      connections: template.connections.filter(con => !(
        con.from.posId === from.posId
          && con.from.slotName === from.slotName
          && con.to.posId === to.posId
          && con.to.slotName === to.slotName),
      ),
    }
  }

  static addPushInFctForSlot(template, { posId, slotName }) {
    const {
      connections: newConnections,
      functionalities: newFunctionalities,
    } = TemplateUtils.generatePushInFctWithConnectionForSlot(template, { posId, slotName })

    return TemplateUtils.update(
      template,
      {
        connections: [...template.connections, ...newConnections],
        functionalities: [...template.functionalities, ...newFunctionalities],
      },
    )
  }

  static addReporterFctForSlot(template, { posId, slotName }) {
    const {
      connections: newConnections,
      functionalities: newFunctionalities,
    } = TemplateUtils.generateReporterFctWithConnectionForSlot(template, { posId, slotName })

    return TemplateUtils.update(
      template,
      {
        connections: [...template.connections, ...newConnections],
        functionalities: [...template.functionalities, ...newFunctionalities],
      },
    )
  }

  static removePushInFctForSlot(template, { posId, slotName }) {
    const pushIn = TemplateUtils.getPushInNode(template, { posId, slotName })
    return TemplateUtils.mutateTemplate(template, [
      tempTpl => TemplateUtils.removeConnection(
        tempTpl, {
          from: { posId: pushIn.posId, slotName: Functionality.INPUT_NODE_SLOT_NAME },
          to: { posId, slotName },
        }),
      tempTpl => TemplateUtils.removeFunctionality(tempTpl, { posId: pushIn.posId }),
    ])
  }

  static removeReporterFctForSlot(template, { posId, slotName }) {
    const reporterNode = TemplateUtils.getReporterNode(template, { posId, slotName })
    return TemplateUtils.mutateTemplate(template, [
      tempTpl => TemplateUtils.removeConnection(
        tempTpl,
        {
          from: { posId, slotName },
          to: { posId: reporterNode.posId, slotName: Functionality.OUTPUT_NODE_SLOT_NAME }
        }
      ),
      tempTpl => TemplateUtils.removeFunctionality(tempTpl, { posId: reporterNode.posId })
    ])
  }

  /////////////////////////////////////////////
  /// Unsafe Methods
  ///
  /// Partially Updates the template, to be called from the Crud Methods.
  /// if used Standalone they risk stale data
  /////////////////////////////////////////////

  static removeFunctionality(template, { posId }) {
    const updatedTemplate = { ...template }
    return {
      ...updatedTemplate,
      functionalities: updatedTemplate.functionalities.filter(
        ({ posId: templatePosId }) => templatePosId !== posId,
      ),
    }
  }

  /////////////////////////////////////////////
  /// GENERATORS
  ///
  /// Generators only format and create data but do not update a template
  /////////////////////////////////////////////

  static generateTemplateFct(fct) {
    const { type, subType, posId, isVirtual, name } = fct
    const fctName = name || subType

    return {
      type,
      subType,
      posId,
      props: { isVirtual, name: fctName },
    }
  }

  static generatePushInFctWithConnectionForSlot(template, { posId, slotName }) {
    const pushInPosId = TemplateUtils.getNextPosId(template)
    return {
      functionalities: [{
        type: Functionality.FIXED_TYPES.INPUT_NODE,
        subType: Functionality.FIXED_SUB_TYPES.PUSH_IN,
        posId: pushInPosId,
        props: {
          isVirtual: true,
          name: `${Functionality.FIXED_SUB_TYPES.PUSH_IN} ${slotName}`,
        },
      }],
      connections: [{
        from: { posId: pushInPosId, slotName: Functionality.INPUT_NODE_SLOT_NAME },
        to: { posId, slotName },
      }],
    }
  }

  static generateReporterFctWithConnectionForSlot(template, { posId, slotName }) {
    const reporterPosId = TemplateUtils.getNextPosId(template)

    return {
      functionalities: [{
        type: Functionality.FIXED_TYPES.OUTPUT_NODE,
        subType: Functionality.FIXED_SUB_TYPES.INTERVAL_OUT,
        posId: reporterPosId,
        props: {
          isVirtual: true,
          name: `${Functionality.FIXED_SUB_TYPES.INTERVAL_OUT} ${slotName}`,
        },
      }],
      connections: [{
        from: { posId, slotName },
        to: { posId: reporterPosId, slotName: Functionality.OUTPUT_NODE_SLOT_NAME },
      }],
    }
  }

  /////////////////////////////////////////////
  /// ASSERTIONS AND QUERIES
  /////////////////////////////////////////////

  static getFctByPosId(template, { posId: lookUpPosId }) {
    const { functionalities } = template

    return functionalities.find(({ posId }) => posId === lookUpPosId)
  }

  static getFctsByPosIds(template, lookUpPosIds) {
    const { functionalities } = template

    return functionalities.filter(({ posId }) => lookUpPosIds.includes(posId))
  }

  static getNextPosId(template) {
    const { functionalities } = template
    let nextPosId = 0
    // eslint-disable-next-line no-loop-func
    while (functionalities.some(({ posId }) => nextPosId === posId)) {
      nextPosId += 1
    }
    return nextPosId
  }

  static getSlot(template, { posId, slotName }) {
    const templateFct = TemplateUtils.getFctByPosId(template, { posId })

    const { slots } = templateFct.props
    if (!slots) {
      return null
    }

    return slots.find(slot => slot.name === slotName) || null
  }

  static getSlotDisplayName(template, { posId, slotName }) {
    const slot = TemplateUtils.getSlot(template, { posId, slotName })
    if (!slot || slot.displayName === undefined) {
      return null
    }
    return slot.displayName
  }

  static getAllIncomming(template, { posId }) {
    const { connections } = template

    const incommingConnections = connections.filter(
      con => con.to.posId === posId,
    )
    const incommingFctsIds = incommingConnections.map(con => con.from.posId)

    return {
      connections: incommingConnections,
      functionalities: TemplateUtils.getFctsByPosIds(template, incommingFctsIds)
    }
  }

  static getAllOutgoing(template, { posId }) {
    const { connections } = template

    const outgoingConnections = connections.filter(
      con => con.from.posId === posId,
    )
    const outGoingFctsIds = outgoingConnections.map(con => con.to.posId)

    return {
      connections: outgoingConnections,
      functionalities: TemplateUtils.getFctsByPosIds(template, outGoingFctsIds)
    }
  }

  static getIncomming(template, { posId, slotName }) {
    const { connections } = template
    const incommingConnections = connections.filter(
      con => con.to.posId === posId && con.to.slotName === slotName,
    )
    const incommingFctsIds = incommingConnections.map(con => con.from.posId)

    return {
      connections: incommingConnections,
      functionalities: TemplateUtils.getFctsByPosIds(template, incommingFctsIds)
    }
  }

  static getOutgoing(template, { posId, slotName }) {
    const { connections } = template
    const outGoingConnections = connections.filter(
      con => con.from.posId === posId && con.from.slotName === slotName,
    )
    const outGoingFctIds = outGoingConnections.map(con => con.to.posId)

    return {
      connections: outGoingConnections,
      functionalities: TemplateUtils.getFctsByPosIds(template, outGoingFctIds),
    }
  }

  static getReporterNode(template, { posId, slotName }) {
    const { functionalities } = TemplateUtils.getOutgoing(template, { posId, slotName })

    const reporterFct = functionalities.find(
      targetFct => targetFct.subType === Functionality.FIXED_SUB_TYPES.INTERVAL_OUT,
    )

    return reporterFct || null
  }

  static getPushInNode(template, { posId, slotName }) {
    const { functionalities } = TemplateUtils.getIncomming(template, { posId, slotName })

    const pushInFct = functionalities.find(fct => fct.subType === Functionality.FIXED_SUB_TYPES.PUSH_IN)

    return pushInFct || null
  }

  static isConnectedToOtherFct(template, target) {
    const { slotName, posId } = target
    const { connections } = template
    const outGoingConnections = connections.filter(
      con => con.to.posId === posId && con.to.slotName === slotName
    )

    const outGoingConnectionPosId = outGoingConnections.map(({ from }) => from.posId)
    return outGoingConnectionPosId.some(outPosId => {
      const fct = TemplateUtils.getFctByPosId(template, { posId: outPosId })
      return fct.subType !== Functionality.FIXED_SUB_TYPES.PUSH_IN
    })
  }
}

export default TemplateUtils
