import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { addUserToGroup } from '~/utils/access'

export default (state, action) => {
  const { processes } = state
  const { processId, groupName, userId } = action.payload

  const updateFn = process => addUserToGroup(process, groupName, userId)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
