import { ACTIONS } from '~/redux/actions/actions'
import setDisplayedPhase from '~/redux/reducers/ui/setDisplayedPhase'
import loadProcessViewer from '~/redux/reducers/ui/loadProcessViewer'
import addFlashMessage from '~/redux/reducers/ui/addFlashMessage'
import deleteFlashMessage from '~/redux/reducers/ui/deleteFlashMessage'

export default {
  [ACTIONS.SET_DISPLAYED_PROCESS_PHASE]: setDisplayedPhase,
  [ACTIONS.LOAD_PROCESSVIEWER]: loadProcessViewer,
  [ACTIONS.ADD_FLASHMESSAGE]: addFlashMessage,
  [ACTIONS.DELETE_FLASHMESSAGE]: deleteFlashMessage,
}
