import React, { useState } from 'react'
import { connect } from 'react-redux'
import nexus from '@ospin/nexus'
import modalize from '~/components/utility/modal/modalize'
import callLogout from '~/redux/thunks/auth/callLogout'
import DeleteUserConfirmModalForm from './DeleteUserConfirmModalForm'

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = dispatch => ({
  dispatchLogout: () => callLogout(dispatch, { global: true }),
})

const DeleteUserConfirmModal = props => {
  const { user: { id: userId, userName }, dispatchLogout, closeHandler } = props

  const [usernameFromInput, setUsernameFromInput] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmitDeletionRequest = async () => {
    try {
      setLoading(true)
      await nexus.user.createDeletionRequest(userId)
      setIsSuccess(true)
      setLoading(false)
      dispatchLogout()
    } catch (e) {
      setIsError(true)
      setErrorMessage(e.message)
      setLoading(false)
    }
  }

  const onHandleUsername = usernameFromInputValue => {
    setUsernameFromInput(usernameFromInputValue)
  }

  const isMatchingUsername = () => usernameFromInput === userName

  return (
    <DeleteUserConfirmModalForm
      submitHandler={onSubmitDeletionRequest}
      closeOnSubmit
      closeHandler={closeHandler}
      submitText='Confirm and Logout'
      submitUsername={onHandleUsername}
      loading={loading}
      disableSubmit={!isMatchingUsername() || loading}
      usernameFromInput={usernameFromInput}
      setUsernameFromInput={usernameFromInput}
    />
  )
}

export default modalize(connect(mapStateToProps, mapDispatchToProps)(DeleteUserConfirmModal))
