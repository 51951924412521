import FlashMessenger from '~/utils/FlashMessenger'
import Device from '~/utils/Device'
import store from '~/redux/store'
import { addUserAlert, removeUserAlertById } from '~/redux/actions/actions'
import Validator from '~/utils/validation/Validator'



function handleRevokedAlert(revokedId) {
  store.dispatch(removeUserAlertById({ alertId: revokedId }))
}

function getMessagePrefix(deviceId) {
  const { devices } = store.getState()

  const deviceName = devices && deviceId
    ? Device.getById(devices, deviceId)?.name
    : undefined

  return deviceName ? `${deviceName}: ` : ''
}

function handleNewAlert(value) {
  const { eventType, displayMsg, deviceId } = value

  if (Validator.isUndefinedOrNull(eventType)) {
    console.warn('recived event missing eventType')
    return
  }

  if (Validator.isUndefinedOrNull(displayMsg)) {
    console.warn('received event missing displayMsg')
    return
  }

  const EVENT_TYPE_TO_FLASH_TYPE = {
    notification: FlashMessenger.info,
    warning: FlashMessenger.warning,
    alarm: FlashMessenger.alarm,
  }

  const flashMsg = EVENT_TYPE_TO_FLASH_TYPE[eventType]

  const messagePrefix = getMessagePrefix(deviceId)
  flashMsg(`${messagePrefix}${displayMsg}`)
  store.dispatch(addUserAlert(value))
}

export default event => {
  const { revokedId } = event

  return revokedId
    ? handleRevokedAlert(revokedId)
    : handleNewAlert(event)
}
