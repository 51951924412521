import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import SensorCalibration from '~/utils/sensors/SensorCalibration'
import CalibrationMode from '~/utils/calibration/CalibrationMode'

const mapStateToProps = ({ user }) => ({ user })

const Footer = ({
  deleteCalibrationEntry,
  resetInputFields,
  activeDevice,
  calibrating,
  resetting,
  closeHandler,
  user,
  existingCalibration,
  type,
  userCanApplyChanges,
}) => (
  <div>
    <Button
      floated='left'
      color='red'
      loading={resetting}
      disabled={!existingCalibration
         || !SensorCalibration.userCanDelete(user, activeDevice, existingCalibration)
         || resetting}
      onClick={e => deleteCalibrationEntry(e, existingCalibration)}
    >
      Delete Calibration
    </Button>
    <Button
      floated='left'
      onClick={resetInputFields}
      disabled={!userCanApplyChanges}
    >
      Reset Inputs
    </Button>
    { type !== CalibrationMode.MODES.DIRECT_INPUT && <Button
      floated='right'
      primary
      type='submit'
      loading={calibrating}
      disabled={calibrating || !userCanApplyChanges}
    >
      Apply
    </Button>}
    <Button
      floated='right'
      onClick={closeHandler}
    >
      Close
    </Button>
  </div>
)

export default connect(mapStateToProps)(Footer)
