import React, { useState } from 'react'
import { Table, Button } from 'semantic-ui-react'
import Time from '~/utils/Time'
import { Process } from '~/utils/process'
import LineChartAnnotationModal from '~/components/processviewer/charts/LineChartAnnotationModal'

const AnnotationsOverviewTableRow = ({
  annotation,
  activeProcess,
}) => {
  const [ annotationModalProps, setAnnotationModalProps ] = useState({ mode: null })

  const totalDuration = Process.getElapsedProcessTimeInMs(activeProcess)
  const derivedTimeDisplayFormat = Time.deriveTimeDisplayFormat(totalDuration)
  const relativeTimeStampInS = (annotation.userTimestamp - activeProcess.startedAt) / 1000

  return (
    <Table.Row key={annotation.id}>
      <Table.Cell>
        {Time.processTimeAxisLabelFormat(relativeTimeStampInS, derivedTimeDisplayFormat)}
      </Table.Cell>
      <Table.Cell>
        {Time.getTrimmedDateAndTimeString(annotation.userTimestamp)}
      </Table.Cell>
      <Table.Cell>
        {annotation.text}
      </Table.Cell>
      <Table.Cell>
        <Button
          primary
          icon='edit'
          size='small'
          onClick={() => setAnnotationModalProps({ mode: 'edit', annotationId: annotation.id })}
        />
        { annotationModalProps.mode && (
          <LineChartAnnotationModal
            activeProcess={activeProcess}
            mode={annotationModalProps.mode}
            annotationId={annotation.id}
            setAnnotationModalProps={setAnnotationModalProps}
            timeStampsMode='processTime'
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default AnnotationsOverviewTableRow
