import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'
import NoDataMessage from '~/components/utility/NoDataMessage'
import DeviceViewLoader from '~/components/utility/DeviceViewLoader'
import callLoadDownloadRequests from '~/redux/thunks/process/callLoadDownloadRequests'
import DownloadRequestCard from './DownloadRequestCard'

const mapStateToProps = ({ downloadRequests }) => ({ requests: downloadRequests })

const mapDispatchToProps = dispatch => ({ dispatchCallLoadDownloadRequests: processId => callLoadDownloadRequests(dispatch, processId) })

const renderRequest = (request, fctGraph) => (
  <DownloadRequestCard
    request={request}
    fctGraph={fctGraph}
    key={request.id}
  />
)

const ProcessDownloadRequests = ({
  activeProcess,
  requests,
  dispatchCallLoadDownloadRequests,
}) => {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadDownloadRequests = async () => {
      setLoading(true)
      /* TODO: ADD_ERROR_HANDLING */
      await dispatchCallLoadDownloadRequests(activeProcess.id)
      setLoading(false)
    }
    loadDownloadRequests()
  }, [])

  if (loading) return <DeviceViewLoader />

  if (requests.length === 0) {
    return <NoDataMessage text='No download requests found' />
  }

  return (
    <Card.Group>
      {requests
        .sort((a, b) => b.createdAt - a.createdAt)
        .map(aRequest => renderRequest(aRequest, activeProcess.fctGraph)) }
    </Card.Group>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessDownloadRequests)
