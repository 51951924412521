import React from 'react'
import { FCTGraph, Slot } from '@ospin/fct-graph'
import { Card, Button } from 'semantic-ui-react'
import Time from '~/utils/Time'
import DownloadRequest from '~/utils/process/DownloadRequest'

const getColorClass = state => {
  switch (state) {
    case 'ready':
      return 'status-green'
    case 'processing':
      return 'status-blue'
    case 'failed':
      return 'status-error'
    default:
      return 'status-blue'
  }
}

const getBorderColorClass = state => {
  switch (state) {
    case 'ready':
      return 'border-ospin-green'
    case 'processing':
      return 'border-ospin-blue'
    case 'failed':
      return 'border-ospin-error'
    default:
      return 'border-ospin-blue'
  }
}

const renderState = state => (
  <span className='float-right'>
    state:
    <span className={getColorClass(state)}>
      &nbsp;
      {state}
    </span>
  </span>
)

const downloadData = url => {
  const a = document.createElement('a')
  a.href = url
  a.setAttribute('download', 'download')
  a.click()
}

const renderDeletionDate = (createdAt, state) => {
  const availableUntil = createdAt + 60 * 60 * 24 * 1000
  const date = state === 'failed'
    ? 'N/A'
    : Time.getTrimmedDateAndTimeString(availableUntil)

  return (
    <span>
      available until
      {' '}
      {date}
    </span>
  )
}

const getDisplayedTitle = (request, fctGraph) => {
  const { reporterFctIds } = request
  if (DownloadRequest.isPDFReportRequest(request)) return 'PDF Process Report'

  const firstSensorName = Slot.getDisplayName(FCTGraph
    .getConnectingSourceSlot(fctGraph, reporterFctIds[0]))
  const firstFctName = FCTGraph
    .getSourceFct(fctGraph, reporterFctIds[0]).name

  const prefix = `${firstFctName}: ${firstSensorName}`

  if (reporterFctIds.length === 1) return prefix

  return `${prefix} and ${reporterFctIds.length - 1} more`
}

const DownloadRequestCard = ({ request, fctGraph }) => {
  const { createdAt, createdBy, state, downloadURL } = request
  const displayTitle = getDisplayedTitle(request, fctGraph)

  const isProcessing = state === 'processing'

  return (
    <Card fluid className={getBorderColorClass(state)}>
      <Card.Content>
        <Card.Header style={{ marginBottom: '4px' }}>
          {displayTitle}
        </Card.Header>
        <Button
          primary
          icon='download'
          style={{ position: 'absolute', right: '10px', top: '12px' }}
          loading={isProcessing}
          disabled={isProcessing || state === 'failed'}
          onClick={() => downloadData(downloadURL)}
        />
        <Card.Meta>
          {renderDeletionDate(createdAt, state)}
        </Card.Meta>
        <Card.Meta>
          <span>
            requested by
            {' '}
            {createdBy}
          </span>
          {renderState(state)}
        </Card.Meta>
      </Card.Content>
    </Card>
  )
}

export default DownloadRequestCard
