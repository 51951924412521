import React from 'react'
import { FCTGraph, Slot } from '@ospin/fct-graph'
import { Table } from 'semantic-ui-react'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import Validator from '~/utils/validation/Validator'
import Time from '~/utils/Time'

const getPhaseNameWithGroupAndCycle = (process, phaseId) => {
  const phase = DescriptionParser.getPhaseById(process, parseInt(phaseId, 10))
  const { groupName, iterationOf, name } = phase

  if (!groupName) return name

  if (Validator.isUndefinedOrNull(iterationOf)) {
    return `${groupName}: ${name} (cycle 1)`
  }

  const phaseIteration = PhaseGroupParser.getPhaseIterationCount(process, phaseId)
  return `${groupName}: ${name} (cycle ${phaseIteration})`
}

const getCycles = (process, phaseId, property) => {
  const phase = DescriptionParser.getPhaseById(process, phaseId)

  if (!phase) return '-'

  const { groupName } = phase

  if (!groupName) return '-'

  const phaseIteration = PhaseGroupParser.getPhaseIterationCount(process, phaseId)
  const totalIterations = PhaseGroupParser.getGroupIterationsCount(process, groupName)
  const dontShowCycleRange = phaseIteration === totalIterations || property === 'next'

  if (dontShowCycleRange) {
    return `${phaseIteration}`
  }

  return `${phaseIteration} - ${totalIterations}`
}

const getPhaseNameWithGroupData = (process, phaseId) => {
  const phase = DescriptionParser.getPhaseById(process, phaseId, 10)
  const { groupName, name } = phase

  if (!groupName) return name

  return `${groupName}: ${name}`
}

const renderPhaseCountChangeRow = (change, process, snapshot) => {
  const { phaseId, action, property } = change
  const deleted = action === 'deleted'
  const verb = deleted ? 'deletes' : 'adds'

  const passedProcess = deleted ? snapshot : process
  const displayedPhaseName = getPhaseNameWithGroupData(passedProcess, phaseId)

  return (
    <>
      <Table.Cell>{displayedPhaseName}</Table.Cell>
      <Table.Cell>{getCycles(passedProcess, phaseId, property)}</Table.Cell>
      <Table.Cell>
        {verb}
        {' '}
        phase
      </Table.Cell>
      <Table.Cell disabled>N/A</Table.Cell>
      <Table.Cell disabled>N/A</Table.Cell>
    </>
  )
}

const renderChangedProp = (property, value, process) => {
  if (property === 'next') {
    if (value === -1) return 'end of process'
    return getPhaseNameWithGroupAndCycle(process, value)
  }

  if (property === 'duration') return Time.stringFromDuration(value, true, '-')

  return value
}

const mapActionToReadableText = (action, property) => {
  switch (true) {
    case action === 'edited' && property === 'groupName':
      return 'moves phase to another group'
    case action === 'added' && property === 'groupName':
      return 'adds phase to group'
    case action === 'deleted' && property === 'groupName':
      return 'removes phase from group'
    case action === 'edited' && property === 'next':
      return 'changes the next phase'
    case action === 'edited' && property === 'transition':
      return 'changes the transition method'
    case action === 'edited' && property === 'duration':
      return 'changes the phase duration'
    default:
      return `${action} ${property}`
  }
}

const renderPhasePropChangeRow = (change, process, snapshot) => {
  const {
    property,
    snapshot: oldValue,
    edited: newValue,
    phaseId,
    action,
  } = change

  return (
    <>
      <Table.Cell>{getPhaseNameWithGroupData(process, phaseId)}</Table.Cell>
      <Table.Cell>{getCycles(process, phaseId, property)}</Table.Cell>
      <Table.Cell>{mapActionToReadableText(action, property)}</Table.Cell>
      <Table.Cell>{renderChangedProp(property, oldValue, snapshot)}</Table.Cell>
      <Table.Cell>{renderChangedProp(property, newValue, process)}</Table.Cell>
    </>
  )
}

const renderPhasePropRemoveChangeRow = (change, process, snapshot) => {
  const {
    property,
    action,
    phaseId,
    snapshot: oldValue,
    edited: newValue,
  } = change

  return (
    <>
      <Table.Cell>{getPhaseNameWithGroupData(process, phaseId)}</Table.Cell>
      <Table.Cell>{getCycles(snapshot, phaseId, property)}</Table.Cell>
      <Table.Cell>{mapActionToReadableText(action, property)}</Table.Cell>
      <Table.Cell>{renderChangedProp(property, oldValue, snapshot)}</Table.Cell>
      <Table.Cell>{renderChangedProp(property, newValue, process)}</Table.Cell>
    </>
  )
}

const renderPhasePropAddChangeRow = (change, process) => {
  const {
    property,
    phaseId,
    edited: newValue,
    action,
  } = change

  return (
    <>
      <Table.Cell>{getPhaseNameWithGroupData(process, phaseId)}</Table.Cell>
      <Table.Cell>{getCycles(process, phaseId, property)}</Table.Cell>
      <Table.Cell>{mapActionToReadableText(action, property)}</Table.Cell>
      <Table.Cell disabled>N/A</Table.Cell>
      <Table.Cell>{renderChangedProp(property, newValue, process)}</Table.Cell>
    </>
  )
}

const renderDefaultInSlotRow = (slot, fct, change, process) => {
  const { snapshot, edited, phaseId, property } = change

  return (
    <>
      <Table.Cell>{getPhaseNameWithGroupData(process, phaseId)}</Table.Cell>
      <Table.Cell>{getCycles(process, phaseId, property)}</Table.Cell>
      <Table.Cell>{`changes ${Slot.getDisplayName(slot)} in ${fct.name}`}</Table.Cell>
      <Table.Cell>
        {snapshot}
        {' '}
        {Slot.isUnitless(slot) ? '' : slot.unit}
      </Table.Cell>
      <Table.Cell>
        {edited}
        {' '}
        {Slot.isUnitless(slot) ? '' : slot.unit}
      </Table.Cell>
    </>
  )
}

const renderBooleanInSlotRow = (slot, fct, change, process) => {
  const { phaseId, snapshot, edited, property } = change

  const convertedOldTarget = snapshot ? 'on' : 'off'
  const convertedNewTarget = edited ? 'on' : 'off'

  return (
    <>
      <Table.Cell>{getPhaseNameWithGroupData(process, phaseId)}</Table.Cell>
      <Table.Cell>{getCycles(process, phaseId, property)}</Table.Cell>
      <Table.Cell>{`changes ${Slot.getDisplayName(slot)} in ${fct.name}`}</Table.Cell>
      <Table.Cell>{convertedOldTarget}</Table.Cell>
      <Table.Cell>{convertedNewTarget}</Table.Cell>
    </>
  )
}

const renderInputNodeValueChange = (change, process) => {
  const { fctGraph } = process
  const inputNodeFctId = change.path[2]
  const slot = FCTGraph.getConnectingSinkSlot(fctGraph, inputNodeFctId)
  const fct = FCTGraph.getSinkFct(fctGraph, inputNodeFctId)

  switch (slot.dataType) {
    case 'boolean':
      return renderBooleanInSlotRow(slot, fct, change, process)
    default:
      return renderDefaultInSlotRow(slot, fct, change, process)
  }
}

const ROW_RENDER_DISPATCH = {
  phaseCountChange: renderPhaseCountChangeRow,
  phasePropChange: renderPhasePropChangeRow,
  phasePropAdd: renderPhasePropAddChangeRow,
  phasePropRemoved: renderPhasePropRemoveChangeRow,
  inputNodeValueChange: renderInputNodeValueChange,
  unknownChange: () => null,
}

const renderTableRow = (diffs, activeProcess, snapshot) => (
  diffs.map(change => {
    const isAdd = change.action === 'added'
    const isDelete = change.action === 'deleted'
    const isWarning = !isAdd && !isDelete
    return (
      <Table.Row
        warning={isWarning}
        positive={isAdd}
        negative={isDelete}
        key={`${change.path.join``}`}
      >
        {ROW_RENDER_DISPATCH[change.type](change, activeProcess, snapshot)}
      </Table.Row>
    )
  })
)

const ChangesTableBody = ({ diffs, activeProcess, snapshot }) => (
  <Table.Body>
    {renderTableRow(diffs, activeProcess, snapshot)}
  </Table.Body>
)

export default ChangesTableBody
