import React from 'react'

export default {
  functionalities:
  <p>
    Functionalities are the basic building blocks of a device. They can be physical (e.g
    sensors and actuators), or virtual (e.g. controllers).
  </p>,
  slotDefaults:
  <p>
    Every input that you can provide to the device
    has a value set by default. You can overwrite these default values here.

  </p>,
  calibrations:
  <p>
    Every sensor on the device can be calibrated using the calibration
    menu present on the sensor&apos;s associated graph.
    This section provides an overview on current calibrations.
  </p>,
  dataPrecision:
  <p>
    The precision of selected sensors can be adjusted for the data viewer as well as the csv export
  </p>,
  connectedFunctionalities:
  <p>
    Supported devices will automatically be detected and made available for you to use.
    The detection may take up to one minute
  </p>,
  portAssignment:
  <p>
    View and change the ports that are used to connect the functionalities
  </p>,
  templateBuilder:
  <p>
    View and change the available templates for the device
  </p>,
}
