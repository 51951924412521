import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FCTGraph } from '@ospin/fct-graph'
import { Form, Menu, Accordion, Button, Segment } from 'semantic-ui-react'
import DraggableComponent from '~/components/utility/DraggableComponent'
import { phaseIsModifiable, Process } from '~/utils/process'
import DescriptionParser from '~/utils/process/DescriptionParser'
import NoDataMessage from '~/components/utility/NoDataMessage'
import Authorizer from '~/utils/Authorizer'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'
import FloatInSlot from './InSlots/FloatInSlot'
import IntegerInSlot from './InSlots/IntegerInSlot'
import BooleanInSlot from './InSlots/BooleanInSlot'
import OneOfInSlot from './InSlots/OneOfInSlot'
import AnyInSlot from './InSlots/AnyInSlot'
import ControllerModal from './ControllerModal'
import FctWidgetHeader from '~/components/processviewer/body/FctWidgetHeader'
import Validator from '~/utils/validation/Validator'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })

const Functionality = ({
  activeProcess,
  activeDevice,
  phaseId,
  index,
  toggleFold,
  functionality,
  fctUIConfig: { folded, slots: slotsUIConfigs },
  dispatchSetInputNodeValue,
  dispatchCallUpdateProcess,
  user,
  functionalityDescriptions,
}) => {
  const { name, slots, id: fctId, subType } = functionality
  const { fctGraph } = activeProcess

  const [ showControllerModal, toggleControllerModal ] = useState(false)
  const [isDragDisabled, setDisableDrag ] = useState(true)

  const updateInputNodeValue = async (inputNodeFctId, newTarget) => {
    dispatchSetInputNodeValue(activeProcess.id, phaseId, inputNodeFctId, newTarget)

    if (Process.isExecutable(activeProcess)) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const renderShowControllerButton = () => (
    <Button
      onClick={() => toggleControllerModal(true)}
      icon='setting'
      type='button'
      style={{ position: 'absolute', right: '8px', top: '8px' }}
      content='Controller'
    />
  )

  const renderControllerModal = controllerSlots => (
    <ControllerModal
      open={showControllerModal}
      closeHandler={() => toggleControllerModal(false)}
      controllerSlots={controllerSlots}
      functionality={functionality}
      activeDevice={activeDevice}
      phaseId={phaseId}
      activeProcess={activeProcess}
      renderSlotByDataType={renderSlotByDataType}
    />
  )

  const renderController = controllerSlots => (
    <>
      { renderShowControllerButton() }
      { renderControllerModal(controllerSlots) }
    </>
  )

  const renderBooleanInSlot = ({
    slot,
    inputNodeValue,
    inputNodeFctId,
    disabledInput,
    showDefault,
    defaultValue,
  }) => (
    <BooleanInSlot
      key={inputNodeFctId}
      slot={slot}
      inputNodeValue={inputNodeValue}
      disabledInput={disabledInput}
      updateInputNodeValue={updateInputNodeValue}
      inputNodeFctId={inputNodeFctId}
      showDefault={showDefault}
      defaultValue={defaultValue}
    />
  )

  const renderAnyInSlot = ({
    slot,
    inputNodeValue,
    inputNodeFctId,
    disabledInput,
    showDefault,
    defaultValue,
  }) => (
    <AnyInSlot
      key={inputNodeFctId}
      slot={slot}
      inputNodeValue={inputNodeValue}
      disabledInput={disabledInput}
      updateInputNodeValue={updateInputNodeValue}
      inputNodeFctId={inputNodeFctId}
      showDefault={showDefault}
      defaultValue={defaultValue}
  />
  )

  const renderFloatInSlot = ({
    slot,
    inputNodeValue,
    inputNodeFctId,
    disabledInput,
    showDefault,
    defaultValue,
  }) => (
    <FloatInSlot
      key={inputNodeFctId}
      slot={slot}
      inputNodeValue={inputNodeValue}
      disabledInput={disabledInput}
      updateInputNodeValue={updateInputNodeValue}
      activeDevice={activeDevice}
      inputNodeFctId={inputNodeFctId}
      showDefault={showDefault}
      defaultValue={defaultValue}
    />
  )

  const renderIntegerInSlot = ({
    slot,
    inputNodeValue,
    inputNodeFctId,
    disabledInput,
    showDefault,
    defaultValue,
  }) => (
    <IntegerInSlot
      key={inputNodeFctId}
      slot={slot}
      inputNodeValue={inputNodeValue}
      disabledInput={disabledInput}
      updateInputNodeValue={updateInputNodeValue}
      activeDevice={activeDevice}
      inputNodeFctId={inputNodeFctId}
      showDefault={showDefault}
      defaultValue={defaultValue}
    />
  )

  const renderOneOfInSlot = ({
    slot,
    inputNodeValue,
    inputNodeFctId,
    disabledInput,
    showDefault,
    defaultValue,
  }) => (
    <OneOfInSlot
      key={inputNodeFctId}
      slot={slot}
      inputNodeValue={inputNodeValue}
      disabledInput={disabledInput}
      updateInputNodeValue={updateInputNodeValue}
      inputNodeFctId={inputNodeFctId}
      showDefault={showDefault}
      defaultValue={defaultValue}
    />
  )

  const renderOnlyControllerParametersGroup = controllerSlots => (
    <Segment style={{ minHeight: '82px' }}>
      <Form style={{ height: '52px', paddingTop: '18px' }}>
        <NoDataMessage
          text='This functionality contains only controller parameters'
        />
        { controllerSlots.length > 0 && renderController(controllerSlots)}
      </Form>
    </Segment>
  )

  const filterByShownSlots = filteredSlots => (
    filteredSlots.filter(slot => slotsUIConfigs.some(slotConfig => slotConfig.name === slot.name))
  )

  const getSlotsToBeDisplayedInControllerModal = () => (
    filterByShownSlots(slots.filter(slot => slot.displayType === 'controller parameter'))
  )

  const getSlotsToBeDisplayedWithoutControllerSlots = () => (
    filterByShownSlots(slots.filter(slot => slot.displayType !== 'controller parameter'))
  )

  const renderSlotByDataType = (slot, { showDefault = false } = {}) => {

    const functionalityDescription = FunctionalityDescription
      .getBySubType(functionalityDescriptions, subType)

    const shouldHideInslot = FunctionalityDescription.shouldHideInSlot({
      functionalityDescription,
      fctId,
      slotName: slot.name,
      process: activeProcess,
      phaseId,
    })


    if (shouldHideInslot) return null

    const inputNodeFctId = FCTGraph
      .getInputNodeFctIdForSlot(fctGraph, fctId, slot.name)
    const inputNodeValue = DescriptionParser
      .getInputNodeValue(activeProcess, phaseId, inputNodeFctId)
    const disabledInput = !phaseIsModifiable(activeProcess, phaseId)
      || Authorizer.isResourceViewer(activeProcess, user.id)

    const params = {
      slot,
      inputNodeValue,
      inputNodeFctId,
      disabledInput,
      showDefault,
    }

    if (showDefault) {
      const { fctsConfigs } = activeProcess
      const customDefault = FunctionalityConfiguration
        .getSlotDefaultInputValue(fctsConfigs, functionality.id, slot.name)
      const defaultValue = !Validator.isUndefinedOrNull(customDefault)
        ? customDefault : slot.defaultValue
      params.defaultValue = defaultValue
    }

    switch (slot.dataType) {
      case 'boolean':
        return renderBooleanInSlot(params)
      case 'oneOf':
        return renderOneOfInSlot(params)
      case 'integer':
        return renderIntegerInSlot(params)
      case 'any':
        return renderAnyInSlot(params)
      default:
        return renderFloatInSlot(params)
    }
  }

  const renderInputSlots = () => {
    if (folded) return null

    const controllerSlots = getSlotsToBeDisplayedInControllerModal()
    const standardSlots = getSlotsToBeDisplayedWithoutControllerSlots()

    if (standardSlots.length === 0) {
      return renderOnlyControllerParametersGroup(controllerSlots)
    }

    return (
      <Segment>
        <Form>
          { controllerSlots.length > 0 && renderController(controllerSlots)}
          { standardSlots.map(renderSlotByDataType) }
        </Form>
      </Segment>
    )
  }

  const handleClick = () => { toggleFold(fctId) }

  return (
    <DraggableComponent
      draggableId={functionality.id}
      index={index}
      isDragDisabled={showControllerModal || isDragDisabled}
    >
      <Menu.Item
        key={index}
        className='disable-select'
      >
        <Accordion.Title
          active={!folded}
          onClick={handleClick}
          content={<FctWidgetHeader activeProcess={activeProcess} functionality={functionality} />}
          index={index}
          style={{ fontSize: '18px', backgroundColor: '#f5f5f5', display: 'flex' }}
          onMouseOver={() => setDisableDrag(false)}
          onFocus={() => setDisableDrag(false)}
          onMouseLeave={() => setDisableDrag(true)}
        />
        <Accordion.Content
          active={!folded}
          content={renderInputSlots()}
          style={{
            backgroundColor: '#f5f5f5',
            padding: '24px',
          }}
        />
      </Menu.Item>
    </DraggableComponent>
  )
}
export default connect(mapStateToProps)(Functionality)
