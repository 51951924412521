import React from 'react'
import { Button, Input, Menu, Pagination } from 'semantic-ui-react'
import {
  calcTotalPages,
  calculateSkippedItems,
  generatePaginationSentence,
} from '~/utils/pagination'
import './Devices.css'

export default function DeviceListControl({
  totalNumberOfDevices,
  initializing,
  skip,
  limit,
  updateActivePage,
  textQuery,
  currentlyAppliedTextQuery,
  updateTextQuery,
}) {
  const shouldRenderPagination = totalNumberOfDevices > limit
  const activePage = Math.floor(skip / limit) + 1
  const shouldHighlightButton = textQuery !== currentlyAppliedTextQuery

  function handleKeyPress(keycode) {
    if (keycode !== 'Enter') { return }
    updateActivePage({ skip: 0, textQuery })
  }

  function renderLeftSide() {
    if (initializing) { return null }
    if (shouldRenderPagination) {
      return (
        <Menu.Item position='left'>
          <Pagination
            activePage={activePage}
            totalPages={calcTotalPages(totalNumberOfDevices, limit)}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            onPageChange={(_, { activePage }) => updateActivePage({
              textQuery,
              skip: calculateSkippedItems(activePage, limit)
            })}
          />
          <div style={{ paddingLeft: '1rem' }}>
            {generatePaginationSentence(activePage, totalNumberOfDevices, limit)}
            {' '}
            devices
          </div>
        </Menu.Item>
      )
    }

    if (!shouldRenderPagination && currentlyAppliedTextQuery && totalNumberOfDevices > 0) {
      return (
        <Menu.Item position='left'>
          {`showing ${totalNumberOfDevices} device${totalNumberOfDevices === 1 ? '' : 's'}`}
        </Menu.Item>
      )
    }

    return null
  }

  return (
    <Menu className='devices-navigation-search-container'>

      {renderLeftSide()}

      <Menu.Item position='right' style={{ padding: '1.75rem' }}>
        <Input
          style={{ marginRight: '0.5em' }}
          icon='search'
          iconPosition='left'
          placeholder='Device Name'
          value={textQuery}
          onChange={(_, { value }) => updateTextQuery(value)}
          onKeyPress={({ code }) => handleKeyPress(code)}
        />
        <Button
          className='input-field-height-button'
          content='Search'
          onClick={() => updateActivePage({ textQuery, skip: 0 })}
          disabled={!shouldHighlightButton}
          primary={shouldHighlightButton}
        />
      </Menu.Item>

    </Menu>
  )
}
