import nexus from '@ospin/nexus'
import { updateProcess } from '~/redux/actions/actions'
import { Process } from '~/utils/process'

const callDeleteCalibration = async (dispatch, fctId, slotName, process) => {
  const { deviceId, fctGraphId } = process
  await nexus.device.functionality
    .calibration.remove(
      deviceId,
      fctGraphId,
      fctId,
      { slotName },
    )

  if (Process.isRunningOrPaused(process)) {
    // To avoid duplicating logic
    // the backend handles the selection of the right calibration so a double call is required
    const { data: { calibrations } } = await nexus.process.get(process.id)
    dispatch(
      updateProcess({ processId: process.id, params: { calibrations } }),
    )
  }
}

export default callDeleteCalibration
