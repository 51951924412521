import ObjectTools from '~/utils/ObjectTools'
import ActuatorValue from './widgets/ActuatorValue'
import QueryValue from './widgets/QueryValue'
import LineChart from './widgets/LineChart'
import Widget from './widgets/Widget'

export default class GraphDashboard {

  static get WIDTH_PX() {
    return 1200
  }

  static get COLUMNS() {
    return 150
  }

  static get ROW_HEIGHT_PX() {
    return 8
  }

  static get COL_WIDTH_PX() {
    return GraphDashboard.WIDTH_PX / GraphDashboard.COLUMNS
  }

  static getWidgetInterface(widgetType) {
    switch (widgetType) {
      case QueryValue.TYPE:
        return QueryValue
      case ActuatorValue.TYPE:
        return ActuatorValue
      case LineChart.TYPE:
        return LineChart
      default:
        throw new Error(`Unknown widget type ${widgetType} provided`)
    }
  }

  static showDataSourceSelection(widgetType) {
    return GraphDashboard.getWidgetInterface(widgetType).USE_DATASOURCES
  }

  static areValidParamsForCreation(widgetType, params) {
    return GraphDashboard.getWidgetInterface(widgetType)
      .hasRequiredParamsForCreation(params)
  }

  static getWidgetDescription(widgetType) {
    return GraphDashboard.getWidgetInterface(widgetType).DESCRIPTION
  }

  static getWidgetById(dashboard, widgetId) {
    const { widgets } = dashboard
    return widgets.find(widget => widget.id === widgetId)
  }

  static getWidthInPx(widthInColumns) {
    return widthInColumns * GraphDashboard.COL_WIDTH_PX
  }

  static getHeightInPx(heightInRows) {
    return heightInRows * GraphDashboard.ROW_HEIGHT_PX
  }

  static transformWidhtInPxToColumns(widhtInPx) {
    return Math.ceil(widhtInPx / GraphDashboard.COL_WIDTH_PX)
  }

  static transformHeightInPxToRows(heightInPx) {
    return Math.ceil(heightInPx / GraphDashboard.ROW_HEIGHT_PX)
  }

  static getDimensionsInRowsAndColumns(widget) {
    /* we store the dimension as pixels, but the
     * react-grid-layout wants them as rows and colums */
    const { display: { dimension } } = widget
    const { maxWidth, maxHeight, minWidth, minHeight, width, height } = dimension

    return {
      w: GraphDashboard.transformWidhtInPxToColumns(width),
      h: GraphDashboard.transformHeightInPxToRows(height),
      minW: GraphDashboard.transformWidhtInPxToColumns(minWidth),
      minH: GraphDashboard.transformHeightInPxToRows(minHeight),
      maxW: GraphDashboard.transformWidhtInPxToColumns(maxWidth),
      maxH: GraphDashboard.transformHeightInPxToRows(maxHeight),
    }
  }

  static getFctsForWidgetType(widgetType, fctGraph, dataSources) {
    if (!widgetType) return []
    return GraphDashboard.getWidgetInterface(widgetType)
      .getCompatibleFcts(fctGraph, dataSources)
  }

  static getSlotsForWidgetType(widgetType, params) {
    const { fct, dataSources, fctGraph } = params

    if (!fct) return []

    return GraphDashboard
      .getWidgetInterface(widgetType)
      .getCompatibleSlots(fct, dataSources, fctGraph)
  }

  static addWidgetByType(widgetType, dashboard, widgetParams) {
    const { widgets } = dashboard
    const widget = GraphDashboard
      .getWidgetInterface(widgetType)
      .createDefaultWidget(widgetParams)
    return { ...dashboard, widgets: [ ...widgets, widget ] }
  }

  static removeWidget(dashboard, widgetId) {
    const { widgets } = dashboard
    const filteredWidgets = widgets.filter(widget => widget.id !== widgetId)
    return { ...dashboard, widgets: filteredWidgets }
  }

  static setWidgetPosition(dashboard, widgetId, { x, y }) {
    /* to show widgets on top of others after moving, we update it and
     * then move it to the last position in the dashboard array */
    const widget = GraphDashboard.getWidgetById(dashboard, widgetId)
    const { widgets: filteredWidgets } = GraphDashboard.removeWidget(dashboard, widgetId)
    return {
      ...dashboard,
      widgets: [ ...filteredWidgets, Widget.updatePosition(widget, { x, y }) ],
    }

  }

  static setWidgetDimensions(dashboard, widgetId, { width, height }) {
    /* to show widgets on top of others after resizing, we update it and
     * then move it to the last position in the dashboard array */
    const widget = GraphDashboard.getWidgetById(dashboard, widgetId)
    const { widgets: filteredWidgets } = GraphDashboard.removeWidget(dashboard, widgetId)

    return {
      ...dashboard,
      widgets: [ ...filteredWidgets, Widget.updateDimensions(widget, { width, height }) ],
    }
  }

  static updateWidget(dashboard, widgetId, updateFn) {
    const { widgets } = dashboard
    return {
      ...dashboard,
      widgets: widgets.map(widget => (widget.id === widgetId ? updateFn(widget) : widget)),
    }
  }

  static addSensorWidgetEscalation(dashboard, widgetId, escalation) {
    const updateFn = widget => QueryValue.addEscalation(widget, escalation)
    return GraphDashboard.updateWidget(dashboard, widgetId, updateFn)
  }

  static removeSensorWidgetEscalation(dashboard, widgetId, escalationIndex) {
    const updateFn = widget => QueryValue.removeEscalation(widget, escalationIndex)
    return GraphDashboard.updateWidget(dashboard, widgetId, updateFn)
  }

  static updateSensorWidgetEscalation(dashboard, widgetId, escalationIndex, updateData) {
    const updateFn = widget => QueryValue.updateEscalation(widget, escalationIndex, updateData)
    return GraphDashboard.updateWidget(dashboard, widgetId, updateFn)
  }

  static addDataSource(dashboard, widgetId, dataSource) {
    const updateFn = widget => LineChart.addDataSource(widget, dataSource)
    return GraphDashboard.updateWidget(dashboard, widgetId, updateFn)
  }

  static removeDataSource(dashboard, widgetId, dataSourceIndex) {
    const updateFn = widget => LineChart.removeDataSource(widget, dataSourceIndex)
    return GraphDashboard.updateWidget(dashboard, widgetId, updateFn)
  }

  static updateDataSourceLineColor(dashboard, widgetId, dataSourceIndex, newlineColor) {
    const updateFn = widget => LineChart.updateDataSource(widget, dataSourceIndex, newlineColor)
    return GraphDashboard.updateWidget(dashboard, widgetId, updateFn)
  }

  static toggleDashboardLock = (dashboard, isLock) => ({ ...dashboard, locked: isLock })

}
