import React, { Component } from 'react'
import { Select } from 'semantic-ui-react'

export default class Unit extends Component {

  getDropdownClass = options => {
    if (options.length > 1) return ''
    return 'single-option-dropdown'
  }

  render() {
    const { slot } = this.props
    const standardOptions = [{ value: slot.unit, text: slot.unit }]

    return (
      <Select
        compact
        open={false}
        options={standardOptions}
        value={slot.unit}
        style={{ borderRadius: '0 .28571429rem .28571429rem 0' }}
        className={this.getDropdownClass(standardOptions)}
      />
    )
  }
}
