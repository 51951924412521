export function addUserToGroup(resource, groupName, userId) {
  resource.access = resource.access.map(group => {
    if (group.name === groupName) {
      group.users.push(userId)
    }
    return group
  })
}

export function removeUserFromGroup(resource, groupName, userId) {
  resource.access = resource.access.map(group => {
    if (group.name === groupName) {
      group.users = group.users.filter(id => id !== userId)
    }
    return group
  })
}

export function removeUserFromResource(resource, userId) {
  resource.access = resource.access.map(group => {
    if (group.users.includes(userId)) {
      group.users = group.users.filter(id => id !== userId)
    }
    return group
  })
}

function getGroup(resource, groupName) {
  return resource.access.find(group => (
    group.name === groupName
  )) || null
}

export function getGroupUserIds(resource, groupName) {
  const group = getGroup(resource, groupName)

  return group ? [ ...group.users ] : []
}
