import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'

const defaultIconStyle = { float: 'right' }

const HelpIconPopup = ({ description, position = 'left center', iconStyle = {} }) => (
  <Popup
    content={description}
    position={position}
    basic
    wide
    trigger={
      <Icon
        name='question circle'
        style={{ ...defaultIconStyle, ...iconStyle }}
      />
    }
  />
)

export default HelpIconPopup
