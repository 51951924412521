import React from 'react'
import { Grid, Loader } from 'semantic-ui-react'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import { connect } from 'react-redux'
import DataManager from '~/utils/DataManager'
import SlotDataDisplayHeader from './SlotDataDisplayHeader'
import './SlotDataDisplay.css'
import OspinLineChartWrapper from '~/components/processviewer/charts/OspinLineChartWrapper'
import GraphTools from '~/utils/GraphTools'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })


class SlotDataDisplay extends React.Component {

  constructor(props) {
    super(props)
    const { functionalityDescriptions, slot, functionality } = this.props
    const description = FunctionalityDescription
      .getBySubType(functionalityDescriptions, functionality.subType)
    const targetSlotName = FunctionalityDescription
      .getAssociatedTargetSlotName(description, slot.name)
    const displayTargetLineCheckbox = targetSlotName !== null
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* this is a performance optimization that is required
     * because otherwise all graphs will be updated when
     * the data for a single graph changes */
    return (
      this.props.sensorData !== nextProps.sensorData
      || this.state !== nextState
      || nextProps.folded !== this.props.folded
      || nextProps.processState !== this.props.processState
      || nextProps.activeDevice.calibrations !== this.props.activeDevice.calibrations
      || nextProps.syncRangeSettings !== this.props.syncRangeSettings

    )
  }

  render() {
    const {
      slot,
      functionality,
      activeProcess,
      reporterFctId,
      syncRangeSettings,
      setSyncRangeSettings,
      activeDevice,
      loaded
    } = this.props



    const lastDataPoint = DataManager.getLastSensorDataPoint(activeProcess, reporterFctId)
    return (
      <Grid>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width='16'>
            <SlotDataDisplayHeader
              lastDataPoint={lastDataPoint}
              slot={slot}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='slot-data-line-chart-boundary'>
          <Grid.Column width='16'>
            {!loaded
            ? <Loader active/>
            : (
              <OspinLineChartWrapper
                activeProcess={activeProcess}
                reporterData={[{ reporterFctId, slot, fct: functionality, lineColor: GraphTools.DEFAULT_COLOR }]}
                syncRangeSettings={syncRangeSettings}
                setSyncRangeSettings={setSyncRangeSettings}
                activeDevice={activeDevice}
              />
            ) }

          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default connect(mapStateToProps)(SlotDataDisplay)
