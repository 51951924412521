import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { addUserToGroup, removeUserFromResource } from '~/utils/access'

export default (state, action) => {
  const { processes } = state
  const { processId, userId, newGroup } = action.payload

  const updateFnRemoveFromGroup = process => removeUserFromResource(process, userId)
  const updateFnAddToGroup = process => addUserToGroup(process, newGroup, userId)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFnRemoveFromGroup, updateFnAddToGroup ])

  return { ...state, processes: updatedProcesses }
}
