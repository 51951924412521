import React from 'react'
import { Form, Message } from 'semantic-ui-react'

const inputData = [
  { name: 'oldPassword', label: 'Old password' },
  { name: 'newPassword', label: 'New password' },
  { name: 'confirmPassword', label: 'Confirm new password' },
]

const renderInputs = (handleChange, values) => (
  inputData.map(({ name, label }) => (
    <React.Fragment key={name}>
      <Form.Field>
        <label htmlFor={name}>{label}</label>
        <Form.Input
          name={name}
          id={name}
          type='password'
          fluid
          onChange={handleChange}
          value={values[name]}
        />
      </Form.Field>
    </React.Fragment>
  ))
)

const ChangePasswordForm = ({
  isError,
  isSuccess,
  handleChange,
  errorMessage,
  submitNewPassword,
  loading,
  values,
}) => (
  <Form className='profile-change-password-form' error={isError} success={isSuccess}>
    <Form.Group widths='equal'>
      {renderInputs(handleChange, values)}
    </Form.Group>
    <Message
      error
      header='Changing password failed.'
      content={errorMessage}
    />
    <Message
      success
      header='Changed password successfully.'
    />
    <Form.Button
      loading={loading}
      disabled={loading}
      onClick={submitNewPassword}
    >
      Submit
    </Form.Button>
  </Form>
)

export default ChangePasswordForm
