import React from 'react'
import { withRouter } from 'react-router-dom'
import nexus from '@ospin/nexus'
import StackTracey from 'stacktracey'

import { connect } from 'react-redux'
import GlobalConfig from '~/GlobalConfig.js'

function logClientError(errorObject) {
  /* TODO: ADD_ERROR_HANDLING */
  nexus.utils.logClientError({ error: errorObject })
}

const mapStateToProps = state => ({ user: state.user })

class ErrorWrapperProvider extends React.Component {

  async componentDidCatch(error) {

    const errorObject = await this.serializeErrorData(error)
    const { enabled: reportClientErrors } = GlobalConfig.REPORT_CLIENT_ERRORS

    if (reportClientErrors) {
      logClientError(errorObject)
      this.redirectToReport(errorObject)
    }
  }

  redirectToReport(errorObject) {
    this.props.history.push({
      pathname: '/report',
      errorObject,
    })
  }

  async generateErrorString(error, stack) {
    return(
    `${String(error)}\n
    Stacktrace:
    ${stack.items.map(({fileRelative, callee, column, line}) => `[File]: ${fileRelative} [Function]: ${callee}:${line}:${column}`).join(' \n ')}`
    )
  }

  async serializeErrorData(error) {
    const { user } = this.props
    const tracy = new StackTracey(error)

    const stack = await tracy.withSourcesAsync()
    const userName = user ? user.userName : 'unauthenticated user'
    const errorString = await this.generateErrorString(error,stack)

    const { line, callee, fileRelative } = stack.items[0]
    return {
      errorString: errorString,
      errorTime: new Date(),
      lineNumber: String(line),
      functionName: String(callee),
      fileName: String(fileRelative),
      userName,
      pathname: window.location.href,
    }
  }

  render() {
    return this.props.children
  }
}
export default withRouter(connect(mapStateToProps, null)(ErrorWrapperProvider))
