import React from 'react'
import { Loader } from 'semantic-ui-react'
import './SectionLoader.css'

const SectionLoader = (props) => (
  <div className='section-loader'>
    <Loader
      active
      data-testid='section-loader'
      {...props}
    />
  </div>
)

export default SectionLoader
