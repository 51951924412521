import React, { useState, } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import GraphTools from '~/utils/GraphTools'
import { Process } from '~/utils/process'
import OspinLineChart from './OspinLineChart'
import OspinLineChartControls from './OspinLineChartControls'
import './OspinLineChart.css'

function requiresRangeSettingUpdate(existingRS, syncedRS) {
  if (existingRS.mode !== syncedRS.mode) {
    return true
  }
  if (syncedRS.mode === GraphTools.DISPLAY_MODES.PHASE) {
    return existingRS.selectedPhaseId !== syncedRS.selectedPhaseId
  }
  if (syncedRS.mode === GraphTools.DISPLAY_MODES.CUSTOM) {
    return syncedRS.x.min !== existingRS.x.min || syncedRS.x.max !== existingRS.x.max
  }
  return false

}

export default function OspinLineChartWrapper({
  activeProcess,
  activeDevice,
  reporterData,
  containerProps,
  syncRangeSettings,
  setSyncRangeSettings,
  clickHandlerOverwrite = null,
  lineChartFormat = 'dataviewer',
  showControls = true,
  showDatapoints = false,
}) {

  const graphRef = useRef(null)

  const [rangeSetting, setRangeSetting] = useState({
    mode: Process.isRunningOrPaused(activeProcess)
      ? GraphTools.DISPLAY_MODES.LIVE : GraphTools.DISPLAY_MODES.FULL,
  })
  const [displaySettings, setDisplaySettings] = useState({
    showPhaseLabel: true,
    showTargetLines: true,
    showAnnotations: true,
    timeStampsMode: 'processTime',
  })
  const [annotationModalProps, setAnnotationModalProps] = useState({ mode: null })
  const [isAnnotating, setIsAnnotating] = useState(false)
  const [includeZero, setIncludeZero] = useState(true)
  const [zoomMode, setZoomMode] = useState('x')

  useEffect(() => {
    if (!syncRangeSettings) { return }

    if (requiresRangeSettingUpdate(rangeSetting, syncRangeSettings)) {
      setRangeSetting(syncRangeSettings)
    }

  }, [syncRangeSettings])

  function clickHandler(type, e) {
    if (clickHandlerOverwrite) {
      return clickHandlerOverwrite(type, e)
    }
    if (type === GraphTools.CLICK_HANDLER_OBJECTS.DATAPOINT && isAnnotating) {
      setAnnotationModalProps({ mode: 'create', targetTimeStamp: e.absoluteTimestamp })
    }
    if (type === GraphTools.CLICK_HANDLER_OBJECTS.ANNOTATION) {
      setAnnotationModalProps({ mode: 'edit', annotationId: e.annotationId })
    }

  }

  function updateDisplaySettings(obj) {
    setDisplaySettings({
      ...displaySettings,
      ...obj,
    })
  }

  function setManualRange(range) {
    setRangeSetting({
      x: { min: null, max: null },
      ...GraphTools.generateNullYLimit(reporterData),
      mode: GraphTools.DISPLAY_MODES.CUSTOM,
      ...range,
    })
  }

  return (
    <div>
      {showControls ? (
        <OspinLineChartControls
          reporterData={reporterData}
          rangeSetting={rangeSetting}
          setRangeSetting={setRangeSetting}
          displaySettings={displaySettings}
          updateDisplaySettings={updateDisplaySettings}
          activeProcess={activeProcess}
          activeDevice={activeDevice}
          zoomMode={zoomMode}
          setZoomMode={setZoomMode}
          includeZero={includeZero}
          setIncludeZero={setIncludeZero}
          isAnnotating={isAnnotating}
          setIsAnnotating={setIsAnnotating}
          graphRef={graphRef}
          setSyncRangeSettings={setSyncRangeSettings}
          annotationModalProps={annotationModalProps}
          setAnnotationModalProps={setAnnotationModalProps}
          lineChartFormat={lineChartFormat}
        />
      ) : null}

      <OspinLineChart
        activeProcess={activeProcess}
        rangeSetting={rangeSetting}
        setRangeSetting={setRangeSetting}
        displaySettings={displaySettings}
        reporterData={reporterData}
        containerProps={containerProps}
        includeZero={includeZero}
        setManualRange={setManualRange}
        graphRef={graphRef}
        clickHandler={clickHandler}
        zoomMode={zoomMode}
        displayDataPoints={isAnnotating || showDatapoints}
      />
    </div>
  )
}
