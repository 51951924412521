class Licence {

  static get TYPES() {
    return {
      ULTIMATE: 'Ultimate',
      CORE: 'Core',
    }
  }

  static getNonExpiredTypes(device) {
    const { licences } = device

    if (!licences) { return [] }

    const nonExpiredLicences = licences.filter(
      ({ expiresAt }) => expiresAt === null || expiresAt > Date.now(),
    )

    const typeNames = nonExpiredLicences.map(({ licenceType }) => licenceType.name)
    return [...new Set(typeNames)]
  }

  static hasUltimateLicence(device) {
    const types = Licence.getNonExpiredTypes(device)
    return types.includes(Licence.TYPES.ULTIMATE)
  }

}

export default Licence
