import React from 'react'
import { Card, Header } from 'semantic-ui-react'
import ProcessControlPagination from './ProcessControlPagination'
import ProcessTile from './ProcessTile'
import './ProcessesTiles.css'

const ProcessesTiles = props => {

  const {
    processes,
    openModalHandler,
    processClickHandler,
    activeDevice,
    showProcessDetailsModal,
    activePage,
    totalPages,
    updateActivePage,
    processesPerPage,
    totalMatchingProcesses,
  } = props

  return (
    <>
      <div className='processes-list'>
        <Header as='h3'>All Processes</Header>
      </div>
      <div>
        <ProcessControlPagination
          activePage={activePage}
          totalPages={totalPages}
          updateActivePage={updateActivePage}
          totalMatchingProcesses={totalMatchingProcesses}
          processesPerPage={processesPerPage}
        />
      </div>
      <Card.Group className='process-tiles'>
        {processes
          .map(process => (
            <ProcessTile
              key={process.id}
              process={process}
              processClickHandler={processClickHandler}
              openModalHandler={openModalHandler}
              activeDevice={activeDevice}
              showProcessDetailsModal={showProcessDetailsModal}
            />
          ))}
      </Card.Group>
    </>
  )
}

export default ProcessesTiles
