import React, { useState } from 'react'
import { Message, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

import SensorCalibration from '~/utils/sensors/SensorCalibration'
import { replaceNoUnitCharacter } from '~/utils/units'
import callSetSensorCalibration from '~/redux/thunks/device/callSetSensorCalibration'
import './ExistingCalibrationSection.css'

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = dispatch => ({
  dispatchCallSensorCalibration: (process, fctId, slotName, params) =>
    callSetSensorCalibration(dispatch, process, fctId, slotName, params),
})

const getLockIcon = bool => (bool ? 'lock' : 'lock open')

const defaultPendingRequests = {
  offset: { deleting: false, locking: false },
  slope: { deleting: false, locking: false },
}

const ExistingCalibrationMessage = ({
  activeDevice,
  activeProcess,
  slot,
  functionality,
  user,
  existingCalibration,
  dispatchCallSensorCalibration,
}) => {

  const [pendingRequests, setPendingRequest] = useState(defaultPendingRequests)

  if (!existingCalibration) return null

  const { params: { slope, offset } } = existingCalibration

  const setLoadingState = (paramName, type, value) => (
    setPendingRequest({ ...pendingRequests, [paramName]: { ...pendingRequests[paramName], [type]: value } })
  )

  const getLoadingState = (paramName, type) => (
    pendingRequests[paramName][type]
  )

  const userCanSetLockedState = SensorCalibration.userCanSetLockedState(user, activeDevice)

  const submitDefaultValue = async (e, paramName) => {
    e.preventDefault()
    setLoadingState(paramName, 'deleting', true)
    try {
      await dispatchCallSensorCalibration(
        activeProcess,
        functionality.id,
        slot.name,
        ({ [paramName]: { value: SensorCalibration.getParamDefaultValue(paramName) } }),
      )
    } catch (_) {}
    finally {
      setLoadingState(paramName, 'deleting', false)
    }
  }

  const submitLockParameter = async (e, paramName, state) => {
    e.preventDefault()
    setLoadingState(paramName, 'locking', true)
    try {
      await dispatchCallSensorCalibration(
        activeProcess,
        functionality.id,
        slot.name,
        ({ [paramName]: { locked: state } }),
      )
    } catch (_) {}
    finally {
      setLoadingState(paramName, 'locking', false)
    }
  }

  const renderLockButton = (paramName, lockState) => (
    <Button
      color='blue'
      size='mini'
      disabled={!userCanSetLockedState
       || getLoadingState(paramName, 'locking')}
      basic
      icon={getLockIcon(lockState)}
      className='calibration-container-button'
      loading={getLoadingState(paramName, 'locking')}
      onClick={e => submitLockParameter(e, paramName, !lockState)}
      data-testid={`lock-${paramName}-btn-id`}
    />
  )

  const renderDeleteButton = (paramName, value) => (
    <Button
      negative
      size='mini'
      disabled={!SensorCalibration
        .userCanUpdateParameter(user, activeDevice, existingCalibration, paramName)
      || value === SensorCalibration.getParamDefaultValue(paramName)
      || getLoadingState(paramName, 'deleting')}
      basic
      icon='times'
      className='calibration-container-button'
      onClick={e => submitDefaultValue(e, paramName)}
      loading={getLoadingState(paramName, 'deleting')}
      data-testid={`delete-${paramName}-btn-id`}
    />
  )

  return (
    <Message info className='calibration-message'>
      <div className='calibration-container'>
        <Message.Header>
          Current Calibration
        </Message.Header>
        <div className='calibration-container-block'>
          <div className='calibration-container-block-row'>
            <span className='calibration-container-block-row-text'>
              {`Offset: ${offset.value.toFixed(3)} ${replaceNoUnitCharacter(slot.unit, '')}`}
            </span>
            {renderLockButton('offset', offset.locked)}
            {renderDeleteButton('offset', offset.value)}
          </div>
          <div className='calibration-container-block-row'>
            <span className='calibration-container-block-row-text'>
              {`Slope: ${slope.value.toFixed(3)}`}
            </span>
            {renderLockButton('slope', slope.locked)}
            {renderDeleteButton('slope', slope.value)}
          </div>
        </div>
      </div>
    </Message>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ExistingCalibrationMessage)
