export default (state, action) => {
  const newAlert = {
    id: action.payload.id,
    deviceId: action.payload.deviceId,
    eventType: action.payload.eventType,
    name: action.payload.name,
    createdAt: action.payload.createdAt,
    processId: action.payload.processId,
    displayMsg: action.payload.displayMsg,
  }
  const updatedUser = { ...state.user }
  updatedUser.events = [...updatedUser.events, newAlert]
  return { ...state, user: updatedUser }
}
