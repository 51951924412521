import nexus from '@ospin/nexus'
import { updateProcess } from '~/redux/actions/actions'

const callDeleteAnnotation = async (dispatch, process, annotationId) => {
  const { data: res } = await nexus.process.annotation
    .remove({ processId: process.id, annotationId })

  const { status } = res
  if (status === 'deleted') {
    const params = { annotations: process.annotations.filter(an => an.id !== annotationId) }
    dispatch(updateProcess({ processId: process.id, params }))
  }
}

export default callDeleteAnnotation
