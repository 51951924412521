import React from 'react'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'
import { capitalize } from '~/utils/string'

const USER_IDENTIFIERS = {
  userName: 'userName',
  email: 'email',
}

const AccessDeviceModalForm = ({
  groupOptions,
  selected,
  identifierType,
  handleChanges,
  isError,
}) => {

  const onUpdate = (e, { value }) => handleChanges('assignedGroup', value)

  return (
    <>
      <Form.Field>
        <Message info>
          <Message.Header>Please Note:</Message.Header>
          The user must already be registered with the Ospin WebApp.
        </Message>
          <label>Invite user by</label>
        <Button.Group fluid>
          <Button
            content='Email'
            onClick={() => handleChanges('identifierType', USER_IDENTIFIERS.email)}
            primary={identifierType === USER_IDENTIFIERS.email}
          />
        <Button.Or />
          <Button
            content='Username'
            onClick={() => handleChanges('identifierType', USER_IDENTIFIERS.userName)}
            primary={identifierType === USER_IDENTIFIERS.userName}
          />
        </Button.Group>
        <label
          htmlFor={`${capitalize(identifierType.toLowerCase())}`}
          style={{ paddingTop: '4px'}}>
            {capitalize(identifierType.toLowerCase())}
          </label>
          <Input
            id={`${capitalize(identifierType.toLowerCase())}`}
            fluid
            placeholder={`add user ${identifierType.toLowerCase()}...`}
            onChange={(_, { value }) => handleChanges('userIdentifierValue', value)}
            required={isError}
          />
      </Form.Field>
      <Form.Dropdown
        selection
        options={groupOptions}
        label='Group'
        placeholder='Choose group'
        selected={selected}
        onChange={onUpdate}
      />
    </>
  )
}

export default formalize(AccessDeviceModalForm)
