import SlotConfig from '~/utils/functionalities/slots/SlotConfig'

class ConfiguredSlot {

  static getDefaultValue(slot, slotConfig) {
    if (!SlotConfig.hasDefaultInputValue(slotConfig)) return slot.defaultValue
    return SlotConfig.getDefaultInputValue(slotConfig)
  }

  static getDisplayUnit(slot, slotConfig) {
    if (!SlotConfig.hasDisplayUnit(slotConfig)) return slot.unit
    return SlotConfig.getDisplayUnit(slotConfig)
  }

}

export default ConfiguredSlot
