import { Process, setPhaseProps } from '~/utils/process'
import { deepClone } from '~/utils/immutability'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PUM from '~/utils/process/ProcessUpdateManager'
import PhaseProgression from '~/utils/process/PhaseProgression'
import Phase from '~/utils/process/Phase'
import EventHistoryParser from '~/utils/process/EventHistoryParser'
import diff from 'deep-diff'
import nexus from '@ospin/nexus'

class PDVC {

  static createSnapshot(processId, processDescription, entryPhaseId) {
    return {
      id: processId,
      description: deepClone(processDescription),
      entryPhaseId,
    }
  }

  static updateSnapshot(snapshot, newDescription) {
    return {
      ...snapshot,
      description: JSON.parse(JSON.stringify(newDescription)),
    }
  }

  static getSnapshot(processId, snapshots) {
    return snapshots.find(snapshot => snapshot.id === processId)
  }

  static revertToSnapshot(process, snapshots) {
    const snapshot = this.getSnapshot(process.id, snapshots)
    return deepClone(snapshot.description)
  }

  static _compareProcessDescription(process, snapshot) {
    const diffs = diff(snapshot.description, process.description)
    const normalizedDiffs = this._normalizeDiffs(process, snapshot, diffs)
    const filteredDiffs = normalizedDiffs.filter(aDiff => this.isAdhocChange(aDiff))

    return this.sortByPath(filteredDiffs)
  }

  static getDiffsFromDescription(current, previous) {
    const diffs = previous ? this._compareProcessDescription(current, previous) : []
    return diffs
      .map(PDVC.mapNullChangesFromEditedToAdded)
      .map(PDVC.mapNullChangesFromEditedToDeleted)
  }

  static getDiff(process, snapshots) {
    const snapshot = this.getSnapshot(process.id, snapshots)
    return this.getDiffsFromDescription(process, snapshot)
  }

  static _getChangeType(diff) {
    switch (true) {
      case (['N', 'D'].includes(diff.kind) && diff.path.length === 1):
        return 'phaseCountChange'
      case (diff.kind === 'E' && diff.path.length === 2):
        return 'phasePropChange'
      case (diff.kind === 'N' && diff.path.length === 2):
        return 'phasePropAdd'
      case (diff.kind === 'D' && diff.path.length === 2):
        return 'phasePropRemoved'
      case (diff.kind === 'E' && diff.path.length === 4):
        return 'inputNodeValueChange'
      default:
        return 'unknownChange'
    }
  }

  // groupName and iterationOf are set to null on phases per default on the backend;
  // When adding or removing a phase to a group, it would recognize this as 'edited'
  // so we map this to 'added' 'deleted'

  static requiresPhaseGroupPropsMapping(aDiff) {
    const { property, type } = aDiff

    const isNotGroupPropChange = property !== 'groupName' && property !== 'iterationOf'
    if (isNotGroupPropChange) return false

    const isNotPhasePropChange = type !== 'phasePropChange'
    if (isNotPhasePropChange) return false

    return true
  }

  static mapNullChangesFromEditedToAdded = aDiff => {
    const { snapshot } = aDiff
    if (!PDVC.requiresPhaseGroupPropsMapping(aDiff)) return aDiff

    const addedPhaseToGroup = snapshot === null
    if (addedPhaseToGroup) {
      return { ...aDiff, type: 'phasePropAdd', action: 'added' }
    }

    return aDiff
  }

  static mapNullChangesFromEditedToDeleted = aDiff => {
    const { edited } = aDiff
    if (!PDVC.requiresPhaseGroupPropsMapping(aDiff)) return aDiff

    const removedPhaseFromGroup = edited === null
    if (removedPhaseFromGroup) {
      return { ...aDiff, type: 'phasePropRemoved', action: 'deleted' }
    }

    return aDiff
  }

  static _normalizeDiffs(process, snapshot, diffs) {
    if (!diffs) return []

    const ACTIONS = {
      N: 'added',
      E: 'edited',
      D: 'deleted',
    }

    return diffs.map(diff => {

      const action = ACTIONS[diff.kind]
      const phaseId = diff.path[0]
      const phaseName = action === 'added'
        ? DescriptionParser.getPhaseName(process, phaseId)
        : DescriptionParser.getPhaseName(snapshot, phaseId)

      return {
        snapshot: diff.lhs,
        edited: diff.rhs,
        phaseId: parseInt(phaseId, 10),
        phaseName,
        action,
        depth: diff.path.length,
        type: this._getChangeType(diff),
        path: diff.path,
        property: diff.path[1],
      }
    })
  }

  static sortByPath(diffs) {
    return diffs
      .sort((a, b) => a.path.join``.localeCompare(b.path.join``)) // ¯\_(ツ)_/¯
  }

  static isAdhocChange(aDiff) {
    return !this.isPhaseNameChange(aDiff)
      && !this.isIterationChange(aDiff)
      && !this.isPhaseDescriptionChange(aDiff)
  }

  static runningPhaseModified(runningPhaseId, diffs) {
    return diffs.some(({ phaseId }) => phaseId === runningPhaseId)
  }

  static hasInputNodeValuesChange(diffs, phaseId) {
    return diffs.some(diff => diff.type === 'inputNodeValueChange' && diff.phaseId === phaseId)
  }

  static isPhaseNameChange(diff) {
    return diff.type === 'phasePropChange' && diff.path[diff.depth - 1] === 'name'
  }

  static isPhaseDescriptionChange(diff) {
    return diff.type === 'phasePropChange' && diff.path[diff.depth - 1] === 'description'
  }

  static get PHASE_CHANGE_TYPES() {
    return ['phasePropChange', 'phasePropAdd', 'phasePropRemoved']
  }

  static isIterationChange(diff) {
    return PDVC.PHASE_CHANGE_TYPES.includes(diff.type) && diff.path[diff.depth - 1] === 'iterationOf'
  }

  static createInsertedPhaseName(phaseName) {
    return `${phaseName}*`
  }

  static async applyRunningPhaseModifications(process, snapshot, insertPhase) {
    let updatedProcess = process

    const runningPhaseId = Process.getLatestPhaseProgressionEntry(process).phaseId
    const runningPhaseData = DescriptionParser.getPhaseById(process, runningPhaseId)

    /* TODO: ADD_ERROR_HANDLING */
    const { data: fetchedProcess } = await nexus.process.get(process.id)
    const pausedDuration = EventHistoryParser
      .getFullPausedDurationFromEvents(fetchedProcess, runningPhaseId)

    const elapsedTime = Math.round(PhaseProgression
      .getSecondsSincePhaseStart(updatedProcess, runningPhaseId) - pausedDuration)

    if (!insertPhase) {
      const transitionMethod = Phase.getTransitionMethod(runningPhaseData)
      const resultingElapsedTime = transitionMethod === Phase.TRANSITIONS.MANUAL
        ? 0
        : elapsedTime

      return {
        process: updatedProcess,
        elapsedTime: resultingElapsedTime,
        entryPhaseId: runningPhaseId,
      }
    }

    updatedProcess = Process.appendNewPhase(process, runningPhaseId, snapshot)
    const insertedPhaseId = DescriptionParser.getLastPhaseId(updatedProcess)

    const insertedPhaseIndex = DescriptionParser.getPhaseIds(updatedProcess).length - 1
    const runningPhaseIndex = DescriptionParser
      .getPhaseExecutionIndex(updatedProcess, runningPhaseData.next)

    Process.movePhase(updatedProcess, insertedPhaseIndex, runningPhaseIndex)

    setPhaseProps(updatedProcess, insertedPhaseId, {
      name: this.createInsertedPhaseName(runningPhaseData.name),
      transition: runningPhaseData.transition,
      duration: Phase.isTimeBased(runningPhaseData) ? runningPhaseData.duration - elapsedTime : 0,
      inputNodeValues: { ...runningPhaseData.inputNodeValues },
    })

    if (runningPhaseData.groupName) {
      setPhaseProps(updatedProcess, insertedPhaseId, { groupName: runningPhaseData.groupName })
    }

    const runningPhaseSnapshotData = DescriptionParser.getPhaseById(snapshot, runningPhaseId)

    setPhaseProps(updatedProcess, runningPhaseId, {
      inputNodeValues: { ...runningPhaseSnapshotData.inputNodeValues },
      name: runningPhaseSnapshotData.name,
      duration: elapsedTime,
      transition: Phase.TRANSITIONS.TIME_BASED,
    })

    // when a phase is insderted, the elapsedTime is always zero because the device
    // transitions into a new phase (the inserted one)

    return { process: updatedProcess, elapsedTime: 0, entryPhaseId: insertedPhaseId }
  }

  static async applyDescriptionModification(process, snapshots, diffs) {

    let updatedProcess = { ...process }
    let elapsedTime = 0
    let entryPhaseId = Process.getLatestPhaseProgressionEntry(updatedProcess).phaseId

    const { allow: adhocAllowed, reason } = await PUM.adhocChangesAllowed(updatedProcess)

    if (!adhocAllowed) {
      throw new Error(reason)
    }

    const runningPhaseModified = this.runningPhaseModified(entryPhaseId, diffs)

    if (runningPhaseModified) {
      const insertPhase = this.hasInputNodeValuesChange(diffs, entryPhaseId)

      const snapshot = this.getSnapshot(updatedProcess.id, snapshots)

      const res = await this.applyRunningPhaseModifications(updatedProcess, snapshot, insertPhase)

      updatedProcess = res.process
      elapsedTime = res.elapsedTime
      entryPhaseId = res.entryPhaseId
    } else if (DescriptionParser
      .getPhaseTransitionMethod(updatedProcess, entryPhaseId) === Phase.TRANSITIONS.TIME_BASED) {

      /* TODO: ADD_ERROR_HANDLING */
      const { data: fetchedProcess } = await nexus.process.get(process.id)
      const pausedDuration = EventHistoryParser
        .getFullPausedDurationFromEvents(fetchedProcess, entryPhaseId)
      elapsedTime = Math.round(PhaseProgression
        .getSecondsSincePhaseStart(updatedProcess, entryPhaseId) - pausedDuration)
    }

    return { updatedProcess, elapsedTime, entryPhaseId: parseInt(entryPhaseId, 10) }
  }
}

export default PDVC
