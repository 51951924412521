import nexus from '@ospin/nexus'
import React, { useState, useEffect } from 'react'
import { Grid, Button, Icon, Header, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Authorizer from '~/utils/Authorizer'
import { getGroupUserIds } from '~/utils/access'
import { getDeploymentEnv } from '~/config/envDetector'
import SectionLoader from '~/components/utility/SectionLoader'
import UserTable from './UserTable'
import SupportTable from './SupportTable'
import AccessDeviceModal from '../modals/AccessDeviceModal'
import DeviceInvitationsTable from './DeviceInvitationsTable'
import TransferOwnershipModal from '../modals/TransferOwnershipModal'

import './ManageAccess.css'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const TOOLTIP_CONTENT = {
  IS_NOT_DEVICE_OWNER: 'You are not the device owner',
  NOT_ENOUGH_USERS: 'There are no other device users to transfer the ownership to',
}

const isTransferButtonDisabled = (deviceUsers, user, activeDevice) => {
  if (deviceUsers.length <= 1) {
    return { disabled: true, reason: TOOLTIP_CONTENT.NOT_ENOUGH_USERS }
  }

  if (!Authorizer.user(user).isOwnerOf(activeDevice)
    && !Authorizer.hasApplicationAdminAccess(user)) {
    return { disabled: true, reason: TOOLTIP_CONTENT.IS_NOT_DEVICE_OWNER }
  }

  return { disabled: false, reason: null }
}

const ManageAccess = ({
  activeDevice,
  user,
  deviceUsers,
}) => {

  const [ showNewUserModal, toggleNewUserModal ] = useState(false)
  const [ showTransferOwnershipModal, toggleTransferOwnershipModal ] = useState(false)
  const [ pendingDeviceInvitations, setpendingDeviceInvitations ] = useState([])

  const [isLoadingInvitations, setIsLoadingInvitations] = useState(true)

  useEffect(() => {
    const getPendingDeviceInvitations = async () => {
      try {
        const { data: invitations } = await nexus.device
          .getUserInvitations({ deviceId: activeDevice.id })
        setpendingDeviceInvitations(invitations)
        setIsLoadingInvitations(false)
      } catch (_) {
      }
    }

    getPendingDeviceInvitations()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const supportUserIds = getGroupUserIds(activeDevice, 'support')
  const supportUsers = deviceUsers.filter(({ id }) => (
    supportUserIds.includes(id)
  ))

  const appendInvitation = newInvitation => {
    setpendingDeviceInvitations([ ...pendingDeviceInvitations, newInvitation ])
  }

  const isAllowedToAddUsers = Authorizer.user(user).hasManageAccessRights(activeDevice)
    || Authorizer.hasApplicationAdminAccess(user)

  const { disabled, reason } = isTransferButtonDisabled(deviceUsers, user, activeDevice)

  const supportAppDomain = getDeploymentEnv() === 'prod' ? 'https://ospin-support.com' : `https://${getDeploymentEnv()}.ospin-support.com`

  const removePendingInvitation = invitationId => {
    const updatedInvitations = pendingDeviceInvitations
      .filter(pendingInv => pendingInv.id !== invitationId)

    setpendingDeviceInvitations(updatedInvitations)
  }

  return (
    <div>
      <Grid.Row key={0}>
        <div className='manage-access-button-container'>
          <Button
            primary
            onClick={() => toggleNewUserModal(true)}
            disabled={!isAllowedToAddUsers}
          >
            <Icon name='plus' />
            Add User
          </Button>
          <Popup
            trigger={(
              <span>
                <Button
                  primary
                  inverted
                  onClick={() => toggleTransferOwnershipModal(true)}
                  disabled={disabled}
                >
                  Transfer Ownership
                </Button>
              </span>
            )}
            disabled={!disabled}
            content={reason}
          />
        </div>
      </Grid.Row>
      <Grid.Row>
        <UserTable
          activeDevice={activeDevice}
        />
        <Header as='h1'>Pending Invitations</Header>
        {
          isLoadingInvitations ? <SectionLoader size='small' inline='centered' />
            : (
              <DeviceInvitationsTable
                activeDevice={activeDevice}
                isLoadingInvitations={isLoadingInvitations}
                pendingDeviceInvitations={pendingDeviceInvitations}
                removePendingInvitation={removePendingInvitation}
                user={user}
              />

            )
        }
        <AccessDeviceModal
          docButtonTag='collaboratingOnDevices'
          headerText=' Add User'
          open={showNewUserModal}
          activeDevice={activeDevice}
          closeHandler={() => toggleNewUserModal(false)}
          appendInvitation={appendInvitation}
        />
        <TransferOwnershipModal
          open={showTransferOwnershipModal}
          activeDevice={activeDevice}
          closeHandler={() => toggleTransferOwnershipModal(false)}
        />
        <br />
        <SupportTable activeDevice={activeDevice} supportUsers={supportUsers} />
        <br />
        {
          Authorizer.user(user).hasApplicationDeveloperAccess()
            ? <a href={`${supportAppDomain}/devices?textQuery=${activeDevice.id}&textQueryField=id`}>Support app</a> : null
        }
      </Grid.Row>
    </div>
  )
}

export default connect(mapStateToProps, null)(ManageAccess)
