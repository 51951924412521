import React, { useState } from 'react'
import { Form, TextArea, Message, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Process } from '~/utils/process'
import PDVC from '~/utils/PDVC'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import FlashMessenger from '~/utils/FlashMessenger'
import { setPhaseProps } from '~/redux/actions/actions'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import Authorizer from '~/utils/Authorizer'
import './PhaseHeader.css'

const { VALID_PHASE_DESCRIPTION_DATA: { phaseDescriptionLength } } = ProcessValidator

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchPhaseDescription: (processId, phaseId, description) => dispatch(
    setPhaseProps({ processId, phaseId, update: { description } }),
  ),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

const PhaseDescription = props => {

  const {
    activeProcess,
    phaseId,
    phase,
    dispatchPhaseDescription,
    dispatchCallUpdateProcess,
    processDescriptionSnapshots,
    editPhaseDescription,
    setEditPhaseDescription,
    setLoadingSubmitDescription,
    isEditDisabled,
    user,
  } = props

  const [newPhaseDescription, setNewPhaseDescription] = useState(phase.description)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const save = async () => {
    await Process.save(activeProcess, dispatchCallUpdateProcess)
  }

  const submitPhaseDescription = async e => {
    const newDescription = e.target.value

    if (!ProcessValidator.isValidPhaseDescriptionLength(newDescription)) {
      return
    }

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    if (diffs.length) {
      FlashMessenger.warning('Please revert or apply your process changes before changing the description')
      return
    }

    setLoadingSubmitDescription(true)

    try {
      dispatchPhaseDescription(activeProcess.id, phaseId, newDescription)
      await save()
      setEditPhaseDescription(false)
      setIsError(false)
      setLoadingSubmitDescription(false)
    } catch ({ message }) {
      setIsError(true)
      setErrorMessage(message)
      setLoadingSubmitDescription(false)
    }
  }

  const updatePhaseDescription = e => {
    const newDescription = e.target.value

    setNewPhaseDescription(newDescription)

    if (!ProcessValidator.isValidPhaseDescriptionLength(newDescription)) {
      setIsError(true)
      setErrorMessage(`Phase description must be shorter than ${phaseDescriptionLength + 1} characters`)
    } else {
      setIsError(false)
      setErrorMessage('')
    }
  }

  const handleDoubleClickDescription = () => {
    setEditPhaseDescription(true)
  }

  const renderEditOrDescription = () => {
    if (editPhaseDescription) {
      return (
        <Form className='phase-description'>
          <Form.Field error={isError}>
            <TextArea
              data-testid='phase-description-text-area-id'
              name='PhaseDescription'
              className='phase-description-text-area'
              onChange={updatePhaseDescription}
              onBlur={submitPhaseDescription}
              value={newPhaseDescription}
              rows={5}
              disabled={Authorizer.isResourceViewer(activeProcess, user.id)}
              autoFocus
            />
            <Label>
              {newPhaseDescription.length}
              /
              {phaseDescriptionLength}
            </Label>
          </Form.Field>
        </Form>
      )
    }

    if (!editPhaseDescription && phase.description.trim() !== '') {
      return (
        <div onDoubleClick={!isEditDisabled ? handleDoubleClickDescription : null}>
          <p
            className='phase-description-shown-as-plain-text'
          >
            {phase.description}
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <>
      {renderEditOrDescription()}
      {isError && <Message error content={errorMessage} />}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseDescription)
