import Validator from './Validator'

export default class DashboardValidator extends Validator {
  static get DASHBOARD_VALIDATION_CONSTANTS() {
    return {
      name: { min: 1, max: 80 },
      description: { min: 0, max: 500 },
    }
  }

  static validateRange(name, key) {
    const { min, max } = this.DASHBOARD_VALIDATION_CONSTANTS[key]
    return this.withinRange(name.length, [ min, max ])
  }

  static isValidDashboardName = ({ name }) => this.validateRange(name, 'name')

  static isValidDashboardDescription = ({ description }) => this.validateRange(description, 'description')

  static isValid(dashboard) {
    return [
      this.isValidDashboardName,
      this.isValidDashboardDescription].every(fn => fn(dashboard))
  }
}
