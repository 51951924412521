import React, { useState } from 'react'
import { Table, Button } from 'semantic-ui-react'
import Time from '~/utils/Time'
import DescriptionParser from '~/utils/process/DescriptionParser'
import ImageModal from './ImageModal'

export default function ImageTable(props) {
  const { imgRefs, activeProcess } = props
  const [showImageModal, setShowImageModal] = useState(false)
  const [focusedImgRef, setFocusedImgRef] = useState(null)

  function generateDownloadButton(downloadUrl) {
    return (
      <a href={downloadUrl}>
        <Button primary icon='download' />
      </a>
    )
  }

  function openAndSetModal(imgRef) {
    setShowImageModal(true)
    setFocusedImgRef(imgRef)
  }

  function generateModalButton(imgRef) {
    return (
      <Button
        content='Show Image'
        onClick={() => openAndSetModal(imgRef)}
      />
    )
  }

  function renderTableRow(imgRef) {
    const phaseName = DescriptionParser.getPhaseById(activeProcess, imgRef.phaseId).name
    switch (imgRef.cameraType) {
      case 'ISOStretcherCamera':
        return (
          <Table.Row key={imgRef.id}>
            <Table.Cell>{Time.getTrimmedDateAndTimeString(imgRef.imageCreatedAt)}</Table.Cell>
            <Table.Cell>{phaseName}</Table.Cell>
            <Table.Cell>{imgRef.metaData.stretcherValue}</Table.Cell>
            <Table.Cell>{generateModalButton(imgRef)}</Table.Cell>
            <Table.Cell>{generateDownloadButton(imgRef.downloadUrl)}</Table.Cell>
          </Table.Row>
        )
      default:
        return null
    }
  }

  function renderTableHead(imgRef) {
    switch (imgRef.cameraType) {
      case 'ISOStretcherCamera':
        return (
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
              <Table.HeaderCell>Phase Name</Table.HeaderCell>
              <Table.HeaderCell>Isostretcher Value</Table.HeaderCell>
              <Table.HeaderCell>Preview</Table.HeaderCell>
              <Table.HeaderCell>Download</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )
      default:
        return null
    }
  }
  return (
    <>
      <ImageModal
        imgRefs={imgRefs}
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        focusedImgRef={focusedImgRef}
        setFocusedImgRef={setFocusedImgRef}
        activeProcess={activeProcess}
      />

      <div style={{
        height: '40vh',
        overflowX: 'scroll',
      }}
      >
        <Table fixed celled striped>
          {renderTableHead(imgRefs[0])}
          <Table.Body>
            {imgRefs.map(imgRef => renderTableRow(imgRef, activeProcess))}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}
