import React from 'react'
import { Button, Checkbox, Icon, Select, Popup } from 'semantic-ui-react'
import './DashboardToolbar.css'

const getDashboardOptions = dashboards => dashboards
  .map(dashboard => ({
    value: dashboard.id,
    text: dashboard.name,
    key: dashboard.id,
  }))

  const DashboardToolbar = props => {
    const {
      dashboard,
      dashboards,
      setSelectedDashboardId,
      setOpenSaveDashboardModalByType,
      toggleDashboardLock,
      requestLockDashboard,
      setOpenWidgetModal,
    } = props

    const { locked: lockDashboard } = dashboard

    return (
      <div>
        <div className='dashboard-toolbar-center-flex'>
          <div className='dashboard-toolbar-center-flex'>
            <h3
              style={{paddingTop: '8px', margin: 0}}
              className='dashboard-title-name'
            >
              {dashboard.name}
            </h3>
            <Button
              onClick={() => setOpenSaveDashboardModalByType('edit')}
              content='Edit Name'
            />
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button
              basic
              onClick={() => setOpenWidgetModal(true)}
              disabled={lockDashboard}
              icon='plus'
              content='Add Widget'
            />
            <Popup
            trigger={(
              <Button
                basic
                onClick={toggleDashboardLock}
                toggle
                disabled={requestLockDashboard}
                content= {lockDashboard ? <><Icon name='lock'/> Unlock</> : <><Icon name='lock open'/> Lock</> }
              />
            )}
            content={lockDashboard ? 'Unlock this dashboard to add more widgets' : 'Lock the dashboard to keep the current layout'}
            position='top right'
          />
            <Button
              basic
              onClick={() => setOpenSaveDashboardModalByType('create')}
              icon='plus'
              content='Add Dashboard'
            />
            <Select
              options={getDashboardOptions(dashboards)}
              value={dashboard.id}
              onChange={(_, { value }) => setSelectedDashboardId(value)}
              data-testid='select-dashboard'
            />

          </div>
        </div>
      </div>
    )
  }

export default DashboardToolbar
