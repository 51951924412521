import React from 'react'
import formalize from '~/components/utility/modal/formalize'

const NextPhaseModalForm = () => (
  <>
    Are you sure you want to proceed to the next phase?
  </>
)

export default formalize(NextPhaseModalForm)
