import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Slot } from '@ospin/fct-graph'
import { withRouter } from 'react-router-dom'
import { replaceNoUnitCharacter } from '~/utils/units'
import './SensorWidgetBody.css'

const isEscalating = (value, escalation) => {
  const { threshold, comparator } = escalation

  switch (comparator) {
    case '>':
      return value > threshold
    case '>=':
      return value >= threshold
    case '<':
      return value < threshold
    case '<=':
      return value <= threshold
    default:
      throw new Error(`Unknown comparator ${comparator} provided`)
  }
}

const generateColorCodeForEscalation = (value, escalations) => {
  let backgroundColor = '#ffffff'
  let fontColor = '#717171'

  for (const escalation of escalations) {
    if (isEscalating(value, escalation) && value !== null) {
      backgroundColor = escalation.backgroundColor
      fontColor = escalation.fontColor
    }
  }

  return { backgroundColor, color: fontColor }
}

export const SensorWidgetHeader = ({
  displayedName,
  onClickEscalation,
  removeWidget,
  image,
}) => (
  <div className='sensor-widget-header-layout'>
    <div className='sensor-widget-header-icon-name'>
      <img src={image} className='sensor-widget-header-icon' alt='Widget Icon' />
      <div className='sensor-widget-slot-name'>
        {displayedName}
      </div>
    </div>
    <div className='transition-opacity sensor-widget-dropdown-container'>
      <Dropdown
        className='sensor-widget-opt-btn'
        item
        closeOnChange
      >
        <Dropdown.Menu className='sensor-widget-opt-menu'>
          <Dropdown.Item onClick={onClickEscalation} text='Alerts' />
          <Dropdown.Item data-testid='sensorwidget-delete' onClick={removeWidget} text='Delete Widget' />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </div>
)

const renderTargetValue = (val, slot) => {
  if (val === null || val === undefined) return null

  return (
    <div className='sensor-widget-target-value'>
      {`Target: ${val}`}
      {' '}
      {replaceNoUnitCharacter(slot.unit, '')}
    </div>
  )
}

const SensorWidgetBody = ({
  onClickEscalation,
  removeWidget,
  image,
  value,
  displayedValue,
  widget,
  fct,
  slot,
  targetValue,
}) => {
  const { display: { escalations } } = widget
  const styleObj = generateColorCodeForEscalation(displayedValue, escalations)

  return (
    <div className='sensor-widget-container'>
      <SensorWidgetHeader
        image={image}
        displayedName={Slot.getDisplayName(slot)}
        onClickEscalation={onClickEscalation}
        removeWidget={removeWidget}
      />
      <div className='sensor-widget-value-container' style={styleObj}>
        <div className='sensor-widget-value'>
          {value === null ? 'No Data' : displayedValue}
          {' '}
          {value !== null ? replaceNoUnitCharacter(slot.unit, '') : null}
        </div>
        {renderTargetValue(targetValue, slot)}
        <div className='sensor-widget-fct-name'>
          {fct.name}
        </div>
      </div>
    </div>
  )
}

export default withRouter(SensorWidgetBody)
