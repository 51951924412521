import React from 'react'
import { connect } from 'react-redux'

import { Menu, Icon } from 'semantic-ui-react'

import './ProcessViewerMenu.css'

const mapStateToProps = ({ user }) => ({ user })

const TABS_MAP = {
  BUILDER: 'Builder',
  DATA_VIEWER: 'Data Viewer',
  DASHBOARD: 'Dashboard',
  OVERVIEW: 'Overview',
  LOGS: 'Logs',
  DOWNLOADS: 'Downloads',
}

const TABS = Object.values(TABS_MAP)

const ProcessViewerMenu = ({
  activeTab,
  setActiveTab,
  hasSyncedPorts,
}) => {
  const [width, setWidth] = React.useState(window.innerWidth)
  const breakpoint = 1000

  React.useEffect(() => {
    function resizeBasedOnWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', resizeBasedOnWidth)
    return function cleanup () {
      window.removeEventListener('resize', resizeBasedOnWidth)
    }
  })

  return (
    <Menu attached tabular>
      {TABS.map(tab => (
        <Menu.Item
          key={tab}
          style={width < breakpoint ? { padding: '10px' } : {}}
          name={tab}
          active={activeTab === tab}
          onClick={() => setActiveTab(tab)}
          icon={!hasSyncedPorts && tab === TABS_MAP.OVERVIEW
            ? (
              <Icon
                className='process-viewer-menu-warning-icon'
                id='yellow-icon'
                name='warning circle'
                color='yellow'
              />
            )
            : null}
        />
      ))}
    </Menu>
  )
}

export default connect(mapStateToProps)(ProcessViewerMenu)
