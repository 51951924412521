import React from 'react'
import { Functionality, Ports } from '@ospin/fct-graph'
import { Dropdown, Divider } from 'semantic-ui-react'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import './FCTGraphSelection.css'

const createTextForDropdowm = fctGraph => {
  const physFcts = fctGraph.functionalities
    .filter(Functionality.isPhysical)

  /* for fctGraphs with a single physical functionality
   * we show the port; for system with multiple
   * phys functionality customers will have to name their
   * functionality graphs to distinguish them easily */
  if (physFcts.length !== 1) return fctGraph.name

  return `${fctGraph.name} - ${Ports.getId(physFcts[0].ports)}`
}

function generateDropdownEntry(fctGraph, activeDevice) {
  return {
    key: fctGraph.id,
    text: createTextForDropdowm(fctGraph),
    value: fctGraph.id,
    image: fctGraph.imageURL || null,
    label: fctGraph.possible && activeDevice.online
      ? { circular: true, className: 'fct-graph-selection-green-dropdown-icon', empty: true }
      : { circular: true, className: 'fct-graph-selection-grey-dropdown-icon', empty: true },
  }
}

function sortByPossibleFirst(fctGraphs, device) {
  const connectedGraphs = fctGraphs
    .filter(fctGraph => FunctionalityGraph.deviceCanExecuteGraph(fctGraph, device))
    .map(fctGraph => ({ ...fctGraph, possible: true }))

  const disconnectedGraphs = fctGraphs
    .filter(fctGraph => connectedGraphs.every(otherFctGraph => otherFctGraph.id !== fctGraph.id))

  return [ ...connectedGraphs, ...disconnectedGraphs ]
}

export default function FCTGraphSelection({
  fctGraphs,
  selectedFctGraphId,
  setSelectedFctGraphId,
  activeDevice,
}) {
  const inputDisabled = fctGraphs.length === 1
  return (
    <>
      <b>Configuration:</b>
      <Dropdown
        disabled={inputDisabled}
        fluid
        selection
        search
        value={selectedFctGraphId}
        onChange={(_, { value: fctGraphId }) => setSelectedFctGraphId(fctGraphId)}
        options={sortByPossibleFirst(fctGraphs, activeDevice)
          .map(fctGraph => generateDropdownEntry(fctGraph, activeDevice))}
      />
      <Divider />
    </>
  )
}
