import store from '~/redux/store'
import { addFlashMessage } from '~/redux/actions/actions'

class FlashMessenger {

  static createMessage(type, text, persistTime = 5000) {
    return {
      type,
      text,
      persistTime,
      id: Date.now(),
    }
  }

  static dispatch(message) {
    store.dispatch(addFlashMessage({ message }))
  }

  static success(text, persistTime) {
    const message = FlashMessenger.createMessage('success', text, persistTime)
    FlashMessenger.dispatch(message)
  }

  static info(text, persistTime) {
    const message = FlashMessenger.createMessage('info', text, persistTime)
    FlashMessenger.dispatch(message)
  }

  static warning(text, persistTime) {
    const message = FlashMessenger.createMessage('warning', text, persistTime)
    FlashMessenger.dispatch(message)
  }

  static error(text, persistTime) {
    const message = FlashMessenger.createMessage('error', text, persistTime)
    FlashMessenger.dispatch(message)
  }

  static alarm(text, persistTime) {
    const message = FlashMessenger.createMessage('alarm', text, persistTime)
    FlashMessenger.dispatch(message)
  }

}

export default FlashMessenger
