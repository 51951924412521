import React from 'react'
import { withRouter } from 'react-router-dom'
import nexus from '@ospin/nexus'
import { Process } from '~/utils/process'
import modalize from '~/components/utility/modal/modalize'
import CreateProcessModalForm from '~/components/device/modals/CreateProcessModalForm'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import FeatureVersioning from '~/utils/FeatureVersioning'
import FCTGraphSelection from './FCTGraphSelection'

const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

function selectInitalFctGraphId(props) {
  const {
    match: { params: { fctGraphId } },
    activeDevice,
  } = props
  if (fctGraphId) {
    return fctGraphId
  }
  const { fctGraphs } = activeDevice

  if (!FeatureVersioning.supportsMultipleProcesses(activeDevice)) {
    return activeDevice.fctGraphs[0].id
  }

  const connectedGraphs = fctGraphs
    .filter(fctGraph => FunctionalityGraph.deviceCanExecuteGraph(fctGraph, activeDevice))
  if (connectedGraphs.length) return connectedGraphs[0].id
  return activeDevice.fctGraphs[0].id
}

class CreateProcessModal extends React.Component {

  state = {
    processName: `MyProcess-${(new Date(Date.now()).toString().substr(4, 20))}`,
    processComment: '',
    loading: false,
    isError: false,
    errorMessage: '',
    selectedFctGraphId: selectInitalFctGraphId(this.props),
  }

  async handleFctGraphUpdate(fctGraphId) {
    this.setState({ selectedFctGraphId: fctGraphId })
  }

  handleUpdate = (_, { name, value }) => {
    this.setState({ [name]: value })
  }

  setErrorMessage = msg => (
    this.setState({ isError: true, errorMessage: msg })
  )

  invalidData = (name, comment) => {
    if (!ProcessValidator.isValidProcessName(name)) {
      this.setErrorMessage(`Process name must be shorter than ${nameLength + 1} characters`)
      return true
    }

    if (!ProcessValidator.isValidProcessComment(comment)) {
      this.setErrorMessage(`Process comment must be shorter than ${commentLength + 1} characters`)
      return true
    }

    return false
  }

  createNewProcess = async () => {

    const {
      processName,
      processComment,
      selectedFctGraphId,
    } = this.state

    const {
      activeDevice,
      history,
      match,
    } = this.props

    if (this.invalidData(processName, processComment)) return

    this.setState({ loading: true })

    /* TODO: ADD_ERROR_HANDLING */
    const { data: { fctsConfigs } } = await nexus.device.functionalityGraph.configuration.get({
      deviceId: activeDevice.id,
      fctGraphId: selectedFctGraphId,
    })

    let processParams = {
      name: processName,
      comment: processComment,
      description: {},
      entryPhaseId: 0,
      fctGraphId: selectedFctGraphId,
    }

    const fctGraph = FunctionalityGraph
      .getGraphById(activeDevice.fctGraphs, selectedFctGraphId)

    const enrichedProcessParams = {
      ...processParams,
      fctGraph,
      fctsConfigs,
    }

    processParams = Process.appendNewPhase(enrichedProcessParams)

    try {
      const { data: process } = await nexus.process.create({
        deviceId: activeDevice.id,
        params: processParams,
      })

      const { params: { fctGraphId } } = match
      if (fctGraphId) {
        history.push(`/devices/${activeDevice.id}/configurations/${fctGraphId}/processes/${process.id}`)
      } else {
        history.push(`/devices/${activeDevice.id}/processes/${process.id}`)
      }
    } catch (e) {
      this.setState({ loading: false, isError: true, errorMessage: e.message })
    }
  }

  getPossibleFctGraphs = () => {
    const {
      activeDevice: { fctGraphs },
      match: { params: { fctGraphId } },
    } = this.props

    if (fctGraphId) {
      return fctGraphs.filter(graph => graph.id === fctGraphId)
    }

    return fctGraphs
  }

  render() {

    const {
      processName,
      processComment,
      loading,
      isError,
      errorMessage,
      selectedFctGraphId,
    } = this.state

    const possibleFctGraphs = this.getPossibleFctGraphs()

    const { closeHandler, activeDevice, match } = this.props
    const shouldDisplayFctGraphSelection = FeatureVersioning.supportsMultipleProcesses(activeDevice)
      && match.params.fctGraphId === undefined

    return (
      <>
        { shouldDisplayFctGraphSelection
          && (
            <FCTGraphSelection
              selectedFctGraphId={selectedFctGraphId}
              setSelectedFctGraphId={fctGraphId => this.handleFctGraphUpdate(fctGraphId)}
              fctGraphs={possibleFctGraphs}
              activeDevice={activeDevice}
            />
          )}
        <CreateProcessModalForm
          isError={isError}
          errorMessage={errorMessage}
          closeHandler={closeHandler}
          submitHandler={this.createNewProcess}
          loading={loading}
          processName={processName}
          processComment={processComment}
          handleUpdate={this.handleUpdate}
        />
      </>
    )
  }
}

export default withRouter(modalize(CreateProcessModal))
