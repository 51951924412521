import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Segment, List, Icon, Button, Popup } from 'semantic-ui-react'

import { Process } from '~/utils/process'
import DescriptionParser from '~/utils/process/DescriptionParser'
import {
  setDisplayedProcessPhase,
  deletePhase,
  deletePhaseWithinGroup,
} from '~/redux/actions/actions'
import DraggablePortal from '~/components/utility/DraggablePortal'
import Phase from '~/utils/process/Phase'
import './PhaseTabDraggable.css'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import PhaseProgression from '~/utils/process/PhaseProgression'
import Authorizer from '~/utils/Authorizer'

const mapStateToProps = state => ({
  displayedPhaseId: state.displayedPhaseId,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  setDisplayedProcessPhase: phaseId => dispatch(setDisplayedProcessPhase(phaseId)),
  dispatchDeletePhase: (processId, phaseId) => dispatch(deletePhase({ processId, phaseId })),
  dispatchDeletePhaseWithinGroup: (processId, groupName, phaseId) => (
    dispatch(deletePhaseWithinGroup({ processId, groupName, phaseId }))
  ),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

const phaseWidgetClassMap = {
  runningAndSelected: {
    iconClass: 'phase-widget-transition-icon-white',
    borderClass: 'phase-widget-selected-and-running animated-background',
  },
  running: {
    iconClass: 'phase-widget-transition-icon-white',
    borderClass: 'phase-widget-running animated-background',
  },
  paused: {
    iconClass: 'phase-widget-transition-icon-white',
    borderClass: 'phase-widget-paused animated-background-paused',
  },
  pausedAndSelected: {
    iconClass: 'phase-widget-transition-icon-white',
    borderClass: 'phase-widget-selected-and-paused animated-background-paused',
  },
  selected: {
    iconClass: 'phase-widget-color-ospin-red',
    borderClass: 'phase-widget-selected',
  },
  default: {
    iconClass: 'phase-widget-color-ospin-grey',
    borderClass: 'phase-widget-default',
  },
}

const isPhaseIteration = (activeProcess, runningPhase, phaseId) => {
  const phase = DescriptionParser
    .getPhaseById(activeProcess, runningPhase.phaseId)

  if (!phase) return false

  const { iterationOf } = phase

  return phaseId === iterationOf
}

const getPhaseWidgetClasses = (isSelected, isRunning, isPausedPhase) => {

  if (isPausedPhase && isSelected) return phaseWidgetClassMap.pausedAndSelected

  if (isPausedPhase) return phaseWidgetClassMap.paused

  if (isRunning && isSelected) return phaseWidgetClassMap.runningAndSelected

  if (isRunning) {
    return phaseWidgetClassMap.running
  }

  if (isSelected) {
    return phaseWidgetClassMap.selected
  }

  return phaseWidgetClassMap.default
}

const renderRunningLoadingBar = isSelected => (
  <div
    className={`phase-widget-running-bar ${isSelected ? 'phase-widget-running-bar-selected' : ''}`}
  />
)

const renderPausedLoadingBar = isSelected => (
  <div
    className={`phase-widget-paused-bar ${isSelected ? 'phase-widget-paused-bar-selected' : ''}`}
  />
)

const getIconClassName = (showPopup, disabled) => {
  if (disabled) return 'phase-widget-delete-icon-disabled'
  if (showPopup) return 'phase-widget-delete-icon-shown'
  return 'phase-widget-delete-icon'
}

const getIconWrapperClassName = isRunning => {
  const baseClass = 'phase-widget-icon-wrapper'
  if (isRunning) return `${baseClass} ${baseClass}-color-white`
  return `${baseClass} ${baseClass}-color-grey`
}

const PhaseTabDraggable = props => {

  const {
    displayedPhaseId,
    phaseId,
    index,
    runningPhase,
    activeProcess,
    phase,
    user,
    isDragDisabled = false,
  } = props
  const { name } = phase

  const [showPopup, setPopupState] = useState(false)

  const isSelected = phaseId === displayedPhaseId
  const isRunning = phaseId === runningPhase.phaseId
    || isPhaseIteration(activeProcess, runningPhase, phaseId)

  const isPausedPhase = isRunning && Process.isPaused(activeProcess)

  const { iconClass, borderClass } = getPhaseWidgetClasses(isSelected, isRunning, isPausedPhase)

  const applyPhaseDeletion = async () => {
    const {
      dispatchDeletePhase,
      dispatchDeletePhaseWithinGroup,
    } = props
    const { groupName } = phase
    if (groupName) {
      dispatchDeletePhaseWithinGroup(activeProcess.id, groupName, phaseId)
    } else {
      dispatchDeletePhase(activeProcess.id, phaseId)
    }

    if (Process.isExecutable(activeProcess)) {
      const { dispatchCallUpdateProcess } = props
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const handleOpen = e => {
    e.stopPropagation()
    setPopupState(true)
  }

  const handleClose = () => setPopupState(false)

  const handleClick = async () => {
    await applyPhaseDeletion()
    handleClose()
  }

  const phaseNeverRan = !PhaseProgression.contains(activeProcess, phaseId)
  const disableDeleteButton = DescriptionParser.getPhaseCount(activeProcess) === 1
    || (!phaseNeverRan && !Process.isExecutable(activeProcess))
    || Process.isFinished(activeProcess)
    || Authorizer.isResourceViewer(activeProcess, user.id)

  return (
    <DraggablePortal
      index={index}
      draggableId={phaseId.toString()}
      As={List.Item}
      onClick={() => props.setDisplayedProcessPhase(phaseId)}
      active={phaseId === displayedPhaseId}
      isDragDisabled={Process.isFinished(activeProcess)
        || isDragDisabled
        || Authorizer.isResourceViewer(activeProcess, user.id)}
    >
      <Segment className={`${borderClass} phase-widget-container disable-select`}>
        <div className='phase-widget-content'>
          <div>
            <div className={getIconWrapperClassName(isRunning)}>
              <Icon
                name={
                  Phase.isManuallyTransitioned(phase)
                    ? 'hand pointer outline'
                    : 'clock outline'
                }
                className={iconClass}
              />
            </div>
            {name}
          </div>
          {!isRunning
            && (
              <div className='phase-widget-trash-ellipsis-icons-wrapper'>
                <Popup
                  trigger={(
                    <div>
                      <Icon
                        role='button'
                        name='trash'
                        className={getIconClassName(showPopup, disableDeleteButton)}
                      />
                    </div>
                  )}
                  content={(
                    <Button
                      primary
                      onClick={handleClick}
                    >
                      Confirm
                    </Button>
                  )}
                  on='click'
                  onOpen={handleOpen}
                  onClose={handleClose}
                  open={showPopup}
                  position='left center'
                />
                <div className={`${getIconClassName(showPopup, disableDeleteButton)} drag-icon-container`}>
                  <Icon
                    style={{ width: '2px' }}
                    name='ellipsis vertical'
                  />
                  <Icon
                    style={{ width: '5px' }}
                    name='ellipsis vertical'
                  />
                </div>
              </div>
            )}
        </div>
        {isRunning && !isPausedPhase && renderRunningLoadingBar(isSelected)}
        {isPausedPhase && renderPausedLoadingBar(isSelected)}
      </Segment>
    </DraggablePortal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseTabDraggable)
