import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseProgression from '~/utils/process/PhaseProgression'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import Validator from '~/utils/validation/Validator'
import { Process } from '~/utils/process'

export default class PhaseGroupRenderer {

  static renderOriginalGroupPhases = process => {
    if (Process.isExecutable(process)
      || Process.isFinished(process)) return true

    return false
  }

  static getNextModifyableGroupPhaseIds = (process, runningGroup) => {
    const iterationData = PhaseGroupParser.getIterationData(process, runningGroup)
    const runningPhase = PhaseProgression.getLatest(process)
    const { iteration, phaseIds: iterationPhaseIds } = iterationData
      .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

    const nextIterationData = iterationData
      .find(({ iteration: nextIteration }) => nextIteration === iteration + 1)

    const runningPhaseExecutionIdx = iterationPhaseIds
      .findIndex(aPhaseId => aPhaseId === runningPhase.phaseId)

    return iterationPhaseIds.map(aPhaseId => {
      const phaseExecutionIdx = iterationPhaseIds.findIndex(iterPhaseId => iterPhaseId === aPhaseId)

      if (phaseExecutionIdx >= runningPhaseExecutionIdx) {
        return { orig: aPhaseId, replaceWith: aPhaseId }
      }

      if (!nextIterationData) {
        return { orig: aPhaseId, replaceWith: iterationData[0].phaseIds[phaseExecutionIdx] }
      }

      return { orig: aPhaseId, replaceWith: nextIterationData.phaseIds[phaseExecutionIdx] }
    })
  }

  static getGroupAdjustedPhaseSequence = process => {

    const runningGroup = Process.getRunningGroup(process)

    if (PhaseGroupRenderer.renderOriginalGroupPhases(process) || !runningGroup) {
      return DescriptionParser
        .getPhasesSequence(process)
        .filter(({ data: { iterationOf } }) => Validator.isUndefinedOrNull(iterationOf))
    }

    const nextModifyableGroupPhaseIds = PhaseGroupRenderer
      .getNextModifyableGroupPhaseIds(process, runningGroup)

    const phases = DescriptionParser
      .getPhasesSequence(process)
      .reduce((acc, aPhase) => {
        const { id, data: { iterationOf, groupName } } = aPhase

        if (!groupName) {
          acc.push(aPhase)
          return acc
        }

        if (groupName !== runningGroup) {
          if (Validator.isUndefinedOrNull(iterationOf)) acc.push(aPhase)
          return acc
        }

        const replacement = nextModifyableGroupPhaseIds.find(({ orig }) => orig === id)

        if (!replacement) return acc

        const replacementPhase = DescriptionParser
          .getPhaseById(process, replacement.replaceWith)
        acc.push({ id: replacement.replaceWith, data: replacementPhase })

        return acc
      }, [])

    return phases
  }

  static createProcessTreeElements = phases => {
    const elements = phases.reduce((tree, phase) => {
      const { data: { groupName } } = phase

      if (!groupName) {
        return [ ...tree, { ...phase, isGroup: false } ]
      }

      const groupEntry = tree.find(treeItem => treeItem.groupName === groupName)

      if (!groupEntry) {
        tree.push({ groupName, group: [ phase ], isGroup: true })
      } else {
        groupEntry.group.push(phase)
      }

      return tree
    }, [])

    return elements.map((element, idx) => {
      element.idx = idx

      if (!element.isGroup) return element

      element.group = element.group.map((phase, phaseIdx) => ({ ...phase, idx: phaseIdx }))
      return element
    })
  }
}
