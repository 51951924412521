import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import { Functionality, Slot } from '@ospin/fct-graph'
import Authorizer from '~/utils/Authorizer'
import nexus from '@ospin/nexus'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'
import DeletePopup from './DeletePopup'

function renderSlotAndValue (slotName, value) {
  return (
    <>
      <Table.Cell>
        {slotName}
      </Table.Cell>
      <Table.Cell>
        {value}
      </Table.Cell>
    </>
  )

}
function renderFloatSlot (slot, slotConfig) {
  const { defaultInputValue } = slotConfig
  const { unit } = slot
  const formattedName = `${Slot.getDisplayName(slot)} [${unit}]`

  return renderSlotAndValue(formattedName, defaultInputValue)
}

function renderBooleanSlot (slot, { defaultInputValue }) {
  const displayedCustomTarget = defaultInputValue ? 'on' : 'off'
  return renderSlotAndValue(Slot.getDisplayName(slot), displayedCustomTarget)
}

function renderSlotByExpectedValueType(slot, slotConfig) {
  switch (slot.dataType) {
    case 'boolean':
      return renderBooleanSlot(slot, slotConfig)
    default:
      return renderFloatSlot(slot, slotConfig)
  }
}

export default function SlotsDefaultTableRow({
  fct,
  activeDevice,
  setFctsConfigs,
  fctsConfigs,
  user,
  slotConfig,
  slotsPerFct,
  index,
  fctGraph,
}) {

  const [isLoading, setIsLoading] = useState(false)

  async function deleteSlotDefaultInputValue (slotName) {
    const updates = [{
      fctId: fct.id,
      slotsConfigs: [{ name: slotName, defaultInputValue: null }],
    }]
    setIsLoading(true)

    try {
      const { data: updatedFctsConfigs } = await nexus.device.functionalityGraph
        .functionalityConfiguration.updateMany({
          deviceId: activeDevice.id,
          fctGraphId: fctGraph.id,
          updates,
        })
      const mergedFctConfigs = FunctionalityConfiguration.mergeMany(fctsConfigs, updatedFctsConfigs)
      setFctsConfigs(mergedFctConfigs)
    } catch (_) {
      setIsLoading(false)
    }
  }

  const { name } = slotConfig
  const displayedFctName = fct.name
  const slot = Functionality.getSlotByName(fct, name)

  return (
    <Table.Row key={fct.id + slot.name}>
      {index === 0 && <Table.Cell rowSpan={slotsPerFct} content={displayedFctName}/>}
      {renderSlotByExpectedValueType(slot, slotConfig)}
      <Table.Cell>
        <DeletePopup
          loading={isLoading}
          confirmHandler={() => deleteSlotDefaultInputValue(name)}
          disabled={!Authorizer.user(user).hasEditRights(activeDevice) || isLoading}
          iconName='close'
          position='right center'
        />
      </Table.Cell>
    </Table.Row>
  )
}
