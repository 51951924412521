import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import FeatureVersioning from '~/utils/FeatureVersioning'
import { setSortByQueryParamsOnClick } from '~/utils/query'
import ProcessRow from './ProcessRow'
import ProcessControlPagination from './ProcessControlPagination'

import './ProcessesTable.css'
import ProcessControls from './ProcessControls'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const processTableHeaderItems = [
  { header: 'Action', sortKey: null },
  { header: 'Name', sortKey: 'name' },
  { header: 'Clone History', sortKey: null },
  { header: 'State', sortKey: 'state' },
  { header: 'Created', sortKey: 'createdAt' },
  { header: 'Finished', sortKey: 'finishedAt' },
  { header: 'Duration', sortKey: null },
  { header: 'Select', sortKey: null },
]

function ProcessesTable({
  selectedIds,
  openModalHandler,
  deviceUsers,
  processes,
  processClickHandler,
  selectProcessForAction,
  user,
  activeDevice,
  showProcessDetailsModal,
  location,
  activePage,
  totalPages,
  updateActivePage,
  totalMatchingProcesses,
  processesPerPage,
  history,
  allSelected,
  handleSelectAll,
  toggleModal,
  isFavoriteProcessesOnly,
}) {

  const generateHeaderItems = () => {
    if (!FeatureVersioning.supportsMultipleProcesses(activeDevice)) {
      return processTableHeaderItems
    }

    return [
      ...processTableHeaderItems.slice(0, 2),
      { header: 'Configuration', sortKey: null },
      ...processTableHeaderItems.slice(2),
    ]
  }

  const getSortIcon = sortKey => {
    const query = new URLSearchParams(location.search)
    const querySortBy = query.get('sortBy')

    if (!sortKey || sortKey !== querySortBy) { return null }

    return (
      <Icon
        name={query.get('sortOrder') === 'ASC' ? 'sort ascending' : 'sort descending'}
        className='processes-table-sort-icon'
      />
    )
  }

  function renderPaginationAndControls() {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <ProcessControlPagination
          activePage={activePage}
          totalPages={totalPages}
          updateActivePage={updateActivePage}
          totalMatchingProcesses={totalMatchingProcesses}
          processesPerPage={processesPerPage}
        />
        <ProcessControls
          totalPages={totalPages}
          selectedIds={selectedIds}
          allSelected={allSelected}
          handleSelectAll={handleSelectAll}
          toggleModal={toggleModal}
        />
      </div>
    )
  }

  return (
    <>
      { isFavoriteProcessesOnly ? null : renderPaginationAndControls()}
      <div style={{ overflowX: 'scroll', marginBottom: '16px' }}>
        <Table className='ospin-red' celled selectable padded sortable>
          <Table.Header fullWidth>
            <Table.Row>
              {
                generateHeaderItems().map(({ header, sortKey }) => (
                  <Table.HeaderCell
                    key={header}
                    onClick={sortKey ? () => setSortByQueryParamsOnClick(history, location, sortKey) : null}
                    className={sortKey ? 'processes-table-clickable-header' : 'processes-table-non-clickable-header'}
                  >
                    { header }
                    { getSortIcon(sortKey) }
                  </Table.HeaderCell>
                ))
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {processes
              .map((process, i) => {

                const selected = selectedIds.includes(process.id)

                return (
                  <ProcessRow
                    key={process.id}
                    checked={selected}
                    process={process}
                    processIndex={i}
                    selectHandler={() => selectProcessForAction(process.id)}
                    processClickHandler={processClickHandler}
                    openModalHandler={openModalHandler}
                    user={user}
                    deviceUsers={deviceUsers}
                    activeDevice={activeDevice}
                    showProcessDetailsModal={showProcessDetailsModal}
                  />
                )
              })}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}
export default withRouter(connect(mapStateToProps, null)(ProcessesTable))
