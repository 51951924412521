import React, { useState, useRef } from 'react'
import { Modal, Button, List, Label, Segment } from 'semantic-ui-react'
import Time from '~/utils/Time'
import DescriptionParser from '~/utils/process/DescriptionParser'
import LoadingImage from '~/images/loadingIcon.png'

export default function ImageModal(props) {
  const {
    imgRefs,
    showImageModal,
    focusedImgRef,
    setFocusedImgRef,
    setShowImageModal,
    activeProcess,
  } = props

  const [hasError, setHasError] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [placeHolderHeight, setPlaceHolderHeight] = useState(600)

  const index = imgRefs.findIndex(ref => ref.id === focusedImgRef?.id)
  const img = useRef()

  React.useEffect(() => {
    function resizePlaceholderDiv() {
      if (img.current) {
        setPlaceHolderHeight(img.current.offsetHeight)
      }
    }
    window.addEventListener('resize', resizePlaceholderDiv)
    return function cleanup () {
      window.removeEventListener('resize', resizePlaceholderDiv)
    }
  })

  if (!focusedImgRef) {
    return null
  }

  function nextImage() {
    setFocusedImgRef(imgRefs[index - 1])
    setHasLoaded(false)
  }

  function previousImage() {
    setFocusedImgRef(imgRefs[index + 1])
    setHasLoaded(false)
  }

  function setImage() {
    setPlaceHolderHeight(img.current.offsetHeight)
    setHasLoaded(true)
  }

  function closeModal() {
    setShowImageModal(false)
    setHasLoaded(false)
  }

  function renderImageOrLoader() {
    return (
      <div style={{ height: `${placeHolderHeight}px` }}>
        <img
          src={LoadingImage}
          alt='loadingIcon'
          style={{
            position: 'absolute', zIndex: 0, top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          }}
        />
        <img
          style={{
            objectFit: 'contain',
            maxHeight: '80vh',
            visibility: hasLoaded ? 'inherit' : 'hidden',
          }}
          data-testid={focusedImgRef.id}
          id={focusedImgRef.id}
          ref={img}
          src={focusedImgRef.previewUrl}
          alt='of the process'
          onError={() => setHasError(true)}
          onLoad={() => setImage()}
          className='ui fluid image'
        />
      </div>
    )
  }

  function displayMetadata(imgRef) {
    const phaseName = DescriptionParser.getPhaseById(activeProcess, imgRef.phaseId).name

    return (
      <List divided horizontal relaxed>
        <List.Item>
          <Label basic horizontal content='time:' />
          <b>{Time.getTrimmedDateAndTimeString(imgRef.imageCreatedAt)}</b>
        </List.Item>
        <List.Item>
          <Label basic horizontal content='phase name:' />
          <b>{phaseName}</b>
        </List.Item>
        <List.Item>
          <Label basic horizontal content='stretcher value:' />
          <b>{imgRef.metaData.stretcherValue}</b>
        </List.Item>
      </List>
    )
  }

  if (hasError) {
    throw new Error(`Image ID: ${focusedImgRef.id} could not be loaded, our team has been alerted !`)
  }

  return (
    <div>
      <Modal
        open={showImageModal}
        onClose={() => closeModal()}
        size='large'
        modal='inverted'

      >
        <Segment style={{ marginTop: '0px' }}>
          {renderImageOrLoader()}
          <hr />
          {displayMetadata(focusedImgRef)}
          <hr />
          <Modal.Actions>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={() => closeModal()}
                content='close'
              />
              <div>
                <a href={focusedImgRef.previewUrl} target='_blank' rel='noreferrer'>
                  <Button content='open in new tab' />
                </a>
                <a href={focusedImgRef.downloadUrl}>
                  <Button primary icon='download' />
                </a>
              </div>
              <div>
                <Button
                  disabled={(index + 1) === (imgRefs.length)}
                  onClick={() => previousImage()}
                  content='previous'
                />
                <Button
                  disabled={index === 0}
                  onClick={() => nextImage()}
                  content='next'
                />
              </div>
            </div>
          </Modal.Actions>
        </Segment>
      </Modal>
    </div>
  )
}
