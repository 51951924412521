import nexus from '@ospin/nexus'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Popup, Icon } from 'semantic-ui-react'
import { setProcessBuilderValidationErrors } from '~/redux/actions/actions'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import DeviceValidator from '~/utils/validation/DeviceValidator'
import FlashMessenger from '~/utils/FlashMessenger'

const mapDispatchToProps = dispatch => ({
  dispatchSetProcessBuilderValidationErrors: errors => (
    dispatch(setProcessBuilderValidationErrors({ errors }))
  ),
  dispatchCallUpdatedProcess: (processId, params) => (
    callUpdateProcess(dispatch, processId, params)
  ),
})

const StartProcessButton = ({
  activeProcess,
  activeDevice,
  dispatchCallUpdatedProcess,
  dispatchSetProcessBuilderValidationErrors,
}) => {

  const [processStarting, setProcessStarting] = useState(false)

  const handleSaveProcess = async () => {

    const updatedProcess = {
      name: activeProcess.name,
      comment: activeProcess.comment,
      description: activeProcess.description,
      entryPhaseId: activeProcess.entryPhaseId,
    }
    /* TODO: ADD_ERROR_HANDLING */
    await dispatchCallUpdatedProcess(activeProcess.id, updatedProcess)
  }

  const handleExecuteClick = async () => {

    const errors = [
      ...ProcessValidator.validateDescription(activeProcess, activeDevice),
      ...DeviceValidator.isReadyToRunProcess(activeDevice),
    ]

    if (errors.length) {
      return dispatchSetProcessBuilderValidationErrors(errors)
    }

    setProcessStarting(true)
    await handleSaveProcess()

    try {
      await nexus.command.device.process.start(activeDevice.id, activeProcess.id)
      dispatchSetProcessBuilderValidationErrors([])
    } catch (e) {
      switch (e.status) {
        case 413: {
          const tooLargeError = ProcessValidator.createExceedingProcessSizeError()
          dispatchSetProcessBuilderValidationErrors([ tooLargeError ])
          break
        }
        default:
          FlashMessenger.error(e.message)
      }
    } finally {
      setProcessStarting(false)
    }
  }

  const processStartValidationErrors = ProcessValidator
    .validateStartProcess(activeProcess, activeDevice)

  const disableToolTip = processStarting
    ? !processStarting : processStartValidationErrors.length === 0

  const generateToolTipContent = () => {
    if (processStarting) {
      return 'The process is starting'
    }

    if (processStartValidationErrors.length) {
      return processStartValidationErrors[0].message
    }
    return ''
  }

  return (
    <Popup
      trigger={(
        <span>
          <Button
            className='start-stop-control-button'
            primary
            size='small'
            icon
            labelPosition='left'
            loading={processStarting}
            onClick={handleExecuteClick}
            disabled={processStarting || processStartValidationErrors.length !== 0}
          >
            <Icon name='play' />
            Start
          </Button>
        </span>
      )}
      disabled={disableToolTip}
      content={generateToolTipContent()}
      position='bottom center'
    />
  )
}

export default connect(null, mapDispatchToProps)(StartProcessButton)
