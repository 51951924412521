import { findAndUpdate } from '~/redux/helper/updateFunctions'

export default (state, { payload: { deviceId, online } }) => {

  const { devices } = state

  const updateFn = targetDevice => Object.assign(targetDevice, { online })

  const updatedDevices = findAndUpdate(deviceId, devices, [ updateFn ])
  return { ...state, devices: updatedDevices }
}
