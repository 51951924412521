import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { addUserToGroup, removeUserFromGroup } from '~/utils/access'
import Device from '~/utils/Device'

export default (state, action) => {
  const { devices, deviceUsers } = state
  const { deviceId, userId, oldGroup, newGroup } = action.payload

  const updateFnAddToGroup = device => addUserToGroup(device, newGroup, userId)
  const updateFnRemoveFromGroup = device => removeUserFromGroup(device, oldGroup, userId)
  const updatedDevices = findAndUpdate(deviceId, devices, [ updateFnAddToGroup, updateFnRemoveFromGroup ])

  const updateFnDeviceUsers = deviceUser => Device.updateDeviceUserGroup(deviceUser, newGroup)
  const updatedDeviceUsers = findAndUpdate(userId, deviceUsers, [ updateFnDeviceUsers ])

  return { ...state, devices: updatedDevices, deviceUsers: updatedDeviceUsers }
}
