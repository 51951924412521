import React, { Component } from 'react'
import { FCTGraph, Slot } from '@ospin/fct-graph'
import modalize from '~/components/utility/modal/modalize'
import { Form, Input, Message, Divider, Button, Table, Checkbox } from 'semantic-ui-react'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'

import nexus from '@ospin/nexus'

class SetDataPrecisionModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSuccess: false,
      targetSlotsDisplayedDecimalPlaces: '',
      targetUpdates: this.getEmptyTargetUpdates(),
      targetFctId: null,
    }
  }

  handleUpdateFct = (e, { name, value: fctId }) => {
    this.setState({
      [name]: fctId,
      isError: false,
      errorMessage: '',
      isSuccess: false,
      targetUpdates: this.getEmptyTargetUpdates(),
    })
  }

  getEmptyTargetUpdates = () => {
    const { filteredFctMap } = this.props
    const blankTargetUpdates = {}
    Object.keys(filteredFctMap).forEach(fct => { blankTargetUpdates[fct] = [] })
    return blankTargetUpdates
  }

  handleUpdateSlot = (selectedSlotName, fctId) => {
    const { targetUpdates } = this.state

    const slotIsAlreadySelected = targetUpdates[fctId].includes(selectedSlotName)
    const selectedSlotsForFct = targetUpdates[fctId]
    const newTargetSlots = targetUpdates

    if (slotIsAlreadySelected) {
      newTargetSlots[fctId] = selectedSlotsForFct.filter(slotname => slotname !== selectedSlotName)
    } else {
      newTargetSlots[fctId].push(selectedSlotName)
    }

    this.setState({
      targetUpdates: newTargetSlots,
      isError: false,
      errorMessage: '',
      isSuccess: false,
    })
  }

  getFunctionalityOptions = () => {
    const { filteredFcts } = this.props

    return filteredFcts
      .map(fct => ({
        value: fct.id,
        text: fct.name,
        key: fct.id,
      }))
  }

  handleDisplayedDecimalPlacesUpdate = (e, { value }) => {
    if (value < 0) { return }
    this.setState({
      targetSlotsDisplayedDecimalPlaces: value === '' ? '' : Math.floor(value),
      isError: false,
      errorMessage: '',
      isSuccess: false,
    })
  }

  hasSelectedAtLeast1Slot = () => {
    const { targetUpdates } = this.state
    const slots = Object.values(targetUpdates).flat()
    return slots.length !== 0
  }

  allSlotsSelected = () => {
    const { targetUpdates, targetFctId } = this.state
    const { filteredFctMap } = this.props

    if (targetFctId) {
      return targetUpdates[targetFctId].length === filteredFctMap[targetFctId].length
    }

    return Object.values(targetUpdates).flat().length
      === Object.values(filteredFctMap).flat().length

  }

  handleSelectAll = () => {
    const { filteredFctMap } = this.props
    const { targetFctId } = this.state
    if (this.allSlotsSelected()) {
      this.setState({ targetUpdates: this.getEmptyTargetUpdates() })
      return
    }
    this.setState({
      targetUpdates: targetFctId
        ? { [targetFctId]: filteredFctMap[targetFctId] }
        : { ...filteredFctMap },
    })

  }

  renderFunctionality = fctId => {
    const { filteredFctMap, selectedGraph } = this.props
    const slots = FCTGraph.getFctById(selectedGraph, fctId)
      .slots.filter(slot => filteredFctMap[fctId].includes(slot.name))

    return this.renderSlots(slots, fctId)
  }

  renderSlots = (slots, fctId) => {
    const {
      fctsConfigs,
      getDisplayedDecimalValueForSlotOrNull,
      filteredFctMap,
      selectedGraph,
    } = this.props
    const { targetUpdates } = this.state

    const fct = FCTGraph.getFctById(selectedGraph, fctId)

    const currentSetting = slot => getDisplayedDecimalValueForSlotOrNull(
      { fctId, slotname: slot.name, fctsConfigs },
    )

    const slotCount = filteredFctMap[fctId].length

    const selectedSlots = targetUpdates[fctId]

    return (
      <Table.Body key={fctId}>
        {
          slots.map(((slot, idx) => (
            <Table.Row key={`${slot.name}-${fctId}`}>
              {idx === 0 && <Table.Cell content={fct.name} rowSpan={slotCount} />}
              <Table.Cell content={Slot.getDisplayName(slot)} />
              <Table.Cell content={slot.unit} />
              <Table.Cell content={Number.isInteger(currentSetting(slot)) ? currentSetting(slot) : '-'} />
              <Table.Cell content={(
                <Checkbox
                  toggle
                  checked={selectedSlots.includes(slot.name)}
                  onChange={() => this.handleUpdateSlot(slot.name, fctId)}
                  data-testid={`${slot.name}-toggle`}
                />
              )}
              />
            </Table.Row>
          )))
        }
      </Table.Body>
    )
  }

  renderTable = () => {
    const { targetFctId } = this.state
    const { filteredFctMap } = this.props

    const headers = ['Functionality', 'Channel', 'Unit', 'Current Setting', '']

    const renderableFct = targetFctId ? [targetFctId] : Object.keys(filteredFctMap)

    return (
      <>
        <Button.Group
          floated='right'
          style={{ paddingBottom: '1rem' }}
          content={(
            <Button
              primary
              content={this.allSlotsSelected() ? 'Deselect All' : 'Select All'}
              onClick={this.handleSelectAll}
              type='button'
            />
          )}
        />
        <Table className='ospin-red' celled structured fixed>
          <Table.Header>
            <Table.Row key='header'>
              {headers.map(text => (
                <Table.HeaderCell key={text} content={text} />
              ))}
            </Table.Row>
          </Table.Header>
          {renderableFct && renderableFct.map(fctId => this.renderFunctionality(fctId))}
        </Table>
      </>
    )
  }

  renderSlotDisplayedDecimal = () => {
    const { targetSlotsDisplayedDecimalPlaces } = this.state

    return (
      <Form.Field content={(
        <Input
          label='new data precision'
          fluid
          name='targetSlotsDisplayedDecimalPlaces'
          type='number'
          required
          value={targetSlotsDisplayedDecimalPlaces}
          onChange={this.handleDisplayedDecimalPlacesUpdate}
        />
      )}
      />
    )
  }

  generateRequest = () => {
    const { targetUpdates, targetSlotsDisplayedDecimalPlaces } = this.state
    const requests = []
    const fctIds = Object.keys(targetUpdates)
    fctIds.forEach(fctId => {
      if (targetUpdates[fctId].length) {
        requests.push({
          fctId,
          slotsConfigs: [
            ...targetUpdates[fctId].map(slotname => ({
              name: slotname,
              displayedDecimalPlaces: targetSlotsDisplayedDecimalPlaces,
            })),
          ],
        })
      }
    })
    return requests
  }

  submitDisplayedDecimalPlaces = async () => {
    const {
      activeDevice,
      setFctsConfigs,
      fctsConfigs,
      selectedGraph,
    } = this.props

    const updates = this.generateRequest()

    this.setState({ isLoading: true })

    try {
      const { data: updatedFctsConfigs } = await nexus.device.functionalityGraph
        .functionalityConfiguration.updateMany({
          deviceId: activeDevice.id,
          fctGraphId: selectedGraph.id,
          updates,
        })
      const mergedFctConfigs = FunctionalityConfiguration.mergeMany(fctsConfigs, updatedFctsConfigs)
      setFctsConfigs(mergedFctConfigs)
      this.setState({
        isSuccess: true,
        isError: false,
        errorMessage: '',
        isLoading: false,
        targetUpdates: this.getEmptyTargetUpdates(),
        targetSlotsDisplayedDecimalPlaces: '',
      })
    } catch (e) {
      this.setState({
        isError: true,
        errorMessage: e.message,
        isSuccess: false,
        isLoading: false,
      })
    }
  }

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  validForSubmission = () => {
    const { targetSlotsDisplayedDecimalPlaces } = this.state
    return Number.isInteger(targetSlotsDisplayedDecimalPlaces) && this.hasSelectedAtLeast1Slot()
  }

  render() {

    const {
      isError,
      errorMessage,
      isLoading,
      isSuccess,
      targetFctId,
    } = this.state

    const functionalityOptions = this.getFunctionalityOptions()

    return (
      <div>
        <Form
          error={isError}
          success={isSuccess}
          style={{ marginBottom: '15px', paddingBottom: '15px' }}
        >
          <Form.Select
            fluid
            name='targetFctId'
            label='Filter by Functionality'
            value={targetFctId}
            options={functionalityOptions}
            onChange={this.handleUpdateFct}
            placeholder='Choose Functionality'
            data-testid='fctSelectionField'
            clearable
          />
          {this.renderTable()}
          <Form.Group widths='equal'>
            {this.renderSlotDisplayedDecimal()}
          </Form.Group>
          <Message
            error
            header='Something went wrong.'
            content={errorMessage}
            icon='warning circle'
          />
          <Message
            success
            header='Success!'
            content={<p>Data precision was set.</p>}
            icon='warning circle'
          />
          <Divider />
          <Button
            disabled={!this.validForSubmission()}
            floated='right'
            primary
            type='submit'
            loading={isLoading}
            content='Submit'
            onClick={this.submitDisplayedDecimalPlaces}
          />
          <Button
            floated='right'
            onClick={this.clearStateAndClose}
            content='Close'
          />
        </Form>
      </div>
    )
  }
}

export default (modalize(SetDataPrecisionModal))
