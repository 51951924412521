import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Loader, Message } from 'semantic-ui-react'
import callLoadProcessFunctionalityImages from '~/redux/thunks/process/callLoadProcessFunctionalityImages'

import ImageTable from './ImageTable'

function mapStateToProps(state, ownProps) {
  const { activeProcess, fctId } = ownProps
  const images = state.processFctsImgs?.[activeProcess.id]?.[fctId]
  return { imgRefs: images }

}

const mapDispatchToProps = dispatch => ({
  dispatchCallLoadProcessFunctionalityImages: (processId, reporterFctId) =>
    callLoadProcessFunctionalityImages(dispatch, processId, reporterFctId),
})

function ImageViewer({
  activeProcess,
  fctId,
  dispatchCallLoadProcessFunctionalityImages,
  imgRefs,
}) {

  useEffect(() => {
    /* TODO: ADD_ERROR_HANDLING */
    dispatchCallLoadProcessFunctionalityImages(activeProcess.id, fctId)
  }, [ fctId, dispatchCallLoadProcessFunctionalityImages ])

  function renderImageTable(refs) {
    if (!refs) {
      return (<Loader> Loading Images </Loader>)
    }
    if (refs.length === 0) {
      return (
        <div style={{ width: '100%' }}>
          <Message header='No images found for this camera' />
        </div>
      )
    }
    return (<ImageTable imgRefs={refs} activeProcess={activeProcess} />)
  }

  return (
    <Grid>
      <Grid.Row style={{ paddingBottom: 0 }}>
        {renderImageTable(imgRefs)}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingLeft: '48px',
          }}
        />
      </Grid.Row>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer)
