import React from 'react'
import { Modal, Form, Button, Message, List } from 'semantic-ui-react'
import { connect } from 'react-redux'
import FunctionalityDefinitions from '~/utils/functionalities/FunctionalityDefinitions'
import TemplateUtils from '../Utils/TemplateUtils'
import TemplateFctDescription from './ModalContent/TemplateFctDescription'
import FunctionalitySlotTable from './ModalContent/FunctionalitySlotTable'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })

const templateChangesAreValid = template => {
  const nonEmptyFctNames = template.functionalities.every(({ props }) => !!props.name)
  const nonEmptySlotNames = template.functionalities.every(({ props }) => {
    if (!props.slots) { return true }
    return props.slots.every(slot => !!slot.displayName)
  })

  return { fctNameValid: nonEmptyFctNames, slotNamesValid: nonEmptySlotNames }
}

function renderValidationWarning(val) {
  return (
    <Message error>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <b>Changes can not be applied due to the following errors</b>
        <List bulleted>
          {!val.fctNameValid ? <List.Item> The Device has an empty name </List.Item> : ''}
          {!val.slotNamesValid ? <List.Item> At least one Input or Output has an empty name </List.Item> : ''}

        </List>

      </div>

    </Message>
  )

}

function FunctionalityInformationModal({
  close,
  template,
  targetPosId,
  updateTemplate,
  functionalityDefinitions,
}) {

  const targetFct = TemplateUtils.getFctByPosId(template, { posId: targetPosId })
  const validation = templateChangesAreValid(template)
  const isValid = Object.values(validation).every(val => val)
  function setFctName(newName) {
    const updatedTemplate = TemplateUtils.renameFunctionality(
      template,
      { posId: targetPosId, name: newName },
    )
    updateTemplate(updatedTemplate)
  }

  const { slots } = FunctionalityDefinitions.findBySubType(
    functionalityDefinitions,
    targetFct.subType
  )

  function closeIfValid() {
    if (!isValid) { return }

    close()
  }

  return (
    <Modal
      open={targetPosId !== null}
      onClose={() => closeIfValid()}
      onClick={event => event.stopPropagation()}
    >
      <Modal.Header>
        <div>
          <Form.Input fluid label='Name' value={targetFct.props.name} onChange={(_, { value }) => setFctName(value)} />
        </div>
      </Modal.Header>
      <Modal.Content>
        {!isValid ? renderValidationWarning(validation) : null}
        <TemplateFctDescription functionality={targetFct} />

        <FunctionalitySlotTable
          targetPosId={targetPosId}
          template={template}
          updateTemplate={updateTemplate}
          slots={slots}
          type='InSlots'
        />
        <FunctionalitySlotTable
          targetPosId={targetPosId}
          template={template}
          updateTemplate={updateTemplate}
          slots={slots}
          type='OutSlots'
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeIfValid()} disabled={!isValid} content='Close' />
      </Modal.Actions>

    </Modal>
  )
}

export default connect(mapStateToProps, null)(FunctionalityInformationModal)
