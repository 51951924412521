/* 1. Bit View
 * 2. Bit Edit
 * 3. Bit Operate
 * 4. Bit Delete
 * 5. Bit Manage Access
 */

class Authorizer {

  NO_RIGHTS_TOKEN = '00000'

  static viewBitSet = rights => rights[0] === '1'

  static editBitSet = rights => rights[1] === '1'

  static operateBitSet = rights => rights[2] === '1'

  static deleteBitSet = rights => rights[3] === '1'

  static manageAccessBitSet = rights => rights[4] === '1'

  static isOwnerOf = (resource, userId) => resource.ownerId === userId

  static getUserGroup = (resource, userId) => (
    resource.access
      .find(group => group.users.includes(userId))
  )

  static isInResourceGroup = (resource, userId, groupName) => {
    const userGroup = this.getUserGroup(resource, userId)
    if (!userGroup) return false
    return userGroup.name === groupName
  }

  static isResourceAdmin = (resource, userId) => (
    Authorizer.isInResourceGroup(resource, userId, 'admins')
  )

  static isResourceOperator = (resource, userId) => (
    Authorizer.isInResourceGroup(resource, userId, 'operators')
  )

  static isResourceViewer = (resource, userId) => (
    Authorizer.isInResourceGroup(resource, userId, 'viewers')
  )

  static isResourceSupport = (resource, userId) => (
    Authorizer.isInResourceGroup(resource, userId, 'support')
  )

  static userHasSuperDeviceAccess = (device, user) => (
    Authorizer.hasApplicationAdminAccess(user)
      || Authorizer.isResourceAdmin(device, user.id)
      || Authorizer.isResourceSupport(device, user.id)
  )

  static userHasDeviceNameAccess = (device, user) => (
    Authorizer.isResourceAdmin(device, user.id)
    || Authorizer.isResourceSupport(device, user.id)
    || Authorizer.user(user).hasApplicationDeveloperAccess()
  )

  static userHasFctGraphNameAccess = (device, user) => (
    Authorizer.userHasDeviceNameAccess(device, user)
  )

  static userHasSuperProcessAccess = (process, device, user) => Authorizer.userHasSuperDeviceAccess(device, user)
    || Authorizer.isResourceAdmin(process, user.id)

  static hasApplicationAdminAccess = user => user.role === 'admin'

  static getUserGroupRights = (resource, userId) => {
    const userGroup = this.getUserGroup(resource, userId)
    if (!userGroup) {
      return this.NO_RIGHTS_TOKEN
    }
    return userGroup.rights
  }

  static user = user => ({
    isOwnerOf: resource => this.isOwnerOf(resource, user.id),

    hasApplicationDeveloperAccess: () => user.role === 'developer' || user.role === 'admin',

    hasEditRights: resource => {
      const userRights = this.getUserGroupRights(resource, user.id)
      if (!userRights) return false
      return this.editBitSet(userRights)
    },

    hasExecuteRights: resource => {
      const userRights = this.getUserGroupRights(resource, user.id)
      if (!userRights) return false
      return this.operateBitSet(userRights)
    },

    hasManageAccessRights: resource => {
      const userRights = this.getUserGroupRights(resource, user.id)
      if (!userRights) return false
      return this.manageAccessBitSet(userRights)
    },

    canModifyAccessOf: targetUser => ({
      to: resource => {

        /* Conditions to be able to modify the access of somebody
         * 1. You are an application admin
         * 2. The user is NOT me (you can't modify your own rights)
         * 3. You are the owner (then you can do everything)
         * 4. You have the correct authorization level and the target user CANNOT modify
         *    access rights on this resource (users are considered to be equal otherwise)
         */

        if (this.isOwnerOf(resource, targetUser.id)) return false
        if (user.role === 'admin') return true
        if (user.id === targetUser.id) return false
        if (this.isOwnerOf(resource, user.id)) return true

        const userRights = this.getUserGroupRights(resource, user.id)
        if (!userRights) return false
        const targetUserRights = this.getUserGroupRights(resource, targetUser.id)
        return this.manageAccessBitSet(userRights) && !this.manageAccessBitSet(targetUserRights)
      },
    }),

    isDeviceAdminOf: device => Authorizer.isResourceAdmin(device, user.id),
  })

  static getAllUserIds(resource) {
    return resource.access.reduce((acc, group) => [ ...acc, ...group.users ], [])
  }

  static isProcessOwner({ ownerId }, userId) { return ownerId === userId }

  static isCurrentLoggedInUser = ({ id }, userId) => (id === userId)

}

export default Authorizer
