import React from 'react'
import { withRouter } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'

import './ProcessesQueryBar.css'

const sortByOptions = [
  { text: 'Name', value: 'name' },
  { text: 'State', value: 'state' },
  { text: 'Created', value: 'createdAt' },
  { text: 'Finished', value: 'finishedAt' },
]

const sortDirectionOptions = [
  { text: 'Descending', value: 'DESC' },
  { text: 'Ascending', value: 'ASC' },
]

const ProcessesSortMenu = ({
  location,
  updateQueryParameters,
}) => {

  const query = new URLSearchParams(location.search)
  const sortBy = query.get('sortBy')
  const sortOrder = query.get('sortOrder')

  return (
    <>
      <span className='sort-filter-margin-horizontal'>
        <label className='text-label'>
          Sort by :
        </label>
        <Dropdown
          icon='angle down'
          selection
          options={sortByOptions}
          aria-label='sortByQuery'
          value={sortBy}
          name='sortByQuery'
          className='sort-dropdown'
          onChange={(_, { value }) => updateQueryParameters([{ key: 'sortBy', value }, { key: 'skip', value: 0 }])}
        />
      </span>
      <span className='sort-filter-margin-horizontal'>
        <label className='text-label'>
          Sort Order :
        </label>
        <Dropdown
          icon='angle down'
          selection
          options={sortDirectionOptions}
          aria-label='sortByDirection'
          name='sortByDirection'
          value={sortOrder}
          className='sort-dropdown'
          onChange={(_, { value }) => updateQueryParameters([{ key: 'sortOrder', value }, { key: 'skip', value: 0 }])}
        />
      </span>
    </>
  )
}

export default withRouter(ProcessesSortMenu)
