import React, { useState } from 'react'
import { Button, Divider, Form, Input, Label, Message, Modal, TextArea } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { formatDateTimeForAnnotations } from '~/utils/GraphTimeStampsFormatter'
import { Process } from '~/utils/process'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import callDeleteAnnotation from '~/redux/thunks/process/callDeleteAnnotation'
import callCreateAnnotation from '~/redux/thunks/process/callCreateAnnotation'
import callUpdateAnnotation from '~/redux/thunks/process/callUpdateAnnotation'

const { VALID_ANNOTATION_DATA: { textMaxLength } } = ProcessValidator

function getAnnotation(activeProcess, annotationId) {
  return activeProcess.annotations.find(({ id }) => id === annotationId)
}

const mapDispatchToProps = dispatch => ({
  dispatchCallDeleteAnnotation: (process, annotationId) => callDeleteAnnotation(
    dispatch,
    process,
    annotationId,
  ),
  dispatchCallCreateAnnotation: (process, data) => callCreateAnnotation(dispatch, process, data),
  dispatchCallUpdateAnnotation: (annotationId, process, data) => callUpdateAnnotation(
    dispatch,
    annotationId,
    process,
    data,
  ),
})

function LineChartAnnotationModal({
  activeProcess,
  mode,
  annotationId,
  setAnnotationModalProps,
  targetTimeStamp,
  timeStampsMode,
  dispatchCallCreateAnnotation,
  dispatchCallUpdateAnnotation,
  dispatchCallDeleteAnnotation,
}) {
  const shouldShow = !!mode
  const isEdit = mode === 'edit'
  const targetAnnotation = isEdit ? getAnnotation(activeProcess, annotationId) : {}

  const [annotation, setAnnotation] = useState({
    text: '',
    userTimestamp: targetTimeStamp,
    ...targetAnnotation,
  })
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  async function handleSubmit(fn) {
    setIsLoading(true)
    setError(null)
    try {
      await fn()
      setAnnotationModalProps({ mode: null })
    } catch ({ message }) {
      setError(message)
      setIsLoading(false)
    }
  }

  function updateAnnotationText(newText) {
    setAnnotation({
      ...annotation,
      text: newText,
    })
  }

  const relativeTimeStampInS = (annotation.userTimestamp - activeProcess.startedAt) / 1000
  const formattedTimeStamp = formatDateTimeForAnnotations(
    Process.getElapsedProcessTimeInMs(activeProcess),
    timeStampsMode,
    activeProcess,
  )(relativeTimeStampInS)

  function renderActionButton() {
    if (mode === 'create') {
      return (
        <Button
          floated='right'
          content='Create'
          onClick={() => handleSubmit(() => dispatchCallCreateAnnotation(
            activeProcess,
            {
              text: annotation.text,
              userTimestamp: annotation.userTimestamp,
            },
          ))}
          loading={isLoading}
          primary
          disabled={!ProcessValidator.isValidAnnotationComment(annotation.text)}
        />
      )
    }

    return (
      <Button
        floated='right'
        content='Update'
        primary
        onClick={() => handleSubmit(() => dispatchCallUpdateAnnotation(
          annotation.id,
          activeProcess,
          {
            text: annotation.text,
            userTimestamp: annotation.userTimestamp,
          },
        ))}
        loading={isLoading}
        disabled={!ProcessValidator.isValidAnnotationComment(annotation.text)}
      />
    )
  }

  function renderContent() {
    if (showDeleteConfirmation) {
      return (
        <>
          <Modal.Description>
            <Message
              content={`The annotation '${annotation.text}' and all data related to it will be deleted unrecoverably`}
              error
              visible
              header='This action is irreversible'
              style={{ marginBottom: '12px' }}
            />
            Are you sure you want to delete the annotation?
          </Modal.Description>
          <Divider />
          <Modal.Actions style={{ paddingBottom: '2rem' }}>
            <Button
              floated='left'
              content='Cancel'
              onClick={() => setShowDeleteConfirmation(false)}
            />
            <Button
              primary
              floated='right'
              content='Delete'
              loading={isLoading}
              onClick={() => handleSubmit(() => dispatchCallDeleteAnnotation(
                activeProcess,
                annotation.id,
              ))}
            />
          </Modal.Actions>
        </>
      )
    }

    return (
      <Modal.Description>
        <Form>
          <Form.Field>
            <label>
              Time
            </label>
            <Input
              fluid
              value={formattedTimeStamp}
              name='timeStamps'
              readOnly
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor='annotationTextArea'>
              Note
            </label>
            <TextArea
              name='annotationTextArea'
              value={annotation.text}
              onChange={(_, { value }) => updateAnnotationText(value)}
              data-testid='create-annotation-text-area'
            />
            <Label>
              {annotation.text.length}
              /
              {textMaxLength}
            </Label>
          </Form.Field>
        </Form>
        <Divider />
        {error ? <Message error header='Something went wrong ' content={error} /> : null}
        <Modal.Actions style={{ paddingBottom: '2rem' }}>
          {isEdit
            ? (
              <Button
                floated='left'
                primary
                content='Delete'
                onClick={() => setShowDeleteConfirmation(true)}
              />
            ) : null}
          <Button
            onClick={() => { setAnnotationModalProps({ mode: null }) }}
            floated='right'
            content='Close'
          />
          {renderActionButton()}
        </Modal.Actions>
      </Modal.Description>
    )
  }

  return (
    <Modal open={shouldShow} onClose={() => setAnnotationModalProps({ mode: null })}>
      <Modal.Header>
        {isEdit ? 'Edit Annotation' : 'Create Annotation'}
      </Modal.Header>
      <Modal.Content>
        {renderContent()}
      </Modal.Content>

    </Modal>
  )
}
export default connect(null, mapDispatchToProps)(LineChartAnnotationModal)
