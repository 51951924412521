import nexus from '@ospin/nexus'
import { setDownloadRequests } from '~/redux/actions/actions'

const callLoadDownloadRequests = async (dispatch, processId) => {
  const { data: processDownloadRequests } = await nexus.dataPoints.downloadRequest.list(processId)
  const { data: pdfReportRequests } = await nexus.process.reports.list(processId)
  dispatch(setDownloadRequests({ requests: [...processDownloadRequests, ...pdfReportRequests] }))
}

export default callLoadDownloadRequests
