import store from '~/redux/store'
import { updateDevice } from '~/redux/actions/actions'

export default (deviceId, { runningProcesses }) => {
  store.dispatch(updateDevice({
    deviceId,
    params: {
      readyForCommand: true,
      runningProcesses,
    },
  }))
}
