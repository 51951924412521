import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { generatePaginationSentence } from '~/utils/pagination'
import './ProcessControls.css'

export default function ProcessControlPagination({
  activePage,
  totalPages,
  updateActivePage,
  totalMatchingProcesses,
  processesPerPage,
}) {

  const showPaginationAndSentence = totalMatchingProcesses > 1

  if (!showPaginationAndSentence) { return null }

  return (
    <div style={{ flexGrow: 1 }}>
      <Pagination
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={updateActivePage}
        siblingRange={1}
        boundaryRange={0}
        className='process-controls-pagination'
      />
      <span className='process-controls-pagination-sentence'>
        {generatePaginationSentence(activePage, totalMatchingProcesses, processesPerPage)}
      </span>
    </div>
  )
}
