import React from 'react'
import { Message } from 'semantic-ui-react'
import modalize from '~/components/utility/modal/modalize'
import DescriptionMismatchModalForm from '~/components/processviewer/modals/DescriptionMismatchModalForm'

const explanationMessage = 'This process\'s configuration is no longer compatible with the device\'s configuration. As a result, you can only view this process. Please create a new process if you want to run the device.'

const DescriptionMismatchModal = ({
  activeDevice,
  closeHandler,
}) => (
  <>
    <Message
      info
      header='Process outdated!'
      content={explanationMessage}
    />
    <DescriptionMismatchModalForm
      closeHandler={closeHandler}
      activeDevice={activeDevice}
      showSubmitButton={false}
    />
  </>
)

export default modalize(DescriptionMismatchModal)
