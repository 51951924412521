import React, { useState, createRef } from 'react'
import nexus from '@ospin/nexus'
import { connect } from 'react-redux'
import { Table, Input, Button } from 'semantic-ui-react'
import { updateDevice } from '~/redux/actions/actions'
import Authorizer from '~/utils/Authorizer'
import FlashMessenger from '~/utils/FlashMessenger'
import './FctGraphNameTable.css'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => (
  { dispatchUpdateDevice: (deviceId, params) => dispatch(updateDevice({ deviceId, params })) }
)

const isFctGraphNameValid = newDeviceName => (
  newDeviceName.trim() !== ''
)

const FctGraphNameTableRow = ({
  selectedGraph,
  activeDevice,
  user,
  dispatchUpdateDevice,
  setIsError,
  setErrorMessage,
}) => {

  const [activeRow, setActiveRow] = useState(null)

  const active = activeRow === 'Name'
  const inputRef = createRef()
  const displayButton = !active && Authorizer.userHasFctGraphNameAccess(activeDevice, user)

  const submitNewFctGraphName = async e => {
    setActiveRow(null)

    if (!isFctGraphNameValid(e.target.value)) {
      FlashMessenger.warning('Name cannot be empty')
      return
    }

    try {
      const { data: { fctGraph } } = await nexus.device.functionalityGraph.update(
        activeDevice.id,
        selectedGraph.id,
        { name: e.target.value.trim() },
      )

      const updatedFctGraph = activeDevice.fctGraphs.map(fctG => (
        fctG.id === fctGraph.id ? fctGraph : fctG))

      dispatchUpdateDevice(activeDevice.id, { fctGraphs: updatedFctGraph })
      setIsError(false)
    } catch ({ message }) {
      setIsError(true)
      setErrorMessage(message)
    }
  }

  return (
    <Table.Row>
      <Table.Cell className='fct-graph-name-title'>
        Name
      </Table.Cell>
      <Table.Cell
        onClick={() => {
          setActiveRow('Name')
          inputRef.current.focus()
        }}
        className='fct-graph-name-table-cell'
      >
        <Input
          className='fct-graph-name-input'
          transparent
          ref={inputRef}
          style={{ opacity: 1, flexGrow: 1 }}
          disabled={!Authorizer.userHasFctGraphNameAccess(activeDevice, user)}
          defaultValue={selectedGraph.name}
          onBlur={e => submitNewFctGraphName(
            e,
          )}
        />
        {displayButton && (
          <Button
            className='fct-graph-name-edit-button'
            size='small'
            icon='edit'
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FctGraphNameTableRow)
