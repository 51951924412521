class CacheGroup {
  static getIsGroupExpanded = (activeProcess, groupName) => {
    const storageKey = `${activeProcess.id}:${groupName}`
    const state = localStorage.getItem(storageKey)

    if (state === null) {
      return true
    }

    return state.toLowerCase() !== 'false'
  }

  static setIsGroupExpanded = (activeProcess, groupName, newState) => {
    const { id } = activeProcess
    const storageKey = `${id}:${groupName}`
    localStorage.setItem(storageKey, newState)
  }
}

export default CacheGroup
