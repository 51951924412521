import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import Validator from '~/utils/validation/Validator'
import NoDataMessage from '~/components/utility/NoDataMessage'
import Authorizer from '~/utils/Authorizer'
import { FCTGraph, Functionality, Ports, Slot } from '@ospin/fct-graph'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'

const mapStateToProps = state => ({
  user: state.user,
  functionalityDescriptions: state.functionalityDescriptions,
})

const VALID_FORMATS = {
  GRAPH: 'graph',
  SUPPORTED_VIRTUAL_FCTS: 'supportedVirtualFunctionalities',
  CONNECTED_PHYSICAL_FCTS: 'connectedPhysicalFunctionalities',
}

const isGraphTable = format => format === VALID_FORMATS.GRAPH
const isConnectedFctTable = format => format === VALID_FORMATS.CONNECTED_PHYSICAL_FCTS

const renderSlotDefaultValue = ({ defaultValue }) => {
  if (Validator.isUndefinedOrNull(defaultValue)) return '-'
  return defaultValue.toString()
}

const renderSlotType = slot => {
  switch (slot.type) {
    case 'InSlot': {
      return 'Input'
    }
    case 'OutSlot': {
      return 'Output'
    }
    default:
      return '-'
  }
}

const renderSlotDataType = slot => {
  switch (slot.dataType) {
    case 'boolean': {
      return 'boolean'
    }
    case 'oneOf': {
      return 'selection'
    }
    case 'integer': {
      return 'number (integer)'
    }
    case 'float': {
      return 'number (float)'
    }
    default:
      return '-'
  }
}

const renderFctIdCell = (id, idx, rowSpan, user) => {
  if (idx !== 0) return null
  if (!Authorizer.user(user).hasApplicationDeveloperAccess()) {
    return null
  }

  return <Table.Cell rowSpan={rowSpan}>{id}</Table.Cell>
}

const renderFctAndSlotsData = (fct, user, format, functionalityDescriptions) => {

  const { name, type, slots, ports, subType } = fct

  const displayName = FunctionalityDescription
    .getFunctionalityDisplayName(functionalityDescriptions, subType)

  const renderFctData = slotCount => (
    <>
      <Table.Cell rowSpan={slotCount} content={isGraphTable(format) ? name : displayName} />
      {isGraphTable(format) && <Table.Cell rowSpan={slotCount} content={type} />}
      {isConnectedFctTable(format) && (
        <Table.Cell rowSpan={slotCount} content={Ports.getId(ports)} />
      )}
    </>
  )
  const shouldDisplayFctId = isGraphTable(format)
    && Authorizer.user(user).hasApplicationDeveloperAccess()

  return slots.map((slot, idx) => (
    <Table.Row key={fct.id + slot.name}>
      {idx === 0 && renderFctData(slots.length)}
      <Table.Cell content={Slot.getDisplayName(slot)} />
      <Table.Cell>
        {renderSlotType(slot)}
      </Table.Cell>
      <Table.Cell>
        {slot.unit}
      </Table.Cell>
      <Table.Cell>
        {renderSlotDataType(slot)}
      </Table.Cell>
      <Table.Cell>
        {renderSlotDefaultValue(slot)}
      </Table.Cell>
      <Table.Cell>
        {!Validator.isUndefinedOrNull(slot.min) ? slot.min : '-'}
      </Table.Cell>
      <Table.Cell>
        {!Validator.isUndefinedOrNull(slot.max) ? slot.max : '-'}
      </Table.Cell>
      { shouldDisplayFctId && renderFctIdCell(fct.id, idx, slots.length, user)}
    </Table.Row>
  ))
}

const getTableHeaders = (user, format) => {
  const commonHeaders = [
    'Channel',
    'Channel Type',
    'Unit',
    'Channel Data Format',
    'Device Default Value',
    'Min',
    'Max',
  ]

  const typeSpecificHeaders = () => {
    // eslint-disable-next-line default-case
    switch (format) {
      case 'graph': return ['Name', 'Type']
      case 'connectedPhysicalFunctionalities': return ['Type', 'Port']
      case 'supportedVirtualFunctionalities': return ['Type']
    }
  }

  const HEADERS = [ ...typeSpecificHeaders(format), ...commonHeaders]

  if (Authorizer.user(user).hasApplicationDeveloperAccess() && isGraphTable(format)) {
    HEADERS.push('Functionality ID')
  }

  return HEADERS
}

function getFctsByFormat(activeDevice, selectedGraph, format) {
  switch (format) {
    case VALID_FORMATS.GRAPH:
      return FCTGraph.getFctsWithoutIONodes(selectedGraph)
    case VALID_FORMATS.CONNECTED_PHYSICAL_FCTS:
      return activeDevice.connectedPhysicalFunctionalities
    case VALID_FORMATS.SUPPORTED_VIRTUAL_FCTS:
      return activeDevice.supportedVirtualFunctionalities.filter(
        fct => !Object.values(Functionality.FIXED_SUB_TYPES).includes(fct.subType),
      )
    default:
      throw new Error('invalid table format given')
  }
}

function sortFunctionalities(functionalities) {
  return functionalities.every(fct => fct.name)
    ? functionalities.sort((a, b) => a.name.localeCompare(b.name))
    : functionalities.sort((a, b) => a.subType.localeCompare(b.subType))
}

const FunctionalitiesTable = ({
  activeDevice,
  selectedGraph,
  user,
  format,
  functionalityDescriptions,
}) => {

  const functionalities = getFctsByFormat(activeDevice, selectedGraph, format)

  const HEADERS = getTableHeaders(user, format)

  if (!functionalities.length) return <NoDataMessage text='No functionalities found' />

  return (
    <Table className='ospin-red' celled structured>
      <Table.Header>
        <Table.Row>
          {HEADERS.map(text => (<Table.HeaderCell key={text} content={text} />))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortFunctionalities(functionalities)
          .map(fct => renderFctAndSlotsData(fct, user, format, functionalityDescriptions))}
      </Table.Body>
    </Table>
  )
}

export default connect(mapStateToProps)(FunctionalitiesTable)
