import React from 'react'
import { Message, Header } from 'semantic-ui-react'
import ProcessesTable from './ProcessesTable'
import ProcessesTiles from './ProcessesTiles'
import FavoriteProcesses from './FavoriteProcesses'
import './ProcessesArea.css'

const ProcessesArea = ({
  viewMode,
  processes,
  processClickHandler,
  toggleModal,
  selectProcessForAction,
  selectedIds,
  hasError,
  activeDevice,
  showProcessDetailsModal,
  activePage,
  totalPages,
  updateActivePage,
  processesPerPage,
  totalMatchingProcesses,
  allSelected,
  handleSelectAll,
}) => {

  const pinnedProcesses = processes.filter(process => process.pinned)

  if (hasError) {
    return (
      <Message
        negative
        content='Something went wrong, please refresh the page'
        className='processes-message-padding'
      />
    )
  }
  if (processes.length === 0) {
    return (
      <Message
        content='No process found, to create one click the create process button on the top or change your search criteria'
        className='processes-message-padding'
        info
      />
    )
  }

  if (viewMode === 'table') {
    return (
      <>
        {pinnedProcesses.length !== 0 && (
          <div className='fav-processes-container'>
            <div className='processes-list'>
              <Header as='h3'>Favorite Processes</Header>
              <ProcessesTable
                isFavoriteProcessesOnly
                processes={pinnedProcesses}
                processClickHandler={processClickHandler}
                openModalHandler={toggleModal}
                selectProcessForAction={selectProcessForAction}
                selectedIds={selectedIds}
                activeDevice={activeDevice}
                showProcessDetailsModal={showProcessDetailsModal}
              />
            </div>
          </div>
        )}
        <div className='processes-list'>
          <Header as='h3'>All Processes</Header>
          <ProcessesTable
            processes={processes.filter(process => !process.ignoreInPagination)}
            processClickHandler={processClickHandler}
            openModalHandler={toggleModal}
            selectProcessForAction={selectProcessForAction}
            selectedIds={selectedIds}
            activeDevice={activeDevice}
            showProcessDetailsModal={showProcessDetailsModal}
            activePage={activePage}
            totalPages={totalPages}
            updateActivePage={updateActivePage}
            totalMatchingProcesses={totalMatchingProcesses}
            processesPerPage={processesPerPage}
            allSelected={allSelected}
            handleSelectAll={handleSelectAll}
            toggleModal={toggleModal}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <FavoriteProcesses
        activeDevice={activeDevice}
        processes={pinnedProcesses}
        processClickHandler={processClickHandler}
        openModalHandler={toggleModal}
        showProcessDetailsModal={showProcessDetailsModal}
      />
      <ProcessesTiles
        activeDevice={activeDevice}
        processes={processes.filter(process => !process.ignoreInPagination)}
        processClickHandler={processClickHandler}
        openModalHandler={toggleModal}
        showProcessDetailsModal={showProcessDetailsModal}
        activePage={activePage}
        totalPages={totalPages}
        updateActivePage={updateActivePage}
        totalMatchingProcesses={totalMatchingProcesses}
        processesPerPage={processesPerPage}
      />
    </>
  )
}

export default ProcessesArea
