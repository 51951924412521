import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import { Dropdown, Segment, Menu, Input, Button } from 'semantic-ui-react'

const LEVELS = ['information', 'warning', 'error']

const generateDropdownOptions = subjects => subjects.map(subject => ({
  key: subject,
  text: subject,
  value: subject,
}))

const getNormalizedTimestamp = (key, value) => {
  if (!value) { return value }
  if (key === 'timeRefStartMS') { return value }
  return new Date(value).setHours(23, 59, 59).valueOf()
}

const getStateFromQuery = location => {
  const query = new URLSearchParams(location.search)
  return {
    timeRefEndMS: query.get('timeRefEndMS') ? parseInt(query.get('timeRefEndMS'), 10) : null,
    timeRefStartMS: query.get('timeRefStartMS') ? parseInt(query.get('timeRefStartMS'), 10) : null,
    ...['subjectQuery', 'textQuery', 'levelQuery'].reduce((a, v) => ({ ...a, [v]: query.get(v) }), {})
  }
}

function LogViewQueryBar({
  subjects,
  location,
  updateQueryParameters,
}) {

  const initalStateKeys = ['timeRefEndMS', 'timeRefStartMS', 'subjectQuery', 'textQuery', 'levelQuery']

  const [pendingQueryParameters, setpendingQueryParameters] = useState(getStateFromQuery(location))

  const returnDateObjectOrNull = timestamp => (timestamp ? new Date(timestamp) : null)

  function updatePendingQueryParams({ key, value }) {
    setpendingQueryParameters({
      ...pendingQueryParameters,
      [key]: value,
    })
  }

  const currentFilterApplied = initalStateKeys.every(
    key => pendingQueryParameters[key] === getStateFromQuery(location)[key],
  )

  function applyFilter() {
    const parameterArray = Object.entries(pendingQueryParameters)
      .map(entries => ({ key: entries[0], value: entries[1] }))
    updateQueryParameters([...parameterArray, { key: 'skip', value: 0 }])
  }

  function updateTimestamps({ key, value }) {
    const normalizeValue = getNormalizedTimestamp(key, value)
    updatePendingQueryParams({ key, value: normalizeValue })
  }


  return (
    <Segment>
      <Menu secondary widths={3}>
        <Menu.Item stacked='true'>
          <label className='text-label'>Start: </label>
          <SemanticDatepicker
            name='timeRefStartMS'
            value={returnDateObjectOrNull(pendingQueryParameters.timeRefStartMS)}
            maxDate={returnDateObjectOrNull(pendingQueryParameters.timeRefEndMS) || new Date()}
            onChange={(_, { value }) => updateTimestamps({ key: 'timeRefStartMS', value: Date.parse(value) || null })}

          />
        </Menu.Item>
        <Menu.Item>
          <label className='text-label'>End: </label>
          <SemanticDatepicker
            name='timeRefEndMS'
            value={returnDateObjectOrNull(pendingQueryParameters.timeRefEndMS)}
            minDate={returnDateObjectOrNull(pendingQueryParameters.timeRefStartMS)}
            maxDate={new Date()}
            onChange={(_, { value }) => updateTimestamps({ key: 'timeRefEndMS', value: Date.parse(value) || null })}
          />
        </Menu.Item>
        <Menu.Item>
          <Input
            fluid
            label='Search'
            placeholder='Search by text'
            value={pendingQueryParameters.textQuery || ''}
            name='textQuery'
            onChange={(_, { value }) => updatePendingQueryParams({ key: 'textQuery', value: value || null })}
            style={{ marginLeft: '16px', marginRight: '16px' }}
          />

        </Menu.Item>

      </Menu>
      <Menu secondary widths={3}>
        <Menu.Item>
          <Dropdown
            selection
            fluid
            clearable
            placeholder='Subject'
            options={generateDropdownOptions(subjects)}
            value={pendingQueryParameters.subjectQuery}
            name='levelQuery'
            onChange={(_, { value }) => updatePendingQueryParams({ key: 'subjectQuery', value: value || null })}
            style={{ marginLeft: '16px', marginRight: '16px' }}
          />
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            selection
            fluid
            clearable
            placeholder='Level'
            options={generateDropdownOptions(LEVELS)}
            testid='level'
            value={pendingQueryParameters.levelQuery}
            name='levelQuery'
            onChange={(_, { value }) => updatePendingQueryParams({ key: 'levelQuery', value: value || null })}
            style={{ marginLeft: '16px', marginRight: '16px' }}
          />
        </Menu.Item>
        <Menu.Item>
          <span>
            <Button
              fluid
              primary
              disabled={currentFilterApplied}
              content='Apply Filter'
              onClick={() => applyFilter()}
            />
          </span>
        </Menu.Item>
      </Menu>
    </Segment>
  )
}

export default withRouter(LogViewQueryBar)
