import nexus from '@ospin/nexus'
import { removeNotifications } from '~/redux/actions/actions'

const callDeleteEphemeralNotifications = async (
  dispatch,
  userId,
  notifications,
  deleteOptimistically = false,
) => {
  const promise = Promise.all(notifications
    .map(not => nexus.user.notifications.remove({ userId, notificationId: not.id })))

  if (!deleteOptimistically) await promise
  dispatch(removeNotifications({ ids: notifications.map(notification => notification.id) }))
}

export default callDeleteEphemeralNotifications
