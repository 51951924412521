import store from '~/redux/store'
import nexus from '@ospin/nexus'
import FlashMessenger from '~/utils/FlashMessenger'
import { updateDevice } from '~/redux/actions/actions'
import { ellipseString } from '~/utils/string'

async function loadDevice(deviceId) {
  const { user } = store.getState()
  const { data: device } = await nexus.user.device.get({ userId: user.id, deviceId })
  FlashMessenger.dispatch(FlashMessenger.createMessage(
    'success',
    `Device "${ellipseString(device.name, 25)}" has updated its configuration`,
  ))

  store.dispatch(updateDevice({ deviceId, params: { fctGraphs: device.fctGraphs } }))
}

export default deviceId => {
  loadDevice(deviceId)
}
