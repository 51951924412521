export default (state, action) => {
  const { devices } = state

  const { deviceUsers, device } = action.payload

  const updatedDevices = devices.length
    ? devices.map(loadedDevice => {
      if (loadedDevice.id === device.id) return device
      return loadedDevice
    })
    : [ device ]

  return {
    ...state,
    deviceUsers,
    activeDeviceInitialized: true,
    devices: updatedDevices,
  }
}
