import React, { useState } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import HelpIconPopup from '~/components/utility/HelpIconPopup'
import nexus from '@ospin/nexus'

const userNameTooltip = 'verified accounts can also reset their password with their email address, please note that the email is case sensitive'

export default function RequestResetCode() {
  const [userName, setUserName] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [ loading, setLoading ] = useState(false)

  async function submit() {
    setLoading(true)
    try {
      await nexus.auth.forgotPassword({ usernameOrEmail: userName })
      setSuccess(true)
      setError(false)
    } catch ({ message }) {
      setError(message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      error={!!error}
      success={success}
      size='large'
      onSubmit={() => submit()}
    >
      <Form.Field>
        <label htmlFor='username'>
          username or email
          <HelpIconPopup description={userNameTooltip} />
        </label>
        <Form.Input
          size='big'
          name='username'
          id='username'
          fluid
          icon='user'
          autoComplete='username'
          iconPosition='left'
          value={userName}
          onChange={(_, { value }) => setUserName(value)}
        />
        <Button
          fluid
          content='Confirm'
          primary
          type='submit'
          disabled={!userName}
          loading={loading}
        />
        {error ? <Message error header='Could not request reset code' content={error} /> : null}
        {success ? <Message success content='A reset code is beeing sent to your email address' /> : null}
      </Form.Field>
    </Form>
  )
}
