import React from 'react'
import formalize from '~/components/utility/modal/formalize'
import { Message } from 'semantic-ui-react'

const AlertsDeleteModalForm = () => (
  <Message negative>
    <Message.Header>
      This will delete all device alerts
    </Message.Header>
    <p>Are you sure you want to proceed?</p>
  </Message>
)

export default formalize(AlertsDeleteModalForm)
