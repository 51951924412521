import React, { useState, useEffect } from 'react'
import nexus from '@ospin/nexus'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createProcessObject } from '~/redux/helper/objectMapper'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'

import DynamicLoader from '~/components/utility/DynamicLoader'
import ProcessViewer from '~/components/processviewer/ProcessViewer'
import { Process } from '~/utils/process'
import { setProcesses } from '~/redux/actions/actions'
import ErrorPage from '~/components/utility/ErrorPage'

const mapStateToProps = state => ({
  user: state.user,
  processes: state.processes,
  devices: state.devices,
  logs: state.logs,
})

const mapDispatchToProps = dispatch => ({
  dispatchSetProcessesToProcessesList: processes => dispatch(setProcesses({ processes })),
})

const ProcessScreen = ({
  activeDevice,
  processes,
  dispatchSetProcessesToProcessesList,
  match: { params: { processId } },
  user,
}) => {

  const [ isLoading, setLoadingStatus ] = useState(true)
  const [ isError, setIsError ] = useState(false)
  const [ error, setError ] = useState(null)

  useEffect(() => {
    async function fetchProcessAndUIConfig() {
      try {
        setLoadingStatus(true)
        const { data: process } = await nexus.process.get(processId)
        await UserFctGraphUIConfig.setUsersFctGraphUIConfig(process.fctGraph)

        dispatchSetProcessesToProcessesList([ createProcessObject(process) ])
      } catch ({ message, status }) {
        setError({ message, status })
        setIsError(true)
      } finally {
        setLoadingStatus(false)
      }
    }

    fetchProcessAndUIConfig()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) return <DynamicLoader />

  if (isError) {
    return (
      <ErrorPage
        errorCode={error.status}
        customMessage={error.status === 404 ? `process: ${processId} not found!` : error.message}
      />
    )
  }

  const activeProcess = Process.getById(processes, processId)

  // safeguarding a race condition of dispatch
  const hasFetchedFctGraphUIConfig = (user.fctGraphUIConfig?.fctGraphId === activeProcess.fctGraphId)

  if (!Process.isEnriched(activeProcess) || !hasFetchedFctGraphUIConfig) return null

  return (
    <ProcessViewer
      activeProcess={activeProcess}
      activeDevice={activeDevice}
    />
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcessScreen))
