class Event {
  static deriveDisplayName(event) {
    return event.name || 'Unnamed Event'
  }

  static hasAssociatedDevice(event) {
    return !!event.deviceId
  }

}

export default Event
