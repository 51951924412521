import ReactDOM from 'react-dom'
import React from 'react'
import { Provider } from 'react-redux'
import './styles/ospin-design-system.css'
import './styles/common.css'
import './styles/global.css'
import './styles/semanticoverrides.css'
// import { createConfig } from '~/env.config'
import { getDeploymentEnv } from '~/config/envDetector'
import nexus from '@ospin/nexus'
import App from './App'
import store from './redux/store'

// createConfig()

nexus.configure({ ENV: getDeploymentEnv() })

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
)
