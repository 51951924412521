import React from 'react'

export default function StencilComponent({node}) {
  return (
    <div
      style={{
        textAlign: 'left',
        overflowWrap: 'anywhere',
        height: '50px',
        width: '180px',
        background: 'white',
        borderRadius: '1em',
        padding: '0.25em',
      }}
    >
      {node.store.data.label}
    </div>
  )
}
