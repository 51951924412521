import DataManager from '~/utils/DataManager'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import { getDeploymentEnv } from '~/config/envDetector'

export const createUserObject = userData => ({
  userName: userData.userName,
  fullName: userData.fullName,
  organization: userData.organization,
  email: userData.email,
  id: userData.id,
  role: userData.role,
  phoneNumber: userData.phoneNumber,
  notifications: userData.notifications,
  events: userData.events,
  uIConfig: userData.uIConfig,
})

export const createProcessObject = processData => ({
  id: processData.id,
  deviceId: processData.deviceId,
  name: processData.name,
  comment: processData.comment,
  createdAt: processData.createdAt,
  createdBy: processData.createdBy,
  cloneHistory: processData.cloneHistory,
  description: processData.description,
  entryPhaseId: processData.entryPhaseId || 0,
  progression: processData.progression,
  access: processData.access,
  pinned: !!processData.pinned,
  ownerId: processData.ownerId,
  historicDataLoaded: false,
  startedAt: processData.startedAt,
  startedBy: processData.startedBy,
  data: DataManager.initSensorData(processData),
  state: processData.state,
  finishedAt: processData.finishedAt,
  outdated: false,
  eventHistory: processData.eventHistory,
  calibrations: processData.calibrations,
  fctGraph: processData.fctGraph,
  fctGraphId: processData.fctGraphId,
  fctsConfigs: processData.fctsConfigs,
  enriched: true, // flag differentiates list process object from full process object
  reporterToFctAndSlotMap: FunctionalityGraph.createReporterToFctAndSlotMap(processData.fctGraph),
  featureVersion: processData.featureVersion,
  annotations: processData.annotations,
  usedPhysicalMapping: processData.usedPhysicalMapping,
})

export const createListProcessObject = processData => ({
  id: processData.id,
  deviceId: processData.deviceId,
  name: processData.name,
  comment: processData.comment,
  createdAt: processData.createdAt,
  cloneHistory: processData.cloneHistory,
  createdBy: processData.createdBy,
  access: processData.access,
  pinned: !!processData.pinned,
  ownerId: processData.ownerId,
  startedAt: processData.startedAt,
  startedBy: processData.startedBy,
  finishedAt: processData.finishedAt,
  progression: processData.progression,
  state: processData.state,
  fctGraph: processData.fctGraph,
  fctGraphId: processData.fctGraphId,
  usedPhysicalMapping: processData.usedPhysicalMapping,
  featureVersion: processData.featureVersion,
  ignoreInPagination: processData.ignoreInPagination,
})

const isOnlineOnCurrentClientStage = ({ online, lastActiveStage }) => {
  const env = getDeploymentEnv()
  if (lastActiveStage !== env) return false
  return online
}

export const createDeviceObject = deviceData => ({
  isVirtual: deviceData.isVirtual,
  id: deviceData.id,
  access: deviceData.access,
  name: deviceData.name,
  online: isOnlineOnCurrentClientStage(deviceData),
  hardwareVersion: deviceData.hardwareVersion,
  ownerId: deviceData.ownerId,
  subscriptions: {},
  readyForCommand: true,
  fctGraphs: deviceData.fctGraphs,
  fctGraphTemplates: deviceData.fctGraphTemplates,
  manufacturer: deviceData.manufacturer,
  manufacturerDeviceType: deviceData.manufacturerDeviceType,
  featureVersion: deviceData.featureVersion,
  firmwareUpdate: deviceData.firmwareUpdate,
  firmwareVersion: deviceData.firmwareVersion,
  runningProcesses: deviceData.runningProcesses,
  connectedPhysicalFunctionalities: deviceData.connectedPhysicalFunctionalities,
  supportedVirtualFunctionalities: deviceData.supportedVirtualFunctionalities,
  licences: deviceData.licences,
})

export const createDeviceLogObject = logData => ({
  id: logData._id,
  content: logData.content,
  user: logData.user,
  topic: logData.topic,
  createdAt: logData.createdAt,
  level: logData.level,
})
