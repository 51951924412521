import React, { useState } from 'react'
import { generateAndDownloadCSV } from '~/utils/ProcessOverviewCSVBuilder'
import modalize from '~/components/utility/modal/modalize'
import CSVDownloadModalForm from './CSVDownloadModalForm'

const CSVDownloadModal = props => {
  const {
    setIsPopupDownloadOpen,
    isPopupDownloadOpen,
    slotsData,
    activeProcess,
    closeHandler,
  } = props

  const [ fileType, setFileType] = useState('csvForExcel')
  const [ delimiter, setDelimiter ] = useState('')

  const changeFileType = (event, data) => {
    setFileType(data.value)
  }

  const changeDelimiter = (event, data) => {
    setDelimiter(data.value)
  }

  const downloadProcessOverview = () => {
    const options = {
      fileType,
      delimiter,
    }
    return generateAndDownloadCSV(slotsData, activeProcess, options)
  }

  return (

    <CSVDownloadModalForm
      slotsData={slotsData}
      activeProcess={activeProcess}
      setIsPopupDownloadOpen={setIsPopupDownloadOpen}
      isPopupDownloadOpen={isPopupDownloadOpen}
      submitText='Download'
      disableSubmit={!fileType}
      closeOnSubmit
      closeHandler={closeHandler}
      submitHandler={downloadProcessOverview}
      changeFileType={changeFileType}
      fileType={fileType}
      changeDelimiter={changeDelimiter}
      delimiter={delimiter}
    />
  )
}

export default modalize(CSVDownloadModal)
