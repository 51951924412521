import React from 'react'
import { Form, Divider, Header, Button } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'
import Authorizer from '~/utils/Authorizer'
import GroupAccessInformation from './GroupAccessInformation'
import ProcessUserTable from './ProcessUserTable'

const renderGrantAccessDropdownGroup = (
  deviceUsers,
  assignedUser,
  assignedGroup,
  groupOptions,
  handleUserChange,
  handleGroupChange,
) => (
  <Form.Group widths='equal'>
    <Form.Select
      fluid
      label='User'
      options={deviceUsers}
      value={assignedUser}
      placeholder='Choose User'
      onChange={handleUserChange}
    />
    <Form.Select
      fluid
      label='Group'
      options={groupOptions}
      value={assignedGroup}
      placeholder='Choose Group'
      onChange={handleGroupChange}
    />
  </Form.Group>
)

const descriptions = {
  admins: 'Admins can view, edit, execute, delete, and manage process access.',
  operators: 'Operators can view, edit, and execute the process.',
  viewers: 'Viewers can look at the process but cannot apply any changes.',
}

const AccessProcessModalForm = props => {
  const {
    loading,
    IsDeviceOrProcessAdmin,
    deviceUserOptions,
    assignedUser,
    assignedGroup,
    groupOptions,
    handleUserChange,
    handleGroupChange,
    assignedGroupData,
    pendingRequests,
    processUsers,
    process,
    user,
    modifyAccess,
    withDrawAccess,
    loadingUsers,
    submitHandler,
  } = props

  const isUserAuthorizedToRevokeOwnAccess = (Authorizer.isCurrentLoggedInUser(user, user.id)
                                            && !Authorizer.isOwnerOf(process, user.id))

  return (
    <>
      {IsDeviceOrProcessAdmin
        ? renderGrantAccessDropdownGroup(
          deviceUserOptions,
          assignedUser,
          assignedGroup,
          groupOptions,
          handleUserChange,
          handleGroupChange,
        ) : (
          <p>Only admins can add users to a process.</p>
        )}
      {IsDeviceOrProcessAdmin
        ? (
          <>
            <Divider />
            <Header as='h4'>Group Rights</Header>
            {assignedGroup ? (
              <div style={{ height: '30px', whitespace: 'nowrap' }}>
                {descriptions[assignedGroup]}
              </div>
            ) : null}
            <GroupAccessInformation
              assignedGroupData={assignedGroupData}
            />
            <Divider />
            <Button
              primary
              type='submit'
              loading={loading}
              disabled={loading || !IsDeviceOrProcessAdmin || !assignedGroup || !assignedUser}
              onClick={submitHandler}
            >
              Share
            </Button>
          </>
        ) : null}
      <Divider />
      <Header as='h4'>Users with Access</Header>
      <ProcessUserTable
        IsDeviceOrProcessAdmin={IsDeviceOrProcessAdmin}
        pendingRequests={pendingRequests}
        processUsers={processUsers}
        loggedInUser={user}
        process={process}
        modifyAccess={modifyAccess}
        withDrawAccess={withDrawAccess}
        groupOptions={groupOptions}
        loadingUsers={loadingUsers}
        isUserAuthorizedToRevokeOwnAccess={isUserAuthorizedToRevokeOwnAccess}
      />
    </>
  )
}

export default formalize(AccessProcessModalForm)
