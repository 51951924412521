import React, { useState } from 'react'
import { Accordion, Container, Message, Icon } from 'semantic-ui-react'

import DraggableComponent from '~/components/utility/DraggableComponent'
import { replaceNoUnitCharacter } from '~/utils/units'

const FCTDataViewerWrapperHOC = ({
  slotName,
  folded,
  toggleFold,
  index,
  hasDataError,
  dragDisableOverride,
  children,
  lastSlotValue,
  unit,
}) => {

  const [ isDragDisabled, setIsDragDisabled ] = useState(true)
  const getSlotInfo = () => {
    if (lastSlotValue === null) return slotName
    const slotUnit = replaceNoUnitCharacter(unit, '')

    return `${slotName} ${lastSlotValue} ${slotUnit}`
  }

  return (
    <DraggableComponent
      draggableId={slotName}
      index={index}
      style={{ padding: '0', borderTop: '1px solid rgba(34,36,38,.15)' }}
      isDragDisabled={isDragDisabled || dragDisableOverride}
      data-testid={slotName}
    >
      <Accordion.Title
        active={!folded}
        content={folded ? getSlotInfo() : ''}
        onClick={toggleFold}
        onMouseOver={() => setIsDragDisabled(false)}
        onFocus={() => setIsDragDisabled(false)}
        onMouseLeave={() => setIsDragDisabled(true)}
      />
      { !folded && (
        <Accordion.Content style={{ paddingBottom: '0' }} active={!folded}>
          {hasDataError && (
            <Message error style={{ padding: '8px !important' }}>
              <Icon name='warning circle' />
              Parts of the data for this functionality channel could not be loaded.
              Contact the support if this problem persists.
            </Message>
          )}
          <Container fluid>
            { children }
          </Container>
        </Accordion.Content>
      )}
    </DraggableComponent>
  )
}

export default FCTDataViewerWrapperHOC
