import React, { useState } from 'react'
import { Functionality } from '@ospin/fct-graph'
import { connect } from 'react-redux'
import CloneProcessModalForm from '~/components/device/modals/CloneProcessModalForm'
import callCloneProcess from '~/redux/thunks/process/callCloneProcess'
import Authorizer from '~/utils/Authorizer'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import FeatureVersioning from '~/utils/FeatureVersioning'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import { withRouter } from 'react-router-dom'
import FCTGraphSelection from './FCTGraphSelection'

const { VALID_PROCESS_DATA: { nameLength } } = ProcessValidator

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => (
  { dispatchCallCloneProcess: (processId, body) => callCloneProcess(dispatch, processId, body) }
)

const getCompatibleFctGraphs = (process, device) => {
  /* we will show the selection only if there are alternative
   * fctGraph (same templateId)
   */
  if (!FeatureVersioning.supportsMultipleProcesses(process)) {
    return []
  }

  const fctGraph = FunctionalityGraph.getGraphById(
    device.fctGraphs,
    process.fctGraphId,
  )

  if (!fctGraph.templateId) return []

  const compatibleFctGraphs = device.fctGraphs
    .filter(g => g.templateId === fctGraph.templateId)

  if (compatibleFctGraphs.length < 2) return []

  return compatibleFctGraphs
}

const CloneProcessFormContainer = props => {
  const {
    process,
    dispatchCallCloneProcess,
    closeHandler,
    history,
    match,
    activeDevice,
    user,
  } = props

  const [ newName, setNewName ] = useState(`Clone_${process.name}`.substr(0, nameLength))
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ selectedFctGraphId, setSelectedFctGraphId ] = useState(process.fctGraphId)

  const handleUpdateName = ({ target: { value: name } }) => {
    setNewName(name)
    setIsError(false)
    setErrorMessage('')
  }

  const createProcessClone = async () => {
    if (!ProcessValidator.isValidProcessName(newName)) {
      setIsError(true)
      setErrorMessage(`Process name must be short than ${nameLength + 1}`)
      return
    }

    setLoading(true)

    try {
      const cloneData = { processName: newName }

      if (selectedFctGraphId !== process.fctGraphId) {
        cloneData.targetFctGraphId = selectedFctGraphId
      }

      const {
        clonedProcessId,
        clonedProcessFctGraphId,
      } = await dispatchCallCloneProcess(process.id, cloneData)
      closeHandler()

      const { params: { fctGraphId } } = match
      if (fctGraphId) {
        history.push(`/devices/${activeDevice.id}/configurations/${clonedProcessFctGraphId}/processes/${clonedProcessId}`)
      } else {
        history.push(`/devices/${activeDevice.id}/processes/${clonedProcessId}`)
      }
    } catch (e) {
      setIsError(true)
      setErrorMessage(e.message)
      setLoading(false)
    }
  }

  const compatibleFctGraphs = getCompatibleFctGraphs(process, activeDevice)
  const isDisabled = Authorizer.isResourceViewer(process, user.id)

  return (
    <>
      { compatibleFctGraphs.length > 0 && (
        <FCTGraphSelection
          selectedFctGraphId={selectedFctGraphId}
          setSelectedFctGraphId={setSelectedFctGraphId}
          fctGraphs={compatibleFctGraphs}
          activeDevice={activeDevice}
        />
      )}
      <CloneProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={createProcessClone}
        submitText='Clone'
        loading={loading}
        newName={newName}
        handleUpdateName={handleUpdateName}
        disableSubmit={isDisabled}
        isDisabled={isDisabled}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CloneProcessFormContainer))
