import React from 'react'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import { Card, Image, Divider, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Device from '~/utils/Device'
import './DeviceWidget.css'
import './FunctionalityGraphWidget.css'
import defaultImage from '~/images/bioreactor_online_cropped.png'

const FCT_GRAPH_WIDGET_FCT_NAME_MIN_LEN = 57

function getAvailableGraphs(fctGraphs, device) {
  return fctGraphs.filter(
    fctGraph => FunctionalityGraph.deviceCanExecuteGraph(fctGraph, device),
  )
}

function hasAtLeastOneOnlineGraph(fctGraphs, device) {
  return getAvailableGraphs(fctGraphs, device).length !== 0
}

const pluralizeDevices = (text, numberOfDevices) => (numberOfDevices > 1 ? `${text}s` : text)

const getRunningProcess = (device, fctGraphs) => {
  const runningProcesses = fctGraphs.map(fctGraph => (
    device.runningProcesses.filter(rp => rp.fctGraphId === fctGraph.id)
  ))
  return runningProcesses.filter(rp => rp.length > 0)
}

const getRunningGraphs = (runningProcess, allAvailableGraphs) => (
  runningProcess.map(rp => (
    allAvailableGraphs.filter(possibleGraph => possibleGraph.id !== rp.fctGraphId)
  ))
)

function renderStatus(fctGraphs, device, messageString) {
  const runningProcess = getRunningProcess(device, fctGraphs)
  const allAvailableGraphs = getAvailableGraphs(fctGraphs, device)
  const runningGraphs = getRunningGraphs(runningProcess, allAvailableGraphs)

  const numberOfIdleGraphs = allAvailableGraphs.length - runningGraphs.length
  const numberOfUnavailableGraphs = fctGraphs.length - allAvailableGraphs.length
  const deviceAndGraphOnline = Device.isOnline(device)
    && hasAtLeastOneOnlineGraph(fctGraphs, device)

  const runningProcessOnDeviceText = runningProcess && runningProcess.length > 0
    ? `${runningProcess.length} ${pluralizeDevices(messageString, runningProcess.length)} running`
    : null

  const connectedDevicesText = numberOfIdleGraphs > 0
    ? `${numberOfIdleGraphs} ${pluralizeDevices(messageString, numberOfIdleGraphs)} ${deviceAndGraphOnline ? 'online' : 'offline'}`
    : null

  const disconnectedDevicesText = numberOfUnavailableGraphs > 0
    ? `${numberOfUnavailableGraphs} ${pluralizeDevices(messageString, numberOfUnavailableGraphs)} disconnected`
    : null

  const getStatusStyle = () => {
    if (runningProcess.length) return 'device-widget-status-running'
    if (deviceAndGraphOnline) return 'device-widget-status-device-and-graph-online'
    return 'device-widget-status-offline-or-disconnected'
  }

  return (
    <Card.Content className={getStatusStyle()}>
      <div className='device-widget-status-container' style={{ height: '60px' }}>
        <span className='device-widget-status-text'>
          {runningProcessOnDeviceText}
          {runningProcessOnDeviceText && connectedDevicesText ? <br /> : null}
          {connectedDevicesText}
          {connectedDevicesText && disconnectedDevicesText ? <br /> : null}
          {disconnectedDevicesText}
          {runningProcessOnDeviceText && disconnectedDevicesText ? <br /> : null}
        </span>
      </div>
    </Card.Content>
  )
}

function getImageClass(device, fctGraphs) {
  const base = 'device-widget-image'
  if (Device.isOffline(device)) return `${base} ${base}--faded`

  return hasAtLeastOneOnlineGraph(fctGraphs, device) ? base : `${base} ${base}--faded`
}

export default function AggregatedFunctionalitiesWidget({
  fctGraphIds,
  template,
  device,
  messageString,
}) {
  const fctGraphs = device.fctGraphs.filter(graph => fctGraphIds.includes(graph.id))
  const { imageURL, name } = template
  const imageSource = imageURL || defaultImage

  return (
    <Link
      to={`/devices/${device.id}/processes?fctGraphIds=${fctGraphIds.join()}`}
    >
      <Card className='functionality-graph-widget'>
        <Card.Content className='device-widget-header fct-graph-widget-header-height'>
          <Card.Meta className='device-widget-meta-style'>&nbsp;</Card.Meta>
          <Card.Header>
            <div className='functionality-graph-widget-name overflow-ellipsis'>
              <Popup
                content={name}
                disabled={name.length < FCT_GRAPH_WIDGET_FCT_NAME_MIN_LEN}
                trigger={
                  <span>{name}</span>
                }
              />
            </div>
          </Card.Header>
          <Card.Meta className='device-widget-meta-style'>&nbsp;</Card.Meta>
        </Card.Content>
        <Divider className='fct-graph-widget-divider' />
        <div className='device-widget-image-frame'>
          <Image
            className={getImageClass(device, fctGraphs)}
            centered
            src={imageSource}
          />
        </div>
        {renderStatus(fctGraphs, device, messageString)}
      </Card>
    </Link>
  )
}
