export const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_SESSION: 'SET_SESSION',
  ADD_PROCESSES: 'ADD_PROCESSES',
  DELETE_PROCESSES: 'DELETE_PROCESSES',
  CREATE_DEVICE: 'CREATE_DEVICE',
  SET_DEVICES: 'SET_DEVICES',
  UPDATE_DEVICE_CONNECTION_STATE: 'UPDATE_DEVICE_CONNECTION_STATE:',
  UPDATE_DEVICE: 'UPDATE_DEVICE',
  LOAD_DEVICE: 'LOAD_DEVICE',
  SET_ACTIVE_DEVICE: 'SET_ACTIVE_DEVICE',
  ADD_LOGS: 'ADD_LOGS',
  REMOVE_DEVICE_USER: 'REMOVE_DEVICE_USER',
  MODIFY_DEVICE_ACCESS: 'MODIFY_DEVICE_ACCESS',
  TRANSFER_DEVICE_OWNERSHIP: 'TRASNFER_DEVICE_OWNERSHIP',
  ADD_PROCESS_USER: 'ADD_PROCESS_USER',
  REMOVE_PROCESS_USER: 'REMOVE_PROCESS_USER',
  MODIFY_PROCESS_ACCESS: 'MODIFY_PROCESS_ACCESS',
  UPDATE_PROCESS: 'UPDATE_PROCESS',
  SET_PROCESSES: 'SET_PROCESSES',
  ADD_PROCESS_TO_LIST: 'ADD_PROCESS_TO_LIST',
  SET_PROCESS_VIEW_MODE: 'SET_PROCESS_VIEW_MODE',
  LOAD_PROCESSVIEWER: 'LOAD_PROCESSVIEWER',
  SET_DISPLAYED_PROCESS_PHASE: 'SET_DISPLAYED_PROCESS_PHASE',
  ADD_PROCESS_PHASE: 'ADD_PROCESS_PHASE',
  SET_INPUT_NODE_VALUE: 'SET_INPUT_NODE_VALUE',
  DELETE_PHASE: 'DELETE_PHASE',
  MOVE_PHASE: 'MOVE_PHASE',
  SET_PROCESSBUILDER_VALIDATION_ERRORS: 'SET_PROCESSBUILDER_VALIDATION_ERRORS',
  END_PROCESS: 'END_PROCESS',
  PAUSE_PROCESS: 'PAUSE_PROCESS',
  RESUME_PROCESS: 'RESUME_PROCESS',
  ADD_HISTORIC_SENSOR_DATA: 'ADD_HISTORIC_SENSOR_DATA',
  SET_LOADING_MORE_HISTORIC_SENSOR_DATA_FLAG: 'SET_LOADING_MORE_HISTORIC_SENSOR_DATA_FLAG',
  UPDATE_PROCESS_STATE: 'UPDATE_PROCESS_STATE',
  SWITCH_PROCESS_PHASE: 'SWITCH_PROCESS_PHASE',
  ADD_LIVE_SENSOR_DATA: 'ADD_LIVE_SENSOR_DATA',
  SET_PROCESS_FCT_IMGS: 'SET_PROCESS_FCT_IMGS',
  ADD_PROCESS_FCT_IMGS: 'ADD_PROCESS_FCT_IMGS',
  REMOVE_NOTIFICATIONS: 'REMOVE_NOTIFICATIONS',
  CREATE_PROCESS_DESCRIPTION_SNAPSHOT: 'CREATE_PROCESS_DESCRIPTION_SNAPSHOT',
  UPDATE_PROCESS_DESCRIPTION_SNAPSHOT: 'UPDATE_PROCESS_DESCRIPTION_SNAPSHOT',
  RESET_PROCESS_DESCRIPTION_SNAPSHOTS: 'RESET_PROCESS_DESCRIPTION_SNAPSHOTS',
  REMOVE_NOTIFICATIONS_BY_TOPIC: 'REMOVE_NOTIFICATIONS_BY_TOPIC',
  ADD_FLASHMESSAGE: 'ADD_FLASHMESSAGE',
  DELETE_FLASHMESSAGE: 'DELETE_FLASHMESSAGE',
  ADD_USER_ALERT: 'ADD_USER_ALERT',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER_ALERT: 'DELETE_USER_ALERT',
  SET_SENSOR_DATA_ERROR_FLAG: 'SET_SENSOR_DATA_ERROR_FLAG',
  COLLAPSE_SIDEBAR: 'COLLAPSE_SIDEBAR',
  SUPPORT_USERS: 'SET_SUPPORT_USERS',
  CREATE_PHASE_GROUP: 'CREATE_PHASE_GROUP',
  ADD_PHASE_WITHIN_GROUP: 'ADD_PHASE_WITHIN_GROUP',
  PREPEND_PHASE_TO_GROUP: 'PREPEND_PHASE_TO_GROUP',
  APPEND_PHASE_TO_GROUP: 'APPEND_PHASE_TO_GROUP',
  DELETE_PHASE_WITHIN_GROUP: 'DELETE_PHASE_WITHIN_GROUP',
  SET_PHASE_GROUP_ITERATIONS_COUNT: 'SET_PHASE_GROUP_ITERATIONS_COUNT',
  SET_PHASE_PROPS: 'SET_PHASE_PROPS',
  RENAME_PHASE_GROUP: 'RENAME_PHASE_GROUP',
  DELETE_USER_ALERTS_BY_DEVICE_ID: 'DELETE_USER_ALERTS_BY_DEVICE_ID',
  SET_DOWNLOAD_REQUESTS: 'SET_DOWNLOAD_REQUESTS',
  UPDATE_OR_CREATE_DOWNLOAD_REQUEST: 'UPDATE_OR_CREATE_DOWNLOAD_REQUEST',
  UPDATE_PUSHER_CONNECTION_STATE: 'UPDATE_PUSHER_CONNECTION_STATE',
  SET_FUNCTIONALITY_DESCRIPTIONS: 'SET_FUNCTIONALITY_DESCRIPTIONS',
}

export function logout(payload) {
  return { type: ACTIONS.LOGOUT, payload }
}

export function setSession(payload) {
  return { type: ACTIONS.SET_SESSION, payload }
}

export function deleteProcesses(payload) {
  return { type: ACTIONS.DELETE_PROCESSES, payload }
}

export function setDevices(payload) {
  return { type: ACTIONS.SET_DEVICES, payload }
}

export function createDevice(payload) {
  return { type: ACTIONS.CREATE_DEVICE, payload }
}

export function updateDeviceConnectionState(payload) {
  return { type: ACTIONS.UPDATE_DEVICE_CONNECTION_STATE, payload }
}

export function updateDevice(payload) {
  return { type: ACTIONS.UPDATE_DEVICE, payload }
}

export function setActiveDevice(payload) {
  return { type: ACTIONS.SET_ACTIVE_DEVICE, payload }
}

export function removeDeviceUser(payload) {
  return { type: ACTIONS.REMOVE_DEVICE_USER, payload }
}

export function modifyDeviceAccess(payload) {
  return { type: ACTIONS.MODIFY_DEVICE_ACCESS, payload }
}

export function transferDeviceOwnership(payload) {
  return { type: ACTIONS.TRANSFER_DEVICE_OWNERSHIP, payload }
}

export function addProcessUser(payload) {
  return { type: ACTIONS.ADD_PROCESS_USER, payload }
}

export function removeProcessUser(payload) {
  return { type: ACTIONS.REMOVE_PROCESS_USER, payload }
}

export function modifyProcessAccess(payload) {
  return { type: ACTIONS.MODIFY_PROCESS_ACCESS, payload }
}

export function endProcess(payload) {
  return { type: ACTIONS.END_PROCESS, payload }
}

export function pauseProcess(payload) {
  return { type: ACTIONS.PAUSE_PROCESS, payload }
}

export function resumeProcess(payload) {
  return { type: ACTIONS.RESUME_PROCESS, payload }
}

export function updateProcess(payload) {
  return { type: ACTIONS.UPDATE_PROCESS, payload }
}

export function setProcesses(payload) {
  return { type: ACTIONS.SET_PROCESSES, payload }
}

export function addProcessToProcessesList(payload) {
  return { type: ACTIONS.ADD_PROCESS_TO_LIST, payload }
}

export function loadProcessViewer(payload) {
  return { type: ACTIONS.LOAD_PROCESSVIEWER, payload }
}

export function setDisplayedProcessPhase(payload) {
  return { type: ACTIONS.SET_DISPLAYED_PROCESS_PHASE, payload }
}

export function addProcessPhase(payload) {
  return { type: ACTIONS.ADD_PROCESS_PHASE, payload }
}

export function moveProcessPhase(payload) {
  return { type: ACTIONS.MOVE_PHASE, payload }
}

export function setInputNodeValue(payload) {
  return { type: ACTIONS.SET_INPUT_NODE_VALUE, payload }
}

export function deletePhase(payload) {
  return { type: ACTIONS.DELETE_PHASE, payload }
}

export function setProcessBuilderValidationErrors(payload) {
  return { type: ACTIONS.SET_PROCESSBUILDER_VALIDATION_ERRORS, payload }
}

export function addHistoricSensorData(payload) {
  return { type: ACTIONS.ADD_HISTORIC_SENSOR_DATA, payload }
}

export function setLoadingMoreHistoricSensorDataFlag(payload) {
  return { type: ACTIONS.SET_LOADING_MORE_HISTORIC_SENSOR_DATA_FLAG, payload }
}

export function switchProcessPhase(payload) {
  return { type: ACTIONS.SWITCH_PROCESS_PHASE, payload }
}

export function addLiveSensorData(payload) {
  return { type: ACTIONS.ADD_LIVE_SENSOR_DATA, payload }
}

export function setProcessFctImgs(payload) {
  return { type: ACTIONS.SET_PROCESS_FCT_IMGS, payload }
}

export function addProcessFctImgs(payload) {
  return { type: ACTIONS.ADD_PROCESS_FCT_IMGS, payload }
}

export function removeNotifications(payload) {
  return { type: ACTIONS.REMOVE_NOTIFICATIONS, payload }
}

export function createProcessDescriptionSnapshot(payload) {
  return { type: ACTIONS.CREATE_PROCESS_DESCRIPTION_SNAPSHOT, payload }
}

export function updateProcessDescriptionSnapshot(payload) {
  return { type: ACTIONS.UPDATE_PROCESS_DESCRIPTION_SNAPSHOT, payload }
}

export function resetProcessDescriptionSnapshots() {
  return { type: ACTIONS.RESET_PROCESS_DESCRIPTION_SNAPSHOTS }
}

export function removeNotificationsByTopic(payload) {
  return { type: ACTIONS.REMOVE_NOTIFICATIONS_BY_TOPIC, payload }
}

export function addFlashMessage(payload) {
  return { type: ACTIONS.ADD_FLASHMESSAGE, payload }
}

export function deleteFlashMessage(payload) {
  return { type: ACTIONS.DELETE_FLASHMESSAGE, payload }
}

export function addUserAlert(payload) {
  return { type: ACTIONS.ADD_USER_ALERT, payload }
}

export function updateUser(payload) {
  return { type: ACTIONS.UPDATE_USER, payload }
}

export function removeUserAlertById(payload) {
  return { type: ACTIONS.DELETE_USER_ALERT, payload }
}

export function deleteAllUserDeviceAlerts(payload) {
  return { type: ACTIONS.DELETE_USER_ALERTS_BY_DEVICE_ID, payload }
}

export function setSensorDataErrorFlag(payload) {
  return { type: ACTIONS.SET_SENSOR_DATA_ERROR_FLAG, payload }
}

export function collapseSidebar(payload) {
  return { type: ACTIONS.COLLAPSE_SIDEBAR, payload }
}

export function setSupportUsers(payload) {
  return { type: ACTIONS.SUPPORT_USERS, payload }
}

export function createPhaseGroup(payload) {
  return { type: ACTIONS.CREATE_PHASE_GROUP, payload }
}

export function addPhaseWithinGroup(payload) {
  return { type: ACTIONS.ADD_PHASE_WITHIN_GROUP, payload }
}

export function deletePhaseWithinGroup(payload) {
  return { type: ACTIONS.DELETE_PHASE_WITHIN_GROUP, payload }
}

export function setPhaseGroupIterationsCount(payload) {
  return { type: ACTIONS.SET_PHASE_GROUP_ITERATIONS_COUNT, payload }
}

export function setPhaseProps(payload) {
  return { type: ACTIONS.SET_PHASE_PROPS, payload }
}

export function prependPhaseToGroup(payload) {
  return { type: ACTIONS.PREPEND_PHASE_TO_GROUP, payload }
}

export function appendPhaseToGroup(payload) {
  return { type: ACTIONS.APPEND_PHASE_TO_GROUP, payload }
}

export function setDownloadRequests(payload) {
  return { type: ACTIONS.SET_DOWNLOAD_REQUESTS, payload }
}

export function updateOrCreateDownloadRequest(payload) {
  return { type: ACTIONS.UPDATE_OR_CREATE_DOWNLOAD_REQUEST, payload }
}

export function updatePusherConnectionState(payload) {
  return { type: ACTIONS.UPDATE_PUSHER_CONNECTION_STATE, payload }
}

export function setFunctionalityDescriptions(payload) {
  return { type: ACTIONS.SET_FUNCTIONALITY_DESCRIPTIONS, payload }
}
