import nexus from '@ospin/nexus'
import { updateOrCreateDownloadRequest } from '~/redux/actions/actions'
import DownloadRequest from '~/utils/process/DownloadRequest'

const callCreateDownloadRequest = async (dispatch, requestType, processId, reporterFctIds) => {
  const { data: request } = requestType === DownloadRequest.TYPES.SENSOR_DATA
    ? await nexus.dataPoints.downloadRequest.createForProcess(
      processId,
      { reporterFctIds },
    ) : await nexus.process.reports.create(processId)
  dispatch(updateOrCreateDownloadRequest({
    request: {
      ...request,
      /* this flag prevents auto-starting download requests in other windows/tabs */
      dispatchedFromThisTab: true,
    },
  }))
}

export default callCreateDownloadRequest
