import React, { useState } from 'react'
import { Input, Popup } from 'semantic-ui-react'
import { setPhaseGroupIterationsCount } from '~/redux/actions/actions'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import { connect } from 'react-redux'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import DescriptionParser from '~/utils/process/DescriptionParser'
import { Process } from '~/utils/process'
import PDVC from '~/utils/PDVC'
import Authorizer from '~/utils/Authorizer'

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchSetPhaseGroupIterationsCount: (processId, groupName, count) =>
    dispatch(setPhaseGroupIterationsCount({ processId, groupName, count })),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

const ITERATIONS_CONSTANTS = { MAX: 100 }

const disabledInputText = `
  This input is disabled because you have modified the running phase of this group.
  You can either apply your changes to the running phase first and then apply your
  changes to the iterations in a second step or increase the iterations before modifying the running phase
`
function PhaseIterationCounter({
  activeProcess,
  groupName,
  dispatchSetPhaseGroupIterationsCount,
  dispatchCallUpdateProcess,
  processDescriptionSnapshots,
  user,
}) {
  const count = PhaseGroupParser.getGroupIterationsCount(activeProcess, groupName)
  const groupIsRunning = groupName === Process.getRunningGroup(activeProcess, groupName)
  const [isIterationsError, setIterationsError] = useState(false)
  const [isTakingInput, setIsTakingInput] = useState(false)
  const [iterationsInput, setIterationsInput] = useState('')

  const getMinimumAllowedIterationsCount = () => (
    PhaseGroupParser.getFinishedIterationsCount(activeProcess, groupName) + 1
  )

  const isValidIterationsCount = newIterationCount => {
    const minIreations = getMinimumAllowedIterationsCount()

    return (newIterationCount < ITERATIONS_CONSTANTS.MAX
    && newIterationCount >= minIreations && !isNaN(newIterationCount))
  }

  const save = async () => {
    if (Process.isExecutable(activeProcess) && !isIterationsError) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const submit = async () => {
    if (!isIterationsError && iterationsInput !== '') {
      dispatchSetPhaseGroupIterationsCount(activeProcess.id, groupName, iterationsInput)
      await save()
    }

    setIterationsError(false)
    setIsTakingInput(false)
    setIterationsInput('')
  }

  const onChangeIterationsInput = async e => {
    const { target: { value } } = e
    setIsTakingInput(true)
    setIterationsInput(value)
    setIterationsError(false)

    const newIterationCount = Number(value)

    if (!isValidIterationsCount(newIterationCount)) {
      setIterationsError(true)
    }
  }

  const getDisplayedTargetValue = () => (isTakingInput ? iterationsInput : count)

  const renderProgression = () => {
    if (Process.isExecutable(activeProcess)) return null
    const { finished, total } = PhaseGroupParser
      .getGroupIterationsProgression(activeProcess, groupName)
    const offset = groupIsRunning ? 1 : 0
    const iterationPlaceholder = total > 1 ? 'iterations' : 'iteration'

    return <span style={{ marginTop: '5px', marginRight: '11px' }}>{`${finished + offset}/${total} ${iterationPlaceholder}`}</span>
  }

  const showInputField = () => {
    if (Process.isFinished(activeProcess)) return false
    if (Process.isExecutable(activeProcess)) return true
    if (!PhaseGroupParser.hasGroupFinished(activeProcess, groupName)) return true
    return false
  }

  const disableInputField = () => {
    if (Process.isFinished(activeProcess)) return true
    if (Process.isExecutable(activeProcess)) return false

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)
    const runningPhase = Process.getLatestPhaseProgressionEntry(activeProcess)
    const phase = DescriptionParser.getPhaseById(activeProcess, runningPhase.phaseId)

    if (!phase) return true

    const { groupName: runningPhaseGroupName } = phase

    if (!runningPhaseGroupName || runningPhaseGroupName !== groupName) return false

    const runningPhaseChangedWithRequiredPhaseInsertion = diffs
      .some(({ phaseId, property }) => parseInt(phaseId, 10) === runningPhase.phaseId && property === 'inputNodeValues')

    return runningPhaseChangedWithRequiredPhaseInsertion
  }

  const renderProgressionRowText = showInput => {
    if (showInput) {
      return (
        <>
          <div style={{ flexGrow: 1 }} />
          <span style={{ marginTop: '5px', marginRight: '12px' }}>{renderProgression()}</span>
        </>
      )
    }

    return (
      <>
        <span style={{ marginTop: '5px', marginRight: '12px' }}>{renderProgression()}</span>
        <div style={{ flexGrow: 1 }} />
      </>
    )
  }

  const renderInputField = showInput => {
    const disableInput = disableInputField()

    if (!showInput) return null

    if (disableInput) {
      return (
        <Popup
          basic
          position='bottom right'
          trigger={(
            <div>
              <Input
                aria-label='iterations-input'
                onClick={e => e.stopPropagation()}
                value={count}
                type='number'
                size='mini'
                style={{ width: '100%', maxWidth: '100px', cursor: 'default' }}
                disabled
              />
            </div>
          )}
          content={disabledInputText}
        />
      )
    }

    return (
      <Input
        aria-label='iterations-input'
        onClick={e => { e.stopPropagation(); e.target.focus() }}
        value={getDisplayedTargetValue()}
        error={isIterationsError}
        onChange={onChangeIterationsInput}
        onBlur={submit}
        type='number'
        size='mini'
        style={{ maxWidth: '64px', marginRight: '8px' }}
        max={ITERATIONS_CONSTANTS.MAX}
        min={getMinimumAllowedIterationsCount()}
        disabled={Authorizer.isResourceViewer(activeProcess, user.id)}
      />
    )
  }

  const showInput = showInputField()

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
        {renderInputField(showInput)}
        {renderProgressionRowText(showInput)}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseIterationCounter)
