import { auth } from '@ospin/nexus'
import { OspinPusherClient } from '@ospin/pusher'
import { logout } from '~/redux/actions/actions'

const callLogout = async (dispatch, { global = false } = {}) => {
  await auth.signOut({ global })
  OspinPusherClient.disconnect()
  dispatch(logout())
}

export default callLogout
