import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import FeatureVersioning from '~/utils/FeatureVersioning'
import { DeviceProcessStreamingDataPusherChannel } from '@ospin/pusher'
import { FCTGraph } from '@ospin/fct-graph'
import { Container, Grid, Message } from 'semantic-ui-react'
import PhaseGroupRenderer from '~/utils/render/PhaseGroupRenderer'
import ProcessViewerBody from '~/components/processviewer/body/ProcessViewerBody'
import { Process, EXECUTED_PROCESS_STATES } from '~/utils/process'
import GraphTools from '~/utils/GraphTools'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import callLoadProcessDataSummarySensorWise from '~/redux/thunks/process/callLoadProcessDataSummarySensorWise'
import {
  updateProcess,
  loadProcessViewer,
  resetProcessDescriptionSnapshots,
} from '~/redux/actions/actions'
import StopProcessModal from '~/components/processviewer/modals/StopProcessModal'
import PauseProcessModal from '~/components/processviewer/modals/PauseProcessModal'
import NextPhaseModal from '~/components/processviewer/modals/NextPhaseModal'
import deviceProcessStreamingDataChannelHandler from '~/redux/pusher/deviceProcessStreamingDataChannel/deviceProcessStreamingDataChannelHandler'
import ProcessDetailsModal from '~/components/device/modals/ProcessDetailsModal'
import PhasesNavbarWrapper from './phasesdisplaybar/PhasesNavbarWrapper'
import DescriptionMismatchModal from './modals/DescriptionMismatchModal'
import ProcessViewerHeader from './headers/ProcessViewerHeader'

const mapStateToProps = state => ({
  processBuilderValidationErrors: state.processBuilderValidationErrors,
  logs: state.logs,
  userId: state.user.id,
})

const mapDispatchToProps = dispatch => ({
  dispatchUpdateProcess: (processId, params) => dispatch(updateProcess({ processId, params })),
  dispatchCallLoadProcessDataSummarySensorWise: process => callLoadProcessDataSummarySensorWise(dispatch, process),
  dispatchLoadProcessViewer: process => dispatch(loadProcessViewer(process)),
  dispatchResetProcessDescriptionSnapshots: () => dispatch(resetProcessDescriptionSnapshots()),
})

class ProcessViewer extends React.Component {

  state = {
    showDescriptionMismatchModal: false,
    missMatches: {},
    amendingProcess: false,
    amendingError: false,
    amendingErrorMessage: '',
  }

  async componentDidMount() {
    await this.fetchNewProcessDataAndSubscribe()
  }

  async componentDidUpdate(prevProps) {
    const { activeProcess } = this.props
    if (activeProcess.id !== prevProps.activeProcess.id) {
      await this.fetchNewProcessDataAndSubscribe()
    }
  }

  componentWillUnmount() {
    const {
      activeProcess,
      activeDevice,
      dispatchResetProcessDescriptionSnapshots,
    } = this.props

    DeviceProcessStreamingDataPusherChannel
      .unsubscribe({ deviceId: activeDevice.id, processId: activeProcess.id })
    dispatchResetProcessDescriptionSnapshots()
  }

  fetchNewProcessDataAndSubscribe = async () => {
    const {
      activeProcess,
      activeDevice,
      dispatchLoadProcessViewer,
      dispatchCallLoadProcessDataSummarySensorWise,
    } = this.props

    dispatchLoadProcessViewer(activeProcess)
    if (Process.isExecutable(activeProcess)) {
      const isMismatch = ProcessValidator.isMismatchingDevice(activeProcess, activeDevice)

      if (isMismatch) {
        this.setState({ showDescriptionMismatchModal: true })
      }
    } else {
      await dispatchCallLoadProcessDataSummarySensorWise(activeProcess)
    }

    if (!(activeProcess.state === EXECUTED_PROCESS_STATES.finished)) {
      DeviceProcessStreamingDataPusherChannel
        .subscribe(
          { deviceId: activeDevice.id, processId: activeProcess.id },
          deviceProcessStreamingDataChannelHandler(),
        )
    }
  }

  toggleModal = (e, modalTrigger) => {
    this.setState(prevState => ({ [modalTrigger]: !prevState[modalTrigger] }))
  }

  renderPhasesSideBar = () => {

    const { activeProcess, activeDevice, moveProcessPhase } = this.props

    return (
      <PhasesNavbarWrapper
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        phases={PhaseGroupRenderer.getGroupAdjustedPhaseSequence(activeProcess)}
        movePhaseHandler={moveProcessPhase}
        runningPhase={Process.getLatestPhaseProgressionEntry(activeProcess)}
      />
    )
  }

  mapErrorTypeToReable = type => {
    const { VALIDATION_ERRORS } = ProcessValidator
    switch (type) {
      case VALIDATION_ERRORS.TRANSITION_ERROR:
        return 'Error in transition method'
      case VALIDATION_ERRORS.INPUT_VALUE_ERROR:
        return 'Error in channel value'
      default:
        return 'Error in process configuration'
    }
  }

  mapErrorsToString = error => {
    const { type, phaseName, groupName, message } = error
    const errorTypeText = this.mapErrorTypeToReable(type)
    const displayedPhaseName = phaseName ? `in phase '${phaseName}'` : ''
    const displayedGroupName = groupName ? ` (${groupName})` : ''
    return `${errorTypeText} ${displayedPhaseName}${displayedGroupName}: ${message}`
  }

  getPossibleFctGraphs = () => {
    const { activeDevice, match: { params: { fctGraphId } } } = this.props

    if (!FeatureVersioning.supportsMultipleProcesses(activeDevice)) {
      return activeDevice.fctGraphs
    }

    const possibleGraphs = activeDevice.fctGraphs
      .filter(graph => FunctionalityGraph.deviceCanExecuteGraph(graph, activeDevice))

    if (!fctGraphId) return possibleGraphs
    return possibleGraphs.filter(graph => graph.id === fctGraphId)
  }

  render() {

    const {
      processBuilderValidationErrors,
      logs,
      activeProcess,
      activeDevice,
    } = this.props

    const {
      showDescriptionMismatchModal,
      amendingError,
      amendingErrorMessage,
      amendingProcess,
      showPauseProcessModal,
      showStopProcessModal,
      showNextPhaseModal,
      showProcessDetailsModal,
    } = this.state

    const errorDisplay = processBuilderValidationErrors.map(this.mapErrorsToString)
    const possibleFctGraphs = this.getPossibleFctGraphs()

    return (
      <>
        {showPauseProcessModal && (
          <PauseProcessModal
            open={showPauseProcessModal}
            closeHandler={e => this.toggleModal(e, 'showPauseProcessModal')}
            activeDevice={activeDevice}
            activeProcess={activeProcess}
            headerText='Pause process'
          />
        )}
        {showStopProcessModal && (
          <StopProcessModal
            open={showStopProcessModal}
            activeProcess={activeProcess}
            closeHandler={e => this.toggleModal(e, 'showStopProcessModal')}
            headerText='Stop process'
          />
        )}
        {showNextPhaseModal && (
          <NextPhaseModal
            open={showNextPhaseModal}
            activeProcess={activeProcess}
            closeHandler={e => this.toggleModal(e, 'showNextPhaseModal')}
            headerText='Go to next phase'
          />
        )}
        {showProcessDetailsModal && (
          <ProcessDetailsModal
            toggleModal={e => this.toggleModal(e, 'showProcessDetailsModal')}
            showProcessDetailsModal={showProcessDetailsModal}
            activeProcess={activeProcess}
            processesToDelete={[ activeProcess ]}
            requiresRedirect
            activeDevice={activeDevice}
            possibleFctGraphs={possibleFctGraphs}
            initialTab='Edit'
          />
        )}
        <Grid style={{ minWidth: '750px' }}>
          {processBuilderValidationErrors.length > 0 && (
            <Grid.Row>
              <Container fluid>
                <Message error>
                  <Message.Header>
                    Process could not be dispatched.
                  </Message.Header>
                  <Message.List items={errorDisplay} />
                </Message>
              </Container>
            </Grid.Row>
          )}
          <Grid.Row textAlign='justified' verticalAlign='bottom'>
            <ProcessViewerHeader
              toggleModal={this.toggleModal}
              activeProcess={activeProcess}
              activeDevice={activeDevice}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={13}>
              <ProcessViewerBody
                phases={PhaseGroupRenderer.getGroupAdjustedPhaseSequence(activeProcess)}
                activeProcess={activeProcess}
                activeDevice={activeDevice}
                logs={logs}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              {this.renderPhasesSideBar()}
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid.Row>
          {showDescriptionMismatchModal && (
            <DescriptionMismatchModal
              open
              closeHandler={e => this.toggleModal(e, 'showDescriptionMismatchModal')}
              headerText='Process description does not match the device'
              activeDevice={activeDevice}
              amendingError={amendingError}
              amendingErrorMessage={amendingErrorMessage}
              amendingProcess={amendingProcess}
            />
          )}
        </Grid>
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProcessViewer))
