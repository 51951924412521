import React from 'react'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseProgression from '~/utils/process/PhaseProgression'

import SlotUtils from '~/utils/functionalities/slots/Slot'
import ActuatorWidgetBody from './ActuatorWidgetBody'

import './ActuatorWidget.css'

const ActuatorWidget = ({
  activeProcess,
  widget,
  removeWidget,
}) => {

  const { fctGraph } = activeProcess
  const { fctId, slotName } = widget

  const fct = FCTGraph.getFctById(fctGraph, fctId)
  const slot = Functionality.getSlotByName(fct, slotName)

  const inputNodeFctId = FCTGraph
    .getInputNodeFctIdForSlot(fctGraph, fct.id, slot.name)

  const phase = PhaseProgression.getLatest(activeProcess)
  const value = phase ? DescriptionParser
    .getInputNodeValue(activeProcess, phase.phaseId, inputNodeFctId) : null
  const image = SlotUtils.getImage(slot)

  return (
    <div className='actuator-widget'>
      <ActuatorWidgetBody
        removeWidget={removeWidget}
        image={image}
        fctName={fct.name}
        value={value}
        displayedName={Slot.getDisplayName(slot)}
        displayedValue={value}
        unit={slot.unit}
      />
    </div>
  )
}

export default ActuatorWidget
