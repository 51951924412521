import CalibrationMode from '~/utils/calibration/CalibrationMode'
import DataManager from '~/utils/DataManager'

class OffsetOnlyMode extends CalibrationMode {

  static calculateParams(componentState) {

    const { pointSelectionOffset } = componentState

    const { offsetRangeAvrgReal, offsetRangeReference } = pointSelectionOffset
    const parsedOffset = parseFloat(offsetRangeReference) - offsetRangeAvrgReal

    if (Number.isNaN(parsedOffset)) {
      return { error: ('Offset is not a number.') }
    }

    return { offset: parsedOffset, slope: 1 }
  }

  static selectParams({ offset }) {
    return { offset: { value: offset } }
  }

  static getNextField(currField) {
    const nextFields = { startTime: 'endTime' }

    return nextFields[currField]
  }

  static getUpdatedPointSelection(componentState, time) {
    const { pointSelectionOffset, selectedField } = componentState

    if (selectedField === 'endTime'
      && pointSelectionOffset.startTime !== '') {
      if (time <= pointSelectionOffset.startTime) {
        return
      }
    }

    if (selectedField === 'startTime'
      && pointSelectionOffset.endTime !== '') {
      if (time >= pointSelectionOffset.endTime) {
        return
      }
    }

    const nextField = this.getNextField(selectedField)
    return {
      params: {
        pointSelectionOffset: {
          ...pointSelectionOffset,
          [selectedField]: time,
        },
      },
      nextField,
    }
  }

  static getUpdatedAverage(componentState, componentProps, rawData) {
    const {
      activeProcess,
      slot,
      functionality,
    } = componentProps
    const { pointSelectionOffset } = componentState

    const {
      startTime,
      endTime,
      offsetRangeAvrgDisplayed,
    } = pointSelectionOffset

    const fctId = functionality.id
    const slotName = slot.name

    const newAvrg = this.getAverage(rawData, startTime, endTime)
    const decalibratedData = this
      .reverseCalibrationOnSlice(rawData, startTime, endTime, activeProcess, fctId, slotName)
    const realNewAvrg = this.getAverage(decalibratedData, startTime, endTime)

    return {
      params: {
        pointSelectionOffset: {
          ...pointSelectionOffset,
          offsetRangeAvrgDisplayed: newAvrg || offsetRangeAvrgDisplayed,
          offsetRangeAvrgReal: realNewAvrg,
        },
      },
    }
  }

  static attachGraphSlice(sensorData, componentState) {

    const { pointSelectionOffset } = componentState
    const { startTime, endTime } = pointSelectionOffset

    if (startTime !== '' && endTime !== '') {
      const slice = DataManager.valuesWithinTimeRange(sensorData, startTime, endTime)
      return [ this.createGraphSlice(slice, { color: 'red', label: 'Selected' }) ]
    }
    return []
  }
}

export default OffsetOnlyMode
