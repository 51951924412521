import React, { useState, useEffect } from 'react'
import nexus from '@ospin/nexus'
import { Button, Checkbox, Loader, Message, Table } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router-dom'
import TemplateTableRow from './TemplateTableRow'
import DeleteTemplateModal from './DeleteTemplateModal'

export default function TemplateTable({setSelectedGraphId, selectedGraph, activeDevice}) {
  const [isLoading, setIsLoading] = useState(true)
  const [fctGraphTemplates, setFctGraphTemplates] = useState([])
  const [ selectedTemplateId, setSelectedTemplateId ] = useState(null)
  const [ showDeleteTemplateModal, setShowDeleteTemplateModal ] = useState(false)

  const history = useHistory()

  const { deviceId } = useParams()

  useEffect(() => {
    async function fetchTemplates() {
      setIsLoading(true)
      try {
        const { data: { fctGraphTemplates: responseTemplates } } = await nexus.deviceDescription.template.list({ deviceId })
        setFctGraphTemplates(responseTemplates)
        setIsLoading(false)
      } catch (error) {
        // TODO error handling
      }
    }

    fetchTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader inline active />
      </div>
    )
  }

  function renderContent() {
    return fctGraphTemplates.length === 0 ? (
      <Message
        info
        content='No Ultimate Templates have been created for this device'
      />
    ) : (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fctGraphTemplates.map(template => (
            <TemplateTableRow
              key={template.id}
              template={template}
              setShowDeleteTemplateModal={setShowDeleteTemplateModal}
              setSelectedTemplateId={setSelectedTemplateId}
            />
          ))}
        </Table.Body>
      </Table>
    )
  }

  function redirectToCreate() {
    history.push(`/devices/${deviceId}/setups/create`)
  }

  function removeTemplateFromList(templateId) {
    if (selectedGraph.templateId === templateId) {
      const nonAffectedGraph = activeDevice.fctGraphs.find((
        { templateId: graphTemplateId }) => templateId !== graphTemplateId,
      )
      setSelectedGraphId(nonAffectedGraph.id)
    }
    setFctGraphTemplates(fctGraphTemplates.filter(({ id }) => id !== templateId))

  }

  return (
    <>
      { showDeleteTemplateModal
        ? (
          <DeleteTemplateModal
            activeDevice={activeDevice}
            show={showDeleteTemplateModal}
            close={() => setShowDeleteTemplateModal(false)}
            template={fctGraphTemplates.find(({ id }) => id === selectedTemplateId)}
            removeTemplateFromList={id => removeTemplateFromList(id)}
          />
        ) : null }
      {renderContent()}
      <Button
        content='Create New Template'
        onClick={() => redirectToCreate()}
      />
    </>
  )
}
