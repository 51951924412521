import React from 'react'
import modalize from '~/components/utility/modal/modalize'
import AlertsDeleteModalForm from '~/components/alerts/AlertsDeleteModalForm'

const AlertsDeleteModal = ({
  closeHandler,
  alertCount,
  deleteAllDeviceAlerts,
  loading,
}) => (
  <AlertsDeleteModalForm
    closeHandler={closeHandler}
    submitHandler={deleteAllDeviceAlerts}
    alertCount={alertCount}
    loading={loading}
  />
)

export default modalize(AlertsDeleteModal)
