import { FCTGraph, Functionality } from '@ospin/fct-graph'
import Widget from './Widget'

const TYPE = 'lineChart'
const DISPLAY_NAME = 'Line Chart'
const DESCRIPTION = 'Displays the times series of one or more sensors'
const USE_DATASOURCES = true

const getCompatibleSlots = (fct, dataSources) => {
  const outSlotsFct = Functionality.getOutSlots(fct)

  if (dataSources.length === 0) {
    return outSlotsFct
  }

  const removeAlreadySelectedSlots = slot => (
    !dataSources.some(ds => ds.slotName === slot.name && ds.fctId === fct.id)
  )

  return outSlotsFct
    .filter(removeAlreadySelectedSlots)
}

const getCompatibleFcts = (fctGraph, dataSources) => (
  FCTGraph.getFctsWithoutIONodes(fctGraph)
    .filter(fct => {
      const compatibleSlots = getCompatibleSlots(fct, dataSources, fctGraph)
      return compatibleSlots.length
    })
)

const createDefaultWidget = ({ dataSources }) => ({
  type: TYPE,
  dataSources,
  display: {
    dimension: {
      minHeight: 200,
      maxHeight: 680,
      minWidth: 200,
      maxWidth: 1200,
      height: 340,
      width: 400,
    },
    ...Widget.generateDisplayDefaults(),
  },
  ...Widget.generateDefaults(),
})

const addDataSource = (widget, dataSource) => ({
  ...widget,
  dataSources: [
    ...widget.dataSources,
    dataSource,
  ],
})

const removeDataSource = (widget, dataSourceIndex) => ({
  ...widget,
  dataSources: widget.dataSources.filter((_, idx) => idx !== dataSourceIndex),
})

const updateDataSource = (widget, dataSourceIndex, newLineColor) => {
  const updatedDataSource = widget.dataSources.map((dataSource, idx) => {
    if (idx === dataSourceIndex) {
      return { ...dataSource, lineColor: newLineColor }
    }
    return dataSource
  })

  return { ...widget, dataSources: updatedDataSource }
}

const hasRequiredParamsForCreation = params => params.dataSources.length > 0

export default {
  DESCRIPTION,
  DISPLAY_NAME,
  TYPE,
  USE_DATASOURCES,
  addDataSource,
  createDefaultWidget,
  getCompatibleFcts,
  getCompatibleSlots,
  hasRequiredParamsForCreation,
  removeDataSource,
  updateDataSource,
}
