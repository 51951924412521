import React, { useState } from 'react'
import { connect } from 'react-redux'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import FlashMessenger from '~/utils/FlashMessenger'
import DeleteDashboardConfirmationModalForm from './DeleteDashboardConfirmationModalForm'

const mapStateToProps = ({ user }) => ({ user })

const DeleteDashboardConfirmationModal = props => {
  const {
    user,
    closeHandler,
    setSelectedDashboardId,
    dashboard,
  } = props

  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)

  const { fctGraphUIConfig: { configs, fctGraphId } } = user
  const { dashboards } = configs

  const deleteSelectedDashboard = async () => {
    try {
      setIsLoading(true)
      setIsError(false)
      const updatedDashboards = GraphDashboards.deleteDashboard(dashboards, dashboard.id)
      // set to display last dashboard after deletion
      setSelectedDashboardId(updatedDashboards[updatedDashboards.length - 1].id)
      await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
      FlashMessenger.success('Dashboard has been successfully deleted')
    } catch ({ message }) {
      setIsError(true)
      setErrorMessage(message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DeleteDashboardConfirmationModalForm
      isError={isError}
      errorMessage={errorMessage}
      closeHandler={closeHandler}
      submitHandler={deleteSelectedDashboard}
      loading={isLoading}
      closeOnSubmit
    />
  )
}

export default connect(mapStateToProps)(DeleteDashboardConfirmationModal)
