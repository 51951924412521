import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Icon, Popup } from 'semantic-ui-react'
import HelpIconPopup from '~/components/utility/HelpIconPopup'

import UI_CONSTANTS from '~/config/ui'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PDVC from '~/utils/PDVC'
import Time from '~/utils/Time'
import Phase from '~/utils/process/Phase'
import { Process } from '~/utils/process'

const TIMER_STYLE = {
  width: 'fit-content',
  margin: '3px 0px 7px 0px',
  opacity: '0.6',
  height: '15px',
}

const mapStateToProps = state => ({ snapshots: state.processDescriptionSnapshots })

const getDisplayTimeLeft = remainingTimeInMS => {
  const {
    days,
    hours,
    minutes,
    seconds,
  } = Time.getDisplayableDaysHoursMinutesSeconds(remainingTimeInMS)

  return `${days}:${hours}:${minutes}:${seconds}`
}

const PhaseTimer = ({ activeProcess, displayPhase }) => {
  const [remainingTimeInMS, setRemainingTimeInMS] = useState(null)

  useEffect(() => {
    const myInterval = setInterval(() => {
      const remainingDurationMS = Phase.getRemainingMSDurationWithinProcess(
        displayPhase,
        activeProcess,
      )
      setRemainingTimeInMS(remainingDurationMS)
    }, 150)

    return () => clearInterval(myInterval)
  }, [activeProcess, displayPhase])

  return (
    <Popup
      mouseEnterDelay={UI_CONSTANTS.POPUP_DELAY_MED}
      content={`Remaining duration of ${displayPhase.name}`}
      trigger={(
        <div style={TIMER_STYLE}>
          <Icon name='clock outline' />
          { remainingTimeInMS ? getDisplayTimeLeft(remainingTimeInMS) : '...calculating'}
        </div>
      )}
    />
  )
}

const renderPhaseTimer = ({ displayPhase, activeProcess }) => (
  Phase.isTimeBased(displayPhase)
    ? (
      <PhaseTimer
        activeProcess={activeProcess}
        displayPhase={displayPhase}
      />
    ) : <div style={TIMER_STYLE}>Manual Transition</div>
)

const renderPresentRunningPhase = ({ displayPhase, activeProcess }) => (
  <>
    <div style={{ fontSize: '1.2rem' }}>
      <b>
        {displayPhase.name}
      </b>
    </div>
    { renderPhaseTimer({ displayPhase, activeProcess })}
  </>
)

const renderAbsentRunningPhase = () => (
  <div style={{ fontSize: '1rem', display: 'flex', justifyContent: 'space-between' }}>
    <b>
      Initializing Device...
    </b>
    <HelpIconPopup
      description='The Device has been started and the process is currently awaiting the first phase to start '
      position='bottom right'
      iconStyle={{
        marginLeft: '4px',
        marginRight: '0px',
      }}
    />
  </div>
)

const RunningPhaseDetails = ({ activeProcess, snapshots }) => {
  const runningPhase = Process.getRunningPhaseDetails(activeProcess)
  if (!runningPhase) {
    return (
      <div style={{ marginBottom: '7.5px' }}>
        {
          renderAbsentRunningPhase()
        }
      </div>
    )
  }
  const snapshot = PDVC.getSnapshot(activeProcess.id, snapshots)
  const displayPhase = snapshot
    ? DescriptionParser.getPhaseByIdWithId(snapshot, runningPhase.id)
    : runningPhase

  return (
    <div style={{ marginBottom: '7.5px' }}>
      {
        renderPresentRunningPhase({ displayPhase, activeProcess })
      }
    </div>
  )
}

export default connect(mapStateToProps, null)(RunningPhaseDetails)
