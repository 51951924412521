export default class ObjectTools {

  static getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value)
  }

  static removeKeysWithFalsyValuesOrEmptyArrays = obj => {
    const filteredParams = Object.keys(obj)
      .filter(key => (Array.isArray(obj[key]) ? obj[key].length : !!obj[key]))
      .reduce((res, key) => ({ ...res, [key]: obj[key] }), {})

    return filteredParams
  }

  static isObject(value) {
    return !(['string', 'number', 'boolean'].includes(typeof value) || Array.isArray(value))
  }

  static pickKeys(obj, props) {
    return props.reduce((acc, prop) => {
      acc[prop] = obj[prop]
      return acc
    }, {})
  }
}
