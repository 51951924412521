import { FCTGraph, Functionality, Ports } from '@ospin/fct-graph'
import FeatureVersioning from '~/utils/FeatureVersioning'
import { Process } from '~/utils/process'

class FunctionalityGraph {

  static getGraphById(graphs, graphId) {
    if (!graphs || !Array.isArray(graphs)) return
    return graphs.find(graph => graph.id === graphId)
  }

  static createReporterToFctAndSlotMap(fctGraph) {
    const reporters = FCTGraph.getIntervalOutFcts(fctGraph)

    return reporters.reduce((acc, reporter) => ({
      ...acc,
      [reporter.id]: {
        sourceSlotName: FCTGraph.getConnectingSourceSlot(fctGraph, reporter.id)?.name,
        sourceFctId: FCTGraph.getSourceFct(fctGraph, reporter.id)?.id,
      },
    }), {})
  }

  static deviceCanExecuteGraph(graph, device) {
    const physicalFcts = graph.functionalities.filter(Functionality.isPhysical)
    const hasAllPhysicalFcts = physicalFcts
      .every(fct => device.connectedPhysicalFunctionalities.some(conFct => (
        conFct.subType === fct.subType && Ports.areEqual(conFct.ports, fct.ports)
      )))

    const virtualFcts = graph.functionalities.filter(fct => !Functionality.isPhysical(fct))
    const hasAllVirtualFcts = virtualFcts
      .every(virtFct => device.supportedVirtualFunctionalities
        .some(fct => fct.subType === virtFct.subType))

    return hasAllPhysicalFcts && hasAllVirtualFcts
  }

  static singleFunctionalityIsConnected(device, functionality) {
    return device.connectedPhysicalFunctionalities.some(physFct => (
      physFct.subType === functionality.subType
        && Ports.areEqual(physFct.ports, functionality.ports)
    ))
  }

  static findSharedFcts(graphA, graphB) {
    function isEqual(a, b) {
      return a.type === b.type
        && a.subType === b.subType
        && Ports.areEqual(a.ports, b.ports)
    }

    const physicalFctsA = FCTGraph.getPhysicalFcts(graphA)
    const physicalFctsB = FCTGraph.getPhysicalFcts(graphB)
    return physicalFctsA.filter(fctA => physicalFctsB.some(fctB => isEqual(fctA, fctB)))
  }

  static findGraphsWithOverlappingPhysicalFcts(referenceGraph, graphs) {
    const overlappingGraphs = graphs.filter(
      graph => FunctionalityGraph.findSharedFcts(graph, referenceGraph).length,
    )
    return overlappingGraphs
  }

  static processPortsAreUpToDate(process) {
    const { usedPhysicalMapping, fctGraph } = process

    if (!FeatureVersioning.supportsMultipleProcesses(process)) return true

    if (!Process.isExecutable(process)) return true

    const physicalFcts = fctGraph.functionalities.filter(Functionality.isPhysical)

    return physicalFcts.every(physicalFct => {
      const fct = usedPhysicalMapping.functionalities
        .find(fctMapping => fctMapping.id === physicalFct.id)
      return Ports.areEqual(physicalFct.ports, fct.ports)
    })
  }

}

export default FunctionalityGraph
