import React from 'react'
import { Table } from 'semantic-ui-react'
import LogEntry from './LogEntry'

const LogList = ({ displayedLogs }) => (
  <Table.Body>
    {displayedLogs.map(deviceLog => (
      <LogEntry key={deviceLog.id} deviceLog={deviceLog} />
    ))}
  </Table.Body>
)

export default LogList
