import React from 'react'
import formalize from '~/components/utility/modal/formalize'
import { Message } from 'semantic-ui-react'

const renderProcessMessage = processes => (
  processes.length === 1 ? processes[0].name : `${processes.length} processes`
)

const renderProcessList = processes => {
  if (processes.length === 1) return null

  return (
    <ul>
      {processes.map(process => (
        <li key={process.id}>
          {process.name}
        </li>
      ))}
    </ul>
  )
}

const generateWarningMessage = (processes, disableSubmit) => {
  if (disableSubmit) {
    return 'Only process admins, device admins or device support users can delete a process'
  }
  return (processes.length > 1 ? 'The processes and all data related to them will be deleted unrecoverably'
    : 'The process and all data related to it will be deleted unrecoverably')
}

const getMessageHeader = (disableSubmit, processes) => {
  if (disableSubmit) {
    return processes.length > 1 ? 'Insufficient rights to delete one or more selected processes'
      : 'Insufficient rights to delete process'
  }
  return 'This action is irreversible'
}

const DeleteProcessModalForm = ({ processesToDelete, disableSubmit }) => (
  <div>
    <Message
      content={generateWarningMessage(processesToDelete, disableSubmit)}
      error
      visible
      header={getMessageHeader(disableSubmit, processesToDelete)}
      icon='warning'
      style={{ marginBottom: '12px' }}
    />
    {!disableSubmit ? `Are you sure you want to delete ${renderProcessMessage(processesToDelete)}?` : ''}
    {renderProcessList(processesToDelete)}
  </div>
)

export default formalize(DeleteProcessModalForm)
