import React from 'react'
import { Form, Select, Button } from 'semantic-ui-react'
import LineChart from '~/utils/UIConfig/widgets/LineChart'
import './DashboardWidgetFCTAndSlotSelection.css'

const DashboardWidgetFCTAndSlotSelection = ({
  slotOptions,
  fctOptions,
  selectedFctId,
  setSelectedFctId,
  selectedSlotName,
  setSelectedSlotName,
  showAddDataSource,
  addDataSource,
  selectedWidgetType,
  selectedLineColor,
  setSelectedLineColor,
}) => (
  <>
  <div style={{ marginBottom: '8px '}}>
    <b>Choose dataset</b>
  </div>
    <Form.Group inline>
      <Form.Field
        control={Select}
        value={selectedFctId}
        label='Functionality'
        disabled={!fctOptions.length}
        placeholder='Choose Functionality'
        options={fctOptions}
        onChange={(_, { value }) => {
          setSelectedFctId(value)
          setSelectedSlotName(null)
        }}
      />
      <Form.Field
        control={Select}
        label='Slot'
        value={selectedSlotName}
        disabled={!selectedFctId || !slotOptions.length}
        placeholder='Choose Slot'
        options={slotOptions}
        onChange={(_, { value }) => setSelectedSlotName(value)}
      />
      {selectedWidgetType === LineChart.TYPE ? (
        <Form.Field>
          <label htmlFor='lineColor'>Color</label>
          <input
            className='Dashb-Widget-FCT-Slot-Selection-input-color'
            id='chartLineColorSlotSelection'
            name='chartLineColorSlotSelection'
            type='color'
            onInput={({ target: { value } }) => setSelectedLineColor(value)}
            value={selectedLineColor}
            disabled={!selectedFctId || !slotOptions.length || !selectedSlotName}
          />
        </Form.Field>
      ) : null}
      {showAddDataSource ? (
        <Form.Field>
          <Button
            compact
            size='tiny'
            primary
            disabled={!selectedFctId || !selectedSlotName || !slotOptions.length}
            onClick={() => {
              addDataSource({
                fctId: selectedFctId,
                slotName: selectedSlotName,
                lineColor: selectedLineColor,
              })
              setSelectedSlotName(null)
            }}
            className='Dashb-Widget-FCT-Slot-Selection-Add-btn'
          >
            Add to chart
          </Button>
        </Form.Field>
      ) : null}
    </Form.Group>
  </>
)

export default DashboardWidgetFCTAndSlotSelection
