import store from '~/redux/store'
import { Process } from '~/utils/process'
import { updateProcess } from '~/redux/actions/actions'

export default (deviceId, { update, processId }) => {
  if (update.newPhase) {
    // we used to publish the whole "progression" array
    // within the update, however for processes with more than
    // 250 phases, pusher surrenders because it exceeds the payload
    // size of 10 kB. So now we publish only the newPhase and merge
    // it with the existing progression of the process

    const { processes } = store.getState()
    const process = Process.getById(processes, processId)

    if (!process) return

    update.progression = [ ...process.progression, update.newPhase ]
    delete update.newPhase
  }

  store.dispatch(updateProcess({ processId, params: update }))
}
