import { Slot } from '@ospin/fct-graph'
import React from 'react'
import { connect } from 'react-redux'
import { Checkbox, Icon, Input, Popup, Table } from 'semantic-ui-react'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import TemplateUtils from '../../Utils/TemplateUtils'
import FunctionalitySlotTableRowConnections from './FunctionalitySlotTableRowConnections'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })

function FunctionalitySlotTableRow({
  slot,
  targetPosId,
  template,
  updateTemplate,
  functionalityDescriptions,
}) {

  const isOutSlotTable = slot.type === Slot.TYPES.OUT_SLOT
  const functionality = TemplateUtils.getFctByPosId(template, { posId: targetPosId })

  const templateSlotName = TemplateUtils.getSlotDisplayName(
    template, { posId: targetPosId, slotName: slot.name },
  )

  const displayName = templateSlotName === null ? slot.name : templateSlotName

  const slotDescription = FunctionalityDescription.getSlotDescription(
    functionalityDescriptions, functionality.subType, slot.name,
  )

  function updateSlotDisplayName(slotName, newName) {
    const updatedTemplate = TemplateUtils.setSlotDisplayName(
      template, { posId: targetPosId, slotName, displayName: newName }
    )
    updateTemplate(updatedTemplate)
  }

  function removeSlotDisplayName() {
    const updatedTemplate = TemplateUtils.removeSlotDisplayName(
      template, { posId: targetPosId, slotName: slot.name }
    )
    updateTemplate(updatedTemplate)
  }

  function getConnectedReporter() {
    const { posId } = functionality
    const { name } = slot
    return TemplateUtils.getReporterNode(template, { posId, slotName: name })
  }

  const isConnected = !!getConnectedReporter()

  function removeReporter() {
    const updatedTemplate = TemplateUtils.removeReporterFctForSlot(
      template,
      { posId: functionality.posId, slotName: slot.name },
    )
    return updateTemplate(updatedTemplate)

  }

  function addReporter() {
    const updatedTemplate = TemplateUtils.addReporterFctForSlot(
      template,
      { posId: functionality.posId, slotName: slot.name },
    )
    updateTemplate(updatedTemplate)
  }

  function toggle() {
    if (isConnected) {
      removeReporter()
    } else {
      addReporter()
    }
  }

  const shouldDisplayClearSlotNameButton = templateSlotName !== null && templateSlotName !== slot.name

  function renderInput() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Input
          fluid
          value={displayName}
          onChange={e => updateSlotDisplayName(slot.name, e.target.value)}
          style={{ width: slotDescription ? '100%' : '100%' }}
          error={!displayName}
        />
        {shouldDisplayClearSlotNameButton
          ? (
            <Icon
              style={{position: 'relative', left: '-3.5rem', marginRight: '-3rem', top: '0'}}
              color='grey'
              size='large'
              name='remove'
              onClick={() => removeSlotDisplayName()}
            />
          )
          : null }

        {slotDescription ? <Popup content={slotDescription} trigger={<Icon name='question' size='large' />} /> : null}
      </div>
    )
  }
  return (
    <Table.Row key={slot.name}>
      <Table.Cell width={7}>
        {renderInput()}
      </Table.Cell>
      <Table.Cell>
        <FunctionalitySlotTableRowConnections
          slot={slot}
          template={template}
          targetPosId={targetPosId}
        />
      </Table.Cell>
      {isOutSlotTable ? (
        <Table.Cell>
          <Checkbox
            checked={isConnected}
            onClick={() => toggle()}
          />
        </Table.Cell>
      ) : null}
    </Table.Row>
  )
}

export default connect(mapStateToProps, null)(FunctionalitySlotTableRow)
