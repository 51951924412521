import React from 'react'
import { connect } from 'react-redux'

import FlashMessage from './FlashMessage'

const mapStateToProps = state => ({ flashMessages: state.flashMessages })

const FlashMessagesContainer = ({ flashMessages }) => (
  <div
    style={{
      position: 'fixed',
      bottom: '5%',
      right: '5%',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1000,
    }}
  >
    {flashMessages.map(message => (
      <FlashMessage
        key={message.id}
        message={message}
      />
    ))}
  </div>
)

export default connect(mapStateToProps)(FlashMessagesContainer)
