import nexus from '@ospin/nexus'
import { updateProcess, updateProcessDescriptionSnapshot } from '~/redux/actions/actions'

const callUpdateProcess = async (dispatch, processId, params) => {
  await nexus.process.update({ processId, params })
  dispatch(updateProcess({ processId, params }))
  if (params.description) {
    dispatch(updateProcessDescriptionSnapshot({ processId, params }))
  }
}

export default callUpdateProcess
