import store from '~/redux/store'
import { updateOrCreateDownloadRequest } from '~/redux/actions/actions'

const autoStartDownloadRequest = (request, user, downloadRequests) => {
  const { createdBy, state, id: reqId } = request
  const hasPendingRequestInThisTab = downloadRequests
    .some(({ id, dispatchedFromThisTab }) => id === reqId && dispatchedFromThisTab)

  if (createdBy !== user.userName) return

  if (state !== 'ready') return

  if (!hasPendingRequestInThisTab) return

  const a = document.createElement('a')
  a.href = request.downloadURL
  a.setAttribute('download', 'download')
  a.click()
}

export default request => {
  const { user, downloadRequests } = store.getState()
  autoStartDownloadRequest(request, user, downloadRequests)

  store.dispatch(updateOrCreateDownloadRequest({ request }))
}
