import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Report from '~/components/utility/Report'
import ErrorWrapperProvider from '~/components/utility/ErrorWrapperProvider'
import FlashMessagesContainer from '~/components/utility/FlashMessagesContainer'
import ConfirmSignUp from '~/components/authentication/signUp/ConfirmSignUp'
import Main from '../main/Main'
import Login from '../authentication/Login'
import SignUp from '../authentication/signUp/SignUp'
import ResetPassword from '../authentication/ResetPassword/ResetPassword'
import PrivateRoute from './PrivateRoute'

const Routes = () => (

  <BrowserRouter forceRefresh={false}>
    <ErrorWrapperProvider>
      <FlashMessagesContainer />
      <div>
        <Switch>
          <PrivateRoute exact path='/' component={Main} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/sign-up' component={SignUp} />
          <Route exact path='/reset-password' component={ResetPassword} />
          <Route exact path='/confirm-sign-up' component={ConfirmSignUp} />
          <Route exact path='/report' component={Report} />
          <PrivateRoute path='/' component={Main} />
        </Switch>
      </div>
    </ErrorWrapperProvider>
  </BrowserRouter>
)

export default Routes
