import React, { useState } from 'react'
import { connect } from 'react-redux'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import DashboardValidator from '~/utils/validation/DashboardValidation'
import DashboardDetailsModalForm from './DashboardDetailsModalForm'

const mapStateToProps = ({ user }) => ({ user })

const SUCCESS_NEW_DASHB_MSG = 'The new dashboard has been created sucessfully'
const SUCCESS_UPDATE_MSG = 'The dashboard has been updated sucessfully'

const SaveDashboardModal = ({
  closeHandler,
  dashboard,
  setSelectedDashboardId,
  user,
  modalType,
}) => {

  const isEditModal = modalType === 'edit'

  const [modalDashboard, setModalDashboard] = useState(isEditModal ? { ...dashboard } : { name: '', description: '' })
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { fctGraphUIConfig: { configs, fctGraphId } } = user
  const { dashboards } = configs

  const handleUpdate = (_, { name, value }) => {
    setModalDashboard({ ...modalDashboard, [name]: value })
    setIsError(false)
    setErrorMessage('')
  }

  const createDashboard = async () => {
    const updatedDashboards = GraphDashboards.createDashboard(
      dashboards,
      { name: modalDashboard.name, description: modalDashboard.description },
    )

    await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
    setSelectedDashboardId(dashboards[dashboards.length - 1].id)
    setIsSuccess(true)
    setSuccessMessage(SUCCESS_NEW_DASHB_MSG)
  }

  const updateDashboard = async () => {
    const updatedDashboards = GraphDashboards
      .updateDashboard(dashboards, modalDashboard)

    await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
    setIsSuccess(true)
    setSuccessMessage(SUCCESS_UPDATE_MSG)
  }

  const saveDashboard = async () => {
    setLoading(true)
    try {
      if (isEditModal) {
        await updateDashboard()
      } else {
        await createDashboard()
      }
    } catch ({ message }) {
      setIsError(true)
      setErrorMessage(message)
      setIsSuccess(false)
      setSuccessMessage('')
    } finally {
      setLoading(false)
    }
  }

  return (
    <DashboardDetailsModalForm
      closeHandler={closeHandler}
      modalDashboard={modalDashboard}
      submitText={isEditModal ? 'Save' : 'Confirm'}
      submitHandler={saveDashboard}
      handleUpdate={handleUpdate}
      isError={isError}
      errorMessage={errorMessage}
      isSuccess={isSuccess}
      successMessage={successMessage}
      loading={loading}
      disableSubmit={!DashboardValidator.isValid(modalDashboard)}
    />
  )
}

export default connect(mapStateToProps)(SaveDashboardModal)
