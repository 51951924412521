import { updatePusherConnectionState } from '~/redux/actions/actions'
import syncHelper from './syncHelper'

/* Firefox disconnects the web socket for a brief period when
/* triggering automatic downloads (known bug), so we delay
/* dispatching the disconnection update of the client by a brief period
* and cancel it completely if the client reconnects within a second */

let delayIntervalId = null
let wasDisconnected = false
const shouldBeDelayed = navigator.userAgent.includes('Firefox')

export default dispatch => states => {
  if (states.previous === 'initialized' && states.current === 'failed') {
    const error = 'UNSUPPORTED_BROWSER'
    dispatch(updatePusherConnectionState({ error, connected: false }))
    return
  }

  if ((states.previous === 'connecting' && states.current === 'unavailable')
   || (states.previous === 'connected' && states.current === 'connecting')) {
    const error = 'DISCONNECTED'
    wasDisconnected = true

    if (shouldBeDelayed) {
      delayIntervalId = setTimeout(
        () => {
          dispatch(updatePusherConnectionState({ error, connected: false }))
        },
        1000,
      )
      return
    }

    dispatch(updatePusherConnectionState({ error, connected: false }))
  }

  if (states.previous === 'connecting' && states.current === 'connected') {
    if (shouldBeDelayed && delayIntervalId) {
      clearTimeout(delayIntervalId)
      delayIntervalId = null
    } else if (wasDisconnected) {
      syncHelper()
    }
    dispatch(updatePusherConnectionState({ connected: true }))
    wasDisconnected = false
  }
}
