import React, { useState, useEffect } from 'react'
import nexus from '@ospin/nexus'
import { Button, Form, Message } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import { removeWhiteSpaces } from '~/utils/string'
import FlashMessenger from '~/utils/FlashMessenger'

const signUpCodeExpired = timestamp => (Date.now() - timestamp) > (1000 * 60 * 60 * 24)

function SubmitSignUpCode({ history, location, renderReRequestForm }) {
  const [userName, setUserName] = useState('')
  const [error, setError] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    if (query.get('timestamp') && signUpCodeExpired(query.get('timestamp'))) {
      renderReRequestForm()
      return
    }

    setUserName(query.get('userName') || '')
    setConfirmationCode(query.get('confirmationCode') || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function confirmSignUp() {
    setLoading(true)
    try {
      await nexus.auth.confirmSignUp({ username: userName, code: confirmationCode })
      FlashMessenger.success('Your Account has been confirmed')
      history.push('/login')
    } catch ({ message }) {
      setLoading(false)
      setError(message)
    }
  }

  function updateValue(value, handler) {
    handler(removeWhiteSpaces(value))
  }

  return (
    <Form
      error={!!error}
      size='large'
      onSubmit={() => confirmSignUp()}
    >
      <Form.Field>
        <label htmlFor='username'>
          username
        </label>
        <Form.Input
          size='big'
          name='username'
          id='username'
          fluid
          icon='user'
          iconPosition='left'
          value={userName}
          onChange={((_, { value }) => updateValue(value, setUserName))}
        />
        <label htmlFor='confirmationCode'>
          confirmation code
        </label>
        <Form.Input
          size='big'
          name='confirmationCode'
          id='confirmationCode'
          fluid
          iconPosition='left'
          value={confirmationCode}
          onChange={((_, { value }) => updateValue(value, setConfirmationCode))}
        />
      </Form.Field>
      {error && (
        <Message
          error
          header='Could not confirm account'
          content={error}
        />
      )}
      <Button
        loading={loading}
        size='large'
        primary
        fluid
        content='Confirm Sign Up'
        disabled={!userName || !confirmationCode}
      />

    </Form>

  )
}

export default withRouter(SubmitSignUpCode)
