import React from 'react'
import { Loader } from 'semantic-ui-react'

const DeviceViewLoader = () => (
  <div style={{ height: '480px' }}>
    <Loader active size='big' />
  </div>
)

export default DeviceViewLoader
