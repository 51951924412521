import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Process } from '~/utils/process'
import ProcessLabel from './ProcessLabel'
import ProcessControl from './ProcessControl'

import './ProcessViewerHeader.css'

const ProcessViewerHeader = ({
  activeProcess,
  toggleModal,
  activeDevice,
}) => (
  <Grid.Column
    width={13}
    style={{
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    }}
  >
    <ProcessLabel
      activeProcess={activeProcess}
      toggleModal={toggleModal}
    />
    { !Process.isFinished(activeProcess)
    && (
      <ProcessControl
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        toggleModal={toggleModal}
      />
    ) }
  </Grid.Column>
)

export default ProcessViewerHeader
