import nexus from '@ospin/nexus'
import React, { useState, useEffect } from 'react'
import { Accordion } from 'semantic-ui-react'
import SectionLoader from '~/components/utility/SectionLoader'
import Time from '~/utils/Time'
import PDVC from '~/utils/PDVC'
import NoDataMessage from '~/components/utility/NoDataMessage'
import ChangesTable from '~/components/processviewer/body/builder/ChangesTable'

const renderChangeTable = (prev, current) => {
  const diffs = PDVC.getDiffsFromDescription(current, prev)

  return (
    <ChangesTable
      diffs={diffs}
      activeProcess={current}
      snapshot={prev}
    />
  )
}

const renderSnapshots = (
  snapshots,
  activeProcess,
  shownChanges,
  toggleHandler,
) => (
  snapshots
    .sort((a, b) => a.createdAt - b.createdAt)
  /* adding the entryPhaseId because it is required for proper
   * phase parsing
   */
    .map(snapshot => ({
      ...snapshot,
      data: {
        ...snapshot.data,
        entryPhaseId: activeProcess.entryPhaseId,
      },
    }))
    .map(({ id, createdAt, data }, idx, updatedSnapshots) => {
      const showContent = shownChanges.includes(id)
      return (
        <div key={id}>
          <Accordion.Title
            content={Time.getTrimmedDateAndTimeString(createdAt)}
            onClick={() => toggleHandler(id)}
            active={showContent}
          />
          {showContent
            && (
              <Accordion.Content
                active={showContent}
              >
                {renderChangeTable(
                  data,
                  idx < updatedSnapshots.length - 1
                    ? { ...activeProcess, ...updatedSnapshots[idx + 1].data }
                    : activeProcess,
                )}
              </Accordion.Content>
            )}
        </div>
      )
    })
)

const ChangesOverview = ({ activeProcess }) => {

  const [loading, setLoading] = useState(true)
  const [snapshots, setSnapshots] = useState([])
  const [shownChanges, setShownChanges] = useState([])

  const toggleShowChanges = snapshotId => {
    if (shownChanges.includes(snapshotId)) {
      return setShownChanges(shownChanges.filter(id => id !== snapshotId))
    }

    setShownChanges([ ...shownChanges, snapshotId ])
  }

  useEffect(() => {
    const fetchSnapshots = async () => {
      const { data: fetchedSnapshots } = await nexus
        .process.snapshot.list({ processId: activeProcess.id })
      setSnapshots(fetchedSnapshots)
      setLoading(false)
    }

    fetchSnapshots()
  }, [activeProcess.id])

  if (loading) return <SectionLoader />

  if (!snapshots.length) return <NoDataMessage />

  return (
    <Accordion
      fluid
      styled
      exclusive={false}
    >
      {renderSnapshots(
        snapshots,
        activeProcess,
        shownChanges,
        toggleShowChanges,
      )}
    </Accordion>
  )
}

export default ChangesOverview
