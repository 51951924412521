import React, { useState } from 'react'
import { Modal, Segment } from 'semantic-ui-react'
import { Process } from '~/utils/process'
import EditProcessFormContainer from './EditProcessFormContainer'
import DeleteProcessFormContainer from './DeleteProcessFormContainer'
import CloneProcessFormContainer from './CloneProcessFormContainer'
import ProcessDetailsModalMenu from '../processes/ProcessDetailsModalMenu'
import AccessProcessFormContainer from './AccessProcessModal/AccessProcessFormContainer'

const ProcessDetailsModal = props => {
  const {
    toggleModal,
    showProcessDetailsModal,
    activeProcess,
    processesToDelete,
    reloadFunction,
    requiresRedirect = false,
    requiresReload = false,
    activeDevice,
    initialTab,
  } = props

  const [ activeTab, setActiveTab ] = useState(initialTab)

  const handleActiveMenuTab = tab => {
    setActiveTab(tab)
  }

  const isProcessRunningOrPaused = () => (
    activeProcess && (Process.isRunning(activeProcess) || Process.isPaused(activeProcess))
  )

  return (
    <Modal
      open={showProcessDetailsModal}
      onClose={e => toggleModal(e, 'showProcessDetailsModal')}
    >
      <Modal.Header>
        Process Details
      </Modal.Header>
      <Modal.Content>
        <ProcessDetailsModalMenu
          activeTab={activeTab}
          handleActiveMenuTab={handleActiveMenuTab}
          disableDelete={isProcessRunningOrPaused(activeProcess)}
        />
        <Segment attached='bottom' className='no-border'>
          {activeTab === 'Edit' && (
            <EditProcessFormContainer
              process={activeProcess}
              closeHandler={toggleModal}
            />
          )}
          {activeTab === 'Delete' && (
            <DeleteProcessFormContainer
              processesToDelete={processesToDelete}
              closeHandler={toggleModal}
              requiresReload={requiresReload}
              requiresRedirect={requiresRedirect}
              reloadFunction={reloadFunction}
              activeDevice={activeDevice}
            />
          )}
          {activeTab === 'Clone' && (
            <CloneProcessFormContainer
              process={activeProcess}
              closeHandler={toggleModal}
              activeDevice={activeDevice}
            />
          )}
          {activeTab === 'Access' && (
            <AccessProcessFormContainer
              activeDevice={activeDevice}
              process={activeProcess}
              closeHandler={toggleModal}
              requiresReload={requiresReload}
              requiresRedirect={requiresRedirect}
              reloadFunction={reloadFunction}
            />
          )}
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

export default ProcessDetailsModal
