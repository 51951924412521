import React from 'react'
import { connect } from 'react-redux'
import { Table, Dropdown, Label } from 'semantic-ui-react'

import callRevokeDeviceAccess from '~/redux/thunks/device/callRevokeDeviceAccess'
import DeletePopup from '~/components/device/setup/DeletePopup'
import Authorizer from '~/utils/Authorizer'

const mapDispatchToProps = dispatch => ({
  dispatchCallRevokeDeviceAccess: (deviceId, userId, hostName) =>
    callRevokeDeviceAccess(dispatch, deviceId, userId, hostName),
})

const User = ({
  user,
  activeDevice,
  me,
  modifyAccessHandler,
  dispatchCallRevokeDeviceAccess,
  pendingRequests,
  addPendingRequest,
  removePendingRequest,
}) => {

  const deleteAndClose = async userId => {
    const request = { userId, action: 'revokeAccess' }
    addPendingRequest(request)

    /* TODO: ADD_ERROR_HANDLING */
    await dispatchCallRevokeDeviceAccess(activeDevice.id, userId)

    removePendingRequest(request)
  }

  const modifyAccess = async (userId, newGroup, event, data) => {
    await modifyAccessHandler(userId, data.value)
  }

  const isUpdatingAccessGroup = () => pendingRequests.some(currentRequest => (
    user.id === currentRequest.userId && 'modifyAccess' === currentRequest.action
  ))

  const isRevokingAccess = () => pendingRequests.some(currentRequest => (
    user.id === currentRequest.userId && 'revokeAccess' === currentRequest.action
  ))

  const { userName, id } = user

  const groupOptions = activeDevice.access
    .filter(group => group.name !== 'support')
    .map(group => ({
      value: group.name,
      text: group.name,
      key: group.name,
    }))

  const accessGroup = Authorizer.getUserGroup(activeDevice, id)
  const I = Authorizer.user(me)

  const shouldDisableRemoveButton = !I.canModifyAccessOf(user).to(activeDevice)
  || isRevokingAccess()
  || Authorizer.user(user).isOwnerOf(activeDevice)

  return (
    <Table.Row>
      <Table.Cell>
        {userName}
        {Authorizer.user(user).isOwnerOf(activeDevice) && (
          <span style={{ marginLeft: '16px' }}>
            <Label>
              device owner
            </Label>
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          onChange={(event, data) => modifyAccess(id, accessGroup.name, event, data)}
          value={accessGroup.name}
          options={groupOptions}
          disabled={!I.canModifyAccessOf(user).to(activeDevice) || isUpdatingAccessGroup()}
          loading={isUpdatingAccessGroup()}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <DeletePopup
          confirmHandler={() => deleteAndClose(user.id, accessGroup.name)}
          disabled={shouldDisableRemoveButton}
          iconName='user times'
          position='left center'
          loading={isRevokingAccess()}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default connect(null, mapDispatchToProps)(User)
