import React from 'react'
import { Functionality, Ports } from '@ospin/fct-graph'
import FeatureVersioning from '~/utils/FeatureVersioning'

const FctWidgetHeader = ({ activeProcess, functionality }) => {

  if (!FeatureVersioning.supportsMultipleProcesses(activeProcess)) {
    /* devices before that featureVersion have no ports */
    return functionality.name
  }

  if (!Functionality.isPhysical(functionality)) {
    return functionality.name
  }
  const { usedPhysicalMapping } = activeProcess
  const portName = usedPhysicalMapping
    ? Ports.getId(usedPhysicalMapping
      .functionalities.find(mappingFct => mappingFct.id === functionality.id).ports)
    : 'Not Assigned'

  return (
    <div
      style={{ width: '100%' }}
      className='space-between-items'
    >
      <div>{functionality.name}</div>
      <div className='process-viewer-port-name'>{portName}</div>
    </div>
  )
}

export default FctWidgetHeader
