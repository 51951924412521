import React from 'react'
import { Icon } from 'semantic-ui-react'
import PasswordValidation from '~/utils/validation/PasswordValidation'

const PasswordRulesPopup = ({ password }) => (
  <div style={{ listStyleType: 'none' }}>
    {PasswordValidation.REQUIRED_PASSWORD_RULES.map(rule => (
      <li key={rule.errorMessage}>
        <Icon name={rule.isValid(password) ? 'checkmark' : 'x'} color={rule.isValid(password) ? 'green' : 'red'} />
        {rule.errorMessage}
      </li>
    ))}
  </div>
)

export default PasswordRulesPopup
