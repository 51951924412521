import React from 'react'
import { Container, Segment, Item, Header, Divider } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import Alert from './Alert'

const GeneralAlerts = ({ generalAlerts, deleteAlert }) => {

  if (generalAlerts.length === 0) return null

  return (
    <Container fluid text>
      <Segment data-testid='general-alerts-id'>
        <div style={{ display: 'flex ', verticalAlign: 'middle', justifyContent: 'space-between' }}>
          <Header as='h2'>General Alerts</Header>
        </div>
        <Divider />
        <Item.Group divided>
          {generalAlerts.map(alert => (
            <Alert key={alert.id} alert={alert} deleteAlert={deleteAlert} />
          ))}
        </Item.Group>
      </Segment>
    </Container>
  )
}

export default withRouter(GeneralAlerts)
