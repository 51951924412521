export const initialState = {
  user: { notifications: [], events: [] },
  authenticated: undefined,
  processes: [],
  processDescriptionSnapshots: [],
  noDevices: false,
  devices: [],
  deviceUsers: [],
  displayedPhaseId: 0,
  processBuilderValidationErrors: [],
  flashMessages: [],
  downloadRequests: [],
  pusherConnectionState: {
    connected: true,
    error: null,
  },
  processFctsImgs: [],
  activeDeviceInitialized: false,
  functionalityDescriptions: [],
}
