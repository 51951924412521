import React from 'react'
import { Pagination } from 'semantic-ui-react'
import { generatePaginationSentence, calcTotalPages } from '~/utils/pagination'

export default function LogViewTableControls({
  activePage,
  updateActivePage,
  totalMatchingLogs,
  logsPerPage,
}) {

  const showPaginationAndSentence = totalMatchingLogs >= 0
  const totalPages = calcTotalPages(totalMatchingLogs, logsPerPage)

  if (!showPaginationAndSentence) { return null }

  return (
    <div style={{ flexGrow: 1 }}>
      <Pagination
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={updateActivePage}
        siblingRange={1}
        boundaryRange={0}
        className='process-controls-pagination'
      />
      <span className='process-controls-pagination-sentence'>
        {generatePaginationSentence(activePage, totalMatchingLogs, logsPerPage)}
      </span>
    </div>
  )
}

