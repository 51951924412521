import { FCTGraph } from "@ospin/fct-graph"
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import cross from '../assets/cross.svg'

class StencilUtils {

  static get WIDTH() {
    return 180
  }

  static get IMAGE_HEIGHT() {
    return this.WIDTH
  }

  static get ICON_PADDING() {
    return 10
  }

  static generateBaseAttrs({ name, iconURL }) {

    return {
      body: {
        stroke: '#EDEDED',
        strokeWidth: 5,
        fill: '#FFFFFF',
        rx: 6,
        ry: 6,
      },
      label: {
        refX: 10,
        refY: this.IMAGE_HEIGHT - 30,
        textVerticalAnchor: 'top',
        fontFamily: 'sans-serif',
        fontSize: 14,
        strokeWidth: 0.5,
        fill: '#000000',
        text: name,
        textAnchor: 'start',
      },
      labelBackground: {
        stroke: '#CACBCD',
        fill: '#FFFFFF',

        width: this.WIDTH - this.ICON_PADDING - 5,
        height: 28,
        rx: 6,
        ry: 6,
        refX: 7.5,
        refY: this.IMAGE_HEIGHT - 28 - 10,
      },
      img: {
        refX: this.ICON_PADDING / 2,
        refY: this.ICON_PADDING / 2,
        xlinkHref: iconURL || cross,
        width: this.WIDTH - this.ICON_PADDING,
      },
    }
  }

  static get PHYSICAL_FCT_MARKUP() {
    return [
      {
        tagName: 'rect',
        selector: 'body',
      },{
        tagName: 'image',
        selector: 'img',
      },{
        tagName: 'rect',
        selector: 'labelBackground',
    },
{
      tagName: 'text',
      selector: 'label'
    }

    ]
}

  static generateVirtualFctStencils(fcts, graph, functionalityDefinitions, functionalityDescriptions) {
    const svfctsToRender = FCTGraph.getFctsWithoutIONodes(
      { functionalities: fcts },
    )


    return svfctsToRender.map(svfct => {
      const { type, subType } = svfct

      const iconURL = FunctionalityDescription.getFunctionalityIcon(
        functionalityDescriptions,
        subType
      )

      const data = {
        type,
        subtype: subType,
        isvirtual: true,
        name: FunctionalityDescription.getFunctionalityDisplayName(
          functionalityDescriptions,
          subType,
        ),
      }

      return graph.createNode({
        shape: 'stencil-node',
        width: 180,
        height: 60,
        label: data.name,
        attrs: this.generateBaseAttrs({name: data.name, iconURL}),
        data,
      })
    })
  }

  static generatePhysicalFctStencils(fcts, graph, functionalityDefinitions, functionalityDescriptions) {
    return fcts.map(phyFct => {
      const { type, subType } = phyFct

      const iconURL = FunctionalityDescription.getFunctionalityIcon(
        functionalityDescriptions,
        subType
      )

      const data = {
        type,
        subtype: subType,
        isvirtual: false,
        name: FunctionalityDescription.getFunctionalityDisplayName(
          functionalityDescriptions,
          subType,
        ),
      }

      return graph.createNode({
        shape: 'stencil-node',
        width: this.WIDTH,
        height: this.IMAGE_HEIGHT,
        label: data.name,
        attrs: this.generateBaseAttrs({ name: data.name, iconURL }),
        markup: this.PHYSICAL_FCT_MARKUP,

        data,
      })
    })
  }
}

export default StencilUtils
