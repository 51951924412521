import React from 'react'
import { connect } from 'react-redux'
import { Grid, Loader } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import nexus from '@ospin/nexus'
import Authorizer from '~/utils/Authorizer'
import { Process } from '~/utils/process'
import { createListProcessObject } from '~/redux/helper/objectMapper'
import { setProcesses } from '~/redux/actions/actions'
import {
  calcTotalPages,
  calculateSkippedItems,
} from '~/utils/pagination'
import {
  setQueryParametersOnPageLoad,
  getQueryParams,
} from '~/utils/query'

import ProcessDetailsModal from '~/components/device/modals/ProcessDetailsModal'
import DeleteProcessModal from '../modals/DeleteProcessModal'
import ProcessesArea from './ProcessesArea'
import ProcessSearchMenu from './ProcessSearchMenu'
import ProcessControlPagination from './ProcessControlPagination'
import CreateProcessModal from '../modals/CreateProcessModal'
import ProcessesQueryBar from './ProcessesQueryBar'
import './Processes.css'

const mapStateToProps = state => ({
  user: state.user,
  processes: state.processes,
})

const mapDispatchToProps = dispatch => ({
  dispatchSetProcessesToProcessesList: processes => dispatch(
    setProcesses({ processes }),
  ),
})

const NON_QUERY_STRING_DEFAULTS = {
  limit: '40',
}

const QUERY_STRING_DEFAULTS = {
  skip: '0',
  viewMode: 'tiles',
  sortBy: 'createdAt',
  sortOrder: 'DESC',
}

const OPTIONAL_QUERY_STRING_PARAMETERS = [
  'fctGraphIds',
  'stateQuery',
  'textQuery',
  'timeRefEndMS',
  'timeRefStartMS',
  'timeRefTarget',
]

const QUERY_STRING_PARAMETERS = [
  ...Object.keys(QUERY_STRING_DEFAULTS),
  ...OPTIONAL_QUERY_STRING_PARAMETERS,
]

class Processes extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentProcess: null,
      hasError: false,
      hasFetchedProcesses: false,
      hasFetchedFavorites: false,
      selectedIds: [],
      showAccessProcessModal: false,
      showCloneModal: false,
      showCreateProcessModal: false,
      showEditModal: false,
      showProcessDetailsModal: false,
      showProcessSearchMenu: this.hasActiveSearchQuery(),
      totalMatchingProcesses: 0,
    }
  }

  componentDidMount() {
    const { location, history } = this.props
    setQueryParametersOnPageLoad(location, history, QUERY_STRING_DEFAULTS)
    this.fetchProcesses()
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { fctGraphId } }, location } = this.props

    const prevQuery = new URLSearchParams(prevProps.location.search)
    const currentQuery = new URLSearchParams(location.search)


    const nonViewModeDefaults = Object.keys(QUERY_STRING_DEFAULTS)
      .filter(key => key !== 'viewMode')

    const isInitialSearchSet = nonViewModeDefaults.every(
      key => prevQuery.get(key) === null && prevQuery.get(key) !== currentQuery.get(key),
    )

    const changedKeys = QUERY_STRING_PARAMETERS.filter(
      key => prevQuery.get(key) !== currentQuery.get(key),
    )

    const isViewModeChangeOnly = changedKeys.length === 1 && changedKeys[0] === 'viewMode'
    const isPaginationOnly = changedKeys.length === 1 && changedKeys[0] === 'skip'

    const skipFetchProcesses = (isInitialSearchSet || changedKeys.length === 0) || isViewModeChangeOnly
    const navigateFromGraphToDevice = fctGraphId !== prevProps.match.params.fctGraphId

    if (skipFetchProcesses && !navigateFromGraphToDevice) { return }

    this.fetchProcesses({ fetchFavorites: !isPaginationOnly })
  }

  updateQueryParameters = keyValuePairs => {
    const { location, history } = this.props
    const query = new URLSearchParams(location.search)

    keyValuePairs.forEach(({ key, value }) => {
      if (value === null) {
        query.delete(key)
      } else {
        query.set(key, value)
      }
    })

    history.replace(`?${query.toString()}`)
  }

  updateActivePage = (_, { activePage }) => {
    const skipValue = calculateSkippedItems(activePage, NON_QUERY_STRING_DEFAULTS.limit)
    this.updateQueryParameters([{ key: 'skip', value: skipValue }])

    this.setState({ selectedIds: [] })
  }

  toggleProcessSearchMenu = () => {
    this.setState(({ showProcessSearchMenu }) => (
      { showProcessSearchMenu: !showProcessSearchMenu }
    ))
  }

  processClickHandler = async process => {
    const { history } = this.props
    history.push(`processes/${process.id}`)
  }

  selectProcessForAction = processId => {
    const { selectedIds } = this.state
    const newSelectedIds = [...selectedIds]

    if (newSelectedIds.includes(processId)) {
      const indexToRemove = newSelectedIds.findIndex(id => id === processId)
      newSelectedIds.splice(indexToRemove, 1)
    } else {
      newSelectedIds.push(processId)
    }
    this.setState({ selectedIds: newSelectedIds })
  }

  hasAllSelected = () => {
    const { selectedIds } = this.state
    const { processes } = this.props
    const nonRunningOrFavoriteProcesses = processes
      .filter(process => !process.ignoreInPagination && !Process.isRunningOrPaused(process))
    const nonRunningOrFavoriteIds = nonRunningOrFavoriteProcesses.map(({ id }) => id)

    return selectedIds.length >= nonRunningOrFavoriteProcesses.length
      && nonRunningOrFavoriteIds.every(id => selectedIds.includes(id))
  }

  handleSelectAll = () => {
    const { processes } = this.props
    const nonRunningOrFavoriteProcesses = processes
      .filter(process => !process.ignoreInPagination && !Process.isRunningOrPaused(process))
    const selection = this.hasAllSelected()
      ? []
      : nonRunningOrFavoriteProcesses.map(p => p.id)
    this.setState({ selectedIds: selection })
  }

  toggleModal = (modalTrigger, process) => {
    this.setState(prevState => ({
      [modalTrigger]: !prevState[modalTrigger],
      currentProcess: process,
    }))
  }

  getFctGraphIds = () => {
    const { location, match: { params: { fctGraphId } } } = this.props
    if (fctGraphId) return [fctGraphId]

    const query = new URLSearchParams(location.search)
    const fctGraphIds = query.get('fctGraphIds')
    if (fctGraphIds === null) return null
    return fctGraphIds.split(',')
  }

  getNonURLQueryParameters = () => {
    /* we have a bunch of properties that are added to the query, but not persisted
     * in the URL (they cannot be controlled by the user) */
    const { activeDevice, user } = this.props
    const query = { deviceId: activeDevice.id }

    /* if app admin, device admin or device support,
   * fetch all device processes - else fetch only mine (+ the ones shared with me)
   * on this device
   */
    if (!Authorizer.userHasSuperDeviceAccess(activeDevice, user)) {
      query.userId = user.id
    }

    return query
  }

  mergeProcesses = (pageProcesses, favProcessWithoutIgnoreFlags) => {
    // to allow caching favorite processes when paginating and
    // to prevent changing the order of fav processes,
    // we need to split/merge the two different process arrays;
    // this is possible because favorite and page processes
    // are always sorted the same way;
    // We prepend all favorite processes that come before the first pinned
    // process on the page processes, and append all that come after the last pinned
    // process on the page processes
    const favProcesses = favProcessWithoutIgnoreFlags.map(p => {
      p.ignoreInPagination = true
      return p
    })

    let firstPinnedIndexInPage = -1
    let lastPinnedIndexInPage = -1

    pageProcesses.forEach((p, idx) => {
      if (!p.pinned) return
      if (firstPinnedIndexInPage === -1) firstPinnedIndexInPage = idx
      lastPinnedIndexInPage = idx
    })

    if (firstPinnedIndexInPage === -1) {
      favProcesses.forEach(p => pageProcesses.push(p))
      return pageProcesses
    }

    const firstPinnedProcess = pageProcesses[firstPinnedIndexInPage]
    const firstPinnedIndexInFavs = favProcesses
      .findIndex(p => p.id === firstPinnedProcess.id)

    const lastPinnedProcess = pageProcesses[lastPinnedIndexInPage]
    const lastPinnedIndexInFavs = favProcesses
      .findIndex(p => p.id === lastPinnedProcess.id)

    const beforePage = favProcesses.filter((_, idx) => idx < firstPinnedIndexInFavs)
    beforePage.reverse().forEach(p => pageProcesses.unshift(p))

    const afterPage = favProcesses.filter((_, idx) => idx > lastPinnedIndexInFavs)
    afterPage.forEach(p => pageProcesses.push(p))

    return pageProcesses
  }

  fetchProcesses = async ({ fetchFavorites = true } = {}) => {
    this.setState({
      hasFetchedProcesses: false,
      selectedIds: []
    })
    const {
      dispatchSetProcessesToProcessesList,
      location,
      processes,
    } = this.props

    const {
      sortBy,
      sortOrder,
      skip,
      ...optionalValues
    } = getQueryParams(location, QUERY_STRING_DEFAULTS, QUERY_STRING_PARAMETERS)

    try {
      const optionalQuery = {}
      const optionalKeys = [
        'timeRefTarget',
        'textQuery',
        'stateQuery',
        'timeRefEndMS',
        'timeRefStartMS',
      ]

      optionalKeys
        .filter(key => optionalValues[key] !== null)
        .forEach(key => { optionalQuery[key] = optionalValues[key] })

      const baseQuery = {
        ...optionalQuery,
        ...this.getNonURLQueryParameters(),
      }

      const fctGraphIds = this.getFctGraphIds()

      if (fctGraphIds !== null) {
        baseQuery.fctGraphIds = fctGraphIds.join()
      }

      const globalQuery = {
        ...baseQuery,
        skip,
        sortBy,
        sortOrder,
        ...NON_QUERY_STRING_DEFAULTS,
      }

      if (sortBy && sortOrder) {
        globalQuery.sortBy = sortBy
        globalQuery.sortOrder = sortOrder
      }

      const promises = [ nexus.process.list(globalQuery) ]

      if (fetchFavorites) {
        const queryPinnedProcesses = {
          ...baseQuery,
          pinned: true,
        }
        if (sortBy && sortOrder) {
          queryPinnedProcesses.sortBy = sortBy
          queryPinnedProcesses.sortOrder = sortOrder
        }
        promises.push(nexus.process.list(queryPinnedProcesses))
      }

      const [ allProcessesData, pinnedProcessesData ] = await Promise.all(promises)
      const { data: { processes: pageProcesses, totalMatchingProcesses } } = allProcessesData

      const pinnedProcesses = fetchFavorites
        ? pinnedProcessesData.data.processes
        : processes.filter(p => p.pinned)

      const mergedProcesses = this.mergeProcesses(pageProcesses, pinnedProcesses)
      dispatchSetProcessesToProcessesList(mergedProcesses.map(createListProcessObject))

      this.setState({
        currentProcess: null,
        hasFetchedProcesses: true,
        totalMatchingProcesses,
        hasError: false,
      })

    } catch (_) {
      this.setState({ hasError: true, hasFetchedProcesses: true })
    }
  }

  hasActiveSearchQuery = () => {
    const { location } = this.props
    const query = new URLSearchParams(location.search)

    const relevantSearchQueryKeys = [
      'textQuery',
      'stateQuery',
      'timeRefStartMS',
      'timeRefEndMS',
      'fctGraphIds',
    ]

    return relevantSearchQueryKeys.some(key => query.get(key) !== null)
  }

  render() {

    const {
      showCreateProcessModal,
      currentProcess,
      showProcessSearchMenu,
      hasFetchedProcesses,
      selectedIds,
      hasError,
      totalMatchingProcesses,
      showDeleteProcessModal,
      showProcessDetailsModal,
    } = this.state
    const { activeDevice, user, processes, location } = this.props

    const {
      viewMode,
      skip,
      timeRefTarget,
      timeRefStartMS,
      timeRefEndMS,
      stateQuery,
      textQuery,
    } = getQueryParams(location, QUERY_STRING_DEFAULTS, QUERY_STRING_PARAMETERS)

    const fctGraphIds = this.getFctGraphIds()
    const parsedLimit = parseInt(NON_QUERY_STRING_DEFAULTS.limit, 10)
    const parsedSkip = parseInt(skip, 10)

    const totalPages = calcTotalPages(totalMatchingProcesses, parsedLimit)
    const activePage = Math.floor(parsedSkip / parsedLimit) + 1

    const selectedProcesses = processes.filter(process => selectedIds.includes(process.id))

    const processesToDelete = currentProcess ? [currentProcess] : selectedProcesses
    const hasPermissioningRights = Authorizer.isResourceAdmin(activeDevice, user.id)
      || Authorizer.isResourceOperator(activeDevice, user.id)
      || Authorizer.user(user).hasApplicationDeveloperAccess()

    /// prevent race condition between modal trigger and currentProcess update
    const shouldShowProcessDetailsModal = (showProcessDetailsModal && currentProcess)

    return (
      <div>
        <CreateProcessModal
          open={showCreateProcessModal}
          activeDevice={activeDevice}
          closeHandler={() => this.toggleModal('showCreateProcessModal')}
          headerText='Create Process'
        />
        <Grid.Row>
          <ProcessesQueryBar
            viewMode={viewMode}
            activeDevice={activeDevice}
            hasPermissioningRights={hasPermissioningRights}
            toggleModal={this.toggleModal}
            toggleProcessSearchMenu={this.toggleProcessSearchMenu}
            showProcessSearchMenu={showProcessSearchMenu}
            disableCloseSearchMenu={showProcessSearchMenu && this.hasActiveSearchQuery()}
            updateQueryParameters={this.updateQueryParameters}
          />
          <ProcessSearchMenu
            timeRefTarget={timeRefTarget}
            timeRefStartMS={timeRefStartMS ? parseInt(timeRefStartMS, 10) : null}
            timeRefEndMS={timeRefEndMS ? parseInt(timeRefEndMS, 10) : null}
            stateQuery={stateQuery || null}
            textQuery={textQuery || null}
            fctGraphIds={fctGraphIds}
            updateQueryParameters={this.updateQueryParameters}
            activeDevice={activeDevice}
            showProcessSearchMenu={showProcessSearchMenu}
            limit={QUERY_STRING_DEFAULTS.limit}
          />
        </Grid.Row>
        <Grid.Row>
          {hasFetchedProcesses ? (
            <ProcessesArea
              viewMode={viewMode}
              processes={processes}
              processClickHandler={this.processClickHandler}
              selectProcessForAction={this.selectProcessForAction}
              toggleModal={this.toggleModal}
              selectedIds={selectedIds}
              hasError={hasError}
              activeDevice={activeDevice}
              showProcessDetailsModal={showProcessDetailsModal}
              sortTable={this.sortTable}
              activePage={activePage}
              totalPages={totalPages}
              updateActivePage={this.updateActivePage}
              totalMatchingProcesses={totalMatchingProcesses}
              processesPerPage={parsedLimit}
              allSelected={this.hasAllSelected() && processes.length > 0}
              handleSelectAll={this.handleSelectAll}

            />
          )
            : (
              <div style={{ paddingTop: '5em' }}>
                <Loader active size='big' inline='centered' data-testid='processesLoader' />
              </div>
            )}
        </Grid.Row>
        {
          showDeleteProcessModal && (
            <DeleteProcessModal
              open
              closeHandler={() => this.toggleModal('showDeleteProcessModal', null)}
              headerText='Delete Process'
              processesToDelete={processesToDelete}
              activeDevice={activeDevice}
              reloadFunction={() => this.fetchProcesses()}
              requiresReload
            />
          )
        }
        {(shouldShowProcessDetailsModal) && (
          <ProcessDetailsModal
            toggleModal={() => this.toggleModal('showProcessDetailsModal', null)}
            showProcessDetailsModal={showProcessDetailsModal}
            // passing in the process plainly runs into state update errors
            // because the local currentProcess diverges from the process in the redux store
            // lesson: storing store items in local state is bad!
            activeProcess={Process.getById(processes, currentProcess.id)}
            processesToDelete={processesToDelete}
            reloadFunction={() => this.fetchProcesses()}
            requiresReload
            activeDevice={activeDevice}
            initialTab='Edit'
          />
        )}
        <Grid.Row>
          {hasFetchedProcesses && (
            <ProcessControlPagination
              activePage={activePage}
              totalPages={totalPages}
              updateActivePage={this.updateActivePage}
              totalMatchingProcesses={totalMatchingProcesses}
              processesPerPage={parsedLimit}
            />
          )}
        </Grid.Row>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Processes))
