import Validator from '~/utils/validation/Validator'
import SlotConfig from '~/utils/functionalities/slots/SlotConfig'

class FunctionalityConfiguration {

  static getByFctId = (configs, targetFctId) => (
    configs.find(({ fctId }) => fctId === targetFctId)
  )

  static getSlotConfig = (configs, fctId, slotName) => {
    const config = FunctionalityConfiguration.getByFctId(configs, fctId)
    if (!config) return
    return config.slotsConfigs.find(({ name }) => name === slotName)
  }

  static hasConfig = (configs, targetFctId) => (
    configs.some(({ fctId }) => fctId === targetFctId)
  )

  static mergeMany = (oldConfigs, newConfigs) => {
    const updatedConfigs = oldConfigs.map(oldConfig => {
      const updatedConfig = FunctionalityConfiguration.getByFctId(newConfigs, oldConfig.fctId)
      return updatedConfig || oldConfig
    })

    newConfigs.forEach(newConfig => {
      if (!FunctionalityConfiguration.hasConfig(oldConfigs, newConfig.fctId)) {
        updatedConfigs.push(newConfig)
      }
    })

    return updatedConfigs
  }

  static getDisplayName(configs, fctId) {
    const config = FunctionalityConfiguration.getByFctId(configs, fctId)
    return config?.display.name
  }

  static getSlotDisplayUnit(configs, fctId, slotName) {
    const slotConfig = FunctionalityConfiguration.getSlotConfig(configs, fctId, slotName)
    return SlotConfig.getDisplayUnit(slotConfig)
  }

  static getSlotDefaultInputValue(configs, fctId, slotName) {
    const slotConfig = FunctionalityConfiguration.getSlotConfig(configs, fctId, slotName)
    return SlotConfig.getDefaultInputValue(slotConfig)
  }

  static getSlotAbsoluteTareValue(configs, fctId, slotName) {
    const slotConfig = FunctionalityConfiguration.getSlotConfig(configs, fctId, slotName)
    return SlotConfig.getAbsoluteTareValue(slotConfig)
  }

  static getSlotDisplayedDecimalPlaces(configs, fctId, slotName) {
    const slotConfig = FunctionalityConfiguration.getSlotConfig(configs, fctId, slotName)
    return SlotConfig.getDisplayedDecimalPlaces(slotConfig)
  }

  static getConfigsWithSlotDefaultInputValues(configs) {
    return configs.filter(config => (
      config.slotsConfigs
        .some(({ defaultInputValue }) => !Validator.isUndefinedOrNull(defaultInputValue))
    ))
  }

  static getConfigsWithDisplayName(configs) {
    return configs.filter(config => config.display.name)
  }

}

export default FunctionalityConfiguration
