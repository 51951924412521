import React, { useState } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import { removeWhiteSpaces } from '~/utils/string'
import PasswordValidation from '~/utils/validation/PasswordValidation'
import PasswordRulesPopup from '../PasswordRulesPopup'
import PasswordStrenghIndicator from '../PasswordStrenghIndicator'

export default function ResetPasswordForm() {
  const [usernameOrEmail, setUsernameOrEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [resetCode, setResetCode] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [showPasswordPopup, setShowPasswordPopup] = useState(false)

  async function submit() {
    setLoading(true)
    try {
      await nexus.auth.forgotPasswordSubmit({
        usernameOrEmail,
        code: resetCode,
        newPassword: password,
      })
      setSuccess(true)
      setError(false)
    } catch ({ message }) {
      setError(message)
    } finally {
      setLoading(false)
    }
  }

  const getNonValidRules = passwordValue => (
    PasswordValidation.REQUIRED_PASSWORD_RULES
      .filter(rule => !rule.isValid(removeWhiteSpaces(passwordValue)))
  )

  const isPasswordFieldValid = getNonValidRules(password).length === 0

  const isValid = [usernameOrEmail, password, resetCode].every(val => !!val)
    && password === passwordConfirmation && isPasswordFieldValid

  const shouldDisplayConfirmationError = (
    !!passwordConfirmation && passwordConfirmation !== password
  )

  function updateAndStripWhiteSpace(value, handler) {
    handler(removeWhiteSpaces(value))
  }

  function onChangeHandlePassword(newValue) {
    updateAndStripWhiteSpace(newValue, setPassword)
    setShowPasswordPopup(true)

    const nonValidPasswordRules = getNonValidRules(newValue)
    if (!nonValidPasswordRules.length) {
      setShowPasswordPopup(false)
    }
  }

  return (
    <Form
      error={!!error}
      success={success}
      size='large'
      onSubmit={() => submit()}
    >
      <Form.Field>
        <label htmlFor='username'>
          username or email
        </label>
        <Form.Input
          size='big'
          name='username'
          id='username'
          fluid
          autoComplete='username'
          icon='user'
          iconPosition='left'
          value={usernameOrEmail}
          onChange={(_, { value }) => updateAndStripWhiteSpace(value, setUsernameOrEmail)}
        />
        <label htmlFor='password'>
          password
        </label>
        <Form.Input
          size='big'
          name='password'
          id='password'
          fluid
          icon='lock'
          iconPosition='left'
          autoComplete='new-password'
          type='password'
          value={password}
          onChange={(_, { value: newValue }) => onChangeHandlePassword(newValue)}
          error={showPasswordPopup && (
            { content: <PasswordRulesPopup password={password} /> })}
        />
        {password ? <PasswordStrenghIndicator password={password} /> : null}
        <label htmlFor='confirmPassword'>
          confirm password
        </label>
        <Form.Input
          size='big'
          name='confirmPassword'
          id='confirmPassword'
          fluid
          icon='lock'
          iconPosition='left'
          autoComplete='new-password'
          type='password'
          value={passwordConfirmation}
          onChange={(_, { value }) => updateAndStripWhiteSpace(value, setPasswordConfirmation)}
          error={(shouldDisplayConfirmationError) ? { content: 'passwords do not match' } : null}
        />
        <label htmlFor='resetCode'>
          reset code
        </label>
        <Form.Input
          size='big'
          name='resetCode'
          id='resetCode'
          fluid
          icon='grid layout'
          iconPosition='left'
          value={resetCode}
          onChange={(_, { value }) => updateAndStripWhiteSpace(value, setResetCode)}
        />
        <Button
          fluid
          content='Submit'
          primary
          disabled={!isValid}
          type='submit'
          loading={loading}
        />
        {error ? <Message error content={error} /> : null}
        {success ? <Message success content='Your password has been reset' /> : null}
      </Form.Field>
    </Form>
  )
}
