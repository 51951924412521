import { FCTGraph } from '@ospin/fct-graph'
import { EXECUTED_PROCESS_STATES } from '~/utils/process'
import GraphTools from '~/utils/GraphTools'
import nexus from '@ospin/nexus'
import { addHistoricSensorData, setSensorDataErrorFlag } from '~/redux/actions/actions'

const fetchDataSensorWise = async ({
  endpoint,
  params,
  reporterFctIds,
  process,
  dispatch,
}) => {
  const dataRequests = reporterFctIds.map(reporterFctId => ({
    reporterFctId,
    dataPromise: endpoint(process.id, reporterFctId, params),
  }))

  dataRequests.forEach(async request => {
    try {
      const { data: reporterData } = await request.dataPromise
      dispatch(addHistoricSensorData({
        reporterFctId: request.reporterFctId,
        data: reporterData,
        processId: process.id,
      }))
    } catch (e) {
      dispatch(setSensorDataErrorFlag({
        reporterFctId: request.reporterFctId,
        processId: process.id,
      }))
    }
  })
}

const callLoadProcessDataSummarySensorWise = async (
  dispatch,
  process,
) => {

  const { fctGraph, state } = process
  const reporterFctIds = FCTGraph
    .getIntervalOutFcts(fctGraph)
    .map(fct => fct.id)

  const fetchOnlyRecentData = state === EXECUTED_PROCESS_STATES.running
    || state === EXECUTED_PROCESS_STATES.paused

  if (!fetchOnlyRecentData) {
    await fetchDataSensorWise({
      endpoint: nexus.dataPoints.materializedView.get,
      reporterFctIds,
      process,
      dispatch,
    })

    // this serves a rare edge case: When a process was just finished
    // the materialized view might not be ready yet, so users will not
    // see anything; we prevent that by loading the most recent data in the
    // case that the process finished in the last 8 seconds additionally to the
    // materialized view (modeToFetchData is then set to DISPLAY_MODES.HOUR)
    const processRecentlyEnded = (Date.now() - process.finishedAt) < 8000
    if (!processRecentlyEnded) return
  }

  const modeToFetchData = fetchOnlyRecentData
    ? GraphTools.DISPLAY_MODES.LIVE
    : GraphTools.DISPLAY_MODES.HOUR

  const start = GraphTools.getRelativeProcessTimeAgoInSeconds(
    process,
    GraphTools.getIntervalInMSFromMode(modeToFetchData),
  )

  await fetchDataSensorWise({
    endpoint: nexus.dataPoints.get,
    reporterFctIds,
    process,
    dispatch,
    params: { numberOfPoints: GraphTools.NO_LIMIT_VALUE, start, end: -1 },
  })

}

export default callLoadProcessDataSummarySensorWise
