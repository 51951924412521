import nexus from '@ospin/nexus'
import store from '~/redux/store'
import { updateUser } from '~/redux/actions/actions'

export default class UserUIConfig {

  static get DEFAULT_DEVICE_CONFIG() {
    return {
      widget: {
        viewAggregate: false,
        viewOffline: false,
        elements: {
          singleFctView: { collapsed: false, index: 0 },
          multiFctView: { collapsed: false, index: 1 },
        },
      },
      configurations: {
        viewAggregate: false,
        viewOffline: false,
        elements: {
          singleFctView: { collapsed: false, index: 0 },
          multiFctView: { collapsed: false, index: 1 },
        },
      },
    }
  }

  static getDeviceConfig(uIConfig, deviceId) {
    return uIConfig.devices[deviceId] || UserUIConfig.DEFAULT_DEVICE_CONFIG
  }

  static async updateConfig(deviceId, updatedDeviceConfig) {
    /* optimistic approach for snappy behaviour */
    const { user } = store.getState()

    const updatedUIConfig = {
      ...user.uIConfig,
      devices: {
        ...user.uIConfig.devices,
        [deviceId]: updatedDeviceConfig,
      },
    }

    store.dispatch(updateUser({ uIConfig: updatedUIConfig }))
    await nexus.uIConfig.userUIConfig.post(
      user.id,
      { devices: updatedUIConfig.devices },
    )
  }

  static toggleController(
    deviceId,
    uIConfig,
    updatedKey,
    viewType,
  ) {
    const targetDeviceUserUIConfig = UserUIConfig.getDeviceConfig(uIConfig, deviceId)

    const updatedData = {
      ...targetDeviceUserUIConfig,
      [viewType]: {
        ...targetDeviceUserUIConfig[viewType],
        [updatedKey]: !targetDeviceUserUIConfig[viewType][updatedKey],
      },
    }

    UserUIConfig.updateConfig(deviceId, updatedData)
  }

  static setCollapsedValue(deviceId, uIConfig, updatedKey, viewType) {
    const targetDeviceUserUIConfig = UserUIConfig.getDeviceConfig(uIConfig, deviceId)
    const { [viewType]: { elements } } = targetDeviceUserUIConfig

    const updatedData = {
      ...targetDeviceUserUIConfig,
      [viewType]: {
        ...targetDeviceUserUIConfig[viewType],
        elements: {
          ...elements,
          [updatedKey]: {
            ...elements[updatedKey],
            collapsed: !elements[updatedKey].collapsed,
          },
        },
      },
    }

    UserUIConfig.updateConfig(deviceId, updatedData)
  }

  static setAllCollapsedValue(
    deviceId,
    uIConfig,
    newValue,
    viewType,
  ) {
    const targetDeviceUserUIConfig = UserUIConfig.getDeviceConfig(uIConfig, deviceId)

    const { [viewType]: { elements } } = targetDeviceUserUIConfig
    const { multiFctView, singleFctView } = elements

    const updatedElements = {
      ...elements,
      multiFctView: { ...multiFctView, collapsed: newValue },
      singleFctView: { ...singleFctView, collapsed: newValue },
    }

    const updatedData = {
      ...targetDeviceUserUIConfig,
      [viewType]: {
        ...targetDeviceUserUIConfig[viewType],
        elements: { ...updatedElements },
      },
    }

    UserUIConfig.updateConfig(deviceId, updatedData)
  }

  static switchIndexes(index) {
    return index === 0 ? 1 : 0
  }

  static swapElementPositions(deviceId, uIConfig, updatedKey, viewType) {
    const targetDeviceUserUIConfig = UserUIConfig.getDeviceConfig(uIConfig, deviceId)
    const { [viewType]: { elements } } = targetDeviceUserUIConfig
    const { multiFctView, singleFctView } = elements

    const updatedData = {
      ...targetDeviceUserUIConfig,
      [viewType]: {
        ...targetDeviceUserUIConfig[viewType],
        elements: {
          ...elements,
          multiFctView: {
            ...multiFctView,
            index: UserUIConfig.switchIndexes(multiFctView.index),
          },
          singleFctView: {
            ...singleFctView,
            index: UserUIConfig.switchIndexes(singleFctView.index),
          },
        },
      },
    }

    UserUIConfig.updateConfig(deviceId, updatedData)
  }
}
