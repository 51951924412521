import React from 'react'
import modalize from '~/components/utility/modal/modalize'
import DeleteProcessFormContainer from './DeleteProcessFormContainer'

const DeleteProcessModal = props => {
  const {
    processesToDelete,
    closeHandler,
    requiresReload = false,
    requiresRedirect = false,
    reloadFunction,
    activeDevice,
  } = props

  return (
    <DeleteProcessFormContainer
      processesToDelete={processesToDelete}
      closeHandler={closeHandler}
      requiresReload={requiresReload}
      requiresRedirect={requiresRedirect}
      reloadFunction={reloadFunction}
      activeDevice={activeDevice}
    />
  )
}

export default modalize(DeleteProcessModal)
