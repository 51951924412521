import React from 'react'
import { Image, Label, Accordion } from 'semantic-ui-react'
import defaultImage from '~/images/bioreactor_online_cropped.png'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import UserUIConfig from '~/utils/UIConfig/UserUIConfig'
import GatewayDisplayedFcts from '~/utils/partners/GatewayDisplayedFcts'
import GraphsSection from './GraphsSection'
import ConfigurationViewControl from './ConfigurationViewControl'
import NoGraphMessage from './NoGraphmessage'
import './GatewayWidget.css'
import './DeviceWidget.css'

const DEFAULT_OFFLINE_DISPLAY = { statusText: 'offline', backgroundColor: '#B3B3B3' }

const mapStateToProps = ({ user }) => ({ user })

const generateOnlineText = device => {
  const { connectedPhysicalFunctionalities } = device
  const numberOfFcts = connectedPhysicalFunctionalities.length
  if (numberOfFcts === 0) { return 'online' }
  return `${numberOfFcts} device${numberOfFcts === 1 ? '' : 's'} connected`
}

function getDeviceDisplay(device) {
  if (device.online) {
    return { statusText: generateOnlineText(device), backgroundColor: '#27AE60' }
  }
  return DEFAULT_OFFLINE_DISPLAY
}

const renderVirtual = () => (
  <Label className='heidolph-gateway-widget-virtual-label' color='blue' basic size='small'>
    Virtual
  </Label>
)

const getImageClass = online => {
  const base = 'heidolph-gateway-widget-image'
  if (online) return base
  return `${base} ${base}--faded`
}

const getImageSource = device => {
  const { manufacturerDeviceType } = device
  return manufacturerDeviceType.imageURL || defaultImage
}

function GatewayWidget(props) {
  const { device, user, match: { params: { deviceId: deviceIdMatchParams } } } = props
  const { id: deviceId, fctGraphs, name, isVirtual, manufacturer } = device

  const { statusText, backgroundColor } = getDeviceDisplay(device)

  function renderWidgetContent() {
    const { uIConfig } = user

    const viewType = GatewayDisplayedFcts.getViewType(deviceIdMatchParams)

    const { [viewType]: { elements } } = UserUIConfig.getDeviceConfig(uIConfig, deviceId)

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <ConfigurationViewControl
          device={device}
        />
        <Accordion fluid>
          {GatewayDisplayedFcts.getOrderedElements(elements).map(type => (
            <GraphsSection
              key={type}
              fctGraphsType={type}
              device={device}
            />
          ))}
        </Accordion>
      </div>
    )
  }

  return (
    <div className='heidolph-gateway-widget heidolph-gateway-widget-graph-container'>
      <div className='heidolph-gateway-widget-column-1'>
        <Link to={`/devices/${device.id}/processes`}>
          <div className='heidolph-gateway-widget-header'>
            <div className='heidolph-gateway-widget-header-l'>
              <div className='heidolph-gateway-widget-image-frame'>
                <Image
                  className={getImageClass(device.online)}
                  src={getImageSource(device)}
                />
                {isVirtual ? renderVirtual() : null}
              </div>
              <div className='heidolph-gateway-widget-header-text'>
                <div className='device-widget-device-name'>
                  {name}
                </div>
                <div className='heidolph-gateway-widget-header-text-manufac-info'>
                  {device.manufacturerDeviceType.name}
                </div>
                <div className='heidolph-gateway-widget-header-text-manufac-info'>
                  {manufacturer.name}
                </div>
              </div>
            </div>
            <div
              className='heidolph-gateway-widget-header-r'
              style={{ backgroundColor }}
            >
              <span className='heidolph-gateway-widget-status-text'>
                {statusText}
              </span>
            </div>
          </div>
        </Link>
      </div>
      {fctGraphs.length ? renderWidgetContent() : (
        <NoGraphMessage
          noGraphMsgClassName='no-graph-msg-parent-widget'
        />
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(GatewayWidget))
