import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import Time from '~/utils/Time'

const getTimeFormattedValue = seconds => {
  if (seconds === '') return seconds
  const derivedTimeDisplayFormat = Time.deriveTimeDisplayFormat(seconds)
  return Time.processTimeAxisLabelFormat(seconds, derivedTimeDisplayFormat)
}

const getSelectedTimeRangesArray = pointSelectionSlope => ([
  pointSelectionSlope.startTime,
  pointSelectionSlope.endTime,
])

const isSelectedField = (fieldName, selectedField) => (
  fieldName === selectedField
)

const getPlaceHolder = (fieldName, selectedField) => {
  const isSelected = isSelectedField(fieldName, selectedField)
  return isSelected ? 'click point' : 'activate to set'
}

const SlopeMenu = ({
  menuParams,
  handleRefUpdateSlope,
  setSelectedField,
}) => {

  const {
    displayedUnit,
    pointSelectionSlope,
    selectedField,
  } = menuParams

  const [
    startTime,
    endTime,
  ] = getSelectedTimeRangesArray(pointSelectionSlope).map(getTimeFormattedValue)

  const { slopeRangeAvrgDisplayed, slopeRangeReference } = pointSelectionSlope

  return (
    <div>
      <Form.Group widths='equal'>
        <Form.Input
          fluid
          label='Interval start time'
          placeholder={getPlaceHolder('startTime', selectedField)}
          focus={isSelectedField('startTime', selectedField)}
          onFocus={e => setSelectedField(e, 'startTime')}
          value={startTime}
          action={
            <Button
              icon='crosshairs'
              primary={isSelectedField('startTime', selectedField)}
              onClick={e => setSelectedField(e, 'startTime')}
            />
          }
          actionPosition='left'
        />
        <Form.Input
          fluid
          label='Interval end time'
          placeholder={getPlaceHolder('endTime', selectedField)}
          focus={isSelectedField('endTime', selectedField)}
          onFocus={e => setSelectedField(e, 'endTime')}
          value={endTime}
          action={
            <Button
              icon='crosshairs'
              primary={isSelectedField('endTime', selectedField)}
              onClick={e => setSelectedField(e, 'endTime')}
            />
          }
          actionPosition='left'
        />
        <Form.Input
          fluid
          label={`Average [${displayedUnit}]`}
          placeholder='average...'
          value={slopeRangeAvrgDisplayed}
          readOnly
        />
        <Form.Input
          fluid
          type='number'
          label={`Correct value [${displayedUnit}]`}
          placeholder='enter correct value'
          name='slopeRangeReference'
          value={slopeRangeReference}
          onChange={handleRefUpdateSlope}
        />
      </Form.Group>
    </div>
  )
}

export default SlopeMenu
