import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import Time from '~/utils/Time'

const getSelectedTimeRangesObject = pointSelectionAtoP => {
  const {
    firstIntervalStart,
    firstIntervalEnd,
    secondIntervalStart,
    secondIntervalEnd,
  } = pointSelectionAtoP

  return {
    firstIntervalStart: Time.parseSecondsToLabelFormat(firstIntervalStart),
    firstIntervalEnd: Time.parseSecondsToLabelFormat(firstIntervalEnd),
    secondIntervalStart: Time.parseSecondsToLabelFormat(secondIntervalStart),
    secondIntervalEnd: Time.parseSecondsToLabelFormat(secondIntervalEnd),
  }
}

const isSelectedField = (fieldName, selectedField) => (
  fieldName === selectedField
)

const getPlaceHolder = (fieldName, selectedField) => {
  const isSelected = isSelectedField(fieldName, selectedField)
  return isSelected ? 'click point' : 'activate to set'
}

const renderSelectionInput = ({ name, label, value, selectedField, setSelectedField }) => (
  <Form.Input
    fluid
    label={label}
    placeholder={getPlaceHolder(name, selectedField)}
    focus={isSelectedField(name, selectedField)}
    onFocus={e => setSelectedField(e, name)}
    value={value}
    key={name}
    action={
      <Button
        icon='crosshairs'
        primary={isSelectedField(name, selectedField)}
        onClick={e => setSelectedField(e, name)}
      />
    }
    actionPosition='left'
  />
)

const renderCalculatedAverage = ({ value, displayedUnit }) => (
  <Form.Input
    fluid
    label={`Average [${displayedUnit}]`}
    placeholder='average...'
    value={value}
    readOnly
  />
)

const renderReferenceInput = ({ name, displayedUnit, placeholder, value, changeHandler }) => (
  <Form.Input
    fluid
    type='number'
    label={`Correct value [${displayedUnit}]`}
    placeholder={placeholder}
    name={name}
    onChange={changeHandler}
    value={value}
  />
)

const groupData = [
  {
    id: 1,
    selectionInputs: [
      { name: 'firstIntervalStart', label: '1. interval start time' },
      { name: 'firstIntervalEnd', label: '1. interval end time' },
    ],
    calculatedAverage: { name: 'firstIntervalAverageDisplayed' },
    reference: { name: 'firstIntervalReference', placeholder: 'correct first value' },
  },
  {
    id: 2,
    selectionInputs: [
      { name: 'secondIntervalStart', label: '2. interval start time' },
      { name: 'secondIntervalEnd', label: '2. interval end time' },
    ],
    calculatedAverage: { name: 'secondIntervalAverageDisplayed' },
    reference: { name: 'secondIntervalReference', placeholder: 'correct second value' },
  },
]

const AverageSelectionMenu = ({
  handleRefUpdateAtoP,
  menuParams,
  setSelectedField,
}) => {

  const {
    displayedUnit,
    pointSelectionAtoP,
    selectedField,
  } = menuParams

  const ranges = getSelectedTimeRangesObject(pointSelectionAtoP)

  return (
    <div>
      {groupData.map(({ selectionInputs, calculatedAverage, reference, id }) => (
        <Form.Group widths='equal' key={id}>
          {selectionInputs.map(({ name, label }) => (
            renderSelectionInput({
              name,
              label,
              value: ranges[name],
              selectedField,
              setSelectedField,
            })
          ))}
          {renderCalculatedAverage({
            value: pointSelectionAtoP[calculatedAverage.name],
            displayedUnit,
          })}
          {renderReferenceInput({
            name: reference.name,
            displayedUnit,
            placeholder: reference.placeholder,
            value: pointSelectionAtoP[reference.name],
            changeHandler: handleRefUpdateAtoP,
          })}
        </Form.Group>
      ))}
    </div>
  )
}

export default AverageSelectionMenu
