import React from 'react'
import { Button, Divider, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {
  createPhaseGroup,
  appendPhaseToGroup,
  prependPhaseToGroup,
} from '~/redux/actions/actions'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import { Process, phaseIsModifiable } from '~/utils/process'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import Authorizer from '~/utils/Authorizer'
import PhaseTreeItemFactory from './PhaseTreeItemFactory'
import './PhaseTreeBody.css'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCreatePhaseGroup: (processId, phaseIds) => dispatch(
    createPhaseGroup({ processId, phaseIds })),
  dispatchAppendPhaseToGroup: (processId, groupName, phaseId) => dispatch(
    appendPhaseToGroup({ processId, groupName, phaseId })),
  dispatchPrependPhaseToGroup: (processId, groupName, phaseId) => dispatch(
    prependPhaseToGroup({ processId, groupName, phaseId })),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

function PhaseTreeBody(props) {
  const {
    dispatchCreatePhaseGroup,
    dispatchAppendPhaseToGroup,
    dispatchPrependPhaseToGroup,
    dispatchCallUpdateProcess,
    runningPhase,
    activeProcess,
    groupedPhaseData,
    isDraggingOver,
    activeDevice,
    user,
  } = props

  const save = async () => {
    if (Process.isExecutable(activeProcess)) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const handleGroupPhases = async phasesToBeGrouped => {
    const { groupName: group1, id: id1 } = phasesToBeGrouped[0]
    const { groupName: group2, id: id2 } = phasesToBeGrouped[1]

    if (group1 && !group2) {
      dispatchAppendPhaseToGroup(activeProcess.id, group1, id2)
    }

    if (!group1 && group2) {
      dispatchPrependPhaseToGroup(activeProcess.id, group2, id1)
    }

    if (!group1 && !group2) {
      dispatchCreatePhaseGroup(activeProcess.id, [id1, id2])
    }

    await save()
  }

  const renderConnectorElement = phasesToBeGrouped => {
    const [ firstEntry, secondEntry ] = phasesToBeGrouped
    const didRun = firstEntry.isGroup
      ? PhaseGroupParser.hasGroupFinished(activeProcess, firstEntry.groupName)
      : !phaseIsModifiable(activeProcess, firstEntry.id)

    const bothElementAreAGroup = firstEntry.isGroup && secondEntry.isGroup
    const processIsFinished = Process.isFinished(activeProcess)

    if (didRun || bothElementAreAGroup || processIsFinished) {
      return (
        <Icon
          name='chevron circle down'
          color='grey'
        />
      )
    }

    return (
      <Button
        disabled={Authorizer.isResourceViewer(activeProcess, user.id)}
        onClick={() => handleGroupPhases(phasesToBeGrouped)}
        size='mini'
        basic
        data-testid='chain-group-phases-btn'
      >
        <Icon
          style={{ marginLeft: '3.5px' }}
          name='chain'
        />
      </Button>
    )
  }

  const getDividerStyle = () => {
    const style = { textAlign: 'center' }
    if (isDraggingOver) {
      style.opacity = 0
    }
    return style
  }

  const renderChainPhase = phasesToBeGrouped => (
    <div>
      <Divider style={getDividerStyle()} className='phase-tree-divider' fitted horizontal>
        {renderConnectorElement(phasesToBeGrouped)}
      </Divider>
    </div>
  )

  return (
    groupedPhaseData.map((phaseData, i) => (
      [
        <div key={phaseData.idx * 2}>
          {i > 0 ? (
            renderChainPhase([groupedPhaseData[i - 1], groupedPhaseData[i]])
          ) : (
            <div />
          )}
        </div>,
        <PhaseTreeItemFactory
          key={phaseData.idx * 2 + 1}
          runningPhase={runningPhase}
          activeProcess={activeProcess}
          treeElement={phaseData.isGroup ? 'group' : 'tab'}
          phaseData={phaseData}
          activeDevice={activeDevice}
        />,
      ]
    ))
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseTreeBody)
