class LayoutUtils {
  static update(originalLayout, { posId, x, y }) {
    return {
      ...originalLayout,
      nodePositions: {
        ...originalLayout.nodePositions,
        [posId]: { x, y }
      }
    }
  }

  static remove(originalLayout, { posId }) {
    const updatedNodePositions = {...originalLayout.nodePositions}

    delete updatedNodePositions[posId]
    return {
      ...originalLayout,
      nodePositions: updatedNodePositions
    }
  }

  static getNodePositionOr0(layout, posId) {
    const { nodePositions } = layout
    if (nodePositions?.[posId]) {
      return nodePositions[posId]
    }

    return { x: 0, y: 0 }
  }
}

export default LayoutUtils
