import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Segment } from 'semantic-ui-react'
import { useLocation, useHistory } from 'react-router-dom'
import LogView from '~/components/log/LogView'
import Validator from '~/utils/validation/Validator'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import DescriptionParser from '~/utils/process/DescriptionParser'
import ProcessViewerDisplay from '~/utils/display/ProcessViewerDisplay'
import { setDisplayedProcessPhase } from '~/redux/actions/actions'
import { Process } from '~/utils/process'
import PhaseGroupRenderer from '~/utils/render/PhaseGroupRenderer'
import ChangesFooter from '~/components/processviewer/body/builder/ChangesFooter'
import ObjectTools from '~/utils/ObjectTools'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import DataGraphs from './dataviewer/DataGraphs'
import Dashboards from './dashboard/Dashboards'
import ProcessDownloadRequests from './ProcessDownloadRequests/ProcessDownloadRequests'
import ProcessOverview from './processOverview/ProcessOverview'
import Builder from './builder/Builder'
import OutdatedProcessInfoBanner from './OutdatedProcessInfoBanner'
import ProcessViewerMenu from './processViewerMenu/ProcessViewerMenu'

import './ProcessViewerBody.css'

const mapStateToProps = ({ displayedPhaseId }) => ({ displayedPhaseId })

const mapDispatchToProps = dispatch => ({
  dispatchSetDisplayedProcessPhase: phaseId =>
    dispatch(setDisplayedProcessPhase(phaseId)),
})

const renderProcessChangesTableRow = (activeDevice, activeProcess) => {
  const state = Process.getState(activeProcess)
  if (!Process.isRunning({ state }) && !Process.isPaused({ state })) return null

  const phases = PhaseGroupRenderer.getGroupAdjustedPhaseSequence(activeProcess)
  return (
    <ChangesFooter
      activeProcess={activeProcess}
      activeDevice={activeDevice}
      phases={phases}
    />
  )
}

const ProcessViewerBody = ({
  activeProcess,
  phases,
  activeDevice,
  dispatchSetDisplayedProcessPhase,
  displayedPhaseId,
}) => {

  const tabsToParams = {
    builder: 'Builder',
    dataviewer: 'Data Viewer',
    overview: 'Overview',
    logs: 'Logs',
    downloads: 'Downloads',
    dashboard: 'Dashboard',
    fctGraph: 'Functionality Graph',
  }

  const query = new URLSearchParams(useLocation().search)
  const history = useHistory()

  function setActiveTabToURL(tab) {
    history.replace(`?active-tab=${ObjectTools.getKeyByValue(tabsToParams, tab)}`)
  }

  function getActiveTabFromURL() {
    const queryTab = query.get('active-tab')
    return tabsToParams[queryTab]
  }

  function getDefaultTab() {
    return Process.isExecutable(activeProcess) ? 'Builder' : 'Data Viewer'
  }

  const renderFallbackPhase = () => {
    const phaseId = ProcessViewerDisplay.getInitiallyDisplayedPhaseId(activeProcess)
    dispatchSetDisplayedProcessPhase(phaseId)
  }

  const tabFromURL = getActiveTabFromURL()

  const activeTab = tabFromURL || getDefaultTab()

  useEffect(() => {
    if (!tabFromURL) {
      setActiveTabToURL(getDefaultTab())
    }

    const phaseIsRendered = phases.some(({ id }) => id === displayedPhaseId)

    if (phaseIsRendered) return

    const phase = DescriptionParser.getPhaseById(activeProcess, displayedPhaseId)

    if (!phase) {
      renderFallbackPhase()
      return
    }

    const { iterationOf, groupName } = phase

    if (!groupName) {
      renderFallbackPhase()
      return
    }

    const phaseIterationIds = Validator.isUndefinedOrNull(iterationOf)
      ? PhaseGroupParser.getPhaseIterationIds(activeProcess, displayedPhaseId)
      : PhaseGroupParser.getPhaseIterationIds(activeProcess, iterationOf)

    const nextIteration = phases.find(({ id }) => phaseIterationIds.includes(id))

    if (nextIteration) {
      dispatchSetDisplayedProcessPhase(nextIteration.id)
    } else {
      dispatchSetDisplayedProcessPhase(iterationOf)
    }

  })

  const hasSyncedPorts = FunctionalityGraph.processPortsAreUpToDate(activeProcess)

  return (
    <div>
      <OutdatedProcessInfoBanner activeProcess={activeProcess} />
      {renderProcessChangesTableRow(activeDevice, activeProcess)}
      <Segment>
        <div>
          <ProcessViewerMenu
            activeTab={activeTab}
            setActiveTab={setActiveTabToURL}
            hasSyncedPorts={hasSyncedPorts}
          />
          {activeTab === 'Builder' && (
            <Segment
              attached='bottom'
              className='no-border'
            >
              <Builder
                phases={phases}
                activeProcess={activeProcess}
                activeDevice={activeDevice}
              />
            </Segment>
          )}
          {activeTab === 'Data Viewer' && (
            <Segment attached='bottom' className='no-border'>
              <DataGraphs
                activeProcess={activeProcess}
                activeDevice={activeDevice}
              />
            </Segment>
          )}
          {activeTab === 'Dashboard' && (
            <Segment attached='bottom' className='no-border'>
              <Dashboards
                activeProcess={activeProcess}
                activeDevice={activeDevice}
              />
            </Segment>
          )}
          {activeTab === 'Overview' && (
            <Segment
              attached='bottom'
              style={{ overflow: 'auto' }}
              className='no-border'
            >
              <ProcessOverview
                phases={phases}
                activeProcess={activeProcess}
                hasSyncedPorts={hasSyncedPorts}
              />
            </Segment>
          )}
          {activeTab === 'Logs' && (
            <Segment attached='bottom' className='no-border'>
              <LogView />
            </Segment>
          )}
          {activeTab === 'Downloads' && (
            <Segment attached='bottom' className='no-border'>
              <ProcessDownloadRequests
                activeProcess={activeProcess}
              />
            </Segment>
          )}
        </div>
      </Segment>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessViewerBody)
