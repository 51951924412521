import store from '~/redux/store'
import callLoadHistoricSensorData from '~/redux/thunks/process/callLoadHistoricSensorData'
import { Process, PROCESS_STATES } from '~/utils/process'

class GraphTools {

  static get DATAPOINT_HIT_RADIUS() { return 5 }
  static get ANNOTATATION_BORDER_WITH() { return 2 }

  static NO_LIMIT_VALUE = -1

  static STRIP_LINE_TYPES = {
    ANNOTATION: 'annotation',
    PHASE_START: 'phaseStart',
  }

  static DISPLAY_MODES = {
    LIVE: 'live',
    PHASE: 'phase',
    FULL: 'full',
    HOUR: 'hour',
    HOUR8: 'hour8',
    DAY: 'day',
    CUSTOM: 'custom'
  }

  static DISPLAY_TIME_FRAME_IN_MS = {
    [GraphTools.DISPLAY_MODES.LIVE]: 300000,
    [GraphTools.DISPLAY_MODES.HOUR]: 3600000,
    [GraphTools.DISPLAY_MODES.HOUR8]: 28800000,
    [GraphTools.DISPLAY_MODES.DAY]: 86400000,
  }

  static CONSTANTS = {
    PAN_DEBOUNCE_INTERVAL: 2000,
    MINIMUM_POINTS_IN_VIEWPORT: 600,
    RELOAD_COUNT: 3000,
    DEFAULT_NUMBER_POINTS: 3000,
    POINTS_IN_LIVE_VIEW: 120,
    MANUAL_ZOOM_Y_MIN: 0,
    MANUAL_ZOOM_Y_MAX: 100,
  }

  static CLICK_HANDLER_OBJECTS = {
    DATAPOINT: 'datapoint',
    ANNOTATION: 'annotation'
  }

  static COLOR_SET = [
    '#900e2c',
    '#1578c2',
    '#016936',
    '#FFD700',
  ]

  static DEFAULT_COLOR = '#900E2C'

  static getColorByIndex = index => {
    const colorIndex = index % this.COLOR_SET.length
    return this.COLOR_SET[colorIndex]
  }

  static getIntervalLimitsForRefetch(minTime, maxTime) {
    const start = minTime
    const end = maxTime === null ? -1 : maxTime

    return { start, end }
  }

  static requireRefetch(dps, minTime, maxTime) {
    const dpsBeforeMin = []
    const dpsInViewPort = []

    const usedMaxTime = maxTime === null ? Infinity : maxTime

    dps.forEach(dp => {
      if (dp.x < minTime) dpsBeforeMin.push(dp)
      else if (dp.x >= minTime && dp.x <= usedMaxTime) dpsInViewPort.push(dp)
    })

    if (dpsInViewPort.length < this.CONSTANTS.MINIMUM_POINTS_IN_VIEWPORT) {
      return true
    }

    /* This guarantees we load old data when we have a lot of recent data */
    /* but no data at all for big chunk of the process BEFORE the first dp
    /* of the recent data */
    const offsetFromOriginInSeconds = 8
    if (dpsBeforeMin.length === 0 && dpsInViewPort[0].x > offsetFromOriginInSeconds) {
      return true
    }

    return false
  }

  static fetchDataInViewport({
    minTime,
    maxTime,
    activeProcess,
    sensorData,
    reporterFctId,
    loadMaterializedView,
  }) {

    if (!GraphTools.requireRefetch(sensorData, minTime, maxTime)) return

    const { start, end } = GraphTools.getIntervalLimitsForRefetch(minTime, maxTime)

    return callLoadHistoricSensorData(
      store.dispatch,
      activeProcess,
      reporterFctId,
      this.CONSTANTS.RELOAD_COUNT,
      start,
      end,
      loadMaterializedView,
    )
  }

  static getIntervalInMSFromMode = mode => {
    if (mode in GraphTools.DISPLAY_TIME_FRAME_IN_MS) {
      return GraphTools.DISPLAY_TIME_FRAME_IN_MS[mode]
    }
    throw Error(`Unknown mode ${mode} provided`)
  }

  static getRelativeProcessTimeAgoInSeconds = (process, timeAgoInMs) => {
    const totalDur = Process.getElapsedProcessTimeInMs(process)
    return Math.max(totalDur - timeAgoInMs, 0) / 1000
  }

  static generateAxisId = (fctId, slotname) => `yAxis-${ fctId }-${ slotname }`

  static generateNullYLimit = (reporterData) => {
    return reporterData.reduce((a, v) => ({ ...a, [GraphTools.generateAxisId(v.fct.id, v.slot.name)]: {min: null, max: null} }), {})
  }

  static deriveTimeBoundariesFromMode = (rangeSettings, activeProcess) => {
    const { mode, selectedPhaseId } = rangeSettings
    switch (mode) {
      case GraphTools.DISPLAY_MODES.PHASE: {
        const { start, end } = Process.getPhaseBoundaries(activeProcess, selectedPhaseId)
        return { minTime: start, maxTime: end === Infinity ? null : end }
      }
      case GraphTools.DISPLAY_MODES.FULL: {
        return { minTime: 0, maxTime: null }
      }
      case GraphTools.DISPLAY_MODES.CUSTOM: {
        return { minTime: rangeSettings.x.min, maxTime: rangeSettings.x.max }
      }
      default:
        return {
          minTime: GraphTools.getRelativeProcessTimeAgoInSeconds(
            activeProcess,
            GraphTools.getIntervalInMSFromMode(mode),
          ),
          maxTime: null,
        }
    }
  }
}

export default GraphTools
