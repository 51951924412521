import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FCTGraph, Functionality } from '@ospin/fct-graph'
import Slot from '~/utils/functionalities/slots/Slot'
import DataManager from '~/utils/DataManager'
import PhaseProgression from '~/utils/process/PhaseProgression'
import DescriptionParser from '~/utils/process/DescriptionParser'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import SensorWidgetBody from './SensorWidgetBody'
import EscalationWidgetModal from './EscalationWidgetModal'

import './SensorWidget.css'

const mapStateToProps = ({ functionalityDescriptions }) => ({ functionalityDescriptions })

const getTargetValue = (functionalityDescriptions, fct, slot, process) => {
  const description = FunctionalityDescription
    .getBySubType(functionalityDescriptions, fct.subType)

  const targetSlotName = FunctionalityDescription
    .getAssociatedTargetSlotName(description, slot.name)

  if (!targetSlotName) return null

  const targetSlot = Functionality.getSlotByName(fct, targetSlotName)
  if (!targetSlot) return null

  const inputNodeFctId = FCTGraph
    .getInputNodeFctIdForSlot(process.fctGraph, fct.id, targetSlot.name)

  if (!inputNodeFctId) return null

  const latestPhase = PhaseProgression.getLatest(process)
  if (!latestPhase) return null

  const { phaseId } = latestPhase

  return DescriptionParser
    .getInputNodeValue(process, phaseId, inputNodeFctId)
}

const SensorWidget = ({
  activeProcess,
  removeWidget,
  setAllowDrag,
  widget,
  dashboard,
  functionalityDescriptions,
}) => {
  const { fctGraph } = activeProcess
  const { fctId, slotName } = widget

  const fct = FCTGraph.getFctById(fctGraph, fctId)
  const slot = Functionality.getSlotByName(fct, slotName)
  const reporterFctId = FCTGraph
    .getReporterFctIdForSlot(fctGraph, fct.id, slot.name)

  const [ isEscalationWidgetOpen, setIsEscalationWidgetOpen ] = useState(false)
  const value = DataManager.getLastSensorDataPoint(activeProcess, reporterFctId)

  const image = Slot.getImage(slot)

  const onCloseModalWidget = () => {
    setIsEscalationWidgetOpen(false)
    setAllowDrag(true)
  }

  const onClickEscalation = () => {
    setIsEscalationWidgetOpen(true)
    setAllowDrag(false)
  }

  return (
    <div className='sensor-widget'>
      <SensorWidgetBody
        onClickEscalation={onClickEscalation}
        removeWidget={removeWidget}
        image={image}
        fct={fct}
        slot={slot}
        value={value}
        displayedValue={value}
        widget={widget}
        targetValue={getTargetValue(functionalityDescriptions, fct, slot, activeProcess)}
      />
      <EscalationWidgetModal
        headerText='Alerts'
        closeHandler={onCloseModalWidget}
        open={isEscalationWidgetOpen}
        dashboard={dashboard}
        widget={widget}
        fctGraphId={fctGraph.id}
        size='small'
      />
    </div>
  )
}

export default connect(mapStateToProps)(SensorWidget)
