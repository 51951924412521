import React from 'react'
import { withRouter } from 'react-router-dom'
import FeatureVersioning from '~/utils/FeatureVersioning'
import './DeviceMenu.css'

const newPathIsSamePath = (tab, history) => {
  const pathSplitted = history.location.pathname.split('/')
  return tab === pathSplitted[pathSplitted.length - 1]
}

const goTo = (tab, history) => {
  if (newPathIsSamePath(tab, history)) {
    return
  }

  const pathSplitted = history.location.pathname.split('/')
  const fctGraphIdPresent = !!pathSplitted[4]
  const isConfigView = pathSplitted[3] === 'configurations' && fctGraphIdPresent
  const index = pathSplitted.findIndex(el => el === 'devices') + (isConfigView ? 3 : 1)
  const newPath = `${pathSplitted.filter((_, i) => i <= index).join('/')}/${tab}`
  history.push(newPath)
}

const readActiveTabFromUrl = history => {
  const pathSplitted = history.location.pathname.split('/')
  const fctGraphIdPresent = !!pathSplitted[4]
  const isConfigView = pathSplitted[3] === 'configurations' && fctGraphIdPresent
  const index = pathSplitted.findIndex(el => el === 'devices') + (isConfigView ? 4 : 2)
  return pathSplitted[index]
}

const menuItemsConfiguration = [
  { tab: 'processes', text: 'My Processes' },
  { tab: 'setup', text: 'Settings' },
]

const menuItemsDevice = [
  menuItemsConfiguration[0],
  { tab: 'logs', text: 'Logs' },
  menuItemsConfiguration[1],
  { tab: 'access', text: 'Access' },
]

const renderMenuItem = ({ tab, text }, activeTab, history) => (
  <a
    target='_blank'
    onClick={() => goTo(tab, history)}
    key={tab}
    className={`device-menu-item ${activeTab === tab ? 'device-menu-item--selected' : ''}`}
  >
    {text}
  </a>
)

const renderMenuItems = (items, activeTab, history) => items
  .map(item => renderMenuItem(item, activeTab, history))

const DeviceMenu = ({ activeDevice, history, match }) => {
  const activeTab = readActiveTabFromUrl(history)

  const getDisplayedMenuItems = () => {
    const { params: { fctGraphId } } = match
    if (fctGraphId) {
      return menuItemsConfiguration
    }
    if (FeatureVersioning.supportsMultipleProcesses(activeDevice)) {
      return [
        ...menuItemsDevice.slice(0, 1),
        { tab: 'configurations', text: 'My Devices & Solutions' },
        ...menuItemsDevice.slice(1),
      ]
    }
    return menuItemsDevice
  }

  return (
    <div className='device-menu-container'>
      {renderMenuItems(getDisplayedMenuItems(), activeTab, history)}
    </div>
  )
}

export default withRouter(DeviceMenu)
