import React from 'react'
import { Button } from 'semantic-ui-react'
import './ProcessIconBar.css'
import { PROCESS_STATES } from '~/utils/process'

const getIconStyle = iconName => ({
  backgroundColor: 'transparent',
  padding: '1em', // iOS recommendation touch screen
  color: (iconName === 'star' && '#FFB500'),
})

const renderIcon = ({ icon, handler }) => (
  <Button
    key={icon}
    size='tiny'
    circular
    style={getIconStyle(icon)}
    icon={icon}
    onClick={handler}
  />
)

const renderIcons = (
  toggleModal,
  handleToggleFavorite,
  isPinned,
) => {

  const iconData = [
    {
      icon: isPinned ? 'star' : 'star outline',
      handler: e => handleToggleFavorite(e),
    },
    {
      icon: 'bars',
      handler: e => toggleModal(e, 'showProcessDetailsModal'),
    },
  ]

  return iconData.map(renderIcon)
}

const getStateColorCSSClass = state => {
  if (state === PROCESS_STATES.running) {
    return 'ospin-blue'
  }
  if (state === PROCESS_STATES.paused) {
    return 'ospin-orange'
  }
  return ''
}

export const IconMenu = ({
  processState,
  toggleModal,
  handleToggleFavorite,
  isPinned,
  showProcessDetailsModal,
  contentJustificationClass = 'space-between-items',
}) => (
  <div className={contentJustificationClass}>
    <div className={`process-tile-process-state ${getStateColorCSSClass(processState)}`}>
      {processState}
    </div>
    <div className='pointer process-icon-bar-container'>
      {renderIcons(
        toggleModal,
        handleToggleFavorite,
        isPinned,
        showProcessDetailsModal,
      )}
    </div>
  </div>
)

const ProcessIconBar = props => {

  const {
    processState,
    toggleModal,
    handleToggleFavorite,
    isPinned,
    showProcessDetailsModal,
  } = props

  return (
    <IconMenu
      processState={processState}
      toggleModal={toggleModal}
      handleToggleFavorite={handleToggleFavorite}
      isPinned={isPinned}
      showProcessDetailsModal={showProcessDetailsModal}
    />
  )
}

export default ProcessIconBar
