import store from '~/redux/store'
import { updateUser } from '~/redux/actions/actions'

export default (userId, { notifications }) => {
  const { user } = store.getState()

  const newNotifications = [ ...user.notifications ]

  notifications.forEach(not => {
    const exists = user.notifications.some(existingNot => existingNot.id === not.id)
    if (!exists) newNotifications.push(not)
  })

  store.dispatch(updateUser({ notifications: newNotifications }))
}
