import PhaseProgression from '~/utils/process/PhaseProgression'
import { getFirstPhaseId, Process } from '~/utils/process'

export default class ProcessViewerDisplay {

  static getInitiallyDisplayedPhaseId(process) {
    if (Process.isRunning(process) || Process.isPaused(process)) {
      const entry = PhaseProgression.getLatest(process)
      if (entry) return entry.phaseId
    }

    return getFirstPhaseId(process)
  }

}
