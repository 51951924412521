import React from 'react'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import { Modal, Form, Icon, Input, Divider, Button, Message, Checkbox, Select } from 'semantic-ui-react'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'
import ConfiguredSlot from '~/utils/functionalities/slots/ConfiguredSlot'
import Validator from '~/utils/validation/Validator'
import nexus from '@ospin/nexus'

class SetSlotDefaultModal extends React.Component {

  initialState = {
    isError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    slotDefault: '',
    targetFctId: null,
    targetSlotName: null,
  }

  state = { ...this.initialState }

  handleUpdateFct = (e, { name, value: fctId }) => {

    const { selectedGraph } = this.props
    const fct = FCTGraph.getFctById(selectedGraph, fctId)

    this.setState({
      [name]: fctId,
      targetSlotName: fct.slots.length === 1 ? fct.slots[0].name : null,
      isError: false,
      errorMessage: '',
      isSuccess: false,
    })
  }

  handleUpdate = (e, { name, value }) => this.setState({
    [name]: value,
    isError: false,
    errorMessage: '',
    isSuccess: false,
  })

  getFunctionalityOptions = () => {
    const { selectedGraph } = this.props

    return selectedGraph.functionalities
      .filter(fct => fct.slots.some(slot => slot.type === 'InSlot') && fct.type !== 'OutputNode')
      .map(fct => ({
        value: fct.id,
        text: fct.name,
        key: fct.id,
      }))
  }

  getSlotOptions = fct => {
    if (!fct) return []

    return Functionality.getInSlots(fct)
      .map(slot => ({
        value: slot.name,
        text: Slot.getDisplayName(slot),
        key: slot.name,
      }))
  }

  getIntegerValue = (slotDefault, slot) => {
    if (!Validator.isIntegerString(slotDefault)) {
      this.setState({ isError: true, errorMessage: 'Default value has to be an integer.' })
      return
    }

    const parsedTarget = parseInt(slotDefault, 10)

    if (parsedTarget > slot.max || parsedTarget < slot.min) {
      this.setState({ isError: true, errorMessage: 'Default target not within valid channel limits.' })
      return
    }

    return parsedTarget
  }

  getFloatValue = (slotDefault, slot) => {
    const parsedTarget = parseFloat(slotDefault)

    if (isNaN(parsedTarget)) {
      this.setState({ isError: true, errorMessage: 'Default value has to be a number.' })
      return
    }

    if (parsedTarget > slot.max || parsedTarget < slot.min) {
      this.setState({ isError: true, errorMessage: 'Default target not within valid channel limits.' })
      return
    }

    return parsedTarget
  }

  getOneOfValue = (slotDefault, slot) => {
    if (!slot.selectOptions.includes(slotDefault)) {
      this.setState({ isError: true, errorMessage: 'Default target not within acceped values.' })
      return
    }
    return slotDefault
  }

  submitSlotDefaultInputValue = async (fctId, defaultValue) => {
    const {
      activeDevice,
      fctsConfigs,
      setFctsConfigs,
    } = this.props
    const { targetSlotName } = this.state
    const { selectedGraph } = this.props

    const updates = [{
      fctId,
      slotsConfigs: [{ name: targetSlotName, defaultInputValue: defaultValue }],
    }]

    try {
      const { data: updatedFctsConfigs } = await nexus.device.functionalityGraph
        .functionalityConfiguration.updateMany({
          deviceId: activeDevice.id,
          fctGraphId: selectedGraph.id,
          updates,
        })
      const mergedFctConfigs = FunctionalityConfiguration.mergeMany(fctsConfigs, updatedFctsConfigs)
      setFctsConfigs(mergedFctConfigs)
      this.setState({
        isSuccess: true,
        isError: false,
        errorMessage: '',
        isLoading: false,
      })
    } catch (e) {
      this.setState({
        isError: true,
        errorMessage: e.message,
        isSuccess: false,
        isLoading: false,
      })
    }
  }

  getConvertedValue = (slot, slotDefault) => {
    switch (slot.dataType) {
      case 'oneOf': {
        return this.getOneOfValue(slotDefault, slot)
      }
      case 'integer': {
        return this.getIntegerValue(slotDefault, slot)
      }
      default: {
        return this.getFloatValue(slotDefault, slot)
      }
    }
  }

  submitNewDefault = async () => {
    const { targetFctId, targetSlotName, slotDefault } = this.state
    const { selectedGraph } = this.props
    const fct = FCTGraph.getFctById(selectedGraph, targetFctId)
    const slot = Functionality.getSlotByName(fct, targetSlotName)

    const convertedDefaultTarget = this.getConvertedValue(slot, slotDefault)

    if (convertedDefaultTarget === undefined) return

    this.setState({ isLoading: true })

    await this.submitSlotDefaultInputValue(targetFctId, convertedDefaultTarget)
  }

  submitBooleanDefault = async newState => {
    const { targetFctId } = this.state
    await this.submitSlotDefaultInputValue(targetFctId, newState)
  }

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  renderBooleanSlot = (slot, slotConfig) => {
    const currDefault = ConfiguredSlot.getDefaultValue(slot, slotConfig)

    return (
      <Form.Field>
        <label>
          Current Default
        </label>
        <Checkbox
          toggle
          checked={currDefault}
          onChange={() => this.submitBooleanDefault(!currDefault)}
          style={{ marginTop: '8px' }}
        />
      </Form.Field>
    )
  }

  getSlotValueOptions = selectOptions => (
    selectOptions.map(value => ({
      value,
      text: value,
    }))
  )

  renderOneOfSlot = (slot, slotConfig) => {
    const { slotDefault } = this.state

    const { selectOptions } = slot
    const currDefault = ConfiguredSlot.getDefaultValue(slot, slotConfig)

    const options = this.getSlotValueOptions(selectOptions)

    return (
      <>
        <Form.Field>
          <label>
            Current Default
            {`[${slot.unit}]`}
          </label>
          <Select
            value={currDefault}
            readOnly
            open={false}
            options={options}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>
            New Default
            {`[${slot.unit}]`}
          </label>
          <Select
            value={slotDefault}
            options={options}
            closeOnChange
            required
            onChange={this.handleUpdate}
            name='slotDefault'
          />
        </Form.Field>
      </>
    )
  }

  renderNumericSlot = (slot, slotConfig) => {

    const { slotDefault } = this.state
    const currDefault = ConfiguredSlot.getDefaultValue(slot, slotConfig)

    return (
      <>
        <Form.Field>
          <label>
            Current Default
            {` [${slot.unit}]`}
          </label>
          <Input
            fluid
            type='text'
            value={currDefault}
            readOnly
          />
        </Form.Field>
        <Form.Field>
          <label>
            New Default
            {`[${slot.unit}]`}
          </label>
          <Input
            fluid
            name='slotDefault'
            type='number'
            required
            value={slotDefault}
            onChange={this.handleUpdate}
          />
        </Form.Field>
      </>
    )
  }

  renderSlotByExpectedValueType = fct => {
    if (!fct) return null

    const { targetSlotName } = this.state
    if (!targetSlotName) return null

    const { fctsConfigs } = this.props

    const slot = Functionality.getSlotByName(fct, targetSlotName)
    const slotConfig = FunctionalityConfiguration.getSlotConfig(fctsConfigs, fct.id, slot.name)

    switch (slot.dataType) {
      case 'boolean': {
        return this.renderBooleanSlot(slot, slotConfig)
      }
      case 'oneOf': {
        return this.renderOneOfSlot(slot, slotConfig)
      }
      default: {
        return this.renderNumericSlot(slot, slotConfig)
      }
    }
  }

  showSubmitButton = fct => {
    if (!fct) return false
    const { targetSlotName } = this.state
    if (!targetSlotName) return false

    const slot = Functionality.getSlotByName(fct, targetSlotName)
    return slot.dataType !== 'boolean'
  }

  render() {

    const {
      isError,
      errorMessage,
      isLoading,
      isSuccess,
      targetFctId,
      targetSlotName,
    } = this.state

    const { selectedGraph, open } = this.props

    const fct = targetFctId !== null
      ? FCTGraph.getFctById(selectedGraph, targetFctId)
      : null

    const functionalityOptions = this.getFunctionalityOptions()
    const slotOptions = this.getSlotOptions(fct)

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={this.clearStateAndClose}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='edit' />
          Set Channel Default Value
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              onSubmit={this.submitNewDefault}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              <Form.Select
                fluid
                name='targetFctId'
                label='Functionality'
                value={targetFctId}
                options={functionalityOptions}
                onChange={this.handleUpdateFct}
                placeholder='Choose Functionality'
              />
              <Form.Select
                fluid
                name='targetSlotName'
                label='Channel'
                value={targetSlotName}
                options={slotOptions}
                onChange={this.handleUpdate}
                placeholder='Choose Channel'
                disabled={!fct}
              />
              <Form.Group widths='equal'>
                {this.renderSlotByExpectedValueType(fct)}
              </Form.Group>
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success!'
                content={<p>Default was set.</p>}
                icon='warning circle'
              />
              <Divider />
              {this.showSubmitButton(fct) && (
                <Button
                  floated='right'
                  primary
                  type='submit'
                  loading={isLoading}
                >
                  Submit
                </Button>
              )}
              <Button
                floated='right'
                onClick={this.clearStateAndClose}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default SetSlotDefaultModal
