import { Functionality, Slot } from '@ospin/fct-graph'
import { isPhysical } from '@ospin/fct-graph/src/functionalities/Functionality'
import TemplateUtils from './TemplateUtils'
import input from '../assets/input.svg'
import output from '../assets/output.svg'
import cross from '../assets/cross.svg'

const PORT_SPACING = 35
const PORT_SIZE = 30
const ICON_PADDING = 10
const TEXT_LINE_HEIGHT = 30
const NODE_WIDTH = 250
const BASE_HEIGT = 20
const IMAGE_SIZE = NODE_WIDTH * 0.8
const CHARACTERS_PER_LINE = 20

const PORT_GROUPS = {
  INPUT: 'input',
  OUTPUT: 'output',
}

function generateBaseMarkup(fct) {
  const { isVirtual } = fct
  const baseLayer = [
    {
      tagName: 'rect',
      selector: 'body',
    },
  ]
  const baseMarkup = [{
    tagName: 'g',
    children: [
      {
        tagName: 'rect',
        selector: 'labelBackground',
      }, {
        tagName: 'text',
        selector: 'label',
      },
    ],
  }, {
    tagName: 'g',
    children: [ {
      tagName: 'rect',
      selector: 'infoButton',
    }, {
      tagName: 'text',
      selector: 'infoButtonLabel',
    }],
  }, {
    tagName: 'g',
    children: [
      {
        tagName: 'circle',
        selector: 'deleteButtonCircle',
      }, {
        tagName: 'text',
        selector: 'deleteButtonText',
      },
    ],
  }]

  const physicalFctMarkup = [
    {
      tagName: 'g',
      children: [
        {
          tagName: 'rect',
          selector: 'imgBackground',
        }, {
          tagName: 'image',
          selector: 'img',
        },
      ],
    },
  ]

  return {
    width: NODE_WIDTH,
    markup: isVirtual
      ? [...baseLayer, ...baseMarkup ]
      : [...baseLayer, ...physicalFctMarkup, ...baseMarkup ],
  }
}

function generateBaseAttrs(fct) {
  const { iconURL, name, isVirtual } = fct
  const baseAttributes = {
    body: {
      stroke: '#D9D9D9',
      strokeWidth: 10,
      fill: '#D9D9D9',
      rx: 6,
      ry: 6,
    },
    label: {
      refX: 8,
      refY: 28,
      text: name,
      textVerticalAnchor: 'top',
      textAnchor: 'start',
    },
    labelBackground: {
      height: TEXT_LINE_HEIGHT,
      width: NODE_WIDTH - ICON_PADDING,
      refY: 28,
      refX: ICON_PADDING / 2,
      rx: 6,
      ry: 6,
      stroke: '#D9D9D9',

      fill: '#FFFFFF',
    },
    infoButton: {
      refX: '70%',
      refY: 5,
      width: 50,
      height: 18,
      stroke: '#D9D9D9',
      rx: 10,
      ry: 10,
      fill: '#FFFFFF',
      cursor: 'pointer',
      event: 'node:info',
    },
    infoButtonLabel: {
      text: 'Info',
      refX: '79%',
      refY: 15,
      width: 24,
      height: 18,
      fontSize: 14,
      event: 'node:info',
    },
    deleteButtonCircle: {
      refX: '95%',
      refY: 13,
      r: 9,
      cursor: 'pointer',
      fill: '#FF3920',
      event: 'node:delete',

    },
    deleteButtonText: {
      text: 'x',
      refX: '95%',
      refY: 13,
      fill: '#F5F5F5',
      fontSize: '18',
      event: 'node:delete',
    },
    text: { fontSize: 30 },
  }

  const physicalAttributes = {
    img: {
      refX: (NODE_WIDTH - IMAGE_SIZE) / 2,
      refY: (NODE_WIDTH - IMAGE_SIZE) / 2,
      xlinkHref: iconURL || cross,
      width: IMAGE_SIZE,
    },
    imgBackground: {
      width: NODE_WIDTH,
      height: NODE_WIDTH,
      rx: 6,
      ry: 6,
      stroke: 'none',
    },
  }

  return isVirtual ? baseAttributes : { ...baseAttributes, ...physicalAttributes }
}

const BASE_PORT_GROUPS = {
  input: {
    position: 'absolute',
    label: { position: 'right' },
    attrs: {
      circle: {
        stroke: '#8f8f8f',
        r: 15,
      },

    },
  },
  output: {
    position: 'absolute',
    label: { position: 'left' },
    attrs: {
      circle: {
        stroke: '#8f8f8f',
        r: 15,
      },
    },
  },
}

function generateNodePort(slot, idx, fct, template) {
  const { name, unit } = slot
  const { posId } = fct

  const templateSlot = TemplateUtils.getSlot(template, { posId, slotName: slot.name })

  const isConnected = TemplateUtils.isConnectedToOtherFct(
    template,
    { slotName: name, posId: fct.posId },
  )

  const isInput = slot.type === Slot.TYPES.IN_SLOT
  const isConnectedToReporterOrInput = isInput ? !!TemplateUtils.getPushInNode(template, { posId: fct.posId, slotName: name }) : !!TemplateUtils.getReporterNode(template, { posId: fct.posId, slotName: name })

  const displayName = templateSlot?.displayName || slot.name

  const spaceFromTop = Functionality.isVirtual(fct) ? 0 : IMAGE_SIZE

  return {
    id: name,
    group: isInput ? PORT_GROUPS.INPUT : PORT_GROUPS.OUTPUT,
    args: {
      x: isInput ? 0 : generateBaseMarkup(fct).width,
      y: BASE_HEIGT + spaceFromTop + (PORT_SIZE * 2) + (idx * PORT_SPACING),
    },
    data: { slotName: name },
    attrs: {
      text: {
        text: displayName,
        fill: isConnectedToReporterOrInput ? 'black' : 'grey',
      },
      circle: { /// This cirlce is used as anchor for the edge
        fill: 'none',
        magnet: !isConnected,

        stroke: 'none',
        r: 11.5,
        refX: 2,
        zIndex: 0,
      },
      img: {
        xlinkHref: isInput ? input : output,
        magnet: !isConnected,
        refX: -Math.abs(PORT_SIZE) / 2,
        refY: -Math.abs(PORT_SIZE) / 2,
        width: PORT_SIZE,
        height: PORT_SIZE,
        zIndex: 2,
        portGroup: isInput ? 'input' : 'output',
        slotName: name,
        stroke: '#fff',
      },

      unitText: {
        text: unit,
        refX: -14,
        refY: -12,
        fontSize: 5,
        fill: '#fff',

        slot: name,
        portGroup: isInput ? 'input' : 'output',

      },
    },
    markup: [{
      tagName: 'circle',
      selector: 'circle',
    }, {
      tagName: 'image',
      selector: 'img',
    }, {
      tagName: 'text',
      selector: 'unitText',
    }],

  }
}

export function generatePhysicalFctMarkup(fct, template) {
  const { slots, posId } = fct

  const { props: { name } } = TemplateUtils.getFctByPosId(template, { posId })
  return {
    ...generateBaseMarkup(fct),
    height: BASE_HEIGT + IMAGE_SIZE + (slots.length * PORT_SPACING) + 40,
    ports: {
      groups: BASE_PORT_GROUPS,
      items: slots.map((slot, idx) => generateNodePort(slot, idx, fct, template)),
    },
    attrs: generateBaseAttrs({ ...fct, name }),
  }
}

export function generateVirtualFctMarkup(fct, template) {
  const { posId, slots } = fct

  const { props: { name } } = TemplateUtils.getFctByPosId(template, { posId })

  return {
    ...generateBaseMarkup(fct),
    label: name,
    height: 40 + (slots.length * PORT_SPACING) + 20,
    ports: {
      groups: BASE_PORT_GROUPS,
      items: slots.map((slot, idx) => generateNodePort(slot, idx, fct, template)),
    },
    attrs: generateBaseAttrs(fct),
  }
}
