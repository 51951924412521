function calcTotalPages(totalItems, itemsPerPage) {
  return Math.ceil(totalItems / itemsPerPage)
}

function calculateSkippedItems(activePage, itemsPerPage) {
  return ((activePage - 1) * itemsPerPage)
}

function generatePaginationSentence(activePage, totalMatching, itemsPerPage) {
  const firstDisplayedItems = (activePage - 1) * itemsPerPage
  const itemsOnScreen = (activePage * itemsPerPage) > totalMatching
    ? totalMatching % itemsPerPage
    : itemsPerPage
  const lastDisplayedProcess = firstDisplayedItems + itemsOnScreen
  return `showing ${firstDisplayedItems + 1} - ${lastDisplayedProcess} out of ${totalMatching}`
}

export {
  calcTotalPages,
  calculateSkippedItems,
  generatePaginationSentence
}
