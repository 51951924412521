import React, { useState } from 'react'
import { Button, Form, List, Message } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import { removeWhiteSpaces } from '~/utils/string'
import PasswordValidation from '~/utils/validation/PasswordValidation'
import AuthViewContainer from '../AuthViewContainer'
import RedirectionLink from './RedirectionLink'
import PasswordRulesPopup from '../PasswordRulesPopup'
import PasswordStrenghIndicator from '../PasswordStrenghIndicator'

let isPasswordFieldValid = false

export default function SignUp() {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validInputs, setValidInputs] = useState({
    username: true,
    password: true,
    confirmPassword: true,
    email: true,
    phone: true,
  })

  const [inputValues, setInputValues ] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    phone: '',
  })

  const [showPasswordPopup, setShowPasswordPopup] = useState(false)

  async function submit() {
    setLoading(true)
    try {
      const { username, password, email, phone } = inputValues

      await nexus.auth.signUp({ username, password, attributes: { email, phone_number: phone } })
      setSuccess(true)
      setError(false)
    } catch ({ message }) {
      setError(message)
    } finally {
      setLoading(false)
    }
  }

  const TOP_FIELDS = [
    {
      icon: 'user',
      name: 'username',
      errorMessage: 'username can not be empty',
      autoComplete: 'username',
      type: 'text',
      validator: val => !!val,
    },
  ]

  const PASSWORD_FIELD = [
    {
      icon: 'lock',
      name: 'password',
      autoComplete: 'new-password',
      type: 'password',
      validator: val => !!val,
    },
  ]

  const BOTTOM_FIELDS = [
    {
      icon: 'lock',
      name: 'confirmPassword',
      label: 'confirm password',
      errorMessage: 'passwords do not match',
      autoComplete: 'new-passwords',
      type: 'password',
      validator: val => !!val && val === inputValues.password,
    }, {
      name: 'email',
      icon: 'at',
      errorMessage: 'invalid email',
      autoComplete: 'email',
      type: 'text',
      validator: val => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(val),
    }, {
      name: 'phone',
      icon: 'phone',
      errorMessage: null,
      autoComplete: 'phone',
      skipWhitespaceStrip: true,
      type: 'text',
      optional: true,
      validator: () => true,
    },
  ]

  const getNonValidPasswordRules = passwordValue => (
    PasswordValidation.REQUIRED_PASSWORD_RULES
      .filter(rule => !rule.isValid(removeWhiteSpaces(passwordValue)))
  )

  function validate(field, value, fieldConfig) {
    if (field === 'password') {
      isPasswordFieldValid = getNonValidPasswordRules(value).length === 0
    } else {
      const isValid = fieldConfig.validator(value)
      setValidInputs({ ...validInputs, [field]: isValid })
    }
  }

  const REQUIRED_FIELDS = [...TOP_FIELDS, ...PASSWORD_FIELD, ...BOTTOM_FIELDS
    .filter(field => !field.optional)]
  const OPTIONAL_FIELDS = BOTTOM_FIELDS.filter(field => field.optional)

  const allRequiredFieldsFilled = REQUIRED_FIELDS.every(field => !!inputValues[field.name])
  const allInputsValid = [...REQUIRED_FIELDS, ...OPTIONAL_FIELDS].every(field => (
    field.name === 'password' ? isPasswordFieldValid : validInputs[field.name]
  ))

  const isValid = allRequiredFieldsFilled && allInputsValid && isPasswordFieldValid

  function renderPasswordPopup(password) {
    const trimmedSpacesPassword = removeWhiteSpaces(password)
    return (
      <PasswordRulesPopup password={trimmedSpacesPassword} />
    )
  }

  function updateAndStripWhiteSpace(newValue, name, skipWhitespaceStrip) {
    setInputValues({
      ...inputValues,
      [name]: skipWhitespaceStrip ? newValue : removeWhiteSpaces(newValue),
    })
  }

  function renderPasswordField({
    name,
    icon,
    autoComplete,
    type,
    skipWhitespaceStrip = false,
  }) {

    function handlePasswordOnChange(newValue) {
      updateAndStripWhiteSpace(newValue, name, skipWhitespaceStrip)
      validate('password', newValue, PASSWORD_FIELD[0])

      if (name === 'password') {
        setShowPasswordPopup(true)
      }

      const nonValidPasswordRules = getNonValidPasswordRules(newValue)
      if (!nonValidPasswordRules.length) {
        setShowPasswordPopup(false)
      }
    }

    const { password } = inputValues

    return (
      <React.Fragment key={name}>
        <label htmlFor='password'>
          password
        </label>
        <Form.Input
          size='big'
          name={name}
          id={name}
          fluid
          icon={icon}
          iconPosition='left'
          autoComplete={autoComplete}
          type={type}
          value={password}
          onChange={(_, { value: newValue }) => handlePasswordOnChange(newValue)}
          error={showPasswordPopup && (
            { content: renderPasswordPopup(password) })}
        />
        {password ? <PasswordStrenghIndicator password={password} /> : null}
      </React.Fragment>
    )
  }

  function renderField({
    name,
    icon,
    label,
    errorMessage,
    autoComplete,
    type,
    skipWhitespaceStrip = false,
  }) {

    function handleOnChange(newValue) {
      const fieldConfig = TOP_FIELDS.find(field => field.name === name)
      || BOTTOM_FIELDS.find(field => field.name === name)

      updateAndStripWhiteSpace(newValue, name, skipWhitespaceStrip)
      validate(name, newValue, fieldConfig)
    }

    const value = inputValues[name]
    const errorObject = validInputs[name] ? null : { content: errorMessage }

    return (
      <React.Fragment key={name}>
        <label htmlFor={name}>
          {label || name}
        </label>
        <Form.Input
          size='big'
          name={name}
          id={name}
          fluid
          autoComplete={autoComplete}
          icon={icon}
          iconPosition='left'
          value={value}
          onChange={(_, { value: newValue }) => handleOnChange(newValue)}
          error={errorObject}
          type={type}
        />
      </React.Fragment>
    )
  }

  function renderContent() {
    if (success) {
      return (
        <Message
          success
          header='Signup almost done.'
          content='You should recieve a confirmation email within the next few minutes.'
        />
      )
    }

    return (
      <Form
        error={!!error}
        success={success}
        size='large'
        onSubmit={() => submit()}
      >
        <Form.Field>
          {TOP_FIELDS.map(field => renderField(field))}
          {PASSWORD_FIELD.map(field => renderPasswordField(field))}
          {BOTTOM_FIELDS.map(field => renderField(field))}
          <Button
            fluid
            content='Sign Up'
            primary
            disabled={!isValid}
            type='submit'
            loading={loading}
          />
          {error ? <Message error header='Unable to sign up' content={error} /> : null}
        </Form.Field>
      </Form>
    )
  }

  return (
    <AuthViewContainer>
      {renderContent()}
      <List>
        <List.Item content={<RedirectionLink route='login' text='Back to Login' />} />
      </List>
    </AuthViewContainer>
  )
}
