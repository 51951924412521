import { ACTIONS } from '~/redux/actions/actions'
import setInputNodeValue from '~/redux/reducers/process/setInputNodeValue'
import setPhaseProps from '~/redux/reducers/process/setPhaseProps'
import deletePhase from '~/redux/reducers/process/deletePhase'
import movePhase from '~/redux/reducers/process/movePhase'
import addPhase from '~/redux/reducers/process/addPhase'
import setProcessBuilderValidationErrors from '~/redux/reducers/process/setProcessBuilderValidationErrors'
import updateProcessDescriptionSnapshot from '~/redux/reducers/process/updateProcessDescriptionSnapshot'
import addHistoricSensorData from '~/redux/reducers/process/addHistoricSensorData'
import addLiveSensorData from '~/redux/reducers/process/addLiveSensorData'
import setProcessFctImgs from '~/redux/reducers/process/setProcessFctImgs'
import addProcessFctImgs from '~/redux/reducers/process/addProcessFctImgs'
import setSensorDataErrorFlag from '~/redux/reducers/process/setSensorDataErrorFlag'
import setLoadingMoreHistoricSensorDataFlag from '~/redux/reducers/process/setLoadingMoreHistoricSensorDataFlag'
import addProcessUser from '~/redux/reducers/process/addProcessUser'
import modifyProcessAccess from '~/redux/reducers/process/modifyProcessAccess'
import removeProcessUser from '~/redux/reducers/process/removeProcessUser'
import updateProcess from '~/redux/reducers/process/updateProcess'
import addProcessToProcessesList from '~/redux/reducers/process/addProcessToProcessesList'
import setProcesses from '~/redux/reducers/process/setProcesses'
import deleteProcesses from '~/redux/reducers/process/deleteProcesses'
import createPhaseGroup from '~/redux/reducers/process/createPhaseGroup'
import addPhaseWithinGroup from '~/redux/reducers/process/addPhaseWithinGroup'
import deletePhaseWithinGroup from '~/redux/reducers/process/deletePhaseWithinGroup'
import setPhaseGroupIterationsCount from '~/redux/reducers/process/setPhaseGroupIterationsCount'
import appendPhaseToGroup from '~/redux/reducers/process/appendPhaseToGroup'
import prependPhaseToGroup from '~/redux/reducers/process/prependPhaseToGroup'
import setDownloadRequests from '~/redux/reducers/process/setDownloadRequests'
import updateOrCreateDownloadRequest from '~/redux/reducers/process/updateOrCreateDownloadRequest'
import resetProcessDescriptionSnapshots from '~/redux/reducers/process/resetProcessDescriptionSnapshots'

export default {
  [ACTIONS.SET_INPUT_NODE_VALUE]: setInputNodeValue,
  [ACTIONS.SET_PHASE_PROPS]: setPhaseProps,
  [ACTIONS.DELETE_PHASE]: deletePhase,
  [ACTIONS.ADD_PROCESS_PHASE]: addPhase,
  [ACTIONS.MOVE_PHASE]: movePhase,
  [ACTIONS.SET_PROCESSBUILDER_VALIDATION_ERRORS]: setProcessBuilderValidationErrors,
  [ACTIONS.UPDATE_PROCESS_DESCRIPTION_SNAPSHOT]: updateProcessDescriptionSnapshot,
  [ACTIONS.ADD_HISTORIC_SENSOR_DATA]: addHistoricSensorData,
  [ACTIONS.ADD_LIVE_SENSOR_DATA]: addLiveSensorData,
  [ACTIONS.SET_PROCESS_FCT_IMGS]: setProcessFctImgs,
  [ACTIONS.ADD_PROCESS_FCT_IMGS]: addProcessFctImgs,
  [ACTIONS.SET_SENSOR_DATA_ERROR_FLAG]: setSensorDataErrorFlag,
  [ACTIONS.SET_LOADING_MORE_HISTORIC_SENSOR_DATA_FLAG]: setLoadingMoreHistoricSensorDataFlag,
  [ACTIONS.ADD_PROCESS_USER]: addProcessUser,
  [ACTIONS.MODIFY_PROCESS_ACCESS]: modifyProcessAccess,
  [ACTIONS.REMOVE_PROCESS_USER]: removeProcessUser,
  [ACTIONS.UPDATE_PROCESS]: updateProcess,
  [ACTIONS.ADD_PROCESS_TO_LIST]: addProcessToProcessesList,
  [ACTIONS.SET_PROCESSES]: setProcesses,
  [ACTIONS.DELETE_PROCESSES]: deleteProcesses,
  [ACTIONS.CREATE_PHASE_GROUP]: createPhaseGroup,
  [ACTIONS.ADD_PHASE_WITHIN_GROUP]: addPhaseWithinGroup,
  [ACTIONS.DELETE_PHASE_WITHIN_GROUP]: deletePhaseWithinGroup,
  [ACTIONS.SET_PHASE_GROUP_ITERATIONS_COUNT]: setPhaseGroupIterationsCount,
  [ACTIONS.APPEND_PHASE_TO_GROUP]: appendPhaseToGroup,
  [ACTIONS.PREPEND_PHASE_TO_GROUP]: prependPhaseToGroup,
  [ACTIONS.SET_DOWNLOAD_REQUESTS]: setDownloadRequests,
  [ACTIONS.UPDATE_OR_CREATE_DOWNLOAD_REQUEST]: updateOrCreateDownloadRequest,
  [ACTIONS.RESET_PROCESS_DESCRIPTION_SNAPSHOTS]: resetProcessDescriptionSnapshots,
}
