import React, { useState, createRef } from 'react'
import { Table, Input, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import callUpdateDevice from '~/redux/thunks/device/callUpdateDevice'
import Authorizer from '~/utils/Authorizer'
import FlashMessenger from '~/utils/FlashMessenger'
import './DeviceMetaInformation.css'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (deviceId, params) => callUpdateDevice(dispatch, deviceId, params),
})

const isDeviceNameValid = newDeviceName => (
  newDeviceName.trim() !== ''
)

const submitNewDeviceName = (e, setActiveRow, dispatchCallUpdateDevice, activeDeviceId) => {
  setActiveRow(null)

  if (!isDeviceNameValid(e.target.value)) {
    FlashMessenger.warning('Device name cannot be empty')
    return
  }
  /* TODO: ADD_ERROR_HANDLING */
  dispatchCallUpdateDevice(activeDeviceId, { name: e.target.value })
}

const renderDeviceNameRow = (
  activeDevice,
  activeRow,
  setActiveRow,
  user,
  dispatchCallUpdateDevice,
) => {

  const active = activeRow === 'Name'
  const inputRef = createRef()
  const displayButton = !active && Authorizer.userHasDeviceNameAccess(activeDevice, user)

  return (
    <Table.Row>
      <Table.Cell className='device-name-title'>Name</Table.Cell>
      <Table.Cell
        onClick={() => {
          setActiveRow('Name')
          inputRef.current.focus()
        }}
        className='process-setup-device-name-cell'
      >
        <Input
          data-testid='process-setup-table-device-name-input-field'
          className='process-setup-device-name-input'
          ref={inputRef}
          transparent
          style={{ opacity: 1, flexGrow: 1 }}
          disabled={!Authorizer.userHasDeviceNameAccess(activeDevice, user)}
          defaultValue={activeDevice.name}
          onBlur={e => submitNewDeviceName(
            e,
            setActiveRow,
            dispatchCallUpdateDevice,
            activeDevice.id,
          )}
        />
        { displayButton && (
          <Button
            data-testid='process-setup-table-device-name-edit-button'
            className='device-name-edit-button'
            size='small'
            icon='edit'
            style={{ backgroundColor: 'white' }}
          />
        ) }
      </Table.Cell>
    </Table.Row>
  )
}

const DeviceMetaInformation = props => {
  const { activeDevice, user, dispatchCallUpdateDevice } = props

  const {
    manufacturer,
    manufacturerDeviceType,
    firmwareVersion,
  } = activeDevice

  const [ activeRow, setActiveRow ] = useState(null)

  return (
    <Table celled definition compact className='ospin-red'>
      <Table.Body>
        {renderDeviceNameRow(
          activeDevice,
          activeRow,
          setActiveRow,
          user,
          dispatchCallUpdateDevice,
        )}
        <Table.Row>
          <Table.Cell>Manufacturer</Table.Cell>
          <Table.Cell>{manufacturer.name}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Product</Table.Cell>
          <Table.Cell>{manufacturerDeviceType.name}</Table.Cell>
        </Table.Row>
        {firmwareVersion && (
          <Table.Row>
            <Table.Cell>Firmware</Table.Cell>
            <Table.Cell>
              {firmwareVersion}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceMetaInformation)
