import React from 'react'
import { Divider, Form, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import GraphTools from '~/utils/GraphTools'
import callDeleteCalibration from '~/redux/thunks/device/callDeleteCalibration'
import callSetSensorCalibration from '~/redux/thunks/device/callSetSensorCalibration'
import Calibration from '~/utils/calibration/Calibration'
import CalibrationMode from '~/utils/calibration/CalibrationMode'
import SensorCalibration from '~/utils/sensors/SensorCalibration'
import modalize from '~/components/utility/modal/modalize'
import NoDataMessage from '~/components/utility/NoDataMessage'
import OffsetMenu from './OffsetMenu'
import SlopeMenu from './SlopeMenu'
import OffsetAndSlopeMenu from './OffsetAndSlopeMenu'
import AverageSelectionMenu from './AverageSelectionMenu'
import ExistingCalibrationSection from './ExistingCalibrationSection'
import Footer from './Footer'
import { Process } from '~/utils/process'
import OspinLineChartWrapper from '~/components/processviewer/charts/OspinLineChartWrapper'

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = dispatch => ({
  dispatchCallSetSensorCalibration: (process, fctId, slotName, params) =>
    callSetSensorCalibration(dispatch, process, fctId, slotName, params),
  dispatchCallDeleteCalibration: (fctId, slotName, process) =>
    callDeleteCalibration(dispatch, fctId, slotName, process),
})

class CalibrationModal extends React.Component {

  getClearMessageState = () => ({
    isSuccess: false,
    successMessage: '',
    successMessageHeader: '',
    isError: false,
    errorMessage: '',
  })

  getClearSelectionOffsetRange = () => ({
    startTime: '',
    endTime: '',
    offsetRangeAvrgDisplayed: '',
    offsetRangeAvrgReal: null,
    offsetRangeReference: '',
  })

  getClearSelectionSlopeRange = () => ({
    startTime: '',
    endTime: '',
    slopeRangeAvrgDisplayed: '',
    slopeRangeAvrgReal: null,
    slopeRangeReference: '',
  })

  getClearSelectionAtoPState = () => ({
    firstIntervalStart: '',
    firstIntervalEnd: '',
    secondIntervalStart: '',
    secondIntervalEnd: '',
    firstIntervalAverageDisplayed: '',
    firstIntervalAverageReal: null,
    firstIntervalReference: '',
    secondIntervalAverageDisplayed: '',
    secondIntervalAverageReal: null,
    secondIntervalReference: '',
  })

  getClearInputState = () => ({
    pointSelectionAtoP: { ...this.getClearSelectionAtoPState() },
    pointSelectionOffset: { ...this.getClearSelectionOffsetRange() },
    pointSelectionSlope: { ...this.getClearSelectionSlopeRange() },
    offset: '',
    slope: '',
  })

  constructor(props) {
    super(props)
    this.state = {
      lastPan: null,
      calibrating: false,
      resetting: false,
      submittingSingleParams: { offset: false, slope: false },
      type: CalibrationMode.MODES.DIRECT_INPUT,
      selectedField: 'startTime',
      ...this.getClearInputState(),
      ...this.getClearMessageState(),
    }
    this.calibration = new Calibration(this)
  }

  displayError = errorMessage => (
    this.setState({ ...this.getClearMessageState(), isError: true, errorMessage })
  )

  displaySuccess = successMessageHeader => {
    const { activeProcess } = this.props

    const successMessage = Process.isFinished(activeProcess)
      ? 'The changes will be applied on the next running process'
      : ''

    this.setState({
      ...this.getClearMessageState(),
      isSuccess: true,
      successMessage,
      successMessageHeader,
    })
  }

  resetInputFields = e => {
    e.preventDefault()
    this.setState({ ...this.getClearInputState(), ...this.getClearMessageState() })
  }

  setSelectedField = (e, selectedField) => {
    e.preventDefault()
    this.setState({ selectedField })
  }

  deleteCalibrationEntry = async (e, existingCalibration) => {
    e.preventDefault()
    this.setState({ resetting: true })
    const { dispatchCallDeleteCalibration, activeProcess } = this.props
    const { fctId, slotName } = existingCalibration

    try {
      await dispatchCallDeleteCalibration(fctId, slotName, activeProcess)
      this.displaySuccess('Calibration deleted successfully')
    } catch (e) {
      this.displayError(e.message)
    } finally {
      this.setState({ resetting: false })
    }
  }


  selectPoints(type, e) {
    if (type !== GraphTools.CLICK_HANDLER_OBJECTS.DATAPOINT) { return  }

    const { relativeTimestamp, data } = e

    const res = this.calibration.getUpdatedPointSelection(relativeTimestamp)
    if (!res) return

    const { params: updatedPoints, nextField } = res

    if (!updatedPoints) return

    this.setState({ ...updatedPoints, selectedField: nextField }, () => {
      const { params } = this.calibration.getUpdatedAverage(data)
      this.setState({ ...params, ...this.getClearMessageState })
    })
  }

  handleTypeChange = (event, data) => {
    this.setState({ type: data.value, ...this.getClearMessageState() })
  }

  setOffset = event => {
    this.setState({ offset: event.target.value, ...this.getClearMessageState() })
  }

  setSlope = event => (
    this.setState({ slope: event.target.value, ...this.getClearMessageState() })
  )

  handleRefUpdate = event => (
    this.setState({ [event.target.name]: event.target.value, ...this.getClearMessageState() })
  )

  handleRefUpdateOffset = event => {

    const { pointSelectionOffset } = this.state

    const updatedPointSelectionOffsetRange = {
      ...pointSelectionOffset,
      offsetRangeReference: event.target.value,
    }

    this.setState({
      pointSelectionOffset: updatedPointSelectionOffsetRange,
      ...this.getClearMessageState(),
    })
  }

  handleRefUpdateSlope = event => {

    const { pointSelectionSlope } = this.state

    const updatedPointSelectionSlopeRange = {
      ...pointSelectionSlope,
      slopeRangeReference: event.target.value,
    }

    this.setState({
      pointSelectionSlope: updatedPointSelectionSlopeRange,
      ...this.getClearMessageState(),
    })
  }

  handleRefUpdateAtoP = event => {

    const { pointSelectionAtoP } = this.state

    const updatedPointSelectionAtoP = {
      ...pointSelectionAtoP,
      [event.target.name]: event.target.value,
    }

    this.setState({
      pointSelectionAtoP: updatedPointSelectionAtoP,
      ...this.getClearMessageState(),
    })
  }

  getCalibrationInput = () => {
    const { type } = this.state
    const { activeProcess, slot, functionality } = this.props

    const slotName = slot.name
    const displayedUnit = slot.unit

    const existingCalibration = SensorCalibration
      .getByFctIdAndSlot(activeProcess.calibrations, functionality.id, slotName)

    switch (type) {
      case CalibrationMode.MODES.SELECTION_OFFSET: {
        const { selectedField, pointSelectionOffset } = this.state
        return { displayedUnit, selectedField, pointSelectionOffset }
      }
      case CalibrationMode.MODES.SELECTION_SLOPE: {
        const { selectedField, pointSelectionSlope } = this.state
        return { displayedUnit, selectedField, pointSelectionSlope }
      }
      case CalibrationMode.MODES.DIRECT_INPUT: {
        const { offset, slope } = this.state
        return { offset, slope, displayedUnit, existingCalibration }
      }
      case CalibrationMode.MODES.SELECTION_TWO_POINT: {
        const { pointSelectionAtoP, selectedField } = this.state
        return { pointSelectionAtoP, displayedUnit, selectedField }
      }
      default:
        throw new Error(`Unknown calibration type: ${type}`)
    }
  }

  setLoadingStateOfSingleParamUpdate = (paramName, value) => {
    this.setState(({ submittingSingleParams }) => ({ submittingSingleParams: {
      ...submittingSingleParams,
      [paramName]: value,
    } }))
  }

  handleSubmitDirectMode = async (e, paramName) => {
    e.preventDefault()
    const {
      activeProcess,
      functionality,
      slot,
      dispatchCallSetSensorCalibration,
    } = this.props
    const param = this.state[paramName]

    const parsedParam = parseFloat(param)

    if (paramName === 'slope' && parsedParam === 0) {
      this.displayError('slope may not be 0.')
      return
    }

    this.setLoadingStateOfSingleParamUpdate(paramName, true)

    try {
      await dispatchCallSetSensorCalibration(
        activeProcess,
        functionality.id,
        slot.name,
        ({ [paramName]: { value: parsedParam } }),
      )

      this.displaySuccess('Calibration set successfully.')
    } catch (e) {
      this.displayError(e.message)
    } finally {
      this.setLoadingStateOfSingleParamUpdate(paramName, false)
    }

  }

  handleCalibrationSubmitSelectionModes = async () => {

    const {
      activeProcess,
      slot,
      functionality,
      dispatchCallSetSensorCalibration,
    } = this.props
    const params = this.calibration.getParams()

    if (params.error) {
      this.displayError(params.error)
      return
    }

    this.setState({ calibrating: true })

    try {
      await dispatchCallSetSensorCalibration(
        activeProcess,
        functionality.id,
        slot.name,
        this.calibration.selectParams({ slope: params.slope, offset: params.offset }),
      )

      this.displaySuccess('Calibration set successfully.')
    } catch (e) {
      this.displayError(e.message)
    } finally {
      this.setState({ calibrating: false })
    }
  }

  renderOffsetMenu = menuParams => (
    <OffsetMenu
      menuParams={menuParams}
      setSelectedField={this.setSelectedField}
      handleRefUpdateOffset={this.handleRefUpdateOffset}
    />
  )

  renderSlopeMenu = menuParams => (
    <SlopeMenu
      menuParams={menuParams}
      setSelectedField={this.setSelectedField}
      handleRefUpdateSlope={this.handleRefUpdateSlope}
    />
  )

  renderOffsetAndSlopeMenu = menuParams => {
    const { activeDevice } = this.props
    const { submittingSingleParams } = this.state
    return (
      <OffsetAndSlopeMenu
        menuParams={menuParams}
        setOffset={this.setOffset}
        setSlope={this.setSlope}
        activeDevice={activeDevice}
        submitValue={this.handleSubmitDirectMode}
        submittingSingleParams={submittingSingleParams}
      />
    )
  }

  renderAverageSelectionMenu = menuParams => (
    <AverageSelectionMenu
      handleRefUpdateAtoP={this.handleRefUpdateAtoP}
      menuParams={menuParams}
      setSelectedField={this.setSelectedField}
    />
  )

  static getCalibrationOptions = () => ([
    { key: 1, value: CalibrationMode.MODES.DIRECT_INPUT, text: 'Direct Input: Offset / Slope' },
    { key: 2, value: CalibrationMode.MODES.SELECTION_OFFSET, text: 'From Selection: Only Offset' },
    { key: 3, value: CalibrationMode.MODES.SELECTION_SLOPE, text: 'From Selection: Only Slope' },
    { key: 4, value: CalibrationMode.MODES.SELECTION_TWO_POINT, text: 'From Selection: Two-Point Calibration' },
  ])

  userCanApply = calibration => {
    const { type } = this.state
    const { activeDevice, user } = this.props

    if (type === CalibrationMode.MODES.SELECTION_OFFSET) {
      return SensorCalibration.userCanUpdateParameter(user, activeDevice, calibration, 'offset')
    }

    if (type === CalibrationMode.MODES.SELECTION_SLOPE) {
      return SensorCalibration.userCanUpdateParameter(user, activeDevice, calibration, 'slope')
    }

    if (type === CalibrationMode.MODES.DIRECT_INPUT) {
      return SensorCalibration.userCanUpdateParameter(user, activeDevice, calibration, 'offset')
        || SensorCalibration.userCanUpdateParameter(user, activeDevice, calibration, 'slope')
    }

    if (type === CalibrationMode.MODES.SELECTION_TWO_POINT) {
      return SensorCalibration.userCanUpdateParameter(user, activeDevice, calibration, 'offset')
        && SensorCalibration.userCanUpdateParameter(user, activeDevice, calibration, 'slope')
    }
  }

  blockedMenuMessage = () => {
    const baseMessage = 'This mode is not available for you because you'
      + ' are either not authorized to calibrate or a device admin has locked'

    const { type } = this.state

    switch (type) {
      case CalibrationMode.MODES.SELECTION_OFFSET: {
        return `${baseMessage} the calibration offset parameter`
      }
      case CalibrationMode.MODES.SELECTION_SLOPE: {
        return `${baseMessage} the calibration slope parameter`
      }
      case CalibrationMode.MODES.DIRECT_INPUT: {
        return `${baseMessage} the calibration offset and slope parameters`
      }
      case CalibrationMode.MODES.SELECTION_TWO_POINT: {
        return `${baseMessage} the calibration offset and/or slope parameters`
      }
      default:
        return `${baseMessage} the required calibration parameters`
    }
  }

  renderMenu(params, userCanApplyChanges) {
    const { type } = this.state

    if (!userCanApplyChanges) {
      return <NoDataMessage text={this.blockedMenuMessage()} />
    }

    switch (type) {
      case CalibrationMode.MODES.SELECTION_OFFSET: {
        return this.renderOffsetMenu(params)
      }
      case CalibrationMode.MODES.SELECTION_SLOPE: {
        return this.renderSlopeMenu(params)
      }
      case CalibrationMode.MODES.DIRECT_INPUT: {
        return this.renderOffsetAndSlopeMenu(params)
      }
      case CalibrationMode.MODES.SELECTION_TWO_POINT: {
        return this.renderAverageSelectionMenu(params)
      }
      default:
        return null
    }
  }

  render() {

    const {
      calibrating,
      errorMessage,
      isError,
      isSuccess,
      resetting,
      successMessage,
      successMessageHeader,
      type,
    } = this.state

    const {
      activeProcess,
      activeDevice,
      functionality,
      slot,
      closeHandler,
      reporterFctId,
    } = this.props

    const slotName = slot.name

    const allCalibrations = SensorCalibration
      .getAllByFctIdAndSlot(activeProcess.calibrations, functionality.id, slotName)
    const existingCalibration = allCalibrations.find(calib => SensorCalibration.isActive(calib))
    const userCanApplyChanges = this.userCanApply(existingCalibration)

    return (
      <Form
        error={isError}
        success={isSuccess}
        onSubmit={this.handleCalibrationSubmitSelectionModes}
        style={{ marginBottom: '15px', paddingBottom: '15px' }}
      >
        <Form.Select
          selection
          fluid
          label='Type'
          options={CalibrationModal.getCalibrationOptions()}
          value={type}
          onChange={this.handleTypeChange}
        />
        <div>
          <OspinLineChartWrapper
              activeProcess={activeProcess}
              reporterData={[{ reporterFctId, slot, fct: functionality, lineColor: GraphTools.DEFAULT_COLOR }]}
              activeDevice={activeDevice}
              clickHandlerOverwrite={this.selectPoints.bind(this)}
              lineChartFormat='calibration'
              showDatapoints={true}

          />
          <ExistingCalibrationSection
            existingCalibration={existingCalibration}
            activeDevice={activeDevice}
            activeProcess={activeProcess}
            slot={slot}
            functionality={functionality}
          />
          <Divider />
          {this.renderMenu(this.getCalibrationInput(), userCanApplyChanges)}
        </div>
        <Divider />
        <Message
          error
          header='Error.'
          content={errorMessage}
        />
        <Message
          success
          header={successMessageHeader}
          content={successMessage}
        />
        <Footer
          activeDevice={activeDevice}
          calibrating={calibrating}
          resetting={resetting}
          resetInputFields={this.resetInputFields}
          deleteCalibrationEntry={this.deleteCalibrationEntry}
          closeHandler={closeHandler}
          existingCalibration={existingCalibration}
          type={type}
          userCanApplyChanges={userCanApplyChanges}
        />
      </Form>
    )
  }
}

export default modalize(connect(mapStateToProps, mapDispatchToProps)(CalibrationModal))
