import nexus from '@ospin/nexus'
import React, { useState, useEffect } from 'react'
import { Container, Segment, Item, Header, Divider, Message } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Notification from '~/utils/user/Notification'
import { updateUser } from '~/redux/actions/actions'
import Invitation from './Invitation'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchUpdateUser: userData => dispatch(updateUser(userData)) })

const renderNoInvitiationsMessage = () => (
  <Message warning>
    <Message.Header>You have no Messages!</Message.Header>
    <p>Messages people send you will appear here.</p>
  </Message>
)

const renderNotifications = (
  isError,
  errorMsg,
  noInvitations,
  invitations,
  isLoading,
  setIsLoading,
) => {

  if (isError) return <Message error header='Something went wrong!' content={errorMsg} />

  if (noInvitations) return renderNoInvitiationsMessage()

  return (
    <Item.Group divided>
      {invitations.map(invitation => (
        <Invitation
          inv={invitation}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          key={invitation.id}
        />
      ))}
    </Item.Group>
  )
}

const Notifications = props => {
  const { user, dispatchUpdateUser } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [ errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data: { notifications } } = await nexus.user.get(user.id)

        dispatchUpdateUser({ notifications })
      } catch (e) {
        setIsError(true)
        setErrorMsg(`An error occurred while retrieving messages: ${e.message}`)
      }
    }

    fetchMessages()
  }, [user.id, dispatchUpdateUser])

  const invitations = Notification.getInvitationNotifications(user.notifications)
  const noInvitations = invitations.length === 0

  return (
    <Container fluid text>
      <Segment>
        <Header as='h2'>Messages</Header>
        <Divider />
        {renderNotifications(
          isError,
          errorMsg,
          noInvitations,
          invitations,
          isLoading,
          setIsLoading,
        )}
      </Segment>
    </Container>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))
