import nexus from '@ospin/nexus'
import React from 'react'
import modalize from '~/components/utility/modal/modalize'
import StopProcessModalForm from '~/components/processviewer/modals/StopProcessModalForm'

class StopProcessModal extends React.Component {

  state = {
    isError: false,
    errorMessage: '',
    pending: false,
  }

  stopProcess = async () => {
    const { activeProcess, closeHandler } = this.props
    const { deviceId, id } = activeProcess

    this.setState({ pending: true })
    try {
      await nexus.command.device.process.end(deviceId, id)
      this.setState({ isError: false, errorMessage: '', pending: false }, () => closeHandler())
    } catch (e) {
      this.setState({ isError: true, errorMessage: e.message, pending: false })
    }
  }

  render() {
    const { isError, errorMessage, pending } = this.state
    const { closeHandler } = this.props

    return (
      <StopProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.stopProcess}
        submitText='Confirm'
        loading={pending}
      />
    )
  }
}

export default modalize(StopProcessModal)
