import React from 'react'
import { Message, Icon, Popup } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Event from '~/utils/Event'

const renderDeviceName = (deviceName, deviceId) => (
  deviceName ? (
    <Message.Item
      style={{ display: 'inline-block', marginTop: '7px', marginRight: '8px' }}
      as={Link}
      to={`/devices/${deviceId}`}
    >
      {deviceName}
    </Message.Item>
  )
    : <Message.Item style={{ display: 'inline-block', marginTop: '7px', minHeight: '1em' }} />
)

const Alert = ({ alert, deleteAlert, isDeletable }) => (
  <Message icon style={{ paddingTop: '25px' }}>
    <Popup
      content='You are not authorized to delete this alert'
      disabled={isDeletable}
      position='bottom right'
      trigger={(
        <Icon
          data-testid='alert-delete-button'
          name='close'
          disabled={!isDeletable}
          size='large'
          onClick={() => deleteAlert(alert.id)}
        />
      )}
    />
    <Icon name='warning sign' color='red' style={{ position: 'absolute', top: '30px' }} />
    <i className='icon' style={{ width: '42px' }} />
    <Message.Content>
      <Message.Header>
        { Event.deriveDisplayName(alert) }
      </Message.Header>
      <div>
        {renderDeviceName(alert.deviceName, alert.deviceId)}
      </div>
      <span style={{ marginTop: '10px', float: 'left' }}>
        <span>
          {alert.displayMsg}
        </span>
      </span>
      <span style={{
        float: 'right',
        textAlign: 'right',
        marginTop: '10px',
        marginLeft: '10px',
      }}
      >
        <span style={{ fontWeight: 'bold', display: 'block' }}>{moment(alert.createdAt).format('HH:mm:ss')}</span>
        <span style={{ display: 'block' }}>{moment(alert.createdAt).format('DD/MM/YYYY')}</span>
      </span>
    </Message.Content>
  </Message>
)

export default Alert
