import { FCTGraph } from '@ospin/fct-graph'
import { deepClone } from '~/utils/immutability'
import DescriptionParser from '~/utils/process/DescriptionParser'
import PhaseProgression from '~/utils/process/PhaseProgression'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'
import Phase from '~/utils/process/Phase'
import Validator from '~/utils/validation/Validator'

export const EXECUTED_PROCESS_STATES = {
  running: 'running',
  finished: 'finished',
  paused: 'paused',
}

export const PROCESS_STATES = {
  ...EXECUTED_PROCESS_STATES,
  executable: 'executable',
}

export function phaseIsModifiable(process, phaseId) {
  if (Process.isFinished(process)) return false
  if (Process.isExecutable(process)) return true

  const lastExecutedPhase = PhaseProgression.getLatest(process)

  if (!lastExecutedPhase) return false
  const lastExecutedPhaseId = lastExecutedPhase.phaseId

  if (phaseId === lastExecutedPhaseId) {
    return true
  }

  return !PhaseProgression.contains(process, phaseId)
}

export function getFirstPhaseId(process) {
  return process.entryPhaseId
}

export function setPhaseProps(process, phaseId, props) {
  process.description[phaseId] = {
    ...process.description[phaseId],
    ...props,
  }
}

function updatePhaseProps(process, phaseIds, props) {
  phaseIds.forEach(aPhaseId => setPhaseProps(process, aPhaseId, props))
}

export function setInputNodeValue(process, targetPhaseId, inputNodeFctId, target) {
  process.description[targetPhaseId].inputNodeValues[inputNodeFctId].value = target
}

const updatePhaseTargets = (process, phaseIds, inputNodeFctId, target) => (
  phaseIds.forEach(aPhaseId => {
    setInputNodeValue(process, aPhaseId, inputNodeFctId, target)
  })
)

const updateGroup = (process, phaseId, updateFn, params, isTargetUpdate = false) => {

  const phase = DescriptionParser.getPhaseById(process, phaseId)
  const { groupName } = phase

  const iterationData = PhaseGroupParser.getIterationData(process, groupName)
  const { phaseIds, iteration: targetIteration } = iterationData
    .find(({ phaseIds }) => phaseIds.includes(phaseId))
  const modifiedPhasePosition = phaseIds.findIndex(aPhaseId => aPhaseId === phaseId)

  const idsOfPhasesToUpdate = iterationData
    .filter(({ iteration }) => iteration >= targetIteration)
    .map(({ phaseIds }) => phaseIds[modifiedPhasePosition])

  updateFn(process, idsOfPhasesToUpdate, ...params)

  /*
  * a small explantion:
  * we need to update the group structure in two cases: Any modification coming after
  * the first iteration of the groups or when a input node value is modifed for a
  * running phase in the first iteration because it requires a split of the
  * running phase. Thats the condition in line 95. Then we decide between two cases:
  * 1. When we are not modifying a running phase or when we do but we don't modify
  * any input node values, we don't need to create a bridge group (condition line 97).
  * If we are modyfing the first iteration under this conditions, we actually don't need
  * to do anything (the modified phase never finished before so we don't need to
  * perserve its history) - line 98.
  * The offset is a bit janky and serves which phases are chosen to be extracted
  * into a future group. Sometimes we want to include the running iteration
  * (e.g. when the modified phase is the running one), sometimes we don't need that.
  * 2. Code from line 111 is a bit easier and should be self-explanatory.
  */

  const runningPhase = PhaseProgression.getLatest(process)
  const isRunningPhase = runningPhase.phaseId === phaseId

  if (targetIteration > 1 || (isRunningPhase && isTargetUpdate)) {

    if (!isRunningPhase || (isRunningPhase && !isTargetUpdate)) {
      if (targetIteration <= 1) return

      const { iteration: runningIteration } = iterationData
        .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

      const isFutureIteration = runningIteration < targetIteration
      const isSameIteration = runningIteration === targetIteration
      const isAtLeastSecondIteration = runningIteration > 1

      const offset = isFutureIteration || (isSameIteration && isAtLeastSecondIteration)
        ? 1
        : isRunningPhase ? 1 : 0

      PhaseGroupHandler.createRemainderGroup(process, iterationData, targetIteration - offset, groupName)
      return
    }

    if (isRunningPhase) {
      const offset = isTargetUpdate ? 0 : 1
      if (isTargetUpdate) {
        PhaseGroupHandler.createBridgeGroup(process, iterationData, targetIteration, groupName)
      }
      PhaseGroupHandler.createRemainderGroup(process, iterationData, targetIteration - offset, groupName)
    }
  }
}

const updateGroupTargets = (process, phaseId, inputNodeFctId, newTarget) => {
  updateGroup(process, phaseId, updatePhaseTargets, [ inputNodeFctId, newTarget ], true)
}

const updateGroupPhaseProps = (process, phaseId, props) => {
  updateGroup(process, phaseId, updatePhaseProps, [ props ])
}

export function setPhasePropsWithGroupConsideration(process, phaseId, props) {
  const isPureMetaDataChange = ('name' in props || 'description' in props) && Object.keys(props).length === 1
  const phase = DescriptionParser.getPhaseById(process, phaseId)

  if (Process.isExecutable(process) || isPureMetaDataChange) {
    const originalPhaseId = phase.iterationOf !== null ? phase.iterationOf : phaseId
    const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(process, originalPhaseId)
    updatePhaseProps(process, [ ...phaseIterationIds, originalPhaseId ], props)
    return
  }

  if (Process.isFinished(process)) {
    return
  }

  if (!phaseIsModifiable(process, phaseId)) {
    return
  }

  if (!phase.groupName) {
    updatePhaseProps(process, [ phaseId ], props)
    return
  }

  updateGroupPhaseProps(process, phaseId, props)
}

export function setInputNodeValueWithGroupConsideration(process, phaseId, inputNodeFctId, newTarget) {

  if (Process.isFinished(process)) {
    return
  }

  const phase = DescriptionParser.getPhaseById(process, phaseId)

  if (Process.isExecutable(process)) {
    const originalPhaseId = phase.iterationOf !== null ? phase.iterationOf : phaseId
    const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(process, originalPhaseId)
    updatePhaseTargets(process, [ ...phaseIterationIds, originalPhaseId ], inputNodeFctId, newTarget)
    return
  }

  // process is either running or paused
  // only phases that didn't finish yet or have queued iterations will allow modifications
  // because the inputs are disabled otherwise

  if (!phaseIsModifiable(process, phaseId)) {
    return
  }

  if (!phase.groupName) {
    updatePhaseTargets(process, [ phaseId ], inputNodeFctId, newTarget)
    return
  }

  updateGroupTargets(process, phaseId, inputNodeFctId, newTarget)
}

export function amendPhasesNextProps(process, deletedPhaseId, deletedPhaseNextKey) {
  const amendedProcess = { ...process }
  const prevPhaseId = DescriptionParser.getPreviousPhaseId(amendedProcess, deletedPhaseId)
  if (prevPhaseId !== undefined) {
    amendedProcess.description[prevPhaseId].next = deletedPhaseNextKey
  }
  return amendedProcess
}

export class Process {

  static hasProcess = (processes, processId) => (
    processes.some(process => process.id === processId)
  )

  static getById = (processes, processId) => (
    processes.find(process => process.id === processId)
  )

  static getState = process => process.state

  static isValidPhaseOrderChange = (process, srcPhaseId, destPhaseId, destination) => {
    if (Process.isExecutable(process)) return true
    if (Process.isFinished(process)) return false

    if (PhaseProgression.contains(process, srcPhaseId)) return false
    if (PhaseProgression.contains(process, destPhaseId)) return false

    // forbid dragging a phase before a running group
    // because future iterations of the group dont block dropping a
    // phsae before them

    if (destPhaseId !== -1) {
      const phase = DescriptionParser.getPhaseById(process, destPhaseId)
      const runningGroup = Process.getRunningGroup(process)
      if (!runningGroup) return true

      const { groupName } = phase

      if (!groupName || groupName !== runningGroup) return true

      const iterationData = PhaseGroupParser.getIterationData(process, groupName)
      const iteration = iterationData.find(({ phaseIds }) => phaseIds.includes(destPhaseId))
      const index = iteration.phaseIds.findIndex(aPhaseId => aPhaseId === destPhaseId)

      if (index === 0 && destination.droppableId === 'process-tree') return false
    }

    return true
  }

  static isValidPhaseProgression = (process, newProgression) => {
    const expectedOrderPhaseIds = DescriptionParser.getPhaseIds(process)

    return newProgression.every(({ phaseId }, idx) => (
      expectedOrderPhaseIds[idx] === phaseId
    ))
  }

  static hasBeenStarted(process) {
    return (!(Number.isNaN(process.startedAt) || typeof process.startedAt !== 'number'))
  }

  static hasFinishedAt(process) {
    return (!(Number.isNaN(process.finishedAt) || typeof process.finishedAt !== 'number'))
  }

  static isRunningOnDevice(process, device) {
    return device.runningProcesses.some(runningProcess => runningProcess.id === process.id)
  }

  static isFinished(process) {
    return process.state === PROCESS_STATES.finished
  }

  static isPaused(process) {
    return process.state === PROCESS_STATES.paused
  }

  static isRunningOrPaused(process) {
    return process.state === PROCESS_STATES.paused || process.state === PROCESS_STATES.running
  }

  static isExecutable(process) {
    return process.state === PROCESS_STATES.executable
  }

  static isRunning(process) {
    return process.state === PROCESS_STATES.running
  }

  static isEnriched(process) {
    return process && process.enriched === true
  }

  static markProcessAsOutdated = process => {
    Object.assign(process, { outdated: true })
  }

  static async save(process, saveFn) {
    const updatedProcess = {
      description: process.description,
      entryPhaseId: process.entryPhaseId,
    }
    /* TODO: ADD_ERROR_HANDLING */
    await saveFn(process.id, updatedProcess)
  }

  static getLatestPhaseProgressionEntry(process) {
    if (Process.isFinished(process)) {
      return PhaseProgression.noPhaseObject()
    }
    return PhaseProgression.getLatest(process) || PhaseProgression.noPhaseObject()
  }

  static getRunningPhaseDetails(process) {
    const { phaseId } = this.getLatestPhaseProgressionEntry(process)
    if (phaseId === -1 || phaseId === undefined) return null

    return { ...DescriptionParser.getPhaseById(process, phaseId), id: phaseId }
  }

  static getRunningGroup(process) {
    if (Process.isFinished(process)) return
    const { phaseId } = PhaseProgression.getLatest(process) || PhaseProgression.noPhaseObject()

    if (phaseId !== -1) {
      const { groupName } = DescriptionParser.getPhaseById(process, phaseId)
      return groupName
    }
  }

  static sortPinnedFirst(processes) {
    const samePinnedState = (a, b) => (a.pinned === b.pinned)
    const byCreatedAt = (a, b) => (b.createdAt - a.createdAt)
    const isPinned = a => (a.pinned ? -1 : 1)

    return processes.sort((a, b) => {
      if (samePinnedState(a, b)) {
        return byCreatedAt(a, b)
      }
      return isPinned(a)
    })
  }

  static getUniquePhaseId(process, snapshot) {
    let phaseId = 0
    const allPhaseIds = snapshot && snapshot.description
      ? [ ...Object.keys(process.description), ...Object.keys(snapshot.description) ]
      : Object.keys(process.description)

    while (allPhaseIds.includes(String(phaseId))) {
      phaseId += 1
    }
    return phaseId
  }

  static getNextUnusedPhaseName(phases) {
    const numberOfIterationPhases = phases
      .filter(({ iterationOf }) => !Validator.isUndefinedOrNull(iterationOf)).length
    let phaseNameCounter = phases.length - numberOfIterationPhases + 1
    let phaseName = `Phase ${phaseNameCounter}`
    const phaseNames = phases.map(phase => phase.name)
    while (phaseNames.includes(phaseName)) {
      phaseNameCounter += 1
      phaseName = `Phase ${phaseNameCounter}`
    }
    return phaseName
  }

  static getPreviousPhaseInputNodeValues(process, lastPhaseId) {
    const values = process.description[lastPhaseId].inputNodeValues
    return deepClone(values)
  }

  static getDefaultPhaseInputNodeValues(process) {
    const { fctGraph } = process
    const inputNodeFcts = FCTGraph.getPushInFcts(fctGraph)
    const inputNodeValues = {}

    inputNodeFcts.forEach(inputNodeFct => {
      const sinkFct = FCTGraph.getSinkFct(fctGraph, inputNodeFct.id)
      const slot = FCTGraph.getConnectingSinkSlot(fctGraph, inputNodeFct.id)

      const defaultInputValue = FunctionalityConfiguration
        .getSlotDefaultInputValue(process.fctsConfigs, sinkFct.id, slot.name)

      inputNodeValues[inputNodeFct.id] = !Validator.isUndefinedOrNull(defaultInputValue)
        ? { value: defaultInputValue }
        : { value: slot.defaultValue }
    })

    return inputNodeValues
  }

  static createPhase(process, copyFromPhaseId = undefined, snapshot) {
    const phases = DescriptionParser.getPhasesArray(process)

    const phaseData = {
      name: Process.getNextUnusedPhaseName(phases),
      description: '',
      transition: Phase.TRANSITIONS.MANUAL,
      duration: 0,
      next: -1,
      groupName: null,
      iterationOf: null,
    }

    const prevPhaseId = copyFromPhaseId === undefined
      ? DescriptionParser.getLastPhaseId(process)
      : copyFromPhaseId

    phaseData.inputNodeValues = phases.length > 0
      ? Process.getPreviousPhaseInputNodeValues(process, prevPhaseId)
      : Process.getDefaultPhaseInputNodeValues(process)

    if (copyFromPhaseId !== undefined) {
      const { transition, duration } = DescriptionParser.getPhaseById(process, copyFromPhaseId)
      phaseData.transition = transition
      phaseData.duration = duration
    }

    const phaseId = Process.getUniquePhaseId(process, snapshot)
    return { phaseId, phaseData }
  }

  static attachPhase(process, newPhase, attachAfterPhaseId = undefined) {
    const { phaseId, phaseData } = newPhase
    const prevPhaseId = DescriptionParser.getLastPhaseId(process)
    const phases = DescriptionParser.getPhasesArray(process)

    if (phases.length > 0) {
      process.description[prevPhaseId].next = phaseId
    }

    process.description[phaseId] = phaseData

    if (attachAfterPhaseId !== undefined) {
      const destinationIndex = DescriptionParser
        .getPhaseIds(process).findIndex(aPhaseId => aPhaseId === attachAfterPhaseId)
      Process.movePhase(process, phases.length, destinationIndex + 1)
    }

    return process
  }

  static appendNewPhase(process, phaseIdToCopy, snapshot) {
    const phase = Process.createPhase(process, phaseIdToCopy, snapshot)
    return Process.attachPhase(process, phase)
  }

  static movePhase(process, sourceIndex, destinationIndex) {

    const moveInvalid = (srcIdx, destIdx) => (
      srcIdx === destIdx || srcIdx === undefined || destIdx === undefined
    )

    if (moveInvalid(sourceIndex, destinationIndex)) return process

    const phaseSequence = DescriptionParser.getPhasesSequence(process)

    if (destinationIndex === 0) {
      Process.setEntryPhaseId(process, phaseSequence[sourceIndex].id)
    } else if (sourceIndex === 0) {
      Process.setEntryPhaseId(process, phaseSequence[sourceIndex + 1].id)
    }

    const removedPhase = phaseSequence.splice(sourceIndex, 1)
    phaseSequence.splice(destinationIndex, 0, removedPhase[0])

    phaseSequence.forEach((phase, i) => {
      const nextPhaseId = i < phaseSequence.length - 1 ? phaseSequence[i + 1].id : -1
      process.description[phase.id] = { ...phase.data, next: nextPhaseId }
    })
  }

  static deletePhase(process, phaseId) {
    const deletedPhaseNextKey = process.description[phaseId].next
    delete process.description[phaseId]

    if (phaseId === getFirstPhaseId(process)) {
      process.entryPhaseId = deletedPhaseNextKey
    }

    const updatedProcess = amendPhasesNextProps(process, phaseId, deletedPhaseNextKey)

    return { updatedProcess, deletedPhaseNextKey }
  }

  static setEntryPhaseId(process, phaseId) {
    process.entryPhaseId = phaseId
  }

  static getPhaseRunTimeInSeconds(process, phaseId) {
    if (!PhaseProgression.phaseHasStarted(process, phaseId)) return 0
    const { startTime } = PhaseProgression.getPhaseById(process, phaseId)

    // if its the last phase, compare against process finish time or current time if still running
    if (PhaseProgression.isLatestPhase(process, phaseId)) {
      return Process.hasFinishedAt(process)
        ? Math.round((process.finishedAt - startTime) / 1000)
        : PhaseProgression.getSecondsSincePhaseStart(process, phaseId)
    }

    // if its not the last phase, derive from the following phases start time
    const { startTime: endTime } = PhaseProgression.getFollowingPhaseProgression(process, phaseId)

    return Math.round((endTime - startTime) / 1000)
  }

  static hasManuallyTransitionedPhases(process, startPhaseId) {
    const phaseId = startPhaseId !== undefined ? startPhaseId : process.entryPhaseId
    const phases = DescriptionParser.getPhasesSequence(process)
    const startIndex = phases.findIndex(({ id }) => id === phaseId)

    return phases
      .filter((_, idx) => idx >= startIndex)
      .some(({ data: { transition } }) => transition === Phase.TRANSITIONS.MANUAL)
  }

  static calculateTotalFixedDurationForPhases(process, phaseIds) {
    let durationInMS = 0

    phaseIds.forEach(aPhaseId => {
      const phaseData = DescriptionParser.getPhaseById(process, aPhaseId)
      if (Phase.getTransitionMethod(phaseData) === Phase.TRANSITIONS.TIME_BASED) {
        durationInMS += Phase.getDuration(phaseData) * 1000
      }
    })

    return durationInMS
  }

  static getRemainingFixedProcessDurationInMS(process) {
    if (Process.isFinished(process)) return 0

    const allPhaseIds = DescriptionParser.getPhaseIds(process)
    if (Process.isExecutable(process)) {
      return Process.calculateTotalFixedDurationForPhases(process, allPhaseIds)
    }

    const startedPhasesIds = process.progression.map(({ phaseId }) => phaseId)
    const futurePhaseIds = allPhaseIds.filter(aPhaseId => !startedPhasesIds.includes(aPhaseId))

    const futurePhasesDuration = Process
      .calculateTotalFixedDurationForPhases(process, futurePhaseIds)
    const runningPhase = Process.getRunningPhaseDetails(process)

    if (Phase.isManuallyTransitioned(runningPhase)) {
      return futurePhasesDuration
    }

    const remainingTimeInRunningPhase = Phase
      .getRemainingMSDurationWithinProcess(runningPhase, process)

    return futurePhasesDuration + remainingTimeInRunningPhase
  }

  static getTotalFixedProcessDuration(process) {
    const allPhaseIds = DescriptionParser.getPhaseIds(process)
    return Process.calculateTotalFixedDurationForPhases(process, allPhaseIds)
  }

  static isInLastPhase(process) {
    return PhaseProgression.getLatest(process)?.phaseId
      === DescriptionParser.getLastPhaseId(process)
  }

  static getElapsedProcessTimeInMs(process) {
    if (Process.isExecutable(process)) {
      return 0
    }
    if (Process.isFinished(process)) {
      return process.finishedAt - process.startedAt
    }
    return Date.now() - process.startedAt
  }

  static getPhaseBoundaries(process, phaseId) {
    const start = PhaseProgression.getSecondsSinceProcessStart(process, phaseId)
    const end = PhaseProgression.getSecondsSinceProcessStart(process, DescriptionParser.getNextPhaseId(process, phaseId))

    return { start, end }
  }
}
