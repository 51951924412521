import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import { FCTGraph } from '@ospin/fct-graph'

class GatewayDisplayedFcts {

  static get TAB_VIEW() {
    return 'configurations'
  }

  static get WIDGET_VIEW() {
    return 'widget'
  }

  static get CONSTANTS() {
    return {
      multiFctView: {
        title: 'Solutions',
        messageString: 'solution',
        uiConfigKey: 'multiFctView',
      },
      singleFctView: {
        title: 'Individual Devices',
        messageString: 'device',
        uiConfigKey: 'singleFctView',
      },
    }
  }

  static getFilteredPhysicalFctsGraphs(fctGraphs) {
    const fctGraphsWithMultiplePhysicalFcts = fctGraphs.filter(fctGraph => (
      FCTGraph.getPhysicalFcts(fctGraph).length > 1
    ))

    const fctGraphsWithSinglePhysicalFct = fctGraphs.filter(fctGraph => (
      FCTGraph.getPhysicalFcts(fctGraph).length === 1
    ))

    return { fctGraphsWithMultiplePhysicalFcts, fctGraphsWithSinglePhysicalFct }
  }

  static sortByNameAndOnline(graphs, device) {
    return graphs.sort((a, b) => {
      const isPossible = graph => FunctionalityGraph.deviceCanExecuteGraph(graph, device)
      if (isPossible(a) && !isPossible(b)) return -1
      if (!isPossible(a) && isPossible(b)) return 1
      return a.name.localeCompare(b.name)
    })
  }

  static getDisplayedFctGraphs = (fctGraphs, device, viewOffline) => (
    GatewayDisplayedFcts.sortByNameAndOnline(fctGraphs, device)
      .filter(fctGraph => {
        if (viewOffline) return true
        return FunctionalityGraph.deviceCanExecuteGraph(fctGraph, device)
      })
  )

  static getOrderedElements = elements => {
    const keys = Object.keys(elements)
    keys.sort((a, b) => elements[a].index - elements[b].index)
    return keys
  }

  static getViewType(deviceIdMatchParams) {
    return deviceIdMatchParams !== undefined
      ? GatewayDisplayedFcts.TAB_VIEW : GatewayDisplayedFcts.WIDGET_VIEW
  }
}

export default GatewayDisplayedFcts
