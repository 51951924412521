import React from 'react'
import { Form, Select, Container, Message } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import ActuatorValue from '~/utils/UIConfig/widgets/ActuatorValue'
import QueryValue from '~/utils/UIConfig/widgets/QueryValue'
import LineChart from '~/utils/UIConfig/widgets/LineChart'
import LineChartOptionRow from './LineChartOptionRow'
import DashboardWidgetFCTAndSlotSelection from './DashboardWidgetFCTAndSlotSelection'

const widgetTypes = [
  {
    key: QueryValue.DISPLAY_NAME,
    text: QueryValue.DISPLAY_NAME,
    value: QueryValue.TYPE,
  },
  {
    key: ActuatorValue.DISPLAY_NAME,
    text: ActuatorValue.DISPLAY_NAME,
    value: ActuatorValue.TYPE,
  },
  {
    key: LineChart.DISPLAY_NAME,
    text: LineChart.DISPLAY_NAME,
    value: LineChart.TYPE,
  },
]

const renderSelectionFields = (
  selectedWidgetType,
  fctGraph,
  fctOptions,
  selectedFctId,
  setSelectedFctId,
  selectedSlotName,
  setSelectedSlotName,
  slotOptions,
  dataSources,
  addDataSource,
  removeDataSource,
  selectedLineColor,
  setSelectedLineColor,
  updateDataSource,
) => {
  if (!selectedWidgetType) return null

  const showDataSourceSelection = GraphDashboard.showDataSourceSelection(selectedWidgetType)

  return (
    <Container>
      <DashboardWidgetFCTAndSlotSelection
        slotOptions={slotOptions}
        fctOptions={fctOptions}
        selectedFctId={selectedFctId}
        setSelectedFctId={setSelectedFctId}
        selectedSlotName={selectedSlotName}
        setSelectedSlotName={setSelectedSlotName}
        showAddDataSource={showDataSourceSelection}
        addDataSource={addDataSource}
        selectedWidgetType={selectedWidgetType}
        dataSources={dataSources}
        selectedLineColor={selectedLineColor}
        setSelectedLineColor={setSelectedLineColor}
      />
      { showDataSourceSelection
        ? (
          <LineChartOptionRow
            dataSources={dataSources}
            allowDeletingLastDataSource
            removeDataSource={idx => removeDataSource(idx)}
            fctGraph={fctGraph}
            addDataSource={addDataSource}
            updateDataSource={updateDataSource}
            selectedLineColor={selectedLineColor}
            setSelectedLineColor={setSelectedLineColor}
          />
        ) : null }
    </Container>
  )
}

const renderWidgetDescription = selectedWidgetType => {
  if (!selectedWidgetType) return null

  const widgetDescription = GraphDashboard.getWidgetDescription(selectedWidgetType)

  return (
    <Message info size='small'>
      <p>{ widgetDescription }</p>
    </Message>
  )
}

const DashboardWidgetModalForm = props => {
  const {
    fctOptions,
    fctGraph,
    selectedFctId,
    setSelectedFctId,
    selectedSlotName,
    setSelectedSlotName,
    slotOptions,
    selectedWidgetType,
    setSelectedWidgetType,
    dataSources,
    addDataSource,
    removeDataSource,
    updateDataSource,
    selectedLineColor,
    setSelectedLineColor,
  } = props

  return (
    <div>
      <Form.Field
        control={Select}
        label='Widget type'
        placeholder='Choose widget type'
        options={widgetTypes}
        onChange={(_, { value }) => {
          setSelectedWidgetType(value)
        }}
      />
      {renderWidgetDescription(selectedWidgetType)}
      {renderSelectionFields(
        selectedWidgetType,
        fctGraph,
        fctOptions,
        selectedFctId,
        setSelectedFctId,
        selectedSlotName,
        setSelectedSlotName,
        slotOptions,
        dataSources,
        addDataSource,
        removeDataSource,
        selectedLineColor,
        setSelectedLineColor,
        updateDataSource,
      )}
    </div>
  )
}

export default formalize(DashboardWidgetModalForm)
