import IconButton from '~/components/utility/button/IconButton'
import { FCTGraph, Slot } from '@ospin/fct-graph'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Message, Table } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import SlotConfig from '~/utils/functionalities/slots/SlotConfig'
import DeviceAuthorizer from '~/utils/DeviceAuthorizer'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'

import NoDataMessage from '~/components/utility/NoDataMessage'

import SetDataPrecisionModal from '../modals/SetDataPrecisionModal'
import DeletePopup from './DeletePopup'

const mapStateToProps = state => ({ user: state.user })

async function deleteDisplayedDecimalPlaces({
  fctId,
  slotName,
  activeDevice,
  fctsConfigs,
  setFctsConfigs,
  setIsLoading,
  fctGraph,
}) {

  const updates = [{
    fctId,
    slotsConfigs: [{ name: slotName, displayedDecimalPlaces: null }],
  }]
  setIsLoading(true)

  try {
    const { data: updatedFctsConfigs } = await nexus.device.functionalityGraph
      .functionalityConfiguration.updateMany({
        deviceId: activeDevice.id,
        fctGraphId: fctGraph.id,
        updates,
      })
    const mergedFctConfigs = FunctionalityConfiguration.mergeMany(fctsConfigs, updatedFctsConfigs)
    setFctsConfigs(mergedFctConfigs)
  } catch (_) {
    setIsLoading(false)
    /* TODO: ADD_ERROR_HANDLING */
  }
}

const getTableHeaders = [
  'Functionality',
  'Channel',
  'Data Precision',
  '',
]

const validSlotForDisplayedDecimalSetting = slot => slot.dataType === 'float' && slot.type === 'OutSlot'

const filterFctsWithPossibleDisplayedDecimal = fcts => {
  const filteredFcts = fcts.filter(
    fct => fct.slots.some(slot => validSlotForDisplayedDecimalSetting(slot)),
  )

  return filteredFcts
}

const getDisplayedDecimalValueForSlotOrNull = ({ fctId, slotname, fctsConfigs }) => {

  const slotConfig = FunctionalityConfiguration.getSlotConfig(fctsConfigs, fctId, slotname)
  return Number.isInteger(SlotConfig.getDisplayedDecimalPlaces(slotConfig))
    ? SlotConfig.getDisplayedDecimalPlaces(slotConfig)
    : null
}

const slotHasActiveDisplayedDecimalSetting = (
  { fct, slot, fctsConfigs },
) => !(getDisplayedDecimalValueForSlotOrNull(
  { fctId: fct.id, slotname: slot.name, fctsConfigs },
) === null)

const renderFctAndSlotsData = ({
  fct,
  activeDevice,
  user,
  fctsConfigs,
  setFctsConfigs,
  isLoading,
  setIsLoading,
  fctGraph,
}) => {
  const { name, slots } = fct
  const renderedSlots = slots.filter(
    slot => slotHasActiveDisplayedDecimalSetting({ fct, slot, fctsConfigs }),
  )

  return renderedSlots.map((slot, idx) => (
    <Table.Row key={fct.id + slot.name}>
      {idx === 0 && <Table.Cell rowSpan={renderedSlots.length} content={name} />}
      <Table.Cell content={Slot.getDisplayName(slot)} />
      <Table.Cell content={getDisplayedDecimalValueForSlotOrNull(
        { fctId: fct.id, slotname: slot.name, fctsConfigs },
      )}
      />
      <Table.Cell>
        <DeletePopup
          confirmHandler={() => deleteDisplayedDecimalPlaces({
            slotName: slot.name,
            fctId: fct.id,
            activeDevice,
            setFctsConfigs,
            fctsConfigs,
            setIsLoading,
            fctGraph,
          })}
          loading={isLoading}
          disabled={(!DeviceAuthorizer.isAbleToUpdateDisplayedDecimals(activeDevice, user)
            || isLoading
          )}
          iconName='close'
          position='right center'
        />
      </Table.Cell>
    </Table.Row>
  ))
}

function DataPrecisionTable({
  selectedGraph,
  fctsConfigs,
  setFctsConfigs,
  activeDevice,
  user,
}) {

  const [displayedDecimalModal, setDisplayedDecimalModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const toggleDisplayedDecimalModal = () => { setDisplayedDecimalModal(!displayedDecimalModal) }

  const filteredFcts = filterFctsWithPossibleDisplayedDecimal(FCTGraph
    .getFctsWithoutIONodes(selectedGraph))

  const fctsWithSetDisplayedDecimalPlaces = filteredFcts.filter(fct => fct.slots.some(
    slot => slotHasActiveDisplayedDecimalSetting({ fct, slot, fctsConfigs }),
  ))

  const filteredFctMap = filteredFcts.reduce((ac, fct) => (
    {
      ...ac,
      [fct.id]: fct.slots
        .filter(slot => validSlotForDisplayedDecimalSetting(slot))
        .map(slot => slot.name),
    }), {})

  return (
    <div>
      {filteredFcts.length
        ? (
          <IconButton
            text='Set Data Precision'
            clickHandler={() => setDisplayedDecimalModal(true)}
            disabled={!DeviceAuthorizer.isAbleToUpdateDisplayedDecimals(activeDevice, user)}
          />
        )
        : <Message content='The device has no channels that support this setting' />}

      {fctsWithSetDisplayedDecimalPlaces.length > 0
        ? (
          <Table className='ospin-red' celled structured>
            <Table.Header>
              <Table.Row>
                {getTableHeaders.map(text => (
                  <Table.HeaderCell key={text} content={text} />
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {fctsWithSetDisplayedDecimalPlaces
                .map(fct => renderFctAndSlotsData({
                  fct,
                  activeDevice,
                  user,
                  fctsConfigs,
                  setFctsConfigs,
                  isLoading,
                  setIsLoading,
                  fctGraph: selectedGraph,
                }))}
            </Table.Body>
          </Table>
        ) : (
          <>
            <br />
            <br />
            <NoDataMessage text='No data precision settings found' />
          </>
        )}
      <SetDataPrecisionModal
        size='small'
        headerText='Set Data Precision'
        closeHandler={toggleDisplayedDecimalModal}
        activeDevice={activeDevice}
        selectedGraph={selectedGraph}
        open={displayedDecimalModal}
        filteredFcts={filteredFcts}
        filteredFctMap={filteredFctMap}
        fctsConfigs={fctsConfigs}
        setFctsConfigs={setFctsConfigs}
        getDisplayedDecimalValueForSlotOrNull={getDisplayedDecimalValueForSlotOrNull}
        validSlotForDisplayedDecimalSetting={validSlotForDisplayedDecimalSetting}
      />
    </div>
  )
}
export default connect(mapStateToProps)(DataPrecisionTable)
