import React from 'react'
import { Button, Icon, Popup, Grid } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import './ProcessesQueryBar.css'

import ProcessesSortMenu from './ProcessesSortMenu'

const renderViewModeSelection = (viewMode, updateQueryParameters) => (
  <Button.Group className='processes-query-bar-button-group'>
    <Button
      primary
      inverted={viewMode === 'table'}
      icon
      onClick={() => updateQueryParameters([{ key: 'viewMode', value: 'tiles' }])}
      data-testid='btn-process-view-mode-tiles'
      className='margin-l-16 icon-view-button'
    >
      <Icon name='th' />
    </Button>
    <Button
      primary
      inverted={viewMode === 'tiles'}
      icon
      onClick={() => updateQueryParameters([{ key: 'viewMode', value: 'table' }])}
      data-testid='btn-process-view-mode-table'
      className='margin-r-16 icon-view-button'
    >
      <Icon name='list' />
    </Button>
  </Button.Group>
)

const getCreateButtonState = (hasPermissioningRights, activeDevice) => {
  if (!hasPermissioningRights) {
    return {
      disabled: true,
      reason: `Must be Admin or Operator on ${activeDevice.name} to create a process`,
    }
  }

  if (activeDevice.fctGraphs.length === 0) {
    return {
      disabled: true,
      reason: 'No devices or setups found',
    }
  }

  return { disabled: false, reason: null }
}

const ProcessesQueryBar = ({
  viewMode,
  activeDevice,
  hasPermissioningRights,
  toggleModal,
  toggleProcessSearchMenu,
  showProcessSearchMenu,
  disableCloseSearchMenu,
  location,
  history,
  updateQueryParameters,
}) => {

  const { disabled, reason } = getCreateButtonState(hasPermissioningRights, activeDevice)

  return (
    <Grid
      stackable
      columns='equal'
    >
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={3} floated='left'>
          <Popup
            content={reason}
            position='top center'
            disabled={!disabled}
            trigger={(
              <span>
                <Button
                  primary
                  disabled={disabled}
                  onClick={() => toggleModal('showCreateProcessModal')}
                  icon='plus'
                  content='Create Process'
                />
              </span>
            )}
          />
        </Grid.Column>

        <Grid.Column
          textAlign='right'
          width={13}
          className='processes-query-bar-right'
        >
          <ProcessesSortMenu
            location={location}
            history={history}
            updateQueryParameters={updateQueryParameters}
          />
          <Popup
            content='clear the current query to close the search bar'
            disabled={!disableCloseSearchMenu}
            trigger={(
              <span>
                <Button
                  disabled={disableCloseSearchMenu}
                  icon={showProcessSearchMenu ? 'angle up' : 'angle down'}
                  content='Filter'
                  primary
                  inverted
                  onClick={toggleProcessSearchMenu}
                  style={{ marginTop: '-5px', padding: '12px 21px' }}
                />
              </span>
            )}
          />
          {renderViewModeSelection(viewMode, updateQueryParameters)}

        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default withRouter(ProcessesQueryBar)
