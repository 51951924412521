import nexus from '@ospin/nexus'
import { modifyDeviceAccess } from '~/redux/actions/actions'

const callModifyDeviceAccess = async (dispatch, deviceId, userId, newGroup, oldGroup) => {
  await nexus.device.access.modify({ deviceId, params: { userId, newGroup, oldGroup } })
  dispatch(modifyDeviceAccess({
    userId,
    newGroup,
    oldGroup,
    deviceId,
  }))
}

export default callModifyDeviceAccess
