import React from 'react'
import { Ref } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'

const Div = props => (<div {...props} />)

const DraggableComponent = ({
  children,
  draggableId,
  index,
  style,
  As = Div,
  isDragDisabled = false,
  ...AsProps
}) => (
  <Draggable
    draggableId={draggableId}
    index={index}
    key={draggableId}
    isDragDisabled={isDragDisabled}
  >
    { provided => (
      <Ref innerRef={provided.innerRef}>
        <As
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...AsProps}
        >
          <div style={style}>
            {children}
            {provided.placeholder}
          </div>
        </As>
      </Ref>
    )}
  </Draggable>
)
export default DraggableComponent
