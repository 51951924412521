import React from 'react'
import { Ref } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

let tabRoot = document.getElementById('tab-root')

if (!tabRoot) {
  tabRoot = document.createElement('div')
  tabRoot.setAttribute('id', 'tab-root')
  document.body.appendChild(tabRoot)
}

const Div = props => (<div {...props} />)

class DraggablePortal extends React.Component {

  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    tabRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    tabRoot.removeChild(this.el)
  }

  isTabMoving = (styles, element) => {
    if (styles.position === 'fixed') {
      return createPortal(
        element,
        this.el,
      )
    }
    return element
  }

  render() {
    const {
      children,
      draggableId,
      index,
      style,
      As = Div,
      isDragDisabled = false,
      ...AsProps
    } = this.props

    return (
      <Draggable
        draggableId={draggableId}
        index={index}
        key={draggableId}
        isDragDisabled={isDragDisabled}
      >
        { provided => (
          this.isTabMoving(provided.draggableProps.style, (
            <Ref innerRef={provided.innerRef}>
              <As
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                {...AsProps}
              >
                <div style={style}>
                  {children}
                  {provided.placeholder}
                </div>
              </As>
            </Ref>
          ))
        )}
      </Draggable>
    )
  }
}
export default DraggablePortal
