import React, { useState, useEffect } from 'react'
import { Slot } from '@ospin/fct-graph'
import { Form, TextArea } from 'semantic-ui-react'

/// Any Slots are used text inputs for internal development
export default function AnyInSlot({ slot, inputNodeValue, updateInputNodeValue, inputNodeFctId }) {
  const [takingInput, setTakingInput] = useState(false)
  const [currentInput, setCurrentInput ] = useState(inputNodeValue)

  function updateInput(value) {
    setTakingInput(true)
    setCurrentInput(value)
  }

  async function save() {
    if (inputNodeValue === currentInput) { return }
    updateInputNodeValue(inputNodeFctId, currentInput)
    setTakingInput(false)
    setCurrentInput('')
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => { save() }, [])

  return (
    <div style={{ fontWeight: 'bold' }}>
      {Slot.getDisplayName(slot)}
      <Form.Group widths={1}>
        <Form.Field width={16}>
          <TextArea
            onChange={(_, { value }) => updateInput(value)}
            onBlur={() => save()}
            value={takingInput ? currentInput : inputNodeValue}
          />
        </Form.Field>
      </Form.Group>
    </div>
  )
}
