import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Icon, Button } from 'semantic-ui-react'
import { addProcessPhase, addPhaseWithinGroup } from '~/redux/actions/actions'
import { Process } from '~/utils/process'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import DescriptionParser from '~/utils/process/DescriptionParser'
import { ellipseString } from '~/utils/string'
import Authorizer from '~/utils/Authorizer'

const mapStateToProps = state => ({
  displayedPhaseId: state.displayedPhaseId,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchAddProcessPhase: (processId, deviceId, phaseIdToCopy) => dispatch(
    addProcessPhase({ processId, deviceId, phaseIdToCopy })),
  dispatchAddPhaseWithinGroup: (processId, groupName, phaseIdToCopy) => dispatch(
    addPhaseWithinGroup({ processId, groupName, phaseIdToCopy })),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
})

const AddPhaseButton = ({
  dispatchAddProcessPhase,
  dispatchAddPhaseWithinGroup,
  dispatchCallUpdateProcess,
  displayedPhaseId,
  groupName,
  activeProcess,
  deviceId,
  displayedPhaseName,
  scrollToBottom,
  user,
}) => {

  const [loading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)

    if (groupName) {
      dispatchAddPhaseWithinGroup(activeProcess.id, groupName, displayedPhaseId)
    } else {
      dispatchAddProcessPhase(activeProcess.id, deviceId, displayedPhaseId)
      if (scrollToBottom) scrollToBottom()
    }

    if (Process.isExecutable(activeProcess)) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }

    setIsLoading(false)
  }

  return (
    <Button
      primary
      id='add-phase-button'
      data-testid={groupName ? 'phase-is-grouped' : 'phase-is-ungrouped'}
      loading={loading}
      disabled={loading || Authorizer.isResourceViewer(activeProcess, user.id)}
      onClick={() => handleClick(displayedPhaseId)}
    >
      <Icon name='plus' />
      {`Clone ${ellipseString(displayedPhaseName, 8)}`}
    </Button>
  )
}

const PhaseAddition = ({
  dispatchAddProcessPhase,
  dispatchAddPhaseWithinGroup,
  dispatchCallUpdateProcess,
  displayedPhaseId,
  groupName = null,
  activeProcess,
  deviceId,
  scrollToBottom,
  user,
}) => {

  const phaseDetails = DescriptionParser.getPhaseById(activeProcess, displayedPhaseId)
  const displayPhaseName = phaseDetails ? phaseDetails.name : ''

  if (Process.isFinished(activeProcess)) return null

  return (
    <AddPhaseButton
      groupName={groupName}
      dispatchCallUpdateProcess={dispatchCallUpdateProcess}
      dispatchAddProcessPhase={dispatchAddProcessPhase}
      dispatchAddPhaseWithinGroup={dispatchAddPhaseWithinGroup}
      displayedPhaseId={displayedPhaseId}
      scrollToBottom={scrollToBottom}
      activeProcess={activeProcess}
      deviceId={deviceId}
      displayedPhaseName={displayPhaseName}
      user={user}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseAddition)
