import OffsetOnlyMode from '~/utils/calibration/OffsetOnlyMode'
import SlopeOnlyMode from '~/utils/calibration/SlopeOnlyMode'
import LinearCalibrationDirectMode from '~/utils/calibration/LinearCalibrationDirectMode'
import LinearCalibrationSelectionMode from '~/utils/calibration/LinearCalibrationSelectionMode'
import CalibrationMode from '~/utils/calibration/CalibrationMode'

class Calibration {

  constructor(component) {
    this.component = component
  }

  get mode() {
    const { type } = this.component.state

    switch (type) {
      case CalibrationMode.MODES.SELECTION_OFFSET: {
        return OffsetOnlyMode
      }
      case CalibrationMode.MODES.SELECTION_SLOPE: {
        return SlopeOnlyMode
      }
      case CalibrationMode.MODES.DIRECT_INPUT: {
        return LinearCalibrationDirectMode
      }
      case CalibrationMode.MODES.SELECTION_TWO_POINT: {
        return LinearCalibrationSelectionMode
      }
      default:
        throw new Error(`Unknown calibration type: ${type}`)
    }
  }

  getParams = () => (
    this.mode.calculateParams(this.component.state, this.component.props)
  )

  selectParams = params => (
    this.mode.selectParams(params)
  )

  getUpdatedPointSelection = time => (
    this.mode.getUpdatedPointSelection(this.component.state, time)
  )

  getUpdatedAverage = rawData => {
    const { mode, component } = this
    const { state, props } = component
    return mode.getUpdatedAverage(state, props, rawData)
  }

  attachGraphSlice = sensorData => {
    const { state, props } = this.component
    return this.mode.attachGraphSlice(sensorData, state, props)
  }
}

export default Calibration
