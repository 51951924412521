import { setProcessFctImgs } from '~/redux/actions/actions'
import nexus from '@ospin/nexus'

const callLoadProcessFunctionalityImages = async (dispatch, processId, fctId) => {
  const { data } = await nexus.process.functionality.image.list(processId, fctId)
  dispatch(setProcessFctImgs(
    {
      processId,
      fctId,
      images: data.images,
    },
  ))
}

export default callLoadProcessFunctionalityImages
