import React from 'react'
import { Ports } from '@ospin/fct-graph'
import { Select, Table, TableCell, Icon } from 'semantic-ui-react'
import './PhysicalMappingsSelectionRow.css'

const PORT_INDICATOR_STATUS = {
  connected: { iconName: 'checkmark', color: 'green' },
  requiresAction: { iconName: 'warning sign', color: 'yellow' },
  unavailable: { iconName: 'x', color: 'red' },
}

const portIsDoubleAssigned = (fct, selectedGraph, mapping) => {
  const matchingConnFct = selectedGraph.functionalities.filter(({subType}) => subType == fct.subType)
  return matchingConnFct.map((({id}) => id)).every(fctId => {
    const currentPort = mapping[fctId]
    return Object.values(mapping).filter(mappingPort => Ports.areEqual(mappingPort, currentPort)).length !== 1
  })
}

const subtypeIsUnavailable = (fct,selectedGraph, activeDevice) => {

  const numberOfConnected = (subType) => { return activeDevice.connectedPhysicalFunctionalities.filter(fct => fct.subType === subType).length }
  const numberOfRequired = (subType) => { return selectedGraph.functionalities.filter(fct => fct.subType === subType).length }

  return numberOfConnected(fct.subType) < numberOfRequired(fct.subType)

}

const portHasMoved = (fct,activeDevice, mapping, ) => {
  const currentPort = mapping[fct.id]
  const isAvailableOnOtherPort = activeDevice.connectedPhysicalFunctionalities.filter(connFct => {
    return connFct.subType === fct.subType && !Ports.areEqual(connFct.ports, currentPort).length > 0
  })

  const isAvailableOnOriginalPort = activeDevice.connectedPhysicalFunctionalities.some(connFct => {
    return Ports.areEqual(connFct.ports, currentPort) && connFct.subType === fct.subType
  })

  return !isAvailableOnOriginalPort && isAvailableOnOtherPort
}

function getPortIndicatorStatus(fct, activeDevice, selectedGraph, newPhysicalMappingsValues) {

  if (subtypeIsUnavailable(fct,selectedGraph, activeDevice)) {
    return PORT_INDICATOR_STATUS.unavailable
  }

  if (portIsDoubleAssigned(fct,selectedGraph, newPhysicalMappingsValues)) {
    return PORT_INDICATOR_STATUS.requiresAction
  }
  if (portHasMoved(fct,activeDevice, newPhysicalMappingsValues)) {
    return PORT_INDICATOR_STATUS.requiresAction
  }

  return PORT_INDICATOR_STATUS.connected
}

function getPortOptions(fct, activeDevice, currentPort) {
  const { subType } = fct
  const { connectedPhysicalFunctionalities } = activeDevice

  const portOptions = connectedPhysicalFunctionalities
    .filter(connectedFct => connectedFct.subType === subType)
    .map(connectedFct => connectedFct.ports)

  /* the current port may not be possible anymore, so we have to
  /* make sure it is added */
  const currentMappingIncluded = portOptions
    .some(ports => Ports.areEqual(ports, currentPort))

  if (!currentMappingIncluded) {
    portOptions.push(currentPort)
  }

  return portOptions.map(ports => {
    const portId = Ports.getId(ports)
    return {
      key: portId,
      value: JSON.stringify(ports),
      text: portId,
    }
  })
}

export default function PhysicalMappingsSelectionRow({
  fct,
  disableDropdown,
  selectedGraph,
  activeDevice,
  newPhysicalMappingsValues,
  setNewPhysicalMappingsValues,
}) {

  const options = getPortOptions(fct, activeDevice, newPhysicalMappingsValues[fct.id])
  const inputShouldBeDisabled = disableDropdown || options.length === 1 || subtypeIsUnavailable(fct,selectedGraph, activeDevice)

  const { iconName, color } = getPortIndicatorStatus(
    fct,
    activeDevice,
    selectedGraph,
    newPhysicalMappingsValues,
  )

  const handleDropdownChange = (updatedFct, value) => {
    setNewPhysicalMappingsValues({
      ...newPhysicalMappingsValues,
      /* value is an array, but we have to use it as a string
      /* for the dropdown value, so we have to parse it back */
      [updatedFct.id]: JSON.parse(value),
    })
  }
  return (
    <Table.Row>
      <TableCell content={fct.name} />
      <TableCell className='selected-port-table-cell'>
      <Select
        fluid
        disabled={inputShouldBeDisabled}
        options={options}
        defaultValue={JSON.stringify(newPhysicalMappingsValues[fct.id])}
        onChange={(_, { value }) => handleDropdownChange(fct, value)}
      />
      <Icon
        name={iconName}
        color={color}
        className='selected-port-icon-status'
      />
    </TableCell>
    </Table.Row>
  )
}
