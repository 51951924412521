import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Divider, Button, Message } from 'semantic-ui-react'
import Authorizer from '~/utils/Authorizer'
import callTransferDeviceOwnership from '~/redux/thunks/device/callTransferDeviceOwnership'
import FlashMessenger from '~/utils/FlashMessenger'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallTransferDeviceOwnership: (deviceId, newOwnerId) =>
    callTransferDeviceOwnership(dispatch, deviceId, newOwnerId),
})

const TransferOwnershipModal = ({
  user,
  activeDevice,
  open,
  closeHandler,
  deviceUsers,
  dispatchCallTransferDeviceOwnership,
}) => {

  const defaultState = {
    isError: false,
    isLoading: false,
    errorMessage: '',
  }

  const [ newOwnerId, setNewOwner ] = useState(null)

  const [ requestState, setRequestState ] = useState(defaultState)

  const close = () => {
    closeHandler()
  }

  const handleUserChange = (event, data) => {
    setNewOwner(data.value)
  }

  const submitNewOwner = async () => {
    setRequestState({ ...defaultState, isLoading: true })

    try {
      await dispatchCallTransferDeviceOwnership(activeDevice.id, newOwnerId)
      setNewOwner(null)
      close()
      setRequestState({
        ...defaultState, isLoading: false, isError: false, errorMessage: '',
      })
      FlashMessenger.success('Device ownership transferred successfully')
    } catch (e) {
      setRequestState({ ...defaultState, isError: true, errorMessage: e.message })
    }
  }

  const { isError, errorMessage, isLoading } = requestState

  const userOptions = deviceUsers
    .filter(deviceUser => deviceUser.id !== user.id)
    .map(deviceUser => ({ value: deviceUser.id, key: deviceUser.id, text: deviceUser.userName }))

  const isCurrUserAnApplicationAdmin = Authorizer.hasApplicationAdminAccess(user)

  /* keeping 'Myself as Admin' out of the list if the application admin becomes the device owner */
  if (isCurrUserAnApplicationAdmin && activeDevice.ownerId !== user.id) {
    userOptions.push({ value: user.id, key: user.id, text: 'Myself as Admin' })
  }

  return (
    <Modal
      size='tiny'
      open={open}
      onClose={close}
      onClick={event => event.stopPropagation()}
    >
      <Modal.Header>
        Transfer ownership
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message
            warning
            header='Are you sure?'
            content={(
              <p>
                Transfering the ownership will remove your ownership
                privileges and grant them to the new device owner. The
                new device owner will be able to control all user
                access to the device, including yours!
              </p>
            )}
            icon='warning'
          />
          <Form
            error={isError}
            onSubmit={submitNewOwner}
            style={{ marginBottom: '15px', paddingBottom: '15px' }}
          >
            <Form.Select
              fluid
              label='New Owner'
              options={userOptions}
              onChange={handleUserChange}
              placeholder='Choose new owner'
              value={newOwnerId}
            />
            <Message
              error
              header='Something went wrong.'
              content={errorMessage}
              icon='warning circle'
            />
            <Divider />
            <Button
              floated='right'
              primary
              type='submit'
              loading={isLoading}
            >
              Submit
            </Button>
            <Button
              floated='right'
              onClick={close}
            >
              Close
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnershipModal)
