import { DevicePusherChannel } from '@ospin/pusher'
import { createDeviceObject } from '~/redux/helper/objectMapper'
import deviceChannelHandler from '~/redux/pusher/deviceChannel/deviceChannelHandler'
import { isAlreadySubscribedToChannel } from '~/utils/pusher'

export default (state, action) => {

  const { devices: currentStateDevices } = state
  const newDevices = action.payload

  currentStateDevices.forEach(device => {
    if (isAlreadySubscribedToChannel(DevicePusherChannel, { deviceId: device.id })) {
      DevicePusherChannel.unsubscribe({ deviceId: device.id })
    }
  })

  const devices = []
  newDevices.forEach(deviceData => {
    const device = createDeviceObject(deviceData)
    devices.push(device)
    if (!isAlreadySubscribedToChannel(DevicePusherChannel, { deviceId: deviceData.id })) {
      DevicePusherChannel.subscribe(
        { deviceId: deviceData.id },
        deviceChannelHandler(deviceData.id),
      )
    }
  })

  return {
    ...state,
    devices,
    activeDeviceInitialized: false,
  }
}
