import React from 'react'
import formalize from '~/components/utility/modal/formalize'
import { Message } from 'semantic-ui-react'

const DELETE_DASHBOARD_MODAL_HEADER_MSG = 'This action is irreversible'

const DeleteDashboardConfirmationModalForm = () => {

  const deleteMsg = 'Are you sure you want to delete the dashboard?'

  return (
    <div>
      <Message
        header={DELETE_DASHBOARD_MODAL_HEADER_MSG}
        content='The dashboard will be deleted unrecoverably'
        icon='warning'
        visible
        negative
      />
      <p>
        {deleteMsg}
      </p>
    </div>
  )
}

export default formalize(DeleteDashboardConfirmationModalForm)
