// IF YOU FIND YOURSELF EXTENDING THIS:
// interface got named after the first use case -
// this may be better off working with or wholly abstracted in to regex utils with nothing re: a url
export default class UrlValidator {

  static UUIDV4_REGEX = /[a-f0-9]{8}(-[a-f0-9]{4}){3}-[a-f0-9]{12}/

  static isUuidv4(id) {
    return UrlValidator.UUIDV4_REGEX.test(id)
  }
}
