import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import SensorCalibration from '~/utils/sensors/SensorCalibration'

const mapStateToProps = ({ user }) => ({ user })

const isNonEmptyValue = value => (
  value.trim() !== ''
)

const OffsetAndSlopeMenu = ({
  menuParams,
  setOffset,
  setSlope,
  activeDevice,
  user,
  submitValue,
  submittingSingleParams,
}) => {

  const {
    displayedUnit,
    offset,
    slope,
    existingCalibration,
  } = menuParams

  const getInputParams = (param, value, calib) => {
    if (!SensorCalibration.userCanUpdateParameter(user, activeDevice, calib, param)) {
      return { disabled: true, value: calib ? calib.params[param].value : '' }
    }

    return { value, disabled: false }
  }

  const { value: offsetValue, disabled: offsetDisabled } = getInputParams('offset', offset, existingCalibration)
  const { value: slopeValue, disabled: slopeDisabled } = getInputParams('slope', slope, existingCalibration)

  return (
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        label={`Offset [${displayedUnit}] (default 0)`}
        placeholder='offset..'
        type='number'
        value={offsetValue}
        onChange={setOffset}
        readOnly={offsetDisabled}
        action={
          (
            <Button
              primary
              onClick={e => submitValue(e, 'offset')}
              disabled={submittingSingleParams.offset
                || offsetDisabled
                || !isNonEmptyValue(offsetValue)}
              loading={submittingSingleParams.offset}
              data-testid='submit-offset-btn-id'
            >
              Apply
            </Button>
          )
        }
      />
      <Form.Input
        fluid
        label='Slope (default 1)'
        placeholder='slope..'
        type='number'
        value={slopeValue}
        onChange={setSlope}
        readOnly={slopeDisabled}
        action={
          (
            <Button
              primary
              onClick={e => submitValue(e, 'slope')}
              disabled={submittingSingleParams.slope
                || slopeDisabled
                || !isNonEmptyValue(slopeValue)}
              loading={submittingSingleParams.slope}
              data-testid='submit-slope-btn-id'
            >
              Apply
            </Button>
          )
        }
      />
    </Form.Group>
  )
}

export default connect(mapStateToProps)(OffsetAndSlopeMenu)
