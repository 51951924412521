import React from 'react'
import { Table } from 'semantic-ui-react'
import NoDataMessage from '~/components/utility/NoDataMessage'
import DeviceInvitation from './DeviceInvitation'

const DeviceInvitationsTable = props => {
  const {
    activeDevice,
    pendingDeviceInvitations,
    removePendingInvitation,
    user,
  } = props

  if (!pendingDeviceInvitations.length) {
    return (
      <NoDataMessage text='No pending invitations found' />
    )
  }

  return (
    <div>
      <Table
        className='ospin-red'
        celled
        selectable
        padded
      >
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Group</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            pendingDeviceInvitations.map(invitation => (
              <DeviceInvitation
                key={invitation.id}
                invitation={invitation}
                removePendingInvitation={removePendingInvitation}
                activeDevice={activeDevice}
                user={user}
              />
            ))
          }
        </Table.Body>
      </Table>
    </div>
  )
}

export default DeviceInvitationsTable
