import store from '~/redux/store'
import nexus from '@ospin/nexus'
import { updateDevice } from '~/redux/actions/actions'
import FlashMessenger from '~/utils/FlashMessenger'
import { ellipseString } from '~/utils/string'

function getRelevantKeys(device) {
  const {
    fctGraphs,
    fctGraphTemplates,
    connectedPhysicalFunctionalities,
    supportedVirtualFunctionalities,
  } = device

  return {
    fctGraphs,
    fctGraphTemplates,
    connectedPhysicalFunctionalities,
    supportedVirtualFunctionalities,
  }
}

async function loadDevice(deviceId) {
  const { user, devices } = store.getState()
  const targetDevice = devices.find(({ id }) => id === deviceId)

  if (!targetDevice) {
    return
  }

  const { data: device } = await nexus.user.device.get({ userId: user.id, deviceId })

  if (JSON.stringify(getRelevantKeys(targetDevice)) === JSON.stringify(getRelevantKeys(device))) {
    return
  }

  FlashMessenger.dispatch(FlashMessenger.createMessage(
    'success',
    `Device "${ellipseString(device.name, 25)}" has updated its functionalities`,
  ))

  const params = getRelevantKeys(device)

  store.dispatch(updateDevice({
    deviceId,
    params,
  }))
}

export default function updateDeviceFunctionalities(deviceId) {
  loadDevice(deviceId)
}
