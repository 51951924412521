import { Process } from '~/utils/process'
import { ellipseString } from '~/utils/string'
import FlashMessenger from '~/utils/FlashMessenger'

export default (state, action) => {
  const { processId, image, reporterFctId } = action.payload.data
  const { flashMessages, processes } = state
  const process = Process.getById(processes, processId)
  // The safety checks here are necessary to avoid sending images to users
  // who do not have the proces in state (e.g. 2 users working on 1 device)

  if (!process) {
    return state
  }

  const existingProcessFctImages = state.processFctsImgs[processId]
    ? state.processFctsImgs[processId][reporterFctId]
    : []

  const processFctsImgs = {
    ...state.processFctsImgs,
    [processId]: {
      ...state.processFctsImgs[processId],
      [reporterFctId]: [image, ...existingProcessFctImages],
    },
  }

  const msg = `${ellipseString(process.name, 25)} has recieved a new image`
  const flashMsg = FlashMessenger.createMessage('success', msg)
  const updatedFlashMessages = [ ...flashMessages, flashMsg ]

  return { ...state, processFctsImgs, flashMessages: updatedFlashMessages }
}
