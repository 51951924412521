import { findAndUpdate } from '~/redux/helper/updateFunctions'
import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'

export default (state, action) => {
  const { processes } = state
  const { processId, phaseId, groupName } = action.payload

  const updateFn = process => PhaseGroupHandler.prependPhaseToGroup(process, groupName, phaseId)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
