import FeatureVersioning from '~/utils/FeatureVersioning'
import { FCTGraph } from '@ospin/fct-graph'

/* This file contains helper functions for stream-based devices
 * (featureVersion 1)

/* these filter functions help us to catch the case for where the
 * slots of a functionality can disappear over time and leave
 * configs/calibrations behind
 *
 * We cannot delete the configs/calibration on the backend, as customers
 * expect that when they detach a sensor and re-attach it,
 * configs and calibrations are perserved.
 */

export const filterOutRemovedSlotsFromConfigs = (device, configs) => {
  if (FeatureVersioning.supportsMultipleProcesses(device)) return configs

  const [ fctGraph ] = device.fctGraphs

  return configs.map(config => {
    const fct = FCTGraph.getFctById(fctGraph, config.fctId)
    return {
      ...config,
      slotsConfigs: config.slotsConfigs.filter(slotConfig => {
        const { name } = slotConfig
        return fct.slots.some(slot => slot.name === name)
      }),
    }
  })

}

export const filterOutRemovedSlotsFromCalibrations = (device, calibs) => {
  if (FeatureVersioning.supportsMultipleProcesses(device)) return calibs

  const [ fctGraph ] = device.fctGraphs

  return calibs.filter(calibration => {
    const fct = FCTGraph.getFctById(fctGraph, calibration.fctId)
    return fct.slots.some(slot => slot.name === calibration.slotName)
  })
}
