import React, { useState } from 'react'
import { Divider, Button, List } from 'semantic-ui-react'
import AuthViewContainer from '../AuthViewContainer'
import RequestResetCode from './RequestResetCode'
import ResetPasswordForm from './ResetPasswordForm'
import RedirectionLink from '../signUp/RedirectionLink'

const WORKFLOWS = {
  requestCode: 'requestCode',
  requestReset: 'requestReset',
}

export default function ResetPassword() {
  const [ workflow, setWorkflow ] = useState(WORKFLOWS.requestCode)

  function renderContent() {
    if (workflow === WORKFLOWS.requestCode) {
      return <RequestResetCode />
    }
    return <ResetPasswordForm />

  }
  return (
    <AuthViewContainer>
      <Button.Group fluid>
        <Button
          primary={workflow === WORKFLOWS.requestCode}
          onClick={() => setWorkflow(WORKFLOWS.requestCode)}
          content='Request Reset'
        />
        <Button.Or />
        <Button
          onClick={() => setWorkflow(WORKFLOWS.requestReset)}
          primary={workflow === WORKFLOWS.requestReset}
          content='Submit Code'
        />
      </Button.Group>
      <Divider horizontal hidden />

      {renderContent()}
      <List>
        <List.Item content={<RedirectionLink text='Back To Login' route='login' />} />
      </List>
    </AuthViewContainer>
  )
}
