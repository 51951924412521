import React from 'react'
import { FCTGraph } from '@ospin/fct-graph'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'
import Validator from '~/utils/validation/Validator'
import IconButton from '~/components/utility/button/IconButton'
import TableHeader from '~/components/utility/table/TableHeader'
import NoDataMessage from '~/components/utility/NoDataMessage'
import SetSlotDefaultModal from '../modals/SetSlotDefaultModal'
import SlotsDefaultTableRow from './SlotsDefaultTableRow'

const mapStateToProps = state => ({ user: state.user })

class SlotDefaultsTable extends React.Component {

  state = { showSetSlotDefaultModal: false }

  toggleSetSlotDefaultModal = () => {
    this.setState(prevState => ({ showSetSlotDefaultModal: !prevState.showSetSlotDefaultModal }))
  }

  renderFunctionality = (fct, fctConfig) => {
    const {
      activeDevice,
      user,
      fctsConfigs,
      setFctsConfigs,
      selectedGraph,
    } = this.props

    const slotsConfigsWithDefaultInputValues = fctConfig.slotsConfigs
      .filter(slotConfig => !Validator.isUndefinedOrNull(slotConfig.defaultInputValue))

    return slotsConfigsWithDefaultInputValues.map((slotConfig, idx) => (
      <SlotsDefaultTableRow
        key={`${fct.id}_${slotConfig.name}`}
        fct={fct}
        activeDevice={activeDevice}
        setFctsConfigs={setFctsConfigs}
        fctsConfigs={fctsConfigs}
        user={user}
        slotConfig={slotConfig}
        slotsPerFct={slotsConfigsWithDefaultInputValues.length}
        index={idx}
        fctGraph={selectedGraph}
      />
    ))
  }

  render() {
    const { activeDevice, selectedGraph, fctsConfigs, setFctsConfigs } = this.props

    const { showSetSlotDefaultModal } = this.state

    const fctsConfigsWithCustomDefault = FunctionalityConfiguration
      .getConfigsWithSlotDefaultInputValues(fctsConfigs)

    return (
      <>
        <IconButton
          text='Set Default'
          clickHandler={this.toggleSetSlotDefaultModal}
        />
        {fctsConfigsWithCustomDefault.length !== 0 ? (
          <Table className='ospin-red' celled structured>
            <TableHeader items={['Functionality', 'Channel [unit]', 'Default', '']} />
            <Table.Body key={2}>
              {fctsConfigsWithCustomDefault.map(fctConfig => {
                const { fctId } = fctConfig
                const fct = FCTGraph.getFctById(selectedGraph, fctId)
                return this.renderFunctionality(fct, fctConfig)
              })}
            </Table.Body>
          </Table>
        ) : (
          <>
            <br />
            <br />
            <NoDataMessage text='No custom defaults found' />
          </>
        )}
        <SetSlotDefaultModal
          closeHandler={this.toggleSetSlotDefaultModal}
          activeDevice={activeDevice}
          selectedGraph={selectedGraph}
          setFctsConfigs={setFctsConfigs}
          fctsConfigs={fctsConfigs}
          open={showSetSlotDefaultModal}
        />
      </>
    )
  }
}

export default connect(mapStateToProps)(SlotDefaultsTable)
