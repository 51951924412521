import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'semantic-ui-react'
import Device from '~/utils/Device'
import FunctionalityGraphWidgetStatusIndicator from './FunctionalityGraphWidgetStatusIndicator'

const BACKGROUND_COLOR = {
  grey: '#B3B3B3',
  green: '#27AE60',
}

export default function DeviceWidgetStatusIndicator({ device, fctGraphId }) {
  if (!fctGraphId) {
    return (
      <Link to={`/devices/${device.id}/processes`}>
        <Card.Content
          className='device-widget-status-container '
          style={{
            backgroundColor: Device.isOnline(device)
              ? BACKGROUND_COLOR.green
              : BACKGROUND_COLOR.grey,
          }}
        >
          <span>
            {Device.isOnline(device) ? 'ready for process' : 'offline'}
          </span>
        </Card.Content>
      </Link>
    )
  }

  return <FunctionalityGraphWidgetStatusIndicator device={device} fctGraphId={fctGraphId} />
}
