import nexus from '@ospin/nexus'
import {
  addHistoricSensorData,
  setSensorDataErrorFlag,
  setLoadingMoreHistoricSensorDataFlag,
} from '~/redux/actions/actions'
import DataManager from '~/utils/DataManager'

const callLoadHistoricSensorData = async (
  dispatch,
  activeProcess,
  reporterFctId,
  numberOfPoints,
  start,
  end,
  loadMaterializedView = false,
) => {
  const { id: processId } = activeProcess

  if (DataManager.isSensorDataMaterializedViewLoaded(activeProcess,reporterFctId) && loadMaterializedView) {
    return
  }

  dispatch(setLoadingMoreHistoricSensorDataFlag({ reporterFctId, processId }))
  try {
    const endpoint = loadMaterializedView
      ? nexus.dataPoints.materializedView.get
      : nexus.dataPoints.get
    const { data } = await endpoint(
      processId,
      reporterFctId,
      { numberOfPoints, start, end },
    )
    dispatch(addHistoricSensorData({ reporterFctId, data, processId, isMaterializedView: loadMaterializedView }))
  } catch (e) {
    dispatch(setSensorDataErrorFlag({ reporterFctId, processId }))
  }
}

export default callLoadHistoricSensorData
