import { DevicePusherChannel } from '@ospin/pusher'
import deviceEventHandler from '~/redux/pusher/deviceChannel/eventHandlers/deviceEventHandler'
import updateDeviceDefaultGraphEventHandler from '~/redux/pusher/deviceChannel/eventHandlers/updateDeviceDefaultGraphEventHandler'
import updateDeviceFirmwareUpdates from '~/redux/pusher/deviceChannel/eventHandlers/updateDeviceFirmwareUpdates'
import connectionStateUpdateHandler from '~/redux/pusher/deviceChannel/eventHandlers/connectionStateUpdateHandler'
import stateChangeHandler from '~/redux/pusher/deviceChannel/eventHandlers/stateChangeHandler'
import updateDeviceFunctionalities from './eventHandlers/updateDeviceFunctionalities'

const { EVENTS: DEVICE_EVENTS } = DevicePusherChannel
export default deviceId => ({
  [DEVICE_EVENTS.DEVICE_DESCRIPTION_UPDATED]: () => updateDeviceDefaultGraphEventHandler(deviceId),
  [DEVICE_EVENTS.DEVICE_DEFAULT_FCT_GRAPH_UPDATED]: () => updateDeviceDefaultGraphEventHandler(deviceId),
  [DEVICE_EVENTS.DEVICE_FIRMWARE_UPDATED]: data => updateDeviceFirmwareUpdates(deviceId, data),
  [DEVICE_EVENTS.DEVICE_CONNECTION_UPDATED]: data => connectionStateUpdateHandler(deviceId, data),
  [DEVICE_EVENTS.DEVICE_STATE_UPDATED]: data => stateChangeHandler(deviceId, data),
  [DEVICE_EVENTS.DEVICE_EVENT_CREATED]: deviceEventHandler,
  [DEVICE_EVENTS.DEVICE_FUNCTIONALITIES_UPDATED]: () => updateDeviceFunctionalities(deviceId),
})
