import React from 'react'
import { Table } from 'semantic-ui-react'
import GroupChangeFilter from '~/utils/PDVCUtils/GroupChangeFilter'
import ChangesTableBody from './ChangesTableBody'

const HEADERS = [ 'Phases', 'Cycles', 'Action', 'Previous', 'New']

const ChangesTable = ({ diffs, activeProcess, snapshot }) => (
  <Table
    style={{ width: '100%' }}
    size='large'
    celled
    className='ospin-red'
    collapsing
  >
    <Table.Header>
      <Table.Row>
        {HEADERS.map(title => <Table.HeaderCell key={title}>{title}</Table.HeaderCell>)}
      </Table.Row>
    </Table.Header>
    <ChangesTableBody
      diffs={GroupChangeFilter.filter(diffs, activeProcess, snapshot)}
      activeProcess={activeProcess}
      snapshot={snapshot}
    />
  </Table>
)

export default ChangesTable
