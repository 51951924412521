import React from 'react'
import nexus from '@ospin/nexus'
import { connect } from 'react-redux'
import { Grid, Button, Icon, Loader, Segment } from 'semantic-ui-react'
import { Switch, Route, withRouter, Redirect, Link } from 'react-router-dom'
import { DevicePusherChannel, DeviceProcessesPusherChannel } from '@ospin/pusher'
import { Functionality, Ports } from '@ospin/fct-graph'
import DynamicLoader from '~/components/utility/DynamicLoader'
import ProcessScreen from '~/components/processviewer/ProcessScreen'
import DeviceUtils from '~/utils/Device'
import FirmwareUpdateUtils from '~/utils/device/FirmwareUpdate'
import UrlValidator from '~/utils/validation/UrlValidator'
import LogView from '~/components/log/LogView'
import deviceProcessesChannelHandler from '~/redux/pusher/deviceProcessesChannel/deviceProcessesChannelHandler'
import deviceChannelHandler from '~/redux/pusher/deviceChannel/deviceChannelHandler'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import { createDeviceObject } from '~/redux/helper/objectMapper'
import { setActiveDevice } from '~/redux/actions/actions'
import Notification from '~/utils/user/Notification'
import NotificationModal from '~/components/notifications/NotificationModal'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import ConfigurationsView from './widget/ConfigurationsView'
import DeviceMenu from './menu/DeviceMenu'
import ManageAccess from './manageaccess/ManageAccess'
import Processes from './processes/Processes'
import DeviceSetup from './setup/DeviceSetup'
import ErrorPage from '../utility/ErrorPage'
import InconsistentDeviceStateBanner from './InconsistentDeviceStateBanner'
import UpdateFirmwareModal from './setup/modals/UpdateFirmwareModal'
import './Device.css'
import './widget/GraphsSection.css'
import TemplateBuilderWrapper from './setup/Templates/TemplateBuilder/TemplateBuilderWrapper'

const mapStateToProps = state => ({
  user: state.user,
  processes: state.processes,
  devices: state.devices,
  logs: state.logs,
  activeDeviceInitialized: state.activeDeviceInitialized,
})

const mapDispatchToProps = dispatch => ({
  dispatchSetActiveDevice: (device, deviceUsers) => dispatch(
    setActiveDevice({ device, deviceUsers }),
  ),
})

class Device extends React.Component {

  state = {
    error: {
      isError: false,
      code: null,
    },
    showDeviceNotificationModal: false,
    showFirmwareUpdateModal: false,
  }

  async componentDidMount() {
    const {
      devices,
      match: { params: { deviceId } },
      dispatchSetActiveDevice,
      user,
    } = this.props

    try {
      const deviceWasFetched = devices.some(device => device.id === deviceId)

      const [ { data: deviceData }, { data: { users } } ] = await Promise.all([
        nexus.user.device.get({ userId: user.id, deviceId }),
        nexus.user.list({ deviceId }),
      ])

      const device = createDeviceObject(deviceData)

      if (device.fctGraphs.length) {
        await UserFctGraphUIConfig.setUsersFctGraphUIConfig(device.fctGraphs[0])
      }

      dispatchSetActiveDevice(device, users)

      if (!deviceWasFetched) {
        DevicePusherChannel.subscribe({ deviceId }, deviceChannelHandler(deviceId))
      }

      DeviceProcessesPusherChannel
        .subscribe({ deviceId }, deviceProcessesChannelHandler(deviceId))

      if (Notification.getDisplayableDeviceNotifications(user, device).length) {
        this.setState({ showDeviceNotificationModal: true })
      }

    } catch (e) {
      if (e.status === 404) {
        this.setState({ error: { isError: true, code: 404 } })
      }

      if (e.status === 403) {
        this.setState({ error: { isError: true, code: 403 } })
      }
      /* TODO: ADD_ERROR_HANDLING */
    }
  }

  componentWillUnmount() {
    const { match: { params: { deviceId } } } = this.props
    DeviceProcessesPusherChannel.unsubscribe({ deviceId })
  }

  getActiveDeviceFromUrl = () => {
    const { devices, match: { params: { deviceId } } } = this.props
    return DeviceUtils.getById(devices, deviceId)
  }

  renderProcesses = () => {
    const activeDevice = this.getActiveDeviceFromUrl()

    return <Processes activeDevice={activeDevice} />
  }

  renderLog = () => (<LogView />)

  renderAccess = () => <ManageAccess activeDevice={this.getActiveDeviceFromUrl()} />

  renderSetup = () => <DeviceSetup activeDevice={this.getActiveDeviceFromUrl()} />

  renderTemplateBuilder = () => <TemplateBuilderWrapper activeDevice={this.getActiveDeviceFromUrl()} />

  renderProcessScreen = () => {
    const activeDevice = this.getActiveDeviceFromUrl()
    const { match: { params: { processId } } } = this.props

    return !UrlValidator.isUuidv4(processId)
      ? <ErrorPage customMessage={`process: ${processId} not found!`} errorCode={404} />
      : <ProcessScreen activeDevice={activeDevice} />
  }

  renderConfigurationsView = () => (
    <Segment className='devices-and-solutions-tab-segment'>
      <ConfigurationsView
        device={this.getActiveDeviceFromUrl()}
      />
    </Segment>
  )

  renderDeviceRoutes = () => {
    const { match } = this.props
    const { params: { deviceId, fctGraphId } } = match

    return (
      <Grid.Row>

        <Grid.Column width={16}>
          <Switch>
            <Route exact path='/devices/:deviceId'>
              <Redirect to={`/devices/${deviceId}/processes`} />
            </Route>
            <Route exact path='/devices/:deviceId/setups/create' component={this.renderTemplateBuilder} />
            <Route exact path='/devices/:deviceId/setups/:templateId' component={this.renderTemplateBuilder} />

            <Route exact path='/devices/:deviceId/configurations/:fctGraphId'>
              <Redirect to={`/devices/${deviceId}/configurations/${fctGraphId}/processes`} />
            </Route>
            <Route exact path='/devices/:deviceId/processes' component={this.renderProcesses} />
            <Route exact path='/devices/:deviceId/configurations/:fctGraphId/processes' component={this.renderProcesses} />
            <Route exact path='/devices/:deviceId/configurations/:fctGraphId/processes/:processId' component={this.renderProcessScreen} />
            <Route exact path='/devices/:deviceId/processes/:processId' component={this.renderProcessScreen} />
            <Route exact path='/devices/:deviceId/configurations' component={this.renderConfigurationsView} />
            <Route exact path='/devices/:deviceId/logs' component={this.renderLog} />
            <Route exact path='/devices/:deviceId/setup' component={this.renderSetup} />
            <Route exact path='/devices/:deviceId/configurations/:fctGraphId/setup' component={this.renderSetup} />
            <Route exact path='/devices/:deviceId/access' component={this.renderAccess} />
            <Route>
              <ErrorPage errorCode={404} />
            </Route>
          </Switch>
        </Grid.Column>
      </Grid.Row>
    )
  }

  getStatusCircleColorClass = activeDevice => {
    const { online } = activeDevice
    const state = DeviceUtils.getState(activeDevice)

    if (!online) { return 'background-grey' }

    switch (state) {
      case ('idle'):
        return 'background-green'
      case ('running'):
        return 'background-blue'
      case ('paused'):
        return 'background-orange'
      default:
        return 'background-grey'
    }
  }

  renderFirmwareUpdateHint = activeDevice => {
    const { firmwareUpdate } = activeDevice
    if (!firmwareUpdate) return null

    if (FirmwareUpdateUtils.isInitiated(firmwareUpdate)) {
      return (
        <div className='device-stats-pending-update-container'>
          <Loader size='medium' className='device-stats-pending-update-spinner' active />
          <div>updating firmware</div>
        </div>
      )
    }

    if (FirmwareUpdateUtils.isAvailable(firmwareUpdate)) {
      const goToSetupPage = () => {
        const { history } = this.props
        const pathSplitted = history.location.pathname.split('/')
        const index = pathSplitted.findIndex(el => el === 'devices') + 1
        const newPath = `${pathSplitted.filter((_, i) => i <= index).join('/')}/setup`
        history.push(newPath)
        this.setState({ showFirmwareUpdateModal: true })
      }

      return (
        <Button
          primary
          inverted
          onClick={() => goToSetupPage()}
        >
          <Icon name='info' />
          Firmware Update Available
        </Button>
      )
    }
  }

  getClassForPortStatus = (isSingleFctConnected, activeDevice) => {
    if (DeviceUtils.isOnline(activeDevice) && isSingleFctConnected) {
      return 'status-online'
    }
    return 'status-offline'
  }

  renderPortName = (fctGraph, activeDevice) => {
    const physFcts = fctGraph.functionalities.filter(Functionality.isPhysical)

    return (
      <div className='device-stats-fctgraph-port-container'>
        {physFcts.map(physFct => {
          const isSingleFctConnected = FunctionalityGraph
            .singleFunctionalityIsConnected(activeDevice, physFct)

          const portStatus = this.getClassForPortStatus(isSingleFctConnected, activeDevice)
          return (
            <span className={`device-stats-fctgraph-port-indicator port-${portStatus}`} key={physFct.id}>
              {Ports.getId(physFct.ports)}
            </span>
          )
        })}
      </div>
    )
  }

  renderNavigation = (activeDevice, fctGraph, statusName, statusColorCircleClass) => {
    const { location, match: { params: { processId } } } = this.props
    const { showFirmwareUpdateModal } = this.state

    const searchParams = new URLSearchParams(location.search)
    searchParams.set('skip', '0')

    const fullPath = location.pathname.concat('?', searchParams.toString())

    const selectedPartOfUrl = processId
      ? new RegExp(`configurations/${fctGraph.id}/processes/${processId}.*`)
      : `configurations/${fctGraph.id}/`

    const linkTo = fullPath.replace(selectedPartOfUrl, '')

    return (
      <div className='device-stats-container'>
        <span title={`${statusName}`} className={`device-stats-status-circle ${statusColorCircleClass}`} />
        <span className='device-stats-fct-graph-name'>
          <Link
            to={linkTo}
            className='device-stats-device-name-link'
          >
            {activeDevice.name}
          </Link>
          &nbsp;
          {`/ ${fctGraph.name}`}
          {this.renderPortName(fctGraph, activeDevice)}
        </span>

        { this.renderFirmwareUpdateHint(activeDevice, showFirmwareUpdateModal)}
        {showFirmwareUpdateModal && (
          <UpdateFirmwareModal
            activeDevice={activeDevice}
            closeHandler={() => this.setState({ showFirmwareUpdateModal: false })}
            headerText='Update Firmware Version'
          />
        )}
      </div>
    )
  }

  renderDeviceStats = activeDevice => {
    const statusColorCircleClass = this.getStatusCircleColorClass(activeDevice)
    const statusName = DeviceUtils.getState(activeDevice)
    const { match } = this.props
    const { params: { fctGraphId } } = match

    const { showFirmwareUpdateModal } = this.state

    if (fctGraphId) {
      const fctGraph = activeDevice.fctGraphs.find(graph => graph.id === fctGraphId)

      if (fctGraph) {

        return this.renderNavigation(
          activeDevice,
          fctGraph,
          statusName,
          statusColorCircleClass,
        )
      }
    }

    return (
      <div className='device-stats-container'>
        <div>
          <span title={`${statusName}`} className={`device-stats-status-circle ${statusColorCircleClass}`} />
          <span className='device-stats-name'>
            {activeDevice.name}
          </span>
        </div>
        {this.renderFirmwareUpdateHint(activeDevice, showFirmwareUpdateModal)}
        {showFirmwareUpdateModal && (
          <UpdateFirmwareModal
            activeDevice={activeDevice}
            closeHandler={() => this.setState({ showFirmwareUpdateModal: false })}
            headerText='Update Firmware Version'
          />
        )}

      </div>
    )
  }

  renderPortsUpdatedModal = activeDevice => {
    const { match, user } = this.props
    const { notifications } = user
    const { params: { fctGraphId } } = match
    if (!fctGraphId) return null

    const fctGraph = activeDevice.fctGraphs.find(graph => graph.id === fctGraphId)
    if (!fctGraph) return null

    const hasPortUpdateNotification = Notification
      .getFunctionalityGraphLevelEphemeralNotifications(notifications, activeDevice.id, fctGraphId)

    if (!hasPortUpdateNotification.length) return null

    return (
      <NotificationModal
        user={user}
        fctGraph={fctGraph}
        device={activeDevice}
        level='fctGraph'
        show
        closeHandler={() => this.setState({ showDeviceNotificationModal: false })}
        deleteOptimistically
      />
    )
  }

  render() {

    const {
      match,
      devices,
      activeDeviceInitialized,
      user,
    } = this.props
    const { params: { deviceId } } = match
    const { error, showDeviceNotificationModal } = this.state

    if (error.code === 404 || !UrlValidator.isUuidv4(deviceId)) {
      return <ErrorPage customMessage={`device: ${deviceId} not found!`} errorCode={404} />
    }

    if (error.isError) {
      return <ErrorPage errorCode={error.code} />
    }

    if (!activeDeviceInitialized) return <DynamicLoader />
    const activeDevice = DeviceUtils.getById(devices, deviceId)

    return (
      <div>
        {showDeviceNotificationModal && (
          <NotificationModal
            user={user}
            device={activeDevice}
            level='device'
            show={showDeviceNotificationModal}
            closeHandler={() => this.setState({ showDeviceNotificationModal: false })}
          />
        )}
        {this.renderPortsUpdatedModal(activeDevice)}
        <Grid columns={2}>
          <Grid.Row>
            {this.renderDeviceStats(activeDevice)}
          </Grid.Row>
          <Grid.Row>
            <DeviceMenu activeDevice={activeDevice} />
          </Grid.Row>
          <InconsistentDeviceStateBanner activeDevice={activeDevice} />
          {this.renderDeviceRoutes()}
        </Grid>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Device))
