import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { removeUserFromResource } from '~/utils/access'

export default (state, action) => {
  const { processes } = state
  const { processId, userId } = action.payload

  const updateFn = process => removeUserFromResource(process, userId)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
