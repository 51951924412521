import React, { useState } from 'react'
import { Table, Message } from 'semantic-ui-react'
import FctGraphNameTableRow from './FctGraphNameTableRow'

const FctGraphNameTable = ({ selectedGraph, activeDevice }) => {

  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <>
      <Table celled definition compact className='ospin-red'>
        <Table.Body>
          <FctGraphNameTableRow
            selectedGraph={selectedGraph}
            activeDevice={activeDevice}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
          />
        </Table.Body>
      </Table>
      {isError && <Message error content={errorMessage} /> }
    </>
  )
}

export default FctGraphNameTable
