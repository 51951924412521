import { Functionality } from '@ospin/fct-graph'

class DeviceTemplateUtils {
  static getFct(fctDefinitions, { type, subType, isVirtual }) {
    return {
      ...fctDefinitions.find(svfct => type === svfct.type && subType === svfct.subType), isVirtual,
    }
  }

  static getSlot(fctDefinitions, { type, subType, isVirtual, slotName }) {
    const fct = DeviceTemplateUtils.getFct(fctDefinitions, { type, subType, isVirtual })
    const slot = Functionality.getSlotByName(fct, slotName)
    return slot
  }

  /**
   *
   *
   * @static
   * Parsed the functionality definitions and returns all the fcts that are used in either the
   * template or are available at the device
   * @return {*}
   * @memberof DeviceTemplateUtils
   */
  static getAllAvailableFcts(device, definitions, template) {
    const templateSubTypes = template.functionalities.map(({ subType }) => subType)
    const deviceSubTypes = [
      ...device.connectedPhysicalFunctionalities,
      ...device.supportedVirtualFunctionalities].map(({ subType }) => subType)

    const uniqueSubTypes = [...new Set([...templateSubTypes, ...deviceSubTypes])]
    const uniqueSubTypesWithoutIO = uniqueSubTypes.filter(subType => ![
      Functionality.FIXED_SUB_TYPES.INTERVAL_OUT,
      Functionality.FIXED_SUB_TYPES.PUSH_IN
    ].includes(subType))
    return definitions.filter(({ subType }) => uniqueSubTypesWithoutIO.includes(subType))
  }
}

export default DeviceTemplateUtils
