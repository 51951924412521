import { findAndUpdate } from '~/redux/helper/updateFunctions'
import DataManager from '~/utils/DataManager'

export default (state, action) => {
  const { data, reporterFctId, processId, isMaterializedView } = action.payload

  const { processes } = state

  const updateFn = targetProcess => DataManager
    .mergeSensorData(targetProcess, reporterFctId, data ,isMaterializedView)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
