import React from 'react'
import { FCTGraph } from '@ospin/fct-graph'
import { connect } from 'react-redux'
import { Accordion, Button, Ref } from 'semantic-ui-react'
import { DragDropContext, Droppable, useMouseSensor, useTouchSensor } from 'react-beautiful-dnd'
import { phaseIsModifiable, Process } from '~/utils/process'
import ProcessValidator from '~/utils/validation/ProcessValidator'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import PDVC from '~/utils/PDVC'
import FlashMessenger from '~/utils/FlashMessenger'
import {
  setPhaseProps,
  setInputNodeValue,
  deletePhaseWithinGroup,
  setDisplayedProcessPhase,
} from '~/redux/actions/actions'
import callUpdateProcess from '~/redux/thunks/process/callUpdateProcess'
import Functionality from './Functionality'
import PhaseHeader from './PhaseHeader'

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  displayedPhaseId: state.displayedPhaseId,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchRenamePhase: (processId, phaseId, name) =>
    dispatch(setPhaseProps({ processId, phaseId, update: { name } })),
  dispatchDeletePhaseWithinGroup: (processId, groupName, phaseId) =>
    dispatch(deletePhaseWithinGroup({ processId, groupName, phaseId })),
  dispatchCallUpdateProcess: (processId, params) => callUpdateProcess(dispatch, processId, params),
  dispatchSetInputNodeValue: (processId, phaseId, inputNodeFctId, value) => dispatch(
    setInputNodeValue({ processId, phaseId, value, inputNodeFctId, })),
  dispatchSetDisplayedProcessPhase: phaseId => dispatch(
    setDisplayedProcessPhase(phaseId),
  ),
})

class PhaseTabContent extends React.Component {

  submitNewPhaseName = async newPhaseName => {
    const { activeProcess, phaseId, dispatchRenamePhase, processDescriptionSnapshots } = this.props

    try {
      ProcessValidator.validatePhaseName(newPhaseName)
    } catch (e) {
      FlashMessenger.error(e.message)
      return
    }

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    if (diffs.length) {
      FlashMessenger.warning('Please revert or apply your process changes before renaming a phase')
      return
    }

    dispatchRenamePhase(activeProcess.id, phaseId, newPhaseName)
    await this.save()
  }

  toggleFold = fctId => {
    UserFctGraphUIConfig.toggleBuilderFctFold(fctId)
  }

  foldAllFunctionalities = () => {
    UserFctGraphUIConfig.toggleAllBuilderFcts(true)
  }

  expandAllFunctionalities = () => {
    UserFctGraphUIConfig.toggleAllBuilderFcts(false)
  }

  async save() {
    const { activeProcess, dispatchCallUpdateProcess } = this.props
    await Process.save(activeProcess, dispatchCallUpdateProcess)
  }

  renderHeader = () => {
    const { activeProcess, phaseId, phase, activeDevice, user } = this.props
    const phaseCanBeModified = phaseIsModifiable(activeProcess, phaseId)

    return (
      <PhaseHeader
        phase={phase}
        phaseId={phaseId}
        phaseName={phase.name}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        phaseCanBeModified={phaseCanBeModified}
        submitNewPhaseName={this.submitNewPhaseName}
        user={user}
      />
    )
  }

  onDragEnd = result => {
    const { type, destination, source } = result
    if (!destination || destination.droppableId !== source.droppableId) {
      return
    }

    if (type && type.type === 'Group') {
      UserFctGraphUIConfig.moveBuilderFct(source.index, destination.index)
    }
  }

  render() {
    const {
      activeProcess,
      phaseId,
      activeDevice,
      displayedPhaseId,
      dispatchCallUpdateProcess,
      dispatchSetInputNodeValue,
      user,
    } = this.props

    const { fctGraphUIConfig } = user
    const { configs: { processBuilder: { functionalities } } } = fctGraphUIConfig
    const { fctGraph } = activeProcess

    if (phaseId !== displayedPhaseId) return null

    return (
      <div>
        {this.renderHeader()}
        <DragDropContext onDragEnd={this.onDragEnd} sensors={[useMouseSensor, useTouchSensor]}>
          <Droppable droppableId='builder-group-id' type={{ type: 'Group' }}>
            {
              provided => (
                <Ref innerRef={provided.innerRef}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                    <Button.Group
                      style={{ marginLeft: 'auto', marginBottom: '10px' }}
                    >
                      <Button
                        className='process-viewer-button'
                        compact
                        onClick={this.foldAllFunctionalities}
                      >
                        Collapse All
                      </Button>
                      <Button
                        className='process-viewer-button'
                        compact
                        onClick={this.expandAllFunctionalities}
                      >
                        Expand All
                      </Button>
                    </Button.Group>
                    <br />
                    <Accordion
                      {...provided.droppableProps}
                      fluid
                      styled
                      exclusive={false}
                    >
                      {functionalities.map((fctUIConfig, i) => {
                        const { fctId } = fctUIConfig
                        const fct = FCTGraph.getFctById(fctGraph, fctId)
                        return (
                          <Functionality
                            className='disable-text-selection'
                            key={fctId}
                            index={i}
                            phaseId={phaseId}
                            activeProcess={activeProcess}
                            activeDevice={activeDevice}
                            fctUIConfig={fctUIConfig}
                            functionality={fct}
                            toggleFold={this.toggleFold}
                            dispatchCallUpdateProcess={dispatchCallUpdateProcess}
                            dispatchSetInputNodeValue={dispatchSetInputNodeValue}
                            user={user}
                          />
                        )
                      })}
                      {provided.placeholder}
                    </Accordion>
                  </div>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseTabContent)
