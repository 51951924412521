import React from 'react'
import defaultImage from '~/images/bioreactor_online_cropped.png'
import { connect } from 'react-redux'
import { Table, Checkbox, Button, Icon, Popup, List } from 'semantic-ui-react'
import { ellipseString } from '~/utils/string'
import { Process } from '~/utils/process'
import { Functionality, Ports } from '@ospin/fct-graph'
import callUpdateProcessOptimistic from '~/redux/thunks/process/callUpdateProcessOptimistic'
import Time from '~/utils/Time'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'
import FeatureVersioning from '~/utils/FeatureVersioning'
import { IconMenu } from './ProcessIconBar'
import './ProcessRow.css'

const MAX_PROCESS_NAME_LEN = 30
const MAX_TRIMMED_DATE_TIME_LEN = 15
const MAX_FINISHED_STR_LEN = 18
const MAX_CONFIGURATION_TEXT_LEN = 35

const mapDispatchToProps = dispatch => ({
  dispatchcallUpdateProcessOptimistic:
  (processId, params) => callUpdateProcessOptimistic(dispatch, processId, params),
})

function handleToggleFavorite(event, processId, pinned, dispatchcallUpdateProcessOptimistic) {
  event.stopPropagation()
  dispatchcallUpdateProcessOptimistic(processId, { pinned: !pinned })
}

const getDuration = (state, startedAt, finishedAt) => {
  if (finishedAt && startedAt) return Time.stringFromDuration((finishedAt - startedAt) / 1000)
  if (Process.isFinished({ state })) return 'Not exposed'
  return 'N/A'
}

const getFinishedString = (state, finishedAt) => {
  if (finishedAt) return Time.getTrimmedDateAndTimeString(finishedAt)
  if (Process.isFinished({ state })) return 'Not exposed'
  return 'N/A'
}

const ProcessRow = ({
  process,
  checked,
  openModalHandler,
  selectHandler,
  processClickHandler,
  activeDevice,
  showProcessDetailsModal,
  dispatchcallUpdateProcessOptimistic,
}) => {

  const {
    name,
    state,
    createdAt,
    startedAt,
    finishedAt,
    cloneHistory,
  } = process

  const dislayCellText = (text, max) => (
    <Popup
      trigger={(
        <p>{ellipseString(text, max)}</p>
      )}
      content={text}
      hoverable
    />
  )

  const createTextConfiguration = (physFcts, fctGraph) => {
    /* for fctGraphs with a single physical functionality
      * we show the port */
    if (physFcts.length !== 1) {
      return `${fctGraph.name}`
    }
    return `${fctGraph.name} - ${Ports.getId(physFcts[0].ports)}`
  }

  const renderConfiguration = () => {
    const fctGraph = FunctionalityGraph.getGraphById(activeDevice.fctGraphs, process.fctGraphId)

    const physFcts = fctGraph.functionalities
      .filter(Functionality.isPhysical)

    const setFctImg = fctGraph.imageURL === null ? defaultImage : fctGraph.imageURL

    return (
      <div className='process-row-cell-configuration-container'>
        <img
          src={setFctImg}
          alt='device'
          className='process-row-cell-configuration-img'
        />
        {dislayCellText(createTextConfiguration(physFcts, fctGraph), MAX_CONFIGURATION_TEXT_LEN)}
      </div>
    )
  }

  const renderIntermediateIcon = () => (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Icon name='neuter' flipped='vertically' style={{ position: 'absolute', bottom: '-1.5px', margin: '0' }} />
      <Icon name='neuter' style={{ position: 'absolute', bottom: '-5px', margin: 0 }} />
    </div>
  )

  function renderProcessHistoryPopupListItem(entry) {
    const isLastItem = cloneHistory.indexOf(entry) === 0
    return (
      <List.Item key={entry.id} style={{ whiteSpace: 'nowrap' }}>
        <b>
          {isLastItem
            ? <Icon name='neuter' flipped='vertically' />
            : renderIntermediateIcon()}
        </b>
        <a
          style={{ marginLeft: isLastItem ? 0 : '22px' }}
          className='blue-link highlight-popup-item'
          href={`processes/${entry.id}`}
        >
          {entry.name}
        </a>
      </List.Item>
    )
  }

  function renderProcessHistoryContentHeader(headerText) {
    return (
      <>
        <Icon name='neuter' />
        <b>
          <a
            className='blue-link'
            href={`processes/${process.id}`}
          >
            {ellipseString(headerText, MAX_PROCESS_NAME_LEN)}
          </a>
        </b>
      </>
    )
  }

  function renderProcessHistoryPopupContent () {
    return (
      <List style={{ maxHeight: '50vh', overflowY: 'auto', fontSize: '1.1rem' }}>
        {renderProcessHistoryContentHeader(name)}
        {cloneHistory.slice().reverse().map(renderProcessHistoryPopupListItem)}
      </List>
    )
  }

  const toggleModal = (event, modalTrigger) => {
    event.stopPropagation()
    openModalHandler(modalTrigger, process)
  }

  const duration = getDuration(state, startedAt, finishedAt)
  const finishedString = getFinishedString(state, finishedAt)
  const hasHistory = cloneHistory.length > 0

  return (
    <Table.Row key={process.id} positive={process.status === 'running'}>
      <Table.Cell
        className='process-row-cell'
        style={{ cursor: 'pointer' }}
        textAlign='center'
        verticalAlign='middle'
        collapsing
      >
        <IconMenu
          toggleModal={toggleModal}
          handleToggleFavorite={e => handleToggleFavorite(
            e,
            process.id,
            process.pinned,
            dispatchcallUpdateProcessOptimistic,
          )}
          isPinned={process.pinned}
          contentJustificationClass='flex-start negative-left-margin-10'
          showProcessDetailsModal={showProcessDetailsModal}
        />

      </Table.Cell>
      <Table.Cell className='process-row-cell'>
        <span
          role='button'
          tabIndex={0}
          className='blue-link'
          onKeyDown={() => processClickHandler(process)}
          onClick={() => processClickHandler(process)}
        >
          {dislayCellText(name, MAX_PROCESS_NAME_LEN)}
        </span>
      </Table.Cell>

      {FeatureVersioning.supportsMultipleProcesses(activeDevice) ? (
        <Table.Cell className='process-row-cell'>
          {renderConfiguration()}
        </Table.Cell>
      ) : null }
      <Table.Cell className='process-row-cell' collapsing>
        <Popup
          wide='very'
          trigger={(
            <Button disabled={!hasHistory} icon compact basic labelPosition='left'>
              <Icon name='code branch' />
              Show
            </Button>
          )}
          content={renderProcessHistoryPopupContent()}
          position='right center'
          hoverable
        />
      </Table.Cell>
      <Table.Cell className='process-row-cell'>{state}</Table.Cell>
      <Table.Cell className='process-row-cell'>{dislayCellText(Time.getTrimmedDateAndTimeString(createdAt), MAX_TRIMMED_DATE_TIME_LEN)}</Table.Cell>
      <Table.Cell className='process-row-cell'>
        {
          finishedString !== 'N/A' ? dislayCellText(finishedString, MAX_FINISHED_STR_LEN) : finishedString
        }
      </Table.Cell>
      <Table.Cell className='process-row-cell'>{duration}</Table.Cell>
      <Table.Cell className='process-row-cell' collapsing textAlign='center' verticalAlign='middle'>
        <Popup
          content={`${Process.isRunning(process) ? 'running' : 'paused'} processes can not be deleted`}
          disabled={!Process.isRunningOrPaused(process)}
          trigger={(
            <Checkbox
              data-testid={`delete-${process.id}`}
              disabled={Process.isRunningOrPaused(process)}
              checked={checked}
              onChange={() => selectHandler()}
            />
          )}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default connect(null, mapDispatchToProps)(ProcessRow)
