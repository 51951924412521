import React, { useState } from 'react'
import { connect } from 'react-redux'
import modalize from '~/components/utility/modal/modalize'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'
import GraphTools from '~/utils/GraphTools'
import LineChartEditModalForm from './LineChartEditModalForm'

const mapStateToProps = state => ({ user: state.user })

const LineChartEditModal = ({
  closeHandler,
  fctGraph,
  widget,
  user,
  dashboard,
}) => {
  const [ selectedFctId, setSelectedFctId ] = useState('')
  const [ selectedSlotName, setSelectedSlotName ] = useState('')

  const { fctGraphUIConfig: { configs, configs: { dashboards } } } = user

  const [selectedLineColorEditChart, setSelectedLineColorEditChart] = useState(GraphTools
    .getColorByIndex(widget.dataSources.length))

  const addDataSource = async newDataSource => {
    const updatedDashboard = GraphDashboard.addDataSource(dashboard, widget.id, newDataSource)

    await UserFctGraphUIConfig.update(
      fctGraph.id,
      { ...configs, dashboards: GraphDashboards.updateDashboard(dashboards, updatedDashboard) },
    )

    const updatedLineChartWidget = GraphDashboard.getWidgetById(updatedDashboard, widget.id)
    setSelectedLineColorEditChart(
      GraphTools.getColorByIndex(updatedLineChartWidget.dataSources.length),
    )
  }

  const removeDataSource = async index => {
    const updatedDashboard = GraphDashboard.removeDataSource(dashboard, widget.id, index)
    await UserFctGraphUIConfig.update(
      fctGraph.id,
      { ...configs, dashboards: GraphDashboards.updateDashboard(dashboards, updatedDashboard) },
    )

    setSelectedLineColorEditChart(GraphTools.getColorByIndex(index))
  }

  const updateDataSource = async (index, newlineColor) => {
    const updatedDashboard = GraphDashboard
      .updateDataSourceLineColor(dashboard, widget.id, index, newlineColor)

    await UserFctGraphUIConfig.update(
      fctGraph.id,
      { ...configs, dashboards: GraphDashboards.updateDashboard(dashboards, updatedDashboard) },
    )
  }

  return (
    <LineChartEditModalForm
      showSubmitButton={false}
      closeHandler={closeHandler}
      fctGraph={fctGraph}
      widget={widget}
      selectedFctId={selectedFctId}
      setSelectedFctId={setSelectedFctId}
      selectedSlotName={selectedSlotName}
      setSelectedSlotName={setSelectedSlotName}
      addDataSource={addDataSource}
      removeDataSource={removeDataSource}
      updateDataSource={updateDataSource}
      selectedLineColorEditChart={selectedLineColorEditChart}
      setSelectedLineColorEditChart={setSelectedLineColorEditChart}
    />
  )
}

export default connect(mapStateToProps)(modalize(LineChartEditModal))
