import { FCTGraph } from '@ospin/fct-graph'
import { createRoundFunction } from '~/utils/math'
import Validator from '~/utils/validation/Validator'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'

class DataManager {

  static initSensorData(process) {
    const { fctGraph } = process
    const reporterFctIds = FCTGraph
      .getIntervalOutFcts(fctGraph)
      .map(fct => fct.id)

    return reporterFctIds.map(reporterFctId => ({
      reporterFctId,
      dataPoints: [],
      loaded: false,
      error: false,
      fetchingMoreData: false,
      hasFetchedMaterializedData: false
    }))
  }

  static get NULL_VALUE_FLAG() {
    /*
    *  This is a deprecated way for the device to communicate
    *  missing sensor values
    */
    return -500
  }

  static mapMillisToSeconds(valueInMs) {
    return valueInMs / 1000
  }

  static filterNullFlaggedValue(value) {
    return value === DataManager.NULL_VALUE_FLAG ? null : value
  }

  static serializeDataPoints(times, values) {
    return times.map((time, idx) => ({
      x: DataManager.mapMillisToSeconds(time),
      y: DataManager.filterNullFlaggedValue(values[idx]),
    }))
  }

  static mapRealTimeDataToCommonFormat(sensorData) {
    const reporterFctIds = Object.keys(sensorData)
    return reporterFctIds.reduce((acc, reporterFctId) => {
      const [ times, values ] = sensorData[reporterFctId]
      return {
        ...acc,
        [reporterFctId]: DataManager.serializeDataPoints(times, values),
      }
    }, {})
  }

  static mapDataFromDBToCommonFormat(reporterFctId, data) {
    const { times, values } = data
    return { [reporterFctId]: times ? DataManager.serializeDataPoints(times, values) : [] }
  }

  static setSensorDataErrorFlag(process, reporterFctId) {
    return process.data.map(sensorData => {
      if (sensorData.reporterFctId === reporterFctId) {
        sensorData.loaded = true
        sensorData.error = true
        sensorData.fetchingMoreData = false
      }
      return sensorData
    })
  }

  static setLoadingHistoricDataFlag(process, reporterFctId) {
    return process.data.map(sensorData => {
      if (sensorData.reporterFctId === reporterFctId) {
        sensorData.fetchingMoreData = true
      }
      return sensorData
    })
  }

  static setHasLoadedMaterializedView(process, reporterFctId) {
    return process.data.map(sensorData => {
      if (sensorData.reporterFctId === reporterFctId) {
        sensorData.hasFetchedMaterializedData = true
      }
      return sensorData
    })
  }

  static appendDataPointsToSensorData(existingSensorData, newSensorData) {
    const newDataPoints = newSensorData
      .sort((dataPointA, dataPointB) => dataPointA.x - dataPointB.x)

    const futureDataPoints = (
      DataManager.filterForFutureDataPoints(existingSensorData.dataPoints, newDataPoints)
    )

    futureDataPoints.forEach(dp => {
      existingSensorData.dataPoints.push(dp)
    })
    existingSensorData.loaded = true

  }

  static appendManySensorsData(process, liveSensorData) {
    const sensorData = DataManager.mapRealTimeDataToCommonFormat(liveSensorData)
    const reporterFctIds = Object.keys(sensorData)

    reporterFctIds.forEach(reporterFctId => {
      const existingSensorData = DataManager.getSensorDataObject(process, reporterFctId)
      if (!existingSensorData) return

      const newSensorData = sensorData[reporterFctId]

      DataManager.appendDataPointsToSensorData(existingSensorData, newSensorData)
    })
  }

  static sortDataPointsByTime(dataPoints) {
    dataPoints.sort((a, b) => a.x - b.x)
  }

  static mergeSensorData(process, reporterFctId, data) {
    const sensorData = DataManager.mapDataFromDBToCommonFormat(reporterFctId, data)
    const currDataPoints = DataManager.getSensorDataPoints(process, reporterFctId)

    const newDataBatch = sensorData[reporterFctId]
    newDataBatch.forEach(dp => currDataPoints.push(dp))

    DataManager.sortDataPointsByTime(currDataPoints)

    return process.data.map(existingSensorData => {
      if (existingSensorData.reporterFctId === reporterFctId) {
        existingSensorData.dataPoints = currDataPoints
        existingSensorData.loaded = true
        existingSensorData.fetchingMoreData = false
      }
      return existingSensorData
    })
  }

  /* FETCHING */

  static getSensorDataObject(process, reporterFctId) {
    return process.data.find(sensorData => sensorData.reporterFctId === reporterFctId)
  }

  static getRoundFunctionFromSlotConfig(process, reporterFctId) {
    const sourceData = process.reporterToFctAndSlotMap[reporterFctId]
    if (!sourceData) return

    const { sourceFctId, sourceSlotName } = sourceData
    if (!sourceFctId || !sourceSlotName) return null

    const displayedDecimalPlaces = FunctionalityConfiguration
      .getSlotDisplayedDecimalPlaces(process.fctsConfigs, sourceFctId, sourceSlotName)

    return Validator.isUndefinedOrNull(displayedDecimalPlaces)
      ? null
      : createRoundFunction(displayedDecimalPlaces)
  }

  static getSensorDataPoints(process, reporterFctId) {
    const roundFunction = DataManager.getRoundFunctionFromSlotConfig(process, reporterFctId)
    const data = DataManager.getSensorDataObject(process, reporterFctId)?.dataPoints || []

    if (roundFunction) {
      const roundedData = data
        .map(dp => {
          dp.y = roundFunction(dp.y)
          return dp
        })
      return roundedData
    }

    return data
  }

  static isSensorDataLoaded(process, reporterFctId) {
    return DataManager.getSensorDataObject(process, reporterFctId)?.loaded
  }

  static isSensorDataMaterializedViewLoaded(process,reporterFctId) {
    return DataManager.getSensorDataPoints(process, reporterFctId)?.hasFetchedMaterializedData
  }

  static isFetchingMoreSensorData(process, reporterFctId) {
    return DataManager.getSensorDataObject(process, reporterFctId)?.fetchingMoreData
  }

  static isSensorDataError(process, reporterFctId) {
    return DataManager.getSensorDataObject(process, reporterFctId)?.error
  }

  static queryData(data, { start = 0, end = Infinity } = {}) {
    return data.filter(p => (p.x >= start && p.x <= end))
  }

  static queryLastNDataPoints(data, n = 120) {
    return data.slice(-n)
  }

  static getLatestExistingDataPointTime(dataPoints) {
    return dataPoints ? dataPoints[dataPoints.length - 1].x : -1
  }

  static filterOutDataPointsBeforeTimeInSeconds(dataPoints, timeInSeconds) {
    return dataPoints.filter(({ x }) => x > timeInSeconds)
  }

  static filterForFutureDataPoints(existingDataPoints, newDataPoints) {
    if (existingDataPoints.length === 0) return newDataPoints

    const latestExistingDataPointTime = (
      DataManager.getLatestExistingDataPointTime(existingDataPoints)
    )

    const futureDataPoints = (
      DataManager.filterOutDataPointsBeforeTimeInSeconds(newDataPoints, latestExistingDataPointTime)
    )

    return futureDataPoints
  }

  static getLastSensorDataPoint(process, reporterFctId) {
    const sensorData = DataManager.getSensorDataPoints(process, reporterFctId)
    if (!sensorData.length) return null
    return sensorData[sensorData.length - 1].y
  }

  static averageValues(slice) {
    return slice.reduce((total, dp) => total + dp.y, 0) / slice.length
  }

  static valuesWithinTimeRange(slice, min, max) {
    return slice.filter(p => p.x >= min && p.x <= max)
  }

}

export default DataManager
