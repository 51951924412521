import { Functionality } from '@ospin/fct-graph'
import { assertConnectionBetweenIsPossible } from '@ospin/fct-graph/src/slots/Slot'
import { Graph } from '@antv/x6'
import { Stencil } from '@antv/x6-plugin-stencil'
import { register } from '@antv/x6-react-shape'
import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import FunctionalityDescription from '~/utils/functionalities/FunctionalityDescription'
import TemplateUtils from './Utils/TemplateUtils'
import StencilUtils from './Utils/StencilUtils'
import X6Utils from './Utils/X6Utils'
import DeviceTemplateUtils from './Utils/DeviceTemplateUtils'
import TemplateBuilderControlPanel from './TemplateBuilderControlPanel'
import StencilComponent from './Utils/StencilComponent'
import './TemplateBuilder.css'
import FunctionalityInformationModal from './Modals/FunctionalityInformationModal'
import LayoutUtils from './Utils/LayoutUtils'

const mapStateToProps = ({ functionalityDescriptions, user }) => ({ functionalityDescriptions, user })

class TemplateBuilder extends Component {
  constructor(props) {
    super(props)
    const { activeDevice } = this.props
    this.state = {
      template: TemplateUtils.generateEmpty(activeDevice.id),
      layout: { nodePositions: {} },
      detailsModalPosId: null,
    }
  }

  static get METRO_ROUTER () {
    return {
      name: 'metro',
      args: {
        startDirections: ['right'],
        endDirections: ['left'],
      },
    }
  }

  componentDidMount() {
    const {
      sourceTemplate,
      functionalityDefinitions,
      functionalityDescriptions,
      activeDevice,
    } = this.props
    const component = this

    register({
      shape: 'stencil-node',
      width: 120,
      height: 50,
      component: StencilComponent,
    })

    const graph = new Graph({
      container: this.container,
      background: { color: '#F2F7FA' },
      mousewheel: {
        enabled: true,
        modifiers: ['ctrl', 'meta'],
      },
      scroller: {
        enabled: true,
        panning: true,
      },
      connecting: {
        router: TemplateBuilder.METRO_ROUTER,
        allowBlank: false,
        allowEdge: false,
        allowMulti: 'withPort',
        allowNode: false,
        allowPort: true,
        highlight: true,
        validateMagnet(e) {
          const { magnet } = e
          return magnet.getAttribute('port-group') === 'output'
        },
        validateConnection(event) {
          return component.checkIfNodeShouldBeHighlighed(event)
        },
        connectionPoint: { name: 'rect' },
      },
      highlighting: {
        magnetAvailable: {
          name: 'className',
          args: { className: 'connectable' },
        },
        magnetAdsorbed: {
          name: 'className',
          args: { className: 'absorbed' },
        },
      },
    })

    const stencil = new Stencil({
      target: graph,
      stencilGraphHeight: 0,
      title: null,
      layoutOptions: {
        columns: 1,
        marginX: 80,
        dx: 1,
        rowHeight: 'compact',
      },
      groups: [
        { name: 'Connected Devices' },
        { name: 'Virtual Devices' },
        { name: 'Other Devices' }
      ],
    })
    this.stencilContainer.appendChild(stencil.container)
    const deviceFctDefinitions = DeviceTemplateUtils.getAllAvailableFcts(activeDevice, functionalityDefinitions, sourceTemplate)

    const physicalFcts = deviceFctDefinitions.filter(fct => !Functionality.isVirtual(fct))
    const virtualFcts = deviceFctDefinitions.filter(Functionality.isVirtual)

    const disconnectedPhysicalFcts = functionalityDefinitions.filter(({subType: globalSubtype, isVirtual}) => {
      const isIncludedInDeviceDefs = deviceFctDefinitions.map(({subType}) => subType).includes(globalSubtype)
      return !isIncludedInDeviceDefs && !isVirtual
    })

    stencil.load(StencilUtils.generatePhysicalFctStencils(physicalFcts, graph, functionalityDefinitions, functionalityDescriptions), 'Connected Devices')
    stencil.load(StencilUtils.generatePhysicalFctStencils(disconnectedPhysicalFcts, graph, functionalityDefinitions, functionalityDescriptions), 'Other Devices')


    stencil.load(StencilUtils.generateVirtualFctStencils(virtualFcts, graph, functionalityDefinitions, functionalityDescriptions), 'Virtual Devices')

    graph.on('node:added', event => { this.addFunctionality(event) })
    graph.on('node:moved', event => { this.moveFunctionality(event) })
    graph.on('node:removed', event => { this.removeFunctionality(event) })
    graph.on('node:delete', event => { this.removeFunctionality(event) })
    graph.on('node:info', event => { this.showInformationModal(event) })
    graph.on('edge:change:target', event => { this.drawConnection(event) })
    graph.on('edge:removed', event => { this.removeConnection(event) })

    this.graph = graph
    graph.enablePanning()

    if (sourceTemplate) {
      this.loadExistingTemplate(sourceTemplate)
    }
  }

  loadExistingTemplate = sourceTemplate => {
    const { sourceLayout } = this.props
    const layout = sourceLayout || null

    this.updateView(sourceTemplate, layout)
    this.graph.zoomToFit({ padding: 50 })
  }

  addFunctionality = ({ node }) => {
    const { template, layout } = this.state
    const { functionalityDefinitions, functionalityDescriptions } = this.props
    const nodeData = node.getData()
    const { x, y } = node.getPosition()
    const posId = TemplateUtils.getNextPosId(template)

    const fct = DeviceTemplateUtils.getFct(
      functionalityDefinitions,
      { type: nodeData.type, subType: nodeData.subtype, isVirtual: nodeData.isvirtual },
    )
    const name = FunctionalityDescription.getFunctionalityDisplayName(
      functionalityDescriptions, fct.subType,
    )
    const updatedTemplate = TemplateUtils.addFunctionalityWithPushInAndReporterFcts(
      template,
      { ...fct, posId, name },
    )
    const updatedLayout = LayoutUtils.update(layout, { posId, x, y })

    this.updateView(updatedTemplate, updatedLayout)
  }

  moveFunctionality = ({ node }) => {
    const { layout, template } = this.state
    const { posId } = node.getData()
    const { x, y } = node.getPosition()
    const updatedLayout = LayoutUtils.update(layout, { posId, x, y })
    this.updateView(template, updatedLayout)
  }

  removeFunctionality = ({ node }) => {
    const { template, layout } = this.state
    const { posId } = node.getData()
    const updatedTemplate = TemplateUtils.removeFunctionalityWithConnectedPushInAndReporter(template, { posId })
    const updatedLayout = LayoutUtils.remove(layout, { posId })

    this.updateView(updatedTemplate, updatedLayout)
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  checkIfNodeShouldBeHighlighed = ({
    sourceCell,
    targetCell,
    sourceMagnet,
    targetMagnet,
  }) => {
    const { functionalityDefinitions } = this.props
    const { template } = this.state

    if (sourceCell === targetCell) {
      return false
    }
    if (
      !sourceMagnet
      || sourceMagnet.getAttribute('port-group') === 'input'
    ) {
      return false
    }
    if (
      !targetMagnet
      || targetMagnet.getAttribute('port-group') !== 'input'
    ) {
      return false
    }
    const sourceData = sourceCell.getData()
    const source = {
      subType: sourceData.subtype,
      type: sourceData.type,
      isVirtual: sourceData.isvirtual,
      slotName: sourceMagnet.getAttribute('port'),
    }

    const targetData = targetCell.getData()
    const target = {
      subType: targetData.subtype,
      type: targetData.type,
      isVirtual: targetData.isvirtual,
      slotName: targetMagnet.getAttribute('slot-name'),
    }

    const sourceSlot = DeviceTemplateUtils.getSlot(functionalityDefinitions, source)
    const targetSlot = DeviceTemplateUtils.getSlot(functionalityDefinitions, target)

    if (!sourceSlot || !targetSlot) {
      return false
    }
    const isTargetConnected = TemplateUtils.isConnectedToOtherFct(
      template,
      { posId: targetData.posId, slotName: target.slotName },
    )
    if (isTargetConnected) {
      return false
    }
    try {
      assertConnectionBetweenIsPossible(
        { ...sourceSlot, dataStreams: [] },
        { ...targetSlot, dataStreams: [] },
      )
    } catch (error) {
      return false
    }
    return true
  }

  drawConnection = event => {
    const { current, edge } = event
    const { template, layout } = this.state

    const targetCell = edge.getTargetCell()
    if (!targetCell) {
      return
    }
    const sourceCell = edge.getSourceCell()
    const targetPort = current.port
    const sourcePort = edge.getSourcePortId()
    const { posId: sourcePosId } = sourceCell.getData()
    const { posId: targetPosId } = targetCell.getData()

    const updatedTemplate = TemplateUtils.addConnectionAndReplacePushInAndReporterFct(
      template,
      {
        from: { posId: sourcePosId, slotName: sourcePort },
        to: { posId: targetPosId, slotName: targetPort },
      },
    )
    this.updateView(updatedTemplate, layout)
  }

  removeConnection = event => {
    const { edge, edge: { source, target } } = event
    if (edge.target.x && edge.target.y) {
      return
    }

    const { template, layout } = this.state

    const { source_pos_id: sourcePosId, target_pos_id: targetPosId } = edge.getData()

    const updatedTemplate = TemplateUtils.removeConnectionAndReplaceWithPushInAndReporter(
      template,
      {
        from: {
          posId: sourcePosId,
          slotName: source.port,
        },
        to: {
          posId: targetPosId,
          slotName: target.port,
        },
      },
    )
    this.updateView(updatedTemplate, layout)
  }

  showInformationModal = ({cell}) => {
    const { posId } = cell.getData()
    this.setState({ detailsModalPosId: posId })
  }

  updateView = (updatedTemplate, updatedLayout) => {
    const { functionalityDefinitions, functionalityDescriptions } = this.props
    const { graph } = this
    const isDataUpdateOnly = !updatedLayout

    if (isDataUpdateOnly) {
      this.setState({ template: updatedTemplate })
      return
    }

    this.setState({
      layout: updatedLayout,
      template: updatedTemplate,
    })

    const newGraphJSON = X6Utils.generateJSON(
      updatedTemplate,
      updatedLayout,
      functionalityDefinitions,
      functionalityDescriptions,
    )
    graph.fromJSON(newGraphJSON)
  }

  refContainer = container => {
    this.container = container
  }

  refStencil = container => {
    this.stencilContainer = container
  }

  closeModalAndRerender() {
    const { template, layout } = this.state
    this.setState({ detailsModalPosId: null })
    this.updateView(template, layout)
  }

  render() {
    const { template, layout, detailsModalPosId } = this.state
    const { functionalityDefinitions, user,activeDevice  } = this.props
    return (
      <>
        {' '}
        <TemplateBuilderControlPanel
          activeDevice={activeDevice}
          template={template}
          functionalityDefinitions={functionalityDefinitions}
          layout={layout}
          setTemplateAndRerender={this.updateView}
        />
        { detailsModalPosId !== null ? (
          <FunctionalityInformationModal
            targetPosId={detailsModalPosId}
            template={template}
            updateTemplate={newTemplate => this.updateView(newTemplate)}
            close={() => this.closeModalAndRerender()}
            functionalityDefinitions={functionalityDefinitions}
          />
        ) : null }

        <Segment className='template-builder-container'>
          {' '}
          <div className='dnd-app'>
            <div className='dnd-stencil' ref={this.refStencil} />
            <div className='dnd-content' ref={this.refContainer} />

          </div>
        </Segment>
      </>

    )
  }
}

export default connect(mapStateToProps)(TemplateBuilder)
