import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import Widget from './Widget'

const TYPE = 'actuatorValue'
const DISPLAY_NAME = 'Target Value'
const DESCRIPTION = 'Displays the actuator value for the current phase'
const USE_DATASOURCES = false

const getCompatibleFcts = fctGraph => (
  FCTGraph.getFctsWithoutIONodes(fctGraph)
    .filter(fct => fct.slots
      .some(slot => slot.type === Slot.TYPES.IN_SLOT))
)

const getCompatibleSlots = fct => (
  Functionality.getInSlots(fct)
)

const createDefaultWidget = ({ fctId, slotName }) => ({
  type: TYPE,
  fctId,
  slotName,
  display: {
    dimension: {
      minHeight: 200,
      maxHeight: 400,
      minWidth: 200,
      maxWidth: 600,
      height: 200,
      width: 200,
    },
    ...Widget.generateDisplayDefaults(),
  },
  ...Widget.generateDefaults(),
})

const hasRequiredParamsForCreation = params => params.slotName && params.fctId

export default {
  DESCRIPTION,
  DISPLAY_NAME,
  TYPE,
  USE_DATASOURCES,
  createDefaultWidget,
  getCompatibleFcts,
  getCompatibleSlots,
  hasRequiredParamsForCreation,
}
