import nexus from '@ospin/nexus'
import { transferDeviceOwnership } from '~/redux/actions/actions'

const callTransferDeviceOwnership = async (dispatch, deviceId, newOwnerId) => {
  const { data: { access } } = await nexus.device
    .transferOwnership({ deviceId, params: { newOwnerId } })

  const { data: { users: deviceUsers } } = await nexus.user.list({ deviceId })

  dispatch(transferDeviceOwnership({
    deviceId,
    newOwnerId,
    deviceUsers,
    access,
  }))
}

export default callTransferDeviceOwnership
