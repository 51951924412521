import nexus from '@ospin/nexus'
import React from 'react'
import { connect } from 'react-redux'

import modalize from '~/components/utility/modal/modalize'
import { updateDevice } from '~/redux/actions/actions'
import ResumeProcessModalForm from '~/components/processviewer/modals/ResumeProcessModalForm'

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDevice: (deviceId, params) =>
    dispatch(updateDevice({ deviceId, params: { ...params } })),
})

class ResumeProcessModal extends React.Component {

  state = { isError: false, errorMessage: '', pending: false }

  resumeProcess = async () => {
    const {
      activeDevice,
      activeProcess,
      dispatchUpdateDevice,
      closeHandler,
    } = this.props

    if (!activeDevice.readyForCommand) return

    dispatchUpdateDevice(activeDevice.id, { readyForCommand: false })

    this.setState({ pending: true })
    try {
      await nexus.command.device.process.resume(activeDevice.id, activeProcess.id)
      closeHandler()
    } catch (e) {
      this.setState({ isError: true, errorMessage: e.message, pending: false })
    }
  }

  render() {
    const { isError, errorMessage, pending } = this.state
    const { closeHandler } = this.props

    return (
      <ResumeProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.resumeProcess}
        loading={pending}
        submitText='Confirm'
      />
    )
  }
}

export default modalize(connect(null, mapDispatchToProps)(ResumeProcessModal))
