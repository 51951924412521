import Device from '~/utils/Device'
import FeatureVersioning from '~/utils/FeatureVersioning'

export default class DeviceValidator {

  static VALIDATION_ERRORS = new Set([
    'DeviceStateError',
  ])

  static createError(type, message) {
    if (!DeviceValidator.VALIDATION_ERRORS.has(type)) {
      throw new Error(`Unknown validation error type: ${type}`)
    }
    return { type, message }
  }

  static isReadyToRunProcess(device) {
    if (Device.isOffline(device)) {
      return [ DeviceValidator.createError(
        'DeviceStateError',
        'Device is offline',
      )]
    }

    if (!FeatureVersioning.supportsMultipleProcesses(device)) {
      if (Device.isRunning(device)) {
        return [ DeviceValidator.createError(
          'DeviceStateError',
          'Device is running another process',
        )]
      }
    }

    return []
  }
}
