import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import Devices from '~/components/devices/Devices'
import Device from '~/components/device/Device'
import Profile from '~/components/profile/Profile'
import Notifications from '~/components/notifications/Notifications'
import Changelog from '~/components/changelog/Changelog'
import Alerts from '~/components/alerts/Alerts'
import ErrorPage from '~/components/utility/ErrorPage'
import FirmwareUpdate from '~/utils/device/FirmwareUpdate'
import ConnectionIssueBanner from '~/components/utility/banners/ConnectionIssueBanner'
import FailedFirmwareUpdateModal from '~/components/utility/FailedFirmwareUpdateModal'
import callGetFunctionalityDescriptions from '~/redux/thunks/device/callGetFunctionalityDescriptions'
import OspinSidebar from './OspinSidebar'
import '~/components/main/Main.css'

const mapStateToProps = state => ({ devices: state.devices })

const mapDispatchToProps = dispatch => ({
  dispatchCallGetFunctionalityDescriptions: () => {
    callGetFunctionalityDescriptions(dispatch)
  },
})

const Main = ({
  dispatchCallGetFunctionalityDescriptions,
  devices,
}) => {

  useEffect(() => {
    dispatchCallGetFunctionalityDescriptions()
  }, [])

  const devicesWithFailedUpdates = devices
    .filter(({ firmwareUpdate }) => {
      if (!firmwareUpdate) return false
      return firmwareUpdate.state === FirmwareUpdate.STATES.FAILED && !firmwareUpdate.seen
    })

  return (
    <div>
      <ConnectionIssueBanner />
      <FailedFirmwareUpdateModal
        open={devicesWithFailedUpdates.length > 0}
        devicesWithFailedUpdates={devicesWithFailedUpdates}
        headerText='Firmware update failed'
      />
      <OspinSidebar />
      <div className='main-content'>
        <Switch>
          <Route exact path='/' component={Devices} />
          <Route exact path='/devices' component={Devices} />
          <Route exact path='/changelog' component={Changelog} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/messages' component={Notifications} />
          <Route exact path='/alerts' component={Alerts} />
          <Route exact path='/devices/:deviceId/configurations/:fctGraphId/processes/:processId' component={Device} />
          <Route exact path='/devices/:deviceId/processes/:processId' component={Device} />
          <Route path='/devices/:deviceId/configurations/:fctGraphId' component={Device} />
          <Route path='/devices/:deviceId' component={Device} />
          <Route to={{ errorCode: '404', pathname: '/404' }} component={ErrorPage} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main))
