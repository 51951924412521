import FirmwareUpdate from '~/utils/device/FirmwareUpdate'

export default class Notification {

  static get TOPICS() {
    return {
      DEVICE_INVITATION: 'device/invitation',
      DEVICE_REVOCATION: 'device/revocation',
      DEVICE_AVAILABLE_FIRMWARE_UPDATE: 'device/available-firmware-update',
      DEVICE_AUTOMATIC_PORT_UPDATE: 'device/automatic-port-update',
      ACCOUNT: 'account',
      CHANGELOG: 'changelog',
    }
  }

  static get TYPES() {
    return {
      EPHEMERAL: 'ephemeral',
      PERSISTENT: 'persistent',
    }
  }

  static get ApplicationLevelNotifications() {
    return [Notification.TOPICS.ACCOUNT, Notification.TOPICS.DEVICE_REVOCATION]
  }

  static get DeviceLevelNotifications() {
    return [Notification.TOPICS.DEVICE_AVAILABLE_FIRMWARE_UPDATE]
  }

  static get FctGraphLevelNotifications() {
    return [Notification.TOPICS.DEVICE_AUTOMATIC_PORT_UPDATE]
  }

  static getInvitationNotifications(notifications) {
    return notifications.filter(note => note.topic.split('/')[1] === 'invitation')
  }

  static getDisplayableDeviceNotifications(user, device) {
    const unfilteredNotifications = Notification.getDeviceLevelEphmeralNotifications(
      user.notifications, device.id,
    )

    return unfilteredNotifications.filter(note => {
      switch (note.topic) {
        case Notification.TOPICS.DEVICE_AVAILABLE_FIRMWARE_UPDATE:
          // eslint-disable-next-line no-case-declarations
          const { firmwareUpdate } = device
          return firmwareUpdate
            && firmwareUpdate.id === note.data.firmwareUpdateId
            && FirmwareUpdate.isAvailable(firmwareUpdate)

        default:
          return true
      }
    })
  }

  static getApplicationLevelEphmeralNotifications(notifications) {
    return notifications.filter(note => (note.type === Notification.TYPES.EPHEMERAL)
      && this.ApplicationLevelNotifications.includes(note.topic))
  }

  static getDeviceLevelEphmeralNotifications(notifications, deviceId) {
    return notifications.filter(note => (note.type === Notification.TYPES.EPHEMERAL)
      && this.DeviceLevelNotifications.includes(note.topic)
      && note.data.deviceId === deviceId)
  }

  static getFunctionalityGraphLevelEphemeralNotifications(notifications, deviceId, fctGraphId) {
    return notifications.filter(note => (note.type === Notification.TYPES.EPHEMERAL)
      && this.FctGraphLevelNotifications.includes(note.topic)
      && note.data.deviceId === deviceId
      && note.data.fctGraphId === fctGraphId)
  }

  static getChangeLogNotifications(notifications) {
    return notifications.filter(note => note.topic === 'changelog')
  }
}
