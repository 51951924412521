import React from 'react'
import { Form, Label, Input, TextArea } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'
import ProcessValidator from '~/utils/validation/ProcessValidator'

const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

const renderNameInput = (newName, updateHandler, isDisabled) => (
  <Form.Field error={(!ProcessValidator.isValidProcessName(newName)
    || ProcessValidator.isProcessNameMinLengthValid(newName))}
  >
    <label htmlFor='processName'>
      Process Name
    </label>
    <Input
      fluid
      placeholder='new process name...'
      value={newName}
      id='processName'
      name='newName'
      onChange={updateHandler}
      data-testid='edit-process-name-field'
      disabled={isDisabled}
    />
    <Label>
      {newName.length}
      /
      {nameLength}
    </Label>
  </Form.Field>
)

const renderCommentInput = (newComment, updateHandler, isDisabled) => (
  <Form.Field error={!ProcessValidator.isValidProcessComment(newComment)}>
    <label htmlFor='processComment'>
      Process Comment
    </label>
    <TextArea
      onChange={updateHandler}
      name='newComment'
      value={newComment}
      data-testid='edit-process-comment-area'
      disabled={isDisabled}
    />
    <Label>
      {newComment.length}
      /
      {commentLength}
    </Label>
  </Form.Field>
)

const EditProcessModalForm = ({ newName, newComment, handleUpdate, isDisabled }) => (
  <>
    {renderNameInput(newName, handleUpdate, isDisabled)}
    {renderCommentInput(newComment, handleUpdate, isDisabled)}
  </>
)

export default formalize(EditProcessModalForm)
