import React, { useState } from 'react'
import { FCTGraph, Slot } from '@ospin/fct-graph'
import { Table, Checkbox, Header, Button } from 'semantic-ui-react'
import uuidv4 from 'uuid/v4'

import { hasUnit } from '~/utils/units'
import { Process } from '~/utils/process'
import Time from '~/utils/Time'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import PhaseProgression from '~/utils/process/PhaseProgression'
import Phase from '~/utils/process/Phase'
import CalibrationOverview from '~/components/processviewer/body/processOverview/CalibrationOverview'
import ChangesOverview from '~/components/processviewer/body/processOverview/ChangesOverview'
import DescriptionParser from '~/utils/process/DescriptionParser'
import FeatureVersioning from '~/utils/FeatureVersioning'
import FlashMessenger from '~/utils/FlashMessenger'
import DownloadRequest from '~/utils/process/DownloadRequest'

import { connect } from 'react-redux'
import callCreateDownloadRequest from '~/redux/thunks/process/callCreateDownloadRequest'
import CSVDownloadModal from './CSVDownloadModal'
import AnnotationsOverviewTable from './AnnotationsOverviewTable'
import PortAssignmentTable from './PortAssignmentTable'
import './ProcessOverview.css'

const mapDispatchToProps = dispatch => ({
  dispatchCallCreateDownloadRequest:
  processId => callCreateDownloadRequest(
    dispatch,
    DownloadRequest.TYPES.PDF_REPORT,
    processId,
  ),
})

const getInputNodesWithConnectingSlots = fctGraph => {
  const inputNodeFcts = FCTGraph.getPushInFcts(fctGraph)
  return inputNodeFcts.map(fct => ({
    inputNodeFctId: fct.id,
    connectingSinkSlot: FCTGraph.getConnectingSinkSlot(fctGraph, fct.id),
    sinkFct: FCTGraph.getSinkFct(fctGraph, fct.id),
  }))
}

const groupAndSortByFunctionality = slotData => (
  slotData.reduce((acc, curr) => {
    const currFct = curr.sinkFct
    const existingEntry = acc.find(({ fct }) => fct.id === currFct.id)
    if (!existingEntry) {
      return [ ...acc, { fct: currFct, slots: [ curr ] } ]
    }
    existingEntry.slots.push(curr)
    return acc
  }, [])
    .map(entry => ({
      ...entry,
      slots: entry.slots
        .sort((a, b) => a.connectingSinkSlot.name.localeCompare(b.connectingSinkSlot.name)),
    }))
    .sort((a, b) => a.fct.name.localeCompare(b.fct.name))
)

const renderDefaultInSlot = ({ connectingSinkSlot, inputNodeFctId }, phases, process) => (
  <>
    <Table.Cell className='configuration-table-stick-first-column-left-on-scrolling column-2'>
      {hasUnit(connectingSinkSlot.unit) ? `${connectingSinkSlot.name} [${connectingSinkSlot.unit}]` : connectingSinkSlot.name}
    </Table.Cell>
    {phases.map(({ id: phaseId }) => {
      const value = DescriptionParser.getInputNodeValue(process, phaseId, inputNodeFctId)
      return <Table.Cell colSpan={2} key={phaseId} textAlign='right'>{value}</Table.Cell>
    })}
  </>
)

const renderBooleanInSlot = ({ connectingSinkSlot, inputNodeFctId }, phases, process) => (
  <>
    <Table.Cell className='configuration-table-stick-first-column-left-on-scrolling column-2'>
      {Slot.getDisplayName(connectingSinkSlot)}
    </Table.Cell>
    {phases.map(({ id: phaseId }) => {
      const value = DescriptionParser.getInputNodeValue(process, phaseId, inputNodeFctId) ? 'on' : 'off'
      return <Table.Cell colSpan={2} key={phaseId} textAlign='right'>{value}</Table.Cell>
    })}
  </>
)

const renderSlotByDataType = (data, phases, activeProcess) => {
  const { connectingSinkSlot } = data
  switch (connectingSinkSlot.dataType) {
    case 'boolean': {
      return renderBooleanInSlot(data, phases, activeProcess)
    }
    default: {
      return renderDefaultInSlot(data, phases, activeProcess)
    }
  }
}

const renderFunctionality = (data, phases, activeProcess) => {
  const { slots, fct } = data

  const renderFctData = () => (
    <Table.Cell className='configuration-table-stick-first-column-left-on-scrolling column-1'>
      {fct.name}
    </Table.Cell>
  )

  return slots.map(slotData => (
    <Table.Row key={fct.id + slotData.connectingSinkSlot.name}>
      {renderFctData()}
      {renderSlotByDataType(slotData, phases, activeProcess)}
    </Table.Row>
  ))
}

const renderRunDurationCell = (process, currentPhase) => {
  const originalPhaseId = PhaseGroupParser.phaseIsOriginal(currentPhase.data)
    ? currentPhase.id
    : currentPhase.data.iterationOf
  const iterIds = PhaseGroupParser.getPhaseIterationIds(process, originalPhaseId)
  const allIds = [ originalPhaseId, ...iterIds ]

  const phaseRunDisplayTimes = allIds.map(iterId => (
    Time.stringFromDuration(
      Process.getPhaseRunTimeInSeconds(process, iterId),
      true,
      '-',
    )
  ))

  return (
    <Table.Cell
      key={currentPhase.id}
      textAlign='right'
      disabled={!PhaseProgression.phaseHasStarted(process, currentPhase.id)}
      colSpan={2}
    >
      { phaseRunDisplayTimes.map((time, idx) => (
        <React.Fragment key={uuidv4()}>
          {time}
          {phaseRunDisplayTimes.length > 1 && ` (cycle ${idx + 1})`}
          <br />
        </React.Fragment>
      )) }
    </Table.Cell>
  )
}

const renderGroupCells = ({ groupName, phaseCount, iterations }, idx) => {
  if (groupName === null) return <Table.Cell key={idx} />
  return (
    <Table.Cell key={groupName} colSpan={phaseCount} textAlign='center'>
      {`${groupName} (x${iterations})`}
    </Table.Cell>
  )
}

const getReducedGroups = phases => (
  phases.reduce((acc, { data: { groupName } }) => {
    if (!groupName) return [ ...acc, { groupName: null, phaseCount: 1 } ]
    const latestEntry = acc[acc.length - 1]

    if (latestEntry && latestEntry.groupName === groupName) {
      latestEntry.phaseCount += 1
      return acc
    }

    return [ ...acc, { groupName, phaseCount: 1 } ]
  }, [])
)

const renderGroups = (process, phases) => {
  const reducedGroups = getReducedGroups(phases)

  const noGroups = reducedGroups.every(({ groupName }) => groupName === null)
  if (noGroups) return null

  const groupsWithIteration = reducedGroups.map(aGroup => {
    if (aGroup.groupName === null) return { ...aGroup, iterations: null }
    return {
      ...aGroup,
      iterations: PhaseGroupParser.getGroupIterationsCount(process, aGroup.groupName),
    }
  })

  return (
    <Table.Row>
      <Table.Cell
        className='configuration-table-stick-first-column-left-on-scrolling column-1'
      >
        Group
      </Table.Cell>
      {groupsWithIteration.map(renderGroupCells)}
    </Table.Row>
  )
}

const renderDescription = phases => {
  const hasPhaseDescription = phases.some(({ data }) => data.description !== '')

  if (!hasPhaseDescription) {
    return null
  }

  return (
    <Table.Row className='phase-description-line-breaks'>
      <Table.Cell
        colSpan={2}
        className='configuration-table-stick-first-column-left-on-scrolling column-1'
      >
        Description
      </Table.Cell>
      {phases.map(({ data: phase, id }) => (
        <Table.Cell
          colSpan={2}
          key={id}
          textAlign='right'
        >
          {phase.description ? phase.description : '-' }
        </Table.Cell>
      ))}
    </Table.Row>
  )

}

const ProcessOverview = props => {

  const { phases, activeProcess, hasSyncedPorts, dispatchCallCreateDownloadRequest } = props

  const [ showControllerParameters, toggleShowControllerParameters ] = useState(false)
  const [ isPopupDownloadOpen, setIsPopupDownloadOpen ] = useState(false)
  const [ waitingForAPIResponse, setWaitingForAPIResponse ] = useState(false)

  const { fctGraph } = activeProcess

  const togglePopup = () => {
    setIsPopupDownloadOpen(!isPopupDownloadOpen)
  }

  const pdfReportRequest = async () => {
    setWaitingForAPIResponse(true)

    try {
      await dispatchCallCreateDownloadRequest(activeProcess.id)
      FlashMessenger.info('Your download will start once ready and will also be available in the "Downloads" section')
    } catch (_) {
      FlashMessenger.error('Your download request failed. If this issue persist, contact the OSPIN support team.')
    } finally {
      setWaitingForAPIResponse(false)
    }
  }

  const slotsData = groupAndSortByFunctionality(getInputNodesWithConnectingSlots(fctGraph)
    .filter(({ connectingSinkSlot }) => (connectingSinkSlot.displayType !== 'controller parameter'
    || (connectingSinkSlot.displayType === 'controller parameter' && showControllerParameters))))

  return (
    <div>
      <Header>
        Phases
        <div style={{ float: 'right' }}>
          <Button
            primary
            compact
            onClick={togglePopup}
          >
            Download Phases Overview
          </Button>
          {isPopupDownloadOpen && (
            <CSVDownloadModal
              open
              closeHandler={togglePopup}
              slotsData={slotsData}
              activeProcess={activeProcess}
              setIsPopupDownloadOpen={setIsPopupDownloadOpen}
              isPopupDownloadOpen={isPopupDownloadOpen}
              headerText='Download Process Overview'
            />
          )}
        </div>
      </Header>
      <div style={{ overflow: 'auto' }}>
        <div style={{ scroll: 'auto', paddingBottom: '24px' }}>
          <Table striped celled className='configuration-table ospin-red' collapsing structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ width: '200px' }}
                  colSpan={2}
                  className='configuration-table-stick-first-column-left-on-scrolling column-1'
                >
                  <Checkbox
                    toggle
                    label='Show Controller Parameters'
                    onChange={() => toggleShowControllerParameters(!showControllerParameters)}
                    checked={showControllerParameters}
                  />
                </Table.HeaderCell>
                {phases
                  .map(({ data: phase, id }) => (
                    <Table.HeaderCell
                      style={{ width: '200px' }}
                      key={id}
                      colSpan={2}
                    >
                      {phase.name}
                    </Table.HeaderCell>
                  ))}
              </Table.Row>
            </Table.Header>
            <Table.Body className='configuration-table-body'>
              {renderGroups(activeProcess, phases)}
              {renderDescription(phases)}
              <Table.Row>
                <Table.Cell
                  colSpan={2}
                  className='configuration-table-stick-first-column-left-on-scrolling column-1'
                >
                  Transition
                </Table.Cell>
                {phases.map(({ data: phase, id }) => <Table.Cell colSpan={2} key={id} textAlign='right'>{phase.transition}</Table.Cell>)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  colSpan={2}
                  className='configuration-table-stick-first-column-left-on-scrolling column-1'
                >
                  Target Duration
                </Table.Cell>
                {phases.map(({ data: phase, id }) => {
                  const disabled = !Phase.isTimeBased(phase)
                  return (
                    <Table.Cell
                      disabled={disabled}
                      key={id}
                      textAlign='right'
                      colSpan={2}
                    >
                      {Time.stringFromDuration(phase.duration, true, '-')}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  colSpan={2}
                  className='configuration-table-stick-first-column-left-on-scrolling column-1'
                >
                  Run Duration
                </Table.Cell>
                {phases.map(phase => renderRunDurationCell(activeProcess, phase))}
              </Table.Row>
              {slotsData.map(data => renderFunctionality(data, phases, activeProcess))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <Header>Annotations</Header>
        <AnnotationsOverviewTable
          activeProcess={activeProcess}
        />
      </div>
      <div style={{ marginTop: '40px' }}>
        <Header>Calibrations</Header>
        <CalibrationOverview
          activeProcess={activeProcess}
        />
      </div>
      { FeatureVersioning.supportsMultipleProcesses(activeProcess)
      && (
        <div style={{ marginTop: '40px' }}>
          <PortAssignmentTable
            activeProcess={activeProcess}
            hasSyncedPorts={hasSyncedPorts}
          />
        </div>
      )}
      {!Process.isExecutable(activeProcess)
        && (
          <div style={{ marginTop: '40px' }}>
            <Header>Changes during running process</Header>
            <ChangesOverview
              activeProcess={activeProcess}
            />
          </div>
        )}
      <div style={{ marginTop: '40px' }}>
        <Header>Process Report</Header>
        <div>
          <Button
            primary
            compact
            loading={waitingForAPIResponse}
            disabled={waitingForAPIResponse}
            onClick={pdfReportRequest}
          >
            Download Process Report
          </Button>
        </div>
      </div>

    </div>
  )
}

export default connect(null, mapDispatchToProps)(ProcessOverview)
