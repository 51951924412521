import React from 'react'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import SensorCalibration from '~/utils/sensors/SensorCalibration'
import { Table } from 'semantic-ui-react'
import Time from '~/utils/Time'
import { replaceNoUnitCharacter } from '~/utils/units'
import NoDataMessage from '~/components/utility/NoDataMessage'

const TABLE_HEADERS = [
  'Functionality',
  'Channel',
  'Status',
  'Created',
  'Ended',
  'Offset',
  'Slope',
]

const getAllCalibratedSlots = calibrations => {
  const res = []

  calibrations.forEach(cal => {
    const { fctId: currFctId, slotName: currSlotName } = cal
    if (!res.some(({ fctId, slotName }) => fctId === currFctId && slotName === currSlotName)) {
      res.push({ fctId: currFctId, slotName: currSlotName })
    }
  })

  return res
}

const sortBySensorAndTime = calibrations => {
  const allCalibratedSlots = getAllCalibratedSlots(calibrations)

  const res = []

  allCalibratedSlots.forEach(({ fctId, slotName }) => {
    const sensorCalibs = SensorCalibration
      .getAllByFctIdAndSlot(calibrations, fctId, slotName)
      .sort((a, b) => b.createdAt - a.createdAt)

    res.push({ fctId, slotName, sortedEntries: sensorCalibs })
  })

  return res
}

const renderExtraCell = (count, fctName) => (
  <Table.Cell rowSpan={count}>{fctName}</Table.Cell>
)

const renderCreatedAtCell = (createdAt, process) => {
  const { startedAt } = process
  const createdBeforeProcessStart = startedAt === null ? true : (startedAt > createdAt)
  const messageSuffix = createdBeforeProcessStart ? '(initial)' : ''

  return (
    <Table.Cell>
      {Time.getTrimmedDateAndTimeString(createdAt)} {messageSuffix}
    </Table.Cell>
  )
}

const renderEntries = (entries, process) => (
  entries.map(({ fctId, slotName, sortedEntries }) => (
    sortedEntries.map((calib, idx) => {
      const { id, createdAt, endedAt, params: { offset, slope } } = calib
      const fct = FCTGraph.getFctById(process.fctGraph, fctId)
      const slot = Functionality.getSlotByName(fct, slotName)
      const isActive = SensorCalibration.isActive(calib)
      const first = idx === 0

      return (
        <Table.Row key={id} verticalAlign={first ? 'top' : 'middle'}>
          {first && renderExtraCell(sortedEntries.length, fct.name)}
          <Table.Cell>{Slot.getDisplayName(slot)}</Table.Cell>
          <Table.Cell positive={isActive}>{isActive ? 'active' : 'inactive' }</Table.Cell>
          {renderCreatedAtCell(createdAt, process)}
          <Table.Cell>{endedAt ? Time.getTrimmedDateAndTimeString(endedAt) : '-'}</Table.Cell>
          <Table.Cell>{offset.value.toFixed(3)} {replaceNoUnitCharacter(slot.unit, '')}</Table.Cell>
          <Table.Cell>{slope.value.toFixed(3)}</Table.Cell>
        </Table.Row>
      )
    })
  ))
)

const CalibrationOverview = ({ activeProcess }) => {

  const { calibrations } = activeProcess

  if (!calibrations.length) return <NoDataMessage />

  return (
    <Table celled structured className='ospin-red'>
      <Table.Header>
        <Table.Row>
          {TABLE_HEADERS.map(header => <Table.HeaderCell key={header}>{header}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {renderEntries(sortBySensorAndTime(calibrations), activeProcess)}
      </Table.Body>
    </Table>
  )
}

export default CalibrationOverview
