class DownloadRequest {

  static get TYPES() {
    return {
      PDF_REPORT: 'PDF_REPORT',
      SENSOR_DATA: 'SENSOR_DATA',
    }
  }

  static get STATES() {
    return {
      READY: 'ready',
      PROCESSING: 'processing',
      FAILED: 'failed',
    }
  }

  static isPDFReportRequest(request) {
    return !('reporterFctIds' in request)
  }
}

export default DownloadRequest
