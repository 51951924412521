import React, { useState, createRef } from 'react'
import { Button, Input, Table, Grid } from 'semantic-ui-react'
import './UserTable.css'

const tableFields = ({ userName, fullName, organization, email, phoneNumber }) => ([
  {
    field: 'userName',
    fieldDisplay: 'Username',
    value: userName,
    editable: false,
  },
  {
    field: 'fullName',
    fieldDisplay: 'Name',
    value: fullName,
    editable: true,
  },
  {
    field: 'organization',
    fieldDisplay: 'Organization',
    value: organization,
    editable: true,
  },
  {
    field: 'email',
    fieldDisplay: 'Email',
    value: email,
    editable: false,
  },
  {
    field: 'phoneNumber',
    fieldDisplay: 'Phone',
    value: phoneNumber,
    editable: true,
  },
])

const valueStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const renderRows = (user, updateUser, activeField, setActiveField) => (
  tableFields(user).map(({ field, fieldDisplay, value, editable }) => {

    const active = field === activeField
    const inputRef = createRef()
    const displayButton = !active && editable
    const inputClasses = active
      ? 'user-table-input user-table-text-cursor'
      : 'user-table-input user-table-default-cursor'

    return (
      <Table.Row key={field}>
        <Table.Cell>{fieldDisplay}</Table.Cell>
        <Table.Cell
          onClick={() => {
            if (!editable) return
            setActiveField(field)
            inputRef.current.focus()
          }}
          style={valueStyle}
        >
          <Input
            data-testid={`profile-table-${field}-input-field`}
            ref={inputRef}
            transparent
            style={{ opacity: 1, flexGrow: 1 }}
            disabled={!editable}
            className={inputClasses}
            defaultValue={value}
            onBlur={({ target }) => {
              setActiveField(null)
              updateUser({ [field]: target.value })
            }}
          />
          { displayButton && (
            <Button
              data-testid={`profile-table-${field}-edit-button`}
              className='user-table-edit-button'
              size='small'
              icon='edit'
              style={{ backgroundColor: 'white' }}
            />
          ) }
        </Table.Cell>
      </Table.Row>
    )
  })
)

const UserTable = ({ user, updateUser }) => {
  const [ activeField, setActiveField ] = useState(null)

  return (
    <Table celled definition compact className='ospin-red'>
      <Table.Body>
        { renderRows(user, updateUser, activeField, setActiveField) }
      </Table.Body>
    </Table>
  )
}

export default UserTable
