import nexus from '@ospin/nexus'
import store from '~/redux/store'
import ObjectTools from '~/utils/ObjectTools'
import {
  updateProcess,
  updateUser,
  updateDevice,
} from '~/redux/actions/actions'
import {
  isInDeviceView,
  isInProcessView,
  parseDeviceIdFromURL,
  parseProcessIdFromURL,
} from '~/utils/navigation'

const pickUserProps = userData => {
  const keys = ['events']
  return ObjectTools.pickKeys(userData, keys)
}

const pickDeviceProps = deviceData => {
  const keys = [
    'online',
    'runningProcesses',
    'fctGraphs',
    'firmwareUpdate',
    'firmwareVersion',
    'fctGraphTemplates',
    'connectedPhysicalFunctionalities',
    'supportedVirtualFunctionalities',
  ]
  return ObjectTools.pickKeys(deviceData, keys)
}

const pickProcessProps = processData => {
  const keys = [
    'state',
    'progression',
    'startedAt',
    'startedBy',
    'finishedAt',
  ]
  return ObjectTools.pickKeys(processData, keys)
}

/** we call this function after Pusher has registered a reconnect;
/* its task is to update possibly missed data that is critical */

const synchronizePossiblyMissedUpdates = async () => {
  try {
    const { user } = store.getState()

    if (!user.id) return

    const promises = [ nexus.user.get(user.id) ]

    const lookingAtDevice = isInDeviceView()
    if (lookingAtDevice) {
      const deviceId = parseDeviceIdFromURL()
      promises.push(nexus.user.device.get({ userId: user.id, deviceId }))
    }

    const lookingAtProcess = isInProcessView()
    if (lookingAtProcess) {
      const processId = parseProcessIdFromURL()
      promises.push(nexus.process.get(processId))
    }

    const res = await Promise.all(promises)

    const userData = res[0].data
    store.dispatch(updateUser(pickUserProps(userData)))

    if (lookingAtDevice) {
      const deviceData = res[1].data
      store.dispatch(updateDevice({ deviceId: deviceData.id, params: pickDeviceProps(deviceData) }))
    }

    if (lookingAtProcess) {
      const processData = res[2].data
      store.dispatch(updateProcess({
        processId: processData.id,
        params: pickProcessProps(processData),
      }))
    }
  } catch (_) {
    // catching errors as we don't want this convenience feature to break the app
  }
}

export default synchronizePossiblyMissedUpdates
