import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Table, Icon } from 'semantic-ui-react'
import callModifyDeviceAccess from '~/redux/thunks/device/callModifyDeviceAccess'

import { getGroupUserIds } from '~/utils/access'
import Authorizer from '~/utils/Authorizer'
import User from './User'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallModifyDeviceAccess: (deviceId, userId, newGroup, oldGroup) =>
    callModifyDeviceAccess(dispatch, deviceId, userId, newGroup, oldGroup),
})

const usersTableHeaderItems = [
  { header: 'Username', sortKey: 'userName' },
  { header: 'Group', sortKey: 'accessGroup' },
  { header: '', sortKey: null },
]

const UserTable = ({
  activeDevice,
  dispatchCallModifyDeviceAccess,
  user,
  deviceUsers,
}) => {
  const [pendingRequests, setPendingRequests] = useState([])
  const [sortState, setSortState] = useState({ sortKey: usersTableHeaderItems[1].sortKey, sortOrder: 'DESC', initial: true })

  const addPendingRequest = request => {
    const updatedRequests = [ ...pendingRequests, request ]
    setPendingRequests(updatedRequests)
  }

  const removePendingRequest = request => {
    const updatedPendingRequests = pendingRequests
      .filter(currentRequest => !(request.userId === currentRequest.userId
      && request.action === currentRequest.action))

    setPendingRequests(updatedPendingRequests)
  }

  const modifyAccess = async (userId, newGroup) => {

    const oldGroup = activeDevice.access.find(group => group.users.includes(userId)).name

    if (newGroup === oldGroup || newGroup === '') {
      return
    }
    const request = { userId, action: 'modifyAccess' }
    addPendingRequest(request)

    /* TODO: ADD_ERROR_HANDLING */
    await dispatchCallModifyDeviceAccess(activeDevice.id, userId, newGroup, oldGroup)

    removePendingRequest(request)
  }

  const getAccessGroup = user => {
    if (Authorizer.user(user).isOwnerOf(activeDevice)) {
      return 'a owner' // to be earlier than "admin"
    }
    return Authorizer.getUserGroup(activeDevice, user.id).name
  }

  const getSortIcon = sortKey => {
    const querySortBy = sortState.sortKey

    if (!sortKey || sortKey !== querySortBy) { return null }

    return (
      <Icon
        name={sortState.sortOrder === 'ASC' ? 'sort ascending' : 'sort descending'}
        className='processes-table-sort-icon'
      />
    )
  }

  const toggleSortOrder = querySortOrder => (
    querySortOrder === 'ASC' ? 'DESC' : 'ASC'
  )

  const changeSortState = sortkey => {
    setSortState( {
      sortOrder: sortState.sortKey === sortkey
        ? toggleSortOrder(sortState.sortOrder)
        : sortState.sortOrder,
      sortKey: sortkey,
      initial: false,
    })
  }

  const getOrder = (sortOrder, direction) => (
    sortOrder === 'ASC' ? direction * -1 : direction * 1
  )

  const compare = (a, b) => {
    const comp = a[sortState.sortKey].localeCompare(b[sortState.sortKey])
    if (comp !== 0) {
      return getOrder(sortState.sortOrder, comp)
    }
    return sortState.initial ? a.userName.localeCompare(b.userName) : 0

  }
  const supportUserIds = getGroupUserIds(activeDevice, 'support')
  const excludingSupportUsers = deviceUsers.filter(({ id }) => (
    !supportUserIds.includes(id)
  )).map(obj => ({ ...obj, accessGroup: getAccessGroup(obj) })).sort(compare)

  return (
    <Table className='ospin-red' celled selectable padded sortable>
      <Table.Header fullWidth>
        <Table.Row>
          {
            usersTableHeaderItems.map(({ header, sortKey }) => (
              <Table.HeaderCell
                key={header}
                onClick={sortKey ? () => changeSortState(sortKey) : null}
                className={sortKey ? 'processes-table-clickable-header' : 'processes-table-non-clickable-header'}
              >
                { header }
                { getSortIcon(sortKey) }
              </Table.HeaderCell>
            ))
          }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {excludingSupportUsers.map(deviceUser => (
          <User
            activeDevice={activeDevice}
            key={deviceUser.id}
            user={deviceUser}
            me={user}
            modifyAccessHandler={modifyAccess}
            pendingRequests={pendingRequests}
            addPendingRequest={addPendingRequest}
            removePendingRequest={removePendingRequest}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTable)
