import React, { useState } from 'react'
import { Modal, Segment } from 'semantic-ui-react'
import DashboardDetailsModalMenu from './DashboardDetailsModalMenu'
import DeleteDashboardFormContainer from './DeleteDashboardFormContainer'
import SaveDashboardModal from './SaveDashboardModal'

const MODAL_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
}

const MODAL_TITLES = {
  edit: 'Edit Dashboard Details',
  create: 'Create Dashboard',
}

const SaveDashboardModalContainer = props => {
  const {
    initialTab,
    dashboard,
    openSaveDashboardModalByType,
    setSelectedDashboardId,
    closeModal,
    isDeleteForbidden,
  } = props

  const [activeTab, setActiveTab] = useState(initialTab)

  const handleActiveTab = tab => {
    setActiveTab(tab)
  }

  function renderContent() {
    // eslint-disable-next-line default-case
    switch (openSaveDashboardModalByType) {
      case MODAL_TYPES.CREATE:
        return (
          <SaveDashboardModal
            closeHandler={closeModal}
            setSelectedDashboardId={setSelectedDashboardId}
            modalType={openSaveDashboardModalByType}
          />
        )
      case MODAL_TYPES.EDIT:
        return (
          <>
            <DashboardDetailsModalMenu
              activeTab={activeTab}
              handleActiveTab={handleActiveTab}
              disableDelete={isDeleteForbidden}
            />
            <Segment attached='bottom' className='no-border'>
              {activeTab === 'Edit' && (
                <SaveDashboardModal
                  dashboard={dashboard}
                  closeHandler={closeModal}
                  setSelectedDashboardId={setSelectedDashboardId}
                  modalType={openSaveDashboardModalByType}
                />
              )}
              {activeTab === 'Delete' && (
                <DeleteDashboardFormContainer
                  closeHandler={closeModal}
                  setSelectedDashboardId={setSelectedDashboardId}
                  dashboard={dashboard}
                />
              )}
            </Segment>
          </>
        )
    }
  }

  return (
    <Modal
      open={!!openSaveDashboardModalByType}
      onClose={closeModal}
    >
      <Modal.Header>
        {MODAL_TITLES[openSaveDashboardModalByType] || null}
      </Modal.Header>
      <Modal.Content>
        {renderContent()}
      </Modal.Content>
    </Modal>
  )
}

export default SaveDashboardModalContainer
