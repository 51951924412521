import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'
import PhaseGroupParser from '~/utils/process/PhaseGroupParser'
import Validator from '~/utils/validation/Validator'
import DescriptionParser from '~/utils/process/DescriptionParser'
import { Process } from '~/utils/process'
import { findAndUpdate } from '~/redux/helper/updateFunctions'

const getProxyDisplayedPhaseId = (state, action) => {
  const { processes, displayedPhaseId } = state
  const { processId, groupName } = action.payload

  const process = Process.getById(processes, processId)
  const phase = DescriptionParser
    .getPhaseById(process, displayedPhaseId)

  if (!phase) return

  const { iterationOf, groupName: phaseGroupName } = phase

  if (!phaseGroupName
    || phaseGroupName !== groupName
    || Validator.isUndefinedOrNull(iterationOf)
  ) return

  const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(process, iterationOf)
  const currentDisplayedIndex = phaseIterationIds
    .findIndex(aPhaseId => aPhaseId === displayedPhaseId)

  if (currentDisplayedIndex === 0) return iterationOf

  return phaseIterationIds[currentDisplayedIndex - 1]
}

export default (state, action) => {
  const { processId, groupName, count } = action.payload
  const { processes, displayedPhaseId } = state

  const proxyPhaseId = getProxyDisplayedPhaseId(state, action)

  const updateFn = process => PhaseGroupHandler.setGroupIterationsCount(process, groupName, count)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  const updatedProcess = Process.getById(processes, processId)
  const displayedPhaseDeleted = !DescriptionParser.hasPhaseWithId(updatedProcess, displayedPhaseId)

  const newDisplayedPhase = displayedPhaseDeleted && proxyPhaseId !== undefined
    ? proxyPhaseId
    : displayedPhaseId

  return { ...state, processes: updatedProcesses, displayedPhaseId: newDisplayedPhase }
}
