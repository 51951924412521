import React, { useState } from 'react'
import { Button, Divider, Form, Message, Modal } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateDevice } from '~/redux/actions/actions'
import { Functionality, Ports } from '@ospin/fct-graph'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const mapDispatchToProps = dispatch => (
  { dispatchUpdateDevice: (deviceId, params) => dispatch(updateDevice({ deviceId, params })) }
)

function cloneLicenceTemplateNotice() {
  return <Message info content='This Template is based on a default template, to make any changes this template will be cloned under a new name' />
}

function generateNewDeviceData(device, template, graphs) {
  const filteredFctGraphs = device.fctGraphs.filter(({ templateId }) => template.id !== templateId)
  const filteredTemplates = device.fctGraphTemplates.filter(({ id }) => id !== template.id)
  return {
    fctGraphs: [...filteredFctGraphs, ...graphs],
    fctGraphTemplates: [...filteredTemplates, template],
  }
}

function generateFctGraphOptions(graph) {
  const { functionalities } = graph
  const graphPorts = functionalities.filter(
    Functionality.isPhysical,
  ).map(({ ports }) => Ports.getId(ports))

  return `${graph.name} - ${graphPorts.join()}`
}

function SaveTemplateModal({
  open,
  close,
  setTemplateAndRerender,
  template,
  layout,
  dispatchUpdateDevice,
  activeDevice,
}) {
  const [templateName, setTemplateName] = useState(template.name)
  const [ error, setError ] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [wasSuccesfullySaved, setWasSuccessfullySaved] = useState(false)
  const [createdGraphs, setCreatedGraphs] = useState([])
  const { deviceId } = useParams()
  const history = useHistory()

  const createdFromLicenceTemplate = !template.deviceId
  const isCreatingNew = !template.id || createdFromLicenceTemplate

  async function updateTemplate ({ name, connections, functionalities }) {
    const templateData = {
      functionalities,
      connections,
      name,
    }
    const {
      data: {
        template: createdTemplate,
        fctGraphs,
      },
    } = await nexus.deviceDescription.template.update(template.id, { templateData })

    return { fctGraphs, template: createdTemplate }
  }

  async function createTemplate({ name, connections, functionalities }) {
    const {
      data: {
        template: createdTemplate,
        fctGraphs,
      },
    } = await nexus.deviceDescription.template.create({
      deviceId,
      template: {
        name,
        functionalities,
        connections,
      },
    })

    return { fctGraphs, template: createdTemplate }
  }

  async function save() {
    setIsLoading(true)
    const { functionalities, connections } = template

    const handler = isCreatingNew ? createTemplate : updateTemplate

    try {
      const {
        fctGraphs,
        template: savedTemplate,
      } = await handler({ name: templateName, functionalities, connections })

      await nexus.deviceDescription.template.putLayout(savedTemplate.id, { layout })
      if (isCreatingNew) {
        history.push(`/devices/${deviceId}/setups/${savedTemplate.id}`)
      }

      setIsLoading(false)
      dispatchUpdateDevice(
        activeDevice.id,
        generateNewDeviceData(activeDevice, savedTemplate, fctGraphs),
      )
      setCreatedGraphs(fctGraphs)
      setTemplateAndRerender(savedTemplate, layout)
      setWasSuccessfullySaved(true)
    } catch ({ message }) {
      setError(message)
    }
  }

  function renderInitialSaveDialog() {
    return (
      <>
        {createdFromLicenceTemplate ? cloneLicenceTemplateNotice() : null}
        <Form.Input fluid label='Template Name' value={templateName} onChange={(_, { value }) => setTemplateName(value)} />
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button content='Cancel' onClick={() => close()} />
          <Button primary content='Save' loading={isLoading} onClick={() => save()} />
        </div>
      </>
    )
  }

  function renderFollowUpDialog() {
    if (!createdGraphs.length) {
      return 'The Setup was saved but there are not enough connected devices to use it, it will be automatically made available once enough devices have been connected'
    }

    return (
      <>
        <h4>The following Setups are now available</h4>
        <br />
        {createdGraphs.map(g => (
          <Link
            key={g.id}
            to={`/devices/${deviceId}/configurations/${g.id}`}
          >
            <Button content={generateFctGraphOptions(g)} />
          </Link>
        ))}
      </>
    )
  }

  function renderModalDescription() {
    if (error) {
      return <Message error content={error} />
    }

    return wasSuccesfullySaved ? renderFollowUpDialog() : renderInitialSaveDialog()
  }

  return (
    <Modal
      open={open}
      onClose={close}
      onClick={event => event.stopPropagation()}
    >
      {' '}
      <Modal.Header>
        { isCreatingNew ? 'Create Template' : 'Update Template' }
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {renderModalDescription()}
        </Modal.Description>
      </Modal.Content>

    </Modal>
  )
}
export default connect(null, mapDispatchToProps)(SaveTemplateModal)
