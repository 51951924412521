import uuidv4 from 'uuid/v4'

const LIGHT_GREY = '#7E7F82'

const generateDisplayDefaults = () => ({
  position: { x: 0, y: 0 },
  color: LIGHT_GREY,
})

const generateDefaults = () => ({ id: uuidv4() })

const updatePosition = (widget, { x, y }) => ({
  ...widget,
  display: {
    ...widget.display,
    position: { x, y },
  },
})

const updateDimensions = (widget, { width, height }) => ({
  ...widget,
  display: {
    ...widget.display,
    dimension: {
      ...widget.display.dimension,
      width,
      height,
    },
  },
})

export default {
  generateDisplayDefaults,
  generateDefaults,
  updateDimensions,
  updatePosition,
}
