import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DeleteProcessModalForm from '~/components/device/modals/DeleteProcessModalForm'
import nexus from '@ospin/nexus'
import FlashMessenger from '~/utils/FlashMessenger'
import { deleteProcesses } from '~/redux/actions/actions'
import Authorizer from '~/utils/Authorizer'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchDeleteProcesses: ids => dispatch(deleteProcesses(ids)),
})

const DeleteProcessFormContainer = props => {
  const {
    processesToDelete,
    closeHandler,
    history,
    match,
    dispatchDeleteProcesses,
    requiresRedirect,
    requiresReload,
    reloadFunction,
    user,
    activeDevice,
  } = props

  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ pending, setPending ] = useState(false)

  const isUserAllowedToDeleteProcess = processesToDelete.every(process => {
    return Authorizer.userHasSuperProcessAccess(process, activeDevice, user)
  })

  const handleDeleteProcess = async e => {
    const processIds = processesToDelete.map(process => process.id)
    e.stopPropagation()
    try {
      setPending(true)
      setIsError(false)

      /* TODO: ADD_ERROR_HANDLING */
      await nexus.process.deleteMany(processIds)
      if (requiresRedirect) {
        const { params: { fctGraphId } } = match
        if (fctGraphId) {
          history.push(`/devices/${processesToDelete[0].deviceId}/configurations/${fctGraphId}/processes`)
        } else {
          history.push(`/devices/${processesToDelete[0].deviceId}/processes`)
        }
      }

      dispatchDeleteProcesses(processIds)
      FlashMessenger.success(`Process${processesToDelete.length > 1 ? 'es' : ''} successfully deleted.`)

      if (requiresReload) {
        reloadFunction()
      }

    } catch (apiError) {
      const apiErrorMsg = apiError.status === 403
        ? 'Operation failed. No sufficient access rights to delete process.'
        : 'Failed to delete process.'

      setPending(false)
      setErrorMessage(apiErrorMsg)
      setIsError(true)
    }
  }

  return (
    <DeleteProcessModalForm
      isError={isError}
      errorMessage={errorMessage}
      closeHandler={closeHandler}
      submitHandler={e => handleDeleteProcess(e)}
      processesToDelete={processesToDelete}
      loading={pending}
      closeOnSubmit
      disableSubmit={!isUserAllowedToDeleteProcess}
    />
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteProcessFormContainer))
