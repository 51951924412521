import nexus from '@ospin/nexus'
import React from 'react'
import { connect } from 'react-redux'
import modalize from '~/components/utility/modal/modalize'
import { Message } from 'semantic-ui-react'
import PDVC from '~/utils/PDVC'
import NextPhaseModalForm from '~/components/processviewer/modals/NextPhaseModalForm'

const mapStateToProps = ({ processDescriptionSnapshots }) => ({ processDescriptionSnapshots })

class NextPhaseModal extends React.Component {

  state = {
    isError: false,
    errorMessage: '',
    pending: false,
  }

  moveToNextPhase = async () => {
    const { activeProcess, closeHandler } = this.props

    this.setState({ pending: true })
    try {
      await nexus.command.device.process
        .goToNextPhase(activeProcess.deviceId, activeProcess.id)
      closeHandler()
    } catch (e) {
      this.setState({ isError: true, errorMessage: e.message, pending: false })
    }
  }

  renderUnappliedChangesWarning = diffs => {
    if (diffs.length === 0) return null
    return (
      <Message
        warning
        content={'You have unapplied changes in your process.'
          + 'The changes will be lost when you transition into a new phase.'}
      />
    )
  }

  render() {

    const { isError, errorMessage, pending } = this.state
    const { activeProcess, processDescriptionSnapshots, closeHandler } = this.props
    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    return (
      <>
        {this.renderUnappliedChangesWarning(diffs)}
        <NextPhaseModalForm
          isError={isError}
          errorMessage={errorMessage}
          closeHandler={closeHandler}
          submitHandler={this.moveToNextPhase}
          submitText='Confirm'
          loading={pending}
        />
      </>
    )
  }
}

export default modalize(connect(mapStateToProps)(NextPhaseModal))
