import React from 'react'
import {
  Table,
  Button,
  Icon,
  Dropdown,
  Segment,
} from 'semantic-ui-react'
import Authorizer from '~/utils/Authorizer'
import NoDataMessage from '~/components/utility/NoDataMessage'

const hasPendingRequest = (pendingRequests, targetUserId, targetType) => (
  pendingRequests.some(({ userId, type }) => userId === targetUserId && type === targetType)
)

const renderGroupName = ({ groupName }) => (
  <span style={{ color: '#A6A6A6' }}>{groupName}</span>
)

const renderModifyAccessDropdown = (
  user,
  modifyAccess,
  groupOptions,
  pendingRequests,
  loggedInUser,
  process,
) => (
  !Authorizer.isOwnerOf(process, user.id)
  && !Authorizer.isCurrentLoggedInUser(loggedInUser, user.id)
    ? (
      <Dropdown
        onChange={(_, { value }) => modifyAccess(user.id, value)}
        defaultValue={user.groupName}
        options={groupOptions}
        loading={hasPendingRequest(pendingRequests, user.id, 'update')}
        disabled={hasPendingRequest(pendingRequests, user.id, 'update')}
      />
    ) : renderGroupName(user)
)

const ProcessUserTable = ({
  pendingRequests,
  processUsers,
  loggedInUser,
  modifyAccess,
  withDrawAccess,
  groupOptions,
  IsDeviceOrProcessAdmin,
  process,
  loadingUsers,
  isUserAuthorizedToRevokeOwnAccess,
}) => {

  if (loadingUsers) {
    return <Segment loading style={{ border: 'none', boxShadow: 'none' }} />
  }

  if (processUsers.length === 0) return <NoDataMessage text='No users present' />

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>username</Table.HeaderCell>
          <Table.HeaderCell>group</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {processUsers.map(user => (
          <Table.Row key={user.id}>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell>
              {IsDeviceOrProcessAdmin
                ? renderModifyAccessDropdown(
                  user,
                  modifyAccess,
                  groupOptions,
                  pendingRequests,
                  loggedInUser,
                  process,
                )
                : renderGroupName(user)}
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Button
                primary
                onClick={e => withDrawAccess(e, user.id)}
                icon
                size='small'
                loading={hasPendingRequest(pendingRequests, user.id, 'revoke')}
                disabled={
                  (!isUserAuthorizedToRevokeOwnAccess && !IsDeviceOrProcessAdmin)
                  || hasPendingRequest(pendingRequests, user.id, 'revoke')
                  || Authorizer.isOwnerOf(process, user.id)
                }
              >
                <Icon name='user times' />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default ProcessUserTable
