import { linear } from '~/utils/math'
import CalibrationMode from '~/utils/calibration/CalibrationMode'
import DataManager from '~/utils/DataManager'

class LinearCalibrationSelectionMode extends CalibrationMode {

  static calculateParams(componentState) {
    const { pointSelectionAtoP } = componentState

    const {
      firstIntervalAverageReal,
      secondIntervalAverageReal,
      firstIntervalReference,
      secondIntervalReference,
    } = pointSelectionAtoP

    const parsedFirstIntervalRef = parseFloat(firstIntervalReference)
    const parsedSecondIntervalRef = parseFloat(secondIntervalReference)

    if (Number.isNaN(parsedFirstIntervalRef)) {
      return { error: 'First interval value is not a number.' }
    }

    if (Number.isNaN(parsedSecondIntervalRef)) {
      return { error: 'Second interval value is not a number.' }
    }

    if (parsedFirstIntervalRef === parsedSecondIntervalRef) {
      return { error: 'First and second interval values may not be equal.' }
    }

    if (secondIntervalAverageReal === firstIntervalAverageReal) {
      return { error: 'First and second interval averages may not be equal.' }
    }

    let p1 = [firstIntervalAverageReal, parsedFirstIntervalRef]
    let p2 = [secondIntervalAverageReal, parsedSecondIntervalRef]

    if (parsedFirstIntervalRef > parsedSecondIntervalRef) {
      [p1, p2] = [p2, p1]
    }

    return { offset: linear.offset(p1, p2), slope: linear.slope(p1, p2) }
  }

  static selectParams({ offset, slope }) {
    return { offset: { value: offset }, slope: { value: slope } }
  }

  static getNextField(currField) {
    const nextFields = {
      firstIntervalStart: 'firstIntervalEnd',
      firstIntervalEnd: 'secondIntervalStart',
      secondIntervalStart: 'secondIntervalEnd',
    }

    return nextFields[currField]
  }

  static getUpdatedPointSelection(componentState, time) {
    const { pointSelectionAtoP, selectedField } = componentState

    if (selectedField === 'firstIntervalEnd'
      && pointSelectionAtoP.firstIntervalStart !== '') {
      if (time <= pointSelectionAtoP.firstIntervalStart) {
        return
      }
    }

    if (selectedField === 'firstIntervalStart'
      && pointSelectionAtoP.firstIntervalEnd !== '') {
      if (time >= pointSelectionAtoP.firstIntervalEnd) {
        return
      }
    }

    if (selectedField === 'secondIntervalEnd'
      && pointSelectionAtoP.secondIntervalStart !== '') {
      if (time <= pointSelectionAtoP.secondIntervalStart) {
        return
      }
    }

    if (selectedField === 'secondIntervalStart'
      && pointSelectionAtoP.secondIntervalEnd !== '') {
      if (time >= pointSelectionAtoP.secondIntervalEnd) {
        return
      }
    }

    const nextField = this.getNextField(selectedField)
    return {
      params: {
        pointSelectionAtoP: {
          ...pointSelectionAtoP,
          [selectedField]: time,
        },
      },
      nextField,
    }
  }

  static getUpdatedAverage(componentState, componentProps, rawData) {

    const { pointSelectionAtoP } = componentState
    const {
      slot,
      functionality,
      activeProcess,
    } = componentProps

    const {
      firstIntervalStart,
      firstIntervalEnd,
      secondIntervalStart,
      secondIntervalEnd,
      firstIntervalAverageDisplayed,
      secondIntervalAverageDisplayed,
    } = pointSelectionAtoP

    const newAvrgLower = this.getAverage(rawData, firstIntervalStart, firstIntervalEnd)
    const newAvrgUpper = this.getAverage(rawData, secondIntervalStart, secondIntervalEnd)

    const fctId = functionality.id
    const slotName = slot.name

    const decalibratedDataLower = this
      .reverseCalibrationOnSlice(rawData, firstIntervalStart, firstIntervalEnd, activeProcess, fctId, slotName)
    const realNewAvrgLower = this.getAverage(decalibratedDataLower, firstIntervalStart, firstIntervalEnd)
    const decalibratedDataUpper = this
      .reverseCalibrationOnSlice(rawData, secondIntervalStart, secondIntervalEnd, activeProcess, fctId, slotName)
    const realNewAvrgUpper = this.getAverage(decalibratedDataUpper, secondIntervalStart, secondIntervalEnd)

    return {
      params: {
        pointSelectionAtoP: {
          ...pointSelectionAtoP,
          firstIntervalAverageDisplayed: newAvrgLower || firstIntervalAverageDisplayed,
          firstIntervalAverageReal: realNewAvrgLower,
          secondIntervalAverageDisplayed: newAvrgUpper || secondIntervalAverageDisplayed,
          secondIntervalAverageReal: realNewAvrgUpper,
        },
      },
    }
  }

  static attachGraphSlice(sensorData, componentState) {

    const { pointSelectionAtoP } = componentState

    const {
      firstIntervalStart,
      firstIntervalEnd,
      secondIntervalStart,
      secondIntervalEnd,
    } = pointSelectionAtoP

    const res = []

    if (firstIntervalStart !== '' && firstIntervalEnd !== '') {
      const slice = DataManager
        .valuesWithinTimeRange(sensorData, firstIntervalStart, firstIntervalEnd)
      res.push(this.createGraphSlice(slice, { color: 'red', label: 'Interval 1' }))
    }

    if (secondIntervalStart !== '' && secondIntervalEnd !== '') {
      const slice = DataManager
        .valuesWithinTimeRange(sensorData, secondIntervalStart, secondIntervalEnd)
      res.push(this.createGraphSlice(slice, { color: 'blue', label: 'Interval 2' }))
    }

    return res
  }
}

export default LinearCalibrationSelectionMode
