import { DeviceProcessesPusherChannel } from '@ospin/pusher'

import downloadHandler from './eventHandlers/downloadHandler'
import updateRunningProcessHandler from './eventHandlers/updateRunningProcessHandler'

const { EVENTS: DEVICE_PROCESSES_EVENTS } = DeviceProcessesPusherChannel

export default deviceId => ({
  [DEVICE_PROCESSES_EVENTS.PROCESS_DOWNLOAD_REQUEST_UPDATED]: downloadHandler,
  [DEVICE_PROCESSES_EVENTS.PROCESS_PDF_REPORT_REQUEST_UPDATED]: downloadHandler,
  [DEVICE_PROCESSES_EVENTS.RUNNING_PROCESS_STATE_UPDATE]:
    data => updateRunningProcessHandler(deviceId, data),
})
