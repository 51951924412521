export function roundToTheNthDecimal(number, maxDecimals = 3, { revertToNumber = true } = {}) {

  const INVALID_VALUES = [undefined, null, '', false]

  if (INVALID_VALUES.includes(number)) return number

  const roundedNumber = typeof number !== 'number'
    ? number
    : number.toFixed(maxDecimals)

  return revertToNumber ? Number(roundedNumber) : Number(roundedNumber).toString()
}
