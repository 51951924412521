import React from 'react'
import { Card, Image, Label, Divider } from 'semantic-ui-react'

import defaultImage from '~/images/bioreactor_online_cropped.png'
import Device from '~/utils/Device'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DeviceProcess from './DeviceProcess'
import './DeviceWidget.css'
import WidgetStatusIndicator from './WidgetStatusIndicator'

const mapStateToProps = ({ user }) => ({ user })

const renderVirtual = () => (
  <div>
    <Label className='device-widget-virtual-label' color='blue' basic size='small'>
      Virtual
    </Label>
  </div>
)

const getImageClass = online => {
  const base = 'device-widget-image'
  if (online) return base
  return `${base} ${base}--faded`
}

const getImageSource = device => {
  const { manufacturerDeviceType } = device
  return manufacturerDeviceType.imageURL || defaultImage
}

const getImage = device => (
  <Image
    className={getImageClass(device.online)}
    centered
    src={getImageSource(device)}
  />
)

const renderDeviceType = device => {
  const { manufacturerDeviceType } = device

  return manufacturerDeviceType.name
}

function DeviceWidget({ device }) {
  const {
    isVirtual,
    name,
    runningProcesses,
    manufacturer,
  } = device

  const isActive = (Device.isRunning(device) || Device.isPaused(device))

  return (

    <Card className='device-widget'>
      <Link to={`/devices/${device.id}/processes`}>
        <Card.Content className='device-widget-header'>
          <Card.Header>
            <div className='device-widget-device-name' title={name}>
              {name}
            </div>
          </Card.Header>
          <Card.Meta className='device-widget-meta-style'>{renderDeviceType(device)}</Card.Meta>
          <Card.Meta className='device-widget-meta-style'>{manufacturer.name}</Card.Meta>
          <Divider style={{ borderWidth: '2px', marginBottom: 0, marginTop: '8px' }} />
        </Card.Content>

        <div className='device-widget-image-frame'>
          {getImage(device)}
        </div>
      </Link>

      {isVirtual ? renderVirtual() : null}
      {isActive
        ? (
          <DeviceProcess
            device={device}
            runningProcessId={runningProcesses[0].id}
          />
        )
        : (
          <WidgetStatusIndicator
            device={device}
          />
        )}

    </Card>
  )
}

export default withRouter(connect(mapStateToProps)(DeviceWidget))
