import React, { useState } from 'react'
import './SectionWrapper.css'
import { Segment, Header, Icon } from 'semantic-ui-react'
import HelpIconPopup from '~/components/utility/HelpIconPopup'

const getHeaderClassName = showContent => (
  showContent
    ? 'section-header'
    : 'section-header collapsed'
)

const getIconName = showContent => (
  showContent ? 'triangle down' : 'triangle right'
)

const renderDescription = description => (
  <HelpIconPopup
    description={description}
    iconStyle={{
      float: 'right', margin: '0 0 0 0.5em', position: 'relative', top: '3px',
    }}
  />
)

const SectionWrapper = ({ title, description, children, showOnLoad }) => {
  const [showContent, toggle] = useState(showOnLoad)

  return (
    <>
      <Segment
        className={getHeaderClassName(showContent)}
        attached='top'
        onClick={() => toggle(!showContent)}
      >
        <Icon name={getIconName(showContent)} />
        <Header textAlign='left' className='section-header-text' >
          {title}
        </Header>
        {description && renderDescription(description)}
      </Segment>
      {showContent && (
        <Segment attached className='section-body'>
          {children}
        </Segment>
      )}
    </>
  )
}

export default SectionWrapper
