import React, { useState, useEffect } from 'react'
import { Button, Header, Segment, Table } from 'semantic-ui-react'
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom'
import nexus from '@ospin/nexus'
import SectionLoader from '~/components/utility/SectionLoader'
import NoDataMessage from '~/components/utility/NoDataMessage'
import ClaimLicenceModal from './ClaimLicenceModal'
import LicenceRow from './LicenceRow'
import './Licences.css'

const HEADERS = [ 'Type', 'Claimed', 'Expiration date', 'Device', 'Key' ]

const renderLoader = () => (
  <div className='user-licences-loader-wrapper'>
    <SectionLoader size='small' inline='centered' />
  </div>
)

function renderLicenceTable(licences) {
  if (!licences.length) {
    return (
      <div className='user-licences-no-data-msg-wrapper'>
        <NoDataMessage text='No licences found' />
      </div>
    )
  }

  return (
    <Table className='ospin-red' celled padded columns={4}>
      <Table.Header>
        <Table.Row key='header'>
          {HEADERS.map(header => <Table.HeaderCell key={header} content={header} />)}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {licences.map(licence => <LicenceRow key={licence.id} licence={licence} />)}
      </Table.Body>
    </Table>
  )
}

function Licences({ user }) {
  const [licences, setLicences] = useState([])
  const [showClaimLicenceModal, setShowClaimLicenceModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ queryStringLicenceKey, setQueryStringLicenceKey ] = useState('')

  const addLicence = licence => setLicences([...licences, licence])

  const toggleModal = () => setShowClaimLicenceModal(!showClaimLicenceModal)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const licenceKeyFromURL = queryParams.get('claimLicenceKey')

  useEffect(() => {
    const fetchLicences = async () => {
      setIsLoading(true)
      try {
        const { data } = await nexus.user.licence.list({ userId: user.id })
        setLicences(data)
      } catch (_) {
        /* TODO: ADD_ERROR_HANDLING */
      } finally {
        setIsLoading(false)
      }
    }

    if (licenceKeyFromURL) {
      setShowClaimLicenceModal(true)
      setQueryStringLicenceKey(licenceKeyFromURL)
    }

    fetchLicences()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header as='h2'>Licences</Header>
      <ClaimLicenceModal
        headerText='Claim Licence'
        open={showClaimLicenceModal}
        addLicence={addLicence}
        closeHandler={toggleModal}
        user={user}
        queryStringLicenceKey={queryStringLicenceKey}
      />
      <Segment>
        { isLoading ? renderLoader() : renderLicenceTable(licences)}
        <Button primary content='Claim Licence' onClick={() => toggleModal()} />
      </Segment>
    </>
  )
}
export default withRouter(Licences)
