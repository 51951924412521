import store from '~/redux/store'
import { updateDevice } from '~/redux/actions/actions'
import FirmwareUpdate from '~/utils/device/FirmwareUpdate'

export default function updateDeviceFirmwareUpdates(deviceId, message) {
  const { firmwareUpdate, firmwareVersion } = message

  if (firmwareUpdate?.state === FirmwareUpdate.STATES.FAILED) {
    firmwareUpdate.seen = false
  }

  store.dispatch(updateDevice({ deviceId, params: { firmwareUpdate, firmwareVersion } }))
}
