import { findAndUpdate } from '~/redux/helper/updateFunctions'
import DataManager from '~/utils/DataManager'

export default (state, action) => {
  const { reporterFctId, processId } = action.payload

  const { processes } = state
  const updateFn = process => DataManager.setLoadingHistoricDataFlag(process, reporterFctId)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
