import React, { useState } from 'react'
import { FCTGraph, Functionality, Slot } from '@ospin/fct-graph'
import { connect } from 'react-redux'
import { Table, Icon } from 'semantic-ui-react'
import { roundToTheNthDecimal } from '~/utils/number'
import UI_CONSTANTS from '~/config/ui'
import nexus from '@ospin/nexus'
import NoDataMessage from '~/components/utility/NoDataMessage'
import TableHeader from '~/components/utility/table/TableHeader'
import SensorCalibration from '~/utils/sensors/SensorCalibration'
import DeletePopup from './DeletePopup'

const mapStateToProps = state => ({ user: state.user })

const renderLockIcon = locked => {
  const name = locked ? 'lock' : 'lock open'
  return <Icon name={name} color='grey' style={{ marginLeft: '12px' }} />
}

const groupCalibrationsByFctId = calibrations => (
  calibrations.reduce((acc, curr) => {
    const existingEntry = acc.find(entry => entry.fctId === curr.fctId)
    if (!existingEntry) return [ ...acc, { fctId: curr.fctId, fctCalibrations: [ curr ] } ]
    existingEntry.fctCalibrations.push(curr)
    return acc
  }, [])
)

const CalibrationTable = ({
  activeDevice,
  user,
  selectedGraph,
  calibrations,
  setCalibrations,
}) => {

  const [isLoading, setIsLoading] = useState(false)

  async function deleteCalibrationEntry(calib) {

    try {
      setIsLoading(true)
      await nexus.device.functionality
        .calibration.remove(
          activeDevice.id,
          selectedGraph.id,
          calib.fctId,
          { slotName: calib.slotName },
        )
      const updatedCalibrations = SensorCalibration.delete(
        calibrations,
        calib.fctId,
        calib.slotName,
      )
      setCalibrations(updatedCalibrations)
    } catch (_) {
      setIsLoading(false)
      /* TODO: ADD_ERROR_HANDLING */
    }
  }

  if (!selectedGraph) return <NoDataMessage text='No configuration selected' />

  if (!calibrations.length) return <NoDataMessage text='No calibrations found' />

  const groupedCalibrations = groupCalibrationsByFctId(calibrations)

  const renderFctCalibrations = (fct, fctCalibrations) => (
    fctCalibrations.map((calib, idx) => {
      const {
        slotName,
        params: {
          offset: { value: offsetValue, locked: offsetLocked },
          slope: { value: slopeValue, locked: slopeLocked },
        },
      } = calib
      const slot = Functionality.getSlotByName(fct, slotName)

      return (
        <Table.Row key={fct.id + slot.name}>
          {idx === 0 && <Table.Cell rowSpan={fctCalibrations.length}>{fct.name}</Table.Cell>}
          <Table.Cell>
            {Slot.getDisplayName(slot)} [{ slot.unit }]
          </Table.Cell>
          <Table.Cell>
            {roundToTheNthDecimal(offsetValue, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)}
            {renderLockIcon(offsetLocked)}
          </Table.Cell>
          <Table.Cell>
            {roundToTheNthDecimal(slopeValue, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)}
            {renderLockIcon(slopeLocked)}
          </Table.Cell>
          <Table.Cell>
            <DeletePopup
              loading={isLoading}
              confirmHandler={() => deleteCalibrationEntry(calib)}
              disabled={!SensorCalibration.userCanDelete(user, activeDevice, calib) || isLoading}
              iconName='close'
              position='right center'
            />
          </Table.Cell>
        </Table.Row>
      )
    })
  )

  return (
    <Table className='ospin-red' celled structured>
      <TableHeader items={['Functionality', 'Channel', 'Offset', 'Slope', '']} />
      <Table.Body>
        {groupedCalibrations.map(({ fctId, fctCalibrations }) => {
          const fct = FCTGraph.getFctById(selectedGraph, fctId)
          if (!fct) return null
          return renderFctCalibrations(fct, fctCalibrations)
        })}
      </Table.Body>
    </Table>
  )
}

export default connect(mapStateToProps)(CalibrationTable)
