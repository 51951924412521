import React from 'react'
import { Slot } from '@ospin/fct-graph'
import { Form, Select } from 'semantic-ui-react'
import { hasUnit } from '~/utils/units'
import Unit from '../Unit'

export default class OneOfInSlot extends React.Component {

  constructor(props) {
    super(props)
    this.state = { openValueDropDown: false }
  }

  getSlotValueOptions = () => {
    const { slot } = this.props
    const { selectOptions } = slot
    return selectOptions.map(value => ({
      value,
      text: value,
    }))
  }

  save = async (event, { value }) => {
    const { slot, updateInputNodeValue, inputNodeFctId } = this.props
    if (!slot.selectOptions.includes(value)) return
    updateInputNodeValue(inputNodeFctId, value)
  }

  renderSlot = style => {
    const { openValueDropDown } = this.state
    const { inputNodeValue, disabledInput, slot } = this.props
    const { selectOptions } = slot

    const uniqueValue = selectOptions.length === 1

    return (
      <Select
        value={inputNodeValue}
        open={uniqueValue ? false : openValueDropDown}
        options={this.getSlotValueOptions()}
        closeOnChange
        onClick={() => this.setState({ openValueDropDown: !openValueDropDown })}
        onChange={this.save}
        onClose={() => this.setState({ openValueDropDown: false })}
        style={style}
        disabled={disabledInput}
      />
    )
  }

  renderSlotWithUnit = () => {
    const { slot } = this.props

    return (
      <Form.Field>
        {this.renderSlot({
          paddingRight: '0',
          marginRight: '-2px',
          borderRadius: '.28571429rem 0 0 .28571429rem',
        })}
        <Unit
          slot={slot}
        />
      </Form.Field>
    )
  }

  renderUnitlessSlot = () => (
    <Form.Field>
      {this.renderSlot()}
    </Form.Field>
  )

  render() {
    const { slot } = this.props

    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>
          {Slot.getDisplayName(slot)}
        </span>
        <Form.Group inline>
          {hasUnit(slot.unit)
            ? this.renderSlotWithUnit()
            : this.renderUnitlessSlot()}
        </Form.Group>
      </div>
    )
  }
}
