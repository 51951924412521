import Authorizer from './Authorizer'

class DeviceAuthorizer extends Authorizer {
  static isUserAbleToDeleteNotification = (device, user) => (
    Authorizer.hasApplicationAdminAccess(user)
      || Authorizer.isResourceAdmin(device, user.id)
      || Authorizer.isResourceSupport(device, user.id)
      || Authorizer.isResourceOperator(device, user.id)
  )

  static isAbleToUpdateDisplayedDecimals = (device, user) => (
    Authorizer.hasApplicationAdminAccess(user)
    || Authorizer.isResourceAdmin(device, user.id)
    || Authorizer.isResourceSupport(device, user.id)
    || Authorizer.isResourceOperator(device, user.id)
  )
}

export default DeviceAuthorizer
