import React, { useEffect } from 'react'
import { Grid, Loader, Message } from 'semantic-ui-react'
import nexus from '@ospin/nexus'
import { connect } from 'react-redux'
import Event from '~/utils/Event'
import callDeleteUserAlert from '~/redux/thunks/event/callDeleteUserAlert'
import { setDevices } from '~/redux/actions/actions'
import Device from '~/utils/Device'
import DeviceAlerts from './DeviceAlerts'
import GeneralAlerts from './GeneralAlerts'

/// This is an arbitary limit to avoid exceeding the payload size
/// this means that a device might not be included even if it
/// has an alarm but that should be an admin only case

const LIMIT_OF_DEVICES_TO_FETCH = 40

const mapStateToProps = state => ({
  user: state.user,
  devices: state.devices,
})

const mapDispatchToProps = dispatch => ({
  dispatchDeleteUserAlert: alertId => callDeleteUserAlert(dispatch, alertId),
  dispatchSetDevices: devices => dispatch(
    setDevices(devices),
  ),
})

const getDeviceAlerts = (alerts, devices) => (
  alerts.filter(alert => Event.hasAssociatedDevice(alert))
    .map(alert => {
      const deviceName = Device.getById(devices, alert.deviceId)?.name
      return { deviceName, ...alert }
    })
)

const getGeneralAlerts = alerts => (
  alerts.filter(alert => !Event.hasAssociatedDevice(alert))
)

const Alerts = ({
  user,
  devices,
  dispatchSetDevices,
  dispatchDeleteUserAlert,
}) => {

  const [loading, setLoading] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const alerts = user.events
  alerts.sort((a, b) => (
    (a.createdAt < b.createdAt) ? 1 : -1
  ))
  useEffect(() => {
    async function fetchDevices() {
      const requiredDeviceIds = [...new Set(alerts.map(obj => obj.deviceId))]
      const storedDeviceIds = devices.map(obj => obj.id)

      if (requiredDeviceIds.every(element => storedDeviceIds.includes(element))) {
        return
      }

      const { data: { devices: fetchedDevices } } = await nexus.user.device.list({
        userId: user.id,
        queryStringParameters: {
          limit: LIMIT_OF_DEVICES_TO_FETCH,
          skip: 0,
          deviceIds: requiredDeviceIds.sort().join(','),
        },
      })
      dispatchSetDevices(fetchedDevices)
    }
    const loadDevices = async () => {
      setLoading(true)
      try {
        await fetchDevices()
      } catch ({message}) {
        setErrorMessage(message)
      } finally {
        setLoading(false)
      }
    }
    loadDevices()

  /* eslint-disable-next-line */
  }, [])

  if (loading) return <Loader active size='big' />

  if (errorMessage) {
    return (
      <Message
        error
        header='Something went wrong.'
        content={errorMessage}
        icon='warning circle'
      />
    )
  }

  return (
    <Grid>
      <Grid.Row>
        <DeviceAlerts
          deviceAlerts={getDeviceAlerts(alerts, devices)}
          deleteAlert={dispatchDeleteUserAlert}
        />
        <GeneralAlerts
          generalAlerts={getGeneralAlerts(alerts)}
          deleteAlert={dispatchDeleteUserAlert}
        />
      </Grid.Row>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
