import React from 'react'
import { connect } from 'react-redux'
import { Table, Header } from 'semantic-ui-react'
import SupportUser from './SupportUser'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const SupportTable = ({ activeDevice, supportUsers }) => {
  const subject = `Support request for device: ${activeDevice.id}`
  if (!supportUsers || supportUsers.length === 0) return null

  return (
    <>
      <Header as='h1'>Device Support</Header>
      <Table style={{ borderTop: '.2em solid #2878C2' }} celled selectable padded sortable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {supportUsers.map(supportUser => (
            <SupportUser key={supportUser.id} supportUser={supportUser} subject={subject} />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default connect(mapStateToProps)(SupportTable)
