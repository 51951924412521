import React from 'react'
import { Table } from 'semantic-ui-react'
import NoDataMessage from '~/components/utility/NoDataMessage'
import AnnotationsOverviewTableRow from './AnnotationsOverviewTableRow'

const AnnotationsOverviewTable = ({ activeProcess }) => {

  const ANNOTATIONS_TABLE_HEADERS = [
    'Timestamp - Process time',
    'Timestamp - Date & time',
    'Text',
    '',
  ]

  if (!activeProcess.annotations.length) {
    return (
      <NoDataMessage text='No annotations found' />
    )
  }

  return (
    <Table className='ospin-red' celled structured>
      <Table.Header>
        <Table.Row>
          {ANNOTATIONS_TABLE_HEADERS.map(header => (
            <Table.HeaderCell key={header}>
              {header}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { activeProcess.annotations.map(annotation => (
          <AnnotationsOverviewTableRow
            key={annotation.id}
            annotation={annotation}
            activeProcess={activeProcess}
          />
        )) }
      </Table.Body>
    </Table>
  )
}

export default AnnotationsOverviewTable
