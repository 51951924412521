import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { Process } from '~/utils/process'
import PhaseGroupHandler from '~/utils/process/PhaseGroupHandler'

const COMMANDS = {
  MOVE_GROUP: 'MOVE_GROUP',
  MOVE_PHASE_WITHIN_PROCESS_TREE: 'MOVE_PHASE_WITHIN_PROCESS_TREE',
}

const getCommand = (srcArea, destArea, srcPhaseInGroup) => {
  switch (true) {
    case (destArea === 'process-tree' && srcArea === 'process-tree' && srcPhaseInGroup): {
      return COMMANDS.MOVE_GROUP
    }
    default: {
      return COMMANDS.MOVE_PHASE_WITHIN_PROCESS_TREE
    }
  }
}

export default (state, action) => {
  const { processes } = state
  const { processId, src, dest, srcArea, destArea, srcPhaseInGroup } = action.payload

  const command = getCommand(srcArea, destArea, srcPhaseInGroup)

  const getMoveFunction = () => {
    switch (command) {
      case COMMANDS.MOVE_GROUP:
        return { fn: process => PhaseGroupHandler.moveGroup(process, src, dest) }
      case COMMANDS.MOVE_PHASE_WITHIN_PROCESS_TREE:
        return { fn: process => Process.movePhase(process, src, dest) }
      default:
        throw new Error('Unknow move command')
    }
  }

  const { fn: updateFn } = getMoveFunction()

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
