import React from 'react'
import { FCTGraph } from '@ospin/fct-graph'
import { Modal, Form, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import nexus from '@ospin/nexus'
import { updateProcess } from '~/redux/actions/actions'
import Authorizer from '~/utils/Authorizer'
import DescriptionParser from '~/utils/process/DescriptionParser'
import FunctionalityConfiguration from '~/utils/functionalities/FunctionalityConfiguration'

const mapStateToProps = state => ({
  user: state.user,
  functionalityDescriptions: state.functionalityDescriptions,
})

const mapDispatchToProps = dispatch => ({
  dispatchUpdateProcess: ({ processId, params }) => dispatch(updateProcess({ processId, params })),
})

class ControllerModal extends React.Component {

  state = {
    savingDefault: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
  }

  submitSlotDefaultInputValue = async (slot, defaultValue) => {
    const { activeProcess, dispatchUpdateProcess, functionality } = this.props

    const updates = [{
      fctId: functionality.id,
      slotsConfigs: [{ name: slot.name, defaultInputValue: defaultValue }],
    }]

    const { fctGraph, deviceId } = activeProcess

    const res = await nexus.device.functionalityGraph
      .functionalityConfiguration.updateMany({
        deviceId,
        fctGraphId: fctGraph.id,
        updates,
      })

    const { data: updatedFctsConfigs } = res
    const mergedFctConfigs = FunctionalityConfiguration
      .mergeMany(activeProcess.fctsConfigs, updatedFctsConfigs)

    dispatchUpdateProcess({
      processId: activeProcess.id,
      params: { fctsConfigs: mergedFctConfigs },
    })

    return res
  }

  async handleSaveDefault() {

    this.setState({ savingDefault: true })

    const {
      activeProcess,
      phaseId,
      controllerSlots,
      functionality,
    } = this.props
    const { fctGraph } = activeProcess

    const errors = []

    for (const slot of controllerSlots) {
      const inputNodeFctId = FCTGraph
        .getInputNodeFctIdForSlot(fctGraph, functionality.id, slot.name)
      const newDefault = DescriptionParser
        .getInputNodeValue(activeProcess, phaseId, inputNodeFctId)
      const parsedDefault = parseFloat(newDefault)

      if (isNaN(parsedDefault) || parsedDefault > slot.max || parsedDefault < slot.min) {
        errors.push({ id: slot.name })
        continue
      }

      try {
        await this.submitSlotDefaultInputValue(slot, parsedDefault)
      } catch (_) {
        errors.push({ id: slot.name })
      }
    }

    if (errors.length > 0) {
      const errorMessage = errors.reduce((acc, error) => `${acc}${error.id}, `, 'Could not set default for: ')
      this.setState({ isError: true, errorMessage, isSuccess: false, savingDefault: false })
    } else {
      this.setState({ isSuccess: true, isError: false, errorMessage: '', savingDefault: false })
    }
  }

  render() {

    const { savingDefault, isError, errorMessage, isSuccess } = this.state
    const {
      controllerSlots,
      activeProcess,
      open,
      closeHandler,
      user,
      renderSlotByDataType,
    } = this.props

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={closeHandler}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          Controller
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              {controllerSlots.map(slot => renderSlotByDataType(slot, { showDefault: true }))}
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success.'
                content='Controller defaults saved.'
                icon='check circle'
              />
              <Button
                type='button'
                floated='left'
                primary
                loading={savingDefault}
                onClick={() => this.handleSaveDefault()}
                disabled={Authorizer.isResourceViewer(activeProcess, user.id)}
              >
                Save as Default
              </Button>
              <Button
                floated='right'
                type='button'
                onClick={closeHandler}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ControllerModal)
