import React, { useState } from 'react'
import { connect } from 'react-redux'
import { FCTGraph, Ports } from '@ospin/fct-graph'
import nexus from '@ospin/nexus'
import { Table, Button, Message, Header } from 'semantic-ui-react'
import { updateProcess } from '~/redux/actions/actions'
import FlashMessenger from '~/utils/FlashMessenger'

import './PortAssignmentTable.css'

const mapDispatchToProps = dispatch => ({ dispatchUpdateProcess: (processId, params) => dispatch(updateProcess({ processId, params })) })

const HEADERS = ['Functionality', 'Port']

const renderSynchronizeButton = (
  synchronizePorts,
  isLoading,
) => (
  <Button
    primary
    compact
    content='Synchronize'
    loading={isLoading}
    disabled={isLoading}
    onClick={synchronizePorts}
  />
)

const renderWarningMessage = (
  synchronizePorts,
  isLoading,
) => (
  <div>
    <Message warning>
      <div className='assigned-ports-warning-message-header'>
        <Message.Header>
          The process was created with a port assignment that does not match the latest device configuration
        </Message.Header>
      </div>

      <span className='assigned-ports-warning-msg-sync-button'>
        {renderSynchronizeButton(synchronizePorts, isLoading)}
      </span>
      <div className='assigned-ports-warning-body-msg'>
        <p>
          You can synchronize the process&apos; port assignment with the device configuration here
        </p>
      </div>
    </Message>
  </div>
)

const getFuncionalityName = (fctId, fctGraph) => (
  FCTGraph.getFctById(fctGraph, fctId).name
)

const PortAssignmentTableRow = ({ fctGraph, map }) => (
  <Table.Row>
    <Table.Cell content={getFuncionalityName(map.id, fctGraph)} />
    <Table.Cell content={Ports.getId(map.ports)} />
  </Table.Row>
)

const PortAssignmentTable = ({ activeProcess, dispatchUpdateProcess, hasSyncedPorts }) => {
  const { usedPhysicalMapping, fctGraph } = activeProcess

  const [ isLoading, setIsLoading ] = useState(false)

  const synchronizePorts = async () => {
    setIsLoading(true)

    try {
      const { data: { usedPhysicalMapping: updatedMapping } } = await nexus.process.physicalMapping.update(activeProcess.id)

      dispatchUpdateProcess(activeProcess.id, { usedPhysicalMapping: updatedMapping })
      FlashMessenger.success('The ports have been updated successfully')
    } catch (e) {
      FlashMessenger.error(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Header>
        Assigned Ports
      </Header>
      {!hasSyncedPorts
        ? renderWarningMessage(synchronizePorts, isLoading)
        : null}
      <Table className='ospin-red'>
        <Table.Header>
          <Table.Row>
            {HEADERS.map(header => <Table.HeaderCell key={header} content={header} />)}
          </Table.Row>
          {usedPhysicalMapping.functionalities.map(map => (
            <PortAssignmentTableRow
              key={map.id}
              map={map}
              fctGraph={fctGraph}
            />
          ))}
        </Table.Header>
      </Table>
    </>
  )
}

export default connect(null, mapDispatchToProps)(PortAssignmentTable)
