import { deepClone } from '~/utils/immutability'

export const findAndUpdate = (id, entities, updaters) => {
  if (!Array.isArray(updaters)) throw new Error('Update functions have to be provided as an array')
  return entities.map(entity => {
    if (entity.id !== id) return entity
    updaters.forEach(updateFn => updateFn(entity))
    return deepClone(entity)
  })
}
