import React, { useState } from 'react'
import nexus from '@ospin/nexus'
import { Button } from 'semantic-ui-react'

/// TEMPORARY COMPONENT, will be removed once all templates have recieved a layout
export default function AdminTemplateLayoutControl({ template, layout }) {
  const [loading, setLoading] = useState(false)

  async function updateLayout() {
    try {
      setLoading(true)
      await nexus.deviceDescription.template.putLayout(template.id, { layout })
      setLoading(false)
    } catch (error) { }
  }
  return (
    <Button loading={loading} onClick={() => updateLayout()} content='Update Layout as Admin'/>
  )
}
