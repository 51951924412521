import React from 'react'
import { Transition, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { deleteFlashMessage } from '~/redux/actions/actions'

import './FlashMessage.css'

const mapDispatchToProps = dispatch => ({
  dispatchDeleteFlashMessage: messageId => dispatch(deleteFlashMessage({ messageId })),
})

class FlashMessage extends React.Component {

  state = {
    visible: false,
    persistTimeout: undefined,
  }

  ANIMATION_DURATION = 700

  componentDidMount() {
    this.setState({ visible: true })
    const { message: { persistTime, id }, dispatchDeleteFlashMessage } = this.props

    const persistTimeout = setTimeout(() =>
      this.setState({ visible: false }, () => {
        setTimeout(() => dispatchDeleteFlashMessage(id), this.ANIMATION_DURATION)
      }), persistTime)
    this.setState({ persistTimeout })
  }

  componentWillUnmount() {
    const { persistTimeout } = this.state
    if (persistTimeout) clearTimeout(persistTimeout)
  }

  deleteMessage = () => {
    const { message: { id }, dispatchDeleteFlashMessage } = this.props
    dispatchDeleteFlashMessage(id)
  }

  getWrapperCSSClass = () => {
    const { message: { type } } = this.props
    const messageBackgrounds = {
      error: 'red',
      alarm: 'red',
      warning: 'yellow',
      info: 'blue',
      success: 'green',
    }

    let base = 'flashmessage-wrapper'
    base += ` ${base}--${messageBackgrounds[type]}`
    return base
  }

  getMessageIcon = () => {
    const { message: { type } } = this.props
    const messageIcons = {
      error: 'ban',
      warning: 'warning',
      info: 'info',
      success: 'check',
      alarm: 'alarm',
    }

    return messageIcons[type]
  }

  render() {

    const { visible } = this.state
    const { message: { text } } = this.props

    return (
      <Transition
        visible={visible}
        animation='fly left'
        duration={this.ANIMATION_DURATION}
      >
        <div
          id='flashmessage-wrapper-flex-display'
          className={this.getWrapperCSSClass()}
        >
          <Icon
            name={this.getMessageIcon()}
            size='big'
            className='flashmessage-icon'
          />
          <div className='flashmessage-text'>
            {text}
          </div>
          <Button
            icon
            circular
            basic
            inverted
            onClick={() => this.deleteMessage()}
            className='flashmessage-close-button'
          >
            <Icon name='close' />
          </Button>
        </div>
      </Transition>
    )
  }
}

export default connect(null, mapDispatchToProps)(FlashMessage)
