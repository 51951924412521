import Time from '~/utils/Time'

export function getXAxisLabelFormater(timeStampsMode, activeProcess, derivedTimeDisplayFormat) {
  if (timeStampsMode === 'dateTime') {
    return value => Time.dateTimeAxisLabelFormat(activeProcess.startedAt / 1000 + value)
  }
  return value => Time.processTimeAxisLabelFormat(value, derivedTimeDisplayFormat)
}

export function getTotalDuration(data) {
  const lastDataSetPoints = data[data.length - 1].data
  if (lastDataSetPoints.length === 0) return
  const lastXValue = lastDataSetPoints[lastDataSetPoints.length - 1].x

  return lastXValue
}

export function formatDateTimeForAnnotations(
  totalDuration,
  timeStampsMode,
  activeProcess,
) {
  const derivedTimeDisplayFormat = Time.deriveTimeDisplayFormat(totalDuration)
  return getXAxisLabelFormater(timeStampsMode, activeProcess, derivedTimeDisplayFormat)
}
