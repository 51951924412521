import { FCTGraph, Slot } from '@ospin/fct-graph'
import React, { useState, useEffect } from 'react'
import modalize from '~/components/utility/modal/modalize'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import GraphTools from '~/utils/GraphTools'
import DashboardWidgetModalForm from './DashboardWidgetModalForm'

const DashboardWidgetModal = props => {
  const {
    closeHandler,
    addWidget,
    fctGraph,
  } = props

  const [selectedFctId, setSelectedFctId] = useState(null)
  const [selectedSlotName, setSelectedSlotName] = useState(null)
  const [dataSources, setDataSources] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedWidgetType, setSelectedWidgetType] = useState(null)
  const [selectedLineColor, setSelectedLineColor] = useState(GraphTools.getColorByIndex(0))

  const fctOptions = GraphDashboard
    .getFctsForWidgetType(selectedWidgetType, fctGraph, dataSources)
    .map(fct => ({ key: fct.id, text: fct.name, value: fct.id }))

  useEffect(() => {
    if (fctOptions.length === 1) {
      setSelectedFctId(fctOptions[0].value)
    }
  }, [fctOptions])

  const selectedFct = FCTGraph.getFctById(fctGraph, selectedFctId)

  const getSlotOptions = () => (
    GraphDashboard
      .getSlotsForWidgetType(selectedWidgetType, { fct: selectedFct, dataSources, fctGraph })
      .map(slot => ({ key: slot.name, text: Slot.getDisplayName(slot), value: slot.name }))
  )

  const slotOptions = getSlotOptions()

  useEffect(() => {
    if (slotOptions.length === 1) {
      setSelectedSlotName(slotOptions[0].value)
    }
  }, [slotOptions])

  const addDataSource = newSource => {
    setDataSources([...dataSources, newSource])
    setSelectedLineColor(GraphTools.getColorByIndex(dataSources.length + 1))
  }

  const updateDataSource = (updatedSourceIdx, updatedColor) => {
    setDataSources(dataSources.map((source, currIdx) => {
      if (updatedSourceIdx === currIdx) {
        return { ...source, lineColor: updatedColor }
      }
      return source
    }))
  }

  const removeDataSource = sourceFctIdx => {
    setDataSources(dataSources.filter((_, currIdx) => sourceFctIdx !== currIdx))
    setSelectedLineColor(GraphTools.getColorByIndex(sourceFctIdx))
  }

  const submitHandler = async () => {
    setLoading(true)
    if (GraphDashboard.showDataSourceSelection(selectedWidgetType)) {
      await addWidget(selectedWidgetType, { dataSources })
      setDataSources([])
    } else {
      await addWidget(selectedWidgetType, { fctId: selectedFctId, slotName: selectedSlotName })
    }
  }

  const disableConfirmButton = () => {
    if (!selectedWidgetType) return true
    return !GraphDashboard
      .areValidParamsForCreation(selectedWidgetType, {
        slotName: selectedSlotName,
        fctId: selectedFctId,
        dataSources,
      })
  }

  return (
    <DashboardWidgetModalForm
      closeHandler={closeHandler}
      closeOnSubmit
      disableSubmit={disableConfirmButton()}
      fctGraph={fctGraph}
      fctOptions={fctOptions}
      slotOptions={slotOptions}
      loading={loading}
      selectedFctId={selectedFctId}
      selectedSlotName={selectedSlotName}
      selectedWidgetType={selectedWidgetType}
      setLoading={setLoading}
      setSelectedFctId={setSelectedFctId}
      setSelectedSlotName={setSelectedSlotName}
      setSelectedWidgetType={setSelectedWidgetType}
      submitHandler={submitHandler}
      dataSources={dataSources}
      addDataSource={addDataSource}
      removeDataSource={removeDataSource}
      updateDataSource={updateDataSource}
      selectedLineColor={selectedLineColor}
      setSelectedLineColor={setSelectedLineColor}
    />
  )
}

export default modalize(DashboardWidgetModal)
