import React from 'react'
import { Header, Segment, List, Form, Input } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'

const DeleteUserConfirmModalForm = props => {
  const { submitUsername, usernameFromInput } = props

  const handleChange = (value) => {
    submitUsername(value)
  }

  return (
    <div>
      <Header>Please read through the information before continuing</Header>
      <Segment inverted color='red' tertiary>
        The action is irreversible
      </Segment>
      <List relaxed='very' bulleted>
        <List.Item>
          Your account will be marked for deletion for the next
          <b> 4 weeks </b>
        </List.Item>
        <List.Item>
          If you login into the application during that period, your deletion request will be cancelled and your account will not be deleted
        </List.Item>
      </List>
      <Header>Upon expiration of the period</Header>
      <List relaxed='very' bulleted>
        <List.Item>Your user account will be deleted</List.Item>
        <List.Item>Your access to all your devices will be revoked</List.Item>
        <List.Item>Your access to all of your processes will be revoked</List.Item>
      </List>
      <Form.Field>
        <label htmlFor='username'>Enter username</label>
        <Input
          data-testid='username-input-deletion'
          id='username'
          name='username'
          type='text'
          placeholder='Username'
          onChange={({ target: { value } }) => handleChange(value)}
          value={usernameFromInput}
        />
      </Form.Field>
    </div>
  )
}

export default formalize(DeleteUserConfirmModalForm)
