import React, { useState } from 'react'
import { Table, Button, Icon } from 'semantic-ui-react'


import Time from '~/utils/Time'

export default function LicenceRow({ licence }) {
  const [showLicenceKey, setShowLicenceKey] = useState(false)

  const { claimedAt, expiresAt, deviceId, device, key, licenceType } = licence

  return (
    <Table.Row key={key}>
      <Table.Cell width='one' content={licenceType.name} />
      <Table.Cell content={Time.getTrimmedDateAndTimeString(claimedAt, { hours: false })} />
      <Table.Cell content={expiresAt ? Time.getTrimmedDateAndTimeString(expiresAt, { hours: false }) : 'never'} />
      <Table.Cell content={device ? (
        <div>
          <span>{device.name}</span>
          <a className="margin-l-8" href={`/devices/${deviceId}/processes`}>
            <Icon name='external alternate' />
          </a>
        </div>
      )
        : <span className='ui basic'>{deviceId} (deleted)</span>
      }
      />
      <Table.Cell content={showLicenceKey
        ? key
        : <Button onClick={() => setShowLicenceKey(true)} content='Show Licence Key' />}
      />
    </Table.Row>
  )

}
