import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Segment } from 'semantic-ui-react'
import Authorizer from '~/utils/Authorizer'
import AdminTemplateLayoutControl from './AdminTemplateLayoutControl'
import SaveTemplateModal from './Modals/SaveTemplateModal'
import TemplateUtils from './Utils/TemplateUtils'

const mapStateToProps = ({ user }) => ({ user })

function TemplateBuilderControlPanel({
  template,
  layout,
  setTemplateAndRerender,
  activeDevice,
  user,
}) {
  const [saveTemplateModal, setSaveTemplateModal] = useState(false)

  const createButtonText = () => {
    if (TemplateUtils.isLicenceTemplate(template)) {
      return 'Create New Template'
    }
    return TemplateUtils.isExistingTemplate(template) ? 'Update' : 'Create'
  }

  return (
    <>
      {saveTemplateModal
        ? (
          <SaveTemplateModal
            activeDevice={activeDevice}
            template={template}
            layout={layout}
            open={saveTemplateModal}
            setTemplateAndRerender={setTemplateAndRerender}
            close={() => setSaveTemplateModal(false)}
          />
        )
        : null}
        <Segment style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {Authorizer.user(user).hasApplicationDeveloperAccess() ? <AdminTemplateLayoutControl layout={layout} template={template} /> : null}

        <Button primary content={createButtonText()} onClick={() => setSaveTemplateModal(true)} />

        </Segment>

    </>

  )
}


export default connect(mapStateToProps)(TemplateBuilderControlPanel)
