import React, { useState } from 'react'
import { Icon, Item, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import callAcceptDeviceInvitation from '~/redux/thunks/device/callAcceptDeviceInvitation'
import callDeleteUserNotification from '~/redux/thunks/user/callDeleteUserNotification'
import FlashMessenger from '~/utils/FlashMessenger'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallAcceptDeviceInvitation:
     (userId, invitationId) => callAcceptDeviceInvitation(dispatch, userId, invitationId),
  dispatchCallDeclineDeviceInvitation:
   (userId, invitationId) => callDeleteUserNotification(dispatch, userId, invitationId),
})

const Invitation = ({
  inv,
  user,
  dispatchCallAcceptDeviceInvitation,
  dispatchCallDeclineDeviceInvitation,
  isLoading,
  setIsLoading,
}) => {

  const [pending, setPending] = useState(false)

  const handleDeviceInvitiation = async (action, handler) => {
    setIsLoading(true)
    setPending(action)
    try {
      await handler(user.id, inv.id)
    } catch ({ message }) {
      FlashMessenger.error(message)
    } finally {
      setPending(false)
      setIsLoading(false)
    }
  }

  return (
    <Item>
      <Icon name='envelope' size='large'/>
      <Item.Content>
        <Item.Header as='a'>Invitation</Item.Header>
        <Item.Meta>
          <span className='cinema'>
            by &nbsp;
            {inv.from.name}
          </span>
        </Item.Meta>
        <Item.Description>{inv.data.message}</Item.Description>
        <Item.Extra>
          <Button
            loading={pending === 'decline'}
            disabled={isLoading}
            floated='right'
            onClick={() => handleDeviceInvitiation('decline', dispatchCallDeclineDeviceInvitation)}
          >
            Decline
          </Button>
          <Button
            primary
            loading={pending === 'accept'}
            disabled={isLoading}
            floated='right'
            onClick={() => handleDeviceInvitiation('accept', dispatchCallAcceptDeviceInvitation)}
          >
            Accept
          </Button>
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation)
