export default class Device {

  static get STATES() {
    return {
      IDLE: 'idle',
      RUNNING: 'running',
      PAUSED: 'paused',
    }
  }

  static getState(device) {
    if (device.runningProcesses.length) {
      return device.runningProcesses[0].state // TODO Multi Process Refactor
    }
    return Device.STATES.IDLE
  }

  static removeDeviceUser(device, userId) {
    device.access = device.access.map(group => {
      if (group.users.includes(userId)) {
        group.users = group.users.filter(id => id !== userId)
      }
      return group
    })
  }

  static updateDeviceUserGroup(deviceUser, newGroup) {
    deviceUser.accessGroup = newGroup
  }

  static getById(devices, deviceId) {
    return devices.find(device => device.id === deviceId)
  }

  static isOnline(device) {
    return device.online
  }

  static isOffline(device) {
    return !Device.isOnline(device)
  }

  static isRunning(device) {
    return Device.getState(device) === Device.STATES.RUNNING
  }

  static isPaused(device) {
    return Device.getState(device) === Device.STATES.PAUSED
  }

  static isIdle(device) {
    return Device.getState(device) === Device.STATES.IDLE
  }

  static hasInconsistentState(device) {
    const isRunningOrPaused = Device.isPaused(device) || Device.isRunning(device)
    return Device.isOffline(device) && isRunningOrPaused
  }

  static isReadyForProcess(device) {
    return Device.isOnline(device) && Device.isIdle(device)
  }

}
