import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { setInputNodeValueWithGroupConsideration } from '~/utils/process'

export default (state, action) => {
  const { processes } = state
  const { processId, phaseId, value, inputNodeFctId } = action.payload

  const updateFn = process => setInputNodeValueWithGroupConsideration(process, phaseId, inputNodeFctId, value)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
