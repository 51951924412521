import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Card, Image, Popup } from 'semantic-ui-react'
import { Functionality, Ports } from '@ospin/fct-graph'

import callUpdateProcessOptimistic from '~/redux/thunks/process/callUpdateProcessOptimistic'
import ProcessIconBar from '~/components/device/processes/ProcessIconBar'
import Time from '~/utils/Time'
import { PROCESS_STATES, Process } from '~/utils/process'
import FeatureVersioning from '~/utils/FeatureVersioning'
import FunctionalityGraph from '~/utils/functionalities/FunctionalityGraph'

import processFinished from '~/images/ProcessFinished.png'
import processExecutable from '~/images/ProcessExecutable.png'
import processRunning from '~/images/ProcessRunning.png'
import processPaused from '~/images/ProcessPaused.png'

import processFinishedCircle from '~/images/svgs/process_finished.svg'
import processExecutableCircle from '~/images/svgs/process_executable.svg'
import processRunningCircle from '~/images/svgs/process_running.svg'
import processPausedCircle from '~/images/svgs/process_paused.svg'

import './ProcessTile.css'

const mapDispatchToProps = dispatch => ({
  dispatchcallUpdateProcessOptimistic: (processId, params) =>
    callUpdateProcessOptimistic(dispatch, processId, params),
})

const mapStateToProps = ({ user }) => ({ user })

const renderPort = fctGraph => {
  const physFcts = fctGraph.functionalities.filter(Functionality.isPhysical)

  if (physFcts.length !== 1) return null

  return (
    <span className='process-tile-port-display'>
      {Ports.getId(physFcts[0].ports)}
    </span>
  )
}

const renderFctGraphSpecificInformation = (process, device, match) => {
  /* showing this only on the whole gateway view; showing ports only for graphs
   * with a single physical fct */
  const { params: { fctGraphId: currFctGraphId } } = match

  if (currFctGraphId) return null

  if (!FeatureVersioning.supportsMultipleProcesses(process)) {
    return null
  }

  const { fctGraphs } = device
  const { fctGraphId } = process

  const fctGraph = FunctionalityGraph
    .getGraphById(fctGraphs, fctGraphId)

  return (
    <div className='process-tile-fct-graph-info'>
      <Popup
        content={fctGraph.name}
        basic
        trigger={(
          <span className='process-tile-fct-graph-name'>
            {fctGraph.name}
          </span>
        )}
      />
      {renderPort(fctGraph)}
    </div>
  )
}

const DISPLAY_MAP = {
  [PROCESS_STATES.running]: processRunning,
  [PROCESS_STATES.paused]: processPaused,
  [PROCESS_STATES.executable]: processExecutable,
  [PROCESS_STATES.finished]: processFinished,
}

const getFctGraphImgURL = (process, device) => {
  if (!FeatureVersioning.supportsMultipleProcesses(process)) {
    return null
  }

  const { fctGraphs } = device
  const { fctGraphId } = process

  const fctGraph = FunctionalityGraph
    .getGraphById(fctGraphs, fctGraphId)

  if (!fctGraph) return null

  return fctGraph.imageURL
}

const renderImage = url => (
  <Image
    floated='left'
    size='tiny'
    src={url}
    className='process-tile-svg'
  />
)

const getStatusDisplayCircle = process => {
  const stateToImageMap = {
    [PROCESS_STATES.running]: processRunningCircle,
    [PROCESS_STATES.paused]: processPausedCircle,
    [PROCESS_STATES.executable]: processExecutableCircle,
    [PROCESS_STATES.finished]: processFinishedCircle,
  }

  return stateToImageMap[process.state]
}

const getDisplayedImage = (activeDevice, process) => {
  const fctGraphImageURL = getFctGraphImgURL(process, activeDevice)
  if (!fctGraphImageURL) return renderImage(DISPLAY_MAP[process.state])

  return (
    <div>
      <img
        className='process-tile-status-circle'
        src={getStatusDisplayCircle(process)}
        alt={`process-status-icon-${process.state}`}
      />
      {renderImage(fctGraphImageURL)}
    </div>
  )
}

const continuouslyUpdateRunningDuration = (startedAt, setRunningDuration) => (
  setInterval(() => {
    setRunningDuration(Date.now() - startedAt)
  }, 1000)
)

const renderStateTransitionDate = (dateAndTime, transitionText) => (
  `${transitionText} ${dateAndTime}`
)

const renderRunning = (startedAt, duration) => {
  const dateAndTime = new Date(startedAt).toString().slice(4, 21)

  const durationString = duration !== null ? Time.stringFromDuration(duration / 1000) : 'loading...'

  return (
    <div className='process-tile-meta-data'>
      {renderStateTransitionDate(dateAndTime, 'Started')}
      <div className='process-tile-running-duration'>
        Duration:
        {' '}
        {durationString}
      </div>
    </div>
  )
}

const renderExecutable = createdAt => {
  const dateAndTime = new Date(createdAt).toString().slice(4, 21)

  return (
    <div className='process-tile-meta-data'>
      {renderStateTransitionDate(dateAndTime, 'Created')}
    </div>
  )
}

const renderFinished = (createdAt, startedAt, finishedAt) => {

  // legacy for processes which don't have the finishedAt property set
  if (!finishedAt) return renderExecutable(createdAt)

  const processDurationInSeconds = (finishedAt - startedAt) / 1000

  const durationString = Time.stringFromDuration(processDurationInSeconds)
  const dateAndTime = new Date(finishedAt).toString().slice(4, 21)

  return (
    <div className='process-tile-meta-data'>
      {renderStateTransitionDate(dateAndTime, 'Finished')}
      <div>
        Duration:
        {' '}
        {durationString}
      </div>
    </div>
  )
}

const renderNotRunning = (state, createdAt, startedAt, finishedAt) => (
  Process.isFinished({ state })
    ? renderFinished(createdAt, startedAt, finishedAt)
    : renderExecutable(createdAt)
)

function ProcessTile(props) {

  const {
    process: {
      name,
      state,
      createdAt,
      startedAt,
      finishedAt,
      pinned,
    },
    process,
    activeDevice,
    openModalHandler,
    dispatchcallUpdateProcessOptimistic,
    showProcessDetailsModal,
    processId,
    match,
  } = props

  const { fctGraphId } = process
  const [ runningDuration, setRunningDuration ] = useState(null)

  const isActive = (
    Process.isRunning(process)
    || Process.isPaused(process)
  )

  useEffect(() => {

    if (!isActive) return

    setRunningDuration(Date.now() - startedAt)
    const interval = continuouslyUpdateRunningDuration(startedAt, setRunningDuration)
    return () => { clearInterval(interval) }
  }, [isActive, startedAt])

  const toggleModal = (event, modalTrigger) => {
    event.stopPropagation()
    openModalHandler(modalTrigger, process)
  }

  const handleToggleFavorite = () => {
    /* TODO: ADD_ERROR_HANDLING */
    dispatchcallUpdateProcessOptimistic(process.id, { pinned: !pinned })
  }

  const renderName = () => (
    <Popup
      content={name}
      basic
      trigger={(
        <h4 className='process-tile-name'>
          {name}
        </h4>
      )}
    />
  )

  const { params: { fctGraphId: currentPathFctGraphId } } = match

  const linkTo = FeatureVersioning.supportsMultipleProcesses(activeDevice) && !currentPathFctGraphId
    ? `configurations/${fctGraphId}/processes/${process.id}`
    : `processes/${process.id}`

  return (
    <Card className='process-tile'>
      <Link to={linkTo} className='process-tile-link-wrapper'>
        <Card.Content className='process-tile-content'>
          {renderFctGraphSpecificInformation(process, activeDevice, match)}
          {getDisplayedImage(activeDevice, process)}
          <Card.Header className='process-tile-header'>
            {renderName(process)}
          </Card.Header>
          <Card.Meta>
            {isActive
              ? renderRunning(startedAt, runningDuration)
              : renderNotRunning(state, createdAt, startedAt, finishedAt)}
          </Card.Meta>
        </Card.Content>
      </Link>
      <Card.Content className='process-tile-footer'>
        <ProcessIconBar
          processState={state}
          toggleModal={toggleModal}
          handleToggleFavorite={handleToggleFavorite}
          isPinned={pinned}
          showProcessDetailsModal={showProcessDetailsModal}
          process={process}
          processId={processId}
        />
      </Card.Content>
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProcessTile))
