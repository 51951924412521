import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import GridLayout from 'react-grid-layout'
import { Container } from 'semantic-ui-react'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import ActuatorValue from '~/utils/UIConfig/widgets/ActuatorValue'
import QueryValue from '~/utils/UIConfig/widgets/QueryValue'
import LineChart from '~/utils/UIConfig/widgets/LineChart'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'
import SensorWidget from './SensorWidget'
import ActuatorWidget from './ActuatorWidget'
import DashboardWidgetModal from './DashboardWidgetModal'
import LineChartWidget from './LineChartWidget'
import './Dashboard.css'
import 'react-grid-layout/css/styles.css'

const mapStateToProps = ({ user }) => ({ user })

const renderWidgetByType = (
  widget,
  dashboard,
  activeProcess,
  removeWidget,
  setAllowDrag,
  locked,
) => {
  const { type, id } = widget

  switch (type) {
    case QueryValue.TYPE:
      return (
        <SensorWidget
          activeProcess={activeProcess}
          widget={widget}
          removeWidget={() => removeWidget(id)}
          setAllowDrag={setAllowDrag}
          dashboard={dashboard}
        />
      )
    case ActuatorValue.TYPE:
      return (
        <ActuatorWidget
          activeProcess={activeProcess}
          dashboard={dashboard}
          widget={widget}
          removeWidget={() => removeWidget(id)}
        />
      )
    case LineChart.TYPE:
      return (
        <LineChartWidget
          activeProcess={activeProcess}
          dashboard={dashboard}
          widget={widget}
          removeWidget={() => removeWidget(id)}
          setAllowDrag={setAllowDrag}
          locked={locked}
        />
      )
    default:
      throw new Error('Unknown widget type provided')
  }
}

function Dashboard({
  activeProcess,
  user,
  dashboard,
  dashboards,
  setOpenWidgetModal,
  openWidgetModal,
}) {

  /* prevents dragging elements behind the modal dimmer */
  const [ allowDrag, setAllowDrag ] = useState(true)

  /* forces re-render to position elements properly */
  const [ initialRender, setInitialRender ] = useState(Date.now())

  useEffect(() => {
    setInitialRender(Date.now())
  }, [])

  const { fctGraphUIConfig: { configs, fctGraphId } } = user

  const { widgets, locked } = dashboard
  const { fctGraph } = activeProcess

  const addWidget = async (widgetType, widgetParams) => {

    let updatedDashboard = GraphDashboard.addWidgetByType(widgetType, dashboard, widgetParams)

    const newWidget = updatedDashboard.widgets[updatedDashboard.widgets.length - 1]

    let x = 0
    let y = 0
    const otherWidgets = updatedDashboard.widgets
      .filter((_, i) => i !== updatedDashboard.widgets.length - 1)

    // eslint-disable-next-line no-loop-func
    while (otherWidgets.some(w => w.display.position.x === x && w.display.position.y === y)) {
      x += 3
      y += 3
    }

    updatedDashboard = GraphDashboard.setWidgetPosition(updatedDashboard, newWidget.id, { x, y })

    const updatedDashboards = GraphDashboards.updateDashboard(dashboards, updatedDashboard)
    await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
    /* TODO: add error handling */

  }

  const removeWidget = async widgetId => {
    const updatedDashboard = GraphDashboard.removeWidget(dashboard, widgetId)
    const updatedDashboards = GraphDashboards.updateDashboard(dashboards, updatedDashboard)

    await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
  }

  const positionHandler = async (layout, widgetBefore, widgetAfter) => {
    const { i, x, y } = widgetAfter

    const updatedDashboard = GraphDashboard.setWidgetPosition(dashboard, i, { x, y })
    const updatedDashboards = GraphDashboards.updateDashboard(dashboards, updatedDashboard)

    await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
  }

  const resizeWidget = async (layout, widgetBefore, widgetAfter) => {
    const { i, w: width, h: height } = widgetAfter
    const heightInPx = GraphDashboard.getHeightInPx(height)
    const widthInPx = GraphDashboard.getWidthInPx(width)
    const updatedDashboard = GraphDashboard
      .setWidgetDimensions(dashboard, i, { width: widthInPx, height: heightInPx })

    const updatedDashboards = GraphDashboards.updateDashboard(dashboards, updatedDashboard)
    await UserFctGraphUIConfig.update(fctGraphId, { ...configs, dashboards: updatedDashboards })
  }

  return (
    <div>
      <GridLayout
        allowOverlap
        className='layout dashboard'
        width={GraphDashboard.WIDTH_PX}
        cols={GraphDashboard.COLUMNS}
        rowHeight={GraphDashboard.ROW_HEIGHT_PX}
        margin={[0, 0]}
        containerPadding={[0, 7]}
        style={{ marginTop: '16px', position: 'relative' }}
        useCSSTransforms={false}
        isDraggable={!locked && allowDrag}
        onDragStop={positionHandler}
        isResizable={!locked}
        onResizeStop={resizeWidget}
      >
        { widgets
          .map(widget => {
            const { id, display: { position: { x, y } } } = widget
            return (
              <div
                key={id}
                data-grid={{
                  isBounded: true,
                  x,
                  y,
                  ...GraphDashboard.getDimensionsInRowsAndColumns(widget),
                }}
                className='widget-dashboard'
              >
                { renderWidgetByType(
                  widget,
                  dashboard,
                  activeProcess,
                  removeWidget,
                  setAllowDrag,
                  locked
                )}
              </div>
            )
          })}
      </GridLayout>
      <Container style={{ marginTop: '20px', marginBottom: '20px', padding: '20px' }} fluid>
        <DashboardWidgetModal
          headerText='Select Widget'
          addWidget={addWidget}
          fctGraph={fctGraph}
          dashboard={dashboard}
          closeHandler={() => setOpenWidgetModal(false)}
          closeOnSubmit
          open={openWidgetModal}
          size='small'
        />
      </Container>
    </div>
  )
}

export default connect(mapStateToProps)(Dashboard)
