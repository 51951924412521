export default class Validator {
  static isBoolean = value => typeof value === 'boolean'

  static isNumber = value => !Number.isNaN(value) && typeof value === 'number'

  static withinRange = (value, [ min, max ]) => value <= max && value >= min

  static lessThanOrEqual = (value, limit) => value <= limit

  static isUndefinedOrNull = value => (value === undefined || value === null)

  static isDigitString = value => /^-?(\d+\.?|\d+\.\d+)$/.test(value)

  static isIntegerString = value => /^-?\d+$/.test(value)

  static isUUIDv4 = value => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
}
