import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { replaceNoUnitCharacter } from '~/utils/units'
import './ActuatorWidgetBody.css'

export const ActuatorWidgetHeader = ({
  displayedName,
  removeWidget,
  image,
}) => (
  <div className='actuator-widget-header-layout'>
    <div className='actuator-widget-header-icon-name'>
      <img src={image} className='actuator-widget-header-icon' alt='Widget Icon' />
      <div className='actuator-widget-slot-name'>
        {displayedName}
      </div>
    </div>
    <div className='transition-opacity actuator-widget-dropdown-container'>
      <Dropdown
        className='actuator-widget-opt-btn'
        item
        closeOnChange
      >
        <Dropdown.Menu className='actuator-widget-opt-menu'>
          <Dropdown.Item data-testid='actuatorwidget-delete' onClick={removeWidget} text='Delete Widget' />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </div>
)

const displayingValueCondition = (value, displayedValue) => {
  switch (true) {
    case value === null:
      return 'No Data'
    case value === false:
      return 'OFF'
    case value === true:
      return 'ON'
    default:
      return displayedValue
  }
}

const ActuatorWidgetBody = ({
  removeWidget,
  image,
  value,
  fctName,
  displayedName,
  displayedValue,
  unit,
}) => {
  return (
    <div className='actuator-widget-container'>
      <ActuatorWidgetHeader
        image={image}
        displayedName={displayedName}
        removeWidget={removeWidget}
      />
      <div className='actuator-widget-value-container'>
        <div className='actuator-widget-value'>
          { displayingValueCondition(value, displayedValue) }
          {' '}
          {value !== null ? replaceNoUnitCharacter(unit, '') : null}
        </div>
        <div className='actuator-widget-fct-name'>
          {fctName}
        </div>
      </div>
    </div>
  )
}

export default withRouter(ActuatorWidgetBody)
