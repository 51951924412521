import nexus from '@ospin/nexus'
import { addProcessToProcessesList } from '~/redux/actions/actions'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'

const callCloneProcess = async (dispatch, processId, body) => {
  const { data: processCloneData } = await nexus.process.clone(processId, body)
  dispatch(addProcessToProcessesList(processCloneData))

  const { data } = await nexus.process.get(processCloneData.id)
  await UserFctGraphUIConfig.setUsersFctGraphUIConfig(data.fctGraph)

  return {
    clonedProcessId: processCloneData.id,
    clonedProcessFctGraphId: processCloneData.fctGraphId,
  }
}

export default callCloneProcess
