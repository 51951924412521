import React from 'react'
import { Menu } from 'semantic-ui-react'

const MODAL_TABS = [
  'Edit',
  'Clone',
  'Access',
  'Delete',
]

const ProcessDetailsModalMenu = props => {
  const { activeTab, handleActiveMenuTab, disableDelete } = props

  return (
    <Menu attached tabular>
      {MODAL_TABS.map(tab => (
        <Menu.Item
          key={tab}
          name={tab}
          active={activeTab === tab}
          onClick={() => handleActiveMenuTab(tab)}
          disabled={tab === 'Delete' ? disableDelete : false}
        />
      ))}
    </Menu>
  )
}

export default ProcessDetailsModalMenu
