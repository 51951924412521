import nexus from '@ospin/nexus'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card } from 'semantic-ui-react'
import { ellipseString } from '~/utils/string'
import Time from '~/utils/Time'
import DurationDisplay from '~/components/misc/DurationDisplay'
import Device from '~/utils/Device'

const processMetaDataStyle = {
  width: '50%',
  margin: '0px',
  padding: '0px',
  boxShadow: '2px 0px 0px #eee',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}

const DEFAULT_OFFLINE_DISPLAY = '#B3B3B3'

const STATE_TO_BACKGROUND_COLOR_MAP = {
  running: '#337ab7',
  paused: '#ff8c00',
}

const RenderViewProcess = ({
  deviceId,
  runningProcessId,
  redirectsToConfiguration,
  fctGraphId,
}) => (
  <div
    className='meta'
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      height: '60px',
      display: 'flex',
    }}
  >
    <Link to={redirectsToConfiguration
      ? `/devices/${deviceId}/configurations/${fctGraphId}/processes/${runningProcessId}`
      : `/devices/${deviceId}/processes/${runningProcessId}`}
    >
      <Button
        className='device-widget-view-running-process-button'
        style={{ marginTop: '24px' }}
        content='View running process'
      />
    </Link>
  </div>
)

const RenderProcessDetails = (
  {
    runningData: {
      startedAtDay,
      startedAtTime,
      startedBy,
      name,
      duration,
    },
    state,
  },
) => (
  <div
    className='device-widget-process-details'
  >
    <div style={processMetaDataStyle}>
      <strong>{ellipseString(name, 17)}</strong>
      <br />
      started by
      <strong>
        {' '}
        {ellipseString(startedBy, 8)}
      </strong>
      <br />
      on
      <strong>
        {' '}
        {startedAtDay}
      </strong>
      at
      <strong>
        {' '}
        {startedAtTime}
      </strong>
    </div>
    <DurationDisplay ms={duration} />
    <div className={state === Device.STATES.RUNNING
      ? 'device-widget-running-bar'
      : 'device-widget-paused-bar'}
    />
  </div>
)

const backgroundColor = (device, runningProcessId) => {
  if (Device.isOffline(device)) { return DEFAULT_OFFLINE_DISPLAY }
  const { state } = device.runningProcesses.find(({ id }) => id === runningProcessId)

  return STATE_TO_BACKGROUND_COLOR_MAP[state]

}

const serializeUserAndDate = async process => {
  /* TODO: ADD_ERROR_HANDLING */
  const { data: user } = await nexus.user.preview.get({ userId: process.startedBy })

  const { startedAt, name, hasAccess } = process

  return {
    name,
    hasAccess,
    startedBy: user.userName,
    startedAtDay: Time.getDisplayDay(startedAt),
    startedAtTime: Time.getDisplayTime(startedAt),
    duration: (Date.now() - startedAt),
    startedAtSinceEpoch: startedAt,
  }
}

const fetchAndSetRunningData = async (id, setRunningData) => {
  /* TODO: ADD_ERROR_HANDLING */
  const { data: process } = await nexus.process.preview.get(id)
  const serialized = await serializeUserAndDate(process)
  return setRunningData(serialized)
}

const continuouslyUpdateRunningDuration = setRunningData => (
  setInterval(() => {
    setRunningData(prevRunningData => ({
      ...prevRunningData,
      duration: (Date.now() - prevRunningData.startedAtSinceEpoch),
    }))
  }, 15000)
)

function renderLoadingIndicator() {
  return (
    <div className='device-widget-status-container'>
      <span>
        loading...
      </span>
    </div>
  )
}

function renderProcessPreview({
  hovered,
  runningData,
  device,
  runningProcessId,
  redirectsToConfiguration,
  fctGraphId,
  state,
}) {
  return (
    hovered && runningData.hasAccess
      ? (
        <RenderViewProcess
          deviceId={device.id}
          runningProcessId={runningProcessId}
          redirectsToConfiguration={redirectsToConfiguration}
          fctGraphId={fctGraphId}
        />
      )
      : (
        <RenderProcessDetails
          runningData={runningData}
          state={state}
        />
      )
  )
}

function DeviceProcess({
  device,
  runningProcessId,
  redirectsToConfiguration = false,
  fctGraphId,
}) {
  const [runningData, setRunningData] = useState(null)
  const [ hasFetchedData, setHasFetchedData ] = useState(false)
  const [hovered, setHover] = useState(false)

  const { state } = device.runningProcesses.find(({ id }) => id === runningProcessId)

  useEffect(() => {
    fetchAndSetRunningData(runningProcessId, setRunningData)
    setHasFetchedData(true)
    return () => setHasFetchedData(false)
  }, [runningProcessId])

  useEffect(() => {
    if (runningData && runningData.startedAtSinceEpoch) {
      const interval = continuouslyUpdateRunningDuration(setRunningData)
      return () => { clearInterval(interval) }
    }
  }, [runningData])

  const hasNotFetchedProcessPreview = !runningData

  return (
    <Card.Content
      extra
      className='center aligned'
      style={{
        backgroundColor: backgroundColor(device, runningProcessId),
        color: 'white !important',
        padding: '0px 8px 24px 8px',
        height: '90px',
        position: 'relative',
      }}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
    >
      { hasNotFetchedProcessPreview
        ? renderLoadingIndicator()
        : renderProcessPreview({
          hovered,
          runningData,
          device,
          runningProcessId,
          redirectsToConfiguration,
          fctGraphId,
          state,
        }) }
    </Card.Content>
  )
}

export default DeviceProcess
