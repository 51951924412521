import React from 'react'
import { Form, Input, Label, TextArea } from 'semantic-ui-react'
import DashboardValidator from '~/utils/validation/DashboardValidation'
import formalize from '~/components/utility/modal/formalize'

const {
  DASHBOARD_VALIDATION_CONSTANTS:
    { name: { max: maxNameLen }, description: { max: maxDescriptionLen } },
} = DashboardValidator

const DashboardDetailsModalForm = props => {
  const {
    modalDashboard,
    handleUpdate,
  } = props

  return (
    <>
      <Form.Field error={!DashboardValidator.isValidDashboardName({ name: modalDashboard.name })}>
        <label htmlFor='dashboardName'>Name</label>
        <Input
          name='name'
          placeholder='new dashboard&apos;s name'
          onChange={handleUpdate}
          value={modalDashboard.name}
        />
        <Label>
          {modalDashboard.name.length}
          /
          {maxNameLen}
        </Label>
      </Form.Field>

      <Form.Field error={!DashboardValidator
        .isValidDashboardDescription({ description: modalDashboard.description })}
      >
        <label htmlFor='dashboardDescription'>
          Dashboard Description
        </label>
        <TextArea
          name='description'
          onChange={handleUpdate}
          value={modalDashboard.description}
        />
        <Label>
          {modalDashboard.description.length}
          /
          {maxDescriptionLen}
        </Label>
      </Form.Field>
    </>
  )
}

export default formalize(DashboardDetailsModalForm)
