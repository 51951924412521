import React, { useState } from 'react'
import nexus from '@ospin/nexus'
import { Button, Divider, Form, Message } from 'semantic-ui-react'
import { removeWhiteSpaces } from '~/utils/string'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const signUpCodeExpired = timestamp => (Date.now() - timestamp) > (1000 * 60 * 60 * 24)

const cognitoNoUserMessage = 'Username/client id combination not found.'
const humanReadable404Message = 'The Requested User could not be found, if you are using your email please try using your username'

function RequestConfirmationCode({ location }) {
  const [userName, setUserName] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  async function resendConfirmationCode() {
    setLoading(true)
    try {
      await nexus.auth.resendConfirmationCode({ username: userName })
      setSuccess('A new confirmation code has been sent to your email')
    } catch ({ message }) {
      setError(message === cognitoNoUserMessage ? humanReadable404Message : message)
    } finally {
      setLoading(false)
    }
  }

  function updateUsername(value) {
    setError(null)
    setUserName(removeWhiteSpaces(value))
  }

  const shouldShowExpirationWarning = () => {
    const query = new URLSearchParams(location.search)
    const timestamp = query.get('timestamp')

    return timestamp && signUpCodeExpired(timestamp)

  }

  return (
    <>
      {shouldShowExpirationWarning() ? <Message info content='The requested code has expired, please request a new one' /> : null}
      <Form
        error={!!error}
        success={success}
        size='large'
        onSubmit={() => resendConfirmationCode()}
      >
        <Form.Field>
          <label htmlFor='username'>
            username
          </label>
          <Form.Input
            size='big'
            name='username'
            id='username'
            fluid
            icon='user'
            iconPosition='left'
            value={userName}
            onChange={((_, { value }) => updateUsername(value))}
          />
        </Form.Field>
        {error && (
          <Message
            error
            content={error}
          />
        )}
        {success && (
          <Message
            success
            content={success}
          />
        )}
        <Button
          loading={loading}
          size='large'
          primary
          fluid
          content='Submit'
          type='submit'
          disabled={!userName}
        />
        <Divider horizontal hidden />
      </Form>
    </>
  )
}
export default withRouter(RequestConfirmationCode)
