import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { addUserToGroup, removeUserFromGroup } from '~/utils/access'
import Device from '~/utils/Device'

export default (state, action) => {
  const { devices } = state
  const {
    deviceId,
    newOwnerId,
    deviceUsers: updatedDeviceUsers,
    access: updatedAccess,
  } = action.payload

  const updateOwnerId = device => Object.assign(device, { ownerId: newOwnerId })

  const updateDeviceAccess = device => {
    if (device.id === deviceId) {
      device.access = updatedAccess
    }
    return device
  }

  const updatedDevices = findAndUpdate(
    deviceId,
    devices,
    [ updateOwnerId, updateDeviceAccess ],
  )

  return { ...state, devices: updatedDevices, deviceUsers: updatedDeviceUsers }
}
