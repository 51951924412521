import React from 'react'
import { connect } from 'react-redux'
import { Container, Button } from 'semantic-ui-react'
import formalize from '~/components/utility/modal/formalize'
import GraphDashboard from '~/utils/UIConfig/GraphDashboard'
import UserFctGraphUIConfig from '~/utils/UIConfig/UserFctGraphUIConfig'
import EscalationWidgetModalFormRow from '~/components/processviewer/body/dashboard/EscalationWidgetModalFormRow'
import GraphDashboards from '~/utils/UIConfig/GraphDashboards'

const mapStateToProps = state => ({ user: state.user })

const EscalationWidgetModalForm = props => {
  const { widget, user, dashboard } = props
  const { id: widgetId, display: { escalations } } = widget

  const { fctGraphUIConfig: { configs, configs: { dashboards }, fctGraphId } } = user

  const addEscalation = async () => {

    const escalation = {
      threshold: 0,
      backgroundColor: '#FF0000',
      fontColor: '#FFFFFF',
      comparator: '>',
    }

    const updatedDashboard = GraphDashboard
      .addSensorWidgetEscalation(dashboard, widgetId, escalation)
    await UserFctGraphUIConfig.update(
      fctGraphId,
      { ...configs, dashboards: GraphDashboards.updateDashboard(dashboards, updatedDashboard) },
    )
  }

  return (
    <div>
      <div>
        Alerts allow you to define rules to conditionally change the background and/or
        font color of the widget based on the current value.
      </div>
      <Container style={{ marginBottom: '10px', marginTop: '16px' }}>
        {escalations.map((escalation, index) => (
          <EscalationWidgetModalFormRow
            escalation={escalation}
            key={`${escalation.backgroundColor}-${escalation.fontColor}-${index}`}
            index={index}
            widgetId={widgetId}
            dashboard={dashboard}
          />
        ))}
      </Container>
      <Container>
        <Button primary onClick={addEscalation}>Add</Button>
      </Container>
    </div>
  )
}

export default connect(mapStateToProps)(formalize(EscalationWidgetModalForm))
