import { findAndUpdate } from '~/redux/helper/updateFunctions'
import { setPhasePropsWithGroupConsideration } from '~/utils/process'
import Phase from '~/utils/process/Phase'

export default (state, action) => {
  const { processes } = state
  const { processId, phaseId, update } = action.payload

  if ('transition' in update && update.transition === Phase.TRANSITIONS.MANUAL) {
    update.duration = 0
  }

  const updateFn = process => setPhasePropsWithGroupConsideration(process, phaseId, update)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
