import { Functionality } from '@ospin/fct-graph'
import React from 'react'
import { Grid, Table, TableBody } from 'semantic-ui-react'
import FunctionalitySlotTableRow from './FunctionalitySlotTableRow'

export default function FunctionalitySlotTable({
  type,
  slots,
  template,
  targetPosId,
  updateTemplate,
}) {

  const isOutSlotTable = type === 'OutSlots'

  const slotsToRender = isOutSlotTable
    ? Functionality.getOutSlots({ slots })
    : Functionality.getInSlots({ slots })

  return (
    <div>
      <Grid celled>
        <Grid.Row>
          <Grid.Column>
            <b>{isOutSlotTable ? 'Outputs' : 'Inputs'}</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table>
              <TableBody>
                <Table.Row>
                  <Table.Cell>Slot Name</Table.Cell>
                  <Table.Cell>Connected To</Table.Cell>
                  {isOutSlotTable ? <Table.Cell>Report Data</Table.Cell> : null}
                </Table.Row>
                {slotsToRender.map(slot => (
                  <FunctionalitySlotTableRow
                    key={slot.name}
                    slot={slot}
                    targetPosId={targetPosId}
                    template={template}
                    type={type}
                    updateTemplate={updateTemplate}
                  />
                ))}
              </TableBody>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    </div>
  )
}
