import React from 'react'
import { Image, Divider } from 'semantic-ui-react'
import './AuthViewContainer.css'
import logo3 from '../../images/logo3.png'

const AuthViewContainer = ({ children }) => (
  <div className='auth-view-container' style={{ padding: '0 16px'}}>
    <div className='auth-view-container-form'>
      <Image
        src={logo3}
        centered
        size='medium'
      />
      <Divider horizontal hidden />

      { children }
    </div>
  </div>
)

export default AuthViewContainer
