import React from 'react'
import { Card, Header } from 'semantic-ui-react'
import ProcessTile from './ProcessTile'

import './ProcessesArea.css'

const FavoriteProcesses = ({
  activeDevice,
  processes,
  processClickHandler,
  openModalHandler,
  showProcessDetailsModal,
}) => {
  if (!processes.length) return null

  return (
    <div className='fav-processes-container'>
      <div className='processes-list'>
        <Header as='h3'>Favorite Processes</Header>
      </div>
      <Card.Group className='process-tiles'>
        {processes
          .map(process => (
            <ProcessTile
              key={process.id}
              process={process}
              processClickHandler={processClickHandler}
              openModalHandler={openModalHandler}
              activeDevice={activeDevice}
              showProcessDetailsModal={showProcessDetailsModal}
            />
          ))}
      </Card.Group>
    </div>
  )
}

export default FavoriteProcesses
